export interface TveProviders extends TveResponse {
  providers: TveCountryProviders;
}

export interface TveCountryProviders {
  countryCode: string;
  country: string;
  idp: TveProvider[];
}

export interface TveProvider {
  description: string;
  shortName: string;
  logo: string;
}

export interface TveError {
  error: string;
}

export interface TveLoginResponse extends TveResponse {
  accessToken: string;
  isMobile?: boolean;
}

export interface TveLogoutResponse extends TveResponse {
  status: string;
}

export interface TveVideoTokenResponse extends TveResponse {
  videoToken: string;
}

export interface TveAuthTokenResponse {
  access_token?: string;
  success: boolean;
}

export interface TveVideoTokenRequestParams {
  accessToken: string;
  mediaId: string;
  urn: string;
  network: string;
  userId: string;
  requestor: string;
  mvpd: string;
}

interface TveResponse {
  success: boolean;
  error?: string;
}

export interface TveToolboxConfig {
  enabled: boolean;
  authorizeUrl: string;
  tokenUrl: string;
  providersUrl: string;
  logoutUrl: string;
  clientId: string;
  scopes: string[];
  redirectUrl: string;
  countries: TveCountry[];
  videoTokenUrl: string;
  tenant: string;
  urnParam: string;
}

export interface TveCountry {
  name: string;
  code: string;
}

export const EMPTY_TVE_TOOLBOX_CONFIG = {
  enabled: false,
  authorizeUrl: '',
  tokenUrl: '',
  providersUrl: '',
  logoutUrl: '',
  issuer: '',
  clientId: '',
  scopes: [],
  redirectUrl: '',
  redirectUrlWeb: '',
  countries: [],
  tveRegsitrationToken: '',
  videoTokenUrl: '',
  tenant: '',
  urnParam: '',
} as TveToolboxConfig;
