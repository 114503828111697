import React, { useState, ReactNode, createContext } from 'react';
import { LayoutChangeEvent, View } from 'react-native';
import { useGetDevice } from '@warnermmedia/gsp-core/sdk/ui';

interface Props {
  children: ReactNode;
}

export const LayoutUpdateContext = createContext<number>(800);

export const LayoutUpdateProvider = ({ children }: Props) => {
  const [contentWidth, setContentWidth] = useState(0);
  const { isPwa } = useGetDevice();

  const onlayout = (event: LayoutChangeEvent) => {
    const width = isPwa ? event.nativeEvent.layout.width - 10 : event.nativeEvent.layout.width;
    setContentWidth(width);
  };

  return (
    <View onLayout={onlayout}>
      <LayoutUpdateContext.Provider value={contentWidth}>{children}</LayoutUpdateContext.Provider>
    </View>
  );
};
