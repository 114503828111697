import { callApiEndpoint, createApiResponse } from '../axiosHelper';
import { AxiosTypes } from '../../types/axios-types';
import { GeoGetLocateResponse, GeoPath, GetLocateResponse } from '../../types/geo-types';

interface LocateProps {
  getLocate(): GeoGetLocateResponse;
}

const useGeo = (locateApiPath: string, appId: string): LocateProps => {
  const getGeoHeaders = (): { 'app-id': string } => {
    return {
      'app-id': appId,
    };
  };

  const getLocate = async (): GeoGetLocateResponse => {
    const getCodeApiCall = await callApiEndpoint(
      AxiosTypes.GET,
      locateApiPath,
      GeoPath.GET_LOCATE,
      null,
      getGeoHeaders()
    );

    return createApiResponse<GetLocateResponse>(getCodeApiCall);
  };

  return {
    getLocate: (): GeoGetLocateResponse => getLocate(),
  };
};

export default useGeo;
