/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import React, { useEffect, useRef, useState } from 'react';
import { DocumentNode, useApolloClient, useReactiveVar } from '@apollo/client';
import { CMSAPIQueries, isUserLoggedIn, Query } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { useProfileData } from '../useProfileData';
import get from 'lodash/get';
import { useIsMountedRef } from '../useIsMountedRef';
import { CatchQueryMapsNames, QueryNames, QueryVariablesType } from './useFetchPageTypes';
import { getQueryVariables, isQueryReady } from './fetchPageHelpers';
import isEqual from 'lodash/isEqual';

export const useFetchPageData = (queryOptions: QueryVariablesType): Query | undefined => {
  const [pageData, setPageData] = useState<Query | undefined>();
  const [cachedQuery, setCachedQuery] = useState<Query>();
  const [queryData, setQueryData] = useState({
    loading: false,
    called: false,
    ready: false,
    error: '' as unknown,
    data: {},
    queryName: '' as QueryNames,
    queryVariables: {},
  });
  const { userData } = useProfileData();
  const client = useApolloClient();
  const isLogged = useReactiveVar(isUserLoggedIn);
  const isMountedRef = useIsMountedRef();
  const queryCallRef = useRef<ZenObservable.Subscription | null>(null);

  const handleQuery = () => {
    setQueryData((prevState) => {
      return {
        ...prevState,
        loading: true,
        called: true,
      };
    });

    queryCallRef.current = client
      .watchQuery({
        query: CMSAPIQueries[queryData.queryName] as DocumentNode,
        errorPolicy: 'all',
        fetchPolicy: 'cache-and-network',
        variables: queryData.queryVariables,
      })
      .subscribe(
        ({ data, loading }) => {
          if (isMountedRef.current) {
            setQueryData((prevState) => ({
              ...prevState,
              loading,
              data,
            }));
          }
        },
        (e: unknown) => {
          if (isMountedRef.current) {
            setQueryData((prevState) => ({
              ...prevState,
              error: e,
            }));
          }
        }
      );
  };

  useEffect(() => {
    const queryVariables = getQueryVariables(queryOptions, userData);
    if (
      queryOptions &&
      isQueryReady(queryOptions, isLogged, userData) &&
      isMountedRef.current &&
      queryVariables &&
      (!isEqual(queryData.queryVariables, queryVariables) || !queryData.ready)
    ) {
      setQueryData((prevState) => {
        return {
          ...prevState,
          loading: true,
          called: true,
          ready: true,
          queryName: queryOptions.queryName,
          queryVariables: queryVariables,
        };
      });
    }
  }, [queryData.ready, queryOptions, userData, isMountedRef, isLogged]);

  useEffect(() => {
    if (queryData.ready && isMountedRef.current) {
      handleQuery();
    }
  }, [queryData.ready, isMountedRef, queryData.queryVariables]);

  useEffect(() => {
    if (cachedQuery && isMountedRef.current) {
      setPageData(cachedQuery);
    }
  }, [cachedQuery, isMountedRef]);

  useEffect(() => {
    if (pageData && queryCallRef.current && queryData.data && !queryData.loading) {
      queryCallRef.current.unsubscribe();
    }
  }, [pageData, queryData.data, queryData.loading]);

  useEffect(() => {
    const cacheQuery = (query: QueryNames) => {
      const data = client.readQuery({
        query: CMSAPIQueries[query] as DocumentNode,
        variables: queryData.queryVariables,
      });
      return data;
    };

    const cacheQueryName = get(CatchQueryMapsNames, queryData.queryName, '') as QueryNames;
    if ((queryData.called || (!queryData.loading && queryData.data)) && cacheQueryName && isMountedRef.current) {
      const cacheData = cacheQuery(cacheQueryName);
      cacheData && setCachedQuery(cacheData);
    }
  }, [client, queryData.called, queryData.data, queryData.loading, isMountedRef]);

  return pageData;
};
