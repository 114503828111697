import React, { useCallback, useState } from 'react';
import { Linking, Text } from 'react-native';
import { List, useTheme } from 'react-native-paper';
import {
  RegisterAccountForm as Form,
  RegisterAccountFormData,
  registerAccountFormErrors,
} from '@warnermmedia/gsp-core/sdk/ui';
import {
  Dalton,
  estadioErrorHandle,
  getUserPrimaryEmail,
  languageStrings,
  useMparticleCustomEventObject,
} from '@warnermmedia/gsp-core/brands/estadio/feature';
import { getStyles } from './account.styles';
import {
  AuthState,
  IdentityTypes,
  MParticleAppConfigParams,
  MParticleCustomEventTypes,
  mParticleEventProcessor,
  setMparticleUserId,
  UserProfileResponse,
} from '@warnermmedia/gsp-core/sdk/data-access';
import { AxiosError } from 'axios';
import { ConsentMessage } from '../consentMessage';
import { isAppTransitioning } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { PasswordHide, PasswordShow } from '@warnermmedia/gsp-core/brands/estadio/assets';

export const RegisterAccountForm = ({
  acceptText,
  acceptLink,
  shouldFocusForm = false,
  setShouldFocusForm,
  mParticleEventRegisterOptions = {} as MParticleAppConfigParams,
}: {
  acceptText: string;
  acceptLink?: string;
  isTest?: boolean;
  shouldFocusForm?: boolean;
  setShouldFocusForm: (value: boolean) => void;
  mParticleEventRegisterOptions?: MParticleAppConfigParams;
}) => {
  const [apiError, setApiError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const dalton = Dalton();
  const mParticleEventData = useMparticleCustomEventObject(mParticleEventRegisterOptions);
  const language = languageStrings.default;
  const { colors } = useTheme();
  const styles = getStyles(colors);

  const errorMessages: registerAccountFormErrors = {
    missingAnyField: language.missingAnyField,
    missingFirstName: language.missingFirstName,
    missingLastName: language.missingLastName,
    missingEmail: language.missingEmail,
    invalidEmail: language.invalidEmail,
    missingPassword: language.missingPassword,
    missingPassword2: language.missingPassword2,
    invalidPassword: language.passwordErrorMsg,
    differingPassword: language.differingPassword,
    invalidCaptcha: language.invalidCaptcha,
    unacceptedTerms: language.unacceptedTerms,
    alreadyRegisteredEmail: language.alreadyRegisteredEmail,
    incorrectPassword: language.incorrectPassword,
    unconfirmedAccount: language.unconfirmedAccount,
    onlyAlphabeticCharactersError: language.onlyAlphabeticCharactersError,
  };

  const checkboxAttributes = {
    color: colors.tenantBackground.light.surfaceBase,
    uncheckedColor: colors.tenantBackground.light.surfaceBase,
  };
  const textInputAttributes = {
    dense: true,
    mode: 'outlined' as 'outlined' | 'flat' | undefined,
    outlineColor: colors.fill.action.accent02,
    theme: { colors: { text: colors.keyboardFocus.dark, error: colors.tenantBackground.dark.notifyError } },
    textAlign: 'left' as 'left' | 'right' | 'center' | undefined,
    editable: !isLoading,
    focusable: !isLoading,
  };
  const toolTipAttributes = {
    opacity: 1,
    position: 'left' as 'right' | 'left' | 'center',
    tooltipArrowStyle: { borderBottomColor: colors.tenantBackground.dark.notifyError },
    tooltipLabelStyle: { backgroundColor: colors.tenantBackground.dark.notifyError },
  };
  const termsLabel = (
    <>
      {language.registerTermsLabelLine1}
      <Text onPress={() => (acceptLink ? Linking.openURL(acceptLink) : null)}>
        {language.registerTermsLabelLine2}
      </Text>{' '}
      {language.registerTermsLabelLine3} {acceptText}
    </>
  );
  const errorIcon = (
    <List.Icon
      icon="alert-circle-outline"
      color={colors.tenantBackground.light.surfaceBase}
      style={{ height: 32, margin: 0, width: 24 }}
    />
  );

  const handleRegistration = useCallback(
    async (data: RegisterAccountFormData) => {
      /*
      TODO: dalton error checking:
      alreadyRegisteredEmail = errorMessages.alreadyRegisteredEmail || 'Username, password, or both are incorrect. Please revise it.'
      incorrectPassword = errorMessages.incorrectPassword || 'Username, password, or both are incorrect. Please revise it.'
      unconfirmedAccount = errorMessages.unconfirmedAccount || 'Please confirm your account before entering Estadio.'
    */
      const signupPayload = {
        firstname: data.firstname,
        lastName: data.lastname,
        principal: data.email,
        credential: data.password,
        identityType: IdentityTypes.EMAIL,
      };
      setIsLoading(true);
      setApiError('');
      isAppTransitioning(true);
      mParticleEventProcessor.pushMParticleEvent(MParticleCustomEventTypes.RegistrationStartEvent, mParticleEventData);
      await dalton.signup(
        data.firstname,
        data.lastname,
        data.email,
        data.password,
        (response: UserProfileResponse) => {
          const userPrimaryEmail = getUserPrimaryEmail(response);
          setMparticleUserId(response.user.tid, userPrimaryEmail);

          mParticleEventProcessor.pushMParticleEvent(MParticleCustomEventTypes.RegistrationCompleteEvent, {
            ...mParticleEventData,
            ...{ auth_state: AuthState.Authenticated, customer_id: response.user.tid },
          });

          isAppTransitioning(false);
        },
        (error: AxiosError) => {
          const errorCode = error.response ? error.response.status.toString() : 'general';
          setIsLoading(false);
          setApiError(estadioErrorHandle(errorCode, 'signup'));
          mParticleEventProcessor.pushMParticleEvent(MParticleCustomEventTypes.RegistrationErrorEvent, {
            ...mParticleEventData,
            ...{ error_code: errorCode },
          });
          isAppTransitioning(false);
        }
      );
    },
    [mParticleEventData, dalton, setIsLoading, setApiError]
  );

  const formTitle = <Text style={styles.formTitleTextStyle}>{language.registerFormTitle}</Text>;

  const formDirectionsText = <Text style={styles.formDirectionsTextStyle}>{language.registerFormDirectionsText}</Text>;

  return (
    <Form
      firstNameLabel={language.firstName}
      lastNameLabel={language.lastName}
      passwordLabel={language.password}
      password2Label={language.repeatPassword}
      checkboxAttributes={checkboxAttributes}
      termsLabel={termsLabel}
      ctaLabel={isLoading ? language.loadingText : language.register}
      isLoading={isLoading}
      errorIcon={errorIcon}
      errorListLabel={language.errorListLabel}
      errorMessages={errorMessages}
      styles={styles}
      textInputAttributes={textInputAttributes}
      tooltipAttributes={toolTipAttributes}
      handleRegister={handleRegistration}
      apiError={apiError}
      consentMessage={<ConsentMessage formName={language.register} />}
      useAcceptTerms={false}
      formTitle={formTitle}
      formDirectionsText={formDirectionsText}
      setApiError={setApiError}
      shouldFocusForm={shouldFocusForm}
      setShouldFocusForm={setShouldFocusForm}
      passwordHideIcon={<PasswordHide />}
      passwordShowIcon={<PasswordShow />}
    />
  );
};

export default RegisterAccountForm;
