import React, { useRef } from 'react';
import { Avatar, List } from 'react-native-paper';
import { Text, View } from 'react-native';
import { callbackNavigateExternal, getFooterGoToLink } from '@warnermmedia/gsp-core/brands/estadio/feature';
import { getStyles } from './footer.styles';
import { Hoverable } from 'react-native-web-hover';
import { Breakpoints, useSpatialNavigation } from '@warnermmedia/gsp-core/sdk/ui';
import { FocusKeys } from '@warnermmedia/gsp-core/sdk/data-access';

export interface LeftProps {
  goTo: string;
  copyright: string;
  appVersion: string;
  breakpoints: Breakpoints;
  colors: ReactNativePaper.ThemeColors;
  isUserLoggedIn: boolean;
  isNavMobile: boolean;
}

export const FooterLeft = (props: LeftProps) => {
  const styles = getStyles(props.breakpoints, props.colors);
  const footerLinkFocusKey = useRef(FocusKeys.FOOTER_LEFT).current;
  const { ref, focused, focusSelf, setFocus, setFocusOnNavbar } = useSpatialNavigation({
    focusKey: footerLinkFocusKey,
    onEnterPress: () => {
      callbackNavigateExternal(getFooterGoToLink());
    },
    onArrowPress: (direction) => {
      if (direction === 'left' && (!props.isUserLoggedIn || props.isNavMobile)) {
        focusSelf();
        return false;
      }

      if (direction === 'left' && props.isUserLoggedIn && !props.isNavMobile) {
        setFocusOnNavbar();
      }

      if (direction === 'right' && props.isNavMobile) {
        focusSelf();
        return false;
      }

      if (direction === 'up' && props.isNavMobile) {
        setFocus(FocusKeys.FOOTER_IMAGES_ROW);
        return false;
      }

      return true;
    },
  });

  const renderGotoLink = () => {
    return (
      <Hoverable>
        {({ hovered }) => (
          <List.Item
            title={props.goTo}
            style={[styles.gotoIconSelf, focused && styles.focusStyle]}
            onPress={() => callbackNavigateExternal(getFooterGoToLink())}
            titleStyle={styles.titleStyles}
            left={() => (
              <Avatar.Icon
                size={24}
                icon="open-in-new"
                color={'white'}
                style={[
                  styles.icon,
                  {
                    backgroundColor: hovered
                      ? props.colors.tenantBackground.dark.surface04
                      : props.colors.tenantBackground.dark.surfaceBase,
                  },
                ]}
              />
            )}
          />
        )}
      </Hoverable>
    );
  };

  return (
    <View style={styles.openInNew}>
      <View style={styles.footerBottom}>
        <Text style={styles.stadium}>{props.copyright}</Text>
        <Text style={styles.version}>{props.appVersion}</Text>
      </View>
      <View style={styles.goToLinkContainer} ref={ref} nativeID={footerLinkFocusKey}>
        {renderGotoLink()}
      </View>
    </View>
  );
};
