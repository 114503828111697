import { useBreakpoints, useWebos } from '@warnermmedia/gsp-core/brands/estadio/feature';
import React, { ReactNode, createContext } from 'react';

interface Props {
  children: ReactNode;
  device: string;
}

interface estadioVars {
  magicRemoteVisible?: boolean;
}

export const EstadioContext = createContext<estadioVars>({});

// Add all app startup here instead of app.tsx
export const EstadioProvider = ({ children, device }: Props) => {
  useBreakpoints();
  const magicRemoteVisible = useWebos(device);
  return <EstadioContext.Provider value={{ magicRemoteVisible }}>{children}</EstadioContext.Provider>;
};
