import {
  ComscoreConfig,
  IPlayer,
  ListenableEvents as PlayerEvent,
  PlayConfig,
  MediaJSONData,
  TubErrorResult,
  PlayerFactory,
  ListenableEventBase,
  ListenableEventCallback,
  TimeMode,
  SetupConfig,
  ViewMode,
} from '@top/tub-player-web';
import Conviva from '@convivainc/conviva-js-coresdk';
import { ErrorCodes } from './ErrorCodes';
import { playerStateStore, videoStates } from '@warnermmedia/gsp-core/brands/estadio/data-access';

// The player requires us to include conviva ourselves
// This has effectively the same side-effect as adding a script tag with conviva:
declare global {
  interface Window {
    Conviva: unknown;
  }
}

window.Conviva = Conviva;

export type RemovableListener = () => void;
export interface IMediaItem {
  mediaData: MediaJSONData;
  isLive: boolean;
  clipContent?: boolean;
  tveContent?: boolean;
  streamUrl?: string; // for audio
  comscoreConfig?: ComscoreConfig;
  adProfile?: string;
}

export type PlayerEventCallback = ListenableEventCallback<ListenableEventBase>;

const maxPlaybackAttempts = 3;

export class Player {
  private static _instance: Player;

  private _pendingLoad?: Promise<void>;
  private _sessionId?: string;
  private _playerSetup = false;
  private _retryAttempts = 0;

  private _player: IPlayer | null;
  private _media?: IMediaItem;
  private _playConfig?: PlayConfig;
  private _containerElement?: HTMLElement;
  private _setupConfig?: SetupConfig;
  private _loadTime?: number;

  events: RemovableListener[] = [];

  constructor(containerElement: HTMLElement, setupConfig: SetupConfig) {
    this._player = null;
    this._player = PlayerFactory(containerElement, setupConfig);
  }

  static getInstance(containerElement: HTMLElement, setupConfig: SetupConfig) {
    if (!this._instance) {
      this._instance = new Player(containerElement, setupConfig);
    }
    return this._instance;
  }

  get currentMediaId(): string {
    return this._media ? this._media.mediaData.mediaId : '';
  }

  isPlaying = () => !!this._playerSetup && this._player?.isPlaying();
  isLive = () => !!this._playerSetup && this._player?.isLive();
  getVolume = () => (this._playerSetup && this._player?.getVolume()) || 0;
  getDuration = () => (this._playerSetup && this._player?.engine.getDuration()) || 0;
  getCurrentTime = () => (this._playerSetup && this._player?.engine.getCurrentTime(TimeMode.RelativeTime)) || 0;
  playingAd = () => (this._playerSetup && this._player?.playingAd) || false;
  seekToLive = () => {
    this._playerSetup && this._player?.getMaxTimeShift();
    if (this._playerSetup) {
      const time = this._player?.getMaxTimeShift();
      if (time) {
        this._player?.timeShift(0);
      }
    }
  }; // Time Shift
  setFullScreen = () => this._playerSetup && this._player?.setViewMode(ViewMode.Fullscreen);

  seekTo(time: number): void {
    this._player?.seek(time, 'seekSelection');
  }

  seekForward(secondsForward: number): void {
    if (!this._playerSetup) {
      return;
    }

    this._player?.seek(Math.min(this.getDuration(), this.getCurrentTime() + secondsForward));
  }

  seekBackward(secondsBackward: number): void {
    if (!this._playerSetup) {
      return;
    }

    this._player?.seek(Math.max(0, this.getCurrentTime() - secondsBackward));
  }

  async play() {
    if (!this._playerSetup) {
      return;
    }

    try {
      if (this._player?.isPaused()) {
        await this._player?.play();
      }
      // This gets thrown despite not the user experience not being effected and other errors handling any disruptions to the user.
      // eslint-disable-next-line no-empty
    } catch (error) {
      console.error(error, ' play()');
    }
  }

  pause() {
    if (!this._playerSetup) {
      return;
    }

    if (this._player?.isPlaying()) {
      this._player?.pause();
    }
  }

  /**
   * Open and play media
   * @param media - the media to play, either a url or an object with all the necessary metadata and config
   */
  async open(media: IMediaItem) {
    try {
      this._loadTime = Date.now();
      await this._setupPlayer();
    } catch (error) {
      this.handlePlayerError(true, ErrorCodes.PLAYER_SETUP_FAILED, error);
      throw error;
    }
    this._media = media;

    this._playConfig = {
      isLive: media.isLive,
      drm: {
        enabled: true,
        // TODO Support more than just widevine for other devices
        widevine: { enabled: true },
        playready: { enabled: false },
        fairplay: { enabled: false },
      },
    };

    await this._startLoad();
  }
  setPosterImage(url: string, keepPersistent: boolean) {
    this._player?.setPosterImage(url, keepPersistent);
  }

  private _resetRetryAttempts() {
    this._retryAttempts = this._media && this._media.isLive ? maxPlaybackAttempts : 1;
  }

  private async _startLoad() {
    /// #if ENV !== 'production'
    console.assert(this._media, 'Attempting to start player load with no media set');
    console.assert(this._playConfig, 'Attempting to start player load with no playConfig set');
    /// #endif

    if (!this._media || !this._playConfig) {
      return;
    }

    try {
      this._pendingLoad = this._player?.playByMediaJson(this._media.mediaData, this._playConfig).catch((error) => {
        console.error(error, 'player_error', error?.message);
        playerStateStore({
          type: videoStates.TUB_ERROR,
          message: error?.message,
        });
      });
      await this._pendingLoad;
      this._pendingLoad = void 0;
    } catch (err) {
      console.warn(err);
      this._pendingLoad = void 0;
      this.handlePlayerError(false, ErrorCodes.PLAYER_LOADING_FAILED, err as Error);
    }
  }

  /**
   *
   * @param event
   * @param callback
   */
  addListener(event: PlayerEvent, callback: PlayerEventCallback) {
    try {
      if (!this._player) return;

      this._player.on(event, callback);

      const removeListener = () => {
        if (!this._player) return;
        this._player.off(event, callback);
      };

      this.events.push(removeListener);
    } catch (error) {
      console.log(error);
    }
  }

  clear() {
    this.events.forEach((removeListener) => removeListener());
    this.events = [];
  }

  async close(): Promise<void> {
    try {
      if (this._playerSetup) {
        if (this._pendingLoad) {
          await this._pendingLoad;
        }
        if (this._player?.unload) {
          await this._player?.unload(); // Make sure Player unloads first incase of any further errors causing player not to unload properly
        }
      }
      this.clear();

      this._sessionId = undefined;
      this._media = undefined;
      this._playConfig = undefined;
      this._retryAttempts = 0;
      this._playerSetup = false;
      if (this._player?.destroy) {
        this._player?.destroy();
      }
    } catch (error) {
      console.warn(error);
    }
  }

  private async _setupPlayer() {
    try {
      if (!this._playerSetup) {
        await this._player?.setup();
        this._playerSetup = true;
      }
    } catch (error) {
      this.handlePlayerError(false, ErrorCodes.PLAYER_SETUP_FAILED, error);
    }
  }

  private _isTimeChanged(event: ListenableEventBase) {
    if (!this.isLive) {
      playerStateStore({
        type: videoStates.TIME_CHANGED,
        message: event,
      });
    }
  }

  private _isWarning(event: ListenableEventBase) {
    playerStateStore({
      type: videoStates.TIME_CHANGED,
      message: event,
    });
  }

  private _isPlayingHandler(event: ListenableEventBase) {
    if (this._loadTime) {
      const startupTime = new Date(Date.now() - this._loadTime);
      console.log('Video Startup Time: ' + startupTime.getSeconds() + '.' + startupTime.getMilliseconds() + 's');
    }
    playerStateStore({
      type: videoStates.IS_PLAYING,
      message: event,
    });
  }

  private _isPlaybackFinished(event: ListenableEventBase) {
    playerStateStore({
      type: videoStates.CONTENT_ENDED,
      message: event,
    });
  }

  private _isPausedHandler(event: ListenableEventBase) {
    playerStateStore({
      type: videoStates.IS_PAUSED,
      message: event,
    });
  }

  private _errorHandler(event: ListenableEventBase) {
    playerStateStore({
      type: videoStates.PLAYER_ERROR,
      message: event,
    });
  }

  private _seeked() {
    if (!this._player?.isPlaying()) {
      void this._player?.play();
    }
  }

  private _playerReady(event: ListenableEventBase) {
    playerStateStore({
      type: videoStates.PLAYER_READY,
      message: event,
    });
  }

  private _moduleReadyHandler(event: ListenableEventBase) {
    playerStateStore({
      type: videoStates.MODULE_READY,
      message: event,
    });
  }

  _yospaceSessionTimeout = () => {
    this.handlePlayerError(false, ErrorCodes.PLAYER_AD_ERROR, new Error('yospace session timeout'));
  };

  _onGenericError = (err: TubErrorResult) => {
    if (!err.message) {
      err.message = err.name;
    }
    this.handlePlayerError(false, ErrorCodes.PLAYER_GENERIC_ERROR, (err as unknown) as Error, err.code);
  };

  onRetry = (errorCode: ErrorCodes): void => {
    console.log(errorCode);
  };

  handlePlayerError = (isFatal: boolean, errorCode: ErrorCodes, error?: Error, bitmovinErrorCode?: number) => {
    if (isFatal || this._retryAttempts <= 0) {
      this.onFatalError(errorCode);
    } else {
      void this._startLoad();
      this.onRetry(errorCode);
    }
  };

  /**
   * Callback for fatal errors
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onFatalError = (errorCode: ErrorCodes): void => {};
}
