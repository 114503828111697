import { StyleProp, StyleSheet, TextStyle, ViewStyle } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';
import { merge } from 'lodash';

interface Styles {
  fullScreenContainer: ViewStyle;
  container: ViewStyle;
  containerInner: ViewStyle;
  overrideTermsContainer: ViewStyle;
  overrideTermsWrapper: ViewStyle;
  termsContainer: ViewStyle;
  title: TextStyle;
  description: TextStyle;
  btnLabel: TextStyle;
  btnContainer: ViewStyle;
  btn: ViewStyle;
  closeBtn: TextStyle;
  centeredViewMobile: ViewStyle;
}

export const getStyles = (breakpoints: Breakpoints, isTv: boolean, colors: ReactNativePaper.ThemeColors) => {
  const isMobileLandscape = breakpoints.breakpointHelpers.isLandScape;

  const base: StyleProp<Styles> = {
    fullScreenContainer: {
      backgroundColor: colors.tenantBackground.dark.modalBackground,
      position: 'absolute',
      width: '100%',
      height: '100%',
    },
    centeredViewMobile: {
      backgroundColor: colors.tenantBackground.dark.modalBackground,
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    container: {
      flexDirection: 'column',
      paddingVertical: 48,
      marginVertical: '10%',
      backgroundColor: colors.tenantBackground.dark.surface03,
      alignItems: 'center',
      justifyContent: 'center',
      alignSelf: 'center',
    },
    containerInner: {
      flexDirection: 'column',
      alignItems: 'center',
      width: '90%',
    },
    title: {
      fontSize: 20,
      color: colors.keyboardFocus.light,
      fontFamily: 'Oswald-Medium',
      textTransform: 'uppercase',
      paddingBottom: 12,
      textAlign: 'center',
    },
    description: {
      fontSize: 16,
      color: colors.keyboardFocus.light,
      fontFamily: 'Rubik-Regular',
      textAlign: 'center',
      marginTop: 5,
    },
    overrideTermsContainer: {
      marginTop: 10,
      width: '100%',
      maxWidth: '100%',
      justifyContent: 'center',
      alignSelf: 'center',
    },
    overrideTermsWrapper: {
      justifyContent: 'center',
      width: '100%',
      maxWidth: '100%',
    },
    termsContainer: {
      width: '100%',
    },
    btnContainer: {
      width: '100%',
    },
    btn: {
      backgroundColor: colors.fill.action.accent01,
      borderRadius: 4,
      height: 40,
      width: '100%',
      borderColor: 'transparent',
    },
    btnLabel: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Oswald-Medium',
      fontSize: 20,
      fontWeight: '500',
      textTransform: 'uppercase',
      textAlign: 'center',
      lineHeight: 24,
    },
    closeBtn: {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      alignSelf: 'flex-end',
      fontSize: 20,
      fontWeight: '900',
      textTransform: 'uppercase',
      position: 'absolute',
      top: 15,
      right: 15,
    },
  };

  const mediaQueries = isTv
    ? ({
        container: {
          width: '43%',
          maxWidth: 622,
          paddingHorizontal: 64,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isTiny ||
      breakpoints.currentBreakpoints.isTnySm ||
      breakpoints.currentBreakpoints.isSmMed
    ? {
        container: {
          width: '86%',
          paddingHorizontal: 20,
          paddingVertical: isMobileLandscape ? 20 : 48,
        },
        bottomContainer: {
          justifyContent: 'center',
        },
      }
    : breakpoints.currentBreakpoints.isMedLg
    ? ({
        container: {
          width: '74%',
          paddingHorizontal: 59,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isLgXl ||
      breakpoints.currentBreakpoints.isXlXxl ||
      breakpoints.currentBreakpoints.isXxl
    ? ({
        container: {
          width: '43%',
          paddingHorizontal: 64,
        },
      } as StyleProp<Styles>)
    : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
