import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  button: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'rgb(255, 255, 255)',
    borderRadius: 4,
    height: 40,
  },
  buttonText: {
    flex: 1,
    textAlign: 'left',
    backgroundColor: '#fff',
    padding: 8,
  },
  icon: {
    marginRight: 10,
  },
  dropdown: {
    position: 'absolute',
    backgroundColor: '#fff',
    width: '100%',
    shadowColor: '#000000',
    shadowRadius: 4,
    shadowOffset: { height: 4, width: 0 },
    shadowOpacity: 0.5,
  },
  overlay: {
    width: '100%',
    height: '100%',
  },
  item: {
    flex: 1,
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
  },
  itemText: {
    paddingVertical: 8,
  },
  activeIcon: {
    alignSelf: 'flex-start',
    padding: 0,
  },
  focused: {
    borderWidth: 2,
    borderColor: '#000000',
  },
});
