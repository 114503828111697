import { TokenResponseEventResult } from '@top/player-block-react-native';
import { useEffect, useState } from 'react';
import { useInterval } from './useInterval';

export interface HeartBeatParams {
  tokenUrl: string;
  assetId: string;
  appId: string;
  mediaId: string;
  heartbeatToken: string;
  heartbeatInterval?: number;
}

export interface useHeartBeatResponse {
  heartBeatError: string;
  setPoll: React.Dispatch<React.SetStateAction<boolean>>;
  setHeartBeatError: React.Dispatch<React.SetStateAction<string>>;
}

export const useHeartBeat = ({
  tokenUrl,
  assetId,
  appId,
  mediaId,
  heartbeatToken,
  heartbeatInterval = 60000,
}: HeartBeatParams): useHeartBeatResponse => {
  const [appHeartbeatToken, setAppHeartbeatToken] = useState<string>('');
  const [heartBeatError, setHeartBeatError] = useState<string>('');
  const [poll, setPoll] = useState(false);
  const [beatToken, setBeatToken] = useState<string>(heartbeatToken);

  useEffect(() => {
    setBeatToken(heartbeatToken);
  }, [heartbeatToken]);

  const beatRequest = () => {
    if (poll) {
      setHeartbeat();
    }
  };

  useEffect(() => {
    const oneTimer = setTimeout(beatRequest, 15000);
    return () => {
      clearTimeout(oneTimer);
    };
  }, [poll]);

  useEffect(() => {
    if (!poll && (beatToken || appHeartbeatToken)) {
      setBeatToken('');
      setAppHeartbeatToken('');
    }
  }, [poll]);

  const handleHeartbeatResponse = (response: TokenResponseEventResult) => {
    if (response.auth && response.auth.error) {
      const errMsg = response.auth.error.message;
      setHeartBeatError(errMsg);
      setAppHeartbeatToken('');
      setBeatToken('');
      setPoll(false);
      return;
    }
    if (response && response.turner) {
      setAppHeartbeatToken(response.turner.token);
    }
  };

  const setHeartbeat = async () => {
    try {
      fetch(
        `${tokenUrl}?assetId=${assetId}&appId=${appId}&format=json&mediaId=${mediaId}&accessToken=${
          appHeartbeatToken || beatToken
        }&accessTokenType=turner`
      ).then((response) => {
        response.json().then(handleHeartbeatResponse);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useInterval(setHeartbeat, heartbeatInterval, poll);

  return {
    heartBeatError,
    setPoll,
    setHeartBeatError,
  };
};

export default useHeartBeat;
