import React, { useRef } from 'react';
import { Text, TextStyle, ViewStyle } from 'react-native';
import { PressableWithOpacity, useGetDevice, useSpatialNavigation } from '@warnermmedia/gsp-core/sdk/ui';
import { useTheme } from 'react-native-paper';
import { ItemName } from '@warnermmedia/gsp-core/sdk/data-access';
import { getUniqueId } from '@warnermmedia/gsp-core/brands/estadio/feature';

interface ButtonProps {
  disabled?: boolean;
  disabledBtnColor?: string;
  btnColor?: string;
  onPress: () => void;
  label?: string;
  btnStyle?: ViewStyle | ViewStyle[];
  outlined?: boolean;
  style?: ViewStyle;
  labelStyle?: TextStyle;
  accessible?: boolean;
  focusKey?: string;
}

export const EstadioLinkButton = ({
  disabled,
  onPress,
  label,
  btnStyle,
  labelStyle,
  accessible = false,
  focusKey,
}: ButtonProps) => {
  const { isWeb } = useGetDevice();
  const { colors } = useTheme();
  const linkButtonFocusKey = useRef(focusKey ?? getUniqueId(ItemName.LINK_BUTTON_ITEM)).current;
  const { ref } = useSpatialNavigation({ focusKey: linkButtonFocusKey });

  return (
    <PressableWithOpacity
      style={[
        {
          backgroundColor: 'transparent',
          borderWidth: 0,
        },
        btnStyle,
      ]}
      onPress={onPress}
      disabled={disabled}
      ref={ref}
      nativeID={linkButtonFocusKey}
    >
      <Text
        accessible={accessible}
        style={[
          {
            fontFamily: 'Oswald-Regular',
            fontSize: 14,
            color: colors.keyboardFocus.light,
            textTransform: 'uppercase',
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            /* @ts-ignore: properties for correct outlining. */
            textDecorationLine: isWeb ? 'underline' : 'none',
          },
          labelStyle,
        ]}
      >
        {label}
      </Text>
    </PressableWithOpacity>
  );
};

export default EstadioLinkButton;
