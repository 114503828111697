import React from 'react';
import { Text, View } from 'react-native';
import { getStyles } from './error.styles';
import { useReactiveVar } from '@apollo/client';
import { breakpointsStateStore } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { languageStrings, useScaleSizing } from '@warnermmedia/gsp-core/brands/estadio/feature';
import { EstadioButton } from '../../components';
import { useTheme } from 'react-native-paper';
import { FocusKeys } from '@warnermmedia/gsp-core/sdk/data-access';

export interface FinalPinLoginScreenProps {
  onRetry: () => void;
}

export const ErrorPinLoginScreen = (props: FinalPinLoginScreenProps) => {
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const scaling = useScaleSizing();
  const { colors } = useTheme();
  const styles = getStyles(breakpoints, scaling, colors);

  return (
    <View style={styles.wrapper}>
      <View style={styles.textContainer}>
        <Text style={styles.titleStyle}>{languageStrings.default.pinPairErrorTitle}</Text>
        <Text style={styles.descriptionStyle}>{languageStrings.default.pinPairErrordescription}</Text>

        <EstadioButton
          onPress={props.onRetry}
          label={languageStrings.default.pinPairBack}
          btnStyle={styles.buttonStyle}
          labelStyle={styles.buttonText}
          focusKey={FocusKeys.PIN_LOGIN_ERROR}
        />
      </View>
      <View style={styles.empty} />
    </View>
  );
};

export default ErrorPinLoginScreen;
