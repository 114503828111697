import { StyleProp, Text, TextProps, TextStyle } from 'react-native';
import { useTheme } from 'react-native-paper';
import React from 'react';

interface TextContentProps extends TextProps {
  children?: React.ReactNode[] | React.ReactNode | string;
  style?: StyleProp<TextStyle>;
}

export function TextContent({ children, style, ...rest }: TextContentProps) {
  const { defaults, colors } = useTheme();
  const styles: StyleProp<TextStyle> = {
    fontSize: defaults?.fontSize ? defaults?.fontSize : 16,
    fontWeight: defaults?.fontWeight ? defaults?.fontWeight : 'normal',
    textAlign: defaults?.textAlign ? defaults?.textAlign : 'left',
    color: colors?.text ? colors?.text : '#000',
  };

  return (
    <Text style={[styles, style]} {...rest}>
      {children}
    </Text>
  );
}

export default TextContent;
