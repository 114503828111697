import React, { FC } from 'react';
import { View } from 'react-native';
import styles from './subscriptionCard.styles';
import { Product } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { ProductComponent } from '../product';
import { Payment } from '../payment';

interface SubscriptionCardProps {
  renderPayment: () => React.ReactNode;
  renderPayWithPayPal: () => React.ReactNode;
  setHasCoupon: (val: boolean) => void;
  product: Product;
  description: string | null | undefined;
  couponDeduction: string;
}

export const SubscriptionCard: FC<SubscriptionCardProps> = ({
  product,
  description,
  couponDeduction,
  renderPayment,
  renderPayWithPayPal,
  setHasCoupon,
}) => {
  const isPaypal = product && product?.isPayPalEnabled;

  return (
    <View style={styles.wrapper}>
      <ProductComponent couponDeduction={couponDeduction} description={description} product={product as Product} />
      <View style={styles.paymentWrapper}>
        <Payment
          renderPayment={renderPayment}
          setHasCoupon={setHasCoupon}
          renderPayWithPayPal={renderPayWithPayPal}
          isPaypal={isPaypal}
        />
      </View>
    </View>
  );
};

export default SubscriptionCard;
