import React, { useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';
import { getStyles } from './editAccountDetails.style';
import { countriesObj, loginAPIStateStore, Page, UserAccount } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import {
  carouselBase,
  Dalton,
  languageStrings,
  QueryNames,
  TeamPagesUrl,
  useCMSQueryDataResults,
  useCMSTeamsData,
  useMparticleCustomEventObject,
  useScrollToTop,
  useStatusMessage,
} from '@warnermmedia/gsp-core/brands/estadio/feature';
import {
  loadData,
  MParticleCustomEventTypes,
  mParticleEventProcessor,
  Preference,
  UpdateUserParams,
  User,
  UserAddressResponseEntity,
  UserContactNumberResponseEntity,
} from '@warnermmedia/gsp-core/sdk/data-access';
import {
  AccountDetailsProps,
  Breakpoints,
  CustomButton as Button,
  Datetimepicker,
  deriveDate,
  DeviceTypes,
  GenericAccountDetails,
  OverrideRenderDetail,
  validateAlphabeticCharacters,
  validateEmail,
  validateNonEmptyString,
  validatePasswordRule,
  Variant,
} from '@warnermmedia/gsp-core/sdk/ui';
import { useReactiveVar } from '@apollo/client';
import { AxiosError } from 'axios';
import { TeamListItem } from '../../../modal/favTeamModal/favTeamModal';
import { ScrollViewRefContext } from '../../../layout';
import { ChangesNotSavedModal } from '../../../modal/changesNotSavedModal';
import { PasswordHide, PasswordShow } from '@warnermmedia/gsp-core/brands/estadio/assets';
import isEqual from 'lodash/isEqual';
import { cloneDeep } from 'lodash';

export interface EditProps {
  userData: UserAccount;
  imageEditComponent?: OverrideRenderDetail;
  dateEditComponent?: OverrideRenderDetail;
  addressObj?: UserAddressResponseEntity;
  contactNumberObj?: UserContactNumberResponseEntity;
  userPreferences?: Preference[];
  toggleEditForm: () => void;
  isTveAccount: boolean;
  breakpoints: Breakpoints;
  device: DeviceTypes;
  colors: ReactNativePaper.ThemeColors;
}

export const EditAccountDetailsComponent = (props: EditProps) => {
  const styles = getStyles(props.breakpoints, props.device.isTv, props.colors);
  const dalton = Dalton();
  const { user } = useReactiveVar(loginAPIStateStore);
  const { pageData: pageUriData } = useCMSQueryDataResults<Page>({
    queryName: QueryNames.GET_TEAMS,
    uri: TeamPagesUrl.TeamsList,
  });
  const { formatTeamData, formatTeamItem } = useCMSTeamsData();
  const [teams, setTeams] = useState<TeamListItem[]>([]);
  const [rawTeamsObj, setRawTeamsObj] = useState<carouselBase[]>([]);
  const [userData, setData] = useState(props.userData);
  const [emailList] = useState(user?.userEmailResponses);
  const [birthdate, setBirthdate] = useState<string>('');
  const { setStatusMessage } = useStatusMessage();
  const { scrollToTop } = useScrollToTop(ScrollViewRefContext);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [userError, setError] = useState({
    id: false,
    firstName: false,
    lastName: false,
    gender: false,
    birthdate: false,
    email: false,
    phone: false,
    countryOfResidence: false,
    alternativeEmail: false,
    password: false,
    passwordConfirmation: false,
    rut: false,
    favoriteTeamLogo: false,
  });

  const language = languageStrings.default;
  const [errorMsg, setErrorMsg] = useState({
    id: '',
    firstName: language.firstNameErrorMsg,
    lastName: language.lastNameErrorMsg,
    gender: '',
    birthdate: language.birthdateErrorMsg,
    email: '',
    phone: language.phoneErrorMsg,
    countryOfResidence: '',
    alternativeEmail: language.alternativeEmailErrorMsg,
    password: language.passwordErrorMsg,
    passwordConfirmation: language.passwordConfirmationErrorMsg,
    rut: language.rutErrorMsg,
    favoriteTeamLogo: '',
    onlyAlphabeticCharactersError: language.onlyAlphabeticCharactersError,
  });
  const [isPasswordIconClicked, setIsPasswordIconClicked] = useState({
    password: false,
    passwordConfirmation: false,
  });
  const mParticleEventData = useMparticleCustomEventObject();
  const { toggleEditForm } = props;

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    setData(props.userData);
  }, []);

  useEffect(() => {
    setBirthdate(props.userData.birthdate);
  }, [props.userData.birthdate]);

  useEffect(() => {
    if (pageUriData && pageUriData.components) {
      const teamsData = formatTeamData(pageUriData.components);
      if (teamsData && teamsData.length > 0) {
        const teams = formatTeamItem(teamsData);
        loginAPIStateStore({ ...loginAPIStateStore(), ...{ competitors: cloneDeep(teamsData) } });
        setTeams(teams);
        setRawTeamsObj(teamsData);
      }
    }
  }, [pageUriData]);

  const onChange = useCallback((value: string, label: string) => {
    setData((prevState) => ({
      ...prevState,
      [label]: value,
    }));
  }, []);

  const handleSetError = (label: string, value: boolean) => {
    setError((prevState) => ({
      ...prevState,
      [label]: value,
    }));
  };

  const handlePasswordIconClick = useCallback((label: string, value: boolean) => {
    setIsPasswordIconClicked((prevState) => ({
      ...prevState,
      [label]: value,
    }));
  }, []);

  const pushMparticleEvents = () => {
    const favoriteTeam = userData['favoriteTeam'] || '';
    const hasUpdatedPassword = !!userData['passwordConfirmation'];
    mParticleEventProcessor.pushMParticleEvent(
      [
        MParticleCustomEventTypes.AccountUpdateEvent,
        ...(favoriteTeam ? [MParticleCustomEventTypes.FavoriteTeamSelectEvent] : []),
        ...(hasUpdatedPassword ? [MParticleCustomEventTypes.PasswordUpdateEvent] : []),
      ],
      {
        ...mParticleEventData,
        ...(favoriteTeam ? { favorite_team: favoriteTeam } : {}),
      }
    );
  };

  const runRequiredValidation = () => {
    const firstName = !validateNonEmptyString(userData.firstName) || !validateAlphabeticCharacters(userData.firstName);
    const lastName = !validateNonEmptyString(userData.lastName) || !validateAlphabeticCharacters(userData.lastName);
    let password = false;
    let passwordConfirmation = false;
    if (userData.password || userData.passwordConfirmation) {
      password = !validatePasswordRule(userData.password);
      passwordConfirmation = !confirmPassword(userData.passwordConfirmation as string);
    }

    setError((prevData) => ({
      ...prevData,
      firstName,
      lastName,
      password,
      passwordConfirmation,
    }));
    return {
      firstName,
      lastName,
      password,
      passwordConfirmation,
    };
  };

  const getUserPreferences = (userPreferences: Preference[] = []): Preference[] => {
    const profilePreferences: Preference[] = [];
    ['rut', 'gender', 'favoriteTeam'].forEach((item) => {
      const itemAsKey = item as keyof UserAccount;
      if (userData[itemAsKey]) {
        const selectionItem = userData[itemAsKey] as string;
        if (item === 'favoriteTeam') {
          const teamObj = rawTeamsObj.find((teamData) => teamData.title === userData[itemAsKey]);
          const favoriteTeamId = teamObj?.sportRadarId as string;
          profilePreferences.push({ collection: 'favoriteTeamId', selections: [favoriteTeamId] });
        }
        profilePreferences.push({
          collection: item,
          selections: [selectionItem],
        });
      }
    });
    const keysToFilter = profilePreferences.map((item) => item.collection);
    return [...userPreferences.filter((item) => !keysToFilter.includes(item.collection)), ...profilePreferences];
  };

  const handleEditPersonalDetails = async () => {
    setIsSubmitting(true);
    const formHasError = Object.values(userError).includes(true);
    const requiredValidation = Object.values(runRequiredValidation()).includes(true);
    const birthdateError = Object.values(userError.birthdate).includes(true);

    if (formHasError || requiredValidation || birthdateError) {
      setIsSubmitting(false);
      setStatusMessage({
        message: language.editFormError2,
        type: Variant.Error,
      });
      scrollToTop();
      return;
    }

    const userObj: UpdateUserParams = {
      firstName: userData.firstName,
      lastName: userData.lastName,
    };

    if (birthdate) {
      const stripped = birthdate.split('/');
      userObj.birthdate = stripped.length === 3 ? `${stripped[2]}${stripped[1]}${stripped[0]}` : birthdate;
    }

    if (userData?.alternativeEmail) {
      userObj.saveEmailAddresses = [
        {
          emailAddress: userData.alternativeEmail,
          primary: false,
          onHold: false,
        },
      ];
    }

    if (props.userData.alternativeEmail && props.userData.alternativeEmail !== userData.alternativeEmail) {
      const filteredList = emailList?.filter((el) => {
        return el.primary !== true;
      });
      userObj.deleteEmailAddresses = filteredList;
    }

    if (userData.countryOfResidence && !props?.userData?.countryOfResidence) {
      userObj.addresses = [
        {
          country: userData.countryOfResidence,
          description: 'Home',
        },
      ];
    }

    if (userData.countryOfResidence && props?.userData?.countryOfResidence) {
      userObj.addresses = [
        {
          country: userData.countryOfResidence,
          description: 'Home',
          addressId: props.addressObj?.id,
        },
      ];
    }

    if (!userData.password) {
      delete userObj.password;
    }

    const contactNumberObj = userData.phone
      ? {
          id: props.contactNumberObj?.id,
          contactType: 'Mobile',
          contactNumber: userData.phone,
        }
      : null;

    const profileRes = await dalton.updateUser(userObj);

    const userProfilePreferences =
      props.userPreferences && Object.keys(userData).length ? getUserPreferences(props.userPreferences) : [];
    let preferenceRes;
    if (userProfilePreferences.length) {
      preferenceRes = await dalton.updateUserPreferences({ preferences: userProfilePreferences });
    }
    let passwordRes;
    if (userData.password) {
      passwordRes = await dalton.savePassword(
        userData.password as string,
        userData.password,
        loadData('DALTON_AUTH_TOKEN').token
      );
    }
    let contactRes;
    let newContact;
    if (contactNumberObj) {
      const userContactNumberResponses = loginAPIStateStore().user?.userContactNumberResponses;
      if (userContactNumberResponses && userContactNumberResponses?.length >= 1) {
        await Promise.all(
          (userContactNumberResponses || []).map((contact) => dalton.removeUserContactNumber(contact.id))
        );
      }

      contactRes = await dalton.updateUserContactNumber(contactNumberObj);
      newContact = contactRes;
    }

    let user;
    let contact;

    if (profileRes.success) {
      user = profileRes.data as User;
      loginAPIStateStore({
        ...loginAPIStateStore(),
        ...{ user },
      });
      pushMparticleEvents();
    }

    if (contactRes && contactRes.success && user) {
      contact = newContact?.data as UserContactNumberResponseEntity;
      user.userContactNumberResponses = [contact];
      loginAPIStateStore({
        ...loginAPIStateStore(),
        ...{ user: { ...user } },
      });
    }

    if (preferenceRes && preferenceRes.success) {
      loginAPIStateStore({
        ...loginAPIStateStore(),
        ...{ preferences: userProfilePreferences },
      });
    }

    if (
      profileRes.success &&
      (!preferenceRes || preferenceRes.success) &&
      (!contactRes || contactRes?.success) &&
      (!passwordRes || passwordRes.success)
    ) {
      // show success message on the layout components

      scrollToTop();
      setIsSubmitting(false);
      setStatusMessage({ message: language.editProfileSuccessMessage, type: Variant.Success });
      props.toggleEditForm();
    } else {
      let errMsg = language.saveDetailsError;
      /* eslint-disable @typescript-eslint/no-explicit-any */
      const errObj = (profileRes.data as unknown) as AxiosError<any>;
      const errArr = errObj?.response?.data.errors;
      if (errArr && errArr[0].error === 'email.exists') {
        errMsg += language.saveEmailError;
      }
      if (preferenceRes && !preferenceRes.success) {
        errMsg += language.savePreferenceError;
      }
      if (contactRes && !contactRes.success) {
        errMsg += language.saveContactError;
      }
      if (contactRes && !contactRes.success && preferenceRes && !preferenceRes.success) {
        errMsg += language.profileError;
      }
      if (passwordRes && !passwordRes.success) {
        errMsg += language.savePasswordError;
      }
      setStatusMessage({ message: errMsg, type: Variant.Error });
      scrollToTop();
      setIsSubmitting(false);
    }
  };

  const confirmPassword = (cpassword: string) => {
    return cpassword === userData.password;
  };

  const validateAltEmail = (altEmail: string) => {
    if (!altEmail && !props.userData.alternativeEmail) {
      return true;
    }
    return validateEmail(altEmail) && altEmail !== userData.email;
  };

  const validatePhone = (phone: string) => {
    if (!phone && !props.userData.phone) {
      return true;
    }
    const strippedNumber = phone.replace(/-/g, '');
    return /^\d{9}$/.test(strippedNumber);
  };

  const validateRUT = (rut: string) => {
    if (!rut) {
      return true;
    }
    const strippedNumber = rut.replace(/[-.]/g, '');
    return /^\d{9}$/.test(strippedNumber);
  };

  const validateOnlyAlphabeticCharacters = (value: string) => {
    if (!value) {
      return false;
    }
    return validateAlphabeticCharacters(value);
  };

  const onSelectDate = useCallback(
    (value: string, isValid: boolean) => {
      setBirthdate(value);
      if (!value && !props.userData.birthdate) {
        setError((prevState) => ({
          ...prevState,
          birthdate: false,
        }));
      } else {
        setError((prevState) => ({
          ...prevState,
          birthdate: !isValid,
        }));
      }
    },
    [props.userData.birthdate]
  );

  const formatBirthdate = useCallback((): string => {
    let date = '';
    if (props.userData.birthdate) {
      const { year, month, day } = deriveDate(props.userData.birthdate);
      date = `${day}/${month}/${year}`;
    }
    return date;
  }, [props.userData.birthdate]);

  const renderDateComponents: OverrideRenderDetail = () => {
    return (
      <View style={styles.dateWrapper}>
        <Datetimepicker
          date={formatBirthdate()}
          errorMessage={errorMsg.birthdate}
          validRange={{
            endDate: new Date(),
          }}
          modalInputEnabled={false}
          editable={!isSubmitting}
          focusable={!isSubmitting}
          dateFormat="DD/MM/YYYY"
          placeholder="DD/MM/YYYY"
          containerStyle={styles.dateInput}
          inputStyle={[birthdate.length <= 0 ? styles.dateTextInput : styles.dateTextInputFilled]}
          onSelect={onSelectDate}
          shouldUseFourArrow={true}
        />
      </View>
    );
  };

  const handleCancelBtnPress = useCallback(() => {
    if (!isEqual(props.userData, userData) || (!!props.userData.birthdate && birthdate !== props.userData.birthdate)) {
      setHasUnsavedChanges(true);
    } else {
      toggleEditForm();
    }
  }, [props.userData, userData, birthdate, toggleEditForm]);

  const handleDiscard = useCallback(() => {
    toggleEditForm();
  }, [toggleEditForm]);

  const handleContinueEditing = useCallback(() => {
    setHasUnsavedChanges(false);
  }, []);

  const accountProps: AccountDetailsProps<UserAccount> = {
    details: userData,
    title: language.accountInfoTitle,
    subTitle: language.editAccountSubTitle,
    detailsOptions: {
      id: {
        label: '',
        type: 'string',
        isHidden: true,
      },
      firstName: {
        label: language.firstName,
        type: 'string',
        setError: handleSetError,
        hasError: userError.firstName,
        isRequired: true,
        onChange,
        errorMessage: !userData.firstName ? errorMsg.firstName : errorMsg.onlyAlphabeticCharactersError,
        validator: validateOnlyAlphabeticCharacters,
        isHidden: props.isTveAccount,
      },
      lastName: {
        label: language.lastName,
        type: 'string',
        setError: handleSetError,
        hasError: userError.lastName,
        isRequired: true,
        onChange,
        errorMessage: !userData.lastName ? errorMsg.lastName : errorMsg.onlyAlphabeticCharactersError,
        validator: validateOnlyAlphabeticCharacters,
        isHidden: props.isTveAccount,
      },
      email: {
        label: 'Email',
        type: 'string',
        readOnly: true,
        overrideStyle: {
          color: props.colors.tenantBackground.light.surfaceBase,
          backgroundColor: 'transparent',
        },
        isHidden: props.isTveAccount,
      },
      gender: {
        label: language.genderText,
        type: 'radio',
        valueOptions: [language.genderFemaleText, language.genderMaleText],
        setError: handleSetError,
        hasError: userError.gender,
        overrideStyle: {
          width: 100,
          color: props.colors.tenantBackground.light.surfaceBase,
        },
        onChange,
        errorMessage: errorMsg.gender,
        isHidden: props.isTveAccount,
      },
      birthdate: {
        label: language.birthdateText,
        type: 'date',
        setError: handleSetError,
        overrideRenderDetail: props.dateEditComponent ? props.dateEditComponent : renderDateComponents,
        onChange,
        isHidden: props.isTveAccount,
      },
      rut: {
        label: 'RUT',
        type: 'string',
        setError: handleSetError,
        hasError: userError.rut,
        onChange,
        isRequired: validateNonEmptyString(userData.rut),
        errorMessage: errorMsg.rut,
        validator: validateRUT,
        isHidden: props.isTveAccount,
      },
      countryOfResidence: {
        label: language.countryText,
        type: 'select',
        setError: handleSetError,
        hasError: userError.countryOfResidence,
        onChange,
        valueOptions: countriesObj,
        errorMessage: errorMsg.countryOfResidence,
        isHidden: props.isTveAccount,
      },
      alternativeEmail: {
        label: language.alternativeEmail,
        type: 'string',
        setError: handleSetError,
        hasError: userError.alternativeEmail,
        isRequired: !!userData.alternativeEmail,
        errorMessage: errorMsg.alternativeEmail,
        validator: validateAltEmail,
        onChange,
        isHidden: props.isTveAccount,
      },
      phone: {
        label: language.phone,
        type: 'phone',
        setError: handleSetError,
        hasError: userError.phone,
        isRequired: !!userData.phone,
        onChange,
        errorMessage: errorMsg.phone,
        validator: validatePhone,
        isHidden: props.isTveAccount,
      },
      password: {
        label: language.password,
        type: 'password',
        setError: handleSetError,
        hasError: userError.password,
        isRequired: !!userData.password || !!userData.passwordConfirmation,
        validator: (password: string) => {
          if (!password) {
            return true;
          }
          return validatePasswordRule(userData.password);
        },
        onChange,
        errorMessage: errorMsg.password,
        isHidden: props.isTveAccount,
      },
      passwordConfirmation: {
        label: language.confirmPassword,
        type: 'password',
        setError: handleSetError,
        hasError: userError.passwordConfirmation,
        isRequired: validateNonEmptyString(userData.password as string) || !!userData.passwordConfirmation,
        validator: confirmPassword,
        onChange,
        errorMessage: errorMsg.passwordConfirmation,
        isHidden: props.isTveAccount,
      },
      favoriteTeam: {
        label: language.favoriteTeamLabel,
        type: 'select',
        overrideRenderDetail: props.imageEditComponent,
        onChange,
        valueOptions: teams,
        errorMessage: errorMsg.favoriteTeamLogo,
      },
    },
    actionBtnText: isSubmitting ? language.loadingText : language.editAccountSubmit,
    handleActionBtnPress: handleEditPersonalDetails,
    editable: true,
    disabled: isSubmitting,
  };

  return (
    <View style={styles.wrapper}>
      <GenericAccountDetails
        {...accountProps}
        detailsStyle={styles.detailsStyle}
        wrapperStyle={styles.accountWrapperStyle}
        detailStyle={styles.detail}
        titleStyle={styles.titleStyle}
        detailLabelStyle={styles.detailLabel}
        detailValueStyle={styles.detailValue}
        subTitleStyle={styles.subTitle}
        actionBtnStyle={styles.actionBtn}
        actionBtnTextStyle={styles.actionBtnText}
        passwordHideIcon={<PasswordHide />}
        passwordShowIcon={<PasswordShow />}
        handlePasswordIconClick={handlePasswordIconClick}
        isPasswordIconClicked={isPasswordIconClicked}
      />
      <View style={[styles.cancelBtnWrapper]}>
        <Button
          btnStyle={styles.cancelBtn}
          mode="text"
          label={language.cancel}
          labelStyle={styles.cancelBtnText}
          onPress={handleCancelBtnPress}
        />
      </View>

      <ChangesNotSavedModal
        visible={hasUnsavedChanges}
        onEditing={handleContinueEditing}
        onDiscard={handleDiscard}
        title={languageStrings.default.changesNotSavedModalTitle}
        description={languageStrings.default.changesNotSavedModalDescription}
        editingLabel={languageStrings.default.changesNotSavedModalEditBtn}
        discardLabel={languageStrings.default.changesNotSavedModalDiscardBtn}
        colors={props.colors}
        breakpoints={props.breakpoints}
      />
    </View>
  );
};

export default EditAccountDetailsComponent;
