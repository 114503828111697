import { AppConfigContext, breakpointsStateStore } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { languageStrings } from '@warnermmedia/gsp-core/brands/estadio/feature';
import React, { useContext } from 'react';
import { Linking, Text, TextStyle } from 'react-native';
import { getStyles } from './consentMessage.styles';
import { useReactiveVar } from '@apollo/client';
import { useTheme } from 'react-native-paper';
interface ConsentMessageProps {
  formName: string;
  textStyle?: TextStyle;
}

export const ConsentMessage = ({ formName, textStyle }: ConsentMessageProps) => {
  const appConfig = useContext(AppConfigContext);
  const terms = appConfig?.appConfig.vindicia.terms as string;
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const { colors } = useTheme();
  const styles = getStyles(breakpoints, colors);
  const termsAndCondition = (
    <Text
      style={styles.link}
      onPress={() => {
        Linking.openURL(terms);
      }}
    >
      {languageStrings.default.termsAndConditionLabel}
    </Text>
  );

  const privacyPolicies = (
    <Text
      style={styles.link}
      onPress={() => {
        Linking.openURL('https://www.warnermediaprivacy.com/policycenter/b2c/es-row/');
      }}
    >
      {languageStrings.default.privacyPoliciesLabel}
    </Text>
  );

  return (
    <Text style={[styles.consent, textStyle]}>
      {languageStrings.default.consentMessageLine1} {termsAndCondition} {languageStrings.default.consentMessageLine2}
      {privacyPolicies} {languageStrings.default.consentMessageLine3} {privacyPolicies}.
    </Text>
  );
};

export default ConsentMessage;
