import merge from 'lodash/merge';
import { ImageStyle, StyleProp, StyleSheet, TextStyle, ViewStyle } from 'react-native';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { BreakpointsSize, ScaleAction } from '@warnermmedia/gsp-core/brands/estadio/feature';

interface Styles {
  pageContents: ViewStyle;
  left: ViewStyle;
  topMarketingImage: ImageStyle;
  bottomMarketingImage: ImageStyle;
  disclaimerText: TextStyle;
  right: ViewStyle;
  headerViewStyle: ViewStyle;
  header1TextStyle: TextStyle;
  header2TextStyle: TextStyle;
  subscribeViewStyle: ViewStyle;
  subscribeShadowStyle: ViewStyle;
  subscribeLargeTextStyle: TextStyle;
  subscribeSmallTextStyle: TextStyle;
  landingImageList: ViewStyle;
  maxImage: ImageStyle;
  maxMobileImage: ImageStyle;
}

export const getStyles = (
  breakpoints: Breakpoints,
  colors: ReactNativePaper.ThemeColors,
  isRegisterEnabled: boolean,
  isTv: boolean,
  scaling: ScaleAction
) => {
  const { scaleHeight, scaleWidth } = scaling;
  const base: StyleProp<Styles> = {
    pageContents: {
      flex: 1,
      marginBottom: 80,
      paddingHorizontal: 25,
      paddingTop: 24,
      alignSelf: 'center',
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    left: {
      width: '100%',
      height: '100%',
      marginBottom: 81,
      maxWidth: isRegisterEnabled ? 455 : 540,
    },
    landingImageList: {
      marginTop: 32,
      marginBottom: 16,
    },
    topMarketingImage: {
      width: '100%',
      aspectRatio: 4 / 1,
      marginBottom: 16,
    },
    bottomMarketingImage: {
      width: '100%',
      aspectRatio: 16 / 9,
    },
    disclaimerText: {
      fontFamily: 'Rubik-Regular',
      fontSize: 14,
      lineHeight: 16,
      fontWeight: '400',
      color: colors.keyboardFocus.light,
      textAlign: 'center',
    },
    right: {
      maxWidth: 455,
    },
    headerViewStyle: {
      paddingBottom: 32,
    },
    header1TextStyle: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Oswald-SemiBold',
      fontSize: 33,
      fontWeight: '600',
      textTransform: 'uppercase',
      width: '80%',
    },
    header2TextStyle: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Oswald-Regular',
      fontSize: 33,
      textTransform: 'uppercase',
    },
    subscribeViewStyle: {
      alignItems: 'flex-end',
      backgroundColor: '#1cdcac',
      borderRadius: 4,
      flexDirection: 'row',
      marginBottom: 16,
      padding: 16,
    },
    subscribeShadowStyle: {
      shadowColor: colors.stroke.onLight.outline01,
      shadowOffset: { width: 2, height: 4 },
      shadowOpacity: 0.5,
      shadowRadius: 4,
      elevation: 5,
    },
    subscribeLargeTextStyle: {
      color: colors.tenantBackground.dark.surface03,
      fontFamily: 'Oswald-SemiBold',
      fontSize: 33,
      fontWeight: '600',
      lineHeight: 44,
      textTransform: 'uppercase',
    },
    subscribeSmallTextStyle: {
      color: colors.tenantBackground.dark.surface03,
      fontFamily: 'Oswald-Regular',
      paddingBottom: 5,
      paddingLeft: 16,
      textTransform: 'uppercase',
    },
    maxImage: {
      width: '100%',
      ...(isTv ? {} : { aspectRatio: 16 / 9 }),
    },
    maxMobileImage: {
      width: '100%',
      aspectRatio: 9 / 16,
    },
  };

  const mediaQueries = isTv
    ? ({
        pageContents: {
          flexDirection: 'column',
        },
        left: {
          maxWidth: 960,
          width: '100%',
          flexShrink: 0,
          height: '100%',
        },
        right: {
          marginLeft: 105,
          minWidth: 328,
          maxWidth: 328,
        },
        topMarketingImage: {
          width: '100%',
          maxHeight: 240,
        },
        bottomMarketingImage: {
          width: '100%',
          maxHeight: 540,
        },
        headerViewStyle: {
          paddingTop: 26,
        },
        maxImage: {
          width: scaleWidth(1280, BreakpointsSize.Tv),
          height: scaleHeight(720, BreakpointsSize.Tv),
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isLgXl ||
      breakpoints.currentBreakpoints.isXlXxl ||
      breakpoints.currentBreakpoints.isXxl
    ? ({
        pageContents: {
          flexDirection: isRegisterEnabled ? 'row' : 'column',
        },
        left: {
          maxWidth: isRegisterEnabled ? 591 : 960,
          width: isRegisterEnabled ? '52%' : '100%',
          flexShrink: isRegisterEnabled ? 1 : 0,
          height: '100%',
        },
        right: {
          marginLeft: 105,
          minWidth: 328,
          maxWidth: 328,
        },
        topMarketingImage: {
          width: '100%',
          maxHeight: isRegisterEnabled ? 148 : 240,
        },
        bottomMarketingImage: {
          width: '100%',
          maxHeight: isRegisterEnabled ? 333 : 540,
        },
        headerViewStyle: {
          paddingTop: 26,
        },
      } as StyleProp<Styles>)
    : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
