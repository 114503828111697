import { AppConfigContext, errorMsgStore, loginAPIStateStore } from '@warnermmedia/gsp-core/brands/estadio/data-access';

import { useContext } from 'react';
import { languageStrings } from '../../../index';
import { useInitVideo, UseVideoResponse } from '@warnermmedia/gsp-core/sdk/data-access';
import get from 'lodash/get';

interface UseVideoParams {
  mediaId: string;
  token: string;
  isFreeVideo?: boolean;
}
export const useVideo = ({ mediaId, token, isFreeVideo = false }: UseVideoParams): UseVideoResponse => {
  const appConfig = useContext(AppConfigContext);
  const tveConfig = appConfig?.appConfig.tve;
  const tenant = get(tveConfig, 'tenant', 'estadiochile');

  return useInitVideo({
    mediaId,
    token,
    errorMsgStore,
    appConfig,
    isTveAuth: loginAPIStateStore().isTveAuth,
    tenant,
    languageStrings,
    isFreeVideo,
  });
};
