import { StyleProp, ViewStyle, StyleSheet } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';
import merge from 'lodash/merge';

interface Styles {
  forwardArrow: ViewStyle;
  backArrow: ViewStyle;
  forwardArrowGrad: ViewStyle;
  backArrowGrad: ViewStyle;
  carouselStyle: ViewStyle;
}

export const getStyles = (breakpoints: Breakpoints, isTv: boolean) => {
  const base: StyleProp<Styles> = {
    forwardArrow: {
      right: 0,
      paddingRight: 0,
      paddingLeft: 0,
    },
    backArrow: {
      left: 0,
      paddingRight: 0,
      paddingLeft: 0,
    },
    forwardArrowGrad: {
      height: '100%',
      paddingHorizontal: 9,
      paddingVertical: 45,
    },
    backArrowGrad: {
      height: '100%',
      paddingHorizontal: 9,
      paddingVertical: 45,
    },
    carouselStyle: {
      paddingLeft: 7,
      paddingRight: 0,
    },
  };

  const mediaQueries = isTv
    ? ({} as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isTiny || breakpoints.currentBreakpoints.isTnySm
    ? ({} as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isSmMed
    ? ({} as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isMedLg || breakpoints.windowWidth === breakpoints.breakpointSizes.lg
    ? ({} as StyleProp<Styles>)
    : {};
  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
