// source: https://www.nationsonline.org/oneworld/countrynames_spanish.htm

import { currentLocale } from '../lib/I18n';

export const countriesWithCode = [
  { name: 'Afganistán', english: 'Afghanistan', code: 'AF' },
  { name: 'Albania', english: 'Albania', code: 'AL' },
  { name: 'Argelia', english: 'Algeria', code: 'DZ' },
  { name: 'Samoa Americana', english: 'American Samoa', code: 'AS' },
  { name: 'Andorra', english: 'AndorrA', code: 'AD' },
  { name: 'Angola', english: 'Angola', code: 'AO' },
  { name: 'Anguilla', english: 'Anguilla', code: 'AI' },
  { name: 'Antártida', english: 'Antarctica', code: 'AQ' },
  { name: 'Antigua y Barbuda', english: 'Antigua and Barbuda', code: 'AG' },
  { name: 'Argentina', english: 'Argentina', code: 'AR' },
  { name: 'Armenia', english: 'Armenia', code: 'AM' },
  { name: 'Aruba', english: 'Aruba', code: 'AW' },
  { name: 'Australia', english: 'Australia', code: 'AU' },
  { name: 'Austria', english: 'Austria', code: 'AT' },
  { name: 'Azerbaiyán', english: 'Azerbaijan', code: 'AZ' },
  { name: 'Bahamas', english: 'Bahamas', code: 'BS' },
  { name: 'Bahrein', english: 'Bahrain', code: 'BH' },
  { name: 'Bangladesh', english: 'Bangladesh', code: 'BD' },
  { name: 'Barbados', english: 'Barbados', code: 'BB' },
  { name: 'Bielorrusia', english: 'Belarus', code: 'BY' },
  { name: 'Bélgica', english: 'Belgium', code: 'BE' },
  { name: 'Belice', english: 'Belize', code: 'BZ' },
  { name: 'Benín', english: 'Benin', code: 'BJ' },
  { name: 'Bermudas', english: 'Bermuda', code: 'BM' },
  { name: 'Bután', english: 'Bhutan', code: 'BT' },
  { name: 'Bolivia', english: 'Bolivia', code: 'BO' },
  { name: 'Bosnia-Herzegovina', english: 'Bosnia and Herzegovina', code: 'BA' },
  { name: 'Botsuana', english: 'Botswana', code: 'BW' },
  { name: 'Brasil', english: 'Brazil', code: 'BR' },
  { name: 'Brunei Darussalam', english: 'Brunei Darussalam', code: 'BN' },
  { name: 'Bulgaria', english: 'Bulgaria', code: 'BG' },
  { name: 'Burkina Faso', english: 'Burkina Faso', code: 'BF' },
  { name: 'Burundi', english: 'Burundi', code: 'BI' },
  { name: 'Camboya', english: 'Cambodia', code: 'KH' },
  { name: 'Camerún', english: 'Cameroon', code: 'CM' },
  { name: 'Canadá', english: 'Canada', code: 'CA' },
  { name: 'Cabo Verde', english: 'Cape Verde', code: 'CV' },
  { name: 'Islas Caimán', english: 'Cayman Islands', code: 'KY' },
  { name: 'República Centroafricana', english: 'Central African Republic', code: 'CF' },
  { name: 'Chad', english: 'Chad', code: 'TD' },
  { name: 'Chile', english: 'Chile', code: 'CL' },
  { name: 'China', english: 'China', code: 'CN' },
  { name: 'Isla de Navidad, Isla Christmas', english: 'Christmas Island', code: 'CX' },
  { name: 'Islas Cocos', english: 'Cocos (Keeling) Islands', code: 'CC' },
  { name: 'Colombia', english: 'Colombia', code: 'CO' },
  { name: 'Comores', english: 'Comoros', code: 'KM' },
  { name: 'República Democrática del Congo', english: 'Democratic Republic of the Congo (Kinshasa)', code: 'CD' },
  { name: 'República del Congo', english: 'Congo, Republic of (Brazzaville)', code: 'CG' },
  { name: 'Islas Cook', english: 'Cook Islands', code: 'CK' },
  { name: 'Costa Rica', english: 'Costa Rica', code: 'CR' },
  { name: 'Costa de Marfil', english: `Ivory Coast (Cote d'Ivoire)`, code: 'CI' },
  { name: 'Croacia', english: 'Croatia', code: 'HR' },
  { name: 'Cuba', english: 'Cuba', code: 'CU' },
  { name: 'Chipre', english: 'Cyprus', code: 'CY' },
  { name: 'República Checa', english: 'Czech Republic', code: 'CZ' },
  { name: 'Dinamarca', english: 'Denmark', code: 'DK' },
  { name: 'Djibouti, Yibuti', english: 'Djibouti', code: 'DJ' },
  { name: 'Dominica', english: 'Dominica', code: 'DM' },
  { name: 'Dominicana, República', english: 'Dominican Republic', code: 'DO' },
  { name: 'Timor Oriental', english: 'East Timor (Timor-Leste)', code: 'TP' },
  { name: 'Ecuador', english: 'Ecuador', code: 'EC' },
  { name: 'Egipto', english: 'Egypt', code: 'EG' },
  { name: 'El Salvador', english: 'El Salvador', code: 'SV' },
  { name: 'Guinea Ecuatorial', english: 'Equatorial Guinea', code: 'GQ' },
  { name: 'Eritrea', english: 'Eritrea', code: 'ER' },
  { name: 'Estonia', english: 'Estonia', code: 'EE' },
  { name: 'Etiopía', english: 'Ethiopia', code: 'ET' },
  { name: 'Islas Malvinas', english: 'Falkland Islands', code: 'FK' },
  { name: 'Islas Feroe', english: 'Faroe Islands', code: 'FO' },
  { name: 'Fiyi', english: 'Fiji', code: 'FJ' },
  { name: 'Finlandia', english: 'Finland', code: 'FI' },
  { name: 'Francia', english: 'France', code: 'FR' },
  { name: 'Guayana Francesa', english: 'French Guiana', code: 'GF' },
  { name: 'Polinesia Francesa', english: 'French Polynesia', code: 'PF' },
  { name: 'Tierras Australes y Antárticas Francesas', english: 'French Southern Territories', code: 'TF' },
  { name: 'Gabón', english: 'Gabon', code: 'GA' },
  { name: 'Gambia', english: 'Gambia', code: 'GM' },
  { name: 'Georgia', english: 'Georgia', code: 'GE' },
  { name: 'Alemania', english: 'Germany', code: 'DE' },
  { name: 'Ghana', english: 'Ghana', code: 'GH' },
  { name: 'Gibraltar', english: 'Gibraltar', code: 'GI' },
  { name: 'Grecia', english: 'Greece', code: 'GR' },
  { name: 'Groenlandia', english: 'Greenland', code: 'GL' },
  { name: 'Granada', english: 'Grenada', code: 'GD' },
  { name: 'Guadalupe', english: 'Guadeloupe', code: 'GP' },
  { name: 'Guam', english: 'Guam', code: 'GU' },
  { name: 'Guatemala', english: 'Guatemala', code: 'GT' },
  { name: 'República Guinea', english: 'Guinea', code: 'GN' },
  { name: 'Guinea Bissau', english: 'Guinea-Bissau', code: 'GW' },
  { name: 'Guyana', english: 'Guyana', code: 'GY' },
  { name: 'Haiti', english: 'Haiti', code: 'HT' },
  { name: 'Honduras', english: 'Honduras', code: 'HN' },
  { name: 'Hong Kong', english: 'Hong Kong', code: 'HK' },
  { name: 'Hungría', english: 'Hungary', code: 'HU' },
  { name: 'Islandia', english: 'Iceland', code: 'IS' },
  { name: 'India', english: 'India', code: 'IN' },
  { name: 'Indonesia', english: 'Indonesia', code: 'ID' },
  { name: 'Irán', english: 'Iran (Islamic Republic of)', code: 'IR' },
  { name: 'Iraq', english: 'Iraq', code: 'IQ' },
  { name: 'Irlanda', english: 'Ireland', code: 'IE' },
  { name: 'Israel', english: 'Israel', code: 'IL' },
  { name: 'Italia', english: 'Italy', code: 'IT' },
  { name: 'Jamaica', english: 'Jamaica', code: 'JM' },
  { name: 'Japón', english: 'Japan', code: 'JP' },
  { name: 'Jordania', english: 'Jordan', code: 'JO' },
  { name: 'Kazajstán', english: 'Kazakhstan', code: 'KZ' },
  { name: 'Kenia', english: 'Kenya', code: 'KE' },
  { name: 'Kiribati', english: 'Kiribati', code: 'KI' },
  { name: 'Corea del Norte', english: "Korea, Democratic People's Rep. (North Korea)", code: 'KP' },
  { name: 'Corea del Sur', english: 'Korea, Republic of (South Korea)', code: 'KR' },
  { name: 'Kosovo', english: 'Kosovo', code: 'XK' },
  { name: 'Kuwait', english: 'Kuwait', code: 'KW' },
  { name: 'Kirguistán', english: 'Kyrgyzstan', code: 'KG' },
  {
    name: 'Laos; oficialmente: República Democrática Popular Lao',
    english: "Lao, People's Democratic Republic",
    code: 'LA',
  },
  { name: 'Letonia', english: 'Latvia', code: 'LV' },
  { name: 'Líbano', english: 'Lebanon', code: 'LB' },
  { name: 'Lesotho', english: 'Lesotho', code: 'LS' },
  { name: 'Liberia', english: 'Liberia', code: 'LR' },
  { name: 'Libia', english: 'Libya', code: 'LY' },
  { name: 'Liechtenstein', english: 'Liechtenstein', code: 'LI' },
  { name: 'Lituania', english: 'Lithuania', code: 'LT' },
  { name: 'Luxemburgo', english: 'Luxembourg', code: 'LU' },
  { name: 'Macao', english: 'Macao', code: 'MO' },
  { name: 'Macedonia del Norte', english: 'North Macedonia', code: 'MK' },
  { name: 'Madagascar', english: 'Madagascar', code: 'MG' },
  { name: 'Malawi', english: 'Malawi', code: 'MW' },
  { name: 'Malasia', english: 'Malaysia', code: 'MY' },
  { name: 'Maldivas', english: 'Maldives', code: 'MV' },
  { name: 'Malí', english: 'Mali', code: 'ML' },
  { name: 'Malta', english: 'Malta', code: 'MT' },
  { name: 'Islas Marshall', english: 'Marshall Islands', code: 'MH' },
  { name: 'Martinica', english: 'Martinique', code: 'MQ' },
  { name: 'Mauritania', english: 'Mauritania', code: 'MR' },
  { name: 'Mauricio', english: 'Mauritius', code: 'MU' },
  { name: 'Mayotte', english: 'Mayotte', code: 'YT' },
  { name: 'México', english: 'Mexico', code: 'MX' },
  { name: 'Micronesia, Estados Federados de', english: 'Micronesia, Federated States of', code: 'FM' },
  { name: 'Moldavia', english: 'Moldova, Republic of', code: 'MD' },
  { name: 'Mónaco', english: 'Monaco', code: 'MC' },
  { name: 'Mongolia', english: 'Mongolia', code: 'MN' },
  { name: 'Montenegro', english: 'Montenegro', code: 'ME' },
  { name: 'Montserrat', english: 'Montserrat', code: 'MS' },
  { name: 'Marruecos', english: 'Morocco', code: 'MA' },
  { name: 'Mozambique', english: 'Mozambique', code: 'MZ' },
  { name: 'Myanmar, Birmania', english: 'Myanmar, Burma', code: 'MM' },
  { name: 'Namibia', english: 'Namibia', code: 'NA' },
  { name: 'Nauru', english: 'Nauru', code: 'NR' },
  { name: 'Nepal', english: 'Nepal', code: 'NP' },
  { name: 'Países Bajos, Holanda', english: 'Netherlands', code: 'NL' },
  { name: 'Antillas Holandesas', english: 'Netherlands Antilles', code: 'AN' },
  { name: 'Nueva Caledonia', english: 'New Caledonia', code: 'NC' },
  { name: 'Nueva Zelanda', english: 'New Zealand', code: 'NZ' },
  { name: 'Nicaragua', english: 'Nicaragua', code: 'NI' },
  { name: 'Niger', english: 'Niger', code: 'NE' },
  { name: 'Nigeria', english: 'Nigeria', code: 'NG' },
  { name: 'Niue', english: 'Niue', code: 'NU' },
  { name: 'Marianas del Norte', english: 'Northern Mariana Islands', code: 'MP' },
  { name: 'Noruega', english: 'Norway', code: 'NO' },
  { name: 'Omán', english: 'Oman', code: 'OM' },
  { name: 'Pakistán', english: 'Pakistan', code: 'PK' },
  { name: 'Palau', english: 'Palau', code: 'PW' },
  { name: 'Territorios Palestinos', english: 'Palestinian territories', code: 'PS' },
  { name: 'Panamá	', english: 'Panama', code: 'PA' },
  { name: 'Papúa-Nueva Guinea', english: 'Papua New Guinea', code: 'PG' },
  { name: 'Paraguay', english: 'Paraguay', code: 'PY' },
  { name: 'Perú', english: 'Peru', code: 'PE' },
  { name: 'Filipinas', english: 'Philippines', code: 'PH' },
  { name: 'Isla Pitcairn', english: 'Pitcairn Island', code: 'PN' },
  { name: 'Polonia', english: 'Poland', code: 'PL' },
  { name: 'Portugal', english: 'Portugal', code: 'PT' },
  { name: 'Puerto Rico', english: 'Puerto Rico', code: 'PR' },
  { name: 'Qatar', english: 'Qatar', code: 'QA' },
  { name: 'Reunión', english: 'Reunion', code: 'RE' },
  { name: 'Rumanía', english: 'Romania', code: 'RO' },
  { name: 'Federación Rusa', english: 'Russian Federation', code: 'RU' },
  { name: 'Ruanda', english: 'Rwanda', code: 'RW' },
  { name: 'San Cristobal y Nevis', english: 'Saint Kitts and Nevis', code: 'KN' },
  { name: 'Santa Lucía', english: 'Saint Lucia', code: 'LC' },
  { name: 'San Vincente y Granadinas', english: 'Saint Vincent and the Grenadines', code: 'VC' },
  { name: 'Samoa', english: 'Samoa', code: 'WS' },
  { name: 'San Marino', english: 'San Marino', code: 'SM' },
  { name: 'Santo Tomé y Príncipe', english: 'Sao Tome and Príncipe', code: 'ST' },
  { name: 'Arabia Saudita	', english: 'Saudi Arabia', code: 'SA' },
  { name: 'Senegal', english: 'Senegal', code: 'SN' },
  { name: 'Serbia', english: 'Serbia', code: 'CS' },
  { name: 'Seychelles', english: 'Seychelles', code: 'SC' },
  { name: 'Sierra Leona', english: 'Sierra Leone', code: 'SL' },
  { name: 'Singapur', english: 'Singapore', code: 'SG' },
  { name: 'Eslovaquia', english: 'Slovakia (Slovak Republic)', code: 'SK' },
  { name: 'Eslovenia', english: 'Slovenia', code: 'SI' },
  { name: 'Islas Salomón', english: 'Solomon Islands', code: 'SB' },
  { name: 'Somalia', english: 'Somalia', code: 'SO' },
  { name: 'Sudáfrica', english: 'South Africa', code: 'ZA' },
  { name: 'Sudán del Sur', english: 'South Sudan', code: 'SS' },
  { name: 'España', english: 'Spain', code: 'ES' },
  { name: 'Sri Lanka', english: 'Sri Lanka', code: 'LK' },
  { name: 'Sudán', english: 'Sudan', code: 'SD' },
  { name: 'Surinam', english: 'Suriname', code: 'SR' },
  { name: 'Suazilandia', english: 'Swaziland', code: 'SZ' },
  { name: 'Suecia', english: 'Sweden', code: 'SE' },
  { name: 'Suiza', english: 'Switzerland', code: 'CH' },
  { name: 'Siria', english: 'Syria, Syrian Arab Republic', code: 'SY' },
  { name: 'Taiwán (República de China)', english: 'Taiwan (Republic of China)', code: 'TW' },
  { name: 'Tadjikistan', english: 'Tajikistan', code: 'TJ' },
  { name: 'Tanzania', english: 'Tanzania', code: 'TZ' },
  { name: 'Tailandia', english: 'Thailand', code: 'TH' },
  { name: 'Tíbet', english: 'Tíbet', code: 'TB' },
  { name: 'Timor Oriental', english: 'Timor-Leste (East Timor)', code: 'TL' },
  { name: 'Togo', english: 'Togo', code: 'TG' },
  { name: 'Tokelau', english: 'Tokelau', code: 'TK' },
  { name: 'Tonga', english: 'Tonga', code: 'TO' },
  { name: 'Trinidad y Tobago', english: 'Trinidad and Tobago', code: 'TT' },
  { name: 'Túnez', english: 'Tunisia', code: 'TN' },
  { name: 'Turquía', english: 'Turkey', code: 'TR' },
  { name: 'Turkmenistan', english: 'Turkmenistan', code: 'TM' },
  { name: 'Islas Turcas y Caicos', english: 'Turks and Caicos Islands', code: 'TC' },
  { name: 'Tuvalu', english: 'Tuvalu', code: 'TV' },
  { name: 'Uganda', english: 'Uganda', code: 'UG' },
  { name: 'Ucrania', english: 'Ukraine', code: 'UA' },
  { name: 'Emiratos Árabes Unidos', english: 'United Arab Emirates', code: 'AE' },
  { name: 'Reino Unido', english: 'United Kingdom', code: 'GB' },
  { name: 'Estados Unidos', english: 'United States', code: 'US' },
  { name: 'Uruguay', english: 'Uruguay', code: 'UY' },
  { name: 'Uzbekistán', english: 'Uzbekistan', code: 'UZ' },
  { name: 'Vanuatu', english: 'Vanuatu', code: 'VU' },
  { name: 'Ciudad del Vaticano', english: 'Vatican City State (Holy See)', code: 'VA' },
  { name: 'Venezuela', english: 'Venezuela', code: 'VE' },
  { name: 'Vietnam', english: 'Vietnam', code: 'VN' },
  { name: 'Islas Virgenes Británicas', english: 'Virgin Islands (British)', code: 'VG' },
  { name: 'Islas Virgenes Americanas', english: 'Virgin Islands (U.S.)', code: 'VI' },
  { name: 'Wallis y Futuna', english: 'Wallis and Futuna Islands', code: 'WF' },
  { name: 'Sáhara Occidental', english: 'Western Sahara', code: 'EH' },
  { name: 'Yemen', english: 'Yemen', code: 'YE' },
  { name: 'Zambia', english: 'Zambia', code: 'ZM' },
  { name: 'Zimbabwe', english: 'Zimbabwe', code: 'ZW' },
];

export const getCountryNamesAndCode = () => {
  let language = 'en';

  if (currentLocale) {
    language = currentLocale.split('_')[0];
  }

  return countriesWithCode.map((country) => {
    let nameValue: string;

    switch (language) {
      case 'en':
        nameValue = country.english;

        break;

      case 'es':
        nameValue = country.name;

        break;

      default:
        nameValue = country.name;
    }

    return { name: nameValue, code: country.code };
  });
};

export const countriesObj = getCountryNamesAndCode().map((country) => ({
  value: country.name,
  label: country.name,
}));
