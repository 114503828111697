import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  providerLogoImage: {
    width: 126,
    height: 30,
  },
  provider: {
    backgroundColor: '#d1d2d7',
    borderRadius: 5,
    width: '49%',
    height: 80,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 8,
  },
});
