import merge from 'lodash/merge';
import { StyleProp, StyleSheet, TextStyle, ViewStyle } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';

interface Styles {
  inputWrapper: ViewStyle;
  label: TextStyle;
  input: ViewStyle;
  inputFilled: ViewStyle;
  btnWrapper: ViewStyle;
  btnLabel: TextStyle;
  recaptchWrapper: ViewStyle;
  calendarWrapper: ViewStyle;
  errorLink: TextStyle;
  messageWrapper: ViewStyle;
  errorText: TextStyle;
  dateInput: ViewStyle;
  dateTextInput: ViewStyle;
}

export const getStyles = (breakpoints: Breakpoints, colors: ReactNativePaper.ThemeColors) => {
  const base: StyleProp<Styles> = {
    inputWrapper: {
      marginVertical: 8,
    },
    label: {
      color: colors.keyboardFocus.light,
      marginBottom: 4,
      fontFamily: 'Rubik-Medium',
      fontWeight: '500',
      lineHeight: 20,
      fontSize: 16,
    },
    input: {
      backgroundColor: colors.tenantBackground.light.surfaceBase,
      borderRadius: 4,
      width: '100%',
      borderColor: 'transparent',
      opacity: 0.8,
    },
    inputFilled: {
      backgroundColor: colors.tenantBackground.light.surfaceBase,
      borderRadius: 4,
      width: '100%',
      borderColor: 'transparent',
      opacity: 1,
    },
    btnWrapper: {
      marginTop: 16,
      backgroundColor: colors.fill.action.accent01,
      borderRadius: 4,
      height: 40,
      width: '100%',
      borderColor: 'transparent',
    },
    btnLabel: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Oswald-Medium',
      fontSize: 20,
      fontWeight: '500',
      lineHeight: 24,
      textAlign: 'center',
      textTransform: 'uppercase',
    },
    recaptchWrapper: {
      alignItems: 'center',
      marginTop: 18,
      marginBottom: 10,
      maxWidth: 450,
      width: '100%',
    },
    calendarWrapper: {
      position: 'absolute',
      top: -250,
    },
    errorLink: {
      textDecorationColor: colors.keyboardFocus.light,
      textDecorationStyle: 'solid',
      textDecorationLine: 'underline',
      fontSize: 12,
    },
    messageWrapper: {
      width: '80%',
      height: '100%',
      maxWidth: 450,
      alignSelf: 'center',
    },
    errorText: {
      fontSize: 12,
    },
    dateInput: {
      width: '100%',
    },
    dateTextInput: {
      backgroundColor: colors.tenantBackground.light.surfaceBase,
      borderColor: colors.tenantBackground.light.surfaceBase,
      height: 50,
      borderRadius: 5,
      width: '100%',
      opacity: 1,
    },
  };

  const mediaQueries =
    breakpoints.currentBreakpoints.isTiny ||
    breakpoints.currentBreakpoints.isTnySm ||
    breakpoints.currentBreakpoints.isSmMed
      ? ({} as StyleProp<Styles>)
      : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
