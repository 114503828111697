import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ImageStyle, Pressable, PressableProps, ViewStyle } from 'react-native';
import styles from './checkbox.style';
import { CheckMark } from '@warnermmedia/gsp-core/sdk/assets';
import { getUniqueId } from '@warnermmedia/gsp-core/brands/estadio/feature';
import { ItemName } from '@warnermmedia/gsp-core/sdk/data-access';
import { useSpatialNavigation } from '../../spatialNavigation';

export interface CheckboxProps extends PressableProps {
  checkboxStyle?: ViewStyle | ViewStyle[];
  checkedStyle?: ViewStyle | ViewStyle[];
  unCheckedStyle?: ViewStyle | ViewStyle[];
  isChecked: boolean;
  disabled?: boolean;
  error?: boolean;
  iconStyle?: ImageStyle;
  handlePress: () => void;
  checkMarkFill?: string;
  focusable?: boolean;
  focusKey?: string;
}

export const CustomCheckbox = ({
  checkboxStyle,
  checkedStyle,
  unCheckedStyle,
  iconStyle,
  handlePress,
  error,
  isChecked,
  checkMarkFill,
  focusable = true,
  focusKey,
  disabled,
  ...props
}: CheckboxProps) => {
  const [checked, setChecked] = useState(isChecked);
  const [pressed, setPressed] = useState(false);
  const boxStyle = isChecked ? [styles.checked, checkedStyle] : [styles.unchecked, unCheckedStyle];
  const errorStyle = error ? styles.error : {};
  const checkboxFocusKey = useRef(focusKey ?? getUniqueId(ItemName.CHECKBOX_ITEM)).current;
  const { ref } = useSpatialNavigation({
    focusKey: checkboxFocusKey,
    focusable: focusable && !disabled,
  });

  useEffect(() => {
    if (pressed) {
      handlePress();
    }
    setPressed(false);
  }, [pressed]);

  const onPress = useCallback(() => {
    setChecked(!checked);
    setPressed(true);
  }, [checked]);

  return (
    <Pressable
      style={[styles.checkbox, ...boxStyle, errorStyle, checkboxStyle]}
      onPress={onPress}
      focusable={focusable}
      ref={ref}
      nativeID={checkboxFocusKey}
      {...props}
    >
      {isChecked ? <CheckMark fill={checkMarkFill} overideStyle={iconStyle} /> : null}
    </Pressable>
  );
};

export default CustomCheckbox;
