import { useContext, useEffect } from 'react';
import { AppConfigContext } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { useGetDevice } from '@warnermmedia/gsp-core/sdk/ui';

export function useAppsFlyerTag(): void {
  const appConfig = useContext(AppConfigContext);
  const { isWeb } = useGetDevice();

  const appsFlyerFunction = (appsFlyerKey: string) => {
    return `!function(t,e,n,s,a,c,i,o,p){t.AppsFlyerSdkObject=a,t.AF=t.AF||function(){
      (t.AF.q=t.AF.q||[]).push([Date.now()].concat(Array.prototype.slice.call(arguments)))},
      t.AF.id=t.AF.id||i,t.AF.plugins={},o=e.createElement(n),p=e.getElementsByTagName(n)[0],o.async=1,
      o.src="https://websdk.appsflyer.com?"+(c.length>0?"st="+c.split(",").sort().join(",")+"&":"")+(i.length>0?"af_id="+i:""),
      p.parentNode.insertBefore(o,p)}(window,document,"script",0,"AF","pba",{pba: {webAppId: "${appsFlyerKey}"}})`;
  };

  useEffect(() => {
    if (isWeb) {
      const appsFlyerScript = document.getElementById('appsFlyerScript');
      const appsFlyerKey = appConfig?.appConfig.appsFlyer || 'a325cfe0-5fa8-4931-b49f-f7174beb2050';

      if (!appsFlyerScript) {
        const appsFlyerTag = document.createElement('script');
        appsFlyerTag.setAttribute('id', 'appsFlyerScript');
        appsFlyerTag.setAttribute('class', 'optanon-category-tc');
        appsFlyerTag.setAttribute('type', 'text/plain');
        appsFlyerTag.append(document.createTextNode(appsFlyerFunction(appsFlyerKey)));
        document.head.appendChild(appsFlyerTag);
      }
    }
  }, [appConfig]);
}
