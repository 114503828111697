import { useReactiveVar } from '@apollo/client';
import { loginAPIStateStore, userHasSubscription } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { SubscriberType, SubscriptionStatus } from '@warnermmedia/gsp-core/sdk/data-access';
import { getDate } from '@warnermmedia/gsp-core/sdk/ui';

export enum ContentType {
  Clip = 'Clip',
  Match = 'Match',
  Episode = 'Episode',
}

export interface UseVideoTagParams {
  awayTeam?: string;
  homeTeam?: string;
  scheduled_date?: string;
  type?: string;
  duration?: string;
  episode?: string;
  subtitle?: string;
  image?: string;
  mediaId?: string;
  isLive?: boolean;
  title?: string;
  season?: string;
  tournament?: string;
}

export const useVideoTag = ({
  awayTeam,
  homeTeam,
  scheduled_date,
  title,
  type,
  image,
  episode,
  season,
  duration,
  tournament,
}: UseVideoTagParams) => {
  const hasSubscription = useReactiveVar(userHasSubscription);

  const contentType = type === 'match' ? ContentType.Match : type === 'media' ? ContentType.Clip : '';

  const { preferences, isTveAuth } = loginAPIStateStore();
  const favoriteTeam =
    preferences &&
    preferences.length > 0 &&
    preferences.find((el, id) => {
      return el.collection === 'favoriteTeam';
    });

  const tags = {
    title,
    ...(image ? { image } : {}),
    ...(season ? { season } : {}),
    ...(episode ? { episode } : {}),
    ...(duration ? { duration } : {}),
    ...(homeTeam ? { homeTeam } : {}),
    ...(awayTeam ? { awayTeam } : {}),
    ...(tournament ? { tournament } : {}),
    ...(contentType ? { contentType } : {}),
    ...(scheduled_date ? { pubDate: getDate(scheduled_date).format('YYYY-MM-DD') } : {}),
    subscriptionStatus: hasSubscription ? SubscriptionStatus.Active : SubscriptionStatus.Inactive,
    favoriteTeam: favoriteTeam && favoriteTeam?.selections?.length > 0 ? favoriteTeam.selections[0] : '',
    subscriptionType: isTveAuth ? SubscriberType.TVE : SubscriberType.D2C,
  };

  return tags;
};
