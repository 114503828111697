import { formatDateDDMMYYYY } from '@warnermmedia/gsp-core/sdk/ui';

export default {
  app_name: 'EstadioTNTSports',
  app_version: 'Version',
  SplashScreenText: 'POTENTIAL STRAPLINE',
  dateFormat: 'MM/DD/YYYY',
  app_web_title: 'Estadio TNT Sports Web',

  // FOOTER STRINGS
  FOOTER_LABEL_1: 'TERMS AND CONDITIONS',
  FOOTER_LABEL_2: 'COOKIES POLICY',
  FOOTER_LABEL_3: 'PREFERENCE CENTER',
  FOOTER_LABEL_4: 'FAQ',
  FOOTER_LABEL_5: 'MINIMUM REQS.',
  FOOTER_LABEL_6: 'TICKET CONSULTATION',
  FOOTER_LABEL_7: 'TNTSports.cl',
  FOOTER_LABEL_8: 'GO TO STADIUM ARGENTINA',
  FOOTER_LABEL_9: 'HELP',
  FOOTER_LABEL_10: 'PRIVACY POLICY',
  FOOTER_COPYRIGHT_1: 'TNT SPORTS STADIUM',
  FOOTER_COPYRIGHT_2: ', ALL RIGHTS RESERVED.',
  //////////////////////////////

  // Card State Labels
  todayLabel: 'Today',
  revealLabel: 'Reveal',
  liveLabel: 'Live',
  dayLabel: 'Day',

  // Error MessageDisplay
  contentLockError: 'Sorry, you are not authorized for this content.',
  noAccessError: 'You have no access to this content.',
  cantPlayContentError: "Can't play content, try again later.",
  tokenExpiredError: 'User token expired. Please login again.',
  locationError: 'Content blocked due to location',
  noAccessToContent: 'Sorry, you do not have access to this content. Contact us for more information.',
  maxStream: 'Max allowed streams exceeded',
  mediaNotAvailable: 'Media not available at this moment',
  authorisationError: 'Authorisation Error',
  tryAgainLater: 'There was a problem playing content. Please try again later.',

  // ResendEmailVerication
  emailVericationPageTitle: 'You Must validate Your Account',
  emailVericationPageTitleSuccess: 'Your email was successfully validated.',
  emailVericationParagraph_1: 'Validate your account by clicking on the e-mail we sent to',
  emailVerificationParagraph_2: "If you haven't received it, please click the button below to resend it.",
  emailVerificationParagraphPinCode: 'Once you validate your account, please restart the login process from your TV',
  emailVericationCtaButton: 'Resend Email',
  emailVericationMessage: 'Did you already validate your account?.',
  emailVericationLogoutMessage: 'Did you enter the wrong email?.',
  emailVericationLogoutLabel: 'Click here to start over.',
  emailVericationSuccessMessage: 'You have not received the validation email?.',
  emailVericationCheckButtonText: 'Check validation',
  emailVericationApiSuccess:
    'We sent an email to the account you used to register.\n\nPlease click on the email to validate your account.',
  emailVericationApiError: 'The email has not been sent, please try again.',
  emailVericationSuccessRouteMessage: 'You can now enter Estadio TNT Sports.',
  emailCheckvalidationMessageSuccess:
    'We have checked and your email is not verified yet.\nPlease click on the email to validate your account.',
  emailCheckvalidationMessageError: 'An error occurred checking the validation of your account.',
  checkEmailContentTitle: 'Check your email',
  emailVerificationSuccessContentTitle: 'You successfully validated your account',

  //update app modal
  updateAppModalTitle: 'An update is available',
  updateAppModalDescription:
    'There is a new version of this app. Update your app to continue enjoying Estadio TNT Sports.',
  updateAppModalBtnText: 'UPDATE',

  // My Account
  editProfileSuccessMessage: 'Your personal information was successfully updated.',
  tvEditProfileDescriptionLine1: 'Scan the QR code or enter ',
  tvEditProfileDescriptionLine2: ', and follow the steps to edit your account details.',
  tvEditProfileQRTitle: 'Do you want to edit your Estadio TNT sports account information?',
  tvEditProfileQRButtonLabel: 'Understood',
  tvEditProfileQREditButton: 'Edit Information',
  checkPaymentInfo: 'Check payment information',
  userPreferencesErrorMessage: 'You cannot edit your account at this moment, please try later.',

  // Subscription
  tvSubscriptionCTAButtonText: 'I ALREADY ACTIVATED MY ACCOUNT',
  payPalBtnLabel: 'Continue with paypal',
  subsMobilePageTitle: "You don't have an active subscription.",
  subsWebPageTitle: 'START ENJOYING OUR CONTENT',
  subsPinValidationSubtitle: 'Once you activate your subscription, please restart the login process from your TV',
  noSubscriptionText:
    'You do not have an active subscription, please activate one to view content on TNT Sports Stadium.',
  noSubscriptionEnabledText: 'You do not have an active subscription.',
  activateSubscription: 'ACTIVATE SUBSCRIPTION',
  iOSButtonText: 'Return',
  androidButtonText: 'subscribe now',
  mobileMsgDisplayText: "We're sorry, to enter TNT Sports Stadium you need an active subscription.",
  buyNowLabel: 'BUY NOW',
  subscriptionFailureMessage: 'Invalid coupon code.',
  subscriptionInvalidPromocodeMessage: 'Invalid promo code.',
  subscriptionSuccessMessage: 'The coupon has been applied successfully.',
  subscriptionPreviewFailedMessage: 'Product preview failed!',
  subscriptionUsedPromocodeMessage: 'Promotion code already used for this account.',
  subscriptionPaymentFailedMessage: 'Subscription payment failed!',
  subscriptioncancelledMessage: 'Current subscription already cancelled, please re-subscribe to use promo code.',
  subscriptionButtonText: 'Send',

  doNotRefreshLabel: "Please don't refresh this page",
  doNotRefreshDescription: 'Wait a few moments while your payment is processed.',

  cardNameError: 'Enter the Name that appears on the card.',
  cardNumberError: 'Enter all 16 card numbers.',
  cardExpiryDateError: 'Please enter the expiration of the card.',
  cardCVNError: 'Please enter the CVV code of the card.',
  cardNumberLabel: 'Credit Card Number',

  loadingText: 'Loading...',
  subscriptionSuccessHomeBtnText: 'Go to start',
  subscriptionSuccessHomeQuestionBtnText: 'What is TNT Sports Stadium?',
  subscriptionSuccessRecommendationBtnText: 'Basic recommendations',
  subscriptionSuccessSolutionBtnText: 'Problems Solution',
  subscriptionSuccessTitle: 'Subscription successful!',
  subscriptionSuccessText1: 'Welcome, you already have your seat secured.',
  subscriptionSuccessText2: 'The coupon has been applied successfully.',
  subscriptionSuccessText3: 'The transaction has been processed successfully.',
  subscriptionSuccessText4:
    'You can start enjoying all the Chilean soccer matches live and VOD, along with the best programming from TNT Sports Stadium. We also invite you to review some tips.',
  backToAccountLabel: 'Back to my account',
  returnLabel: 'Return',
  subscribeNowLabel: 'Subscribe now',
  cancelSubscriptionLabel: 'cancel my subscription',
  subscriptionCancelledTitle: 'SUBSCRIPTION CANCELED.',
  subscriptionCancelledLabel: 'You have canceled your subscription.',
  subscriptionRequirementDescription: "We're sorry, to enter TNT Sports Stadium you need an active subscription",
  cancelSubscriptionWarningDescription: 'You are about to cancel your subscription. Are you sure?',
  cancelSubscriptionTitle: 'Before you go',
  cancelSubscriptionSubTitle:
    "We're sorry you want to cancel your TNT Sports Stadium subscription, please help us improve!",
  paypalText:
    'To finish registration, click the Continue to PayPal button and log in to PayPal with your email and password.',
  tvSubscriptionText: (webUrlLink: string) => `Scan the QR code or go to ${webUrlLink}, and subscribe now.`,
  couponButtonLabel: 'I have a Coupon',
  payTypeHeaderLabel: 'Credit/Debit Card',
  payTitleLabel: 'Enter your payment details',
  payDescriptionLabel: 'Your subscription starts as soon as you complete your payment details.',
  cancelCommentTitle: 'You can comment a bit further if you want',
  subscriptionExpireDescription: (date: string) => `You can continue enjoying Estadio TNT Sports until ${date}.`,
  cancelSubscriptionContentTitle: 'Sorry to see you go',
  unsubscribeConfirmContentTitle: 'Are you sure?',
  subscriptionSuccessContentTitle: 'Welcome!',
  subscriptionFailureContentTitle: 'Something went',
  subscriptionFailureInfo1: 'The payment process has been cancelled',
  subscriptionFailureInfo2: 'Your card has not been charged. Please try again.',

  // vindicia label
  accountName: 'Name on the card',
  accountCountry: 'Country',
  cardNumber: 'Card number',
  expiryDate: 'Valid Thru',
  cvn: 'Security code (CVV)',

  cancel: 'Cancel',
  lastName: 'Lastname',
  firstName: 'Firstname',
  alternativeEmail: 'Alternative Email',
  birthdateText: 'Birthdate',
  genderText: 'Génder',
  genderMaleText: 'Male',
  genderFemaleText: 'Female',
  countryText: 'Country of Residence',
  phone: 'Phone Number',
  password: 'Password',
  repeatPassword: 'Repeat your Password',
  favoriteTeamLabel: 'your favorite team',
  confirmPassword: 'Password confirmation',
  editAccountSubmit: 'SAVE INFORMATION',
  accountInfoTitle: 'My information',
  editAccountSubTitle: 'Feel free to edit any of your details below to keep your account fully up to date.',
  editMyAccountBtnTex: 'EDIT INFORMATION',

  // register
  errorListLabel: 'The form has errors:',
  register: 'Register',
  missingAnyField: 'You must enter all your data to register in Stadium.',
  missingFirstName: 'Enter your name to register.',
  missingLastName: 'Enter your last name to register.',
  missingEmail: 'Enter your email to register.',
  invalidEmail: 'Please enter a valid email.',
  missingPassword: 'Please fill in your password.',
  missingPassword2: 'Please fill in your password again.',
  differingPassword: 'Passwords do not match. Please review them.',
  invalidCaptcha: 'The recaptcha is not correct. Please try again.',
  unacceptedTerms: 'You must accept the Stadium terms and conditions to register on the platform.',
  alreadyRegisteredEmail: 'The username or password are incorrect. Please check them.',
  incorrectPassword: 'The username or password are incorrect. Please check them.',
  unconfirmedAccount: 'Please confirm your account before entering the Stadium.',
  onlyAlphabeticCharactersError: 'Only Alphabetic characters are accepted.',
  registerFormTitle: 'Create your account on Estadio TNT Sports.',
  registerFormDirectionsText: 'Enter your information to register.',
  registerTermsLabelLine1: 'I accept the',
  registerTermsLabelLine2: 'terms and conditions',
  registerTermsLabelLine3: 'of use',

  // EditAccountError
  firstNameErrorMsg: 'Firstname is required!',
  lastNameErrorMsg: 'Lastname is required!',
  dayErrorMsg: 'Enter a valid day!',
  monthErrorMsg: 'Please enter a valid month!',
  yearErrorMsg: 'Please enter a valid year!',
  phoneErrorMsg: 'Please enter a valid phone number from 9 numbers!',
  alternativeEmailErrorMsg: 'Please enter a valid email and it must be different from the main email!',
  passwordErrorMsg: 'The password must contain at least 8 characters: 1 uppercase, 1 lowercase, 1 number or symbol.',
  passwordConfirmationErrorMsg: 'The password and the confirmation password must be the same!',
  rutErrorMsg: 'Enter a valid RUT of 9 numbers!',
  editFormError1: 'Date of birth partially filled!',
  editFormError2: "Your changes weren't saved, please try again.",
  saveDetailsError: 'Profile update failed;',
  saveEmailError: 'the alternate email has already been used,',
  saveContactError: 'during update; mobile Contact,',
  savePreferenceError: 'during update; preferences,',
  profileError: 'during update; mobile contact and preferences.',
  savePasswordError: 'during update; password.',
  birthdateErrorMsg: 'Enter a valid date.',

  // redeemCoupon
  redeemCouponErrorMessage: 'Your coupon has not been validated, please try again later.',
  redeemCouponTitle: 'Validate your Coupon',
  redeemCouponSubTitle: 'Enter your coupon code.',
  code: 'Code',
  valid: 'Valid',
  confirm: 'Confirm',
  couponToolTip: 'Enter the coupon code to continue!',
  contactUsMessage: "Can't validate your coupon?",
  contactUsMessageButton: 'Get in contact with us',
  couponRedemptionTitle: 'You are one step away from redeeming your coupon',
  couponRedemptionDescription:
    'Accept the terms and conditions and confirm the coupon to apply the discount on your next subscription.',
  freeCouponRedemptionTitle: 'You are one step away from redeeming your coupon',
  freeCouponRedemptionDescription: (date: string | number | Date) =>
    `Accept the terms and conditions and confirm the coupon to enjoy TNT Sports Stadium for free until ${formatDateDDMMYYYY(
      date
    )}`,
  couponTermsText: 'I accept the terms and conditions of Use of TNT Sports Stadium.',
  couponRedemptionToolTip: 'You must accept the terms and conditions of the Stadium to use the coupon on the platform.',

  // retention
  retentionTitle: 'YOU ARE ABOUT TO CANCEL YOUR SUBSCRIPTION. ARE YOU SURE?',
  promotionButtonLabel: 'ACTIVATE PROMOTION',
  activatePromotionSuccess: 'Promotion successfully registered.',
  activatePromotionErr: 'There was a problem, please try again later.',

  // invoice
  consult: 'Consult',
  invoiceTitle: 'Find your ticket',
  invoiceTitleDownload: 'Your ticket is available',
  invoiceSubtitle1: 'Complete all the fields to find your ballot.',
  invoiceSubtitle2: 'You can now download it by clicking on the download button.',
  invoiceNotFoundLink: 'get in touch with our support team',
  invoiceNotFound1: "We couldn't match your information with an existing invoice. Please, try again or",
  invoiceNotFound2: 'to help you.',
  invoiceErrorMsg: 'The recaptcha is not correct. Please try again.',
  invoiceSuccessMsg: 'You must complete all the data.',
  invoiceNumber: 'Ticket number',
  invoiceNumberToolTip: 'Enter the ticket number to continue!',
  invoiceDate: 'Date of issue',
  invoiceDateToolTip: 'Please enter a valid issue date (DD/MM/YYYY) to continue!',
  invoiceAmount: 'Total amount',
  invoiceAmountToolTip: 'Enter the total amount to continue!',
  invoiceRUTToolTip: 'Enter the RUT to continue!',
  invoiceRecaptchaToolTip: 'Enter the ReCAPTCHA to continue!',

  // Metatags
  myAccountTitle: 'My Account',
  appInfoTitle: 'App Information',

  // TVE Provider Login
  providerLoginTitle: 'Log in',
  providerLoginDescription: 'Select your provider to enter the platform',
  providerLoginSearchLabel: 'Search',
  providerLoginSearchPlaceholder: 'Search your provider',
  providerLoginWithAccountLabel: 'Log in with my account instead',
  providerLoginCountryLabel: 'Country',
  providerLoginShowAllLabel: 'Show all',
  providerLoginCountrySelectionLabel: 'Select your country',
  providerLoginProviderError: 'There are no providers to display.',
  providerLoginProviderListLoadingError: 'An error occurred loading the provider list.',
  providerLoginAuthenticationError: 'An error occurred authenticating to provider',

  // TVE Errors
  unKnownLoginError: 'Unknown TVE login error occurred.',
  unKnownLogoutError: 'Unknown TVE logout error occurred.',
  unKnownProviderError: 'Unknown TVE provider error occurred.',
  unKnownTokenError: 'You have not contracted the service to access the Stadium. Contact your provider.',
  // login
  error401:
    'For security, this account has been blocked. To log in again, you will need to change your password using the RECOVER PASSWORD option located under the ENTER button.',
  error420:
    'The username or password are incorrect. If you do not remember your password, you can generate a new one with the RECOVER PASSWORD option located under the ENTER button.',
  errorDefault: 'An error occurred while logging in.',
  forgotPassword: 'Recover password',

  // Page Titles:
  homePage: 'Home',
  signalTvPage: 'TV Signal',
  loginPage: 'Login',
  cancellationPage: 'Before you go',
  profilePage: 'My cccount',
  showsPage: 'Shows',
  teamsPage: 'Teams',
  tournamentsPage: 'Tournaments',
  passwordRecoveryPage: "Can't access your account?",
  resetPasswordPage: 'Reset password',
  subscriptionFailurePage: 'Failed transaction',
  landingPage: 'Welcome',
  billingPage: 'Your billing history',
  golfChannelPage: 'Golf Channel',

  // Cancellation
  cancellationSubTitle:
    'We are sorry you want to cancel your Estadio TNT Sports subscription, please help us to improve!',
  cancellationReturnToMyAccount: 'Return to my account',

  // Password Recovery
  passwordRecoverySuccess:
    'If there is a register user for the email you had used we sent it an email. Please follow the steeps in that email to recover your account.',
  passwordRecoverySubTitle:
    'Please complete the email  associated to your account and will send you the steps to recover Estadio access.',
  passwordRecoverySubWithCodeTitle:
    'Input your email and we will send you instructions to regain access to Estadio\n\nOnce you regain access to your account, please restart the login process from your TV',
  recoveryEmailLabel: 'Send recovery email',
  recoveryEmailInputError: 'The email format is invalid. Please review it.',

  // Reset Password
  resetPasswordSuccessTitle: 'Change made successfully',
  resetPasswordSuccessSuccess: 'Your new password has been saved. You can access Estadio now.',
  resetPasswordSubTitle: 'Choose a new password for your Estadio account.',
  resetPasswordErrorMessage: 'An error occurred. The token authentication failed, please',
  resetPasswordErrorMessageLink: ' "request a new one."',
  resetPasswordConfirmLabel: 'Confirm Password',
  resetPasswordRule: 'Passwords must contain a minimum of 8 characters: 1 uppercase, 1 lowercase, 1 number or symbol',
  resetPasswordUpdateLabel: 'Update Password',
  passwordMatchError: 'Passwords do not match. They must be the same to confirm your new password.',
  ruleMismatchError: 'Your chosen password does not have the minimum requirements for us to confirm it',
  resetPasswordSuccessContentTitle: 'You can enter now',

  // Landing
  landingSubTitle: 'Enter your credentials to enter Estadio TNT Sports.',

  // Login Form
  invalidPasswordError: 'Please fill in your password.',
  emptyUsernameError: 'Enter your email to login.',
  invalidUsernameError: 'Please enter a valid email.',
  rememberMeText: 'Remember this user in the future',
  registerTextTV: 'I do not have an account',
  registerText: 'Register at TNT Sports Stadium',
  forgotpasswordQRTitle: "Can't access your account at the tnt sports stadium?",
  forgotpasswordQRButtonLabel: 'I regained access',
  registerQRTitle: "Don't you have a tnt sports stadium account?",
  registerQRButtonLabel: 'I already have my account',
  forgotModalDescription: (forgotPasswordLink: string) =>
    `Scan the QR code or go to ${forgotPasswordLink}, and follow the indicated steps to recover your account.`,
  registerModalDescription: (registerLink: string) =>
    `Scan the QR code or enter ${registerLink}, and follow the steps to register your account.`,
  confirmVerificationCodeMessage: 'Please login to verify your account',
  verificationMessageTokenExpired:
    'The time you had to validate your account is over, you need to validate your email to enter Estadio.',
  providerLoginText: 'Login with my provider account',

  // Common button texts:
  continueText: 'Continue',
  logoutText: 'Logout',
  emailSentText: 'Email Sent',
  loginText: 'Login',
  emailText: 'Email',
  passwordText: 'Password',
  tryAgainText: 'Try again',
  enterText: 'Enter',
  stayInTouchText: 'Get in contact with us.',
  saveText: 'Save',
  closeButton: 'Close this message',

  // Register page
  consentMessageLine1: 'By creating an account, you agree to the ',
  consentMessageLine2: ' and acknowledge that you have read and accept our ',
  consentMessageLine3: `. You acknowledge that Estadio TNT Sports and its WarnerMedia affiliates may use your address email address to send [updates/news], notices, and offers. You can cancel or get more information about your rights through the `,
  termsAndConditionLabel: 'Terms of Use',
  privacyPoliciesLabel: 'Privacy Policy',

  //Account Detail
  accountDetailHeader: 'Subscription Details',
  subscriptionTitle: 'Your subscription',
  subscriptionValidLabel: 'Your subscription is valid until ',

  // Favorite Team
  favoriteTeamErrorMessage: 'Your team has not been saved, please try again later.',
  favoriteTeamModalHeaderText: 'Your favorite team',
  favoriteTeamModalMessageText:
    'Pick a team from the list below and you will see highlighted content in Estadio TNT Sports homepage.',
  favoriteTeamModalSkipLabel: 'Later',
  favoriteTeamModalDropdownTitle: 'Chilean Teams',
  favoriteTeamModalDropdownPlaceholder: '- Pick a team -',

  // Geo block
  geoBlockMessageTitle: 'This content cannot be played',
  geoBlockMessageDescription: 'This content is not available in your current location.',
  // Subscription Info
  subscriptionInfoTitle: 'Subscription Details',
  subscriptionInfoSubTitle: 'Your Subscription',
  subscriptionInfoDescription: (subscriptionEndDate: string) =>
    `Your subscription is valid until ${subscriptionEndDate}`,
  subscriptionTveInfoDescription: (providerName: string) =>
    `Your subscription is valid through ${providerName}. For any further information, please get in touch with them.`,
  subscriptionInfoCancelButton: 'CANCEL SUBSCRIPTION',
  subscriptionInfoCoupon: 'Validate a coupon',

  // Concurrency labels
  concurrencyModalTitle: 'YOU ARE ALREADY PLAYING A CONTENT',
  concurrencyModalDescription: 'You reached the playback limit on simultaneous devices.',
  concurrencyModalCTALabel: 'GO BACK HOME',
  toolboxConcurrencyModalTitle: 'Oops! Your session on this device has expired.',
  toolboxConcurrencyModalDescription: 'Log out and log back in to continue enjoying your favorite content.',
  toolboxConcurrencyModalCTALabel: 'Sign Out',

  //TermsCheckbox
  termsText: 'I accept Estadio terms and conditions.',
  tooltipTermsText: 'Accept the terms and conditions to continue!',

  epgSwimlaneHeadline: 'Schedule',

  //CMS Carousel Headline
  teamDetailCarouselHeadline: 'Upcoming Matches',

  //PIN and PAIR Login
  pinPairTitle: 'Scan the QR code or visit the link:',
  pinPairDescription: 'Enter the code on your device:',
  buttonText: 'UPDATE THE CODE',
  pinPairBack: 'Go Back',
  pinPairLandingTitle: 'LIVE THE PASSION OF CHILEAN FOOTBALL',
  pinPairLandingSubtitle: 'WHEREVER YOU ARE',
  pinPairErrorTitle: 'Something went wrong',
  pinPairErrordescription: 'We are experiencing some issues, please try again later.',
  contentTitleSuffix: 'Estadio TNT Sports',

  // Code Validation
  codeValidationTitle: 'Input the code shown on your tv',
  codeValidationDescription: 'Input the 6 digit code on your television into the field below.',
  codeValidationDescriptionUnLogged:
    "Tap PAIR MY TV so we can identify your account, you'll be able to log in on the next step.",
  codeValidationDescriptionLogged: (username: string) =>
    `Tap PAIR MY TV and we'll use the account below credentials to log you in. \n\n ${username}`,
  codeValidationCta: 'Pair my TV',
  codeValidationProcessingCta: 'Validating',
  codeValidationOtherAccountBtn: 'Use a different account',
  codeValidationIncorrectCodeError: 'Something went wrong. The code is not valid. Please try again.',
  codeValidationGenericError: 'Something went wrong. We are experiencing some issues, please try again later.',
  codeValidationLoginSubtitle: 'Input your credentials to log in to Estadio TNT Sports and activate your TV.',

  // Code Validation Success
  codeValidationSuccessTitle: 'Your TV is ready to go',
  codeValidationSuccessSubtitle:
    'You can now close this window and access Estadio from your TV.\n\nThis can take a moment to load on your TV.',
  codeValidationSuccessNewsBtn: 'Get the latest news from TNT Sports',

  // recaptch language
  recaptchaLang: 'en',

  // Payment type
  paymentTypeCoupon: 'Coupon',

  // Navbar labels
  navHome: 'Home',
  navSignalTvPage: 'Live',
  navTournamentsPage: 'Tournaments',
  navTeamsPage: 'Teams',
  navShowsPage: 'Shows',
  navProfilePage: 'My Account',
  navLogout: 'Logout',
  navHelp: 'Help',
  navSubscribe: 'Subscribe',
  navSubscription: 'Subscription',

  // ChangesNotSavedModal
  changesNotSavedModalTitle: 'You have unsaved chages',
  changesNotSavedModalDescription:
    'Are you sure you want to cancel? Any unsaved changes wont be applied to your account.',
  changesNotSavedModalEditBtn: 'Continue editing',
  changesNotSavedModalDiscardBtn: 'Discard changes',

  //Discover Max Modal
  discoverMaxModalTitle: 'Discover MAX and continue enjoying!',
  discoverMaxModalText: 'Provider not available. Log in to max.com and continue enjoying',
  discoverMaxModalButtonLabel: 'Return',

  // Go to Max
  goToMax: 'Go to Max',
};
