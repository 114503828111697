/* eslint-disable react-hooks/exhaustive-deps */
import Axios, { AxiosError } from 'axios';

import { ErrorMsgState, PreAuthAxiosResponse, PreAuthzs, StrapiiAxiosResponse } from '../types';
import { handleApiError } from './helpers/handleError';
import { ReactiveVar } from '@apollo/client';
import { useEffect, useState } from 'react';

/**
 * @interface Token
 * @description Strapii token response for specified media
 */
export interface Token {
  mediaId: string;
  accessAllowed: boolean;
  preAuthz: PreAuthzs | undefined;
  token: string;
}

/**
 * @interface View
 * @description Strapii view response for specified media
 */
export interface View {
  mediaId: string;
  accessAllowed: boolean;
  preAuthz: PreAuthzs | undefined;
}

interface Props {
  authorizeMediaIds(token: string, mediaIds: string[], errorMsgStore: ReactiveVar<ErrorMsgState>): Promise<string[]>;
  getPreAuths(authToken: string): Promise<PreAuthzs[] | null | AxiosError>;
  makeStrapiiCall(preAuth: PreAuthzs[] | null, mediaIds: string[]): Promise<Token[]>;
  makeStrapiiViewCall(preAuth: PreAuthzs[] | null, mediaIds: string[]): Promise<View[]>;
}

export interface APIs {
  regwallPreauth: string;
  authzToken: string;
  authzView: string;
}

export const useStrapii = (config: APIs, organization: string, authToken: string): Props => {
  const [preAuthzTimeout, setPreAuthzTimeout] = useState<number | null>(null);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    if (preAuthzTimeout && preAuthzTimeout > 0) {
      timeoutId = setTimeout(() => {
        getPreAuths(authToken);
      }, preAuthzTimeout);
    }
    return () => clearTimeout(timeoutId);
  }, [preAuthzTimeout]);

  /**
   * @function getPreAuths
   * @description Gets all preauthorizations for the user and checks to see if user has access to media
   */
  const getPreAuths = async (authToken: string): Promise<PreAuthzs[] | null | AxiosError> => {
    try {
      const headers = {
        headers: {
          Authorization: `${authToken}`,
          'Content-Type': 'application/json',
        },
      };

      const response = await Axios.post<PreAuthAxiosResponse>(config.regwallPreauth, null, headers);
      const { data } = response;

      if (data) {
        return data.preAuthorizedEntitlements;
      }
      return null;
    } catch (e) {
      const error = e as AxiosError;
      return Promise.reject(handleApiError(error));
    }
  };

  /**
   * @function makeStrapiiCall
   * @description Get JWT tokens for each authorized media request
   */
  const makeStrapiiCall = async (preAuths: PreAuthzs[] | null, mediaIds: string[]): Promise<Token[]> => {
    try {
      const body = {
        organization,
        mediaIds,
        preAuthzs: preAuths,
      };

      const headers = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const response = await Axios.post<StrapiiAxiosResponse>(config.authzToken, body, headers);

      const { results } = response.data;
      const tokens = results.filter((x) => x.accessAllowed);
      // move this out since this will be handled outside this hook;
      if (results[0].preAuthz) {
        const expireDate = results[0].preAuthz.expirationTimeMillis;
        const date = new Date();
        const timeout = expireDate - date.getTime();
        setPreAuthzTimeout(timeout);
      }

      return tokens;
    } catch (e) {
      const error = e as AxiosError;
      const serverErr = handleApiError(error);
      throw serverErr;
    }
  };

  /**
   * @function makeStrapiiCall
   * @description returns array of authorized media ids
   */

  const authorizeMediaIds = async (
    token: string,
    mediaIds: string[],
    errorMsgStore: ReactiveVar<ErrorMsgState>
  ): Promise<string[]> => {
    const authorized: string[] = [];
    await getPreAuths(token)
      .then(async (res) => {
        const response = res as PreAuthzs[] | null;
        if (response && response.length > 0) {
          if (mediaIds.length > 0) {
            await makeStrapiiCall(response, mediaIds).then((res) => {
              if (res[0].accessAllowed) {
                authorized.push(res[0].mediaId);
              }
            });
          }
        }
      })
      .catch((err) => {
        // errorMsgStore({
        //   type: 'AXIOS_ERROR',
        //   message: 'authorizedMediaIds function error',
        // });
        console.log(err);
      });
    return authorized;
  };

  /**
   * @function makeStrapiiCall
   * @description Get JWT tokens for each authorized media request
   */
  const makeStrapiiViewCall = async (preAuths: PreAuthzs[] | null, mediaIds: string[]): Promise<View[]> => {
    const emptyToken = [
      {
        mediaId: '',
        accessAllowed: false,
        preAuthz: undefined,
        token: '',
      },
    ];
    try {
      const body = {
        organization,
        mediaIds,
        preAuthzs: preAuths,
      };

      const headers = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const response = await Axios.post<StrapiiAxiosResponse>(config.authzView, body, headers);

      const { results } = response.data;
      return results.filter((x) => x.accessAllowed);
    } catch (e) {
      const error = e as AxiosError;
      const serverErr = handleApiError(error);
      return emptyToken;
    }
  };

  return {
    // token: tokens,
    authorizeMediaIds: async (
      token: string,
      mediaIds: string[],
      errorMsgStore: ReactiveVar<ErrorMsgState>
    ): Promise<string[]> => authorizeMediaIds(token, mediaIds, errorMsgStore),
    getPreAuths: async (authToken: string): Promise<PreAuthzs[] | null | AxiosError> => getPreAuths(authToken),
    makeStrapiiCall: async (preAuth: PreAuthzs[] | null, mediaIds: string[]): Promise<Token[]> =>
      makeStrapiiCall(preAuth, mediaIds),
    makeStrapiiViewCall: async (preAuth: PreAuthzs[] | null, mediaIds: string[]): Promise<View[]> =>
      makeStrapiiViewCall(preAuth, mediaIds),
  };
};

export default useStrapii;
