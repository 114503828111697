import React from 'react';
import { breakpointsStateStore } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { View, Text, ViewStyle, StyleProp, TextStyle } from 'react-native';
import { getStyles } from './cta.styles';
import { useReactiveVar } from '@apollo/client';
import { useTheme } from 'react-native-paper';

export type CtaProps = React.ComponentPropsWithRef<typeof View> & {
  /**
   * Icon
   */
  icon: JSX.Element;
  /**
   * Position of icon and text
   */
  position?: 'left' | 'center';
  /**
   * CTA Text
   */
  text?: string;
  /**
   * Styles to apply to container
   */
  style?: StyleProp<ViewStyle>;
  /**
   * Text container style
   */
  textContainStyle?: TextStyle;
  /**
   * Text style
   */
  textStyle?: TextStyle;
};

export const Cta = ({ icon, text, style, textStyle = {}, position, textContainStyle = {} }: CtaProps) => {
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const { colors } = useTheme();
  const styles = getStyles(breakpoints, colors);

  return (
    <View style={[styles.cta, style, position === 'left' ? styles.ctaIconLeft : {}]}>
      <View style={[position === 'left' ? styles.ctaInnerLeft : styles.ctaInner]}>
        <View style={[styles.icon, position === 'left' ? styles.iconLeft : styles.iconCenter]}>{icon}</View>
        <View style={[position === 'left' ? styles.textContainLeft : styles.textContainCenter, textContainStyle]}>
          {text && (
            <Text accessible={false} style={[styles.text, textStyle]}>
              {text}
            </Text>
          )}
        </View>
      </View>
    </View>
  );
};

export default Cta;
