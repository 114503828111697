import React, { FC, useCallback } from 'react';
import { languageStrings } from '@warnermmedia/gsp-core/brands/estadio/feature';
import { breakpointsStateStore, ModalType } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { Text, View } from 'react-native';
import { EstadioButton, ModalContentContainer, ModalWrapper } from '@warnermmedia/gsp-core/brands/estadio/ui';
import { getStyles } from './serviceWorkerModal.styles';
import { useReactiveVar } from '@apollo/client';
import { useTheme } from 'react-native-paper';

const ServiceWorkerWrapper: FC<{ visible: boolean }> = ({ visible }) => {
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const { colors } = useTheme();
  const styles = getStyles(breakpoints, colors);

  const reloadPage = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <ModalWrapper
      visible={visible}
      modalType={visible ? ModalType.AppUpdateModal : null}
      onClose={reloadPage}
      modalContentContainerType={ModalContentContainer.Full}
      modalMainContainerStyle={styles.container}
      modalLayoutContainerStyle={styles.fullScreenContainer}
    >
      <View style={styles.textContainer}>
        <Text style={styles.title}>{languageStrings.default.updateAppModalTitle}</Text>
        <View style={styles.containerInner}>
          <Text style={styles.description}>{languageStrings.default.updateAppModalDescription}</Text>
        </View>
        <EstadioButton
          mode="outline"
          label={languageStrings.default.updateAppModalBtnText}
          onPress={reloadPage}
          btnStyle={styles.buttonStyle}
          labelStyle={styles.buttonLabelStyle}
          focusKey={ModalType.AppUpdateModal}
        />
      </View>
    </ModalWrapper>
  );
};

export default ServiceWorkerWrapper;
