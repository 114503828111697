import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  wrapper: {
    width: '100%',
    maxWidth: 1000,
    padding: 20,
    backgroundColor: 'transparent',
  },
  label: {
    color: 'black',
    textAlign: 'left',
    fontSize: 18,
    fontWeight: '500',
    marginVertical: 15,
  },
  radioBtnWrapper: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
});
