import React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';

/* eslint-disable-next-line */
export interface YouTubeProps {
  width?: number;
  height?: number;
  color?: string;
  iconColor?: string;
}

export function Youtube(props: YouTubeProps) {
  const width = props.width || 20;
  const height = props.height || 20;
  const color = props.color || '#000A5A';
  const iconColor = props.iconColor || '#fff';

  return (
    <Svg fill="none" height={height} viewBox="0 0 28 28" width={width}>
      <Circle cx="14" cy="14" r="14" fill={color} />
      <Path
        fill={iconColor}
        d="M14.1898315,8.39890515 C15.0427727,8.40290627 18.5142555,8.43491518 19.4697457,8.6909865 C20.0721324,8.85197673 20.5465279,9.32642922 20.7074989,9.9288882 C20.9648999,10.8898509 20.995788,12.7695645 20.9994946,13.2068579 L20.9999813,13.2885833 L20.9999813,13.2885833 L21,13.2992979 C21,13.2992979 21,13.3029433 20.9999813,13.3100123 L20.9994946,13.3917361 C20.995788,13.8290208 20.9648999,15.7086919 20.7074989,16.669552 C20.5465279,17.2720109 20.0721324,17.7465801 19.4697457,17.9075704 C19.0602499,18.0172715 18.188633,18.0858346 17.2590712,18.1286866 L16.7913327,18.1480414 C15.5411331,18.1943216 14.3076762,18.1994638 14.0490093,18.2000352 L13.950138,18.2000352 C13.4651374,18.1989639 9.55314098,18.1818231 8.52940154,17.9075704 C7.92713153,17.7465801 7.45265818,17.2720109 7.29164838,16.669552 C7.04594741,15.7523674 7.00663526,13.9980016 7.00034531,13.4600845 L7.00034531,13.1385081 C7.00663526,12.6005797 7.04594741,10.8461708 7.29164838,9.9288882 C7.45265818,9.32642922 7.92713153,8.85197673 8.52940154,8.6909865 C9.48489169,8.43491518 12.9563746,8.40290627 13.8093158,8.39890515 Z M12.5994106,11.198879 L12.5994106,15.3997167 L16.2369099,13.2992979 L12.5994106,11.198879 Z"
      />
    </Svg>
  );
}

export default Youtube;
