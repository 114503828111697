import { StyleProp, StyleSheet, TextStyle, ViewStyle } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';
import merge from 'lodash/merge';

interface Styles {
  epgCardsLayout: ViewStyle;
  headline: TextStyle;
  forwardArrow: ViewStyle;
  backArrow: ViewStyle;
  forwardArrowGrad: ViewStyle;
  backArrowGrad: ViewStyle;
}
export const getStyles = (breakpoints: Breakpoints, isTv: boolean, colors: ReactNativePaper.ThemeColors) => {
  const base: StyleProp<Styles> = {
    epgCardsLayout: {
      marginTop: 10,
      marginBottom: 10,
      paddingBottom: 17,
    },
    headline: {
      fontFamily: 'Oswald-SemiBold',
      fontSize: 22,
      textTransform: 'uppercase',
      color: colors.keyboardFocus.light,
      paddingBottom: 16,
    },
    forwardArrow: {
      right: 0,
      paddingRight: 0,
      paddingLeft: 0,
    },
    backArrow: {
      left: 0,
      paddingRight: 0,
      paddingLeft: 0,
    },
    forwardArrowGrad: {
      height: '100%',
      paddingRight: 80,
      paddingLeft: 20,
      paddingVertical: 31,
    },
    backArrowGrad: {
      height: '100%',
      paddingRight: 20,
      paddingLeft: 5,
      paddingVertical: 31,
    },
  };

  const mediaQueries = isTv
    ? ({
        headline: {
          fontSize: 33,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isTiny ||
      breakpoints.currentBreakpoints.isTnySm ||
      breakpoints.currentBreakpoints.isSmMed
    ? ({
        epgCardsLayout: {
          paddingLeft: 4,
          paddingBottom: 17,
        },
        forwardArrowGrad: {
          paddingRight: 30,
          paddingLeft: 30,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isMedLg || breakpoints.windowWidth === breakpoints.breakpointSizes.lg
    ? ({
        epgCardsLayout: {
          paddingLeft: 4,
          paddingBottom: 17,
        },
        forwardArrowGrad: {
          paddingRight: 40,
          paddingLeft: 20,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isMedLg ||
      breakpoints.currentBreakpoints.isLgXl ||
      breakpoints.currentBreakpoints.isXlXxl ||
      breakpoints.currentBreakpoints.isXxl
    ? ({
        epgCardsLayout: {
          paddingLeft: 4,
          paddingBottom: 17,
        },
      } as StyleProp<Styles>)
    : {};
  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
