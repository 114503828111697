import React, { useEffect, useRef, useState, useContext } from 'react';
import { AppConfigContext } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import Zendesk from 'react-zendesk';
import { ZendeskAPI } from 'react-zendesk';
import { View } from 'react-native';
import { isZendeskLoaded } from '@warnermmedia/gsp-core/brands/estadio/data-access';

const ZendeskInc: React.FC = (): JSX.Element => {
  const appConfig = useContext(AppConfigContext);
  const [key, setKey] = useState<string>('');
  const ZENDESK_KEY = appConfig?.appConfig?.zendesk ?? '';

  const handleLocale = () => {
    isZendeskLoaded(true);
    ZendeskAPI('webWidget', 'setLocale', 'es');
  };

  useEffect(() => {
    setKey(ZENDESK_KEY);
  }, [ZENDESK_KEY]);

  return <View>{key !== '' ? <Zendesk zendeskKey={key} onLoaded={() => handleLocale()} /> : null}</View>;
};

// Avoid unnecessary re-renders
export default React.memo(ZendeskInc);
