interface ColorProps {
  [key: string]: string;
}

export function isHexColorCode(value: string) {
  return /^#(?:[A-Fa-f0-9]{3}){1,2}$/i.test(value);
}

export function isRGBColor(value: string) {
  return /^rgb[(](?:\s*0*(?:\d\d?(?:\.\d+)?(?:\s*%)?|\.\d+\s*%|100(?:\.0*)?\s*%|(?:1\d\d|2[0-4]\d|25[0-5])(?:\.\d+)?)\s*(?:,(?![)])|(?=[)]))){3}[)]$/i.test(
    value
  );
}

export function isRGBAColor(value: string) {
  return /^rgba[(](?:\s*0*(?:\d\d?(?:\.\d+)?(?:\s*%)?|\.\d+\s*%|100(?:\.0*)?\s*%|(?:1\d\d|2[0-4]\d|25[0-5])(?:\.\d+)?)\s*,){3}\s*0*(?:\.\d+|1(?:\.0+)?)\s*[)]$/i.test(
    value
  );
}

export function isHSLColor(value: string) {
  return /^hsl[(]\s*0*(?:[12]?\d{1,2}|3(?:[0-5]\d|60))\s*(?:\s*,\s*0*(?:\d\d?(?:\.\d+)?\s*%|\.\d+\s*%|100(?:\.0*)?\s*%)){2}\s*[)]$/i.test(
    value
  );
}

export function isHSLAColor(value: string) {
  return /^hsla[(]\s*0*(?:[12]?\d{1,2}|3(?:[0-5]\d|60))\s*(?:\s*,\s*0*(?:\d\d?(?:\.\d+)?\s*%|\.\d+\s*%|100(?:\.0*)?\s*%)){2}\s*,\s*0*(?:\.\d+|1(?:\.0+)?)\s*[)]$/i.test(
    value
  );
}

export function isColor(propValue: string) {
  return (
    isHexColorCode(propValue) ||
    isRGBColor(propValue) ||
    isRGBAColor(propValue) ||
    isHSLColor(propValue) ||
    isHSLAColor(propValue)
  );
}

export const colorPropType = (props: ColorProps, propName: string | number, componentName: string): Error | void => {
  if (!isColor(props[propName])) {
    return new Error(`Invalid prop ${propName} passed to component ${componentName}, invalid color value.`);
  }
};
