import merge from 'lodash/merge';
import { StyleProp, ViewStyle, StyleSheet, TextStyle, ImageStyle } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';

interface Styles {
  backgroundImage: ViewStyle;
  title: TextStyle;
}

export const getStyles = (breakpoints: Breakpoints, isTv: boolean, colors: ReactNativePaper.ThemeColors) => {
  const base: StyleProp<Styles> = {
    backgroundImage: {
      width: 158,
      height: 197,
      marginRight: 8,
    },
    title: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Rubik-Medium',
      fontSize: 16,
      fontWeight: '500',
      lineHeight: 20,
      paddingTop: 8,
      marginLeft: 8,
    },
  };

  const mediaQueries = isTv
    ? ({
        backgroundImage: {
          width: 264,
          height: 330,
          marginRight: 25,
        },
        title: {
          fontSize: 20,
          lineHeight: 24,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isMedLg ||
      breakpoints.currentBreakpoints.isLgXl ||
      breakpoints.windowWidth === breakpoints.breakpointSizes.lg
    ? ({
        backgroundImage: {
          width: 160,
          height: 199,
          marginRight: 16,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isXlXxl || breakpoints.currentBreakpoints.isXxl
    ? ({
        backgroundImage: {
          width: 240,
          height: 300,
          marginRight: 16,
        },
      } as StyleProp<Styles>)
    : {};
  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
