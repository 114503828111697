import React, { useContext, useEffect, useState } from 'react';
import { Dimensions, ScrollView, View } from 'react-native';
import { getStyles } from './register.styles';
import { useReactiveVar } from '@apollo/client';
import get from 'lodash/get';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  AppConfigContext,
  breakpointsStateStore,
  componentTypes,
  Page,
} from '@warnermmedia/gsp-core/brands/estadio/data-access';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  carouselFormat,
  PagesUrl,
  QueryNames,
  useCMSPageData,
  useCMSPageMetaTags,
  useCMSQueryDataResults,
  useIsMountedRef,
  useMparticleScreenEvent,
} from '@warnermmedia/gsp-core/brands/estadio/feature';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { MetaTags, useGetDevice } from '@warnermmedia/gsp-core/sdk/ui';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { MParticleAppConfigParams } from '@warnermmedia/gsp-core/sdk/data-access';
import { useTheme } from 'react-native-paper';
import { LandingHero } from '../landingHero/landingHero';
import { LandingProduct } from './landingProduct/landingProduct';
import { LandingHowTo } from './landingHowTo/landingHowTo';
import { LandingDevice } from './landingDevice/landingDevice';
import { LandingOndemand } from './landingOndemand/landingOndemand';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { SkeletonDisplay } from '@warnermmedia/gsp-core/brands/estadio/ui';

export const Register = () => {
  const { pageData: pageUriData, loading } = useCMSQueryDataResults<Page>({
    queryName: QueryNames.GET_PRODUCTS,
    uri: PagesUrl.Register,
  });
  const [display, setDisplay] = useState<carouselFormat[] | null>(null);
  const { metaTags, title, type, cmsId } = useCMSPageMetaTags(pageUriData);
  const [mParticleEventOptions, setMparticleEventOptions] = useState<MParticleAppConfigParams>(
    {} as MParticleAppConfigParams
  );
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const { colors } = useTheme();
  const { width } = Dimensions.get('window');
  const styles = getStyles(breakpoints, colors);
  const [shouldFocusForm, setShouldFocusForm] = useState(false);
  const screenComponents = display ? display.filter(({ type }) => type === componentTypes.SCREEN) : [];
  const landingHero = screenComponents.find(({ id }) => id === 'landing-hero');
  const landingProduct = get(landingHero, 'content[0].product[0]', null);
  const productDesc = screenComponents.find(({ id }) => id === 'landing-product-desc');
  const productClip = get(productDesc, 'content[0].clips[0]', null);
  const productImage = get(productDesc, 'images[0].image.url', '');
  const howTo = screenComponents.find(({ id }) => id === 'landing-how-to');
  const valueProp = screenComponents.find(({ id }) => id === 'value-prop');
  const valueImage = get(valueProp, 'images[0].image.url', '');
  const liveOndemand = screenComponents.find(({ id }) => id === 'live-ondemand');
  const liveOndemandImage = get(liveOndemand, 'images[0].image.url', '');
  const ondemandContent = get(liveOndemand, 'content[0].item', '');
  const appConfig = useContext(AppConfigContext);
  const isRegisterEnabled = appConfig?.appConfig?.registerEnabled ?? true;
  const commonProps = { breakpoints: breakpoints, width, colors, isRegisterEnabled };
  const { formatData } = useCMSPageData();
  useMparticleScreenEvent(type, title, cmsId);
  const isMountedRef = useIsMountedRef();
  const { isWeb } = useGetDevice();

  useEffect(() => {
    if (pageUriData && pageUriData.components && isMountedRef.current) {
      setDisplay(formatData(pageUriData.components));
    }
  }, [pageUriData, isMountedRef]);

  useEffect(() => {
    if (isWeb && isMountedRef.current) {
      const inputs = Array.from(document.getElementsByTagName('input'));
      inputs.forEach((input) => {
        input.tabIndex = 0;
      });
    }
  }, [isWeb, isMountedRef]);

  useEffect(() => {
    if (isMountedRef.current) {
      setMparticleEventOptions({
        section: type,
        contentTitle: title,
        contentId: cmsId,
      });
    }
  }, [title, type, cmsId, isMountedRef]);

  return (
    <>
      {isWeb && <MetaTags data={metaTags} title={title} />}
      {!loading ? (
        <ScrollView>
          <View style={styles.pageContainer}>
            {landingHero ? (
              <LandingHero
                landingImages={landingHero.images}
                mParticleEventOptions={mParticleEventOptions}
                shouldFocusForm={shouldFocusForm}
                setShouldFocusForm={setShouldFocusForm}
                backgroundImage={landingHero?.image?.url}
                product={landingProduct}
                ctaLink={landingHero?.link}
                {...commonProps}
              />
            ) : null}
            {productDesc ? (
              <LandingProduct
                productHeadline={productDesc?.headline}
                productDescription={productDesc?.description}
                productImage={productImage}
                productClip={productClip}
                {...commonProps}
              />
            ) : null}
            {howTo && isRegisterEnabled ? (
              <LandingHowTo
                headLine={howTo.headline}
                tutorialContents={howTo.content}
                breakpoints={breakpoints}
                colors={colors}
              />
            ) : null}
            {valueProp ? (
              <LandingDevice
                headLine={valueProp.headline}
                valueDescription={valueProp.description}
                valueImage={valueImage}
                valueContents={valueProp.content}
                setShouldFocusForm={setShouldFocusForm}
                ctaLink={valueProp?.link}
                {...commonProps}
              />
            ) : null}
            {liveOndemand ? (
              <LandingOndemand
                liveHeadLine={liveOndemand.headline}
                liveDescription={liveOndemand.description}
                ondemandHeadLine={ondemandContent?.headline}
                ondemandDescription={ondemandContent?.description}
                image={liveOndemandImage}
                {...commonProps}
              />
            ) : null}
          </View>
        </ScrollView>
      ) : (
        <SkeletonDisplay route="/register" />
      )}
    </>
  );
};

export default Register;
