import React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';

/* eslint-disable-next-line */
export interface HeartProps {
  width?: number;
  height?: number;
  color?: string;
  iconColor?: string;
}

export function Heart(props: HeartProps) {
  const width = props.width || 20;
  const height = props.height || 20;
  const color = props.color || '#359fd7';
  const iconColor = props.iconColor || '#fff';

  return (
    <Svg fill="none" height={height} viewBox="0 0 20 20" width={width}>
      <Circle cx="10" cy="10" r="10" fill={color} />
      <Path
        fill={iconColor}
        d="M12.28 5.907c1.5.007 2.713 1.22 2.72 2.72 0 2.746-5 5.466-5 5.466s-5-2.76-5-5.466c0-1.502 1.218-2.72 2.72-2.72.92-.007 1.78.455 2.28 1.226.504-.767 1.362-1.228 2.28-1.226z"
      />
    </Svg>
  );
}

export default Heart;
