import { NativeModules } from 'react-native';
import get from 'lodash/get';
import { isWebPlatform } from '../Utils/Platform';

const getCurrentLocale = (): string =>
  !isWebPlatform()
    ? // Platform.OS === ios
      get(
        NativeModules,
        'SettingsManager.settings.AppleLocale',
        // iOS 13 workaround, take first of AppleLanguages array  ["en", "en-US"]
        get(
          NativeModules,
          'SettingsManager.settings.AppleLanguages[0]',
          // else (not ios)
          get(NativeModules, 'I18nManager.localeIdentifier', 'es_US')
        )
      )
    : // (Netscape - Browser Localization)
      get(
        window,
        'navigator.userLanguage',
        // (Netscape - Browser Localization)
        get(
          window,
          'navigator.language',
          // (IE-Specific - Browser Localized Language)
          get(
            window,
            'navigator.browserLanguage',
            // (IE-Specific - Windows OS - Localized Language)
            get(window, 'navigator.systemLanguage', 'es_US')
          )
        )
      );

export default getCurrentLocale;
