import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Image,
  ImageStyle,
  NativeSyntheticEvent,
  Pressable,
  Text,
  TextInput,
  TextInputKeyPressEventData,
  TextInputProps,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import styles from './textInput.style';
import { KeyCodes, useGetDevice, useSpatialNavigation } from '@warnermmedia/gsp-core/sdk/ui';
import { getUniqueId } from '@warnermmedia/gsp-core/brands/estadio/feature';
import { ItemName } from '@warnermmedia/gsp-core/sdk/data-access';

export interface Props extends TextInputProps {
  inputStyle?: TextStyle | TextStyle[];
  icon?: string | JSX.Element;
  iconStyle?: ImageStyle;
  containerStyle?: ViewStyle | ViewStyle[];
  iconPosition?: 'left' | 'right';
  handleIconClick?: () => void;
  error?: boolean;
  onBlur?: () => void;
  onKeyPress?: (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => void;
  inputLabel?: string | JSX.Element | React.ReactNode;
  inputLabelStyle?: TextStyle;
  shouldFocus?: boolean;
  focusKey?: string;
  shouldUseFourArrow?: boolean;
}

export const CustomTextInput = ({
  inputStyle,
  icon,
  iconStyle,
  containerStyle,
  iconPosition,
  handleIconClick,
  error,
  onBlur,
  onKeyPress,
  inputLabel,
  inputLabelStyle,
  shouldFocus = false,
  focusable = true,
  shouldUseFourArrow = false,
  focusKey,
  ...props
}: Props) => {
  const { isTv, isWeb } = useGetDevice();
  const iconIsLeft = iconPosition === 'left';
  const iconPositionStyle = iconIsLeft ? styles.leftIcon : styles.rightIcon;
  let inputWithIconStyle = {};
  if (icon) {
    inputWithIconStyle = iconIsLeft ? styles.inputWithLeftIcon : styles.inputWithRightIcon;
  }
  const [isFocused, setIsFocused] = useState(false);
  const textRef = useRef<TextInput>(null);
  const textFocusKey = useRef(focusKey ?? getUniqueId(ItemName.TEXT_ITEM)).current;
  const { ref, focused } = useSpatialNavigation({
    focusKey: textFocusKey,
    onBlur: () => {
      setIsFocused(false);
    },
  });
  const errorStyle = isFocused ? styles.focused : error ? styles.error : {};

  useEffect(() => {
    if (textRef.current && (shouldFocus || focused)) {
      textRef.current.focus();
      setIsFocused(true);
    }
  }, [textRef, shouldFocus, focused]);

  const onArrowKeyPress = useCallback(
    (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (e.keyCode === KeyCodes.UpArrow || e.keyCode === KeyCodes.DownArrow) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        e.target.blur();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
      } else if (shouldUseFourArrow && (e.keyCode === KeyCodes.LeftArrow || e.keyCode === KeyCodes.RightArrow)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        e.target.blur();
      }
    },
    [shouldUseFourArrow]
  );

  const renderIcon = useCallback(
    () => (
      <Pressable
        onPress={() => {
          handleIconClick && handleIconClick();
        }}
        style={[styles.iconWrapper, iconPositionStyle]}
      >
        {typeof icon === 'string' ? (
          <Image source={{ uri: icon }} style={[styles.icon, iconStyle]} />
        ) : (
          <View style={[styles.svgIcon, iconStyle]}>{icon}</View>
        )}
      </Pressable>
    ),
    [handleIconClick, iconPositionStyle, icon, iconStyle]
  );

  const content = typeof inputLabel === 'string' ? <Text style={inputLabelStyle}>{inputLabel}</Text> : inputLabel;

  return (
    <View style={[styles.container, containerStyle]} {...(focusable ? { ref } : {})}>
      {inputLabel ? content : null}
      <TextInput
        ref={textRef}
        style={[
          styles.input,
          inputWithIconStyle,
          inputStyle,
          errorStyle,
          focusable && isFocused && !isTv && { borderColor: '#359fd7', borderWidth: 2 },
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          /* @ts-ignore: properties for correct outlining. */
          focusable && isFocused && isWeb ? { outline: '2px solid #359fd7', opacity: 1 } : {},
        ]}
        onBlur={() => {
          setIsFocused(false);
          onBlur && onBlur();
        }}
        onKeyPress={onKeyPress ?? onArrowKeyPress}
        focusable={focusable}
        nativeID={textFocusKey}
        {...props}
      />
      {icon && renderIcon()}
    </View>
  );
};

export default CustomTextInput;
