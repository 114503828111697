import { ImageStyle, StyleProp, StyleSheet, TextStyle, ViewStyle } from 'react-native';
import merge from 'lodash/merge';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';

interface Styles {
  wrapper: ViewStyle;
  imageContainer: ViewStyle;
  seriesImage: ImageStyle;
  contentContainer: ViewStyle;
  title: TextStyle;
  description: TextStyle;
  genericImage: ImageStyle;
}
export const getStyles = (breakpoints: Breakpoints, isTv: boolean) => {
  const base: StyleProp<Styles> = {
    wrapper: {
      flexDirection: 'row',
      width: '100%',
      flex: 1,
      paddingLeft: 13,
      paddingBottom: 17,
      marginBottom: 32,
      marginTop: 16,
    },
    imageContainer: {
      alignItems: 'center',
      justifyContent: 'center',
    },
    contentContainer: {
      width: '60%',
      marginLeft: 15,
      marginTop: 18,
      alignContent: 'flex-start',
    },
    title: {
      color: 'rgb(255, 255, 255)',
      fontSize: 33,
      fontFamily: 'Oswald-SemiBold',
      fontWeight: '600',
      textTransform: 'uppercase',
      marginBottom: 4,
    },
    seriesImage: {
      height: 103,
      width: 103,
      borderRadius: 0,
    },
    description: {
      color: 'rgb(255, 255, 255)',
      fontSize: 16,
      fontFamily: 'Rubik-Regular',
      fontWeight: 'normal',
      textAlign: 'justify',
      lineHeight: 20,
    },
    genericImage: {
      alignSelf: 'center',
      top: 0,
      height: '100%',
      width: '100%',
      position: 'relative',
    },
  };
  const mediaQueries = isTv
    ? ({
        imageContainer: {
          alignItems: 'center',
          justifyContent: 'flex-start',
          marginLeft: 16,
        },
        contentContainer: {
          marginTop: 54,
        },
        title: {
          fontSize: 44,
        },
        seriesImage: {
          height: 194,
          width: 194,
        },
        description: {
          lineHeight: 32,
          fontSize: 24,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isTiny || breakpoints.currentBreakpoints.isTnySm
    ? ({
        wrapper: {
          flexDirection: 'column',
          paddingLeft: 14,
        },
        imageContainer: {
          alignItems: 'flex-start',
          marginLeft: 16,
        },
        contentContainer: {
          width: '90%',
          marginTop: 18,
          alignContent: 'flex-start',
        },
        title: {
          fontSize: 33,
          marginBottom: 4,
        },
        seriesImage: {
          height: 101,
          width: 101,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isSmMed
    ? ({
        wrapper: {
          flexDirection: 'column',
          paddingLeft: 20,
        },
        imageContainer: {
          alignItems: 'flex-start',
          marginLeft: 20,
        },
        contentContainer: {
          marginTop: 18,
          marginLeft: 20,
        },
        title: {
          fontSize: 33,
          marginBottom: 4,
        },
        seriesImage: {
          height: 101,
          width: 101,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isMedLg || breakpoints.windowWidth === breakpoints.breakpointSizes.lg
    ? ({
        wrapper: {
          flexDirection: 'row',
          paddingLeft: 40,
        },
        imageContainer: {
          alignItems: 'center',
          justifyContent: 'flex-start',
        },
        contentContainer: {
          width: '70%',
          marginLeft: 16,
          marginTop: 2,
          alignContent: 'flex-start',
        },
        seriesImage: {
          height: 101,
          width: 101,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isLgXl ||
      breakpoints.currentBreakpoints.isXlXxl ||
      breakpoints.currentBreakpoints.isXxl
    ? ({
        imageContainer: {
          alignItems: 'center',
          justifyContent: 'flex-start',
          marginLeft: 16,
        },
        title: {
          fontSize: 33,
        },
        seriesImage: {
          height: 111,
          width: 111,
        },
      } as StyleProp<Styles>)
    : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
