import { StyleProp, ViewStyle, StyleSheet, TextStyle, ImageStyle } from 'react-native';
import merge from 'lodash/merge';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';

interface Styles {
  pipSlide: ViewStyle;
  pipSlideActive: ViewStyle;
  pipSlideInner: ViewStyle;
  pipMobileStyle: ViewStyle;
}

export const getStyles = (breakpoints: Breakpoints, colors: ReactNativePaper.ThemeColors) => {
  const base: StyleProp<Styles> = {
    // PIP
    pipSlide: {
      width: 20,
      maxWidth: 20,
      height: 3,
      backgroundColor: colors.tenantBackground.light.surfaceBase,
      borderRadius: 2.5,
      marginRight: 2.5,
      marginLeft: 2.5,
      marginTop: 5,
    },
    pipSlideActive: {
      backgroundColor: colors.fill.action.accent02,
    },
    pipSlideInner: {
      borderRadius: 5,
    },
    pipMobileStyle: {
      flexDirection: 'row',
      justifyContent: 'center',
      marginHorizontal: 50,
    },
  };

  const mediaQueries = breakpoints.currentBreakpoints.isTiny ? ({} as StyleProp<Styles>) : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
