import { useEffect, useState } from 'react';
import { useGetBillingStatus, useIsMountedRef } from '@warnermmedia/gsp-core/brands/estadio/feature';
import useValidateEmailCode from '../useValidateEmailCode/useValidateEmailCode';
import { useReactiveVar } from '@apollo/client';
import {
  isAppTransitioning,
  isUserLoggedIn,
  userHasSubscription,
} from '@warnermmedia/gsp-core/brands/estadio/data-access';
import useEmailStatus from '../useEmailStatus/useEmailStatus';
import { isEmpty } from 'lodash';
import { DALTON_AUTH_TOKEN, loadData, TVE_TOOLBOX_AUTH_TOKEN } from '@warnermmedia/gsp-core/sdk/data-access';

export const useFirstLoadingStatus = () => {
  const isLoggedIn = useReactiveVar(isUserLoggedIn);
  const { isEmailVerified } = useEmailStatus();
  const hasSubscription = useReactiveVar(userHasSubscription);
  const isTransitioning = useReactiveVar(isAppTransitioning);
  const loadingBillingProfile = useGetBillingStatus();
  const { verifyingEmailCode } = useValidateEmailCode();
  const [isAppLoading, setIsAppLoading] = useState(true);
  const isMountedRef = useIsMountedRef();

  useEffect(() => {
    if (isMountedRef.current) {
      const tokenSaved = !isEmpty(loadData(TVE_TOOLBOX_AUTH_TOKEN)) || !isEmpty(loadData(DALTON_AUTH_TOKEN));
      setIsAppLoading(
        (loadingBillingProfile || verifyingEmailCode || isEmailVerified === null || hasSubscription === null) &&
          (isLoggedIn || tokenSaved) &&
          !isTransitioning
      );
    }
  }, [
    loadingBillingProfile,
    verifyingEmailCode,
    hasSubscription,
    isLoggedIn,
    isMountedRef,
    isTransitioning,
    isEmailVerified,
  ]);

  return isAppLoading;
};

export default useFirstLoadingStatus;
