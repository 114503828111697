import React, { useContext, useState } from 'react';
import { Text, View } from 'react-native';
import { UniversalSwimlane } from '../../swimlanes/universal/universalSwimlane';
import { Breakpoints, DeviceTypes, mockMatchesPage } from '@warnermmedia/gsp-core/sdk/ui';
import { carouselBase, LayoutUpdateContext, useSetCardIndex } from '@warnermmedia/gsp-core/brands/estadio/feature';
import { getStyles } from './matchVideos.styles';
import { CAROUSEL_KEY } from '@warnermmedia/gsp-core/sdk/data-access';

export const MatchVideos = ({
  videoItems,
  itemClick,
  breakpoints,
  colors,
  device,
}: {
  videoItems: carouselBase[];
  itemClick: (item: carouselBase) => void;
  breakpoints: Breakpoints;
  device: DeviceTypes;
  colors: ReactNativePaper.ThemeColors;
}) => {
  const styles = getStyles(breakpoints, device.isTv, colors);
  const [videoIndex, setVideoIndex] = useState(0);
  const contentWidth = useContext(LayoutUpdateContext);
  const { setIndex } = useSetCardIndex(videoIndex, setVideoIndex);

  // Gets called when item click/tap
  const clickAction = (item: carouselBase) => {
    if (typeof item !== 'undefined') {
      // Navigate to video page
      itemClick(item);
    }
    setIndex(videoIndex);
  };

  return (
    <View>
      <View style={styles.left}>
        <Text style={styles.textStyle}>{mockMatchesPage.topText}</Text>
      </View>

      {videoItems.length > 0 && (
        <UniversalSwimlane
          headline={''}
          index={videoIndex}
          indexUpdate={setIndex}
          data={videoItems}
          action={clickAction}
          laneWidth={contentWidth}
          device={device}
          colors={colors}
          breakpoints={breakpoints}
          carouselFocusKey={CAROUSEL_KEY(2)}
        />
      )}
    </View>
  );
};

export default MatchVideos;
