import { AxiosResponse } from 'axios';
import { ApiResponse } from './dalton-types';

export enum GeoPath {
  GET_LOCATE = '/v2/locate',
}

export interface GetLocateResponse extends AxiosResponse {
  ip: string;
  city: string;
  state: string;
  zipcodes: string[];
  counties: string[];
  country: string;
  countryAlpha2: string;
  countryAlpha3: string;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  proxy: any;
}

export type GeoGetLocateResponse = ApiResponse<GetLocateResponse>;
