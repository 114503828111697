import React from 'react';
import { EventSort, Feeds, SeriesSort, SortDirections } from '@warnermmedia/gsp-core/brands/estadio/data-access';

export enum QueryNames {
  GET_HOME_PAGE = 'GET_HOME_PAGE',
  GET_HOME_PAGE_CACHED = 'GET_HOME_PAGE_CACHED',
  GET_SERIES_LIST = 'GET_SERIES_LIST',
  GET_ALL_EVENTS = 'GET_ALL_EVENTS',
  GET_EVENT_BY_ID = 'GET_EVENT_BY_ID',
  GET_VIDEO_BY_ID = 'GET_VIDEO_BY_ID',
  GET_VIDEO_BY_EVENT_ID = 'GET_VIDEO_BY_EVENT_ID',
  GET_SCHEDULE_FEED = 'GET_SCHEDULE_FEED',
  GET_SERIES_BY_ID = 'GET_SERIES_BY_ID',
  GET_COMPETITOR_BY_ID = 'GET_COMPETITOR_BY_ID',
  GET_LATEST_CLIPS = 'GET_LATEST_CLIPS',
  GET_TEAMS = 'GET_TEAMS',
  GET_TEAMS_CACHED = 'GET_TEAMS_CACHED',
  GET_TICKER_EVENTS = 'GET_TICKER_EVENTS',
  GET_TOURNAMENTS = 'GET_TOURNAMENTS',
  GET_TOURNAMENTS_CACHED = 'GET_TOURNAMENTS_CACHED',
  GET_PROGRAMS = 'GET_PROGRAMS',
  GET_PROGRAMS_CACHED = 'GET_PROGRAMS_CACHED',
  GET_PROGRAM = 'GET_PROGRAM',
  GET_PROGRAM_CACHED = 'GET_PROGRAM_CACHED',
  GET_PRODUCTS = 'GET_PRODUCTS',
  GET_COMMON_PAGE_DATA = 'GET_COMMON_PAGE_DATA',
}

export const CatchQueryMapsNames = {
  GET_HOME_PAGE: QueryNames.GET_HOME_PAGE_CACHED,
  GET_SERIES_LIST: QueryNames.GET_SERIES_LIST,
  GET_ALL_EVENTS: QueryNames.GET_ALL_EVENTS,
  GET_EVENT_BY_ID: QueryNames.GET_EVENT_BY_ID,
  GET_VIDEO_BY_ID: QueryNames.GET_VIDEO_BY_ID,
  GET_VIDEO_BY_EVENT_ID: QueryNames.GET_VIDEO_BY_EVENT_ID,
  GET_SCHEDULE_FEED: QueryNames.GET_SCHEDULE_FEED,
  GET_SERIES_BY_ID: QueryNames.GET_SERIES_BY_ID,
  GET_COMPETITOR_BY_ID: QueryNames.GET_COMPETITOR_BY_ID,
  GET_LATEST_CLIPS: QueryNames.GET_LATEST_CLIPS,
  GET_TEAMS: QueryNames.GET_TEAMS_CACHED,
  GET_TICKER_EVENTS: QueryNames.GET_TICKER_EVENTS,
  GET_TOURNAMENTS: QueryNames.GET_TOURNAMENTS_CACHED,
  GET_PROGRAMS: QueryNames.GET_PROGRAMS_CACHED,
  GET_PROGRAM: QueryNames.GET_PROGRAM_CACHED,
  GET_PRODUCTS: QueryNames.GET_PRODUCTS,
  GET_COMMON_PAGE_DATA: QueryNames.GET_COMMON_PAGE_DATA,
};

export const QueryResultNameMap = {
  GET_HOME_PAGE: 'getPageByUri',
  GET_ALL_EVENTS: 'getAllEvents',
  GET_SCHEDULE_FEED: 'getScheduleByFeeds',
  GET_VIDEO_BY_EVENT_ID: 'getVideosByEventId',
  GET_VIDEO_BY_ID: 'getVideoById',
  GET_EVENT_BY_ID: 'getEventById',
  GET_SERIES_BY_ID: 'getSeriesById',
  GET_SERIES_LIST: 'getSeriesList',
  GET_COMPETITOR_BY_ID: 'getCompetitorById',
  GET_LATEST_CLIPS: 'getVideosByTeamId',
  GET_TEAMS: 'getPageByUri',
  GET_TICKER_EVENTS: 'getAllEvents',
  GET_TOURNAMENTS: 'getPageByUri',
  GET_PROGRAMS: 'getPageByUri',
  GET_PROGRAM: 'getPageByUri',
  GET_PRODUCTS: 'getPageByUri',
  GET_COMMON_PAGE_DATA: 'getPageByUri',
};

export enum PagesUrl {
  Home = '/es/home',
  TvSignal = '/es/tvsignal',
  ForgotPassword = '/es/forgot-password',
  Register = '/es/landing-v2',
  Subscription = '/es/subscription',
  CancelSubscription = '/es/cancel-subscription',
  Login = '/es/login',
  Tournaments = '/es/tournaments',
  Shows = '/es/programs',
  Retention = '/es/retention',
  CodeValidation = '/es/pin-and-pair-code-validation',
  CodeValidationSuccess = '/es/pin-and-pair-code-validation-success',
  TvLanding = '/es/pin-and-pair-tv-landing',
  GolfChannel = '/es/golfchannel',
}

export enum TeamPagesUrl {
  TeamsList = '/es/fav-team-pick-list',
  Teams = '/es/teams',
}

export enum EstadioEventsCallTypes {
  Ticker = 'Ticker',
  Series = 'Series',
  Tournament = 'Tournament',
  Team = 'Team',
}

export enum DerivedStatus {
  Upcoming = 'upcoming',
  Live = 'live',
  Ended = 'ended',
  Replay = 'replay',
}

export interface CMSQueryVariables {
  uri: PagesUrl;
  teamId?: string;
  showId?: string;
  queryName:
    | QueryNames.GET_HOME_PAGE
    | QueryNames.GET_PROGRAMS
    | QueryNames.GET_TOURNAMENTS
    | QueryNames.GET_PROGRAM
    | QueryNames.GET_PRODUCTS
    | QueryNames.GET_COMMON_PAGE_DATA;
}

export interface CMSTeamsQueryVariables {
  uri: TeamPagesUrl;
  queryName: QueryNames.GET_TEAMS;
}

export interface AllEventsQueryVariables {
  callType: EstadioEventsCallTypes;
  id?: string;
  countStart?: string;
  countEnd?: string;
  season?: string;
  queryName: QueryNames.GET_ALL_EVENTS | QueryNames.GET_TICKER_EVENTS;
  sort?: EventSort;
  order?: SortDirections;
  mediaFilter?: DerivedStatus;
}

export interface AllSeriesListQueryVariables {
  tournamentId: string;
  countStart?: number;
  countEnd?: number;
  season?: string;
  queryName: QueryNames.GET_SERIES_LIST;
  sort?: SeriesSort;
  order?: SortDirections;
}

export interface EPGQueryVariables {
  feed?: Feeds;
  from?: string;
  to?: string;
  queryName: QueryNames.GET_SCHEDULE_FEED;
}

export interface GenericGetByIdQueryVariables {
  id: string;
  queryName:
    | QueryNames.GET_VIDEO_BY_EVENT_ID
    | QueryNames.GET_SERIES_BY_ID
    | QueryNames.GET_COMPETITOR_BY_ID
    | QueryNames.GET_LATEST_CLIPS;
}

export interface GetEventByIdQueryVariables {
  id: string;
  queryName: QueryNames.GET_EVENT_BY_ID;
  isSportRadar?: boolean;
}

export interface GetVideoByIdQueryVariables {
  id: string;
  queryName: QueryNames.GET_VIDEO_BY_ID;
  publishedOnly?: boolean;
}

export type QueryVariablesType =
  | CMSQueryVariables
  | AllEventsQueryVariables
  | EPGQueryVariables
  | GenericGetByIdQueryVariables
  | CMSTeamsQueryVariables
  | GetEventByIdQueryVariables
  | GetVideoByIdQueryVariables
  | AllSeriesListQueryVariables;
