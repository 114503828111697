/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { useCallback, useContext, useEffect, useState } from 'react';
import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  from,
  InMemoryCache,
  NormalizedCacheObject,
  ServerError,
  ServerParseError,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import {
  Constants,
  Dalton,
  getCurrentLocale,
  isWebPlatform,
  useIsMountedRef,
} from '@warnermmedia/gsp-core/brands/estadio/feature';
import { configStateStore } from './ApolloProvider';
import { TypePolicies } from './TypePolicies';
import { AsyncStorageWrapper, CachePersistor, LocalStorageWrapper } from 'apollo3-cache-persist';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { createPersistLink, persistenceMapper } from './utils/persistence';
import { AppConfigContext } from '../AppConfig/AppConfigProvider';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { sha256 } from 'react-native-sha256';

const authLink = setContext((_, { headers }) => {
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,

      // Tie in KillSwicth feature and Language Selection
      app_version: Constants.APP_VERSION,
      language: getCurrentLocale(),
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    );

  if (networkError) {
    if ((networkError as ServerError | ServerParseError)?.statusCode === 401) {
      // Let's Log user out for security reasons
      Dalton().logout((result) =>
        console.log('Problem with Token -> logout() -> networkError.statusCode -> 401 => ', result)
      );
    }
  } else {
    const { response } = operation.getContext();

    if (response) {
      const { headers } = response;

      if (headers) {
        configStateStore({
          ...configStateStore(),
          cacheControl: (parseInt(headers.get('cache-control') ?? 60) as number) * 1000, //must be in miliseconds.
        });
      }
    }
  }
});

const useApolloClient = () => {
  //get appconfig context.
  const cache = new InMemoryCache(TypePolicies);
  const appConfig = useContext(AppConfigContext);
  const [httpLink, setHttpLink] = useState<ApolloLink | null>(null);
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();
  const [persistor, setPersistor] = useState<CachePersistor<NormalizedCacheObject>>();
  const isMountedRef = useIsMountedRef();
  const clearCache = useCallback(() => {
    if (!persistor) {
      return;
    }
    persistor.purge();
  }, [persistor]);

  const intializeApollo = async (link: ApolloLink) => {
    try {
      const newPersistor = !isWebPlatform()
        ? new CachePersistor({
            cache,
            storage: new AsyncStorageWrapper(AsyncStorage),
            debug: __DEV__,
            trigger: 'write',
            persistenceMapper,
          })
        : new CachePersistor({
            cache,
            storage: new LocalStorageWrapper(window.localStorage),
            debug: __DEV__,
            trigger: 'write',
          });

      await newPersistor.restore();
      setPersistor(newPersistor);

      const persistLink = createPersistLink();

      setClient(
        new ApolloClient({
          link: from([errorLink, persistLink.concat(authLink.concat(link))]),
          cache,
          //add version and name!
        })
      );
    } catch (uACError) {
      console.log('useApolloClient() =>', uACError);
    }
  };

  useEffect(() => {
    if (httpLink && isMountedRef.current) intializeApollo(httpLink);
  }, [httpLink, isMountedRef]);

  useEffect(() => {
    if (
      appConfig?.appConfig?.apis?.federatedAPI !== '' &&
      appConfig?.appConfig.federated.id !== '' &&
      isMountedRef.current
    ) {
      const link = createHttpLink({
        uri: appConfig?.appConfig.apis.federatedAPI,
        headers: {
          authorization: `Bearer ${appConfig?.appConfig.federated.id}`,
        },
        fetchOptions: {
          mode: 'cors',
        },
      });

      const plink = createPersistedQueryLink({
        sha256,
        useGETForHashedQueries: true,
      }).concat(link);

      setHttpLink(plink);
    }
  }, [appConfig?.appConfig?.apis?.federatedAPI, appConfig?.appConfig.federated.id, isMountedRef]);

  return {
    errorLink,
    authLink,
    httpLink,
    client,
    clearCache,
  };
};

export default useApolloClient;
