import React from 'react';
import { Text, View } from 'react-native';
import { CardProps, getLocalDate } from '@warnermmedia/gsp-core/sdk/ui';
import { getStyles } from './epgCard.styles';
import { carouselBase } from '@warnermmedia/gsp-core/brands/estadio/feature';

import { CHILE_TIMEZONE } from '@warnermmedia/gsp-core/brands/estadio/data-access';

export const EpgCard = ({ item, breakpoints, colors, device }: CardProps<carouselBase>) => {
  const styles = getStyles(breakpoints, device.isTv, colors);

  const getShowTime = (date?: string) => {
    const formattedDate = date && getLocalDate(date, CHILE_TIMEZONE);
    return formattedDate ? formattedDate.format('HH:mm') : '';
  };

  if (!item.show) {
    return <View />;
  }

  return (
    <View style={styles.container}>
      <Text style={styles.airDateTime}>{getShowTime(item.show?.airDateTime)}</Text>
      <Text ellipsizeMode="clip" numberOfLines={2} style={styles.title}>
        {item.show?.originalTitle ?? ''}
      </Text>
    </View>
  );
};

export default EpgCard;
