import { useEffect } from 'react';
import { MPARTICLE_EVENT_PUSH, mParticleEventProcessor } from '@warnermmedia/gsp-core/sdk/data-access';
import { useIsMountedRef } from './useIsMountedRef';

export function useMparticleEventProcessor() {
  const isMountedRef = useIsMountedRef();

  useEffect(() => {
    if (mParticleEventProcessor.eventEmitter && isMountedRef.current) {
      mParticleEventProcessor.eventEmitter.addListener(MPARTICLE_EVENT_PUSH, () => {
        mParticleEventProcessor.processMParticleEvents();
      });
    }
    return () => {
      mParticleEventProcessor?.eventEmitter?.removeAllListeners();
    };
  }, []);

  return null;
}
