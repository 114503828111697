import { FooterData } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import React, { FC } from 'react';

export interface PrivacyPreferenceCenterProps {
  link: FooterData;
}

export const PrivacyPreferenceCenter: FC<PrivacyPreferenceCenterProps> = ({ link }) => (
  <div id="ot-sdk-btn" className="optanon-toggle-display" role="menuitem">
    {link.title}
  </div>
);

export default PrivacyPreferenceCenter;
