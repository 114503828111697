import { StyleProp, StyleSheet, ViewStyle } from 'react-native';
import merge from 'lodash/merge';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';
import { SKELETON_NAVBAR_WIDTH } from '@warnermmedia/gsp-core/brands/estadio/data-access';

interface Styles {
  skeletonNav: ViewStyle;
  skeletonNavContainer: ViewStyle;
}

export const getStyles = (breakpoints: Breakpoints, isTv: boolean) => {
  const base: StyleProp<Styles> = {
    skeletonNav: {
      width: SKELETON_NAVBAR_WIDTH,
      height: '100vh',
      minHeight: 1200,
      borderRadius: 0,
    },
    skeletonNavContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 800,
    },
  };

  const mediaQueries = isTv
    ? ({} as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isTiny ||
      breakpoints.currentBreakpoints.isTnySm ||
      breakpoints.currentBreakpoints.isSmMed
    ? ({} as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isMedLg
    ? ({} as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isLgXl ||
      breakpoints.currentBreakpoints.isXlXxl ||
      breakpoints.currentBreakpoints.isXxl
    ? ({} as StyleProp<Styles>)
    : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
