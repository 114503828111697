import { authorize, AuthorizeResult } from 'react-native-app-auth';
import { TveToolboxConfig } from '../../types';

const host = window && window.location ? window.location.host : '';
const redirectUri = host ? (host.includes('localhost') ? `http://${host}` : `https://${host}`) : '';

const useAuthorize = (toolboxConfig: TveToolboxConfig, tenantTitle: string, isMobile?: boolean) => {
  if (isMobile) {
    return (_country: string, _idp: string): Promise<AuthorizeResult> =>
      authorize({
        clientId: toolboxConfig.clientId,
        redirectUrl: `${toolboxConfig.redirectUrl}?platform=app`,
        scopes: toolboxConfig.scopes,
        additionalParameters: { country: _country, idp: _idp },
        serviceConfiguration: {
          authorizationEndpoint: toolboxConfig.authorizeUrl,
          tokenEndpoint: toolboxConfig.tokenUrl,
        },
      });
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (_country: string, _idp: string): Promise<{ accessToken: any }> => {
    const params = new URLSearchParams({
      client_id: toolboxConfig.clientId,
      scope: toolboxConfig.scopes?.join(','),
      redirect_uri: `${redirectUri}${window.location.pathname}`,
      response_type: 'code',
      country: _country,
      idp: _idp,
    });

    window.open(`${toolboxConfig.authorizeUrl}?${params}`, '_parent');

    return Promise.resolve({ accessToken: '' });
  };
};

export default useAuthorize;
