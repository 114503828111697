import merge from 'lodash/merge';
import { StyleProp, ViewStyle, StyleSheet, TextStyle, ImageStyle } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';

interface Styles {
  genericTop: ViewStyle;
  cardLayout: ViewStyle;
  cardContainer: ViewStyle;
  slideBottom: ViewStyle;
  genericImage: ImageStyle;
  slideBottomActive: ViewStyle;
  slideBgImageStyle: ImageStyle;
  slideBottomTitle: TextStyle;
}

export const getStyles = (breakpoints: Breakpoints, isTv: boolean, colors: ReactNativePaper.ThemeColors) => {
  const base: StyleProp<Styles> = {
    slideBgImageStyle: {
      borderRadius: 5,
    },
    genericTop: {
      width: 325,
      height: 183,
      borderRadius: 6.02,
    },
    genericImage: {
      alignSelf: 'center',
      top: 0,
      height: '100%',
      width: 150,
      position: 'relative',
    },
    slideBottom: {
      marginTop: 8,
      paddingTop: 0,
    },
    slideBottomActive: {},
    slideBottomTitle: {
      fontSize: 16,
      marginLeft: '4%',
      color: colors.keyboardFocus.light,
      fontFamily: 'Rubik-Medium',
      fontWeight: '500',
      lineHeight: 20,
      marginTop: 0,
    },
    cardLayout: {
      borderRadius: 10,
      marginRight: 8,
    },
    cardContainer: {
      marginBottom: 34,
    },
  };

  const mediaQueries = isTv
    ? ({
        cardLayout: {
          marginBottom: 18,
          marginRight: 0,
        },
        cardContainer: {
          marginBottom: 30,
          marginRight: 8,
          marginLeft: 8,
          marginTop: 8,
          width: 405,
        },
        genericTop: {
          height: 228,
          width: 405,
        },
        slideBottomTitle: {
          fontSize: 20,
          lineHeight: 28,
        },
        slideBottom: {
          marginTop: 16,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isTiny || breakpoints.currentBreakpoints.isTnySm
    ? ({
        cardLayout: {
          width: '100%',
          marginBottom: 15,
        },
        cardContainer: {
          marginRight: 4,
          marginLeft: 4,
          marginTop: 4,
          marginBottom: 20,
        },
        genericTop: {
          height: 183,
          width: '100%',
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isSmMed ||
      breakpoints.currentBreakpoints.isMedLg ||
      breakpoints.windowWidth === breakpoints.breakpointSizes.lg
    ? ({
        cardLayout: {
          marginBottom: 15,
        },
        cardContainer: {
          width: 218,
          marginRight: 4,
          marginLeft: 4,
          marginTop: 4,
          marginBottom: 20,
        },
        genericTop: {
          height: 124,
          width: 218,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isLgXl ||
      breakpoints.currentBreakpoints.isXlXxl ||
      breakpoints.currentBreakpoints.isXxl
    ? ({
        cardLayout: {
          marginBottom: 15,
        },
        cardContainer: {
          width: 239,
          marginRight: 4,
          marginLeft: 4,
          marginTop: 4,
          marginBottom: 20,
        },
        genericTop: {
          height: 136,
          width: 239,
        },
      } as StyleProp<Styles>)
    : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
