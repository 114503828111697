import React from 'react';
import Svg, { Defs, LinearGradient, Path, Stop } from 'react-native-svg';

/* eslint-disable-next-line */
export interface NavbarNarrowProps {
  width?: number | string;
  height?: number | string;
}

export function NavbarNarrow(props: NavbarNarrowProps) {
  const width = props.width || 150;
  const height = props.height || 129;
  return (
    <Svg width={width} height={height} fill="none" viewBox="0 0 150 129">
      <Path
        fill="url(#paint0_linear)"
        d="M64.368 52.453c-11.063.572-22.082.286-33.02-1.622-4.3-.688-8.483-1.974-12.427-3.82-1.569-.756-3.133-1.618-3.544-3.604v9.453c-.048.522.048 1.046.277 1.517.228.471.581.871 1.02 1.156 1.357.88 2.79 1.635 4.285 2.252 7.945 3.056 16.301 3.962 24.692 4.48 12.962.734 25.966-.092 38.732-2.46.445-.078 3.06-.62 4.604-.94l-5.66-8.717c-6.308.969-12.588 1.971-18.959 2.305z"
      />
      <Path
        fill="url(#paint1_linear)"
        d="M70.153 36.957c-12.931 1.167-25.829 1.05-38.654-1.148-4.337-.663-8.56-1.931-12.545-3.768-1.578-.76-3.118-1.66-3.577-3.641v9.453c-.039.521.064 1.044.297 1.511.234.468.59.865 1.03 1.147 1.18.798 2.433 1.482 3.742 2.044 7.296 2.906 15.008 3.918 22.755 4.499 9.238.702 18.52.576 27.737-.378 2.01-.213 6.657-.833 9.363-1.186l-5.882-9.056c-1.438.198-2.852.397-4.266.523z"
      />
      <Path
        fill="url(#paint2_linear)"
        d="M123.536 48.39c-.077-1.816 0-3.641 0-5.457v-3.58c-.256 1.531-1.317 2.379-2.513 3.086-3.035 1.801-6.424 2.576-9.818 3.317-.911.194-1.826.363-2.745.528v9.773c2.173-.446 4.357-.863 6.511-1.4 2.411-.526 4.707-1.482 6.779-2.823.584-.356 1.06-.865 1.375-1.472.315-.607.457-1.289.411-1.972z"
      />
      <Path
        fill="url(#paint3_linear)"
        d="M116.753 39.049c1.798-.634 3.529-1.445 5.166-2.422 1.152-.634 1.627-1.743 1.598-3.118-.053-2.79 0-5.584 0-8.378 0-.247-.034-.485-.063-.901-.484 1.627-1.031 2.421-2.494 3.201-1.057.631-2.183 1.141-3.355 1.52-3.026.838-6.085 1.55-9.15 2.252v9.948c2.885-.693 5.631-1.279 8.298-2.102z"
      />
      <Path
        fill="url(#paint4_linear)"
        d="M123.391 11.227c0-1.366.145-2.363-.319-3.191-.756 2.223-2.905 4.523-7.079 6.208-2.721.562-5.422 1.196-8.158 1.666-3.902.673-7.824 1.244-11.74 1.811-.804.116-1.051.397-1.041 1.225.063 6.596.072 13.197.096 19.793.027.372-.035.745-.179 1.09-.78-1.168-1.564-2.335-2.338-3.507-3.036-4.61-6.09-9.201-9.083-13.845-1.23-1.913-2.866-2.543-5.069-2.175-9.884 1.607-19.907 2.19-29.91 1.739-6.856-.276-13.678-.886-20.384-2.422-1.302-.295-2.585-.673-3.873-1.04-1.462-.408-2.86-1.021-4.149-1.822-2.004-1.259-4.183-3.273-3.428-5.81-1.002.793-1.34 1.757-1.34 3.171 0 3.124-.04 5.172-.04 7.748 0 2.732.736 3.052 2.276 3.952 2.953 1.72 6.207 2.644 9.475 3.463 6.55 1.632 13.236 2.29 19.966 2.596 9.887.45 19.744.169 29.534-1.352.243-.038.493-.004.719.098.225.1.416.266.549.474 1.138 1.777 2.338 3.51 3.495 5.273 4.3 6.538 8.594 13.08 12.884 19.623.799 1.226 1.854 2.092 3.355 1.966 1.937-.16 3.902-.484 5.916-.774V26.53c3.389-.649 6.657-1.21 9.916-1.937 2.856-.63 5.655-1.453 8.143-3.11 1.491-.968 1.835-2.265 1.835-4.416.015-1.985-.029-3.06-.029-5.84z"
      />
      <Path
        fill="url(#paint5_linear)"
        d="M20.426 8.951c-1.334.455-2.58 1.134-3.684 2.01-1.317 4.421 6.023 7.1 6.023 7.1.095-.063.2-.109.31-.136 1.849-.427 3.684-.969 5.558-1.255 7.914-1.291 15.835-2.532 23.762-3.724 5.083-.78 10.167-1.583 15.27-2.203 6.39-.753 12.828-1.034 19.26-.843 8.187.213 16.335.78 24.318 2.669 1.598.382 3.307.803 4.75 1.675 4.072-1.632 6.323-3.985 7.078-6.208-.372-.62-.891-1.139-1.51-1.511-.542-.365-1.11-.689-1.7-.969-3.692-1.604-7.557-2.777-11.518-3.496C101.564.832 94.695.164 87.806.065 79.13-.192 70.45.317 61.865 1.585L24.798 7.803c-1.489.251-2.952.635-4.372 1.148z"
      />
      <Path
        fill="url(#paint6_linear)"
        d="M20.426 8.951c-1.334.455-2.58 1.134-3.684 2.01-1.317 4.421 6.023 7.1 6.023 7.1.095-.063.2-.109.31-.136 1.849-.427 3.684-.969 5.558-1.255 7.914-1.291 15.835-2.532 23.762-3.724 5.083-.78 10.167-1.583 15.27-2.203 6.39-.753 12.828-1.034 19.26-.843 8.187.213 16.335.78 24.318 2.669 1.598.382 3.307.803 4.75 1.675 4.072-1.632 6.323-3.985 7.078-6.208-.372-.62-.891-1.139-1.51-1.511-.542-.365-1.11-.689-1.7-.969-3.692-1.604-7.557-2.777-11.518-3.496C101.564.832 94.695.164 87.806.065 79.13-.192 70.45.317 61.865 1.585L24.798 7.803c-1.489.251-2.952.635-4.372 1.148z"
      />
      <Path
        fill="url(#paint7_linear)"
        d="M119.596 119.545l-.935-.281c-.634-.203-1.627-.523-1.627-1.346 0-.824.896-1.017 1.569-1.017h2.009l1.709-3.172H96.497c-.547 0-1.072.217-1.459.604-.386.387-.604.912-.604 1.459v2.934c-.94-3.298-4.207-5.472-7.703-5.472-1.49-.022-2.956.368-4.238 1.128-1.282.76-2.328 1.859-3.024 3.177-.363-2.658-2.372-3.83-5.166-3.83H62.417c-2.818 0-4.841 1.84-4.841 4.697 0 2.722 1.51 3.322 3.805 3.976.785.223 2.222.576 2.222 1.603 0 1.027-.915 1.419-1.772 1.419-1.224-.071-2.379-.588-3.248-1.453l-1.569 2.949c1.447 1.028 3.174 1.589 4.948 1.608 1.446.039 2.86-.43 3.994-1.327 1.119-.968 1.453-2.387 1.453-3.787 0-2.276-1.51-3.269-3.472-3.845l-.93-.281c-.633-.203-1.626-.523-1.626-1.346 0-.823.896-1.191 1.569-1.191h5.63v11.395h3.738v-4.78h2.12c2.049 0 3.535-.586 4.358-1.86.373 4.218 3.588 7.119 7.93 7.119 3.874 0 6.827-2.295 7.703-5.763v5.284h3.743v-5.55h.038l4.155 5.545h4.042l-4.527-5.908c2.044-.364 3.152-2.083 3.152-4.068.002-.426-.047-.85-.145-1.264h2.513v11.24h3.747v-11.24h2.28c-.145.488-.217.996-.213 1.506 0 2.726 1.516 3.327 3.816 3.98.784.228 2.227.582 2.227 1.608 0 1.027-.92 1.424-1.777 1.424-1.226-.07-2.383-.587-3.254-1.453l-1.573 2.954c1.45 1.03 3.18 1.591 4.958 1.608 1.447.039 2.862-.43 3.999-1.327 1.123-.968 1.452-2.421 1.452-3.797.01-2.285-1.501-3.278-3.466-3.859zm-46.644.799h-.634v-3.593h.63c1.452 0 2.696 0 2.696 1.835s-1.331 1.758-2.692 1.758zm13.78 4.644c-.553-.007-1.098-.125-1.603-.347-.506-.221-.962-.542-1.342-.943-.38-.401-.675-.874-.868-1.391-.194-.517-.282-1.068-.26-1.619 0-1.991 1.835-3.807 4.072-3.807s4.072 1.816 4.072 3.807c.023.551-.065 1.102-.26 1.619-.193.517-.488.99-.868 1.391s-.835.722-1.341.943c-.506.222-1.05.34-1.603.347zm11.808-4.799h-.363v-3.593h.363c1.225 0 2.6.232 2.6 1.796s-1.375 1.797-2.6 1.797z"
      />
      <Path
        fill="url(#paint8_linear)"
        d="M39.812 113.651v7.381h-.135l-4.053-6.296c-.173-.365-.456-.667-.81-.864-.353-.196-.759-.277-1.16-.231H16.607v2.906h4.672v11.821h4.076v-11.811h4.537v11.821h3.873v-8.799h.082L39 127.293c.177.362.462.66.814.856.352.196.755.279 1.156.239h2.663v-11.831h4.551v11.821h3.985v-11.821h3.539l1.51-2.906H39.812z"
      />
      <Path
        fill="url(#paint9_linear)"
        d="M66.406 103.438l1.87-4.703h9.682l1.762 4.703h16.195c1.845.11 3.694-.158 5.431-.788 1.738-.63 3.329-1.61 4.674-2.877 1.346-1.268 2.418-2.798 3.151-4.495.732-1.697 1.11-3.527 1.11-5.376 0-1.849-.378-3.678-1.11-5.375-.733-1.698-1.805-3.227-3.151-4.495-1.345-1.268-2.936-2.247-4.674-2.877-1.737-.63-3.586-.899-5.43-.788h-9.979v23.666l-8.909-23.666h-7.683l-10.41 27.071h7.471zm26.57-21.11h1.651c4.522 0 7.718 2.732 7.718 7.574 0 5.279-3.588 7.58-7.79 7.58h-1.579V82.327zm-19.85 2.422h.068l2.876 8.615h-5.853l2.91-8.615z"
      />
      <Path fill="url(#paint10_linear)" d="M118.085 76.367h-7.035v27.071h7.035V76.367z" />
      <Path
        fill="url(#paint11_linear)"
        d="M135.035 75.47c-7.79 0-14.97 5.745-14.97 13.933 0 8.756 6.294 14.936 14.97 14.936 8.676 0 14.965-6.18 14.965-14.936 0-8.189-7.18-13.932-14.965-13.932zm0 22.045c-4.203 0-7.645-3.39-7.645-8.078 0-3.733 3.442-7.143 7.645-7.143 4.202 0 7.645 3.39 7.645 7.143 0 4.669-3.448 8.078-7.645 8.078z"
      />
      <Path
        fill="url(#paint12_linear)"
        d="M22.697 97.481H7.049v-4.668H19.85V86.85H7.05v-4.523h17.429c-.256.896-.383 1.824-.378 2.756 0 5.065 2.808 6.18 7.078 7.395 1.453.416 4.13 1.075 4.13 2.983 0 1.908-1.7 2.64-3.297 2.64-2.324 0-4.357-1.216-6.037-2.708l-2.905 5.482c2.688 1.915 5.899 2.958 9.199 2.988 2.638 0 5.384-.731 7.431-2.465 2.048-1.734 2.707-4.446 2.707-7.046 0-4.238-2.813-6.078-6.459-7.153l-1.733-.523c-1.181-.378-3.021-.968-3.021-2.499 0-1.53 1.665-2.218 2.905-2.218H47.95v21.58h7.35V81.96h8.022l2.237-5.593H0v27.071h19.482l3.215-5.957z"
      />
      <Defs>
        <LinearGradient
          id="paint0_linear"
          x1="15.361"
          x2="223.55"
          y1="63.587"
          y2="63.587"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#fff" />
          <Stop offset="1" stopColor="#D4D4D4" />
        </LinearGradient>
        <LinearGradient
          id="paint1_linear"
          x1="15.361"
          x2="223.55"
          y1="63.587"
          y2="63.587"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#fff" />
          <Stop offset="1" stopColor="#D4D4D4" />
        </LinearGradient>
        <LinearGradient
          id="paint2_linear"
          x1="15.361"
          x2="223.55"
          y1="63.587"
          y2="63.587"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#fff" />
          <Stop offset="1" stopColor="#D4D4D4" />
        </LinearGradient>
        <LinearGradient
          id="paint3_linear"
          x1="15.361"
          x2="223.55"
          y1="63.587"
          y2="63.587"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#fff" />
          <Stop offset="1" stopColor="#D4D4D4" />
        </LinearGradient>
        <LinearGradient id="paint4_linear" x1="15" x2="123" y1="35.259" y2="33.009" gradientUnits="userSpaceOnUse">
          <Stop stopColor="#359FD7" />
          <Stop offset="1" stopColor="#1CE3B3" />
        </LinearGradient>
        <LinearGradient
          id="paint5_linear"
          x1="155591"
          x2="151624"
          y1="7575.56"
          y2="15880.6"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#35A6DE" />
          <Stop offset="1" stopColor="#1CE3B3" />
        </LinearGradient>
        <LinearGradient id="paint6_linear" x1="15" x2="123" y1="16.504" y2="15.005" gradientUnits="userSpaceOnUse">
          <Stop stopColor="#1CDBAB" />
          <Stop offset="1" stopColor="#35A6DE" />
        </LinearGradient>
        <LinearGradient id="paint7_linear" x1="16.5" x2="174" y1="129.033" y2="129.033" gradientUnits="userSpaceOnUse">
          <Stop stopColor="#359FD7" />
          <Stop offset="1" stopColor="#1CDBAB" />
        </LinearGradient>
        <LinearGradient id="paint8_linear" x1="16.5" x2="174" y1="129.033" y2="129.033" gradientUnits="userSpaceOnUse">
          <Stop stopColor="#359FD7" />
          <Stop offset="1" stopColor="#1CDBAB" />
        </LinearGradient>
        <LinearGradient
          id="paint9_linear"
          x1="-.005"
          x2="288.666"
          y1="105.139"
          y2="105.139"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#fff" />
          <Stop offset="1" stopColor="#D4D4D4" />
        </LinearGradient>
        <LinearGradient
          id="paint10_linear"
          x1="-.005"
          x2="288.666"
          y1="105.139"
          y2="105.139"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#fff" />
          <Stop offset="1" stopColor="#D4D4D4" />
        </LinearGradient>
        <LinearGradient
          id="paint11_linear"
          x1="-.005"
          x2="288.666"
          y1="105.139"
          y2="105.139"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#fff" />
          <Stop offset="1" stopColor="#D4D4D4" />
        </LinearGradient>
        <LinearGradient
          id="paint12_linear"
          x1="-.005"
          x2="288.666"
          y1="105.139"
          y2="105.139"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#fff" />
          <Stop offset="1" stopColor="#D4D4D4" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
}

export default NavbarNarrow;
