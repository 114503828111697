import merge from 'lodash/merge';
import { ImageStyle, StyleProp, StyleSheet, TextStyle, ViewStyle } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';

interface Styles {
  pageContainer: ViewStyle;
  left: ViewStyle;
  marketingImageStyle: ImageStyle;
  headerViewStyle: ViewStyle;
  header1TextStyle: TextStyle;
  header2TextStyle: TextStyle;
  subscribeViewStyle: ViewStyle;
  subscribeShadowStyle: ViewStyle;
  subscribeLargeTextStyle: TextStyle;
  subscribeSmallTextStyle: TextStyle;
  availableViewStyle: ViewStyle;
  availableTextStyle: TextStyle;
  right: ViewStyle;
  formTitleTextStyle: TextStyle;
  formDirectionsTextStyle: TextStyle;
  explanationContainer: ViewStyle;
  explanationGroup: ViewStyle;
  explanationHeader: TextStyle;
  explanationText: TextStyle;
  imageMargin: ViewStyle;
}

export const getStyles = (breakpoints: Breakpoints, colors: ReactNativePaper.ThemeColors) => {
  const base: StyleProp<Styles> = {
    pageContainer: {
      flex: 1,
      paddingBottom: 71,
      paddingHorizontal: 25,
      paddingTop: 24,
    },
    left: {
      width: '100%',
      height: '100%',
    },
    marketingImageStyle: {
      marginBottom: 24,
      aspectRatio: 1,
    },
    headerViewStyle: {
      paddingBottom: 32,
    },
    header1TextStyle: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Oswald-SemiBold',
      fontSize: 33,
      fontWeight: '600',
      textTransform: 'uppercase',
      width: 300,
    },
    header2TextStyle: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Oswald-Regular',
      fontSize: 33,
      textTransform: 'uppercase',
    },
    subscribeViewStyle: {
      alignItems: 'flex-end',
      backgroundColor: '#1cdcac',
      borderRadius: 4,
      flexDirection: 'row',
      marginBottom: 16,
      padding: 16,
    },
    subscribeShadowStyle: {
      shadowColor: colors.stroke.onLight.outline01,
      shadowOffset: { width: 2, height: 4 },
      shadowOpacity: 0.5,
      shadowRadius: 4,
      elevation: 5,
    },
    subscribeLargeTextStyle: {
      color: colors.tenantBackground.dark.surface03,
      fontFamily: 'Oswald-SemiBold',
      fontSize: 33,
      fontWeight: '600',
      lineHeight: 44,
      textTransform: 'uppercase',
    },
    subscribeSmallTextStyle: {
      color: colors.tenantBackground.dark.surface03,
      fontFamily: 'Oswald-Regular',
      paddingBottom: 5,
      paddingLeft: 16,
      textTransform: 'uppercase',
    },
    availableViewStyle: {
      paddingBottom: 24,
      paddingLeft: 16,
      paddingRight: 24,
    },
    availableTextStyle: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Oswald-Regular',
      fontSize: 14,
      textTransform: 'uppercase',
    },
    right: {},
    formTitleTextStyle: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Oswald-SemiBold',
      fontSize: 22,
      fontWeight: '600',
      paddingBottom: 24,
      textTransform: 'uppercase',
    },
    formDirectionsTextStyle: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Rubik-Regular',
      fontSize: 16,
      paddingBottom: 8,
    },
    explanationContainer: {
      backgroundColor: colors.tenantBackground.dark.surface02,
      paddingBottom: 51,
      paddingHorizontal: 25,
      paddingTop: 48,
    },
    explanationGroup: {},
    explanationHeader: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Oswald-SemiBold',
      fontSize: 22,
      fontWeight: '600',
      paddingBottom: 24,
      textTransform: 'uppercase',
    },
    explanationText: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Rubik-Regular',
      fontSize: 16,
      lineHeight: 20,
      paddingBottom: 20,
    },
    imageMargin: {
      marginTop: 40,
    },
  };

  const mediaQueries = breakpoints.currentBreakpoints.isTiny
    ? {
        subscribeViewStyle: {
          alignItems: 'flex-start',
          flexDirection: 'column',
        },
        subscribeSmallTextStyle: {
          paddingLeft: 0,
        },
      }
    : breakpoints.currentBreakpoints.isMedLg ||
      breakpoints.currentBreakpoints.isLgXl ||
      breakpoints.currentBreakpoints.isXlXxl ||
      breakpoints.currentBreakpoints.isXxl
    ? ({
        pageContainer: {
          paddingBottom: 96,
          paddingHorizontal: 40,
          paddingTop: 48,
          alignItems: 'flex-start',
          flexDirection: 'row',
          justifyContent: 'space-between',
        },
        left: {
          flexShrink: 1,
        },
        headerViewStyle: {
          paddingTop: 26,
        },
        right: {
          marginLeft: 74,
          width: 336,
        },
        explanationContainer: {
          alignItems: 'flex-start',
          flexDirection: 'row',
          justifyContent: 'space-around',
          width: '100%',
        },
        explanationGroup: {
          maxWidth: '47%',
        },
      } as StyleProp<Styles>)
    : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
