import { breakpointsStateStore } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { useReactiveVar } from '@apollo/client';

export interface ScaleAction {
  scaleWidth: (width: number, breakpointsSize: BreakpointsSize) => number;
  scaleHeight: (height: number, breakpointsSize: BreakpointsSize) => number;
}

interface ScreenMeasurement {
  baseWidth: number;
  baseHeight: number;
}

export enum BreakpointsSize {
  Mobile = 'mobile',
  Tablet = 'tablet',
  Web = 'web',
  Tv = 'tv',
}

export const useScaleSizing = (): ScaleAction => {
  const breakpoints = useReactiveVar(breakpointsStateStore);

  const getBaseMeasurement = (breakpointsSize: BreakpointsSize): ScreenMeasurement => {
    const breakpointsSizes = {
      mobile: { baseWidth: 375, baseHeight: 281.25 },
      tablet: { baseWidth: 768, baseHeight: 576 },
      web: { baseWidth: 1440, baseHeight: 1080 },
      tv: { baseWidth: 1920, baseHeight: 1080 },
    };

    switch (breakpointsSize) {
      case BreakpointsSize.Tablet:
        return breakpointsSizes.tablet;
      case BreakpointsSize.Web:
        return breakpointsSizes.web;
      case BreakpointsSize.Tv:
        return breakpointsSizes.tv;
      default:
        return breakpointsSizes.mobile;
    }
  };

  const scaleWidth = (width: number, breakpointsSize: BreakpointsSize): number => {
    const { baseWidth } = getBaseMeasurement(breakpointsSize);
    return (width / baseWidth) * breakpoints.windowWidth;
  };
  const scaleHeight = (height: number, breakpointsSize: BreakpointsSize): number => {
    const { baseHeight } = getBaseMeasurement(breakpointsSize);
    return (height / baseHeight) * breakpoints.windowHeight;
  };

  return {
    scaleWidth,
    scaleHeight,
  };
};

export default useScaleSizing;
