import React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';

/* eslint-disable-next-line */
export interface PlayProps {
  width?: number;
  height?: number;
  color?: string;
  iconColor?: string;
  opacity?: number;
}

export function Play(props: PlayProps) {
  const width = props.width || 20;
  const height = props.height || 20;
  const color = props.color || '#359fd7';
  const iconColor = props.iconColor || '#fff';
  const opacity = props.opacity || 1;
  return (
    <Svg fill="none" height={height} viewBox="0 0 20 20" width={width}>
      <Circle cx="10" cy="10" r="10" fill={color} opacity={opacity} />
      <Path
        fill={iconColor}
        d="M13.5 9.134c.667.385.667 1.347 0 1.732L9 13.464c-.667.385-1.5-.096-1.5-.866V7.402c0-.77.833-1.251 1.5-.866l4.5 2.598z"
      />
    </Svg>
  );
}

export default Play;
