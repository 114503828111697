import React from 'react';
import Svg, { Defs, LinearGradient, Path, Stop } from 'react-native-svg';

/* eslint-disable-next-line */
export interface NavbarWideProps {
  width?: number | string;
  height?: number | string;
}

export function NavbarWide(props: NavbarWideProps) {
  const width = props.width || 301;
  const height = props.height || 68;

  return (
    <Svg width={width} height={height} fill="none" viewBox="0 0 301 68">
      <Path
        fill="url(#paint0_linear)"
        d="M52.966 56.625c-11.957.622-23.867.314-35.688-1.746-4.65-.743-9.172-2.13-13.438-4.124-1.69-.82-3.38-1.75-3.825-3.894 0 3.403.026 6.795 0 10.203-.052.563.05 1.13.298 1.638.247.51.63.94 1.105 1.247 1.463.953 3.012 1.769 4.625 2.436 8.593 3.298 17.625 4.276 26.688 4.835 14.01.793 28.065-.099 41.862-2.655.481-.084 3.302-.67 4.976-1.014l-6.117-9.409c-6.813 1.046-13.6 2.128-20.486 2.483z"
      />
      <Path
        fill="url(#paint1_linear)"
        d="M59.215 39.904c-13.972 1.255-27.912 1.135-41.779-1.238-4.685-.719-9.247-2.088-13.553-4.067-1.705-.82-3.375-1.792-3.867-3.935 0 3.402.027 6.794 0 10.202-.042.564.069 1.129.32 1.635.253.506.638.934 1.114 1.24 1.275.859 2.628 1.597 4.04 2.206 7.89 3.136 16.195 4.223 24.594 4.85 9.985.764 20.018.627 29.979-.407 2.171-.225 7.195-.894 10.12-1.276l-6.384-9.774c-1.528.214-3.051.423-4.584.564z"
      />
      <Path
        fill="url(#paint2_linear)"
        d="M116.911 52.276c-.079-1.96 0-3.925 0-5.89v-3.863c-.278 1.657-1.424 2.572-2.716 3.335-3.302 1.913-6.965 2.76-10.628 3.549-.984.214-1.973.392-2.967.575v10.547c2.35-.48 4.71-.93 7.038-1.51 2.605-.57 5.086-1.602 7.326-3.047.632-.379 1.148-.924 1.492-1.576.343-.651.5-1.385.455-2.12z"
      />
      <Path
        fill="url(#paint3_linear)"
        d="M109.586 42.157c1.944-.682 3.814-1.557 5.583-2.613 1.246-.685 1.758-1.882 1.727-3.366-.058-3.016 0-6.032 0-9.043 0-.266-.037-.522-.068-.972-.523 1.756-1.12 2.613-2.695 3.45-1.143.683-2.36 1.235-3.626 1.646-3.271.904-6.578 1.673-9.89 2.425v10.752c3.113-.753 6.091-1.385 8.969-2.279z"
      />
      <Path
        fill="url(#paint4_linear)"
        d="M116.759 12.134c0-1.473.157-2.556-.345-3.444-.817 2.4-3.14 4.882-7.651 6.7-2.941.607-5.866 1.292-8.817 1.799-4.218.726-8.456 1.343-12.69 1.955-.868.125-1.135.428-1.124 1.322.067 7.119.078 14.238.104 21.357.028.403-.038.808-.193 1.181-.843-1.26-1.69-2.52-2.528-3.784-3.28-4.976-6.583-9.931-9.817-14.944-1.334-2.064-3.097-2.749-5.478-2.346-10.683 1.735-21.517 2.362-32.328 1.87-7.41-.292-14.783-.95-22.03-2.613-1.408-.324-2.8-.726-4.187-1.123-1.578-.44-3.087-1.102-4.479-1.966C3.03 16.734.675 14.56 1.491 11.826c-1.083.857-1.45 1.903-1.45 3.429 0 3.371-.041 5.582-.041 8.363 0 2.948.795 3.293 2.46 4.26 3.191 1.86 6.708 2.859 10.24 3.742 7.08 1.762 14.306 2.467 21.58 2.802 10.685.486 21.34.183 31.92-1.464.265-.04.536-.003.78.108.245.11.451.289.596.514 1.23 1.918 2.522 3.79 3.778 5.692 4.647 7.056 9.288 14.116 13.925 21.18.863 1.322 2.004 2.252 3.626 2.121 2.093-.172 4.218-.522 6.394-.836V28.651c3.637-.7 7.195-1.306 10.717-2.09 3.087-.68 6.112-1.569 8.796-3.356 1.617-1.045 1.989-2.446 1.989-4.767.005-2.148-.042-3.33-.042-6.304z"
      />
      <Path
        fill="url(#paint5_linear)"
        d="M116.759 12.134c0-1.473.157-2.556-.345-3.444-.817 2.4-3.14 4.882-7.651 6.7-2.941.607-5.866 1.292-8.817 1.799-4.218.726-8.456 1.343-12.69 1.955-.868.125-1.135.428-1.124 1.322.067 7.119.078 14.238.104 21.357.028.403-.038.808-.193 1.181-.843-1.26-1.69-2.52-2.528-3.784-3.28-4.976-6.583-9.931-9.817-14.944-1.334-2.064-3.097-2.749-5.478-2.346-10.683 1.735-21.517 2.362-32.328 1.87-7.41-.292-14.783-.95-22.03-2.613-1.408-.324-2.8-.726-4.187-1.123-1.578-.44-3.087-1.102-4.479-1.966C3.03 16.734.675 14.56 1.491 11.826c-1.083.857-1.45 1.903-1.45 3.429 0 3.371-.041 5.582-.041 8.363 0 2.948.795 3.293 2.46 4.26 3.191 1.86 6.708 2.859 10.24 3.742 7.08 1.762 14.306 2.467 21.58 2.802 10.685.486 21.34.183 31.92-1.464.265-.04.536-.003.78.108.245.11.451.289.596.514 1.23 1.918 2.522 3.79 3.778 5.692 4.647 7.056 9.288 14.116 13.925 21.18.863 1.322 2.004 2.252 3.626 2.121 2.093-.172 4.218-.522 6.394-.836V28.651c3.637-.7 7.195-1.306 10.717-2.09 3.087-.68 6.112-1.569 8.796-3.356 1.617-1.045 1.989-2.446 1.989-4.767.005-2.148-.042-3.33-.042-6.304z"
      />
      <Path
        fill="url(#paint6_linear)"
        d="M5.473 9.678c-1.442.487-2.789 1.22-3.982 2.164C.067 16.619 8 19.509 8 19.509c.1-.072.21-.127.329-.162 1.999-.46 3.982-1.045 6.007-1.353 8.55-1.394 17.111-2.734 25.683-4.02 5.494-.841 10.988-1.709 16.504-2.378 6.907-.812 13.864-1.117 20.816-.915 8.849.236 17.655.847 26.284 2.886 1.727.407 3.574.867 5.133 1.808 4.401-1.761 6.834-4.302 7.651-6.7-.404-.669-.964-1.228-1.633-1.632-.585-.394-1.199-.743-1.837-1.045-3.99-1.732-8.167-2.998-12.448-3.774C93.162.9 85.738.178 78.292.071c-9.376-.28-18.758.27-28.037 1.641L10.193 8.423c-1.609.271-3.19.691-4.72 1.255z"
      />
      <Path
        fill="url(#paint7_linear)"
        d="M5.473 9.678c-1.442.487-2.789 1.22-3.982 2.164C.067 16.619 8 19.509 8 19.509c.1-.072.21-.127.329-.162 1.999-.46 3.982-1.045 6.007-1.353 8.55-1.394 17.111-2.734 25.683-4.02 5.494-.841 10.988-1.709 16.504-2.378 6.907-.812 13.864-1.117 20.816-.915 8.849.236 17.655.847 26.284 2.886 1.727.407 3.574.867 5.133 1.808 4.401-1.761 6.834-4.302 7.651-6.7-.404-.669-.964-1.228-1.633-1.632-.585-.394-1.199-.743-1.837-1.045-3.99-1.732-8.167-2.998-12.448-3.774C93.162.9 85.738.178 78.292.071c-9.376-.28-18.758.27-28.037 1.641L10.193 8.423c-1.609.271-3.19.691-4.72 1.255z"
      />
      <Path
        fill="url(#paint8_linear)"
        d="M249.631 52.834l-1.01-.298c-.687-.224-1.76-.569-1.76-1.451 0-.883.974-1.097 1.702-1.097h2.168l1.849-3.421h-27.923c-.592 0-1.159.234-1.578.651-.418.418-.654.984-.654 1.574v3.133c-1.01-3.556-4.546-5.901-8.333-5.901-1.612-.025-3.2.397-4.587 1.218-1.387.82-2.519 2.007-3.27 3.43-.398-2.867-2.572-4.131-5.594-4.131h-12.817c-3.049 0-5.238 1.98-5.238 5.06 0 2.941 1.639 3.584 4.122 4.289.843.24 2.399.621 2.399 1.728 0 1.108-.99 1.53-1.917 1.53-1.324-.078-2.573-.635-3.515-1.566l-1.692 3.18c1.553 1.108 3.408 1.716 5.317 1.745 1.564.042 3.093-.464 4.321-1.431 1.21-1.045 1.571-2.575 1.571-4.084 0-2.455-1.639-3.52-3.755-4.147l-1.011-.303c-.686-.22-1.755-.564-1.755-1.447 0-.883.969-1.29 1.692-1.29h6.092v12.29h4.049v-5.15h2.294c2.221 0 3.824-.638 4.714-2.011.403 4.549 3.881 7.677 8.58 7.677 4.19 0 7.385-2.47 8.333-6.21v5.693h4.049V56.12h.042l4.495 5.975h4.373l-4.897-6.366c2.21-.392 3.41-2.246 3.41-4.393.002-.457-.051-.913-.158-1.358h2.724v12.117h4.049V49.977h2.467c-.153.526-.228 1.072-.225 1.62 0 2.945 1.634 3.587 4.122 4.293.854.245 2.41.626 2.41 1.739 0 1.112-.99 1.53-1.923 1.53-1.326-.076-2.578-.633-3.519-1.567l-1.698 3.186c1.566 1.112 3.437 1.717 5.359 1.734 1.566.044 3.097-.462 4.326-1.43 1.216-1.046 1.572-2.581 1.572-4.096.01-2.465-1.629-3.535-3.772-4.152zm-50.457.862h-.686v-3.875h.686c1.572 0 2.913 0 2.913 1.98 0 1.979-1.43 1.885-2.897 1.885l-.016.01zm14.902 5.009c-.597-.009-1.185-.137-1.732-.376-.546-.24-1.039-.585-1.449-1.017-.41-.433-.73-.943-.939-1.5-.209-.557-.304-1.15-.28-1.745 0-2.142 1.986-4.1 4.4-4.1 2.415 0 4.405 1.959 4.405 4.1.023.593-.072 1.184-.282 1.74-.209.555-.527 1.063-.936 1.494-.409.431-.9.776-1.445 1.016-.544.239-1.131.367-1.726.377l-.016.01zm12.776-5.176h-.393v-3.876h.393c1.325 0 2.812.251 2.812 1.938 0 1.687-1.472 1.927-2.797 1.927l-.015.01z"
      />
      <Path
        fill="url(#paint9_linear)"
        d="M249.631 52.834l-1.01-.298c-.687-.224-1.76-.569-1.76-1.451 0-.883.974-1.097 1.702-1.097h2.168l1.849-3.421h-27.923c-.592 0-1.159.234-1.578.651-.418.418-.654.984-.654 1.574v3.133c-1.01-3.556-4.546-5.901-8.333-5.901-1.612-.025-3.2.397-4.587 1.218-1.387.82-2.519 2.007-3.27 3.43-.398-2.867-2.572-4.131-5.594-4.131h-12.817c-3.049 0-5.238 1.98-5.238 5.06 0 2.941 1.639 3.584 4.122 4.289.843.24 2.399.621 2.399 1.728 0 1.108-.99 1.53-1.917 1.53-1.324-.078-2.573-.635-3.515-1.566l-1.692 3.18c1.553 1.108 3.408 1.716 5.317 1.745 1.564.042 3.093-.464 4.321-1.431 1.21-1.045 1.571-2.575 1.571-4.084 0-2.455-1.639-3.52-3.755-4.147l-1.011-.303c-.686-.22-1.755-.564-1.755-1.447 0-.883.969-1.29 1.692-1.29h6.092v12.29h4.049v-5.15h2.294c2.221 0 3.824-.638 4.714-2.011.403 4.549 3.881 7.677 8.58 7.677 4.19 0 7.385-2.47 8.333-6.21v5.693h4.049V56.12h.042l4.495 5.975h4.373l-4.897-6.366c2.21-.392 3.41-2.246 3.41-4.393.002-.457-.051-.913-.158-1.358h2.724v12.117h4.049V49.977h2.467c-.153.526-.228 1.072-.225 1.62 0 2.945 1.634 3.587 4.122 4.293.854.245 2.41.626 2.41 1.739 0 1.112-.99 1.53-1.923 1.53-1.326-.076-2.578-.633-3.519-1.567l-1.698 3.186c1.566 1.112 3.437 1.717 5.359 1.734 1.566.044 3.097-.462 4.326-1.43 1.216-1.046 1.572-2.581 1.572-4.096.01-2.465-1.629-3.535-3.772-4.152zm-50.457.862h-.686v-3.875h.686c1.572 0 2.913 0 2.913 1.98 0 1.979-1.43 1.885-2.897 1.885l-.016.01zm14.902 5.009c-.597-.009-1.185-.137-1.732-.376-.546-.24-1.039-.585-1.449-1.017-.41-.433-.73-.943-.939-1.5-.209-.557-.304-1.15-.28-1.745 0-2.142 1.986-4.1 4.4-4.1 2.415 0 4.405 1.959 4.405 4.1.023.593-.072 1.184-.282 1.74-.209.555-.527 1.063-.936 1.494-.409.431-.9.776-1.445 1.016-.544.239-1.131.367-1.726.377l-.016.01zm12.776-5.176h-.393v-3.876h.393c1.325 0 2.812.251 2.812 1.938 0 1.687-1.472 1.927-2.797 1.927l-.015.01z"
      />
      <Path
        fill="url(#paint10_linear)"
        d="M163.336 46.468v7.98h-.131l-4.379-6.79c-.19-.391-.497-.715-.879-.926-.381-.21-.819-.3-1.253-.254h-18.469v3.134h5.076v12.743h4.384V49.612h4.913v12.743h4.191v-9.52h.089l5.552 8.356c.192.39.5.71.881.92.381.21.818.3 1.251.255h2.875V49.622h4.929v12.744h4.306V49.622h3.818l1.635-3.133-18.789-.021z"
      />
      <Path
        fill="url(#paint11_linear)"
        d="M163.336 46.468v7.98h-.131l-4.379-6.79c-.19-.391-.497-.715-.879-.926-.381-.21-.819-.3-1.253-.254h-18.469v3.134h5.076v12.743h4.384V49.612h4.913v12.743h4.191v-9.52h.089l5.552 8.356c.192.39.5.71.881.92.381.21.818.3 1.251.255h2.875V49.622h4.929v12.744h4.306V49.622h3.818l1.635-3.133-18.789-.021z"
      />
      <Path
        fill="url(#paint12_linear)"
        d="M209.981 40.402l1.999-5.086h10.466l1.899 5.08h17.504c1.994.12 3.992-.17 5.87-.85 1.878-.68 3.597-1.737 5.051-3.105 1.454-1.369 2.613-3.02 3.405-4.851.792-1.832 1.201-3.807 1.201-5.802 0-1.996-.409-3.97-1.201-5.803-.792-1.831-1.951-3.482-3.405-4.85-1.454-1.369-3.173-2.426-5.051-3.106-1.878-.68-3.876-.97-5.87-.85H231.09v25.538l-9.628-25.538h-8.299l-11.251 29.218 8.069.005zm28.712-22.79h1.785c4.892 0 8.341 2.949 8.341 8.18 0 5.693-3.878 8.175-8.414 8.175h-1.712V17.614zm-21.454 2.614h.079l3.103 9.299h-6.348l3.166-9.299z"
      />
      <Path fill="url(#paint13_linear)" d="M265.836 11.184h-7.603v29.218h7.603V11.184z" />
      <Path
        fill="url(#paint14_linear)"
        d="M284.152 10.227c-8.42 0-16.18 6.189-16.18 15.022 0 9.456 6.803 16.12 16.18 16.12 9.377 0 16.174-6.664 16.174-16.12 0-8.833-7.76-15.022-16.174-15.022zm0 23.793c-4.542 0-8.263-3.659-8.263-8.719 0-4.03 3.721-7.71 8.263-7.71s8.262 3.66 8.262 7.71c0 5.024-3.725 8.703-8.262 8.703v.016z"
      />
      <Path
        fill="url(#paint15_linear)"
        d="M162.741 33.968h-16.913v-5.04h13.825V22.5h-13.825v-4.887h18.838c-.276.967-.414 1.968-.408 2.974 0 5.472 3.041 6.67 7.651 7.981 1.569.45 4.468 1.16 4.468 3.22 0 2.06-1.842 2.849-3.563 2.849-2.517 0-4.71-1.312-6.531-2.922l-3.139 5.922c2.907 2.062 6.376 3.186 9.942 3.22 2.852 0 5.814-.785 8.027-2.661 2.214-1.876 2.925-4.793 2.925-7.605 0-4.574-3.04-6.56-6.975-7.72l-1.879-.56c-1.276-.412-3.265-1.045-3.265-2.696 0-1.652 1.805-2.394 3.14-2.394h14.965v23.29h7.944V17.215h8.671l2.417-6.031h-70.831v29.218h21.052l3.464-6.434z"
      />
      <Defs>
        <LinearGradient id="paint0_linear" x1="0" x2="116.862" y1="58.367" y2="41.384" gradientUnits="userSpaceOnUse">
          <Stop stopColor="#fff" />
          <Stop offset="1" stopColor="#D5D5D5" />
        </LinearGradient>
        <LinearGradient id="paint1_linear" x1="0" x2="116.862" y1="58.367" y2="41.384" gradientUnits="userSpaceOnUse">
          <Stop stopColor="#fff" />
          <Stop offset="1" stopColor="#D5D5D5" />
        </LinearGradient>
        <LinearGradient id="paint2_linear" x1="0" x2="116.862" y1="58.367" y2="41.384" gradientUnits="userSpaceOnUse">
          <Stop stopColor="#fff" />
          <Stop offset="1" stopColor="#D5D5D5" />
        </LinearGradient>
        <LinearGradient id="paint3_linear" x1="0" x2="116.862" y1="58.367" y2="41.384" gradientUnits="userSpaceOnUse">
          <Stop stopColor="#fff" />
          <Stop offset="1" stopColor="#D5D5D5" />
        </LinearGradient>
        <LinearGradient
          id="paint4_linear"
          x1="44837.6"
          x2="66831.3"
          y1="26818.5"
          y2="40955.2"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#35A6DE" />
          <Stop offset="1" stopColor="#1CE3B3" />
        </LinearGradient>
        <LinearGradient id="paint5_linear" x1="0" x2="116.867" y1="40.48" y2="38.593" gradientUnits="userSpaceOnUse">
          <Stop stopColor="#359ED6" />
          <Stop offset="1" stopColor="#1CDBAB" />
        </LinearGradient>
        <LinearGradient
          id="paint6_linear"
          x1="64759"
          x2="60475"
          y1="9352.8"
          y2="18325.9"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#35A6DE" />
          <Stop offset="1" stopColor="#1CE3B3" />
        </LinearGradient>
        <LinearGradient
          id="paint7_linear"
          x1="-.942"
          x2="116.867"
          y1="18.828"
          y2="18.828"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#1CDBAB" />
          <Stop offset="1" stopColor="#359ED6" />
        </LinearGradient>
        <LinearGradient
          id="paint8_linear"
          x1="32203.1"
          x2="31787.7"
          y1="7406.19"
          y2="9870.99"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#35A6DE" />
          <Stop offset="1" stopColor="#1CE3B3" />
        </LinearGradient>
        <LinearGradient
          id="paint9_linear"
          x1="142.801"
          x2="283.368"
          y1="63.205"
          y2="63.205"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#35A6DE" />
          <Stop offset="1" stopColor="#1CDBAB" />
        </LinearGradient>
        <LinearGradient
          id="paint10_linear"
          x1="32203.1"
          x2="31787.7"
          y1="7406.19"
          y2="9870.99"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#35A6DE" />
          <Stop offset="1" stopColor="#1CE3B3" />
        </LinearGradient>
        <LinearGradient
          id="paint11_linear"
          x1="142.801"
          x2="283.368"
          y1="63.205"
          y2="63.205"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#35A6DE" />
          <Stop offset="1" stopColor="#1CDBAB" />
        </LinearGradient>
        <LinearGradient
          id="paint12_linear"
          x1="138.22"
          x2="450.179"
          y1="42.233"
          y2="42.233"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#fff" />
          <Stop offset="1" stopColor="#D4D4D4" />
        </LinearGradient>
        <LinearGradient
          id="paint13_linear"
          x1="138.22"
          x2="450.179"
          y1="42.233"
          y2="42.233"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#fff" />
          <Stop offset="1" stopColor="#D4D4D4" />
        </LinearGradient>
        <LinearGradient
          id="paint14_linear"
          x1="138.22"
          x2="450.179"
          y1="42.233"
          y2="42.233"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#fff" />
          <Stop offset="1" stopColor="#D4D4D4" />
        </LinearGradient>
        <LinearGradient
          id="paint15_linear"
          x1="138.22"
          x2="450.179"
          y1="42.233"
          y2="42.233"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#fff" />
          <Stop offset="1" stopColor="#D4D4D4" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
}

export default NavbarWide;
