import React, { useCallback, useContext, useEffect, useState } from 'react';
import HeroCard from '../../cards/hero/heroCard';
import { View } from 'react-native';
import { carouselBase, LayoutUpdateContext, useSetCardIndex } from '@warnermmedia/gsp-core/brands/estadio/feature';
import { Breakpoints, DeviceTypes } from '@warnermmedia/gsp-core/sdk/ui';
import { FocusKeys } from '@warnermmedia/gsp-core/sdk/data-access';
import { useFocusable } from '@noriginmedia/norigin-spatial-navigation';

export const MatchHero = ({
  heroItems,
  itemClick,
  breakpoints,
  colors,
  device,
}: {
  heroItems: carouselBase;
  itemClick: (item: carouselBase) => void;
  breakpoints: Breakpoints;
  device: DeviceTypes;
  colors: ReactNativePaper.ThemeColors;
}) => {
  const [heroIndex, setHeroIndex] = useState(0);
  const contentWidth = useContext(LayoutUpdateContext);
  const { setIndex } = useSetCardIndex(heroIndex, setHeroIndex);
  const { setFocus } = useFocusable();

  useEffect(() => {
    if (heroItems) {
      setFocus(FocusKeys.MATCH_HERO);
    }
  }, [setFocus, heroItems]);

  // Gets called when item click/tap
  const clickAction = useCallback(
    (item: carouselBase) => {
      if (typeof item !== 'undefined') {
        // Navigate to video page
        itemClick(item);
      }
      setIndex(heroIndex);
    },
    [heroIndex, itemClick, setIndex]
  );

  return (
    <View style={{ alignItems: 'center' }}>
      {heroItems && (
        <HeroCard
          item={heroItems}
          index={heroIndex}
          currentIndex={heroIndex}
          laneWidth={contentWidth}
          action={clickAction}
          shouldShowPlayIcon={heroItems?.type === 'match' && heroItems?.has_media && heroItems?.status === 4}
          device={device}
          colors={colors}
          breakpoints={breakpoints}
          focusKey={FocusKeys.MATCH_HERO}
          parentFocusKey={FocusKeys.MATCH_HERO}
        />
      )}
    </View>
  );
};

export default MatchHero;
