/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ImageBackground, StyleProp, Text, TextStyle, View, ViewStyle } from 'react-native';
import { getStyles } from './pinLogin.styles';
import QRCode from 'react-qr-code';
import { useReactiveVar } from '@apollo/client';
import { AppConfigContext, breakpointsStateStore } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { EstadioButton } from '@warnermmedia/gsp-core/brands/estadio/ui';
import {
  languageStrings,
  useInterval,
  useIsMountedRef,
  usePinAndPairActions,
  useScaleSizing,
} from '@warnermmedia/gsp-core/brands/estadio/feature';
import { CheckCodeResponse, FocusKeys, TV_LOGIN_POLL_INTERVAL } from '@warnermmedia/gsp-core/sdk/data-access';
import LoadingAppIndicator from '../../loadingAppIndicator/loadingAppIndicator';
import { useTheme } from 'react-native-paper';

export interface PinLoginScreenProps {
  containerStyle?: StyleProp<ViewStyle>;
  title?: string;
  titleStyle?: StyleProp<TextStyle>;
  link?: string;
  linkStyle?: StyleProp<TextStyle>;
  description?: string;
  descriptionStyle?: StyleProp<TextStyle>;
  device?: string;
  buttonText?: string;
  onSuccess: (resp: CheckCodeResponse | undefined) => void;
  onError: () => void;
  onBack: () => void;
  background: Record<string, string>;
}

export const PinLoginScreen = ({
  title = languageStrings.default.pinPairTitle,
  link = 'estadio.com',
  description = languageStrings.default.pinPairDescription,
  buttonText = languageStrings.default.buttonText,
  onError,
  onSuccess,
  background,
}: PinLoginScreenProps) => {
  const appConfig = useContext(AppConfigContext);
  const [qrWidth, setQrWidth] = useState<number>(403);
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const scaling = useScaleSizing();
  const isMountedRef = useIsMountedRef();
  const [qrLoading, setQrLoading] = useState(true);
  const { getCode, checkCode } = usePinAndPairActions();
  const [codes, setCodes] = useState<string[]>(new Array(6).fill(''));
  const [startPolling, setStartPolling] = useState(false);
  const [isGettingCode, setIsGettingCode] = useState(false);
  const { colors } = useTheme();
  const styles = getStyles(breakpoints, scaling, colors);
  const validationPageRoute = '/logintv';
  const baseUrlLink = appConfig?.appConfig?.webUrl ?? '';
  const { loadingText } = languageStrings.default;
  // This  will allow the QR code to redirect to the correct URL while on dev env
  const qrCodeLink = baseUrlLink ? `${baseUrlLink}${validationPageRoute}` : `${link}${validationPageRoute}`;

  /**
   * `onGetCodeSuccess` is a function that takes a response of type string
   * @param {string} response - The response from the server.
   */
  const onGetCodeSuccess = (response: string) => {
    const code = response?.toString().split('');
    if (code) {
      setCodes(code);
      setStartPolling(true);
    }
    setIsGettingCode(false);
  };

  const onSuccessCallback = useCallback(
    (response: CheckCodeResponse | undefined) => {
      if (response && response?.isClaimed && response?.authToken && response?.regCode && isMountedRef.current) {
        setStartPolling(false);
      }
      onSuccess(response);
    },
    [setStartPolling, onSuccess, isMountedRef]
  );

  // when polling start you will call onSuccess or Error depending on polling information
  /**
   * Check the code, and if it's valid, call the onSuccess function, otherwise call the onError function.
   */
  const checkTvCode = useCallback(async () => {
    const code = codes.join('');
    await checkCode(code, onSuccessCallback, onError);
  }, [codes, checkCode, onError, onSuccessCallback]);

  const isCodeEmpty = useCallback((): boolean => {
    return codes.every((digit) => !digit);
  }, [codes]);

  /* A function that is called when the component is mounted. */
  const getUserCode = useCallback(async () => {
    setIsGettingCode(true);
    try {
      await getCode(onGetCodeSuccess, onError, !isCodeEmpty());
    } catch (err) {
      setIsGettingCode(false);
      onError();
    }
  }, [isCodeEmpty, getCode, onError]);

  useEffect(() => {
    if (isMountedRef.current && isCodeEmpty()) {
      getUserCode();
    }
  }, [getUserCode, isCodeEmpty, isMountedRef]);

  useEffect(() => {
    if (isMountedRef.current) {
      setQrWidth(breakpoints.windowWidth * 0.2);
      setQrLoading(false);
    }
  }, [breakpoints.windowWidth, isMountedRef]);

  //Start checking for the claimed code by polling
  useInterval(checkTvCode, TV_LOGIN_POLL_INTERVAL, startPolling);

  return (
    <View style={styles.wrapper}>
      <View style={styles.textContainer}>
        <Text style={styles.titleStyle}>{title}</Text>
        <Text style={styles.linkStyle}>{`${link}${validationPageRoute}`}</Text>
        <Text style={styles.descriptionStyle}>{description}</Text>
        {isCodeEmpty() ? (
          <View style={styles.loaderContainer}>
            <LoadingAppIndicator />
          </View>
        ) : (
          <View style={styles.pinContainer}>
            {codes?.map((el: string, idx: number) => (
              <View style={styles.inputText} key={`${idx}${el}`} accessible={false}>
                <Text style={styles.text}>{el}</Text>
              </View>
            ))}
          </View>
        )}

        <EstadioButton
          onPress={getUserCode}
          label={isGettingCode ? loadingText : buttonText}
          btnStyle={styles.buttonStyle}
          labelStyle={styles.labelStyle}
          disabled={isGettingCode}
          focusKey={FocusKeys.PIN_LOGIN_UPDATE_CODE}
        />
      </View>
      <View style={styles.qrView}>
        <ImageBackground source={background} resizeMode={'cover'} style={styles.qrStyleContainer} blurRadius={80}>
          {qrLoading ? <LoadingAppIndicator /> : <QRCode value={qrCodeLink} size={qrWidth} fgColor={'transparent'} />}
        </ImageBackground>
      </View>
    </View>
  );
};

export default PinLoginScreen;
