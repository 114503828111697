import { ViewStyle, StyleSheet, StyleProp, TextStyle } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';
import { merge } from 'lodash';

interface Styles {
  fullScreenContainer: ViewStyle;
  container: ViewStyle;
  textContainer: ViewStyle;
  containerInner: ViewStyle;
  title: TextStyle;
  description: TextStyle;
  buttonStyle: ViewStyle;
  buttonLabelStyle: TextStyle;
}

export const getStyles = (breakpoints: Breakpoints, colors: ReactNativePaper.ThemeColors) => {
  const base: StyleProp<Styles> = {
    fullScreenContainer: {
      backgroundColor: colors.tenantBackground.dark.modalBackground,
      position: 'absolute',
      width: '100%',
      height: '100%',
    },
    container: {
      flexDirection: 'column',
      paddingVertical: 18.4,
      paddingHorizontal: 18.4,
      backgroundColor: colors.tenantBackground.dark.surface03,
      alignItems: 'center',
      justifyContent: 'center',
      alignSelf: 'center',
    },
    textContainer: {
      paddingHorizontal: 58,
      paddingVertical: 48,
    },
    containerInner: {
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
    },
    title: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Oswald-SemiBold',
      fontSize: 22,
      fontWeight: '600',
      textAlign: 'center',
      textTransform: 'uppercase',
    },
    description: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Rubik-Regular',
      fontSize: 16,
      fontWeight: 'normal',
      lineHeight: 20,
      textAlign: 'center',
      marginVertical: 24,
    },
    buttonStyle: {
      marginTop: 0,
    },
    buttonLabelStyle: {
      fontFamily: 'Oswald-Regular',
      color: colors.keyboardFocus.light,
      textTransform: 'uppercase',
      fontSize: 20,
    },
  };

  const mediaQueries =
    breakpoints.currentBreakpoints.isTiny ||
    breakpoints.currentBreakpoints.isTnySm ||
    breakpoints.currentBreakpoints.isSmMed
      ? {
          fullScreenContainer: {
            justifyContent: 'center',
          },
          container: {
            width: '86%',
            marginVertical: '25%',
          },
          textContainer: {
            paddingHorizontal: 20,
            paddingVertical: 18,
          },
        }
      : breakpoints.currentBreakpoints.isMedLg
      ? ({
          container: {
            width: '74%',
            paddingHorizontal: 10,
            marginVertical: '12%',
          },
          textContainer: {
            paddingHorizontal: 20,
            paddingVertical: 18,
          },
        } as StyleProp<Styles>)
      : breakpoints.currentBreakpoints.isXlXxl || breakpoints.currentBreakpoints.isLgXl
      ? ({
          container: {
            width: '50%',
            marginVertical: '12%',
          },
          textContainer: {
            paddingHorizontal: 30,
            paddingVertical: 30,
          },
        } as StyleProp<Styles>)
      : breakpoints.currentBreakpoints.isXxl
      ? ({
          container: {
            width: '43%',
            marginVertical: '12%',
          },
          textContainer: {},
        } as StyleProp<Styles>)
      : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
