import { StyleProp, StyleSheet, TextStyle, ViewStyle } from 'react-native';
import { merge } from 'lodash';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';

interface Styles {
  homeBtn: ViewStyle;
  infoWrapper: ViewStyle;
  infoTextWrapper: ViewStyle;
  iconWrapper: ViewStyle;
  info: TextStyle;
  transactNo: TextStyle;
  btn: TextStyle;
  btnLabel: TextStyle;
  centerContainerTitle: ViewStyle;
  childrenWrapper: ViewStyle;
  container: ViewStyle;
  centeredWrapper: ViewStyle;
  titleContainer: ViewStyle;
  title: TextStyle;
  centerLayoutTitle: TextStyle;
  titleWrapper: ViewStyle;
}

export const getStyles = (breakpoints: Breakpoints, isTv: boolean, colors: ReactNativePaper.ThemeColors) => {
  const base: StyleProp<Styles> = {
    homeBtn: {
      backgroundColor: colors.fill.action.accent01,
      borderColor: 'transparent',
      width: '100%',
      borderRadius: 4,
      marginVertical: 10,
    },
    infoWrapper: {
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
    infoTextWrapper: {
      width: '100%',
      marginTop: 0,
    },
    iconWrapper: {
      height: 25,
      width: 25,
      justifyContent: 'center',
      alignItems: 'center',
      borderColor: colors.tenantBackground.light.surfaceBase,
      borderRadius: 13,
      borderWidth: 2,
      marginTop: 5,
    },
    info: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Rubik-Regular',
      fontSize: 16,
      fontWeight: 'normal',
      marginVertical: 10,
      marginLeft: 10,
      width: '90%',
    },
    transactNo: {
      fontWeight: '600',
    },
    btn: {
      width: '100%',
      borderRadius: 4,
      borderColor: colors.tenantBackground.light.surfaceBase,
      marginVertical: 10,
    },
    btnLabel: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Oswald-Medium',
      fontSize: 20,
      fontWeight: '500',
      height: 24,
      lineHeight: 24,
      textAlign: 'center',
      textTransform: 'uppercase',
    },
    childrenWrapper: {
      width: '100%',
      paddingHorizontal: 25,
      paddingBottom: 25,
    },
    container: {
      width: '100%',
      height: '100%',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    centeredWrapper: {
      width: '100%',
      height: '100%',
      maxWidth: 500,
      alignSelf: 'center',
    },
    centerContainerTitle: {
      marginTop: 48,
      marginBottom: 20,
    },
    titleContainer: {
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'flex-end',
      paddingHorizontal: 25,
    },
    title: {
      color: colors.keyboardFocus.light,
      fontWeight: '600',
      fontFamily: 'Oswald-SemiBold',
      textTransform: 'uppercase',
      textAlign: 'left',
      width: '100%',
    },
    centerLayoutTitle: {
      fontSize: 22,
    },
    titleWrapper: {
      width: '100%',
      justifyContent: 'flex-start',
    },
  };

  const mediaQueries = isTv
    ? ({
        centerLayoutTitle: {
          fontSize: 44,
        },
        centerContainerTitle: {
          marginBottom: 15,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isTiny ||
      breakpoints.currentBreakpoints.isTnySm ||
      breakpoints.currentBreakpoints.isSmMed
    ? {
        titleContainer: {
          flexDirection: 'column',
          alignItems: 'flex-start',
        },
      }
    : breakpoints.currentBreakpoints.isXlXxl || breakpoints.currentBreakpoints.isXxl
    ? ({
        centerContainerTitle: {
          marginBottom: 24,
        },
      } as StyleProp<Styles>)
    : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
