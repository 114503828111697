import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  toRow: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  wrapText: {
    flex: 1,
    color: '#fff',
    flexWrap: 'wrap',
    fontFamily: 'Rubik-Regular',
  },
  topSpace: {
    marginTop: 20,
    padding: 4,
    paddingTop: 5,
  },
  txtMess: {
    color: '#fff',
    fontSize: 12,
    marginLeft: 4,
  },
  messageContainer: {
    alignItems: 'center',
    padding: 4,
    borderRadius: 4,
    justifyContent: 'center',
  },
  displayIcon: {
    margin: 0,
    padding: 0,
  },
  messageButton: {
    marginTop: 2,
    marginRight: -3,
  },
});
