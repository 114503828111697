import React from 'react';
import { useTizen } from '../../hooks/useTizen';

interface GoBackProps {
  device: string;
}

const GoBack = (props: GoBackProps) => {
  const { device } = props;
  useTizen(device);
  return <></>;
};

export default GoBack;
