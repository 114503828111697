import { useCallback } from 'react';
import { NativeSyntheticEvent, TextInputKeyPressEventData } from 'react-native';

interface OnEnterKeyPressAction {
  onEnterKeyPress: (e: NativeSyntheticEvent<TextInputKeyPressEventData>, callback: () => void) => void;
}

export const useOnEnterKeyPress = (): OnEnterKeyPressAction => {
  const onEnterKeyPress = useCallback((e: NativeSyntheticEvent<TextInputKeyPressEventData>, callback: () => void) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      callback();
    }
  }, []);

  return {
    onEnterKeyPress,
  };
};

export default useOnEnterKeyPress;
