import React, { useRef } from 'react';
import { PressableProps, View, ViewStyle } from 'react-native';
import { PressableWithOpacity, useSpatialNavigation } from '@warnermmedia/gsp-core/sdk/ui';
import { getUniqueId } from '@warnermmedia/gsp-core/brands/estadio/feature';
import { FocusKeys, ItemName } from '@warnermmedia/gsp-core/sdk/data-access';

export interface FooterLinkProps extends PressableProps {
  focusKey?: string | null;
  overrideStyle?: ViewStyle | ViewStyle[];
  index: number;
  dataLength?: number;
  leftFocusKey?: string | null;
  focusStyle?: ViewStyle | ViewStyle[];
  isNavMobile: boolean;
}

export const FooterLink = ({
  focusKey,
  children,
  onPress,
  overrideStyle = {},
  dataLength,
  index,
  leftFocusKey,
  focusStyle = {},
  isNavMobile,
}: FooterLinkProps) => {
  const footerLinkFocusKey = useRef(focusKey ?? getUniqueId(ItemName.FOOTER_LINK)).current;
  const { ref, focused, focusSelf, setFocus, setFocusOnNavbar } = useSpatialNavigation({
    focusKey: footerLinkFocusKey,
    onArrowPress: (direction) => {
      if (direction === 'right' && dataLength === index) {
        focusSelf();
        return false;
      }

      if (direction === 'left' && index === 0 && !isNavMobile && leftFocusKey) {
        if (leftFocusKey === FocusKeys.SIDEBAR) {
          setFocusOnNavbar();
        } else {
          setFocus(leftFocusKey);
        }
      }

      if (direction === 'left' && index === 0 && !leftFocusKey && !isNavMobile) {
        focusSelf();
        return false;
      }

      if ((direction === 'left' || direction === 'right') && isNavMobile) {
        focusSelf();
        return false;
      }

      return true;
    },
  });

  return (
    <PressableWithOpacity onPress={onPress} style={overrideStyle} ref={ref} nativeID={footerLinkFocusKey}>
      <View style={focused && focusStyle}>{children}</View>
    </PressableWithOpacity>
  );
};
