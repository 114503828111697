import React, { useCallback } from 'react';
import { callbackNavigateExternal, languageStrings } from '@warnermmedia/gsp-core/brands/estadio/feature';
import { getStyles } from './maxRetention.styles';
import { useReactiveVar } from '@apollo/client';
import { LayoutType, LayoutWrapper, TitleType } from '../../../../layoutWrapper';
import { breakpointsStateStore, Component, MAX_URL } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { EstadioButton } from '../../../../components';
import { MetaTags, TextContent, useGetDevice } from '@warnermmedia/gsp-core/sdk/ui';
import { useTheme } from 'react-native-paper';
import get from 'lodash/get';
import { Image, Pressable, View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { FocusKeys } from '@warnermmedia/gsp-core/sdk/data-access';

interface MaxRetention {
  loading: boolean;
  maxSubTitle: string;
  cancelSubscription: () => void;
  retentionComponent?: Component | null | undefined;
}

export const MaxRetention = ({ retentionComponent, cancelSubscription, loading, maxSubTitle }: MaxRetention) => {
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const { colors } = useTheme();
  const styles = getStyles(breakpoints, colors);
  const { isWeb, isAndroidDevice } = useGetDevice();
  const retentionImage = get(retentionComponent, 'images[0].image.url', '');
  const retentionMobileImage = get(retentionComponent, 'images[1].image.url', '');
  const isMobileScreen = breakpoints.breakpointHelpers.isMobileScreen;

  const callbackNavigateExternalMax = useCallback(() => {
    if (isWeb || isAndroidDevice) {
      callbackNavigateExternal(retentionComponent?.link?.uri ?? MAX_URL);
    }
  }, [isWeb, isAndroidDevice, retentionComponent]);

  return (
    <LayoutWrapper
      pageTitle={languageStrings.default.retentionTitle}
      layoutType={LayoutType.Full}
      titleType={TitleType.Left}
      subTitle={maxSubTitle}
    >
      {isWeb && <MetaTags title={maxSubTitle} />}
      <View style={styles.container}>
        <View style={styles.maxImageContainer}>
          <LinearGradient
            colors={[
              colors.tenantBackground.dark.surface04FilmGrad.start,
              colors.tenantBackground.dark.surface04FilmGrad.end,
            ]}
            style={styles.offerContainerOvelay}
          >
            <TextContent style={styles.offerText}>{retentionComponent?.subheadline}</TextContent>
          </LinearGradient>
        </View>
        <Pressable onPress={callbackNavigateExternalMax}>
          <Image
            source={{ uri: isMobileScreen ? retentionMobileImage : retentionImage }}
            style={isMobileScreen ? styles.maxMobileImage : styles.maxImage}
          />
        </Pressable>
        <EstadioButton
          onPress={cancelSubscription}
          disabled={loading}
          label={loading ? languageStrings.default.loadingText : languageStrings.default.cancelSubscriptionLabel}
          labelStyle={styles.btnText}
          btnStyle={styles.cancelBtn}
          focusKey={FocusKeys.CANCEL_SUBSCRIPTION}
        />
      </View>
    </LayoutWrapper>
  );
};

export default MaxRetention;
