import { Alert } from "react-native";

export const validateUserName = (username: string) => {
  // TODO! - incorperate default user name conditions
  const alphaCharacters = /^[A-Za-z]+$/;
  if (alphaCharacters.test(username)) {
    return true;
  } else if (username.length <= 0) {
    Alert.alert("Please enter your name");
  }
  return false;
};

export const validateUserEmail = (email: string) => {
  const emailCharacters = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (emailCharacters.test(email)) {
    return true;
  } else if (email.length <= 0) {
    Alert.alert("Please Enter a valid email address");
  }
  return false;
};

export const validatePassword = (password: string) => {
  if (password.length < 8) {
    Alert.alert("Password must be at least 8 characters");
    return false;
  }

  return true;
};
