import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ImageSourcePropType, Linking, Text, View, ViewStyle } from 'react-native';
import { useReactiveVar } from '@apollo/client';
import {
  AppConfigContext,
  breakpointsStateStore,
  HistoryContext,
  loginAPIStateStore,
  userHasSubscription,
} from '@warnermmedia/gsp-core/brands/estadio/data-access';
import {
  CardDefaultBackground,
  Dalton,
  getContentTitle,
  getImageWithStyle,
  languageStrings,
  ScreenEventType,
  useCardItemImage,
  useIsMountedRef,
  useLogout,
  useMparticleScreenEvent,
  useProfileData,
} from '@warnermmedia/gsp-core/brands/estadio/feature';
import { formatDateDDMMYYYY, MessageDisplay, MetaTags, useGetDevice, Variant } from '@warnermmedia/gsp-core/sdk/ui';
import { getStyles } from './myaccount.styles';
import SubscriptionDetails from './subscription/subscriptionInfo';
import TVAccountDetailsComponent from './tvAccountDetails/tvAccountDetails';
import AccountDetailsComponent from './accountDetails/accountDetails';
import { LayoutType, LayoutWrapper, TitleType } from '../../layoutWrapper';
import { EditAccountDetailsComponent } from './editAccountDetails';
import { EstadioButton } from '../../components';
import QrCodeModal from '../../modal/qrcodeModal';
import {
  FocusKeys,
  loadData,
  Preference,
  Subscription,
  TVE_TOOLBOX_AUTH_TOKEN,
} from '@warnermmedia/gsp-core/sdk/data-access';
import { IconButton, useTheme } from 'react-native-paper';
import get from 'lodash/get';
import { capitalize, isEmpty } from 'lodash';

export type MyAccountProps = React.ComponentPropsWithRef<typeof View> & {
  containerStyle?: ViewStyle;
};

export const MyAccount = ({ containerStyle }: MyAccountProps) => {
  const historyContext = useContext(HistoryContext);
  const history = historyContext?.ready ? historyContext?.useHistory() : null;
  const { colors } = useTheme();
  const device = useGetDevice();
  const { isTv, isWeb, isPwa } = device;
  const appConfig = useContext(AppConfigContext);
  const baseUrlLink = appConfig?.appConfig?.webUrl ?? '';
  const { userData, mobileContact, address } = useProfileData();
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const styles = getStyles(breakpoints, colors);
  const [subscriptionEndDate, setSubscriptionEndDate] = useState('');
  const [subscriptionId, setSubscriptionId] = useState('');
  const [mode, setMode] = useState<'edit' | 'read'>('read');
  const [subscriptionActive, setSubscriptionActive] = useState(false);
  const [isPaypalUser, setIsPaypalUser] = useState(false);
  const [isSubscriptionCanceled, setSubscriptionCanceled] = useState(false);
  const [userCanCancelSubscription, setUserCanCancelSubscription] = useState(false);
  const [favoriteTeamLogo, setFavoriteTeamLogo] = useState<ImageSourcePropType | null>(null);
  const { competitors } = useReactiveVar(loginAPIStateStore);
  const hasSubscription = useReactiveVar(userHasSubscription);
  const [userPreferences, setUserPreferences] = useState<Preference[]>();
  const [userPreferencesErrorMessage, setUserPreferencesErrorMessage] = useState<string>();
  const isMountedRef = useIsMountedRef();
  const isEditMode = mode === 'edit';
  const tveConfig = loadData(TVE_TOOLBOX_AUTH_TOKEN);
  const tveConfigObject = isPwa && !isEmpty(tveConfig) ? JSON.parse(tveConfig) : tveConfig;
  const tveProvider = capitalize(get(tveConfigObject, 'provider.description', ''));
  const isTveEnable = appConfig?.appConfig.tve.enabled && !isEmpty(tveConfig);
  useMparticleScreenEvent(ScreenEventType.Profile, languageStrings.default.profilePage);
  const dalton = Dalton();
  const { logout } = useLogout();
  const { getCardImageSource } = useCardItemImage();
  const subscriptionEnabled = appConfig?.appConfig?.subscriptionEnabled ?? true;

  useEffect(() => {
    if (hasSubscription && !isTveEnable && isMountedRef.current) {
      handleGetSubscriptions();
    }
  }, [hasSubscription, isTveEnable, isMountedRef]);

  useEffect(() => {
    if (competitors && competitors?.length > 0 && userData && isMountedRef.current) {
      const teamObj = competitors.find((competitor) => competitor.title === userData.favoriteTeam);
      const teamLogo = getCardImageSource({
        fallbackName: CardDefaultBackground.Team,
        image: getImageWithStyle(teamObj?.image),
      });
      teamObj && teamLogo && setFavoriteTeamLogo(teamLogo);
    }
  }, [competitors, userData.favoriteTeam, isMountedRef, getCardImageSource]);

  const toBillingInfo = useCallback(() => {
    if (isTv) {
      setMode('edit');
    } else {
      history?.push('/profile/billing');
    }
  }, [isTv, history]);

  const getUserPreferences = async () => {
    const userPreferencesResponse = await dalton.getPreferences();
    if (isMountedRef.current) {
      if (userPreferencesResponse.success) {
        setUserPreferences(get(userPreferencesResponse, 'data.preferences', []));
      } else {
        setUserPreferencesErrorMessage(languageStrings.default.userPreferencesErrorMessage);
      }
    }
  };

  useEffect(() => {
    if (isMountedRef.current && !isTveEnable) {
      getUserPreferences();
    }
  }, [isMountedRef, isTveEnable]);

  const handleGetSubscriptions = async () => {
    const result = await dalton.getSubscriptions();
    if (result.success && result?.data) {
      const subscription = get(result.data, 'subscriptions', []).find(
        (subscription: Subscription) => subscription.active === true
      );
      const isPaypalUser = subscription?.paymentType === 'PayPal';

      if (isPaypalUser) {
        setIsPaypalUser(true);
      }
      if (subscription && isMountedRef.current) {
        setSubscriptionActive(true);
        setSubscriptionId(subscription.id);
        setSubscriptionEndDate(
          formatDateDDMMYYYY(subscription.nextBillDateMillis || subscription.terminationDateMillis)
        );
        // Subscriptions should have a payment method specified, if not are bought with a coupon
        setUserCanCancelSubscription(!subscription.card && !subscription.paymentType && isPaypalUser);
        if (subscription.cancelDateMillis) {
          setSubscriptionCanceled(true);
        }
      }
    }
  };

  const toggleEditForm = useCallback(() => {
    if (mode === 'read') {
      setMode('edit');
    } else {
      setMode('read');
    }
  }, [mode]);

  const profileLink = (
    <Text
      onPress={() => {
        Linking.openURL(`${baseUrlLink}/account`);
      }}
      style={styles.profileLink}
    >
      {`${baseUrlLink}/account`}
    </Text>
  );

  const description = (
    <Text>
      {languageStrings.default.tvEditProfileDescriptionLine1}
      {profileLink}
      {languageStrings.default.tvEditProfileDescriptionLine2}
    </Text>
  );

  if (isTv) {
    return (
      <LayoutWrapper
        layoutType={LayoutType.Full}
        titleType={TitleType.Left}
        pageTitle={languageStrings.default.profilePage}
      >
        <QrCodeModal
          visible={isEditMode}
          onChangeVisible={toggleEditForm}
          title={languageStrings.default.tvEditProfileQRTitle}
          description={description}
          buttonLabel={languageStrings.default.tvEditProfileQRButtonLabel}
          qrCodeString={baseUrlLink ? `${baseUrlLink}/profile` : ''}
          onOkPress={() => setMode('read')}
          device={device.device}
        />
        <View style={[styles.container, containerStyle]}>
          <TVAccountDetailsComponent
            userData={userData}
            favoriteTeamLogo={favoriteTeamLogo}
            subscriptionEndDate={subscriptionEndDate}
            isTveAccount={!!isTveEnable}
            tveProvider={tveProvider}
          />
          <View style={{ width: 546 }}>
            {!isTveEnable && (
              <EstadioButton
                onPress={() => setMode('edit')}
                label={languageStrings.default.tvEditProfileQREditButton}
                labelStyle={{
                  fontFamily: 'Oswald-Regular',
                }}
                outlined
                btnStyle={{ marginTop: 28 }}
                focusKey={FocusKeys.PROFILE}
              />
            )}
            <EstadioButton
              onPress={logout}
              label={languageStrings.default.logoutText}
              labelStyle={{
                fontFamily: 'Oswald-Regular',
              }}
              btnStyle={{ marginTop: 28 }}
              focusKey={FocusKeys.PROFILE_LOGOUT}
            />
          </View>
        </View>
      </LayoutWrapper>
    );
  }

  return (
    <LayoutWrapper
      layoutType={LayoutType.Full}
      titleType={TitleType.Left}
      pageTitle={languageStrings.default.profilePage}
    >
      {isWeb && <MetaTags title={getContentTitle(languageStrings.default.profilePage)} />}

      <View style={[styles.container, containerStyle]}>
        {userPreferencesErrorMessage && (
          <MessageDisplay
            displayMessage={userPreferencesErrorMessage}
            variant={Variant.Generic}
            textStyle={styles.messageDisplayText}
            wrapperStyle={styles.messageDisplay}
            displayIcon={
              <IconButton
                style={styles.displayIcon}
                icon="alert-circle-outline"
                color={colors.tenantBackground.light.surfaceBase}
                size={22}
              />
            }
          />
        )}
        {/* This condition needs to be removed for TVE user when we get the api to set preferences*/}
        {!isTveEnable && (
          <View style={styles.subContainer}>
            {!isEditMode ? (
              <AccountDetailsComponent
                userData={userData}
                userPreferences={userPreferences}
                favoriteTeamLogo={favoriteTeamLogo}
                toggleEditForm={toggleEditForm}
                isTveAccount={!!isTveEnable}
              />
            ) : (
              <EditAccountDetailsComponent
                userData={userData}
                userPreferences={userPreferences}
                toggleEditForm={toggleEditForm}
                contactNumberObj={mobileContact}
                addressObj={address}
                isTveAccount={!!isTveEnable}
                device={device}
                colors={colors}
                breakpoints={breakpoints}
              />
            )}
          </View>
        )}
        <View style={styles.subContainer}>
          <View style={styles.subscriptionWrapper}>
            <SubscriptionDetails
              subscriptionEndDate={subscriptionEndDate}
              isSubscriptionActive={hasSubscription}
              subscriptionId={subscriptionId}
              isSubscriptionCanceled={isSubscriptionCanceled || userCanCancelSubscription}
              isTveAccount={!!isTveEnable}
              tveProvider={tveProvider}
              isPaypalUser={isPaypalUser}
              subscriptionEnabled={subscriptionEnabled}
            />
          </View>
        </View>
        <View style={styles.bottomContainer}>
          {!isTveEnable && (
            <EstadioButton
              label={languageStrings.default.checkPaymentInfo}
              onPress={toBillingInfo}
              btnColor="transparent"
              labelStyle={styles.textBtn}
            />
          )}
          <EstadioButton
            label={languageStrings.default.logoutText}
            onPress={logout}
            btnColor="transparent"
            labelStyle={styles.textBtn}
          />
        </View>
      </View>
    </LayoutWrapper>
  );
};

export default MyAccount;
