/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState } from 'react';
import { useHistoryHook, useParamsHook, UseRouterType, useLocationHook } from '../../Types/UseRouterTypes';
import { isWebPlatform } from '../../Utils/Platform';
import { useReactRouter } from './useReactRouter';
import { useReactRouterNative } from './useReactRouterNative';

export const useRouter = (): UseRouterType => {
  const [router, setRouter] = useState<UseRouterType>({
    useHistory: useHistoryHook,
    useParams: useParamsHook,
    useLocation: useLocationHook,
    ready: false,
  });

  useEffect(() => {
    let unmounted = false;
    (async (): Promise<void> => {
      const routerPromise = isWebPlatform() ? useReactRouter : useReactRouterNative;
      const { useHistory, useParams, useLocation, ready } = await routerPromise();
      if (!unmounted) {
        setRouter({
          useHistory,
          useParams,
          useLocation,
          ready,
        });
      }
    })();
    return () => {
      unmounted = true;
    };
  }, []);

  return router;
};
