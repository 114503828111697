import React, { ComponentPropsWithRef, useCallback, useContext } from 'react';
import { useReactiveVar } from '@apollo/client';
import { breakpointsStateStore, HistoryContext } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { languageStrings, useMparticleCustomEventObject } from '@warnermmedia/gsp-core/brands/estadio/feature';
import { TextContent, useGetDevice } from '@warnermmedia/gsp-core/sdk/ui';
import { View, ViewStyle } from 'react-native';
import { EstadioButton } from '../../../components';
import { getStyles } from '../myaccount.styles';
import { MParticleCustomEventTypes, mParticleEventProcessor } from '@warnermmedia/gsp-core/sdk/data-access';
import { useTheme } from 'react-native-paper';

export type SubscriptionDetailsProps = ComponentPropsWithRef<typeof View> & {
  subscriptionContainerStyle?: ViewStyle;
  subscriptionEndDate?: string;
  isSubscriptionActive: boolean | null;
  subscriptionId: string;
  isSubscriptionCanceled: boolean;
  isTveAccount: boolean;
  tveProvider: string;
  isPaypalUser: boolean;
  subscriptionEnabled: boolean;
};

export const SubscriptionDetails = ({
  subscriptionContainerStyle,
  subscriptionEndDate,
  subscriptionId,
  isSubscriptionCanceled,
  isTveAccount,
  tveProvider,
  isPaypalUser,
  isSubscriptionActive,
  subscriptionEnabled,
}: SubscriptionDetailsProps) => {
  const historyContext = useContext(HistoryContext);
  const history = historyContext?.ready ? historyContext?.useHistory() : null;
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const { colors } = useTheme();
  const styles = getStyles(breakpoints, colors);
  const { isMobileDevice } = useGetDevice();
  const mParticleEventData = useMparticleCustomEventObject();

  const cancelSubscription = useCallback(() => {
    // GSP-3270 requested to move this event to the start of the cancellation flow
    mParticleEventProcessor.pushMParticleEvent(MParticleCustomEventTypes.CancellationStartEvent, mParticleEventData);
    history?.push('/cancel-subscription', { subscriptionId, subscriptionEndDate, isPaypalUser });
  }, [subscriptionId, subscriptionEndDate, history, isPaypalUser]);

  const gotoSubscribe = useCallback(() => {
    history?.push('/subscribe');
  }, [history]);

  const handleSubscriptionBtnPress = useCallback(() => {
    isSubscriptionActive ? cancelSubscription() : gotoSubscribe();
  }, [isSubscriptionActive, gotoSubscribe, cancelSubscription]);

  const gotoRedeemCoupon = useCallback(() => {
    history?.push('/redeemcoupon');
  }, [history]);

  return (
    <View style={[styles.subscriptionDetailsContain, subscriptionContainerStyle]}>
      <View style={styles.viewWrapper}>
        <TextContent style={styles.subTitleStyle}>{languageStrings.default.subscriptionInfoTitle}</TextContent>
      </View>
      <View style={styles.subscriptionContent}>
        <View style={styles.viewWrapper}>
          <TextContent style={styles.label}>{languageStrings.default.subscriptionInfoSubTitle}</TextContent>
          {isSubscriptionActive ? (
            <TextContent style={styles.info}>
              {isTveAccount
                ? languageStrings.default.subscriptionTveInfoDescription(tveProvider)
                : languageStrings.default.subscriptionInfoDescription(subscriptionEndDate ?? '')}
            </TextContent>
          ) : (
            <TextContent style={styles.info}>
              {subscriptionEnabled
                ? languageStrings.default.noSubscriptionText
                : languageStrings.default.noSubscriptionEnabledText}
            </TextContent>
          )}
          {!(
            isSubscriptionCanceled ||
            isTveAccount ||
            isMobileDevice ||
            (!isSubscriptionActive && !subscriptionEnabled)
          ) ? (
            <View style={styles.btnWrapper}>
              <EstadioButton
                onPress={handleSubscriptionBtnPress}
                label={
                  isSubscriptionActive
                    ? languageStrings.default.subscriptionInfoCancelButton
                    : languageStrings.default.activateSubscription
                }
                labelStyle={{
                  fontFamily: 'Oswald-Medium',
                }}
              />
            </View>
          ) : null}
          {!(isTveAccount || isMobileDevice) && isSubscriptionActive && subscriptionEnabled ? (
            <EstadioButton
              label={languageStrings.default.subscriptionInfoCoupon}
              onPress={gotoRedeemCoupon}
              btnColor="transparent"
              labelStyle={styles.textBtn}
              btnStyle={styles.couponBtn}
            />
          ) : null}
        </View>
      </View>
    </View>
  );
};

export default SubscriptionDetails;
