import { StyleProp, StyleSheet, ViewStyle, Dimensions } from 'react-native';
import merge from 'lodash/merge';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';

interface Styles {
  gridSkeletonCard: ViewStyle;
  gridSkeletonCardContainer: ViewStyle;
  gridSkeletonWrapper: ViewStyle;
  pageTitle: ViewStyle;
  pageSubtitle: ViewStyle;
  homeSkeletonContainer: ViewStyle;
  heroSkeletonCard: ViewStyle;
  teamSkeletonCard: ViewStyle;
  homeSkeletonWrapper: ViewStyle;
  teamSkeletonCardContainer: ViewStyle;
  teamSkeletonWrapper: ViewStyle;
  signalTvSkeletonContainer: ViewStyle;
  signalTvSkeletonWrapper: ViewStyle;
  signalTvSkeleton: ViewStyle;
  formularySkeletonCardContainer: ViewStyle;
  formularySkeletonCard: ViewStyle;
  landingLayout: ViewStyle;
  landingContent: ViewStyle;
  landingSkeletonWrapper: ViewStyle;
  landingVideoSkeletonWrapper: ViewStyle;
  landingSkeletonTitle: ViewStyle;
  landingTopMarketingImg: ViewStyle;
  landingBottomMarketingImg: ViewStyle;
  landingForm: ViewStyle;
  landingVideoDes: ViewStyle;
  landingVideoDescription: ViewStyle;
  landingSkeletonBody: ViewStyle;
  landingVideo: ViewStyle;
  videoSkeletonWrapper: ViewStyle;
  videoSkeletonBody: ViewStyle;
  mobileSkeletonBody: ViewStyle;
  matchSkeletonContainer: ViewStyle;
  matchCarousel: ViewStyle;
  matchSkeletonCard: ViewStyle;
}

export const getStyles = (breakpoints: Breakpoints, isTv: boolean, isMobileDevice: boolean, height: number) => {
  const window = Dimensions.get('window');
  const footerHeight = 149;

  const base: StyleProp<Styles> = {
    gridSkeletonCard: {
      width: 236,
      height: 136,
      marginRight: 8,
      marginBottom: 35,
      marginLeft: 8,
    },
    gridSkeletonCardContainer: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginTop: 20,
    },
    gridSkeletonWrapper: {
      flex: 1,
    },
    pageTitle: {
      width: 200,
      height: 49,
      borderRadius: 5,
      marginLeft: 8,
      marginTop: 24,
    },
    pageSubtitle: {
      width: 110,
      height: 33,
      borderRadius: 5,
      marginLeft: 8,
      marginTop: 24,
    },
    homeSkeletonContainer: {
      flexDirection: 'row',
      marginTop: 10,
      marginLeft: 2,
    },
    heroSkeletonCard: {
      height: 382,
      marginTop: 20,
      borderRadius: 10,
      marginLeft: 10,
    },
    homeSkeletonWrapper: {
      flex: 1,
    },
    teamSkeletonCard: {
      width: 111,
      height: 111,
      marginBottom: 45,
      marginRight: 10,
    },
    teamSkeletonCardContainer: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginTop: 20,
      marginLeft: 8,
    },
    teamSkeletonWrapper: {
      flex: 1,
    },
    signalTvSkeletonContainer: {
      flexDirection: 'row',
      marginTop: 20,
    },
    signalTvSkeletonWrapper: {
      flex: 1,
    },
    signalTvSkeleton: {
      paddingBottom: '56.25%',
    },
    formularySkeletonCardContainer: {
      marginTop: 20,
    },
    formularySkeletonCard: {
      height: 30,
    },
    landingLayout: {
      marginTop: 40,
      marginBottom: 70,
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    },
    landingContent: {
      width: '100%',
      maxWidth: 1028,
    },
    landingSkeletonWrapper: {
      flexDirection: 'row',
      justifyContent: 'center',
      maxWidth: 1028,
      width: '100%',
    },
    landingSkeletonTitle: {
      marginTop: 20,
      width: '60%',
    },
    landingTopMarketingImg: {
      height: 148,
    },
    landingBottomMarketingImg: {
      height: 330,
      marginTop: 20,
    },
    landingSkeletonBody: {
      width: '30%',
      marginLeft: 50,
      marginTop: 10,
    },
    landingForm: {
      height: 447,
    },
    landingVideoSkeletonWrapper: {
      flexDirection: 'row',
      justifyContent: 'center',
      marginTop: 80,
      maxWidth: 1028,
    },
    landingVideo: {
      width: '50%',
      marginLeft: 50,
    },
    landingVideoDes: {
      width: '40%',
    },
    landingVideoDescription: {
      height: 234,
    },
    videoSkeletonWrapper: {
      flex: 1,
    },
    videoSkeletonBody: {
      height: height - footerHeight,
      margin: 10,
    },
    mobileSkeletonBody: {
      margin: 10,
      height: 550,
    },
    matchSkeletonContainer: {
      marginTop: 20,
    },
    matchCarousel: {
      flexDirection: 'row',
    },
    matchSkeletonCard: {
      width: 236,
      height: 136,
      marginRight: 8,
      marginBottom: 25,
      marginLeft: 8,
    },
  };

  const mediaQueries = isTv
    ? ({
        gridSkeletonCard: {
          width: 405,
          height: 228,
          marginRight: 16,
          marginBottom: 35,
          marginLeft: 8,
        },
        pageTitle: {
          width: 261,
          height: 65,
          borderRadius: 5,
          marginLeft: 8,
          marginTop: 24,
        },
        pageSubtitle: {
          width: 171,
          height: 49,
          borderRadius: 5,
          marginLeft: 8,
          marginTop: 24,
        },
        teamSkeletonCard: {
          width: 200,
          height: 200,
          marginRight: 17,
        },
        videoSkeletonBody: {
          height: height - 20,
        },
        matchSkeletonCard: {
          width: 405,
          height: 228,
          marginRight: 16,
          marginBottom: 35,
          marginLeft: 8,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isTiny || breakpoints.currentBreakpoints.isTnySm
    ? ({
        gridSkeletonCard: {
          alignItems: 'center',
          justifyContent: 'center',
          width: isMobileDevice ? window.width - 50 : '100%',
          marginLeft: 0,
          height: 183,
        },
        gridSkeletonCardContainer: {
          flexDirection: 'column',
          flexWrap: 'wrap',
          width: '100%',
        },
        teamSkeletonCard: {
          width: 90,
          height: 90,
        },
        teamSkeletonCardContainer: {
          marginLeft: 0,
        },
        heroSkeletonCard: {
          height: 284,
        },
        landingLayout: {
          marginTop: 40,
        },
        landingSkeletonWrapper: {
          flexDirection: 'column',
        },
        landingVideoSkeletonWrapper: {
          flexDirection: 'column-reverse',
        },
        landingSkeletonTitle: {
          width: '100%',
        },
        landingSkeletonBody: {
          width: '100%',
          marginLeft: 0,
        },
        landingVideo: {
          width: '100%',
          marginLeft: 0,
        },
        landingVideoDes: {
          width: '100%',
          marginTop: 30,
        },
        mobileSkeletonBody: {
          height: 300,
        },
        matchSkeletonCard: {
          alignItems: 'center',
          justifyContent: 'center',
          width: isMobileDevice ? window.width - 50 : '100%',
          marginLeft: 0,
          height: 183,
        },
        pageTitle: {
          marginLeft: 0,
        },
        pageSubtitle: {
          marginLeft: 0,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isSmMed
    ? ({
        heroSkeletonCard: {
          height: 284,
        },
        teamSkeletonCard: {
          width: 100,
          height: 100,
        },
        landingLayout: {
          marginTop: 40,
        },
        landingContent: {
          width: '100%',
          maxWidth: 455,
        },
        landingSkeletonWrapper: {
          flexDirection: 'column',
        },
        landingVideoSkeletonWrapper: {
          flexDirection: 'column-reverse',
        },
        landingSkeletonTitle: {
          width: '100%',
        },
        landingSkeletonBody: {
          width: '100%',
          marginLeft: 0,
        },
        landingVideo: {
          width: '100%',
          marginLeft: 0,
        },
        landingVideoDes: {
          width: '100%',
          marginTop: 30,
        },
        mobileSkeletonBody: {
          height: 250,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isMedLg
    ? ({
        gridSkeletonCard: {
          width: 218,
          height: 124,
        },
        heroSkeletonCard: {
          height: 214,
        },
        landingLayout: {
          marginTop: 40,
        },
        landingContent: {
          width: '100%',
          maxWidth: 455,
        },
        landingSkeletonWrapper: {
          flexDirection: 'column',
        },
        landingSkeletonTitle: {
          width: '100%',
        },
        landingSkeletonBody: {
          width: '100%',
          marginLeft: 0,
        },
        landingVideoSkeletonWrapper: {
          flexDirection: 'column-reverse',
        },
        landingVideo: {
          width: '100%',
          marginLeft: 0,
        },
        landingVideoDes: {
          width: '100%',
          marginTop: 30,
        },
        matchSkeletonCard: {
          width: 218,
          height: 124,
        },
        mobileSkeletonBody: {
          height: 450,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isXlXxl || breakpoints.currentBreakpoints.isXxl
    ? ({} as StyleProp<Styles>)
    : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
