import { StyleProp, StyleSheet, ViewStyle, TextStyle } from 'react-native';
import merge from 'lodash/merge';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';

interface Styles {
  inputWrapper: ViewStyle;
  label: TextStyle;
  input: ViewStyle;
  inputFilled: ViewStyle;
  btn: ViewStyle;
  messageDisplay: ViewStyle;
  contactUsMobileSectionContainer: ViewStyle;
  contactUsWebSectionContainer: ViewStyle;
  contactUsMessage: TextStyle;
  contactUsBtn: ViewStyle;
  underlinedText: TextStyle;
}

export const getStyles = (breakpoints: Breakpoints, colors: ReactNativePaper.ThemeColors) => {
  const base: StyleProp<Styles> = {
    inputWrapper: {
      width: '100%',
    },
    label: {
      color: colors.tenantBackground.light.surfaceBase,
      fontFamily: 'Rubik-Medium',
      fontSize: 20,
      fontWeight: '500',
      alignSelf: 'flex-start',
      marginBottom: 5,
    },
    input: {
      paddingHorizontal: 10,
      borderRadius: 4,
      height: 38.83,
      width: '100%',
      backgroundColor: colors.tenantBackground.light.surfaceBase,
      borderColor: 'transparent',
      opacity: 0.8,
    },
    inputFilled: {
      paddingHorizontal: 10,
      borderRadius: 4,
      height: 38.83,
      width: '100%',
      backgroundColor: colors.tenantBackground.light.surfaceBase,
      borderColor: 'transparent',
      opacity: 1,
    },
    btn: {
      marginTop: 28,
    },
    messageDisplay: {
      minWidth: 300,
    },
    contactUsMobileSectionContainer: {
      flex: 1,
      marginTop: 20,
      alignItems: 'flex-start',
    },
    contactUsWebSectionContainer: {
      flex: 1,
      marginTop: 20,
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    contactUsMessage: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Rubik-Regular',
      fontSize: 16,
      fontWeight: 'normal',
      height: 20,
      lineHeight: 20,
      maxWidth: 451,
      marginRight: 8,
    },
    contactUsBtn: {
      alignSelf: 'flex-start',
      paddingHorizontal: 0,
    },
    underlinedText: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Oswald-Regular',
      fontSize: 14,
      fontWeight: 'normal',
      lineHeight: 21,
      textDecorationLine: 'underline',
      textTransform: 'uppercase',
    },
  };

  const mediaQueries = breakpoints.currentBreakpoints.isTiny ? ({} as StyleProp<Styles>) : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
