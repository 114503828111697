import React, { KeyboardEvent } from 'react';
import { Pressable, PressableProps, View, ViewStyle } from 'react-native';

export interface PressableWithOpacityProps extends PressableProps {
  overrideStyle?: ViewStyle | ViewStyle[];
  onKeyDown?: (e: KeyboardEvent) => void;
}

export const PressableWithOpacity = React.forwardRef(
  ({ overrideStyle, children, disabled, ...rest }: PressableWithOpacityProps, ref: React.Ref<View>) => {
    return (
      <Pressable
        style={({ pressed }) => [overrideStyle, { opacity: disabled ? 0.5 : pressed ? 0.5 : 1.0 }]}
        disabled={disabled}
        ref={ref}
        {...rest}
      >
        {children}
      </Pressable>
    );
  }
);

export default PressableWithOpacity;
