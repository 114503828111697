import React from 'react';
import Svg, { Path, G } from 'react-native-svg';

/* eslint-disable-next-line */
export interface PasswordHideProps {
  width?: number | string;
  height?: number | string;
}

export function PasswordHide(props: PasswordHideProps) {
  const width = props.width || 20;
  const height = props.height || 20;

  return (
    <Svg width={width} height={height} viewBox="0 0 20 20" fill="none">
      <G opacity="0.5">
        <Path
          d="M19 10C19 7.2 14.9706 3 10 3C5.02944 3 1 7.2 1 10"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <Path
          d="M12.1213 12.1213C13.2929 10.9497 13.2929 9.05023 12.1213 7.87866C10.9497 6.70709 9.05023 6.70709 7.87866 7.87866"
          stroke="black"
          strokeWidth="2"
        />
        <Path d="M2 3L16 15" stroke="black" strokeWidth="2" strokeLinecap="round" />
      </G>
    </Svg>
  );
}

export default PasswordHide;
