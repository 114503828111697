import { Platform, StyleSheet } from 'react-native';

export default StyleSheet.create({
  wrapper: {
    width: '100%',
    maxWidth: 1000,
    backgroundColor: 'transparent',
  },
  title: {
    color: 'white',
    textAlign: 'left',
    fontSize: 22,
    marginVertical: 15,
    fontFamily: 'Oswald-SemiBold',
    fontWeight: '600',
  },
  subTitle: {},
  details: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  detail: {
    width: 300,
    marginVertical: Platform.OS === 'web' ? 25 : 8,
    marginRight: 30,
  },
  detailLabel: {
    fontSize: 10,
    marginBottom: 5,
  },
  readOnlyValue: {
    backgroundColor: 'transparent',
    color: '#FFFFFF',
  },
  detailValue: {
    backgroundColor: 'transparent',
    paddingHorizontal: 2,
    paddingVertical: 0,
  },
  editableDetailValue: {
    backgroundColor: 'transparent',
    paddingHorizontal: 2,
    paddingVertical: 0,
    borderWidth: 1,
    borderRadius: 3,
    height: 40,
  },
  detailsWrapper: {
    width: '100%',
  },
  teamWrapper: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginVertical: 10,
  },
  teamName: {
    marginLeft: 15,
  },
  teamBadge: {
    width: 100,
    height: 100,
  },
  imageContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  detailsImage: {
    width: 50,
    height: 50,
  },
  accountFooter: {
    marginTop: 20,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  radioBtnWrapper: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 40,
  },
  radioLabelStyle: {
    fontSize: 16,
    color: '#FFFFFF',
    alignItems: 'flex-end',
    width: '100%',
    height: '100%',
  },
  dropdownInput: {
    paddingHorizontal: 2,
    paddingVertical: 0,
    borderRadius: 3,
    height: 40,
    backgroundColor: '#FFFFFF',
  },
  dropDownTextStyle: {
    fontSize: 16,
    fontFamily: 'Rubik-Regular',
    fontWeight: 'normal',
    color: 'rgb(0, 0, 0)',
  },
  hoverStyle: {
    backgroundColor: '#359FD7FF',
  },
  dropDownItemHoverStyle: {
    color: '#FFFFFF',
  },
  inputClickStyle: {
    borderWidth: 2,
    borderColor: '#359FD7FF',
  },
  detailValueFilled: {
    color: '#000000',
    backgroundColor: '#ffffff',
    fontFamily: 'Rubik-Regular',
    fontSize: 16,
    fontWeight: 'normal',
    height: 40,
    lineHeight: 24,
    borderWidth: 0,
    opacity: 1,
  },
});
