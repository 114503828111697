export default {
  // Red Color Names
  IndianRed: '#CD5C5C',
  IndianRedRGBA: 'rgba(205, 92, 92, 1.0)',
  LightCoral: '#F08080',
  LightCoralRGBA: 'rgba(240, 128, 128, 1.0)',
  Salmon: '#FA8072',
  SalmonRGBA: 'rgba(250, 128, 114, 1.0)',
  DarkSalmon: '#E9967A',
  DarkSalmonRGBA: 'rgba(233, 150, 122, 1.0)',
  Crimson: '#DC143C',
  CrimsonRGBA: 'rgba(220, 20, 60, 1.0)',
  Red: '#FF0000',
  RedRGBA: 'rgba(220, 20, 60, 1.0)',
  FireBrick: '#B22222',
  FireBrickRGBA: 'rgba(178, 34, 34, 1.0)',
  DarkRed: '#8B0000',
  DarkRedRGBA: 'rgba(139, 0, 0, 1.0)',
  //////////////////////////////////////////////////////

  // Pink Color Names
  Pink: '#FFC0CB',
  PinkRGBA: 'rgba(255, 192, 203, 1.0)',
  LightPink: '#FFB6C1',
  LightPinkRGBA: 'rgba(255, 182, 193, 1.0)',
  HotPink: '#FF69B4',
  HotPinkRGBA: 'rgba(255, 105, 180, 1.0)',
  DeepPink: '#FF1493',
  DeepPinkRGBA: 'rgba(255, 20, 147, 1.0)',
  MediumVioletRed: '#C71585',
  MediumVioletRedRGBA: 'rgba(199, 21, 133, 1.0)',
  PaleVioletRed: '#DB7093',
  PaleVioletRedRGBA: 'rgba(219, 112, 147, 1.0)',
  //////////////////////////////////////////////////////

  // Orange Color Names
  LightSalmon: '#FFA07A',
  LightSalmonRGBA: 'rgba(255, 160, 122, 1.0)',
  Coral: '#FF7F50',
  CoralRGBA: 'rgba(255, 127, 80, 1.0)',
  Tomato: '#FF6347',
  TomatoRGBA: 'rgba(255, 99, 71, 1.0)',
  OrangeRed: '#FF4500',
  OrangeRedRGBA: 'rgba(255, 69, 0, 1.0)',
  DarkOrange: '#FF8C00',
  DarkOrangeRGBA: 'rgba(255, 140, 0, 1.0)',
  Orange: '#FFA500',
  OrangeRGBA: 'rgba(255, 165, 0, 1.0)',
  //////////////////////////////////////////////////////

  // Yellow Color Names
  Gold: '#FFD700',
  GoldRGBA: 'rgba(255, 215, 0, 1.0)',
  Yellow: '#FFFF00',
  YellowRGBA: 'rgba(255, 255, 0, 1.0)',
  LightYellow: '#FFFFE0',
  LightYellowRGBA: 'rgba(255, 255, 224, 1.0)',
  LemonChiffon: '#FFFACD',
  LemonChiffonRGBA: 'rgba(255, 250, 205, 1.0)',
  LightGoldenrodYellow: '#FAFAD2',
  LightGoldenrodYellowRGBA: 'rgba(250, 250, 210, 1.0)',
  PapayaWhip: '#FFEFD5',
  PapayaWhipRGBA: 'rgba(255, 239, 213, 1.0)',
  Moccasin: '#FFE4B5',
  MoccasinRGBA: 'rgba(255, 228, 181, 1.0)',
  PeachPuff: '#FFDAB9',
  PeachPuffRGBA: 'rgba(255, 218, 185, 1.0)',
  PaleGoldenrod: '#EEE8AA',
  PaleGoldenrodRGBA: 'rgba(238, 232, 170, 1.0)',
  Khaki: '#F0E68C',
  KhakiRGBA: 'rgba(240, 230, 140, 1.0)',
  DarkKhaki: '#BDB76B',
  DarkKhakiRGBA: 'rgba(189, 183, 107, 1.0)',
  //////////////////////////////////////////////////////

  // Purple Color Names
  Lavender: '#E6E6FA',
  LavenderRGBA: 'rgba(230, 230, 250, 1.0)',
  Thistle: '#D8BFD8',
  ThistleRGBA: 'rgba(216, 191, 216, 1.0)',
  Plum: '#DDA0DD',
  PlumRGBA: 'rgba(221, 160, 221, 1.0)',
  Violet: '#EE82EE',
  VioletRGBA: 'rgba(238, 130, 238, 1.0)',
  Orchid: '#DA70D6',
  OrchidRGBA: 'rgba(218, 112, 214, 1.0)',
  Fuchsia: '#FF00FF',
  FuchsiaRGBA: 'rgba(255, 0, 255, 1.0)',
  Magenta: '#FF00FF',
  MagentaRGBA: 'rgba(255, 0, 255, 1.0)',
  MediumOrchid: '#BA55D3',
  MediumOrchidRGBA: 'rgba(186, 85, 211, 1.0)',
  MediumPurple: '#9370DB',
  MediumPurpleRGBA: 'rgba(147, 112, 219, 1.0)',
  RebeccaPurple: '#663399',
  RebeccaPurplRGBA: 'rgba(102, 51, 153, 1.0)',
  BlueViolet: '#8A2BE2',
  BlueVioletRGBA: 'rgba(138, 43, 226, 1.0)',
  DarkViolet: '#9400D3',
  DarkOrchidRGBA: 'rgba(148, 0, 211, 1.0)',
  DarkMagenta: '#8B008B',
  DarkMagentaRGBA: 'rgba(139, 0, 139, 1.0)',
  Purple: '#800080',
  PurpleRGBA: 'rgba(128, 0, 128, 1.0)',
  Indigo: '#4B0082',
  IndigoRGBA: 'rgba(75, 0, 130, 1.0)',
  SlateBlue: '#6A5ACD',
  SlateBlueRGBA: 'rgba(106, 90, 205, 1.0)',
  DarkSlateBlue: '#483D8B',
  DarkSlateBlueRGBA: 'rgba(72, 61, 139, 1.0)',
  MediumSlateBlue: '#7B68EE',
  MediumSlateBlueRGBA: 'rgba(123, 104, 238, 1.0)',

  // Green Color Names
  GreenYellow: '#ADFF2F',
  GreenYellowRGBA: 'rgba(173, 255, 47, 1.0)',
  Chartreuse: '#7FFF00',
  ChartreuseRGBA: 'rgba(127, 255, 0, 1.0)',
  LawnGreen: '#7CFC00',
  LawnGreenRGBA: '#7CFC00',
  Lime: '#00FF00',
  LimeRGBA: 'rgba(0, 255, 0, 1.0)',
  LimeGreen: '#32CD32',
  LimeGreenRGBA: 'rgba(50, 205, 50, 1.0)',
  PaleGreen: '#98FB98',
  PaleGreenRGBA: 'rgba(152, 251, 152, 1.0)',
  LightGreen: '#90EE90',
  LightGreenRGBA: 'rgba(144, 238, 144, 1.0)',
  MediumSpringGreen: '#00FA9A',
  MediumSpringGreenRGBA: 'rgba(0, 250, 154, 1.0)',
  SpringGreen: '#00FF7F',
  SpringGreenRGBA: 'rgba(0, 255, 127, 1.0)',
  MediumSeaGreen: '#3CB371',
  MediumSeaGreenRGBA: 'rgba(60, 179, 113, 1.0)',
  SeaGreen: '#2E8B57',
  SeaGreenRGBA: 'rgba(46, 139, 87, 1.0)',
  ForestGreen: '#228B22',
  ForestGreenRGBA: 'rgba(34, 139, 34, 1.0)',
  Green: '#008000',
  GreenRGBA: 'rgba(0, 128, 0, 1.0)',
  DarkGreen: '#006400',
  DarkGreenRGBA: 'rgba(0, 100, 0, 1.0)',
  YellowGreen: '#9ACD32',
  YellowGreenRGBA: 'rgba(154, 205, 50, 1.0)',
  OliveDrab: '#6B8E23',
  OliveDrabRGBA: 'rgba(107, 142, 35, 1.0)',
  Olive: '#808000',
  OliveRGBA: 'rgba(128, 128, 0, 1.0)',
  DarkOliveGreen: '#556B2F',
  DarkOliveGreenRGBA: 'rgba(85, 107, 47, 1.0)',
  MediumAquamarine: '#66CDAA',
  MediumAquamarineRGBA: '#rgb(102, 205, 170, 1.0)',
  DarkSeaGreen: '#8FBC8B',
  DarkSeaGreenRGBA: 'rgba(143, 188, 139, 1.0)',
  LightSeaGreen: '#20B2AA',
  LightSeaGreenRGBA: 'rgba(32, 178, 170, 1.0)',
  DarkCyan: '#008B8B',
  DarkCyanRGBA: 'rgba(0, 139, 139, 1.0)',
  Teal: '#008080',
  TealRGBA: 'rgba(0, 128, 128, 1.0)',
  //////////////////////////////////////////////////////

  // Blue Color Names
  Aqua: '#00FFFF',
  AquaRGBA: 'rgba(0, 255, 255, 1.0)',
  Cyan: '#00FFFF',
  CyanRGBA: 'rgba(0, 255, 255, 1.0)',
  LightCyan: '#E0FFFF',
  LightCyanRGBA: 'rgba(224, 255, 255, 1.0)',
  PaleTurquoise: '#AFEEEE',
  PaleTurquoiseRGBA: 'rgba(175, 238, 238, 1.0)',
  Aquamarine: '#7FFFD4',
  AquamarineRGBA: 'rgba(127, 255, 212, 1.0)',
  Turquoise: '#40E0D0',
  TurquoiseRGBA: 'rgba(64, 224, 208, 1.0)',
  MediumTurquoise: '#48D1CC',
  MediumTurquoiseRGBA: 'rgba(72, 209, 204, 1.0)',
  DarkTurquoise: '#00CED1',
  DarkTurquoiseRGBA: 'rgba(0, 206, 209, 1.0)',
  CadetBlue: '#5F9EA0',
  CadetBlueRGBA: 'rgba(95, 158, 160, 1.0)',
  SteelBlue: '#4682B4',
  SteelBlueRGBA: 'rgba(70, 130, 180, 1.0)',
  LightSteelBlue: '#B0C4DE',
  LightSteelBlueRGBA: 'rgba(176, 196, 222, 1.0)',
  PowderBlue: '#B0E0E6',
  PowderBlueRGBA: 'rgba(176, 224, 230, 1.0)',
  LightBlue: '#ADD8E6',
  LightBlueRGBA: 'rgba(173, 216, 230, 1.0)',
  SkyBlue: '#87CEEB',
  SkyBlueRGBA: 'rgba(135, 206, 235, 1.0)',
  LightSkyBlue: '#87CEFA',
  LightSkyBlueRGBA: 'rgba(135, 206, 250, 1.0)',
  DeepSkyBlue: '#00BFFF',
  DeepSkyBlueRGBA: 'rgba(0, 191, 255, 1.0)',
  DodgerBlue: '#1E90FF',
  DodgerBlueRGBA: 'rgba(30, 144, 255, 1.0)',
  CornflowerBlue: '#6495ED',
  CornflowerBlueRGBA: 'rgba(100, 149, 237, 1.0)',
  RoyalBlue: '#4169E1',
  RoyalBlueRGBA: 'rgba(65, 105, 225, 1.0)',
  Blue: '#0000FF',
  BlueRGBA: 'rgba(0, 0, 255, 1.0)',
  MediumBlue: '#0000CD',
  MediumBlueRGBA: 'rgba(0, 0, 205, 1.0)',
  DarkBlue: '#00008B',
  DarkBlueRGBA: 'rgba(0, 0, 139, 1.0)',
  Navy: '#000080',
  NavyRGBA: 'rgba(0, 0, 128, 1.0)',
  MidnightBlue: '#191970',
  MidnightBlueRGBA: 'rgba(25, 25, 112, 1.0)',
  //////////////////////////////////////////////////////

  // Brown Color Names
  Cornsilk: '#FFF8DC',
  CornsilkRGBA: 'rgba(255, 248, 220, 1.0)',
  BlanchedAlmond: '#FFEBCD',
  BlanchedAlmondRGBA: 'rgba(255, 235, 205, 1.0)',
  Bisque: '#FFE4C4',
  BisqueRGBA: 'rgba(255, 228, 196, 1.0)',
  NavajoWhite: '#FFDEAD',
  NavajoWhiteRGBA: 'rgba(255, 222, 173, 1.0)',
  Wheat: '#F5DEB3',
  WheatRGBA: 'rgba(245, 222, 179, 1.0)',
  BurlyWood: '#DEB887',
  BurlyWoodRGBA: 'rgba(222, 184, 135, 1.0)',
  Tan: '#D2B48C',
  TanRGBA: 'rgba(210, 180, 140, 1.0)',
  RosyBrown: '#BC8F8F',
  RosyBrownRGBA: 'rgba(188, 143, 143, 1.0)',
  SandyBrown: '#F4A460',
  SandyBrownRGBA: 'rgba(244, 164, 96, 1.0)',
  Goldenrod: '#DAA520',
  GoldenrodRGBA: 'rgba(218, 165, 32, 1.0)',
  DarkGoldenrod: '#B8860B',
  DarkGoldenrodRGBA: 'rgba(184, 134, 11, 1.0)',
  Peru: '#CD853F',
  PeruRGBA: 'rgba(205, 133, 63, 1.0)',
  Chocolate: '#D2691E',
  ChocolateRGBA: 'rgba(210, 105, 30, 1.0)',
  SaddleBrown: '#8B4513',
  SaddleBrownRGBA: 'rgba(139, 69, 19, 1.0)',
  Sienna: '#A0522D',
  SiennaRGBA: 'rgba(160, 82, 45, 1.0)',
  Brown: '#A52A2A',
  BrownRGBA: 'rgba(165, 42, 42, 1.0)',
  Maroon: '#800000',
  MaroonRGBA: 'rgba(128, 0, 0, 1.0)',
  //////////////////////////////////////////////////////

  // White Color Names
  White: '#FFFFFF',
  WhiteRGBA: 'rgba(255, 255, 255, 1.0)',
  Snow: '#FFFAFA',
  SnowRGBA: 'rgba(255, 250, 250, 1.0)',
  HoneyDew: '#F0FFF0',
  HoneyDewRGBA: 'rgba(240, 255, 240, 1.0)',
  MintCream: '#F5FFFA',
  MintCreamRGBA: 'rgba(245, 255, 250, 1.0)',
  Azure: '#F0FFFF',
  AzureRGBA: 'rgba(240, 255, 255, 1.0)',
  AliceBlue: '#F0F8FF',
  AliceBlueRGBA: 'rgba(240, 248, 255, 1.0)',
  GhostWhite: '#F8F8FF',
  GhostWhiteRGBA: 'rgba(248, 248, 255, 1.0)',
  WhiteSmoke: '#F5F5F5',
  WhiteSmokeRGBA: 'rgba(245, 245, 245, 1.0)',
  SeaShell: '#FFF5EE',
  SeaShellRGBA: 'rgba(255, 245, 238, 1.0)',
  Beige: '#F5F5DC',
  BeigeRGBA: 'rgba(245, 245, 220, 1.0)',
  OldLace: '#FDF5E6',
  OldLaceRGBA: 'rgba(253, 245, 230, 1.0)',
  FloralWhite: '#FFFAF0',
  FloralWhiteRGBA: 'rgba(255, 250, 240, 1.0)',
  Ivory: '#FFFFF0',
  IvoryRGBA: 'rgba(255, 255, 240, 1.0)',
  AntiqueWhite: '#FAEBD7',
  AntiqueWhiteRGBA: 'rgba(250, 235, 215, 1.0)',
  Linen: '#FAF0E6',
  LinenRGBA: 'rgba(250, 240, 230, 1.0)',
  LavenderBlush: '#FFF0F5',
  LavenderBlushRGBA: 'rgba(255, 240, 245, 1.0)',
  MistyRose: '#FFE4E1',
  MistyRoseRGBA: 'rgba(255, 228, 225, 1.0)',
  //////////////////////////////////////////////////////

  // Gray Color Names
  Gainsboro: '#DCDCDC',
  GainsboroRGBA: 'rgba(220, 220, 220, 1.0)',
  LightGray: '#D3D3D3',
  LightGrayRGBA: 'rgba(211, 211, 211, 1.0)',
  Silver: '#C0C0C0',
  SilverRGBA: 'rgba(192, 192, 192, 1.0)',
  DarkGray: '#A9A9A9',
  DarkGrayRGBA: 'rgba(169, 169, 169, 1.0)',
  Gray: '#808080',
  GrayRGBA: 'rgba(128, 128, 128, 1.0)',
  DimGray: '#696969',
  DimGrayRGBA: 'rgba(105, 105, 105, 1.0)',
  LightSlateGray: '#778899',
  LightSlateGrayRGBA: 'rgba(119, 136, 153, 1.0)',
  SlateGray: '#708090',
  SlateGrayRGBA: 'rgba(112, 128, 144, 1.0)',
  DarkSlateGray: '#2F4F4F',
  DarkSlateGrayRGBA: 'rgba(47, 79, 79, 1.0)',
  Black: '#000000',
  BlackRGBA: 'rgba(0, 0, 0, 1.0)',
  FooterImageColor: '#000a5a',
  //////////////////////////////////////////////////////
};
