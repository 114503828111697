import { StyleProp, StyleSheet, ViewStyle } from 'react-native';
import merge from 'lodash/merge';
import { ScaleAction } from '@warnermmedia/gsp-core/brands/estadio/feature';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';

interface Styles {
  skeletonDetailCard: ViewStyle;
  skeletonDetailCardContainer: ViewStyle;
  detailContentContainer: ViewStyle;
  contentLayout: ViewStyle;
  skeletonDetailWrapper: ViewStyle;
  titleWrapper: ViewStyle;
  teamBadge: ViewStyle;
  textBoxStyle: ViewStyle;
  pageTitle: ViewStyle;
  pageDescription: ViewStyle;
  showTitleWrapper: ViewStyle;
}

export const getStyles = (breakpoints: Breakpoints, isTv: boolean) => {
  const base: StyleProp<Styles> = {
    skeletonDetailCard: {
      width: 239,
      height: 135,
      marginRight: 8,
      marginBottom: 20,
      marginLeft: 8,
    },
    skeletonDetailCardContainer: {
      flexDirection: 'row',
      marginTop: 5,
    },
    detailContentContainer: {},
    contentLayout: {
      marginTop: 20,
    },
    skeletonDetailWrapper: {
      flex: 1,
      paddingLeft: 25,
    },
    titleWrapper: {
      marginTop: 25,
      flexDirection: 'row',
      marginBottom: 32,
      paddingLeft: 8,
    },
    teamBadge: {
      height: 111,
      width: 111,
      borderRadius: 5,
    },
    textBoxStyle: {
      flexDirection: 'column',
      marginLeft: 16,
      flex: 1,
      marginTop: 'auto',
    },
    pageTitle: {
      height: 49,
      width: 149,
      borderRadius: 5,
    },
    pageDescription: {
      height: 40,
      width: '70%',
      borderRadius: 5,
      marginTop: 8,
    },
    showTitleWrapper: {
      paddingLeft: 8,
      marginBottom: 32,
    },
  };

  const mediaQueries = isTv
    ? ({
        skeletonDetailCard: {
          width: 405,
          height: 228,
        },
        teamBadge: {
          height: 194,
          width: 194,
          borderRadius: 5,
        },
        pageTitle: {
          height: 65,
          width: 200,
          borderRadius: 5,
        },
        pageDescription: {
          width: 260,
          height: 49,
          marginTop: 24,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isTiny || breakpoints.currentBreakpoints.isTnySm
    ? ({
        skeletonDetailCard: {
          width: 270,
          height: 152,
        },
        skeletonDetailWrapper: {
          paddingLeft: 17,
        },
        textBoxStyle: {
          marginLeft: 0,
          marginTop: 8,
        },
        titleWrapper: {
          flexDirection: 'column',
          marginTop: 16,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isSmMed
    ? ({
        skeletonDetailCard: {
          width: 270,
          height: 152,
        },
        skeletonDetailWrapper: {
          flex: 1,
          paddingLeft: 32,
        },
        titleWrapper: {
          flexDirection: 'column',
          marginTop: 16,
        },
        textBoxStyle: {
          marginLeft: 0,
          marginTop: 8,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isMedLg
    ? ({
        skeletonDetailWrapper: {
          flex: 1,
          paddingLeft: 32,
        },
        titleWrapper: {},
        textBoxStyle: {
          marginTop: 'auto',
        },
      } as StyleProp<Styles>)
    : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
