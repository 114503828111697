import * as React from 'react';
import { StyleProp, TextStyle, View, ViewStyle } from 'react-native';
import { Button, IconButton, useTheme } from 'react-native-paper';

import styles from './messageDisplay.styles';
import { TextContent } from '../textcontent/index';

export enum Variant {
  Error = 'error',
  Warning = 'warning',
  Success = 'success',
  Generic = 'generic',
}

export interface MessageDisplayProp {
  btnText?: string;
  displayMessage: string | React.ReactNode;
  onClick?: () => void;
  displayIcon?: React.ReactNode;
  wrapperStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  btnStyle?: StyleProp<ViewStyle>;
  ctaBtnColor?: string;
  variant: Variant;
  btnLabelStyle?: StyleProp<TextStyle>;
  iconSize?: number;
  renderDisplayMessage?: () => JSX.Element;
}

function getVariantStyle(variant: Variant, messageTheme: ReactNativePaper.Theme['messages']) {
  const variants = {
    [Variant.Error]: {
      container: {
        backgroundColor: messageTheme.error.backgroundColor,
      },
      icon: {
        name: messageTheme.error.icon,
      },
    },
    [Variant.Warning]: {
      container: {
        backgroundColor: messageTheme.warning.backgroundColor,
      },
      icon: {
        name: messageTheme.warning.icon,
      },
    },
    [Variant.Generic]: {
      container: {
        backgroundColor: messageTheme.generic.backgroundColor,
      },
      icon: {
        name: messageTheme.generic.icon,
      },
    },
    [Variant.Success]: {
      container: {
        backgroundColor: messageTheme.success.backgroundColor,
      },
      icon: {
        name: messageTheme.success.icon,
      },
    },
  };

  return variants[variant];
}

export function MessageDisplay({
  btnText,
  displayMessage,
  onClick,
  displayIcon = undefined,
  wrapperStyle,
  textStyle,
  btnStyle,
  variant,
  ctaBtnColor,
  btnLabelStyle,
  renderDisplayMessage,
  iconSize = 22,
}: MessageDisplayProp) {
  const { messages: messageTheme } = useTheme();
  const variantStyle = getVariantStyle(variant, messageTheme);
  const messagePositionStyle = btnText ? styles.messageButton : {};
  const btnColor = ctaBtnColor || (variantStyle?.container?.backgroundColor ?? messageTheme.generic.backgroundColor);
  const content =
    typeof displayIcon === 'string' ? (
      <IconButton style={styles.displayIcon} icon={displayIcon} color="#fff" size={iconSize} />
    ) : (
      displayIcon
    );

  if (variant === Variant.Generic) {
    return (
      <>
        <View style={[styles.toRow, wrapperStyle, messagePositionStyle]}>
          {content}
          {renderDisplayMessage ? (
            renderDisplayMessage()
          ) : (
            <TextContent style={[styles.wrapText, textStyle]}>{displayMessage}</TextContent>
          )}
        </View>
        {btnText && (
          <Button
            color={btnColor}
            style={[styles.topSpace, btnStyle]}
            mode="contained"
            onPress={onClick}
            labelStyle={btnLabelStyle}
          >
            {btnText}
          </Button>
        )}
      </>
    );
  }

  if (!variantStyle) {
    return null;
  }

  const { container, icon } = variantStyle;

  return (
    <>
      <View style={[styles.toRow, styles.messageContainer, container, wrapperStyle, messagePositionStyle]}>
        {content || <IconButton style={styles.displayIcon} icon={icon.name} color="#fff" />}
        {renderDisplayMessage ? (
          renderDisplayMessage()
        ) : (
          <TextContent accessible={false} style={[styles.wrapText, styles.txtMess, textStyle]}>
            {displayMessage}
          </TextContent>
        )}
      </View>
      {btnText && (
        <Button
          color={btnColor}
          style={[styles.topSpace, btnStyle]}
          mode="contained"
          onPress={onClick}
          labelStyle={[btnLabelStyle]}
        >
          {btnText}
        </Button>
      )}
    </>
  );
}

export default React.memo(MessageDisplay);
