import { StyleSheet } from 'react-native';
import { Sizes } from '@warnermmedia/gsp-core/brands/estadio/feature';

export const getStyles = (
  navMobile: boolean,
  isLogged: boolean,
  isWeb: boolean,
  isVideoPage = false,
  isUserEmailVerified: boolean,
  renderNav: boolean,
  isEmailRoute: boolean,
  colors: ReactNativePaper.ThemeColors,
  isMobileDevice: boolean
) => {
  const styles = StyleSheet.create({
    rootContainer: {
      width: '100%',
      height: '100%',
      overflow: 'hidden',
    },
    container: {
      flexGrow: 1,
    },
    layoutContainer: {
      minHeight: isWeb ? '100vh' : 0,
    },
    content: {
      paddingLeft: !navMobile && isLogged && renderNav && isUserEmailVerified && !isEmailRoute ? 110 : 0,
      flexGrow: 1,
      backgroundColor: colors.tenantBackground.dark.surfaceBase,
    },
    header: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: corretion for web
      position: !isWeb ? 'absolute' : 'fixed',
      top: 0,
      zIndex: 999999,
      width: '100%',
    },
    tickerContainer: {
      paddingLeft: !navMobile && isLogged && isUserEmailVerified && !isEmailRoute ? 110 : 0,
      paddingTop: (!navMobile && isWeb) || isMobileDevice ? 0 : 50,
    },
    topOffLimits: {
      flex: 0,
    },
    bottomOffLimits: {
      flex: 1,
    },
    footerContainer: {
      backgroundColor: colors.keyboardFocus.dark,
      width: '100%',
    },
  });

  return styles;
};
