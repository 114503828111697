import { TveProvider } from '../types';

export enum AuthState {
  Authenticated = 'authenticated',
  Unauthenticated = 'unauthenticated',
}

export enum SubscriberType {
  D2C = 'D2C',
  TVE = 'TVE',
  B2B = 'B2B',
}

export enum SubscriptionStatus {
  Active = 'active',
  Inactive = 'inactive',
}

export enum CouponType {
  Free = 'Free subscription Coupon Redeem',
  Discount = 'Discount Coupon Redeem',
}

export enum MParticleCustomEventTypes {
  RegistrationStartEvent = 'registrationstart_event',
  RegistrationCompleteEvent = 'registrationcomplete_event',
  RegistrationErrorEvent = 'registrationerror_event',
  LoginStartEvent = 'loginstart_event',
  LoginCompleteEvent = 'logincomplete_event',
  LoginErrorEvent = 'loginerror_event',
  FavoriteTeamSelectEvent = 'favoriteteamselect_event',
  FavoriteTeamPostponeEvent = 'favoriteteampostpone_event',
  ChangePaymentStartEvent = 'changepaymentstart_event',
  SubscriptionStartEvent = 'subscriptionstart_event',
  SubscriptionSuccessEvent = 'subscriptionsuccess_event',
  SubscriptionFailEvent = 'subscriptionfail_event',
  RedeemCouponEvent = 'redeemcoupon_event',
  SubscriptionUpdateEvent = 'subscriptionupdate_event',
  ReceiptDownloadEvent = 'receiptdownload_event',
  CancellationStartEvent = 'cancellationstart_event',
  CancellationCompleteEvent = 'cancellationcomplete_event',
  RetentionOfferAcceptEvent = 'retentionofferaccept_event',
  RetentionOfferDeclineEvent = 'retentionofferdecline_event',
  PageNameChangeEvent = 'pagenamechange_event',
  EmailValidationStartEvent = 'validationstart_event',
  EmailValidationCompleteEvent = 'validationcomplete_event',
  GeoblockEvent = 'geoblock_event',
  ConcurrencyLimitEvent = 'concurrencylimit_event',
  AccountRecoveryStartEvent = 'accountrecoverystart_event',
  AccountRecoveryCompleteEvent = 'accountrecoverycomplete_event',
  RetentionOfferDismissEvent = 'retentionofferdismiss_event',
  CancellationReasonDismissEvent = 'cancellationreasondismiss_event',
  CancellationReasonSelectedEvent = 'cancellationreasonselected_event',
  CancellationDeclineEvent = 'cancellationdecline_event',
  AccountUpdateEvent = 'accountupdate_event',
  PasswordUpdateEvent = 'passwordupdate_event',
  PlanSelectNameEvent = 'planselectname_event',
  CodeGenerationStarEvent = 'codegenerationstart_event',
  CodeGenerationCompleteEvent = 'codegenerationcomplete_event',
  CodeGenerationErrorEvent = 'codegenerationerror_event',
  CodeValidationStarEvent = 'codevalidationstart_event',
  CodeValidationCompleteEvent = 'codevalidationcomplete_event',
  CodeValidationErrorEvent = 'codevalidationerror_event',
  CodePairingStarEvent = 'codepairingstart_event',
  CodePairingCompleteEvent = 'codepairingcomplete_event',
  CodePairingErrorEvent = 'codepairingerror_event',
  PairSuccessEvent = 'pairsuccess_event',
  CodeRefreshEvent = 'coderefresh_event',
  PlayerFetchDataCompleteEvent = 'playerfetchdatacomplete_event',
  PlayerLoadEvent = 'playerload_event',
  PlayerReadyEvent = 'playerready_event',
  MediaResponseEvent = 'mediaresponse_event',
  VideoStartEvent = 'videostart_event',
}

export interface MParticleAppConfigParams {
  appName?: string;
  version?: string;
  pageName?: string;
  section?: string;
  subsection?: string;
  contentTitle?: string;
  publicationDate?: string;
  contentId?: string;
  keywords?: string[];
  brand?: string;
  authState?: AuthState;
  subscriberType?: SubscriberType;
  subscriptionStatus?: SubscriptionStatus;
  favoriteTeam?: string;
  homeTeam?: string;
  awayTeam?: string;
  marketRegion?: string;
  market?: string;
  customerId?: string;
  errorCode?: string;
  serviceProvider?: TveProvider;
  cancelReason?: string;
  redirectTiming?: string;
  typeCoupon?: CouponType;
  subscriptionType?: string;
  devicePaired?: string;
  playerTimer?: string;
  cid?: string;
}

export interface MParticleAppConfigObject {
  app_name: string;
  version: string;
  page_name: string;
  section: string;
  subsection: string;
  content_title: string;
  publication_date: string;
  content_id: string;
  keywords: string;
  brand: string;
  auth_state: AuthState;
  subscriber_type: SubscriberType;
  subscription_status: SubscriptionStatus;
  favorite_team: string;
  home_team?: string;
  away_team?: string;
  market_region: string;
  market: string;
  customer_id: string;
  error_code?: string;
  service_provider: string;
  cancel_reason?: string;
  redirect_timing?: string;
  type_coupon?: CouponType;
  subscription_type?: string;
  device_paired?: string;
  player_timer?: string;
  cid?: string;
}

export interface ProviderLoginConfigObject {
  auth_state?: AuthState;
  service_provider: string;
  error_code?: string;
}
