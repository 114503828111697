import React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';

/* eslint-disable-next-line */
export interface CreditCardProps {
  width?: number;
  height?: number;
  color?: string;
  iconColor?: string;
  detailColor?: string;
}

export function CreditCard(props: CreditCardProps) {
  const width = props.width || 20;
  const height = props.height || 20;
  const color = props.color || '#359fd7';
  const iconColor = props.iconColor || '#fff';
  const detailColor = props.detailColor || '#D2D2D2';

  return (
    <Svg fill="none" height={height} viewBox="0 0 20 20" width={width}>
      <Circle cx="10" cy="10" r="10" fill={color} />
      <Path fill={detailColor} d="M5 8H15V12H5z" />
      <Path
        fill={iconColor}
        d="M5 9.725v2.588c0 .745.604 1.35 1.35 1.35h7.3c.746 0 1.35-.605 1.35-1.35V9.725c0-.13-.105-.235-.235-.235h-9.53c-.13 0-.235.105-.235.235zm2.497 1.643H6.87c-.227 0-.41-.184-.41-.411 0-.227.183-.41.41-.41h.626c.227 0 .41.183.41.41 0 .227-.183.41-.41.41zM15 8.434V8.35C15 7.604 14.396 7 13.65 7h-7.3C5.603 7 5 7.604 5 8.35v.084c0 .13.105.235.235.235h9.53c.13 0 .235-.105.235-.235z"
      />
    </Svg>
  );
}

export default CreditCard;
