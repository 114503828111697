import React, { memo, useCallback, useRef } from 'react';
import { Pressable, View } from 'react-native';
import { CardProps, useSpatialNavigation } from '@warnermmedia/gsp-core/sdk/ui';
import MatchCard from '../match/matchCard';
import GenericCard from '../generic/genericCard';
import VideoCard from '../video/videoCard';
import FeaturedCard from '../featuredCard/featuredCard';
import { getStyles } from './baseCard.styles';
import DpgHeader from '../dpgHeader/dpgHeader';
import { carouselBase, getUniqueId } from '@warnermmedia/gsp-core/brands/estadio/feature';

declare module 'react-native' {
  interface PressableStateCallbackType {
    hovered?: boolean;
    focused?: boolean;
  }

  interface PressableProps {
    delayPressIn?: number;
  }
}

const BaseCard = ({
  item,
  index,
  currentIndex,
  laneWidth,
  action,
  type,
  separators,
  cardBg,
  device,
  colors,
  breakpoints,
  dataLength,
  updateIndex,
  focusKey,
  parentFocusKey,
}: CardProps<carouselBase>) => {
  const styles = getStyles(breakpoints, device.isTv);
  const largeScreenSize =
    device.isWeb &&
    (breakpoints.currentBreakpoints.isMedLg ||
      breakpoints.currentBreakpoints.isLgXl ||
      breakpoints.currentBreakpoints.isXlXxl);
  const mediumScreenSize =
    device.isWeb && (breakpoints.currentBreakpoints.isSmMed || breakpoints.currentBreakpoints.isTnySm);
  const shouldDisplayDpgHeader = type === 'dpg' || type === 'epg';
  const cardFocusKey = useRef(focusKey ?? getUniqueId(item.type.toUpperCase())).current;
  const { ref, onCarouselCardArrowPress } = useSpatialNavigation({
    focusKey: cardFocusKey,
    onFocus: () => {
      if (item.type === 'match' && device.isWeb && !shouldDisplayDpgHeader) {
        updateIndex?.(index);
      }
    },
    onEnterPress: () => {
      action?.(item);
    },
    onArrowPress: (direction, props, details): boolean => {
      return onCarouselCardArrowPress(direction, props, details, index, parentFocusKey, dataLength);
    },
  });

  const transformValue = useCallback(() => {
    return largeScreenSize
      ? {
          transform: [{ scale: 1.1 }],
        }
      : mediumScreenSize
      ? {
          transform: [{ scale: 1.05 }],
        }
      : {};
  }, [largeScreenSize, mediumScreenSize]);

  const baseParams = useCallback(() => {
    return {
      currentIndex: currentIndex,
      laneWidth: laneWidth,
      action: action,
      item: item,
      index: index,
      separators: separators,
      type,
      cardBg,
      colors,
      device,
      breakpoints,
    } as CardProps<carouselBase>;
  }, [currentIndex, laneWidth, action, index, item, type, separators, cardBg, colors, device, breakpoints]);

  const cardType = useCallback((item: carouselBase, baseParams: CardProps<carouselBase>) => {
    return ['video', 'manual', 'epg'].includes(item.type) ? (
      <VideoCard {...baseParams} />
    ) : item.type === 'match' ? (
      <MatchCard {...baseParams} />
    ) : item.type === 'item' ? (
      <FeaturedCard {...baseParams} />
    ) : (
      <GenericCard {...baseParams} />
    );
  }, []);

  const handlePress = useCallback(() => {
    return action ? action(item) : null;
  }, [action, item]);

  const getCardMarginTop = useCallback(
    (type: string | undefined) => {
      if (shouldDisplayDpgHeader && device.isTv) {
        return 4;
      } else if (shouldDisplayDpgHeader && !device.isTv) {
        return 0;
      } else if (!shouldDisplayDpgHeader && !device.isTv) {
        return 4;
      } else return 9;
    },
    [device.isTv, shouldDisplayDpgHeader]
  );

  const getCardFocusedMargin = useCallback(
    (focused = false, hovered = false) => {
      return focused && hovered && (largeScreenSize || mediumScreenSize)
        ? item.type === 'item'
          ? { marginHorizontal: 8, marginVertical: 18 }
          : { marginHorizontal: 8 }
        : {};
    },
    [item.type, largeScreenSize, mediumScreenSize]
  );

  return (
    <Pressable
      onPress={handlePress}
      nativeID={cardFocusKey}
      ref={ref}
      style={({ focused, hovered }) => [
        styles.slideLayout,
        hovered ? transformValue() : {},
        getCardFocusedMargin(focused, hovered),
      ]}
    >
      <View style={[item.type === 'item' ? styles.featuredSlide : styles.slide, { marginTop: getCardMarginTop(type) }]}>
        {shouldDisplayDpgHeader && (
          <DpgHeader
            currentIndex={currentIndex}
            laneWidth={laneWidth}
            action={action}
            item={item}
            index={index}
            separators={separators}
            device={device}
            colors={colors}
            breakpoints={breakpoints}
          />
        )}
        <View style={styles.content}>{cardType(item, baseParams())}</View>
      </View>
    </Pressable>
  );
};

export default memo(BaseCard);
