import React from 'react';
import { Animated, ScrollView, View } from 'react-native';
import { getStyles } from './pipCard.styles';
import { Breakpoints, DeviceTypes } from '@warnermmedia/gsp-core/sdk/ui';

export interface PipCardProps {
  currentIndex: number;
  reducedItemWidth: number;
  scrollX: Animated.Value;
  dataLength: number;
  mobileWebPipCardWidth: number;
  breakpoints: Breakpoints;
  device: DeviceTypes;
  colors: ReactNativePaper.ThemeColors;
}

export const PipCard = ({
  currentIndex,
  dataLength,
  reducedItemWidth,
  scrollX,
  device,
  mobileWebPipCardWidth,
  breakpoints,
  colors,
}: PipCardProps) => {
  const styles = getStyles(breakpoints, colors);
  const slices = new Array(dataLength).fill('');
  const isMobileScreen = breakpoints.breakpointHelpers.isMobileScreen;

  return device.isMobileDevice ? (
    <ScrollView>
      <View style={styles.pipMobileStyle}>
        {slices.map((_, index) => {
          const inputRange = [(index - 1) * reducedItemWidth, index * reducedItemWidth, (index + 1) * reducedItemWidth];
          const pipColor = scrollX.interpolate({
            inputRange,
            outputRange: [
              colors.tenantBackground.light.surfaceBase,
              colors.fill.action.accent02,
              colors.tenantBackground.light.surfaceBase,
            ],
            extrapolate: 'clamp',
          });
          return <Animated.ScrollView key={index} style={[styles.pipSlide, { backgroundColor: pipColor }]} />;
        })}
      </View>
    </ScrollView>
  ) : (
    <>
      {slices.map((_, index: number) => (
        <View
          key={index}
          style={[
            styles.pipSlide,
            index === currentIndex && styles.pipSlideActive,
            isMobileScreen && { width: mobileWebPipCardWidth },
          ]}
        >
          <View style={styles.pipSlideInner} />
        </View>
      ))}
    </>
  );
};

export default PipCard;
