import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  webFixWrapper: {
    top: -10000,
    position: 'absolute',
  },
  label: {
    fontSize: 0,
    position: 'absolute',
    color: 'transparent',
  },

  font1: {
    fontFamily: 'Rubik-Regular',
  },
  font2: {
    fontFamily: 'Rubik-Medium',
  },
  font3: {
    fontFamily: 'Oswald-Regular',
  },
  font4: {
    fontFamily: 'Oswald-SemiBold',
  },
  font5: {
    fontFamily: 'Oswald-Bold',
  },
  font6: {
    fontFamily: 'Oswald-Medium',
  },
  font7: {
    fontFamily: 'Rubik-Bold',
  },
  font8: {
    fontFamily: 'Rubik-SemiBold',
  },
});
