import merge from 'lodash/merge';
import { StyleProp, StyleSheet, TextStyle, ViewStyle } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';

interface Styles {
  howToWrapper: ViewStyle;
  howToHeaderContainer: ViewStyle;
  howToHeader: TextStyle;
  tutorialContainer: ViewStyle;
  contentsContainer: ViewStyle;
  tutorialNumberContainer: ViewStyle;
  tutorialContents: ViewStyle;
  tutorialNumber: TextStyle;
  tutorialHeader: TextStyle;
  tutorialDescription: TextStyle;
}

export const getStyles = (breakpoints: Breakpoints, colors: ReactNativePaper.ThemeColors) => {
  const base: StyleProp<Styles> = {
    howToWrapper: {
      flexDirection: 'column',
      justifyContent: 'center',
      paddingBottom: 80,
      paddingHorizontal: 25,
    },
    howToHeaderContainer: {},
    howToHeader: {
      fontFamily: 'Oswald-Regular',
      fontSize: 33,
      textTransform: 'uppercase',
      lineHeight: 44,
      fontWeight: '500',
      color: colors.keyboardFocus.light,
      marginBottom: 40,
    },
    tutorialContainer: {
      flexDirection: 'column',
    },
    contentsContainer: {
      flexDirection: 'row',
      marginBottom: 16,
    },
    tutorialNumberContainer: {
      width: 50,
      height: 50,
      borderRadius: 50,
      backgroundColor: colors.fill.action.accent02,
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 16,
      marginRight: 12,
    },
    tutorialNumber: {
      fontFamily: 'Rubik-bold',
      fontSize: 16,
      lineHeight: 21,
      fontWeight: '700',
      color: colors.fill.action.accent04,
    },
    tutorialContents: {
      flexDirection: 'column',
      width: '85%',
    },
    tutorialHeader: {
      fontFamily: 'Rubik-bold',
      fontSize: 16,
      lineHeight: 21,
      fontWeight: '500',
      color: colors.keyboardFocus.light,
      marginBottom: 8,
    },
    tutorialDescription: {
      fontFamily: 'Rubik-Regular',
      fontSize: 16,
      lineHeight: 20,
      fontWeight: '400',
      color: colors.keyboardFocus.light,
    },
  };

  const mediaQueries =
    breakpoints.currentBreakpoints.isMedLg ||
    breakpoints.windowWidth === breakpoints.breakpointSizes.lg ||
    breakpoints.currentBreakpoints.isLgXl ||
    breakpoints.currentBreakpoints.isXlXxl ||
    breakpoints.currentBreakpoints.isXxl
      ? ({
          howToWrapper: {
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: '100%',
          },
          howToHeaderContainer: {
            width: '100%',
            maxWidth: 1028,
          },
          howToHeader: {
            alignItems: 'flex-start',
          },
          tutorialContainer: {
            flexDirection: 'row',
            alignContent: 'flex-start',
            justifyContent: 'center',
            width: '100%',
            maxWidth: 1028,
            paddingBottom: 40,
          },
          contentsContainer: {
            flexDirection: 'column',
            flex: 1,
          },
        } as StyleProp<Styles>)
      : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
