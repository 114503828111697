import React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';

/* eslint-disable-next-line */
export interface TeamProps {
  width?: number;
  height?: number;
  color?: string;
  iconColor?: string;
  detailColor?: string;
}

export function Team(props: TeamProps) {
  const width = props.width || 20;
  const height = props.height || 20;
  const color = props.color || '#359fd7';
  const iconColor = props.iconColor || '#fff';

  return (
    <Svg x="0px" y="0px" viewBox="0 0 50 50" fill="none" height={height} width={width}>
      <Circle fill={color} cx="25" cy="25" r="25" />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={iconColor}
        d="M35.001,18.954c0-0.752,0-1.253,0-2.005 c0-0.501-0.25-1.003-0.75-1.003c-3.75,0-6.5-1.003-8.75-3.258c-0.25-0.251-0.75-0.251-1,0c-2.25,2.256-5,3.258-8.5,3.258 c-0.5,0-1,0.501-1,1.003c0,0.752,0,1.253,0,2.005c0,6.266-0.25,15.038,9.75,18.546h0.25h0.25 C35.251,33.741,35.001,25.219,35.001,18.954z"
      />
    </Svg>
  );
}

export default Team;
