import { useCallback, useEffect, useRef, useState } from 'react';
import { MODAL_INITIAL_STATE, modalStateStore } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { loadData, ROUTE_FOCUS_KEY } from '@warnermmedia/gsp-core/sdk/data-access';
import { useFocusable } from '@noriginmedia/norigin-spatial-navigation';

export const useWebos = (device: string) => {
  const isWebos = device === 'webos';
  const [magicRemoteVisible, setMagicRemoteVisible] = useState<boolean>(false);
  const keycode = useRef();
  const { setFocus } = useFocusable();

  const onCursorStateChange = (event: CustomEventInit) => {
    // Handle cursorStateChange event for webOs magic remote cursor detection
    setMagicRemoteVisible(!!event.detail.visibility);
  };

  const onScrollHandler = useCallback((event: WheelEvent) => {
    // Handle wheel event for webOs magic remote scroll
    Math.sign(event.deltaY) > 0 ? scrollDown() : scrollUp();
  }, []);

  const scrollUp = () => {
    window.scrollBy(0, -100);
  };

  const scrollDown = () => {
    window.scrollBy(0, 100);
  };

  /* A callback function that is called when the back button is pressed on the remote. */
  const handleTvKeyDown = useCallback((e) => {
    if (window.event) {
      keycode.current = e?.keyCode;
    } else if (e.which) {
      keycode.current = e.which;
    }
    if (keycode.current === 461) {
      modalStateStore(MODAL_INITIAL_STATE);
    }
  }, []);

  /* A callback function for click events */
  const handleTvClick = useCallback(
    (e: MouseEvent) => {
      if (!(e?.target as HTMLElement)?.onclick) {
        e.preventDefault();
        setFocus(loadData(ROUTE_FOCUS_KEY));
      }
    },
    [setFocus]
  );

  useEffect(() => {
    if (isWebos) {
      window.addEventListener('keydown', handleTvKeyDown);
      document.addEventListener('wheel', onScrollHandler);
      document.addEventListener('cursorStateChange', onCursorStateChange);
    }
    return () => {
      if (isWebos) {
        window.removeEventListener('keydown', handleTvKeyDown);
        document.removeEventListener('wheel', onScrollHandler);
        document.removeEventListener('cursorStateChange', onCursorStateChange);
      }
    };
  }, [isWebos]);

  useEffect(() => {
    if (isWebos) {
      if (magicRemoteVisible) {
        document.addEventListener('click', handleTvClick);
      } else {
        document.removeEventListener('click', handleTvClick);
      }
    }
    return () => {
      if (isWebos) {
        document.removeEventListener('click', handleTvClick);
      }
    };
  }, [magicRemoteVisible]);
  return magicRemoteVisible;
};
