import merge from 'lodash/merge';
import { ImageStyle, StyleProp, StyleSheet, TextStyle, ViewStyle } from 'react-native';

export interface RegisterAccountFormStyles {
  formLabel?: TextStyle;
  inputStyle?: TextStyle;
  inputStyleFilled?: TextStyle;
  submitButtonLabelStyle?: TextStyle;
  checkboxStyle?: ViewStyle;
  submitButtonViewStyle?: ViewStyle;
  captcha?: ViewStyle;
  formAcceptViewStyle?: ViewStyle;
  formAcceptTextStyle?: TextStyle;
  formErrorViewStyle?: ViewStyle;
  formErrorTextStyle?: TextStyle;
  errorsContainer?: ViewStyle;
  titleContainer?: ViewStyle;
  errorsViewStyle?: ViewStyle;
  apiErrorViewStyle?: ViewStyle;
  errorNotIconStyle?: ViewStyle;
  errorsBulletTextStyle?: TextStyle;
  errorsTextTextStyle?: TextStyle;
  concentMessageContainer?: ViewStyle;
  consentMessageStyle?: TextStyle;
  formTitleTextStyle?: TextStyle;
  formDirectionsTextStyle?: TextStyle;
  iconStyle?: ImageStyle;
}

export const getStyles = (styles?: RegisterAccountFormStyles) => {
  const base: StyleProp<RegisterAccountFormStyles> = {
    formLabel: {
      color: '#000',
      paddingTop: 16,
    },
    inputStyle: {
      borderColor: '#FFFFFF',
      backgroundColor: '#FFFFFF',
      marginTop: 5,
      opacity: 0.8,
    },
    inputStyleFilled: {
      borderColor: '#FFFFFF',
      backgroundColor: '#FFFFFF',
      marginTop: 5,
      opacity: 1,
    },
    submitButtonLabelStyle: {
      textTransform: 'uppercase',
    },
    checkboxStyle: {
      borderColor: '#FFFFFF',
    },
    submitButtonViewStyle: {
      marginTop: 16,
      borderWidth: 0,
    },
    captcha: {
      paddingTop: 16,
    },
    formAcceptViewStyle: {
      alignItems: 'center',
      flexDirection: 'row',
      paddingTop: 16,
    },
    formAcceptTextStyle: {
      color: '#000',
    },
    formErrorViewStyle: {},
    formErrorTextStyle: {},
    errorsContainer: {
      alignItems: 'flex-start',
      backgroundColor: '#b00020',
      flexDirection: 'row',
    },
    titleContainer: {
      marginTop: 16,
    },
    errorsViewStyle: {
      alignItems: 'flex-start',
      flexDirection: 'row',
      width: '90%',
    },
    apiErrorViewStyle: {
      alignItems: 'center',
    },
    errorNotIconStyle: {
      flexShrink: 1,
      marginLeft: 8,
    },
    errorsBulletTextStyle: {
      color: '#fff',
      paddingLeft: 8,
      paddingRight: 4,
    },
    errorsTextTextStyle: {
      color: '#fff',
      flexShrink: 1,
    },
    concentMessageContainer: {
      paddingTop: 24,
      paddingBottom: 8,
    },
    iconStyle: {
      marginTop: 8,
    },
  };
  const mergedStyles = merge(base, styles);
  return StyleSheet.create(mergedStyles);
};

export default StyleSheet.create({});
