import React from 'react';
import { Page } from '@warnermmedia/gsp-core/brands/estadio/data-access';

export const useCMSPageMetaTags = (data: Page | undefined) => {
  const metaTags = data?.metatags?.map((metatag, index) => ({
    key: index,
    [metatag?.type ?? 'name']: metatag?.key,
    content: metatag?.content,
  }));
  const titleObj = data && data?.metatags && data?.metatags.find((el) => el?.key?.toLowerCase() === 'title');
  const otherTitle = (titleObj && titleObj.content) || '';
  const screenComponent = (data?.components || []).find((item) => item && item?.type === 'screen');
  const title = data?.headline || screenComponent?.headline || otherTitle;
  const type = data?.type ?? '';
  const id = data?._id ?? '';

  return {
    metaTags,
    title,
    type,
    cmsId: id,
  };
};

export default useCMSPageMetaTags;
