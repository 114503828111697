import React, { memo, useCallback, useMemo } from 'react';
import { CardProps, TextContent, widthHeight } from '@warnermmedia/gsp-core/sdk/ui';
import { Team } from '../../team/team';
import { getCardSettings, SlideTop } from '../atoms/cardsAtoms';
import { ImageBackground, View } from 'react-native';
import { getStyles } from './matchCard.styles';
import {
  CardDefaultBackground,
  carouselBase,
  getImageWithStyle,
  useCardItemImage,
} from '@warnermmedia/gsp-core/brands/estadio/feature';

const MatchCard = ({
  item,
  index,
  currentIndex,
  type,
  cardBg,
  breakpoints,
  device,
  colors,
}: CardProps<carouselBase>) => {
  const cardSettings = getCardSettings(colors);
  const styles = getStyles(breakpoints, device.isTv, colors);
  const { getCardImageSource } = useCardItemImage();
  const itemImage = item?.image ? item?.image : null;
  const matchImage = getCardImageSource({
    fallbackName: cardBg ? cardBg : CardDefaultBackground.Match,
    image: itemImage && type === 'dpg' ? getImageWithStyle(itemImage) : null,
  });
  const teamLogoSize: widthHeight = useMemo(
    () =>
      device.isTv
        ? {
            width: 120,
            height: 120,
          }
        : breakpoints.currentBreakpoints.isMedLg ||
          breakpoints.currentBreakpoints.isLgXl ||
          breakpoints.windowWidth === breakpoints.breakpointSizes.lg
        ? {
            width: 65,
            height: 65,
          }
        : breakpoints.currentBreakpoints.isXlXxl || breakpoints.currentBreakpoints.isXxl
        ? {
            width: 71,
            height: 71,
          }
        : {
            width: 80,
            height: 80,
          },
    [
      device.isTv,
      breakpoints.currentBreakpoints.isMedLg,
      breakpoints.currentBreakpoints.isLgXl,
      breakpoints.currentBreakpoints.isXlXxl,
      breakpoints.currentBreakpoints.isXxl,
      breakpoints.breakpointSizes.lg,
      breakpoints.windowWidth,
    ]
  );

  const getMatchMiddle = useCallback(
    (item: carouselBase) => {
      if (item.match?.homeTeam && item.match?.awayTeam) {
        let score,
          penalty = <View />;
        if (item.match?.gameStatus === 4) {
          if (typeof item.match.homeTeam.score !== 'undefined' && typeof item.match.awayTeam.score !== 'undefined') {
            score = (
              <TextContent style={styles.matchScore}>
                {item.match.homeTeam.score} - {item.match.awayTeam.score}
              </TextContent>
            );
          }
        } else {
          score = <TextContent style={styles.matchVs}>VS</TextContent>;
        }
        if (typeof item.match.homeTeam.penalty !== 'undefined' && typeof item.match.awayTeam.penalty !== 'undefined') {
          penalty = (
            <TextContent style={styles.matchPenalty}>
              ({item.match.homeTeam.penalty}-{item.match.awayTeam.penalty})
            </TextContent>
          );
        }
        return (
          <View>
            {score}
            {penalty}
          </View>
        );
      }
      return <View />;
    },
    [styles.matchPenalty, styles.matchScore, styles.matchVs]
  );

  if (!item.match) {
    return <View />;
  }

  return (
    <View style={styles.matchCardWrapper}>
      <ImageBackground source={matchImage} style={styles.imageBackground} imageStyle={styles.imageBgStyle}>
        {item.match?.gameStatus === 4 || (type === 'dpg' && item.duration && <SlideTop right={item.duration} />)}
        {(!itemImage || type !== 'dpg') && (
          <View style={styles.slideTop}>
            <View style={styles.matchImages}>
              <Team
                team={item.match.homeTeam}
                width={teamLogoSize.width}
                height={teamLogoSize.height}
                hideTricode={true}
              />
              <View>{getMatchMiddle(item)}</View>
              <Team
                team={item.match.awayTeam}
                width={teamLogoSize.width}
                height={teamLogoSize.height}
                hideTricode={true}
              />
            </View>
          </View>
        )}
        <View style={styles.slideTopTitle}>
          {item.duration && (
            <View style={styles.clipDurationView}>
              <TextContent style={styles.clipDurationText}>{item.duration}</TextContent>
            </View>
          )}
        </View>
        {item.match?.gameStatus === 3 && type !== 'dpg' && (
          <>
            <SlideTop left={item.cardLabel} isLive={item.match?.gameStatus === 3} />
            <View style={styles.playIcon}>{cardSettings.playButton}</View>
          </>
        )}
      </ImageBackground>
      <View style={[styles.slideBottom, index === currentIndex && styles.slideBottomActive]}>
        <TextContent style={styles.matchup} numberOfLines={1} ellipsizeMode={'tail'}>
          {item.title}
        </TextContent>
        <View style={styles.textContainer}>
          {item.subtitle ? (
            <TextContent style={styles.round} numberOfLines={1} ellipsizeMode={'tail'}>
              {item.subtitle}
            </TextContent>
          ) : null}
          {item.heading ? (
            <TextContent style={styles.round} numberOfLines={1} ellipsizeMode={'tail'}>
              {item.heading}
            </TextContent>
          ) : null}
        </View>
      </View>
    </View>
  );
};

export default memo(MatchCard);
