import React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';

/* eslint-disable-next-line */
export interface CalendarProps {
  width?: number;
  height?: number;
  color?: string;
  iconColor?: string;
  opacity?: number;
}

export function Calendar(props: CalendarProps) {
  const width = props.width || 20;
  const height = props.height || 20;
  const color = props.color || '#359fd7';
  const iconColor = props.iconColor || '#fff';
  const opacity = props.opacity || 1;

  return (
    <Svg fill="none" height={height} viewBox="0 0 20 20" width={width}>
      <Circle cx="10" cy="10" r="10" fill={color} />
      <Path
        fill={iconColor}
        opacity={opacity}
        d="M13.892 5.052h-1.206V4.56c0-.309-.253-.561-.561-.561-.309 0-.561.252-.561.561v.49H8.45v-.49c0-.309-.252-.561-.56-.561-.31 0-.562.252-.562.561v.49H6.122C5.505 5.052 5 5.558 5 6.175V14c0 .617.505 1.122 1.122 1.122h7.756c.617 0 1.122-.505 1.122-1.122V6.174c.014-.617-.491-1.122-1.108-1.122zm-.014 8.934H6.122V9.273h7.756v4.713z"
      />
    </Svg>
  );
}

export default Calendar;
