import React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';

/* eslint-disable-next-line */
export interface PasswordShowProps {
  width?: number | string;
  height?: number | string;
}

export function PasswordShow(props: PasswordShowProps) {
  const width = props.width || 20;
  const height = props.height || 20;

  return (
    <Svg width={width} height={height} viewBox="0 0 20 20" fill="none">
      <Path
        d="M19 10C19 7.2 14.9706 3 10 3C5.02944 3 1 7.2 1 10"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <Circle cx="10" cy="10" r="3" stroke="black" strokeWidth="2" />
    </Svg>
  );
}

export default PasswordShow;
