import { ImageStyle, StyleProp, StyleSheet, TextStyle, ViewStyle } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';
import merge from 'lodash/merge';

interface Styles {
  imageBackground: ViewStyle;
  imageBgStyle: ImageStyle;
  slideTop: ViewStyle;
  episodeImageContain: ImageStyle;
  episodeImage: ImageStyle;
  playIcon?: ViewStyle;
  slideBottom: ViewStyle;
  slideBottomActive: ViewStyle;
  matchup: TextStyle;
  round: TextStyle;
  date: TextStyle;
  // add for non-dpg
  clipDurationText?: TextStyle;
  clipDurationView?: ViewStyle;
  clipLabelText?: TextStyle;
  clipLabelView?: ViewStyle;
  slideTopTitle: ViewStyle;
}

export const getStyles = (breakpoints: Breakpoints, isTv: boolean, colors: ReactNativePaper.ThemeColors) => {
  const base: StyleProp<Styles> = {
    imageBackground: {
      height: 152,
      width: 270,
    },
    imageBgStyle: {
      borderRadius: 5,
    },
    slideTop: {
      width: '100%',
      height: '100%',
    },
    episodeImageContain: {
      borderRadius: 5,
      height: '100%',
      width: '100%',
    },
    episodeImage: {
      height: '100%',
      borderRadius: 5,
    },
    playIcon: {
      alignItems: 'center',
      alignSelf: 'center',
      elevation: 10000,
      height: '100%',
      justifyContent: 'center',
      position: 'absolute',
      width: '100%',
      zIndex: 100,
    },
    slideBottom: {
      paddingTop: 15,
      marginLeft: 8,
      width: 253,
    },
    slideBottomActive: {},
    matchup: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Rubik-Medium',
      fontSize: 16,
      fontWeight: '500',
      lineHeight: 20,
    },
    round: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Rubik-Regular',
      fontSize: 14,
      fontWeight: 'normal',
      lineHeight: 20,
    },
    date: {
      fontSize: 14,
      fontFamily: 'Rubik-Regular',
      color: colors.keyboardFocus.light,
    },
    // add for non-dpg
    clipDurationText: {
      fontFamily: 'Rubik-Regular',
      color: colors.keyboardFocus.light,
      fontSize: 11,
    },
    clipDurationView: {
      alignSelf: 'flex-end',
      backgroundColor: colors.stroke.onLight.outline01,
      opacity: 0.8,
      height: 20,
      justifyContent: 'center',
      paddingHorizontal: 8,
    },
    clipLabelText: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Oswald-SemiBold',
      fontSize: 10,
      fontWeight: '600',
      paddingBottom: 2, // Oswald-SemiBold seems to have extra top padding
      textTransform: 'uppercase',
    },
    clipLabelView: {
      alignSelf: 'flex-start',
      backgroundColor: colors.fill.action.accent01,
      borderRadius: 2,
      height: 20,
      justifyContent: 'center',
      paddingHorizontal: 8,
    },
    slideTopTitle: {
      elevation: 10000,
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingHorizontal: 8,
      position: 'absolute',
      top: 8,
      width: '100%',
    },
  };

  const mediaQueries = isTv
    ? ({
        imageBackground: {
          height: 228,
          width: 405,
        },
        matchup: {
          color: colors.keyboardFocus.light,
          fontFamily: 'Rubik-Medium',
          fontSize: 20,
          fontWeight: '500',
          lineHeight: 28,
        },
        round: {
          fontSize: 16,
          lineHeight: 24,
        },
        date: {
          fontSize: 16,
          lineHeight: 24,
        },
        slideBottom: {
          width: 400,
          paddingRight: 20,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isMedLg ||
      breakpoints.currentBreakpoints.isLgXl ||
      breakpoints.windowWidth === breakpoints.breakpointSizes.lg
    ? ({
        imageBackground: {
          height: 124,
          width: 218,
        },
        slideBottom: {
          width: 202,
          paddingTop: 8,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isXlXxl || breakpoints.currentBreakpoints.isXxl
    ? ({
        imageBackground: {
          height: 135,
          width: 239,
        },
        matchup: {
          color: colors.keyboardFocus.light,
          fontFamily: 'Rubik-Medium',
          fontSize: 16,
          fontWeight: '500',
          lineHeight: 20,
        },
        round: {
          fontSize: 14,
          lineHeight: 20,
        },
        date: {
          fontSize: 14,
          lineHeight: 20,
        },
        slideBottom: {
          width: 223,
          paddingTop: 8,
        },
      } as StyleProp<Styles>)
    : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
