import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FooterSection, MetaTags, ResendVerificationEmailForm, useGetDevice } from '@warnermmedia/gsp-core/sdk/ui';
import {
  callbackNavigateExternal,
  Dalton,
  getContentTitle,
  languageStrings,
  ScreenEventType,
  useIsMountedRef,
  useLogout,
  useMparticleScreenEvent,
  useProfileData,
  useRouter,
} from '@warnermmedia/gsp-core/brands/estadio/feature';
import { LayoutType, LayoutWrapper, TitleType } from '@warnermmedia/gsp-core/brands/estadio/ui';
import {
  CALLED_USER_EMAIL_VALIDATION,
  clearData,
  EMAIL_VERIFICATION_STATUS,
  loadData,
  USER_EMAIL_STATUS,
  UserEmailResponsesEntity,
} from '@warnermmedia/gsp-core/sdk/data-access';
import {
  breakpointsStateStore,
  HistoryContext,
  isUserEmailVerified,
  PIN_CODE,
} from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { ViewStyle } from 'react-native';
import { useReactiveVar } from '@apollo/client';
import get from 'lodash/get';

export interface ResendVerificationEmailState {
  verificationMessage?: string;
  emailStatus?: string;
}

export interface AfterLoginRouteType {
  route: string;
  state?: ResendVerificationEmailState;
}

export interface ApiMessages {
  success: string;
  error: string;
}

export interface ResendVerificationEmailProps {
  title?: string;
  resendButtonText?: string;
  checkButtonText?: string;
  apiMessages?: ApiMessages;
  footerSection?: FooterSection[];
}

const initialFooterSection = [
  {
    id: 'help',
    message: languageStrings.default.emailVericationMessage,
    linkLabel: languageStrings.default.stayInTouchText,
    callback: () => callbackNavigateExternal('https://estadiocl.zendesk.com/hc/es'),
    messageTextStyle: {
      paddingRight: 8,
    },
    directionStyle: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  {
    id: 'logout',
    message: languageStrings.default.emailVericationLogoutMessage,
    linkLabel: languageStrings.default.emailVericationLogoutLabel,
    directionStyle: {
      alignItems: 'flex-start',
    },
  },
] as FooterSection[];

export const ResendVerificationEmail = ({
  title = languageStrings.default.emailVericationPageTitle,
  resendButtonText = languageStrings.default.emailVericationCtaButton,
  checkButtonText = languageStrings.default.emailVericationCheckButtonText,
  apiMessages = {
    success: languageStrings.default.emailVericationApiSuccess,
    error: languageStrings.default.emailVericationApiError,
  },
  footerSection = initialFooterSection,
}: ResendVerificationEmailProps) => {
  const { userData } = useProfileData();
  const { state } = useRouter().useLocation();
  const userEmailStatus = loadData(USER_EMAIL_STATUS);
  const emailStatus = userEmailStatus || (state as ResendVerificationEmailState)?.emailStatus;
  const verificationMessage =
    userEmailStatus && userEmailStatus === EMAIL_VERIFICATION_STATUS.CONFIRMED
      ? languageStrings.default.emailVericationSuccessRouteMessage
      : (state as ResendVerificationEmailState)?.verificationMessage;
  const [email, setEmail] = useState<string>('');
  const [apiSuccessResponseMessage, setApiSuccessResponseMessage] = useState<string>('');
  const [apiErrorResponseMessage, setApiErrorResponseMessage] = useState<string>('');
  const [pageTitle, setPageTitle] = useState<string>(title);
  const [buttonTitle, setButtonTitle] = useState<string>(resendButtonText);
  const [pageFooter, setPageFooter] = useState<FooterSection[]>([]);
  const [defaultMessages, setDefaultMessages] = useState<ApiMessages>({
    success: '',
    error: '',
  });
  const [defaultVerificationMessage, setVerificationMessage] = useState<string | undefined>('');
  const [isLoading, setIsLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [isEmailConfirmed, setIsEmailConfirmed] = useState(false);
  const [checkValidationMessage, setCheckValidationMessage] = useState('');
  const { isMobileDevice, isWeb } = useGetDevice();
  const historyContext = useContext(HistoryContext);
  const history = historyContext?.ready ? historyContext?.useHistory() : null;
  const dalton = Dalton();
  const isMountedRef = useIsMountedRef();
  const isEmailVerified = useReactiveVar(isUserEmailVerified);
  const isUserEmailConfirmed = isEmailVerified || emailStatus === EMAIL_VERIFICATION_STATUS.CONFIRMED;
  const [contentTitle, setContentTitle] = useState<string>(title);
  useMparticleScreenEvent(ScreenEventType.ResendVerificationEmail, contentTitle);
  const { logout } = useLogout();
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const isMobileScreen = breakpoints.breakpointHelpers.isMobileScreen;
  const pinValidationCode = loadData(PIN_CODE);

  const getPageFooter = (elem: FooterSection) => {
    const newFooter = pageFooter && [...pageFooter];
    newFooter[0] = elem;
    return newFooter;
  };

  const logoutFooterAction = useCallback(async () => {
    await logout();
    isWeb && history?.push('/register');
  }, [logout, history, isWeb]);

  const loadFooter = useCallback(() => {
    const newFooterSection = footerSection.map((section) => {
      if (section.id === 'logout') {
        section.callback = logoutFooterAction;
      }
      if (isMobileScreen && section.id === 'help') {
        section.directionStyle = {
          alignItems: 'flex-start',
        };
      } else if (!isMobileScreen && section.id === 'help') {
        section.directionStyle = {
          flexDirection: 'row',
          alignItems: 'center',
        };
      }
      return section;
    });
    if (footerSection && footerSection.length > 0 && !isUserEmailConfirmed) {
      setPageFooter(newFooterSection);
    }
  }, [setPageFooter, isMobileScreen]);

  useEffect(() => {
    const pageTitle = isUserEmailConfirmed
      ? languageStrings.default.emailVericationPageTitleSuccess
      : languageStrings.default.emailVericationPageTitle;
    const contentTitle = isUserEmailConfirmed
      ? languageStrings.default.emailVerificationSuccessContentTitle
      : languageStrings.default.checkEmailContentTitle;
    const buttonTitle = isUserEmailConfirmed ? languageStrings.default.continueText : resendButtonText;
    const directionStyle = (isMobileDevice
      ? { flexDirection: 'column' }
      : {
          flexDirection: 'row',
          alignItems: 'center',
          alignContent: 'center',
          justifyContent: 'space-evenly',
        }) as ViewStyle;
    const pageFooter = !isUserEmailConfirmed ? getPageFooter({ ...footerSection[0], ...{ directionStyle } }) : [];
    const newVerificationMessage = isUserEmailConfirmed
      ? languageStrings.default.emailVericationSuccessRouteMessage
      : verificationMessage;
    if (isMountedRef.current) {
      setPageTitle(pageTitle);
      setContentTitle(contentTitle);
      setButtonTitle(buttonTitle);
      setPageFooter(pageFooter);
      setVerificationMessage(newVerificationMessage);
      setIsEmailConfirmed(isUserEmailConfirmed);
      clearData(CALLED_USER_EMAIL_VALIDATION);
    }
  }, [emailStatus, isEmailVerified]);

  useEffect(() => {
    if (isMountedRef.current && userData) {
      setEmail(userData.email);
    }
  }, [userData, isMountedRef]);

  useEffect(() => {
    if (isMountedRef.current) {
      const basicMessage = `${languageStrings.default.emailVericationParagraph_1} ${email} \n\n${languageStrings.default.emailVerificationParagraph_2}`;
      const defaultMsg = !pinValidationCode
        ? basicMessage
        : `${basicMessage}\n\n${languageStrings.default.emailVerificationParagraphPinCode}`;
      if (verificationMessage) setVerificationMessage(verificationMessage);
      else {
        setVerificationMessage(defaultMsg);
      }
    }
  }, [email, isMountedRef, verificationMessage]);

  useEffect(() => {
    if (isMountedRef.current) {
      loadFooter();
    }
  }, [isMountedRef, loadFooter]);

  useEffect(() => {
    if ((apiMessages?.error || apiMessages?.success) && isMountedRef.current) {
      setDefaultMessages(apiMessages);
    }
  }, [apiMessages.error, apiMessages.success, isMountedRef.current]);

  const onSuccess = (): void => {
    const successPageFooter = {
      id: 'success',
      message: languageStrings.default.emailVericationSuccessMessage,
      linkLabel: languageStrings.default.stayInTouchText,
      callback: () => callbackNavigateExternal('https://estadiocl.zendesk.com/hc/es'),
      directionStyle: {
        flexDirection: 'column',
      } as ViewStyle,
    };
    const newFooter = getPageFooter(successPageFooter);
    setIsLoading(false);
    setEmailSent(true);
    setPageTitle(languageStrings.default.emailSentText);
    setButtonTitle(checkButtonText);
    setPageFooter(newFooter);
    setApiSuccessResponseMessage(defaultMessages && defaultMessages?.success);
  };

  const onError = (): void => {
    setIsLoading(false);
    setEmailSent(false);
    setApiErrorResponseMessage(defaultMessages && defaultMessages?.error);
  };

  const handleResendEmail = async (email: string) => {
    setIsLoading(true);
    setEmailSent(false);
    setApiSuccessResponseMessage('');
    setApiErrorResponseMessage('');
    setCheckValidationMessage('');
    await dalton.resendVerificationEmail(email, onSuccess, onError);
  };

  const userHasEmailVerified = async (): Promise<boolean> => {
    try {
      const profile = await dalton.getProfile();
      if (profile.success) {
        const primaryEmail: UserEmailResponsesEntity | undefined = get(profile, 'data.userEmailResponses', []).find(
          (email: UserEmailResponsesEntity) => email.primary === true
        );
        isUserEmailVerified(primaryEmail?.status === EMAIL_VERIFICATION_STATUS.CONFIRMED);
        dalton.updateUserEmailStatus(primaryEmail?.status ?? '');
        return primaryEmail?.status === EMAIL_VERIFICATION_STATUS.CONFIRMED;
      }
      return false;
    } catch (e) {
      return false;
    }
  };

  const userHasSubscription = async (): Promise<boolean> => {
    const billingProfile = await dalton.getBillingProfile();
    return !!get(billingProfile, 'data.preAuthorizedEntitlements', []).length;
  };

  const getRoute = (isVerified: boolean, hasSubscription: boolean): AfterLoginRouteType | null => {
    let route: AfterLoginRouteType | null = null;
    if (isVerified && !hasSubscription) {
      route = {
        route: '/resend-email',
        state: {
          verificationMessage: languageStrings.default.emailVericationSuccessRouteMessage,
          emailStatus: EMAIL_VERIFICATION_STATUS.CONFIRMED,
        },
      };
    } else if (isVerified && hasSubscription) {
      route = { route: '/home' };
    }
    return route;
  };

  const handleCheckValidation = async () => {
    setIsLoading(true);
    setCheckValidationMessage('');
    try {
      const hasSubscription = await userHasSubscription();
      const isEmailVerified = await userHasEmailVerified();
      const route = getRoute(isEmailVerified, hasSubscription);

      if (route) {
        setApiSuccessResponseMessage('');
        setApiErrorResponseMessage('');
        history?.push(route);
      } else {
        setCheckValidationMessage(languageStrings.default.emailCheckvalidationMessageSuccess);
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setCheckValidationMessage(languageStrings.default.emailCheckvalidationMessageError);
    }
  };

  const handleContinue = () => {
    history?.push('/subscribe');
  };

  const handleButtonAction = (email: string) => {
    if (isEmailConfirmed) {
      handleContinue();
    } else if (emailSent) {
      handleCheckValidation();
    } else {
      handleResendEmail(email);
    }
  };

  return (
    <LayoutWrapper layoutType={LayoutType.Center} titleType={TitleType.Left} pageTitle={pageTitle}>
      {isWeb && <MetaTags title={getContentTitle(pageTitle)} />}
      <ResendVerificationEmailForm
        email={email}
        buttonText={buttonTitle}
        buttonAction={handleButtonAction}
        isLoading={isLoading}
        requestSuccessfulMessage={apiSuccessResponseMessage}
        requestErrorMessage={apiErrorResponseMessage}
        verificationMessage={defaultVerificationMessage}
        checkValidationMessage={checkValidationMessage}
        footerSection={pageFooter}
        isUserEmailConfirmed={isUserEmailConfirmed}
      />
    </LayoutWrapper>
  );
};

export default ResendVerificationEmail;
