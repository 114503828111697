import { carouselBase, carouselCallToAction } from '../Interfaces';

export const mockCarouselHero = [
  {
    id: '611ec07790133b73bc7332d7',
    key: 1,
    type: 'match',
    title: 'Deportes Concepción V/S Wanderers',
    subtitle: 'Clasificatorias Qatar (solo en Chile)',
    heading: 'jun 14',
    callToAction: {
      text: 'Revívelo',
      icon: 'calendar',
      action: 'https://google.com',
    } as carouselCallToAction,
    match: {
      homeTeam: {
        name: 'Colo-Colo',
        tricode: 'COCO',
        score: 2,
        logo: 'https://images.firesport.io/cdf/team/2641/image.png?width=400&height=400',
      },
      awayTeam: {
        name: 'Coquimbo Unido',
        tricode: 'CUN',
        score: 1,
        logo: 'https://images.firesport.io/cdf/team/3672/image.png?width=400&height=400',
      },
    },
  },
  {
    id: '611ec07790133b73bc7332d7',
    key: 2,
    type: 'video',
    title: 'RANGERS V/S BARNECHEA',
    subtitle: 'Text 2',
    heading: 'Heading',
    callToAction: {
      text: '18 MAY 18:00',
      icon: 'calendar',
      action: 'https://google.com',
    } as carouselCallToAction,
    image: 'https://images.firesport.io/p/cdf/program/98/logo/web_554x314.png',
  },
  {
    id: '611ec07790133b73bc7332d7',
    key: 3,
    type: 'video',
    title: 'Item 3',
    subtitle: 'Text 3',
    heading: 'Heading',
    callToAction: {
      text: 'HOY 18:40',
      icon: 'calendar',
      action: 'https://google.com',
    } as carouselCallToAction,
    image: 'https://images.firesport.io/p/cdf/program/98/logo/web_554x314.png',
  },
  {
    id: '611ec07790133b73bc7332d7',
    key: 4,
    type: 'video',
    title: 'Item 4',
    subtitle: 'Text 4',
    heading: 'Heading',
    callToAction: {
      text: 'HOY 18:40',
      icon: 'calendar',
      action: 'https://google.com',
    } as carouselCallToAction,
    image: 'https://images.firesport.io/p/cdf/program/98/logo/web_554x314.png',
  },
  {
    id: '611ec07790133b73bc7332d7',
    key: 5,
    type: 'match',
    title: 'Item 5',
    subtitle: 'Text 5',
    heading: 'Heading',
    callToAction: {
      text: 'Revívelo',
      icon: 'calendar',
      action: 'https://google.com',
    } as carouselCallToAction,
    match: {
      homeTeam: {
        name: 'Colo-Colo',
        tricode: 'COCO',
        score: 2,
        logo: 'https://images.firesport.io/cdf/team/2641/image.png?width=400&height=400',
      },
      awayTeam: {
        name: 'Coquimbo Unido',
        tricode: 'CUN',
        score: 1,
        logo: 'https://images.firesport.io/cdf/team/3672/image.png?width=400&height=400',
      },
    },
  },
] as carouselBase[];

export const mockCarouselCards = [
  {
    id: '87587238572',
    key: 0,
    type: 'match',
    title: 'Deportes Concepción V/S Wanderers',
    subtitle: 'Clasificatorias Qatar (solo en Chile)',
    heading: 'jun 14',
    status: 1,
    callToAction: {
      text: 'Revívelo',
      icon: 'calendar',
      action: 'https://google.com',
    } as carouselCallToAction,
    match: {
      homeTeam: {
        name: 'Colo-Colo',
        tricode: 'COCO',
        logo: 'https://images.firesport.io/cdf/team/2641/image.png?width=400&height=400',
      },
      awayTeam: {
        name: 'Coquimbo Unido',
        tricode: 'CUN',
        logo: 'https://images.firesport.io/cdf/team/3672/image.png?width=400&height=400',
      },
    },
  },
  {
    id: '364826348',
    key: 1,
    type: 'match',
    title: 'Deportes Concepción V/S Wanderers',
    subtitle: 'Clasificatorias Qatar (solo en Chile)',
    heading: 'jun 14',
    status: 2,
    label: 'live',
    callToAction: {
      text: 'Revívelo',
      icon: 'calendar',
      action: 'https://google.com',
    } as carouselCallToAction,
    match: {
      homeTeam: {
        name: 'Colo-Colo',
        tricode: 'COCO',
        score: 2,
        logo: 'https://images.firesport.io/cdf/team/2641/image.png?width=400&height=400',
      },
      awayTeam: {
        name: 'Coquimbo Unido',
        tricode: 'CUN',
        score: 1,
        logo: 'https://images.firesport.io/cdf/team/3672/image.png?width=400&height=400',
      },
    },
  },
  {
    id: '3759729357',
    key: 2,
    type: 'match',
    title: 'Deportes Concepción V/S Wanderers',
    subtitle: 'Clasificatorias Qatar (solo en Chile)',
    heading: 'jun 14',
    label: 'live',
    status: 3,
    callToAction: {
      text: 'Revívelo',
      icon: 'calendar',
      action: 'https://google.com',
    } as carouselCallToAction,
    match: {
      homeTeam: {
        name: 'Colo-Colo',
        tricode: 'COCO',
        score: 2,
        logo: 'https://images.firesport.io/cdf/team/2641/image.png?width=400&height=400',
      },
      awayTeam: {
        name: 'Coquimbo Unido',
        tricode: 'CUN',
        score: 1,
        logo: 'https://images.firesport.io/cdf/team/3672/image.png?width=400&height=400',
      },
    },
  },
  {
    id: '5234624',
    key: 3,
    type: 'match',
    title: 'Deportes Concepción V/S Wanderers',
    subtitle: 'Clasificatorias Qatar (solo en Chile)',
    heading: 'jun 14',
    status: 4,
    callToAction: {
      text: 'Revívelo',
      icon: 'calendar',
      action: 'https://google.com',
    } as carouselCallToAction,
    match: {
      homeTeam: {
        name: 'Colo-Colo',
        tricode: 'COCO',
        score: 1,
        penalty: 4,
        logo: 'https://images.firesport.io/cdf/team/2641/image.png?width=400&height=400',
      },
      awayTeam: {
        name: 'Coquimbo Unido',
        tricode: 'CUN',
        score: 1,
        penalty: 5,
        logo: 'https://images.firesport.io/cdf/team/3672/image.png?width=400&height=400',
      },
    },
  },
  {
    id: '5254645276',
    key: 4,
    type: 'match',
    title: 'Deportes Concepción V/S Wanderers',
    subtitle: 'Clasificatorias Qatar (solo en Chile)',
    heading: 'jun 14',
    status: 3,
    label: 'live',
    callToAction: {
      text: 'Revívelo',
      icon: 'calendar',
      action: 'https://google.com',
    } as carouselCallToAction,
    match: {
      homeTeam: {
        name: 'Colo-Colo',
        tricode: 'COCO',
        logo: 'https://images.firesport.io/cdf/team/2641/image.png?width=400&height=400',
      },
      awayTeam: {
        name: 'Coquimbo Unido',
        tricode: 'CUN',
        logo: 'https://images.firesport.io/cdf/team/3672/image.png?width=400&height=400',
      },
    },
  },
  {
    id: '6246242',
    key: 5,
    type: 'match',
    title: 'Deportes Concepción V/S Wanderers',
    subtitle: 'Clasificatorias Qatar (solo en Chile)',
    heading: 'jun 14',
    duration: '01:05:10',
    status: 2,
    callToAction: {
      text: 'Revívelo',
      icon: 'calendar',
      action: 'https://google.com',
    } as carouselCallToAction,
    match: {
      homeTeam: {
        name: 'Colo-Colo',
        tricode: 'COCO',
        logo: 'https://images.firesport.io/cdf/team/2641/image.png?width=400&height=400',
      },
      awayTeam: {
        name: 'Coquimbo Unido',
        tricode: 'CUN',
        logo: 'https://images.firesport.io/cdf/team/3672/image.png?width=400&height=400',
      },
    },
  },
  {
    id: '835982398',
    key: 6,
    type: 'match',
    title: 'Deportes Concepción V/S Wanderers',
    subtitle: 'Clasificatorias Qatar (solo en Chile)',
    heading: 'jun 14',
    duration: '01:05:10',
    status: 3,
    callToAction: {
      text: 'Revívelo',
      icon: 'calendar',
      action: 'https://google.com',
    } as carouselCallToAction,
    match: {
      homeTeam: {
        name: 'Colo-Colo',
        tricode: 'COCO',
        score: 2,
        logo: 'https://images.firesport.io/cdf/team/2641/image.png?width=400&height=400',
      },
      awayTeam: {
        name: 'Coquimbo Unido',
        tricode: 'CUN',
        score: 1,
        logo: 'https://images.firesport.io/cdf/team/3672/image.png?width=400&height=400',
      },
    },
  },
  {
    id: '357872359723',
    key: 7,
    type: 'match',
    title: 'Deportes Concepción V/S Wanderers',
    subtitle: 'Clasificatorias Qatar (solo en Chile)',
    heading: 'jun 14',
    duration: '01:05:10',
    status: 4,
    callToAction: {
      text: 'Revívelo',
      icon: 'calendar',
      action: 'https://google.com',
    } as carouselCallToAction,
    match: {
      homeTeam: {
        name: 'Colo-Colo',
        tricode: 'COCO',
        score: 2,
        logo: 'https://images.firesport.io/cdf/team/2641/image.png?width=400&height=400',
      },
      awayTeam: {
        name: 'Coquimbo Unido',
        tricode: 'CUN',
        score: 1,
        logo: 'https://images.firesport.io/cdf/team/3672/image.png?width=400&height=400',
      },
    },
  },
  {
    key: 'episode1',
    type: 'video',
    title: 'Episode Title Card',
    duration: '59:32',
    subtitle: 'Goles de intensidad en la jornada 8 de las eliminatorias',
    image: 'https://images.firesport.io/cdf/program/95/logo/web.png',
  },
  {
    key: 'episode2',
    type: 'video',
    title: 'Episode w/ Too Long Title & Subtitle',
    subtitle: 'Also remove image and duration; next card shows this card w/o title and subtitle',
  },
  {
    key: 'episode3',
    type: 'video',
  },
  {
    key: 'episode4',
    type: 'video',
    title: 'Live Episode',
    label: 'live',
    duration: '59:32',
    subtitle: 'Proof of Concept for live label',
    image: 'https://images.firesport.io/cdf/program/95/logo/web.png',
  },
  {
    key: 'episode5',
    type: 'video',
    title: 'Video on Demand 1',
    label: 'Clip Label',
    duration: '3:40',
    subtitle: 'test subtitle 1',
    heading: 'test heading 1',
    image: 'https://images.firesport.io/p/cdf/program/98/logo/web_270x152.png',
  },
  {
    key: 'episode6',
    type: 'video',
    title: 'Video on Demand 2',
    label: 'Clip Label',
    duration: '2:55',
    subtitle: 'test subtitle 2',
    heading: 'test heading 2',
    image: 'https://images.firesport.io/p/cdf/program/98/logo/web_270x152.png',
  },
] as carouselBase[];

export const mockTicker = [
  {
    key: 0,
    match: {
      homeTeam: {
        tricode: 'PTO',
        score: 1,
        id: '123456',
        aggregate: 3,
        penalty: 3,
      },
      awayTeam: {
        tricode: 'MAG',
        score: 1,
        id: '654321',
        aggregate: 2,
        penalty: 4,
      },
      live: false,
      isGameActivated: false,
      startDate: '20190521',
      startTime: '8:30 PM ET',
      startDateTime: 1558485000000,
      gameStatus: 4,
    },
  },
  {
    key: 1,
    match: {
      homeTeam: {
        tricode: 'PTO',
        score: 0,
        id: '123456',
      },
      awayTeam: {
        tricode: 'MAG',
        score: 1,
        id: '654321',
      },
      live: false,
      isGameActivated: false,
      startDate: '20190521',
      startTime: '8:30 PM ET',
      startDateTime: 1558485000000,
      gameStatus: 4,
    },
  },
  {
    key: 2,
    match: {
      homeTeam: {
        tricode: 'PTO',
        score: 1,
        id: '123456',
      },
      awayTeam: {
        tricode: 'MAG',
        score: 0,
        id: '654321',
      },
      live: false,
      isGameActivated: false,
      startDate: '20190521',
      startTime: '8:30 PM ET',
      startDateTime: 1558485000000,
      gameStatus: 3,
    },
  },
  {
    key: 3,
    match: {
      homeTeam: {
        tricode: 'KEN',
        score: '1',
        id: '123456',
      },
      awayTeam: {
        tricode: 'JOE',
        score: '1',
        id: '654321',
      },
      live: false,
      isGameActivated: false,
      startDate: '20190521',
      startTime: '8:30 PM ET',
      startDateTime: 1558485000000,
      gameStatus: 2,
    },
  },
  {
    key: 5,
    match: {
      homeTeam: {
        tricode: 'SOS',
        score: '3',
        id: '123456',
      },
      awayTeam: {
        tricode: 'KEN',
        score: '1',
        id: '654321',
      },
      live: false,
      isGameActivated: false,
      startDate: '20190521',
      startTime: '8:30 PM ET',
      startDateTime: 1558485000000,
      gameStatus: 1,
    },
  },
  {
    key: 6,
    match: {
      homeTeam: {
        tricode: 'SOS',
        id: '123456',
      },
      awayTeam: {
        tricode: 'KEN',
        id: '654321',
      },
      live: false,
      isGameActivated: false,
      startDate: '20190521',
      startTime: '8:30 PM ET',
      startDateTime: 1558485000000,
      gameStatus: 1,
      gamestateDisplay: 'CANCELED',
    },
  },
  {
    key: 7,
    match: {
      homeTeam: {
        tricode: 'SOS',
        id: '123456',
        aggregate: 3,
      },
      awayTeam: {
        tricode: 'KEN',
        id: '654321',
        aggregate: 2,
      },
      live: false,
      isGameActivated: false,
      startDate: '20190521',
      startTime: '8:30 PM ET',
      startDateTime: 1558485000000,
      gameStatus: 1,
      gamestateDisplay: 'POSTPONED',
    },
  },
  {
    key: 8,
    match: {
      homeTeam: {
        tricode: 'SOS',
        score: 0,
        id: '123456',
      },
      awayTeam: {
        tricode: 'KEN',
        score: 0,
        id: '654321',
      },
      live: false,
      isGameActivated: false,
      startDate: '20190521',
      startTime: '8:30 PM ET',
      startDateTime: 1558485000000,
      gameStatus: 1,
      gamestateDisplay: 'NO CONTEST',
    },
  },
] as carouselBase[];

export const mockEPG = [
  {
    id: '1640368824751',
    key: '1640368824751',
    type: 'show',
    show: {
      airDateTime: '2021-12-24T00:30:00',
      episode: '4',
      originalTitle: 'CURICÓ UNIDO VS A. ITALIANO',
      startDate: '2021-12-24T00:30:00',
      startTime: '2021-12-24T02:50:00',
      title: ' ',
    },
  },
  {
    id: '1640368824752',
    key: '1640368824752',
    type: 'show',
    show: {
      airDateTime: '2021-12-24T02:50:00',
      episode: '532',
      originalTitle: 'TNT DATA SPORTS (REPETICIÓN) 23-12-2021',
      startDate: '2021-12-24T02:50:00',
      startTime: '2021-12-24T03:50:00',
      title: ' ',
    },
  },
  {
    id: '1640368824754',
    key: '1640368824754',
    type: 'show',
    show: {
      airDateTime: '2021-12-24T04:00:00',
      episode: '600',
      originalTitle: 'PELOTA PARADA (REPETICIÓN) 23-12-2021',
      startDate: '2021-12-24T04:00:00',
      startTime: '2021-12-24T06:00:00',
      title: ' ',
    },
  },
] as carouselBase[];
