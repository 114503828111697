import { ApiResponse } from './dalton-types';

export enum PinAndPairPath {
  GET_CODE = 'v1/code',
  CHECK_CODE = 'v1/check',
  VALIDATE_CODE = 'v1/validate',
  CLAIM_CODE = 'v1/claim',
}

export enum CodeStatus {
  Paired = 'paired',
  NotPaired = 'not_paired',
}

export interface GetCodeParams {
  deviceCode: string;
  ip: string;
}

export interface ValidateCodeParams {
  code: string;
}

export interface CheckCodeParams {
  code: string;
}

export interface ClaimCodeParams {
  regCode: string;
  uid: string;
  userToken: string;
  options: string;
}

export interface GetCodeResponse {
  createdAt: string;
  recordId: string;
  regCode: string;
}

export interface ValidateCodeResponse {
  isValid: boolean;
  message: string;
}

export interface CheckCodeResponse {
  regCode: string;
  isClaimed: boolean;
  status: CodeStatus;
  message: string;
  authToken: string;
  uid: string;
  options: {
    decoded: string;
    hash: string;
  };
}

export interface ClaimCodeResponse {
  regCode: string;
  isClaimed: boolean;
  status: CodeStatus;
  message: string;
}

export type PinAndPairGetResponse = ApiResponse<GetCodeResponse>;
export type PinAndPairValidateResponse = ApiResponse<ValidateCodeResponse>;
export type PinAndPairCheckResponse = ApiResponse<CheckCodeResponse>;
export type PinAndPairClaimResponse = ApiResponse<ClaimCodeResponse>;
