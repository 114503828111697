import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CUSTOM_DOMAIN, loadData, ROUTE_FOCUS_KEY, saveData } from '@warnermmedia/gsp-core/sdk/data-access';
import { useTheme } from 'react-native-paper';
import { getStyles } from './AppInfoButtons.styles';
import { CustomButton, ENV, useGetEnvironment } from '@warnermmedia/gsp-core/sdk/ui';
import { useFocusable } from '@noriginmedia/norigin-spatial-navigation';

interface AppInfoButtonsProps {
  handlePress: (env: string) => void;
}

export const AppInfoButtons = ({ handlePress }: AppInfoButtonsProps) => {
  const { environment } = useGetEnvironment();
  const { setFocus } = useFocusable();

  const envLinks = useMemo(
    () => [
      {
        name: 'QA',
        link: 'https://appconfig-estadiotntsports.qa.nonprod.wmsports.io',
      },
      {
        name: 'INT',
        link: 'https://appconfig-estadiotntsports.int.nonprod.wmsports.io',
      },
      {
        name: 'DEV',
        link: 'https://appconfig-estadiotntsports.dev.nonprod.wmsports.io',
      },
      {
        name: 'STAGE',
        link: 'https://appconfig-estadiotntsports.stage.nonprod.wmsports.io',
      },
      {
        name: 'PROD',
        link: 'https://appconfig-estadiotntsports.wmsports.io',
      },
    ],
    []
  );

  const [currentEnv, setCurrentEnv] = useState('');
  const { colors } = useTheme();
  const styles = getStyles(colors);

  const getCurrentEnv = useCallback((): string => {
    let current = '';
    switch (environment) {
      case ENV.DEVELOPMENT_QA:
        current = 'QA';
        break;
      case ENV.DEVELOPMENT_INT:
        current = 'INT';
        break;
      case ENV.DEVELOPMENT_STAGING:
        current = 'STAGE';
        break;
      case ENV.DEVELOPMENT_DEV:
        current = 'DEV';
        break;
      case ENV.DEVELOPMENT_PROD:
      case ENV.PRODUCTION:
        current = 'PROD';
        break;
      default:
        current = 'STAGE';
    }
    return envLinks.find((envLink) => envLink.name === current)?.link ?? '';
  }, [envLinks, environment]);

  const loadCurrentEnv = useCallback(() => {
    const defaultWebLink = getCurrentEnv();
    const env = loadData(CUSTOM_DOMAIN) || defaultWebLink;
    setCurrentEnv(env);
  }, [getCurrentEnv]);

  const isCurrentEnv = (env: string): boolean => {
    return env === currentEnv;
  };

  useEffect(() => {
    loadCurrentEnv();
  }, [loadCurrentEnv]);

  useEffect(() => {
    const currentName = envLinks.find((envLink) => envLink.link === currentEnv)?.name ?? '';
    if (currentName) {
      setFocus(currentName);
      saveData(ROUTE_FOCUS_KEY, currentName);
    }
  }, [setFocus, envLinks, currentEnv]);

  return (
    <>
      {envLinks.map(({ link, name }) => (
        <CustomButton
          key={name}
          onPress={() => {
            handlePress(link);
          }}
          label={name}
          mode="contained"
          labelStyle={styles.buttonLabel}
          btnStyle={[styles.button, isCurrentEnv(link) ? styles.currentEnvStyle : {}]}
          focusKey={name}
        />
      ))}
    </>
  );
};

export default AppInfoButtons;
