import { useCallback, useContext } from 'react';
import { GestureResponderEvent } from 'react-native';
import { HistoryContext } from '@warnermmedia/gsp-core/brands/estadio/data-access';

interface GoBackAction {
  goBack: (e?: Event | GestureResponderEvent) => void;
}

export const useGoBack = (): GoBackAction => {
  const historyContext = useContext(HistoryContext);
  const history = historyContext?.ready ? historyContext?.useHistory() : null;

  const goBack = useCallback(
    (e?: Event | GestureResponderEvent) => {
      e && e.preventDefault();
      history?.goBack();
    },
    [history]
  );

  return {
    goBack,
  };
};

export default useGoBack;
