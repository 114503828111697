import merge from 'lodash/merge';
import { StyleProp, StyleSheet, ViewStyle, TextStyle } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';

interface Styles {
  cta: ViewStyle;
  ctaIconLeft: ViewStyle;
  ctaInner: ViewStyle;
  ctaInnerLeft: ViewStyle;
  icon: ViewStyle;
  iconLeft: ViewStyle;
  iconCenter: ViewStyle;
  textContainLeft: ViewStyle;
  textContainCenter: ViewStyle;
  text: TextStyle;
}

export const getStyles = (breakpoints: Breakpoints, colors: ReactNativePaper.ThemeColors) => {
  const base: StyleProp<Styles> = {
    cta: {
      position: 'relative',
      flexDirection: 'row',
      justifyContent: 'center',
      width: '100%',
      overflow: 'visible',
      zIndex: 101,
    },
    ctaIconLeft: {
      justifyContent: 'flex-start',
    },
    ctaInner: {
      margin: 'auto',
    },
    ctaInnerLeft: {
      margin: 0,
      alignItems: 'center',
      justifyContent: 'center',
    },
    icon: {
      position: 'absolute',
      marginLeft: 5,
      zIndex: 102,
    },
    iconLeft: {
      left: -15,
    },
    iconCenter: {
      left: 0,
    },
    textContainLeft: {
      marginLeft: 30,
      marginRight: 20,
    },
    textContainCenter: {
      marginLeft: 50,
      marginTop: 9,
      marginBottom: 3,
    },
    text: {
      fontSize: 20,
      fontFamily: 'Oswald-Medium',
      lineHeight: 24,
      color: colors.keyboardFocus.light,
      marginLeft: 5,
    },
  };
  const mediaQueries =
    breakpoints.currentBreakpoints.isTiny ||
    breakpoints.currentBreakpoints.isTnySm ||
    breakpoints.currentBreakpoints.isSmMed
      ? ({} as StyleProp<Styles>)
      : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
