import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import {
  languageStrings,
  PagesUrl,
  QueryNames,
  useCMSQueryDataResults,
  useIsMountedRef,
} from '@warnermmedia/gsp-core/brands/estadio/feature';
import {
  CMSPageBuilder,
  LayoutType,
  LayoutWrapper,
  SkeletonDisplay,
  TitleType,
} from '@warnermmedia/gsp-core/brands/estadio/ui';
import { Page } from '@warnermmedia/gsp-core/brands/estadio/data-access';

export const Tournaments: React.FC = () => {
  const isMountedRef = useIsMountedRef();
  const [pageTitle, setPageTitle] = useState('');
  const { pageData: pageUriData, loading } = useCMSQueryDataResults<Page>({
    queryName: QueryNames.GET_TOURNAMENTS,
    uri: PagesUrl.Tournaments,
  });

  useEffect(() => {
    if (pageUriData && isMountedRef.current) {
      const component = (pageUriData?.components ?? []).find((content) => content && content.id === 'screen-title');
      setPageTitle(component?.headline || languageStrings.default.tournamentsPage);
    }
  }, [pageUriData, isMountedRef]);

  return (
    <View>
      {loading ? (
        <SkeletonDisplay route="/tournaments" />
      ) : (
        <LayoutWrapper
          layoutType={LayoutType.Full}
          titleType={TitleType.Left}
          pageTitle={pageTitle}
          overrideChildrenWrapper={loading ? { paddingHorizontal: 0 } : {}}
        >
          <CMSPageBuilder data={pageUriData} />
        </LayoutWrapper>
      )}
    </View>
  );
};

export default Tournaments;
