import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { breakpointsStateStore, CMSAPIModels, Maybe } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import {
  carouselBase,
  eventToCarouselBase,
  getContentTitle,
  getMatchPageTitle,
  ScreenEventType,
  useFormatCarouselData,
  useIsMountedRef,
  useMparticleScreenEvent,
} from '@warnermmedia/gsp-core/brands/estadio/feature';
import MatchVideos from '../components/matchVideos';
import MatchHero from '../components/matchHero';
import { MetaTags, useGetDevice } from '@warnermmedia/gsp-core/sdk/ui';
import { useReactiveVar } from '@apollo/client';
import { useTheme } from 'react-native-paper';

export const MatchPageBuilder = ({
  heroData,
  videoData,
  itemClick,
}: {
  heroData: Maybe<CMSAPIModels.Event> | undefined;
  videoData: CMSAPIModels.Video[] | undefined;
  itemClick: (item: carouselBase) => void;
}) => {
  const [pageHeroData, setHeroData] = useState<Maybe<CMSAPIModels.Event>>();
  const [pageVideoData, setVideoData] = useState<CMSAPIModels.Video[]>();
  const [displayHero, setHeroDisplay] = useState<carouselBase | null>(null);
  const [VideoDisplay, setVideoDisplay] = useState<carouselBase[] | null>(null);
  const [pageTitle, setPageTitle] = useState<string>('');
  useMparticleScreenEvent(ScreenEventType.Match, pageTitle, displayHero?.id ?? '', {
    homeTeam: displayHero?.match?.homeTeam?.name ?? '',
    awayTeam: displayHero?.match?.awayTeam?.name ?? '',
  });
  const { formatVideos } = useFormatCarouselData();
  const isMountedRef = useIsMountedRef();
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const device = useGetDevice();
  const { colors } = useTheme();

  useEffect(() => {
    if (pageHeroData && isMountedRef.current) {
      const displayHero = eventToCarouselBase(pageHeroData);
      const title = getMatchPageTitle(displayHero.match);
      setHeroDisplay(displayHero);
      setPageTitle(title);
    }
  }, [pageHeroData, isMountedRef]);

  useEffect(() => {
    if (pageVideoData && isMountedRef.current) {
      setVideoDisplay(formatVideos(pageVideoData));
    }
  }, [pageVideoData, isMountedRef]);

  useEffect(() => {
    if (heroData && isMountedRef.current) {
      setHeroData(heroData);
    }
  }, [heroData, isMountedRef]);

  useEffect(() => {
    if (videoData && isMountedRef.current) {
      setVideoData(videoData);
    }
  }, [videoData, isMountedRef]);

  return (
    <View>
      {device.isWeb && <MetaTags title={getContentTitle(pageTitle)} />}
      {displayHero && (
        <MatchHero
          itemClick={itemClick}
          heroItems={displayHero}
          device={device}
          colors={colors}
          breakpoints={breakpoints}
        />
      )}
      {VideoDisplay && !!VideoDisplay.length && (
        <MatchVideos
          itemClick={itemClick}
          videoItems={VideoDisplay}
          device={device}
          colors={colors}
          breakpoints={breakpoints}
        />
      )}
    </View>
  );
};

export default MatchPageBuilder;
