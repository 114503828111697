import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  wrapper: {},
  content: {
    backgroundColor: '#ffffff20',
    borderRadius: 5,
  },
  title: {
    width: '35%',
    height: 50,
    backgroundColor: '#ffffff30',
    marginBottom: 20,
    marginTop: 20,
    marginLeft: 10,
    borderRadius: 5,
  },
  subTitle: {
    width: '55%',
    height: 30,
    backgroundColor: '#ffffff30',
    marginBottom: 20,
    marginTop: 10,
    marginLeft: 10,
    borderRadius: 5,
  },
});
