import {
  EMAIL_VERIFICATION_STATUS,
  loadData,
  USER_EMAIL_STATUS,
  UserEmailResponsesEntity,
} from '@warnermmedia/gsp-core/sdk/data-access';
import { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { isUserEmailVerified, loginAPIStateStore } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { isEmpty } from 'lodash';
import { useLocation } from 'react-router';
import { useIsMountedRef } from '../useIsMountedRef';

export const useEmailStatus = () => {
  const [isVerified, setIsVerified] = useState<boolean | null>(
    !isEmpty(loadData(USER_EMAIL_STATUS)) ? loadData(USER_EMAIL_STATUS) === EMAIL_VERIFICATION_STATUS.CONFIRMED : null
  );
  const { user } = useReactiveVar(loginAPIStateStore);
  const isMountedRef = useIsMountedRef();
  const location = useLocation();

  useEffect(() => {
    if (isMountedRef.current && user && location.pathname !== '/resend-email') {
      let emailStatus = loadData(USER_EMAIL_STATUS);
      if (isEmpty(emailStatus)) {
        const primaryEmail: UserEmailResponsesEntity | undefined = user.userEmailResponses?.find(
          (email: UserEmailResponsesEntity) => email.primary === true
        );
        emailStatus = primaryEmail?.status;
      }
      isUserEmailVerified(emailStatus === EMAIL_VERIFICATION_STATUS.CONFIRMED);
      setIsVerified(emailStatus === EMAIL_VERIFICATION_STATUS.CONFIRMED);
    }
  }, [isMountedRef, user, location]);

  return { isEmailVerified: isVerified };
};

export default useEmailStatus;
