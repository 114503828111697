import React, { useCallback, useContext, useEffect, useState } from 'react';
import { VideoErrorMessages } from '@warnermmedia/gsp-core/sdk/data-access';
import { languageStrings, useLogout } from '@warnermmedia/gsp-core/brands/estadio/feature';
import {
  breakpointsStateStore,
  HistoryContext,
  isNavOpen,
  ModalType,
} from '@warnermmedia/gsp-core/brands/estadio/data-access';
import ModalWrapper, { ModalContentContainer } from '../modalWrapper';
import { Text, View } from 'react-native';
import { getStyles } from './videoErrorModal.styles';
import { useReactiveVar } from '@apollo/client';
import { useTheme } from 'react-native-paper';
import { EstadioButton } from '../../components';

interface Props {
  errMessage: string;
  setVideoError: React.Dispatch<React.SetStateAction<string>>;
  exitFullScreen?: () => void;
  isTv: boolean;
}

const { GEOBLOCK_EVENT_ERROR, CONCURRENCY_LIMIT_EVENT_ERROR, TOOLBOX_CONCURRENT_SESSION_ERROR } = VideoErrorMessages;

const allowedMessages = [GEOBLOCK_EVENT_ERROR, CONCURRENCY_LIMIT_EVENT_ERROR, TOOLBOX_CONCURRENT_SESSION_ERROR];

const translateMessage = (errMessage: string) => {
  switch (errMessage) {
    case GEOBLOCK_EVENT_ERROR:
      return {
        title: languageStrings.default.geoBlockMessageTitle,
        body: languageStrings.default.geoBlockMessageDescription,
        cta: languageStrings.default.closeButton,
      };

    case CONCURRENCY_LIMIT_EVENT_ERROR:
      return {
        title: languageStrings.default.concurrencyModalTitle,
        body: languageStrings.default.concurrencyModalDescription,
        cta: languageStrings.default.concurrencyModalCTALabel,
      };

    case TOOLBOX_CONCURRENT_SESSION_ERROR:
      return {
        title: languageStrings.default.toolboxConcurrencyModalTitle,
        body: languageStrings.default.toolboxConcurrencyModalDescription,
        cta: languageStrings.default.toolboxConcurrencyModalCTALabel,
      };
    default:
      return { title: '', body: '', cta: '' };
  }
};

export const VideoErrorModal = ({ errMessage, setVideoError, exitFullScreen, isTv }: Props) => {
  const historyContext = useContext(HistoryContext);
  const history = historyContext?.ready ? historyContext?.useHistory() : null;
  const [visible, setVisible] = useState(false);
  const [translatedMessage, setTranslatedMessage] = useState({ title: '', body: '', cta: '' });
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const { colors } = useTheme();
  const styles = getStyles(breakpoints, colors);
  const { logout } = useLogout();

  const closeNavIsTv = () => {
    isNavOpen(false);
  };

  const handleLogOut = (e: Event) => {
    e?.preventDefault();
    logout(e);
    isTv && closeNavIsTv();
  };

  const goBack = useCallback(() => {
    setVisible(false);
    setVideoError('');
    history?.push('/home');
  }, [history, setVideoError]);

  const handleCta = useCallback(
    (e) => {
      switch (errMessage) {
        case TOOLBOX_CONCURRENT_SESSION_ERROR:
          handleLogOut(e);
          break;

        default:
          goBack();
      }
    },
    [errMessage]
  );

  const onVideoErrorModal = useCallback(() => {
    exitFullScreen && exitFullScreen();
  }, [exitFullScreen]);

  useEffect(() => {
    if (!!errMessage && !visible && allowedMessages.includes(errMessage)) {
      setTranslatedMessage(translateMessage(errMessage));
      setVisible(true);
      onVideoErrorModal();
    }
  }, [errMessage, visible, onVideoErrorModal]);

  return (
    <ModalWrapper
      visible={visible}
      modalType={visible ? ModalType.VideoErrorModal : null}
      onClose={goBack}
      showCloseButton={true}
      modalContentContainerType={ModalContentContainer.Full}
      modalMainContainerStyle={styles.container}
      modalLayoutContainerStyle={styles.fullScreenContainer}
    >
      <View style={styles.textContainer}>
        <Text style={styles.title}>{translatedMessage.title}</Text>
        <View style={styles.containerInner}>
          <Text style={styles.description}>{translatedMessage.body}</Text>
        </View>
        <EstadioButton
          mode="outline"
          label={translatedMessage.cta}
          onPress={handleCta}
          btnStyle={styles.buttonStyle}
          labelStyle={styles.buttonLabelStyle}
          focusKey={ModalType.VideoErrorModal}
        />
      </View>
    </ModalWrapper>
  );
};
