import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  wrapper: {
    width: '85%',
    maxWidth: 350,
    backgroundColor: 'transparent',
  },
  title: {
    color: 'black',
    textAlign: 'center',
    fontSize: 18,
    fontWeight: 'bold',
    marginVertical: 15,
  },
  subTitle: {
    color: 'black',
    textAlign: 'center',
    fontSize: 14,
    marginVertical: 5,
  },
  input: {
    backgroundColor: '#fff',
    borderRadius: 5,
    borderWidth: 1,
  },
  inputWrapper: {
    marginTop: 25,
    marginBottom: 40,
  },
  inputLabel: {
    marginBottom: 10,
    color: '#000000',
  },
  checkboxWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  rememberMeText: {
    color: '#000000',
    fontSize: 12,
  },
  loginBtn: {
    paddingVertical: 10,
    backgroundColor: '#ff0085',
  },
  loginBtnText: {
    fontWeight: 'bold',
    fontFamily: 'Oswald-Bold',
  },
  loginBtnWrapper: {
    marginVertical: 20,
  },
  forgotPassBtn: {
    fontSize: 12,
    textTransform: 'capitalize',
  },
  forgotPassBtnContainer: {
    marginVertical: 10,
  },
  errorText: {
    color: 'red',
    fontSize: 12,
    marginTop: 15,
    marginBottom: 10,
    textAlign: 'center',
  },
});
