import React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';

/* eslint-disable-next-line */
export interface QuestionProps {
  width?: number;
  height?: number;
  color?: string;
  iconColor?: string;
}

export function Question(props: QuestionProps) {
  const width = props.width || 20;
  const height = props.height || 20;
  const color = props.color || '#359fd7';
  const iconColor = props.iconColor || '#fff';

  return (
    <Svg fill="none" height={height} viewBox="0 0 20 20" width={width}>
      <Circle cx="10" cy="10" r="10" fill={color} />
      <Path
        fill={iconColor}
        d="M9.497 12.91c-.57 0-1.032.475-1.032 1.045 0 .556.448 1.045 1.032 1.045.583 0 1.044-.489 1.044-1.045 0-.57-.475-1.044-1.044-1.044zM9.673 5C7.841 5 7 6.086 7 6.818c0 .53.448.774.814.774.733 0 .434-1.045 1.818-1.045.679 0 1.222.298 1.222.923 0 .732-.76 1.153-1.208 1.533-.394.339-.91.895-.91 2.062 0 .706.19.91.747.91.665 0 .8-.3.8-.557 0-.705.014-1.113.76-1.696.367-.285 1.52-1.208 1.52-2.483C12.563 5.963 11.41 5 9.673 5z"
      />
    </Svg>
  );
}

export default Question;
