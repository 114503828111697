import { useContext } from 'react';
import {
  clearListData,
  EMAIL_VERIFICATION_STATUS,
  EMPTY_TVE_TOOLBOX_CONFIG,
  saveData,
  setMparticleUserId,
  TVE_AUTH_PROVIDER,
  TVE_TOOLBOX_AUTH_TOKEN,
  TVE_URN_PARAM,
  TveCountryProviders,
  TveError,
  TveProvider,
  TveVideoTokenResponse,
  USER_EMAIL_STATUS,
  useTveToolbox,
} from '@warnermmedia/gsp-core/sdk/data-access';
import {
  AppConfigContext,
  isTveAuth,
  isUserEmailVerified,
  isUserLoggedIn,
  loginAPIStateStore,
  PROVIDER_LOGIN_CODE_VALIDATION,
  tveProvider,
  userHasSubscription,
} from '@warnermmedia/gsp-core/brands/estadio/data-access';

import {
  getStoredAccessProperty,
  getUniqueId,
  languageStrings,
  logoutEstadioApplication,
} from '@warnermmedia/gsp-core/brands/estadio/feature';
import { useGetDevice } from '@warnermmedia/gsp-core/sdk/ui';

export const Tve = () => {
  const { isMobileDevice } = useGetDevice();
  const appConfig = useContext(AppConfigContext);
  const tveConfig = appConfig?.appConfig.tve;
  const tve = useTveToolbox(
    tveConfig ?? EMPTY_TVE_TOOLBOX_CONFIG,
    languageStrings.default.app_web_title,
    isMobileDevice
  );
  const tenant = tveConfig?.tenant ?? 'estadiochile';

  const updateAuthData = (token: string, provider: TveProvider, tveUserId: string): void => {
    saveData(TVE_TOOLBOX_AUTH_TOKEN, {
      token,
      provider,
      tveUserId,
    });
  };

  const handleIsAuthenticated = (
    isAuth: boolean,
    token: string,
    provider: TveProvider | null = null,
    tveUserId?: string
  ) => {
    if (isAuth && appConfig?.appConfig.tve.enabled && provider !== null && !!tveUserId) {
      provider && updateAuthData(token, provider, tveUserId);

      // TODO check for 3rd party scenario or already has an account
      // GSP-1992
      // TODO verify email confirmed status ? 1st party scenario?
      // GSP-1959
      saveData(USER_EMAIL_STATUS, EMAIL_VERIFICATION_STATUS.CONFIRMED);
      isUserLoggedIn(true);
      isTveAuth(true);
      isUserEmailVerified(true);
      userHasSubscription(true);
      tveProvider(provider);
      loginAPIStateStore({
        ...loginAPIStateStore(),
        authToken: token,
        checkUserEmail: false,
        isTveAuth: true,
        userHasSubscription: true,
        preferences: [],
        preferencesProcessed: true,
        preferencesFetched: true,
        gettingPreferences: false,
        tveUserId,
      });
    } else {
      logoutEstadioApplication();
    }
  };

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const handleApiError = (e: any, onError?: (result: TveError) => void) => {
    handleIsAuthenticated(false, '');
    onError && onError({ error: e.message });
  };

  const getTokenErrorMessage = (message = ''): string => {
    return message && message.includes('no access')
      ? languageStrings.default.unKnownTokenError
      : languageStrings.default.providerLoginAuthenticationError;
  };

  const checkVideoTokenAccess = async (accessToken: string): Promise<TveVideoTokenResponse> => {
    const videoTokenResponse = await tve.getTveVideoToken({
      accessToken,
      mediaId: '',
      urn: tveConfig?.urnParam ?? TVE_URN_PARAM,
      userId: 'user-a',
      network: '',
      requestor: tenant,
      mvpd: tenant,
    });
    if (!videoTokenResponse.success) {
      throw new Error(getTokenErrorMessage(videoTokenResponse.error));
    }
    return videoTokenResponse;
  };

  const login = async (
    country: string,
    provider: TveProvider,
    onSuccess: (result: string) => void,
    onError: (result: TveError) => void
  ) => {
    try {
      saveData(TVE_AUTH_PROVIDER, provider);
      const loginResponse = await tve.tveLogin(country, provider.shortName);

      // Don't show error message on web
      if (!loginResponse.isMobile) return;

      if (!loginResponse.success) {
        throw new Error(languageStrings.default.providerLoginAuthenticationError);
      }
      // After a successful login we need to check the TVE user token access
      await checkVideoTokenAccess(loginResponse.accessToken);

      const tveUserId = getUniqueId('tve');
      setMparticleUserId(tveUserId);
      loginAPIStateStore({ ...loginAPIStateStore(), tveUserId, tveProvider: provider });
      handleIsAuthenticated(true, loginResponse.accessToken, provider, tveUserId);
      onSuccess(loginResponse.accessToken);
    } catch (e) {
      handleApiError(e, onError);
    }
  };

  const handleRedirectionLogin = async (
    code: string,
    provider: TveProvider,
    onSuccess: (result: string) => void,
    onError: (result: TveError) => void
  ) => {
    const host = window && window.location ? window.location.host : '';
    const redirectUri = host ? (host.includes('localhost') ? `http://${host}` : `https://${host}`) : '';

    try {
      const tokenResponse = await tve.getAuthorizationToken(code, redirectUri);
      const accessToken = tokenResponse?.access_token;

      if (accessToken) {
        // After a successful login we need to check the TVE user token access
        await checkVideoTokenAccess(accessToken);

        const tveUserId = getUniqueId('tve');
        setMparticleUserId(tveUserId);

        loginAPIStateStore({ ...loginAPIStateStore(), tveUserId, tveProvider: provider });
        handleIsAuthenticated(true, accessToken, provider, tveUserId);

        onSuccess(accessToken);
      } else {
        onError?.({ error: languageStrings.default.providerLoginAuthenticationError });
      }
      clearListData([TVE_AUTH_PROVIDER, PROVIDER_LOGIN_CODE_VALIDATION]);
    } catch (e) {
      handleApiError(e, onError);
    }
  };

  const getProviders = async (
    onSuccess: (result: TveCountryProviders) => void,
    onError: (result: TveError) => void,
    country?: string
  ) => {
    try {
      const providersResponse = await tve.getTveProviders(country);
      if (!providersResponse.success) {
        throw new Error(providersResponse.error ?? languageStrings.default.unKnownProviderError);
      }
      onSuccess(providersResponse.providers);
    } catch (e) {
      handleApiError(e, onError);
    }
  };

  const logout = async (onSuccess: (result: boolean) => void, onError?: (result: TveError) => void) => {
    try {
      const logoutResponse = await tve.tveLogout(getStoredAccessProperty<string>(TVE_TOOLBOX_AUTH_TOKEN, 'token'));
      if (!logoutResponse.success) {
        throw new Error(logoutResponse.error ?? languageStrings.default.unKnownLogoutError);
      }
      logoutEstadioApplication();
      onSuccess(logoutResponse.success);
    } catch (e) {
      handleApiError(e, onError);
      onSuccess(true);
    }
  };

  const restoreSession = (onSuccess?: (result: boolean) => void) => {
    // TODO validate token was actually provided by Toolbox
    // GSP-1991
    try {
      const token = getStoredAccessProperty<string>(TVE_TOOLBOX_AUTH_TOKEN, 'token');
      const provider = getStoredAccessProperty<TveProvider>(TVE_TOOLBOX_AUTH_TOKEN, 'provider');
      const tveUserId = getStoredAccessProperty<string>(TVE_TOOLBOX_AUTH_TOKEN, 'tveUserId');

      if (token && token.length && provider) {
        handleIsAuthenticated(true, token, provider, tveUserId);
        onSuccess && onSuccess(true);
      }
    } catch (e) {
      handleIsAuthenticated(false, ``);
      onSuccess && onSuccess(false);
    }
  };

  return {
    login,
    logout,
    getProviders,
    restoreSession,
    handleIsAuthenticated,
    handleRedirectionLogin,
    checkVideoTokenAccess,
  };
};
