/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { LoginData } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import {
  DALTON_AUTH_TOKEN,
  EMAIL_VERIFICATION_STATUS,
  Entitlements,
  loadData,
  PreAuthzs,
  Preferences,
  TVE_TOOLBOX_AUTH_TOKEN,
  TveProvider,
  User,
  USER_EMAIL_STATUS,
} from '@warnermmedia/gsp-core/sdk/data-access';
import { isEmpty } from 'lodash';
import { carouselBase } from '@warnermmedia/gsp-core/brands/estadio/feature';

export type loginAPIState = {
  login: LoginData;

  isFetchingLoginData: boolean;

  lastLoginEmail: string | null;

  lastLoginTimestamp: string | null;

  lastLogoutTimestamp: string | null;

  lastLoginToken: string | null;

  isLoggedOut: boolean;

  authToken: string | null;

  isLogged: boolean;

  user: User | null;

  isNewUser: boolean;

  checkBillingProfile: boolean;

  checkUserEmail: boolean;

  preferences: Preferences | null;

  DALTON_AUTH_TOKEN: string;

  TVE_TOOLBOX_AUTH_TOKEN: string;

  entitlements: null | Entitlements;

  preAuthorizedEntitlements: PreAuthzs[];

  isUserEmailVerified: boolean | null;

  checkingBillingProfile: boolean;

  gettingPreferences: boolean;

  preferencesFetched: boolean;

  preferencesProcessed: boolean;

  fetchingUser: boolean;

  userHasSubscription: boolean | null;

  competitors: carouselBase[] | null;

  isTveAuth: boolean;

  tveUserId: string | null;

  tveProvider: TveProvider | null;
};

export const LOGINAPI_STATE_INITIAL: loginAPIState = {
  login: {
    login: '',
  },

  isFetchingLoginData: false,

  lastLoginEmail: null,

  lastLoginTimestamp: null,

  lastLogoutTimestamp: null,

  lastLoginToken: null,

  isLoggedOut: true,

  authToken: loadData(TVE_TOOLBOX_AUTH_TOKEN) || loadData(DALTON_AUTH_TOKEN),

  isLogged: !isEmpty(loadData(TVE_TOOLBOX_AUTH_TOKEN)) || !isEmpty(loadData(DALTON_AUTH_TOKEN)),

  user: null,

  isNewUser: false,

  preferences: null,

  checkBillingProfile: true,

  checkUserEmail: true,

  DALTON_AUTH_TOKEN,

  TVE_TOOLBOX_AUTH_TOKEN,

  entitlements: null,

  preAuthorizedEntitlements: [],

  isUserEmailVerified: loadData(USER_EMAIL_STATUS)
    ? loadData(USER_EMAIL_STATUS) === EMAIL_VERIFICATION_STATUS.CONFIRMED
    : null,

  checkingBillingProfile: false,

  gettingPreferences: false,

  preferencesFetched: false,

  preferencesProcessed: false,

  fetchingUser: false,

  userHasSubscription: null,

  competitors: null,

  isTveAuth: false,

  tveUserId: null,

  tveProvider: null,
};
