export interface Country {
  countryCode: string;
  country: string;
  flagURL: string;
}

const countries: Array<Country> = [
  {
    countryCode: 'AR',
    country: 'Argentina',
    flagURL: 'https://gsp-static-cdn.wmsports.io/estadio/images/flags/AR.svg',
  },
  {
    countryCode: 'BO',
    country: 'Bolivia',
    flagURL: 'https://gsp-static-cdn.wmsports.io/estadio/images/flags/BO.svg',
  },
  {
    countryCode: 'BR',
    country: 'Brasil',
    flagURL: 'https://gsp-static-cdn.wmsports.io/estadio/images/flags/BR.svg',
  },
  {
    countryCode: 'CL',
    country: 'Chile',
    flagURL: 'https://gsp-static-cdn.wmsports.io/estadio/images/flags/CL.svg',
  },
  {
    countryCode: 'CO',
    country: 'Colombia',
    flagURL: 'https://gsp-static-cdn.wmsports.io/estadio/images/flags/CO.svg',
  },
];

export default countries;
