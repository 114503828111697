import React from 'react';
import { ButtonProps, CustomButton } from '@warnermmedia/gsp-core/sdk/ui';
import { GestureResponderEvent, TextStyle, ViewStyle } from 'react-native';
import { languageStrings } from '@warnermmedia/gsp-core/brands/estadio/feature';
import { useTheme } from 'react-native-paper';

interface CustomButtonProps extends ButtonProps {
  disabled?: boolean;
  disabledBgColor?: string;
  disabledLabelColor?: string;
  btnColor?: string;
  onPress: (e?: Event | GestureResponderEvent) => void;
  label?: string;
  btnStyle?: ViewStyle;
  outlined?: boolean;
  size?: string;
  labelStyle?: TextStyle;
  mode?: 'text' | 'contained' | 'outline';
  loading?: boolean;
  children?: React.ReactNode;
  focusKey?: string;
}

interface StyleMapValues {
  maxHeight?: number;
  fontSize?: number;
}

interface StyleMap {
  [key: string]: StyleMapValues;
}

const sizeStyleMap: StyleMap = {
  small: {
    maxHeight: 22,
  },
  medium: {
    maxHeight: 54,
  },
  high: {
    maxHeight: 80,
  },
};

const sizeStyleLabelMap: StyleMap = {
  small: {
    fontSize: 11,
  },
};

export const EstadioButton = ({
  disabled = false,
  disabledBgColor,
  disabledLabelColor,
  btnColor,
  onPress,
  label,
  btnStyle,
  outlined,
  size,
  labelStyle,
  loading,
  mode = 'contained',
  children,
  focusKey,
  focusable = true,
  icon,
  iconPosition,
}: CustomButtonProps) => {
  const { colors } = useTheme();
  const outlinedStyle = {
    backgroundColor: 'transparent',
    borderColor: disabled ? colors.tenantBackground.light.surfaceFilm : colors.keyboardFocus.light,
    borderWidth: 2,
  };

  return (
    <CustomButton
      mode={mode}
      btnStyle={[
        {
          backgroundColor: disabled
            ? disabledBgColor || colors.fill.action.accent01Pressed
            : btnColor || colors.fill.action.accent01,
          borderWidth: 0,
        },
        btnStyle ? btnStyle : {},
        outlined ? outlinedStyle : {},
        size ? sizeStyleMap[size] : {},
      ]}
      labelStyle={[
        size ? sizeStyleLabelMap[size] : {},
        disabled ? { color: disabledLabelColor || 'rgba(255, 255, 255, 0.5)' } : {},
        labelStyle ? labelStyle : {},
      ]}
      onPress={onPress}
      label={loading ? languageStrings.default.loadingText : label}
      children={children ? children : null}
      disabled={disabled}
      focusKey={focusKey}
      focusable={focusable}
      icon={icon}
      iconPosition={iconPosition}
    />
  );
};

export default EstadioButton;
