import React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';

/* eslint-disable-next-line */
export interface FacebookProps {
  width?: number;
  height?: number;
  color?: string;
  iconColor?: string;
}

export function Facebook(props: FacebookProps) {
  const width = props.width || 20;
  const height = props.height || 20;
  const color = props.color || '#359fd7';
  const iconColor = props.iconColor || '#fff';

  return (
    <Svg fill="none" height={height} viewBox="0 0 20 20" width={width}>
      <Circle cx="10" cy="10" r="10" fill={color} />
      <Path
        fill={iconColor}
        d="M11.088 6.66H12V5.07c-.157-.021-.699-.07-1.33-.07-1.316 0-2.217.828-2.217 2.35v1.4H7v1.777h1.453V15h1.78v-4.472h1.394l.221-1.778h-1.615V7.526c0-.514.139-.866.854-.866z"
      />
    </Svg>
  );
}

export default Facebook;
