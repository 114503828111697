import React, { useEffect, useState } from 'react';
import { Query } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { useIsMountedRef } from '../useIsMountedRef';
import { QueryVariablesType } from './useFetchPageTypes';
import useCMSQueryData from './useCMSQueryData';
import { useFetchPageData } from './useFetchPageData';

interface QueryResult<T> {
  pageData: T | undefined;
  loading: boolean;
}

export function useCMSQueryDataResults<T>(queryOptions: QueryVariablesType): QueryResult<T> {
  const [pageData, setPageData] = useState<T>();
  const [pageQueryData, setPageQueryData] = useState<Query>();
  const [loading, setLoading] = useState<boolean>(false);
  const isMountedRef = useIsMountedRef();
  const pageUriQueryData = useFetchPageData(queryOptions);
  const pageUriData = useCMSQueryData<T>(queryOptions.queryName, pageQueryData);

  useEffect(() => {
    if (isMountedRef.current) {
      pageUriQueryData && setPageQueryData(pageUriQueryData);
      !pageUriQueryData && setLoading(true);
    }
  }, [pageUriQueryData, isMountedRef]);

  useEffect(() => {
    if ((pageUriData || pageUriData === null) && isMountedRef.current) {
      setPageData(pageUriData);
    }
  }, [pageUriData, isMountedRef]);

  useEffect(() => {
    if ((pageData || pageData === null) && isMountedRef.current) {
      setLoading(false);
    }
  }, [pageData, isMountedRef]);

  return { pageData, loading };
}

export default useCMSQueryDataResults;
