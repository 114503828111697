import React, { useEffect, useRef, useState } from 'react';
import { ScrollView, StyleProp, TextStyle, View, ViewStyle } from 'react-native';
import { IconButton, useTheme } from 'react-native-paper';
import {
  Dalton,
  getContentTitle,
  languageStrings,
  ScreenEventType,
  sortChargesByDate,
  useGoBack,
  useMparticleScreenEvent,
  useScrollToTop,
} from '@warnermmedia/gsp-core/brands/estadio/feature';
import { breakpointsStateStore, SortDirections } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { formatDateDDMMYYYY, MetaTags, TextContent, useGetDevice } from '@warnermmedia/gsp-core/sdk/ui';

import { getStyles } from './billingInfo.styles';
import { useReactiveVar } from '@apollo/client';
import { EstadioButton } from '../components';
import LayoutWrapper, { LayoutType, TitleType } from '../layoutWrapper/layoutWrapper';
import get from 'lodash/get';
import { ScrollViewRefContext } from '../layout';
import { ChargeType, FocusKeys } from '@warnermmedia/gsp-core/sdk/data-access';
import { SkeletonDisplay } from '../skeletonDisplay';

export enum PaymentMethod {
  BANK = 'BANK',
  DIRECT_DEPOSIT = 'DIRECT_DEPOSIT',
  DEBIT = 'DEBIT',
  CREDIT = 'CREDIT',
  COUPON = 'COUPON',
}

type BillingLabelsType = {
  dateOfPurchaseLabel?: string;
  amountLabel?: string;
  productTypeLabel?: string;
  expirationDateLabel?: string;
  paymentMethodLabel?: string;
};

export interface BillingInfoProps {
  title: string;
  containerStyle?: StyleProp<ViewStyle>;
  tableContainerStyle?: StyleProp<ViewStyle>;
  rowWrapperStyle?: StyleProp<ViewStyle>;
  rowHeaderStyle?: StyleProp<TextStyle>;
  rowDetailStyle?: StyleProp<TextStyle>;
  labels?: BillingLabelsType;
}

const PAGE_SIZE = 10;

export const BillingInfo = ({
  title,
  containerStyle,
  tableContainerStyle,
  rowWrapperStyle,
  rowHeaderStyle,
  rowDetailStyle,
  labels = {},
}: BillingInfoProps) => {
  const dalton = Dalton();
  const [pageControl, setPageControl] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [tableItems, setTableItems] = useState<ChargeType[]>([] as ChargeType[]);
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const scrollViewRef = useRef();
  const { colors } = useTheme();
  const styles = getStyles(breakpoints, tableItems.length >= PAGE_SIZE, colors);
  const { isMobileDevice, isWeb } = useGetDevice();
  const isMobile = isMobileDevice || breakpoints.windowWidth <= 768;
  const { scrollToTop } = useScrollToTop(ScrollViewRefContext);
  useMparticleScreenEvent(ScreenEventType.Billing, title);
  const { goBack } = useGoBack();

  const handleGetTransactions = async (pageControl: number) => {
    setLoading(true);
    await dalton.getUserTransactions(
      (data: ChargeType[]) => {
        const newCharges = sortChargesByDate(data, SortDirections.Desc).map((item: ChargeType) => {
          item['product'] = item.products[0].description;
          item.chargeTimestamp = formatDateDDMMYYYY(item.chargeTimestamp);
          return item;
        });
        setTableItems(newCharges);
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    if (tableItems.length) {
      let pages = Math.floor(tableItems.length / PAGE_SIZE);
      if (tableItems.length % PAGE_SIZE > 0) pages = pages + 1;

      setTotalPages(pages);
      scrollToTop();
    }
  }, [tableItems.length]);

  useEffect(() => {
    handleGetTransactions(pageControl);
  }, [pageControl]);

  const billingProps: (keyof ChargeType)[] = ['product', 'amount', 'chargeTimestamp', 'paymentType'];

  if (!tableItems) {
    return null;
  }

  const {
    productTypeLabel = 'Producto',
    amountLabel = 'Monto',
    dateOfPurchaseLabel = 'Fecha de Transacción',
    paymentMethodLabel = 'Forma de Pago',
  } = labels;

  const header = [productTypeLabel, amountLabel, dateOfPurchaseLabel, paymentMethodLabel];

  const handlePageControlClick = (newPage: number) => {
    setPageControl(newPage);
    scrollToTop();
  };

  const getHeader = () => {
    return (
      <View style={{ flex: 1 }}>
        <View style={styles.headerWrapper}>
          {header.map(function (key, index) {
            return (
              <View key={`header-${index}`} style={{ flex: 1 }}>
                <TextContent style={[styles.titleHeader, { textAlign: 'left' }, rowHeaderStyle]}> {key} </TextContent>
              </View>
            );
          })}
        </View>
        <View style={styles.headerBar} />
      </View>
    );
  };

  const RenderRow = (props: { rowData: (string | number)[] }) => {
    return (
      <>
        {props.rowData.map((key: string | number, index: number) => {
          return (
            <TextContent key={index} style={[styles.billingText, { flex: 1 }, rowDetailStyle]}>
              {key}
            </TextContent>
          );
        })}
      </>
    );
  };

  const getRowsData = () => {
    const items: ChargeType[] = tableItems.slice(pageControl * PAGE_SIZE - PAGE_SIZE, pageControl * PAGE_SIZE);

    if (isMobile) {
      return items.map((item: ChargeType, index: number) => (
        <View key={`billingRowOut-${index}`} style={styles.groupWrapper}>
          {billingProps.map((key: string, index) => {
            const value = get(item, key, '');
            const displayValue =
              key === 'paymentType' && value === null
                ? languageStrings.default.paymentTypeCoupon
                : key === 'amount'
                ? value === 0
                  ? ''
                  : `${get(item, 'currency', '')} ${value}`
                : value;

            return (
              <View key={`billingRowm-${index}`} style={[styles.rowWrapper, rowWrapperStyle]}>
                <TextContent style={styles.rowLabel}>{header[index]}</TextContent>
                <TextContent style={styles.rowData}>{displayValue}</TextContent>
              </View>
            );
          })}
        </View>
      ));
    }
    return items.map((row, index) => {
      const rowData: (string | number)[] = [];
      billingProps.map((label, num) => {
        if (label === 'amount') {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          rowData[num] = `${row.currency} ${row[label] || ' '}`;
        } else {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          rowData[num] = row[label] || ' ';
        }

        if (row.amount === 0 && label === 'amount' && row.paymentType === null) {
          rowData[num] = ' ';
        }

        if (row.amount === 0 && label === 'paymentType' && row.paymentType === null) {
          rowData[num] = languageStrings.default.paymentTypeCoupon;
        }
        return label;
      });

      return (
        <View key={`billingRow-${index}`} style={[styles.rowWrapper, rowWrapperStyle]}>
          <RenderRow key={`rowData-${index}`} rowData={rowData} />
        </View>
      );
    });
  };

  const renderSimpleControl = () => {
    const items = new Array(totalPages);
    return items.map((item, index) => (
      <TextContent
        key={`control-${index}`}
        style={pageControl === 1 ? styles.activePageControlItem : styles.pageControlItem}
        onPress={() => handlePageControlClick(item + 1)}
      >
        {item + 1}
      </TextContent>
    ));
  };

  const renderFullControl = () => {
    const STEP_CONTROL = 3;
    const DISPLAY_RANGE = 2;
    const items = Array.from(Array(totalPages).keys());
    const itemsToRender = [];
    if (pageControl - STEP_CONTROL >= 1) {
      itemsToRender.push(
        <TextContent
          key="control-item-first"
          style={pageControl === 1 ? styles.activePageControlItem : styles.pageControlItem}
          onPress={() => handlePageControlClick(1)}
        >
          1
        </TextContent>
      );
      itemsToRender.push(
        <TextContent key="control-item-mid" style={styles.pageControlItem}>
          ...
        </TextContent>
      );
      items.slice(pageControl - DISPLAY_RANGE, pageControl + DISPLAY_RANGE).map((item, index) => {
        itemsToRender.push(
          <TextContent
            key={`control-item-${index}`}
            style={pageControl === item + 1 ? styles.activePageControlItem : styles.pageControlItem}
            onPress={() => handlePageControlClick(item + 1)}
          >
            {String(item + 1)}
          </TextContent>
        );
      });
    } else {
      items.slice(0, STEP_CONTROL).map((item, index) => {
        itemsToRender.push(
          <TextContent
            key={`control-item-${index}`}
            style={pageControl === item + 1 ? styles.activePageControlItem : styles.pageControlItem}
            onPress={() => handlePageControlClick(item + 1)}
          >
            {String(item + 1)}
          </TextContent>
        );
      });
    }

    if (pageControl + STEP_CONTROL <= totalPages || (pageControl <= STEP_CONTROL && totalPages > STEP_CONTROL)) {
      itemsToRender.push(
        <TextContent key="control-item-mid-last" style={styles.pageControlItem}>
          ...
        </TextContent>
      );
      itemsToRender.push(
        <TextContent
          key="control-item-last"
          style={pageControl === totalPages ? styles.activePageControlItem : styles.pageControlItem}
          onPress={() => handlePageControlClick(totalPages)}
        >
          {String(totalPages)}
        </TextContent>
      );
    }

    return itemsToRender.map((view) => view);
  };

  const renderPageControl = () => {
    if (tableItems.length <= PAGE_SIZE) {
      return null;
    }
    return (
      <View style={styles.controlWrapper}>
        <IconButton
          icon="chevron-left"
          size={20}
          onPress={() => handlePageControlClick(pageControl - 1)}
          disabled={pageControl === 1}
        />
        {totalPages > 1 ? renderFullControl() : renderSimpleControl()}
        <IconButton
          icon="chevron-right"
          size={20}
          onPress={() => handlePageControlClick(pageControl + 1)}
          disabled={pageControl === totalPages}
        />
      </View>
    );
  };

  const ListComponent = isMobileDevice ? View : ScrollView;

  return loading ? (
    <SkeletonDisplay route="/profile/billing" />
  ) : (
    <LayoutWrapper layoutType={LayoutType.Full} titleType={TitleType.Left} pageTitle={title}>
      {isWeb && <MetaTags title={getContentTitle(title)} />}
      <View style={[styles.container, containerStyle]}>
        <ListComponent
          /* eslint-disable-next-line */
          /* @ts-ignore: Using refs. */
          ref={scrollViewRef}
          horizontal
          contentContainerStyle={[styles.tableContainer, tableContainerStyle]}
        >
          <View style={styles.dataContainer}>
            {!isMobile && <View style={styles.rowWrapper}>{getHeader()}</View>}
            {getRowsData()}
          </View>
        </ListComponent>
        <View style={styles.footerContainer}>
          {renderPageControl()}
          <EstadioButton
            label={languageStrings.default.returnLabel}
            onPress={goBack}
            btnStyle={styles.backBtn}
            labelStyle={styles.goBackButton}
            focusKey={FocusKeys.BILLING}
          />
        </View>
      </View>
    </LayoutWrapper>
  );
};

export default BillingInfo;
