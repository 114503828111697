import React, { memo, useRef } from 'react';
import { Calendar, Play, Refresh } from '@warnermmedia/gsp-core/brands/estadio/assets';
import { CardProps, useSpatialNavigation, widthHeight } from '@warnermmedia/gsp-core/sdk/ui';
import { Image, ImageBackground, Pressable, Text, View } from 'react-native';
import { Cta } from '../../cta/cta';
import { Team } from '../../team/team';
import { getStyles } from './heroCard.styles';
import {
  CardDefaultBackground,
  carouselBase,
  getImageWithStyle,
  getUniqueId,
  useCardItemImage,
  useReducedHeroWidth,
  useUpcomingEventCountdown,
} from '@warnermmedia/gsp-core/brands/estadio/feature';
import isEqual from 'lodash/isEqual';
import { ItemName } from '@warnermmedia/gsp-core/sdk/data-access';

const HeroCard = ({
  item,
  laneWidth,
  action,
  cardBg,
  shouldShowPlayIcon,
  breakpoints,
  colors,
  device,
  dataLength = 0,
  index,
  focusKey,
  parentFocusKey,
  currentIndex,
  updateIndex,
}: CardProps<carouselBase>) => {
  const styles = getStyles(breakpoints, device.isTv, colors);
  const { getCardImageSource } = useCardItemImage();

  const teamLogoSize: widthHeight =
    breakpoints.currentBreakpoints.isTiny ||
    breakpoints.currentBreakpoints.isTnySm ||
    breakpoints.currentBreakpoints.isSmMed
      ? {
          width: 125,
          height: 125,
        }
      : {
          width: 150,
          height: 150,
        };

  const playLogoSize: widthHeight =
    breakpoints.currentBreakpoints.isTiny ||
    breakpoints.currentBreakpoints.isTnySm ||
    breakpoints.currentBreakpoints.isSmMed ||
    breakpoints.currentBreakpoints.isMedLg ||
    breakpoints.windowWidth === breakpoints.breakpointSizes.lg
      ? {
          height: 50,
          width: 50,
        }
      : {
          height: 75,
          width: 75,
        };

  const ctaLogoSize: widthHeight =
    breakpoints.currentBreakpoints.isTiny ||
    breakpoints.currentBreakpoints.isTnySm ||
    breakpoints.currentBreakpoints.isSmMed ||
    breakpoints.currentBreakpoints.isMedLg ||
    breakpoints.windowWidth === breakpoints.breakpointSizes.lg
      ? {
          height: 20,
          width: 20,
        }
      : {
          height: 30,
          width: 30,
        };

  const status = item?.status;
  const isLive = status === 3;
  const playIcon = isLive || shouldShowPlayIcon;
  const ctaColor = isLive ? colors.tenantBackground.light.surfaceBase : colors.fill.action.accent02;
  const iconColor = isLive ? colors.fill.action.accent01 : colors.tenantBackground.dark.surface04;
  const backgroundColor = isLive ? colors.fill.action.accent01 : colors.tenantBackground.dark.surface04;
  const CtaIcon = status === 4 || item?.callToAction?.icon === 'refresh' ? Refresh : isLive ? Play : Calendar;
  const reducedLaneWidth = useReducedHeroWidth(laneWidth, 160, 70, 60, 50);
  const eventLightLogo = item?.match?.eventLightLogo;
  const eventDarkLogo = item?.match?.eventDarkLogo;
  const heroFeaturedImage = item.type === 'video' && item.image ? item.image : eventLightLogo ? eventLightLogo : null;
  const heroImage = heroFeaturedImage
    ? getCardImageSource({
        image: getImageWithStyle(heroFeaturedImage),
      })
    : null;
  const heroBg = getCardImageSource({
    fallbackName: cardBg ? cardBg : CardDefaultBackground.Hero,
    image: getImageWithStyle(eventDarkLogo),
  });

  const countdown = useUpcomingEventCountdown(status === 2, item.scheduled_utc, item.type);
  const heroFocusKey = useRef(focusKey ?? getUniqueId(ItemName.HERO_ITEM)).current;
  const { ref, onCarouselCardArrowPress } = useSpatialNavigation({
    focusKey: heroFocusKey,
    onEnterPress: () => {
      action?.(item);
    },
    onFocus: () => {
      currentIndex !== index && device.isPwa && updateIndex?.(index);
    },
    onArrowPress: (direction, props, details): boolean => {
      return onCarouselCardArrowPress(direction, props, details, index, parentFocusKey, dataLength);
    },
  });

  return (
    <Pressable onPress={() => (action ? action(item) : null)} nativeID={heroFocusKey} ref={ref}>
      <ImageBackground source={heroBg} style={[styles.slide, { width: reducedLaneWidth }]} imageStyle={styles.slideBg}>
        <View style={styles.slideLeft}>
          {item.match && item.match.homeTeam && item.match.awayTeam && !heroImage && (
            <View style={styles.matchImages}>
              <Team
                team={item.match.homeTeam}
                width={teamLogoSize.width}
                height={teamLogoSize.height}
                teamLogoStyle={styles.teamLogoStyle}
                hideTricode={true}
              />
              <Team
                team={item.match.awayTeam}
                width={teamLogoSize.width}
                height={teamLogoSize.height}
                teamLogoStyle={styles.teamLogoStyle}
                hideTricode={true}
              />
            </View>
          )}
          {heroImage && (
            <View style={[styles.episodeImageContain]}>
              <Image source={heroImage} style={styles.episodeImage} />
            </View>
          )}
        </View>
        {playIcon && (
          <View style={styles.playButton}>
            <Play
              color={colors.tenantBackground.light.surfaceBase}
              iconColor={colors.tenantBackground.light.surfaceBase}
              opacity={0.2}
              height={playLogoSize.height}
              width={playLogoSize.width}
            />
          </View>
        )}
        <View style={styles.slideRight}>
          <View style={styles.slideRightInner}>
            <View style={styles.ctaContain}>
              {item.callToAction && (
                <Cta
                  icon={
                    <CtaIcon
                      color={ctaColor}
                      iconColor={iconColor}
                      height={ctaLogoSize.height}
                      width={ctaLogoSize.width}
                    />
                  }
                  position="left"
                  text={countdown || item?.callToAction?.text}
                  textStyle={styles.ctaText}
                  textContainStyle={styles.ctaContainBox}
                  style={{ backgroundColor: backgroundColor }}
                ></Cta>
              )}
            </View>
            <View style={styles.textBox}>
              <Text style={styles.title} numberOfLines={2}>
                {item.title}
              </Text>
              {item.subtitle ? (
                <Text style={styles.subTitle} numberOfLines={1}>
                  {item.subtitle}
                </Text>
              ) : null}
              <Text style={styles.date} numberOfLines={1}>
                {item.heading}
              </Text>
            </View>
          </View>
        </View>
      </ImageBackground>
    </Pressable>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default memo(HeroCard, (prevProps: any, nextProps: any) => isEqual(prevProps, nextProps));
