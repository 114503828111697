import { PagesUrl, QueryNames, useCMSQueryDataResults } from '@warnermmedia/gsp-core/brands/estadio/feature';
import { CMSPageBuilder, SkeletonDisplay } from '@warnermmedia/gsp-core/brands/estadio/ui';
import React from 'react';
import { View } from 'react-native';
import isEqual from 'lodash/isEqual';
import { Page } from '@warnermmedia/gsp-core/brands/estadio/data-access';

const Home = () => {
  const { pageData: pageUriData, loading } = useCMSQueryDataResults<Page>({
    queryName: QueryNames.GET_HOME_PAGE,
    uri: PagesUrl.Home,
  });

  return <View>{loading ? <SkeletonDisplay route="/home" /> : <CMSPageBuilder data={pageUriData} />}</View>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default React.memo(Home, (prevProps: any, nextProps: any) => isEqual(prevProps, nextProps));
