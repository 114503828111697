import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  checkbox: {
    height: 20,
    width: 20,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
  },
  unchecked: {
    borderWidth: 2,
    borderColor: '#000000',
  },
  checked: {
    backgroundColor: '#ffffff',
  },
  error: {
    borderColor: '#e31313',
  },
  icon: {
    width: '100%',
    height: '100%',
  },
});
