import { logMParticleEvent, MPARTICLE_EVENT_PUSH } from './mParticleEventHelper';
import EventEmitter from 'events';
import { MParticleAppConfigObject, MParticleCustomEventTypes } from './mParticleTypes';

interface MParticleEvent {
  eventName: MParticleCustomEventTypes;
  mParticleEventData: MParticleAppConfigObject;
}

export class MParticleEventProcessor {
  mParticleEventsQueue: MParticleEvent[] = [];
  eventEmitter: EventEmitter | null = null;

  constructor() {
    this.mParticleEventsQueue = [];
    this.eventEmitter = new EventEmitter();
  }

  pushMParticleEvent(
    eventNames: MParticleCustomEventTypes | MParticleCustomEventTypes[],
    mParticleEventData: MParticleAppConfigObject
  ): void {
    const eventNamesList = Array.isArray(eventNames) ? eventNames : [eventNames];
    eventNamesList.forEach((eventName: MParticleCustomEventTypes) => {
      this.mParticleEventsQueue.push({ eventName, mParticleEventData });
    });
    this.eventEmitter?.emit(MPARTICLE_EVENT_PUSH);
  }

  processMParticleEvents(): void {
    while (this.mParticleEventsQueue.length > 0) {
      const event: MParticleEvent | undefined = this.mParticleEventsQueue.shift();
      if (event) {
        logMParticleEvent(event.eventName, event.mParticleEventData);
      }
    }
  }
}

const mParticleEventProcessor = new MParticleEventProcessor();
Object.freeze(mParticleEventProcessor);
export default mParticleEventProcessor;
