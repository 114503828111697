export enum Placement {
  Left,
  Right,
}

export interface ComplexStat {
  value?: string;
  attempts: number;
  made: number;
  position: Placement;
}

export interface ComplexStat {
  value?: string;
  attempts: number;
  made: number;
  position: Placement;
}

export type SimpleStat = string | null | undefined;
export type Stat = SimpleStat | ComplexStat;

export function createAveragesLabel(stat: ComplexStat): string {
  // if attempts is zero or the percentage is a whole number, drop the tenths place decimal
  const percentLabel =
    ((stat.attempts === 0 ? 0 : stat.made / stat.attempts) * 100)
      .toFixed(stat.attempts === 0 || (stat.made * 100) % stat.attempts === 0 ? 0 : 1)
      .toString() + '%';

  if (stat.position === Placement.Left) {
    return `${stat.made}/${stat.attempts} (${percentLabel})`;
  } else {
    return `(${percentLabel}) ${stat.made}/${stat.attempts}`;
  }
}

export function createStatLabel(stat: Stat): string {
  if (stat != null) {
    if ((stat as ComplexStat).position != null) {
      return createAveragesLabel(stat as ComplexStat);
    } else if (typeof stat == 'string') {
      return stat as string;
    }
  }
  return '0';
}
