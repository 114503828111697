import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  wrapper: {
    width: '100%',
    height: '100%',
  },
  qrWrapper: {
    width: '100%',
    minHeight: 272,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 10,
    paddingHorizontal: 20,
    paddingVertical: 30,
  },
  qrNote: {
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  qrText: {
    paddingVertical: 10,
  },
  paymentWrapper: {
    height: '100%',
  },
});
