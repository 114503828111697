import React, { useEffect } from 'react';
import { View } from 'react-native';
import { Theme } from '@warnermmedia/gsp-core/brands/estadio/ui';
import materialCommunityFont from 'react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf';
import {
  AppApolloProvider,
  AppConfigProvider,
  EstadioProvider,
} from '@warnermmedia/gsp-core/brands/estadio/data-access';
import ServiceWorkerWrapper from './serviceWorkerWrapper/serviceWorkerWrapper';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import Routes from './navigation/Routes';
import { PreLoadFonts } from './components/PreLoadFonts';
import * as serviceWorker from '../serviceWorkerRegistration';
import ZendeskInc from './components/ZendeskInc/ZendeskInc';
import { useAllKeyMaps, useGetDevice, useWebAppsVersion } from '@warnermmedia/gsp-core/sdk/ui';

import { init, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { FocusKeys } from '@warnermmedia/gsp-core/sdk/data-access';

init({
  shouldFocusDOMNode: true,
});

const App = (): JSX.Element => {
  const [showWrapper, setShowWrapper] = React.useState(false);
  const isPwa = true;
  const { device, isWeb } = useGetDevice();
  const { setFocus } = useFocusable();
  useWebAppsVersion();
  useAllKeyMaps();

  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    setShowWrapper(true);
  };

  useEffect(() => {
    if (showWrapper) {
      setFocus(FocusKeys.UPDATE_MODAL);
    }
  }, [setFocus, showWrapper]);

  useEffect(() => {
    serviceWorker.register({ onUpdate: onSWUpdate });
  }, []);

  return (
    <View>
      {isWeb && <PreLoadFonts />}
      <AppConfigProvider>
        {isWeb && <ZendeskInc />}
        <AppApolloProvider>
          <EstadioProvider device={device}>
            <Theme>
              {isPwa ? (
                <style type="text/css">{`
                    @font-face {
                      font-family: 'MaterialCommunityIcons';
                      src: url(${materialCommunityFont}) format('truetype');
                    }
                  `}</style>
              ) : null}
              {showWrapper ? (
                <ServiceWorkerWrapper visible={showWrapper} />
              ) : (
                <SafeAreaProvider>
                  <Routes />
                </SafeAreaProvider>
              )}
            </Theme>
          </EstadioProvider>
        </AppApolloProvider>
      </AppConfigProvider>
    </View>
  );
};

export default App;
