import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import {
  languageStrings,
  QueryNames,
  TeamPagesUrl,
  useCMSQueryDataResults,
  useIsMountedRef,
} from '@warnermmedia/gsp-core/brands/estadio/feature';
import {
  CMSPageBuilder,
  LayoutType,
  LayoutWrapper,
  SkeletonDisplay,
  TitleType,
} from '@warnermmedia/gsp-core/brands/estadio/ui';
import { Page } from '@warnermmedia/gsp-core/brands/estadio/data-access';

export const TeamsComponents: React.FC = () => {
  const isMountedRef = useIsMountedRef();
  const [pageTitle, setPageTitle] = useState('');
  const { pageData: pageUriData, loading } = useCMSQueryDataResults<Page>({
    queryName: QueryNames.GET_TEAMS,
    uri: TeamPagesUrl.Teams,
  });

  useEffect(() => {
    if (pageUriData && isMountedRef.current) {
      const component = (pageUriData?.components ?? []).find((content) => content && content.id === 'screen-title');
      setPageTitle(component?.headline || languageStrings.default.teamsPage);
    }
  }, [pageUriData, isMountedRef]);

  return (
    <View>
      {!loading ? (
        <LayoutWrapper
          layoutType={LayoutType.Full}
          titleType={TitleType.Left}
          pageTitle={pageTitle}
          overrideChildrenWrapper={loading ? { paddingHorizontal: 0 } : {}}
        >
          <CMSPageBuilder data={pageUriData} />
        </LayoutWrapper>
      ) : (
        <SkeletonDisplay route="/teams" />
      )}
    </View>
  );
};

export default TeamsComponents;
