import React from 'react';
import { Image } from 'react-native';
import { IconSource } from 'react-native-paper/lib/typescript/components/Icon';

export const sample = {
  iconUri:
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADMAAAAzCAYAAAA6oTAqAAAAEXRFWHRTb2Z0d2FyZQBwbmdjcnVzaEB1SfMAAABQSURBVGje7dSxCQBACARB+2/ab8BEeQNhFi6WSYzYLYudDQYGBgYGBgYGBgYGBgYGBgZmcvDqYGBgmhivGQYGBgYGBgYGBgYGBgYGBgbmQw+P/eMrC5UTVAAAAABJRU5ErkJggg==',
  navbarIcon: () =>
    (
      <Image
        style={{ width: 20, height: 20 }}
        source={{
          uri: sample.iconUri,
        }}
      />
    ) as IconSource,
};
