export interface ModalStore {
  favTeamModal: boolean;
  couponModal: boolean;
  errorModal: boolean;
  openCastView: boolean;
  couponRedemptionModal: boolean;
  videoErrorModal: boolean;
  paymentRefreshModal: boolean;
  qrCodeModal: boolean;
  changesNotSavedModal: boolean;
  datePickerModal: boolean;
  appUpdateModal: boolean;
  discoverMaxModal: boolean;
}

export const MODAL_INITIAL_STATE: ModalStore = {
  favTeamModal: false,
  couponModal: false,
  errorModal: false,
  openCastView: false,
  couponRedemptionModal: false,
  videoErrorModal: false,
  paymentRefreshModal: false,
  qrCodeModal: false,
  changesNotSavedModal: false,
  datePickerModal: false,
  appUpdateModal: false,
  discoverMaxModal: false,
};

export enum ModalType {
  FavTeamModal = 'favTeamModal',
  CouponModal = 'couponModal',
  ErrorModal = 'errorModal',
  OpenCastView = 'openCastView',
  CouponRedemptionModal = 'couponRedemptionModal',
  VideoErrorModal = 'videoErrorModal',
  PaymentRefreshModal = 'paymentRefreshModal',
  QrCodeModal = 'qrCodeModal',
  ChangesNotSavedModal = 'changesNotSavedModal',
  AppUpdateModal = 'appUpdateModal',
  DiscoverMaxModal = 'discoverMaxModal',
}
