import { StyleProp, ViewStyle, StyleSheet, TextStyle, ImageStyle } from 'react-native';
import merge from 'lodash/merge';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';

interface Styles {
  teamCardLayout: ViewStyle;
  wrapper: ViewStyle;
  background: ImageStyle;
  teamLogoStyle: ImageStyle;
  overlay: ViewStyle;
  titleStyle: TextStyle;
}

export const getStyles = (breakpoints: Breakpoints, isTv: boolean, colors: ReactNativePaper.ThemeColors) => {
  const base: StyleProp<Styles> = {
    teamCardLayout: {
      borderRadius: 10,
    },
    wrapper: {
      marginRight: 20,
      marginTop: 10,
      marginBottom: 34,
      justifyContent: 'flex-start',
    },
    background: {
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 5,
    },
    teamLogoStyle: {
      height: '70%',
      width: '70%',
    },
    overlay: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      backgroundColor: colors.fill.action.accent02,
      opacity: 0.9,
      borderRadius: 5,
    },
    titleStyle: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Rubik-Medium',
      fontSize: 16,
      fontWeight: '500',
      lineHeight: 18,
      textTransform: 'capitalize',
      alignSelf: 'flex-start',
      marginLeft: 5,
      marginTop: 8,
      width: '90%',
    },
  };

  const mediaQueries = isTv
    ? ({
        teamCardLayout: {
          marginBottom: 15,
          marginRight: 5,
        },
        wrapper: {
          width: 200,
          marginRight: 8,
          marginLeft: 8,
          marginTop: 8,
          marginBottom: 33,
        },
        background: {
          width: 200,
          height: 200,
        },
        titleStyle: {
          marginTop: 16,
          fontSize: 20,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isTiny
    ? ({
        teamCardLayout: {
          width: '30%',
          marginBottom: 15,
        },
        wrapper: {
          marginRight: 4,
          marginLeft: 4,
          marginTop: 4,
        },
        background: {
          height: 95,
          width: '100%',
        },
        titleStyle: {
          width: '70%',
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isTnySm
    ? ({
        teamCardLayout: {
          width: '30%',
          marginBottom: 15,
        },
        wrapper: {
          marginRight: 4,
          marginLeft: 4,
          marginTop: 4,
        },
        background: {
          height: 103,
          width: '100%',
        },
        titleStyle: {
          width: '75%',
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isSmMed
    ? ({
        teamCardLayout: {
          marginBottom: 15,
        },
        wrapper: {
          width: 100,
          marginRight: 4,
          marginLeft: 4,
          marginTop: 4,
        },
        background: {
          width: 100,
          height: 100,
        },
        titleStyle: {
          width: '70%',
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isMedLg || breakpoints.windowWidth === breakpoints.breakpointSizes.lg
    ? ({
        teamCardLayout: {
          marginBottom: 15,
        },
        wrapper: {
          width: 101,
          marginRight: 4,
          marginLeft: 4,
          marginTop: 4,
          marginBottom: 33,
        },
        background: {
          width: 101,
          height: 101,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isLgXl ||
      breakpoints.currentBreakpoints.isXlXxl ||
      breakpoints.currentBreakpoints.isXxl
    ? ({
        teamCardLayout: {
          marginRight: 5,
          marginBottom: 15,
        },
        wrapper: {
          width: 111,
          marginRight: 4,
          marginLeft: 4,
          marginTop: 4,
          marginBottom: 33,
        },
        background: {
          width: 111,
          height: 111,
        },
      } as StyleProp<Styles>)
    : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
