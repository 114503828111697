import React, { useCallback } from 'react';
import { Text, View } from 'react-native';
import { getStyles } from './changesNotSavedModal.styles';
import { EstadioButton } from '../../components';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';
import { ModalType } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import ModalWrapper from '../modalWrapper';

interface ModalProps {
  title: string;
  visible: boolean;
  editingLabel: string;
  discardLabel: string;
  description: string;
  onEditing(): void;
  onDiscard(): void;
  breakpoints: Breakpoints;
  colors: ReactNativePaper.ThemeColors;
}

export const ChangesNotSavedModal = ({
  title,
  visible,
  description,
  editingLabel,
  discardLabel,
  onEditing,
  onDiscard,
  colors,
  breakpoints,
}: ModalProps) => {
  const styles = getStyles(breakpoints, colors);

  const onContinue = useCallback(() => {
    onEditing();
  }, [onEditing]);

  const onClose = useCallback(() => {
    onDiscard();
  }, [onDiscard]);

  return (
    <ModalWrapper
      visible={visible}
      modalType={visible ? ModalType.ChangesNotSavedModal : null}
      onClose={onClose}
      showCloseButton={true}
      modalLayoutContainerStyle={styles.fullScreenContainer}
      modalMainContainerStyle={styles.container}
    >
      <View style={styles.textContainer}>
        <Text style={styles.title}>{title}</Text>
        <View style={styles.containerInner}>
          <Text style={styles.description}>{description}</Text>
        </View>
        <EstadioButton
          mode="contained"
          label={editingLabel}
          onPress={onContinue}
          btnStyle={styles.buttonStyle}
          labelStyle={styles.buttonLabelStyle}
          focusKey={ModalType.ChangesNotSavedModal}
        />
        <EstadioButton
          mode="outline"
          outlined={true}
          label={discardLabel}
          onPress={onClose}
          btnStyle={styles.discardButtonStyle}
          labelStyle={styles.buttonLabelStyle}
        />
      </View>
    </ModalWrapper>
  );
};
