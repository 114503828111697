import React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';

/* eslint-disable-next-line */
export interface InstagramProps {
  width?: number;
  height?: number;
  color?: string;
  iconColor?: string;
}

export function Instagram(props: InstagramProps) {
  const width = props.width || 20;
  const height = props.height || 20;
  const color = props.color || '#000A5A';
  const iconColor = props.iconColor || '#fff';

  return (
    <Svg fill="none" height={height} viewBox="0 0 28 28" width={width}>
      <Circle cx="14" cy="14" r="14" fill={color} />
      <Path
        fill={iconColor}
        d="M16.2980836,7 C18.8948807,7 21,9.04032962 21,11.5572046 L21,11.5572046 L21,16.4427954 C21,18.9596704 18.8948807,21 16.2980836,21 L16.2980836,21 L11.7019164,21 C9.10513622,21 7,18.9596625 7,16.4427954 L7,16.4427954 L7,11.5572046 C7,9.04032962 9.10511927,7 11.7019164,7 L11.7019164,7 Z M16.2980836,8.09372911 L11.7019164,8.09372911 C9.72835049,8.09372911 8.12845994,9.64437953 8.12845994,11.5572046 L8.12845994,11.5572046 L8.12845994,16.4427954 C8.12845994,18.3556116 9.72836638,19.9062709 11.7019164,19.9062709 L11.7019164,19.9062709 L16.2980836,19.9062709 C18.2716495,19.9062709 19.8715401,18.3556205 19.8715401,16.4427954 L19.8715401,16.4427954 L19.8715401,11.5572046 C19.8715401,9.64437953 18.2716495,8.09372911 16.2980836,8.09372911 L16.2980836,8.09372911 Z M14.0000376,10.5021935 C15.9931587,10.5021935 17.6089027,12.0682094 17.6089027,13.9999878 C17.6089027,15.9317663 15.9931587,17.4977822 14.0000376,17.4977822 C12.0069165,17.4977822 10.3911726,15.9317663 10.3911726,13.9999878 C10.3911726,12.0682094 12.0069165,10.5021935 14.0000376,10.5021935 Z M14.0000376,11.5959226 C12.6301477,11.5959226 11.5196325,12.6722593 11.5196325,13.9999878 C11.5196325,15.3277164 12.6301477,16.404053 14.0000376,16.404053 C15.3699275,16.404053 16.4804427,15.3277164 16.4804427,13.9999878 C16.4804427,12.6722593 15.3699275,11.5959226 14.0000376,11.5959226 Z M17.6804219,9.61114458 C18.1393417,9.61114458 18.5113697,9.97172258 18.5113697,10.4165181 C18.5113697,10.8613136 18.1393417,11.2218916 17.6804219,11.2218916 C17.2215022,11.2218916 16.8494742,10.8613136 16.8494742,10.4165181 C16.8494742,9.97172258 17.2215022,9.61114458 17.6804219,9.61114458 Z"
      />
    </Svg>
  );
}

export default Instagram;
