import React, { useCallback, useContext, useMemo } from 'react';
import { GestureResponderEvent, Image, View } from 'react-native';
import { getStyles } from './header.styles';
import { Appbar, IconButton, useTheme } from 'react-native-paper';
import { IconMenu, ImageAssets, User } from '@warnermmedia/gsp-core/brands/estadio/assets';
import { BreakpointsSize, languageStrings, useScaleSizing } from '@warnermmedia/gsp-core/brands/estadio/feature';
import { breakpointsStateStore, HistoryContext } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { FocusKeys } from '@warnermmedia/gsp-core/sdk/data-access';
import { useReactiveVar } from '@apollo/client';
import { EstadioButton } from '../components';
import SidebarLogo from '../sidebarLogo/sidebarLogo';
import { DeviceTypes } from '@warnermmedia/gsp-core/sdk/ui';

interface HeaderProps {
  menuToggle?: () => void;
  showLoggedOutNavBar: boolean;
  currentPath: string;
  showMobileNav: boolean;
  navigateHome: (e?: Event | GestureResponderEvent) => void;
  showLoginButtonInHeader: boolean;
  devices: DeviceTypes;
}

export const Header = ({
  menuToggle,
  showLoggedOutNavBar,
  showMobileNav,
  showLoginButtonInHeader,
  navigateHome,
  devices,
}: HeaderProps) => {
  const { isWeb, isTv } = devices;
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const { colors } = useTheme();
  const styles = getStyles(breakpoints, colors);
  const logo = ImageAssets.closedNavLogo;
  const response = useMemo(() => (typeof logo === 'number' ? logo : { uri: logo }), [logo]);
  const historyContext = useContext(HistoryContext);
  const history = historyContext?.ready ? historyContext?.useHistory() : null;
  const scaling = useScaleSizing();

  const navigateLanding = useCallback(() => {
    history?.push('/');
  }, [history]);

  const LoggedOutNav = useCallback(() => {
    return (
      <Appbar style={styles.navbar}>
        <View style={styles.navIcon}>
          <View style={styles.logoContain}>
            {!isTv ? (
              <SidebarLogo goToHome={navigateLanding} devices={devices} shouldExpand={true} isHeader={true} />
            ) : (
              <SidebarLogo
                goToHome={() => null}
                devices={devices}
                shouldExpand={true}
                logoWidth={scaling.scaleWidth(248.88, BreakpointsSize.Tv)}
                logoHeight={scaling.scaleHeight(56, BreakpointsSize.Tv)}
              />
            )}
          </View>
        </View>
        {showLoginButtonInHeader && (
          <EstadioButton
            label={languageStrings.default.loginText}
            onPress={navigateHome}
            btnColor="transparent"
            btnStyle={styles.navButtonContainer}
            labelStyle={styles.navTitle}
            icon={<User color={'transparent'} height={isWeb ? 36 : 32} width={isWeb ? 40 : 30} />}
            iconPosition="left"
            focusKey={FocusKeys.HEADER_LOGIN_BUTTON}
          />
        )}
      </Appbar>
    );
  }, [
    isWeb,
    navigateHome,
    navigateLanding,
    showLoginButtonInHeader,
    styles.logoContain,
    styles.navButtonContainer,
    styles.navIcon,
    styles.navTitle,
    styles.navbar,
    devices,
  ]);

  const MobileAppbar = useCallback(() => {
    if (showMobileNav) {
      return (
        <View style={styles.menu}>
          <View style={styles.header}>
            <IconButton
              icon={() => <IconMenu color={colors.tenantBackground.light.surfaceBase} height={17.6} width={22} />}
              onPress={menuToggle}
            />
            <View style={styles.imageContainer}>
              <Image style={styles.mobileNavIconStyle} source={response} resizeMode={'contain'} />
            </View>
          </View>
        </View>
      );
    }
    return <View />;
  }, [
    showMobileNav,
    menuToggle,
    response,
    styles.header,
    styles.imageContainer,
    styles.menu,
    styles.mobileNavIconStyle,
    colors.tenantBackground.light.surfaceBase,
  ]);

  return showLoggedOutNavBar ? <LoggedOutNav /> : <MobileAppbar />;
};

export default Header;
