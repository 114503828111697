import React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';

/* eslint-disable-next-line */
export interface CompetitionProps {
  width?: number;
  height?: number;
  color?: string;
  iconColor?: string;
  detailColor?: string;
}

export function Competition(props: CompetitionProps) {
  const width = props.width || 20;
  const height = props.height || 20;
  const color = props.color || '#359fd7';
  const iconColor = props.iconColor || '#fff';
  const detailColor = props.detailColor || '#ff0085';

  return (
    <Svg fill="none" height={height} viewBox="0 0 20 20" width={width}>
      <Circle cx="10" cy="10" r="10" fill={color} />
      <Path
        fill={iconColor}
        d="M14.5 5.5H13V5H7v.5H5.5c-.3 0-.5.2-.5.5v1.2c0 1.15.85 2.1 2 2.25v.05c0 1.45 1 2.65 2.35 2.95L9 13.5H7.85c-.2 0-.4.15-.45.35L7 15h6l-.4-1.15c-.05-.2-.25-.35-.45-.35H11l-.35-1.05C12 12.15 13 10.95 13 9.5v-.05c1.15-.15 2-1.1 2-2.25V6c0-.3-.2-.5-.5-.5zM7 8.45C6.45 8.3 6 7.8 6 7.2v-.7h1v1.95zm7-1.25c0 .6-.45 1.15-1 1.25V6.5h1v.7z"
      />
      <Path
        fill={detailColor}
        d="M10 6l.494 1.32 1.408.062-1.103.878.377 1.358L10 8.84l-1.176.778.377-1.358-1.103-.878 1.408-.062L10 6z"
      />
    </Svg>
  );
}

export default Competition;
