import { RegisterAccountFormStyles } from '@warnermmedia/gsp-core/sdk/ui';

// normally this is Stylesheet.create but storybook is having issues merging

export const getStyles = (colors: ReactNativePaper.ThemeColors): RegisterAccountFormStyles => {
  return {
    formLabel: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Rubik-Medium',
      fontSize: 16,
      fontWeight: '500',
      paddingTop: 24,
    },
    submitButtonLabelStyle: {
      fontFamily: 'Oswald-Medium',
      fontSize: 20,
      fontWeight: '500',
    },
    submitButtonViewStyle: {
      backgroundColor: colors.fill.action.accent01,
    },
    formAcceptTextStyle: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Rubik-Medium',
      fontSize: 14,
      fontWeight: '500',
      paddingLeft: 16,
    },
    formErrorViewStyle: {
      backgroundColor: colors.stroke.onDark.outlineError,
      borderRadius: 3,
      paddingHorizontal: 8,
      paddingVertical: 4,
    },
    formErrorTextStyle: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Rubik-Medium',
      fontSize: 12,
      fontWeight: '500',
      lineHeight: 16,
    },
    errorsContainer: {
      backgroundColor: colors.stroke.onDark.outlineError,
      borderRadius: 3,
      marginTop: 8,
      paddingHorizontal: 8,
      paddingVertical: 4,
    },
    errorsBulletTextStyle: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Rubik-Medium',
      fontSize: 12,
      fontWeight: '500',
      lineHeight: 16,
    },
    errorsTextTextStyle: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Rubik-Medium',
      fontSize: 12,
      fontWeight: '500',
      lineHeight: 16,
    },
    consentMessageStyle: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Rubik-Medium',
      fontSize: 14,
      lineHeight: 16,
      fontWeight: '500',
    },
    formTitleTextStyle: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Oswald-SemiBold',
      fontSize: 22,
      fontWeight: '600',
      paddingBottom: 24,
      textTransform: 'uppercase',
    },
    formDirectionsTextStyle: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Rubik-Regular',
      fontSize: 16,
      paddingBottom: 8,
    },
  } as RegisterAccountFormStyles;
};
