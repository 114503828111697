/* eslint-disable @nrwl/nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { InMemoryCacheConfig, Reference, StoreObject } from '@apollo/client';
import { CMSAPIModels } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import {
  formatSortDate,
  formatVideoDuration,
  getCallToActionIcon,
  getCallToActionText,
  getEventHeading,
  getEventSubtitleData,
  getFormattedStatus,
  getGameTeam,
  getImageArray,
  getImageCardWidth,
  getSecondsDiff,
  sortContent,
  sortContentByWeight,
} from '@warnermmedia/gsp-core/brands/estadio/feature';
import uniqBy from 'lodash/uniqBy';

export const TypePolicies = {
  typePolicies: {
    Query: {
      keyArgs: false,
      fields: {
        getPageByUri: {
          read(existing: any) {
            return existing;
          },
        },
        getEventById: {
          read(existing: any) {
            return existing;
          },
        },
        getVideoByEventId: {
          read(existing: any) {
            return existing;
          },
        },
      },
    },
    Event: {
      keyFields: ['id'],
      fields: {
        _id: {
          read: () => {
            return null;
          },
        },
        key: {
          read: (_, { readField }) => {
            return readField('id');
          },
        },
        disclaimer: {
          read: () => {
            return null;
          },
        },
        isPayPalEnabled: {
          read: () => {
            return null;
          },
        },
        subtitle: {
          read: (_, { readField }) => {
            const tournament: Reference | StoreObject | undefined = readField('tournament');
            const seriesName = readField('series_name') as string;
            const tournamentName = (tournament ? readField('display_name', tournament) : '') as string;

            return getEventSubtitleData(tournamentName, seriesName);
          },
        },
        type: {
          read: (val) => {
            return val;
          },
        },
        heading: {
          read: (_, { readField }) => {
            const scheduled_utc: string = readField('scheduled_utc') ?? '';
            return getEventHeading(scheduled_utc);
          },
        },
        headline: {
          read: () => {
            return null;
          },
        },
        subHeadline: {
          read: () => {
            return null;
          },
        },
        home: {
          merge(existing: any, incoming: any, { mergeObjects }) {
            return mergeObjects(existing, incoming);
          },
        },
        away: {
          merge(existing: any, incoming: any, { mergeObjects }) {
            return mergeObjects(existing, incoming);
          },
        },
        tournament: {
          merge(existing: any, incoming: any, { mergeObjects }) {
            return mergeObjects(existing, incoming);
          },
        },
        match: {
          read: (_, { readField }) => {
            const home: CMSAPIModels.Competitor | undefined = readField('home');
            const away: CMSAPIModels.Competitor | undefined = readField('away');
            const game: CMSAPIModels.Game | undefined = readField('game');
            const score: CMSAPIModels.Score | undefined = readField('score', game);
            const sportRadarId: CMSAPIModels.Score | undefined = readField('sportradar_id');
            const sourceGameId: CMSAPIModels.Score | undefined = readField('sourceGameId', game);
            const status: string = readField('derived_status') ?? '';
            const gameStatus = getFormattedStatus(status);
            const eventId = (sportRadarId || sourceGameId)?.toString().split(':')[2];
            const homeTeam = getGameTeam(home, readField, score?.home);
            const awayTeam = getGameTeam(away, readField, score?.away);

            const eventLight: string | undefined = readField('logo_light');
            const eventDark: string | undefined = readField('logo_dark');

            const eventLightLogo = eventLight ? getImageArray(eventLight, getImageCardWidth(960, 640)) : null;
            const eventDarkLogo = eventDark ? getImageArray(eventDark, getImageCardWidth(1260, 688)) : null;

            return { homeTeam, awayTeam, eventId, gameStatus, eventLightLogo, eventDarkLogo };
          },
        },
        image: {
          read: (_, { readField }) => {
            const eventImage = readField('event_image');
            return eventImage && typeof eventImage === 'string'
              ? getImageArray(eventImage, getImageCardWidth(1260, 688))
              : null;
          },
        },
        mediaId: {
          read: (_, { readField }) => {
            const mediaLiveAsset: string = readField('media_asset_id_live') ?? '';
            const mediaVodAsset: string = readField('media_asset_id_vod') ?? '';
            const isLive: boolean | undefined = readField('is_live');
            const has_media: boolean | undefined = readField('has_media');

            // When an event has a VOD we always use it.  No matter what isLive is set to.
            if (has_media && mediaVodAsset) {
              return mediaVodAsset;
            }
            if (has_media && isLive && mediaLiveAsset) {
              return mediaLiveAsset;
            }
            return '';
          },
        },
        price: {
          read: () => {
            return null;
          },
        },
        productId: {
          read: () => {
            return null;
          },
        },
        sortDate: {
          read: (_, { readField }) => {
            const scheduled_utc: string = readField('scheduled_utc') ?? '';
            return formatSortDate(scheduled_utc);
          },
        },
        status: {
          read(existing: any, { readField }) {
            const derivedStatus: string = readField('derived_status') ?? '';
            return getFormattedStatus(derivedStatus);
          },
        },
        is_live: {
          read(existing: any) {
            return existing;
          },
        },
        has_media: {
          read(existing: any) {
            return existing;
          },
        },
        duration: {
          read: () => {
            return null;
          },
        },
        callToAction: {
          read: (_, { readField }) => {
            const scheduled_utc: string = readField('scheduled_utc') ?? '';
            const status: string = readField('derived_status') ?? '';
            const formattedStatus = getFormattedStatus(status);
            const text = getCallToActionText(scheduled_utc, formattedStatus);
            const icon = getCallToActionIcon(scheduled_utc, formattedStatus);
            return { text, icon };
          },
        },
        cta: {
          read: () => {
            return null;
          },
        },
        cardLabel: {
          read: () => {
            return null;
          },
        },
        sportRadarId: {
          read: () => {
            return null;
          },
        },
      },
    },
    Video: {
      keyFields: ['_id'],
      fields: {
        id: {
          read: () => {
            return null;
          },
        },
        key: {
          read: (_, { readField }) => {
            const id = readField('_id');
            return id;
          },
        },
        disclaimer: {
          read: () => {
            return null;
          },
        },
        isPayPalEnabled: {
          read: () => {
            return null;
          },
        },
        has_media: {
          read: () => {
            return false;
          },
        },
        subtitle: {
          read: () => {
            return '';
          },
        },
        type: {
          read: () => {
            return 'video';
          },
        },
        image: {
          read: (_, { readField }) => {
            const thumbnail = readField('thumbnail');
            return thumbnail && typeof thumbnail === 'string'
              ? getImageArray(thumbnail, getImageCardWidth(640, 320))
              : null;
          },
        },
        callToAction: {
          read: (_, { readField }) => {
            const created: string = readField('created') ?? '';
            const text = getCallToActionText(created);
            const icon = getCallToActionIcon(created);
            return { text, icon };
          },
        },
        cta: {
          read: () => {
            return null;
          },
        },
        is_live: {
          read: () => {
            return false;
          },
        },
        match: {
          read: () => {
            return null;
          },
        },
        heading: {
          read: () => {
            return null;
          },
        },
        price: {
          read: () => {
            return null;
          },
        },
        productId: {
          read: () => {
            return null;
          },
        },
        scheduled_utc: {
          read: () => {
            return null;
          },
        },
        status: {
          read: () => {
            return null;
          },
        },
        duration: {
          merge(existing: any, incoming: any) {
            if (incoming) {
              const formatted = formatVideoDuration(incoming);
              return incoming === existing ? existing : formatted;
            }
            return null;
          },
        },
        sortDate: {
          merge(existing: any, incoming: any) {
            if (incoming) {
              return formatSortDate(incoming, 'video');
            }
            return null;
          },
        },
        sportRadarId: {
          read: () => {
            return null;
          },
        },
      },
    },
    Show: {
      keyFields: ['id'],
      fields: {
        _id: {
          read: () => {
            return null;
          },
        },
        key: {
          read: (_, { readField }) => {
            const taxonomy: CMSAPIModels.TaxonomyTerm | undefined = readField('taxonomy');
            const parentId: string | undefined = readField('parentId', taxonomy);
            const id = readField('franchiseId');
            return parentId || id;
          },
        },
        disclaimer: {
          read: () => {
            return null;
          },
        },
        isPayPalEnabled: {
          read: () => {
            return null;
          },
        },
        subtitle: {
          read: () => {
            return '';
          },
        },
        type: {
          read: () => {
            return 'epg';
          },
        },
        heading: {
          read: () => {
            return '';
          },
        },
        headline: {
          read: () => {
            return '';
          },
        },
        has_media: {
          read: () => {
            return false;
          },
        },
        subHeadline: {
          read: () => {
            return '';
          },
        },
        taxonomy: {
          merge(existing: any, incoming: any, { mergeObjects }) {
            return mergeObjects(existing, incoming);
          },
          // Not sure why this is needed
          // Shouldn't read automatically default to the value?
          read: (taxonomy) => {
            return taxonomy;
          },
        },
        image: {
          read: (_, { readField }) => {
            const taxonomy: CMSAPIModels.TaxonomyTerm | undefined = readField('taxonomy');
            const image: CMSAPIModels.Image | undefined = readField('image', taxonomy);
            const tax_image = image?.url;
            return tax_image && typeof tax_image === 'string'
              ? getImageArray(tax_image, getImageCardWidth(640, 320))
              : null;
          },
        },
        is_live: {
          read: (_, { readField }) => {
            const status = readField('status');
            return status === 'current';
          },
        },
        match: {
          read: () => {
            return null;
          },
        },
        mediaId: {
          read: () => {
            return null;
          },
        },
        uuid: {
          read: () => {
            return null;
          },
        },
        cta: {
          read: () => {
            return null;
          },
        },
        cmsId: {
          read: () => {
            return null;
          },
        },
        callToAction: {
          read: () => {
            return null;
          },
        },
        price: {
          read: () => {
            return null;
          },
        },
        productId: {
          read: () => {
            return null;
          },
        },
        scheduled_utc: {
          read: (_, { readField }) => {
            return readField('airDateTime') ?? null;
          },
        },
        sortDate: {
          read: (_, { readField }) => {
            return readField('airDateTime') ?? null;
          },
        },
        status: {
          read: (status) => {
            return status === 'current' ? 3 : status === 'estimated' ? 2 : status === 'actual' ? 4 : 1;
          },
        },
        title: {
          read: (_, { readField }) => {
            return readField('originalTitle') ?? '';
          },
        },
        cardLabel: {
          read: (_, { readField }) => {
            return readField('is_live') ? 'live' : '';
          },
        },
        duration: {
          read: (_, { readField }) => {
            const begin = readField('begin') ?? '';
            const end = readField('end') ?? '';
            const showDuration = getSecondsDiff(begin as string, end as string);
            return showDuration && formatVideoDuration(showDuration);
          },
        },
        sportRadarId: {
          read: () => {
            return null;
          },
        },
      },
    },
    Series: {
      keyFields: ['id'],
      fields: {
        _id: {
          read: (_, { readField }) => {
            return readField('id') ?? '';
          },
        },
        key: {
          read: (_, { readField }) => {
            return readField('id') ?? '';
          },
        },
        type: {
          read: () => {
            return 'series';
          },
        },
        cta: {
          read: () => {
            return null;
          },
        },
        disclaimer: {
          read: () => {
            return null;
          },
        },
        subtitle: {
          read: () => {
            return '';
          },
        },
        image: {
          read: (_, { readField }) => {
            const taxonomy: CMSAPIModels.TaxonomyTerm | undefined = readField('taxonomy');
            const image: CMSAPIModels.Image | undefined = readField('image', taxonomy);
            const tax_image = image?.url;
            return tax_image && typeof tax_image === 'string'
              ? getImageArray(tax_image, getImageCardWidth(320, 160))
              : null;
          },
        },
        is_live: {
          read: () => {
            return null;
          },
        },
        isPayPalEnabled: {
          read: () => {
            return null;
          },
        },
        subHeadline: {
          read: () => {
            return null;
          },
        },
        headline: {
          read: (_, { readField }) => {
            return readField('series_name') ?? '';
          },
        },
        price: {
          read: () => {
            return null;
          },
        },
        productId: {
          read: () => {
            return null;
          },
        },
        has_media: {
          read: () => {
            return false;
          },
        },
        match: {
          read: () => {
            return null;
          },
        },
        mediaId: {
          read: () => {
            return null;
          },
        },
        callToAction: {
          read: (_, { readField }) => {
            const id: string | undefined = readField('id');
            const season: string | undefined = readField('season');
            return { url: id && season ? `/tournaments/${season}/${id}` : '' };
          },
        },
        heading: {
          read: () => {
            return null;
          },
        },
        scheduled_utc: {
          read: () => {
            return null;
          },
        },
        sortDate: {
          read: () => {
            return null;
          },
        },
        status: {
          read: () => {
            return null;
          },
        },
        title: {
          read: (_, { readField }) => {
            return readField('series_name') ?? '';
          },
        },
        cardLabel: {
          read: () => {
            return null;
          },
        },
        duration: {
          read: () => {
            return null;
          },
        },
        sportRadarId: {
          read: () => {
            return null;
          },
        },
      },
    },
    Product: {
      keyFields: ['_id'],
      fields: {
        id: {
          read: () => {
            return null;
          },
        },
        key: {
          read: (_, { readField }) => {
            const id = readField('_id');
            return id;
          },
        },
        subtitle: {
          read: () => {
            return '';
          },
        },
        type: {
          read: () => {
            return 'product';
          },
        },
        image: {
          read: () => {
            return null;
          },
        },
        is_live: {
          read: () => {
            return false;
          },
        },
        has_media: {
          read: () => {
            return false;
          },
        },
        match: {
          read: () => {
            return null;
          },
        },
        mediaId: {
          read: () => {
            return null;
          },
        },
        uuid: {
          read: () => {
            return null;
          },
        },
        cmsId: {
          read: () => {
            return null;
          },
        },
        callToAction: {
          read: () => {
            return null;
          },
        },
        heading: {
          read: () => {
            return null;
          },
        },
        scheduled_utc: {
          read: () => {
            return null;
          },
        },
        sortDate: {
          read: () => {
            return null;
          },
        },
        status: {
          read: () => {
            return null;
          },
        },
        title: {
          read: () => {
            return null;
          },
        },
        cardLabel: {
          read: () => {
            return null;
          },
        },
        duration: {
          read: () => {
            return null;
          },
        },
        sportRadarId: {
          read: () => {
            return null;
          },
        },
      },
    },
    Item: {
      keyFields: ['_id'],
      fields: {
        id: {
          read: (_, { readField }) => {
            return readField('_id') ?? '';
          },
        },
        key: {
          read: (_, { readField }) => {
            return readField('_id') ?? '';
          },
        },
        cta: {
          read: () => {
            return null;
          },
        },
        disclaimer: {
          read: () => {
            return null;
          },
        },
        subtitle: {
          read: () => {
            return '';
          },
        },
        type: {
          read: () => {
            return 'item';
          },
        },
        image: {
          read: (_, { readField }) => {
            const images: ReadonlyArray<CMSAPIModels.ImageWithStyle> | undefined = readField('images');
            return images ? getImageArray(images, getImageCardWidth(320, 160)) : null;
          },
        },
        is_live: {
          read: () => {
            return null;
          },
        },
        isPayPalEnabled: {
          read: () => {
            return null;
          },
        },
        subHeadline: {
          read: (_, { readField }) => {
            return readField('subheadline') ?? '';
          },
        },
        price: {
          read: () => {
            return null;
          },
        },
        productId: {
          read: () => {
            return null;
          },
        },
        has_media: {
          read: () => {
            return false;
          },
        },
        match: {
          read: () => {
            return null;
          },
        },
        mediaId: {
          read: () => {
            return null;
          },
        },
        uuid: {
          read: () => {
            return null;
          },
        },
        cmsId: {
          read: () => {
            return null;
          },
        },
        callToAction: {
          read: (_, { readField }) => {
            const link: CMSAPIModels.Link | undefined = readField('link');
            const url: string | undefined = readField('uri', link);
            return { url };
          },
        },
        heading: {
          read: () => {
            return null;
          },
        },
        scheduled_utc: {
          read: () => {
            return null;
          },
        },
        sortDate: {
          read: () => {
            return null;
          },
        },
        status: {
          read: () => {
            return null;
          },
        },
        title: {
          read: (_, { readField }) => {
            return readField('headline') ?? '';
          },
        },
        cardLabel: {
          read: () => {
            return null;
          },
        },
        duration: {
          read: () => {
            return null;
          },
        },
        sportRadarId: {
          read: () => {
            return null;
          },
        },
      },
    },
    Competitor: {
      keyFields: ['id'],
      fields: {
        _id: {
          read: (_, { readField }) => {
            return readField('id') ?? '';
          },
        },
        key: {
          read: (_, { readField }) => {
            return readField('id') ?? '';
          },
        },
        type: {
          read: () => {
            return 'team';
          },
        },
        cta: {
          read: () => {
            return null;
          },
        },
        disclaimer: {
          read: () => {
            return null;
          },
        },
        subtitle: {
          read: () => {
            return '';
          },
        },
        image: {
          read: (_, { readField }) => {
            const image: string | undefined = readField('logo_dark');
            return image ? getImageArray(image, getImageCardWidth(320, 160)) : null;
          },
        },
        is_live: {
          read: () => {
            return null;
          },
        },
        isPayPalEnabled: {
          read: () => {
            return null;
          },
        },
        subHeadline: {
          read: (_, { readField }) => {
            return null;
          },
        },
        headline: {
          read: (_, { readField }) => {
            return readField('name') ?? '';
          },
        },
        price: {
          read: () => {
            return null;
          },
        },
        productId: {
          read: () => {
            return null;
          },
        },
        has_media: {
          read: () => {
            return false;
          },
        },
        match: {
          read: () => {
            return null;
          },
        },
        mediaId: {
          read: () => {
            return null;
          },
        },
        callToAction: {
          read: (_, { readField }) => {
            const id: string | undefined = readField('id');
            return { url: id ? `/team/${id}` : '' };
          },
        },
        heading: {
          read: () => {
            return null;
          },
        },
        scheduled_utc: {
          read: () => {
            return null;
          },
        },
        sortDate: {
          read: () => {
            return null;
          },
        },
        status: {
          read: () => {
            return null;
          },
        },
        title: {
          read: (_, { readField }) => {
            return readField('name') ?? '';
          },
        },
        cardLabel: {
          read: () => {
            return null;
          },
        },
        duration: {
          read: () => {
            return null;
          },
        },
        sportRadarId: {
          read: (_, { readField }) => {
            return readField('sportradar_id') ?? '';
          },
        },
      },
    },
    ContentShow: {
      keyFields: ['id'],
      fields: {
        _id: {
          read: (_, { readField }) => {
            return readField('id') ?? '';
          },
        },
        key: {
          read: (_, { readField }) => {
            return readField('id') ?? '';
          },
        },
        type: {
          read: () => {
            return 'show';
          },
        },
        cta: {
          read: () => {
            return null;
          },
        },
        disclaimer: {
          read: () => {
            return null;
          },
        },
        subtitle: {
          read: () => {
            return '';
          },
        },
        image: {
          read: (image) => {
            return image?.url ? getImageArray(image.url, getImageCardWidth(640, 320)) : null;
          },
        },
        is_live: {
          read: () => {
            return null;
          },
        },
        isPayPalEnabled: {
          read: () => {
            return null;
          },
        },
        subHeadline: {
          read: (_, { readField }) => {
            return null;
          },
        },
        headline: {
          read: (_, { readField }) => {
            return readField('title') ?? '';
          },
        },
        price: {
          read: () => {
            return null;
          },
        },
        productId: {
          read: () => {
            return null;
          },
        },
        has_media: {
          read: () => {
            return false;
          },
        },
        match: {
          read: () => {
            return null;
          },
        },
        mediaId: {
          read: () => {
            return null;
          },
        },
        callToAction: {
          read: (_, { readField }) => {
            const id: string | undefined = readField('id');
            return { url: id ? `/show/${id}` : '' };
          },
        },
        heading: {
          read: () => {
            return null;
          },
        },
        scheduled_utc: {
          read: () => {
            return null;
          },
        },
        sortDate: {
          read: () => {
            return null;
          },
        },
        status: {
          read: () => {
            return null;
          },
        },
        cardLabel: {
          read: () => {
            return null;
          },
        },
        duration: {
          read: () => {
            return null;
          },
        },
        sportRadarId: {
          read: () => {
            return null;
          },
        },
      },
    },
    Component: {
      keyFields: ['_id'],
      fields: {
        allItems: {
          read: (
            _,
            { readField }
          ): ReadonlyArray<
            | CMSAPIModels.Video
            | CMSAPIModels.Event
            | CMSAPIModels.Product
            | CMSAPIModels.Show
            | CMSAPIModels.Series
            | CMSAPIModels.Item
            | CMSAPIModels.Competitor
            | CMSAPIModels.ContentShow
          > => {
            const content: ReadonlyArray<CMSAPIModels.Content> | undefined = readField('content');
            let allItemsArray: ReadonlyArray<
              | CMSAPIModels.Video
              | CMSAPIModels.Event
              | CMSAPIModels.Product
              | CMSAPIModels.Show
              | CMSAPIModels.Series
              | CMSAPIModels.Item
              | CMSAPIModels.Competitor
              | CMSAPIModels.ContentShow
            > = [];
            if (content && content.length > 0) {
              const sortedContent = sortContentByWeight(content as Array<CMSAPIModels.Content>, readField);
              sortedContent.forEach((item) => {
                const clipsRef: ReadonlyArray<CMSAPIModels.Video> | undefined = readField('clips', item);
                const eventsRef: ReadonlyArray<CMSAPIModels.Event> | undefined = readField('events', item);
                const episodesRef: ReadonlyArray<CMSAPIModels.Show> | undefined = readField('episodes', item);
                const productsRef: ReadonlyArray<CMSAPIModels.Product> | undefined = readField('product', item);
                const seriesRef: ReadonlyArray<CMSAPIModels.Series> | undefined = readField('series', item);
                const filter: CMSAPIModels.ContentFilters | undefined = readField('filters', item);
                const sortFilter: CMSAPIModels.ContentSort | undefined = readField('sort', item);
                const itemRef: CMSAPIModels.Item | undefined = readField('item', item);
                const itemsArrayRef = (itemRef ? [itemRef] : []) as ReadonlyArray<CMSAPIModels.Item>;
                const teamsRef: ReadonlyArray<CMSAPIModels.Competitor> | undefined = readField('teams', item);
                const contentShowRef: CMSAPIModels.ContentShow | undefined = readField('show', item);
                const contentShowArrayRef = (contentShowRef
                  ? [contentShowRef]
                  : []) as ReadonlyArray<CMSAPIModels.ContentShow>;
                const itemArray = [
                  clipsRef,
                  eventsRef,
                  episodesRef,
                  productsRef,
                  seriesRef,
                  itemsArrayRef,
                  teamsRef,
                  contentShowArrayRef,
                ].reduce((items, itemRef) => {
                  return [...items, ...(itemRef && itemRef.length > 0 ? itemRef : [])];
                }, [] as ReadonlyArray<CMSAPIModels.Video | CMSAPIModels.Event | CMSAPIModels.Product | CMSAPIModels.Show | CMSAPIModels.Series | CMSAPIModels.Item | CMSAPIModels.Competitor | CMSAPIModels.ContentShow>);
                const max = filter?.maxItems;
                const sortTag = sortFilter?.order?.toLowerCase();
                const slicedArray = max ? itemArray.slice(0, max) : itemArray;
                const sortedArray = sortContent([...slicedArray], readField, sortTag);
                allItemsArray = [...allItemsArray, ...sortedArray];
              });
            }
            return uniqBy(allItemsArray, '__ref');
          },
        },
      },
    },
  },
} as InMemoryCacheConfig;
