import React, { FC, useCallback } from 'react';
import { Pressable, View } from 'react-native';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  NavbarNarrow,
  NavbarNarrowNoGradient,
  NavbarWide,
  NavbarWideNoGradient,
} from '@warnermmedia/gsp-core/brands/estadio/assets';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { DeviceTypes, useSpatialNavigation } from '@warnermmedia/gsp-core/sdk/ui';
import { FocusKeys } from '@warnermmedia/gsp-core/sdk/data-access';
import { isElementAvailable } from '@warnermmedia/gsp-core/brands/estadio/feature';

interface SidebarLogoProps {
  goToHome: () => void;
  shouldExpand: boolean;
  devices: DeviceTypes;
  rightDirectionOnPress?: () => void;
  rightFocusKey?: string;
  isHeader?: boolean;
  onNavFocus?: (key: string) => void;
  downFocusKey?: string;
  logoWidth?: number;
  logoHeight?: number;
}

export const SidebarLogo: FC<SidebarLogoProps> = ({
  goToHome,
  devices,
  shouldExpand,
  rightFocusKey,
  rightDirectionOnPress,
  isHeader = false,
  onNavFocus,
  downFocusKey,
  logoWidth,
  logoHeight,
}: SidebarLogoProps) => {
  const { isTv, isWeb, isIpadOldBrowser } = devices;
  const navLogoFocusKey = FocusKeys.NAVLOGO;
  const { ref, setFocus, focusSelf } = useSpatialNavigation({
    focusKey: navLogoFocusKey,
    focusable: !isTv,
    onEnterPress: () => {
      goToHome();
    },
    onFocus: () => {
      onNavFocus?.(navLogoFocusKey);
    },
    onArrowPress: (direction: string) => {
      if (direction === 'right') {
        rightFocusKey && setFocus(rightFocusKey);
        rightDirectionOnPress?.();
      }

      if (direction === 'left' || direction === 'up') {
        focusSelf();
        return false;
      }
      if (direction === 'down' && !!downFocusKey) {
        setFocus(downFocusKey);
        return false;
      }
      if (direction === 'down' && isElementAvailable(FocusKeys.TICKER)) {
        setFocus(FocusKeys.TICKER);
        return false;
      }
      if (direction === 'down' && isElementAvailable(FocusKeys.LOGIN_TEXT_INPUT)) {
        setFocus(FocusKeys.LOGIN_TEXT_INPUT);
        return false;
      }
      if (direction === 'down' && isElementAvailable(FocusKeys.REGISTER_ACCOUNT_FORM)) {
        setFocus(FocusKeys.REGISTER_ACCOUNT_FORM);
        return false;
      }
      if (direction === 'right' && isElementAvailable(FocusKeys.HEADER_LOGIN_BUTTON)) {
        setFocus(FocusKeys.HEADER_LOGIN_BUTTON);
        return false;
      }
      return true;
    },
  });

  const getNavbarLogo = useCallback(() => {
    const navProps =
      logoWidth && logoHeight
        ? { width: logoWidth, height: logoHeight }
        : isHeader
        ? { width: isWeb ? 140 : 100, height: 56 }
        : shouldExpand
        ? { width: 187, height: 90 }
        : { width: 78, height: 90 };
    if (isIpadOldBrowser) {
      return shouldExpand ? <NavbarWideNoGradient {...navProps} /> : <NavbarNarrowNoGradient {...navProps} />;
    }
    return shouldExpand ? <NavbarWide {...navProps} /> : <NavbarNarrow {...navProps} />;
  }, [isIpadOldBrowser, shouldExpand, isHeader, isWeb, logoWidth, logoHeight]);

  return isTv ? (
    <View pointerEvents="none" nativeID={navLogoFocusKey} ref={ref}>
      {getNavbarLogo()}
    </View>
  ) : (
    <Pressable onPress={goToHome} ref={ref} nativeID={navLogoFocusKey}>
      <View pointerEvents="none">{getNavbarLogo()}</View>
    </Pressable>
  );
};

export default SidebarLogo;
