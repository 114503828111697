import React from 'react';
import { Image, ImageBackground, ImageSourcePropType, Text, View } from 'react-native';
import { TextContent } from '@warnermmedia/gsp-core/sdk/ui';
import { getStyles } from './tvAccountDetails.styles';
import { breakpointsStateStore, UserAccount } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { useReactiveVar } from '@apollo/client';
import { languageStrings, useScaleSizing } from '@warnermmedia/gsp-core/brands/estadio/feature';
import { useTheme } from 'react-native-paper';

export interface Props {
  userData: UserAccount;
  subscriptionEndDate: string;
  favoriteTeamLogo: ImageSourcePropType | null;
  isTveAccount: boolean;
  tveProvider: string;
}

export const TVAccountDetailsComponent = (props: Props) => {
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const scaling = useScaleSizing();
  const { colors } = useTheme();
  const styles = getStyles(breakpoints, scaling, colors);

  const renderTeamComponent = () => {
    const { favoriteTeam, favoriteTeamId } = props.userData;
    const teamLogo = favoriteTeam && favoriteTeamId ? props.favoriteTeamLogo : null;
    return (
      <View style={styles.favoriteTeamContainer}>
        <TextContent style={styles.teamLabel}>{languageStrings.default.favoriteTeamLabel}</TextContent>
        {teamLogo ? (
          <ImageBackground source={teamLogo} style={styles.background}>
            <View style={styles.overlay} />
            <Image source={teamLogo} style={styles.teamLogo} />
          </ImageBackground>
        ) : null}
        <TextContent style={styles.teamName}>{favoriteTeam ? favoriteTeam : '-'}</TextContent>
      </View>
    );
  };

  const accountProps = {
    subTitle: languageStrings.default.accountInfoTitle,
    details: props.userData,
    detailsOptions: {
      firstName: {
        label: languageStrings.default.firstName,
        type: 'string',
      },
      lastName: {
        label: languageStrings.default.lastName,
        type: 'string',
      },
      email: {
        label: languageStrings.default.emailText,
        type: 'string',
      },
    },
  };

  return (
    <View style={styles.accountWrapper}>
      {!props.isTveAccount && (
        <View style={styles.cardWrapper}>
          <Text style={styles.titleStyle}>{accountProps.subTitle}</Text>
          <View style={styles.detailsWrapper}>
            <View style={styles.details}>
              {Object.entries(accountProps.detailsOptions).map((entry) => {
                return (
                  <View key={`tvEntryDetail${entry[0]}`} style={styles.detailContainer}>
                    <TextContent style={styles.detailLabel}>{entry[1]?.label}</TextContent>
                    <TextContent style={styles.detailValue} numberOfLines={1} ellipsizeMode="clip">
                      {
                        /* eslint-disable-next-line */
                        // @ts-ignore
                        accountProps.details[entry[0]]
                      }
                    </TextContent>
                  </View>
                );
              })}
            </View>
            {renderTeamComponent()}
          </View>
        </View>
      )}

      <View style={[styles.cardWrapper, props.isTveAccount && styles.tveCardWrapper]}>
        <Text style={styles.titleStyle} accessible={false}>
          {languageStrings.default.accountDetailHeader}
        </Text>
        <View style={styles.detailContainer}>
          <TextContent style={styles.detailLabel} accessible={false}>
            {languageStrings.default.subscriptionTitle}
          </TextContent>
          {!props.isTveAccount && (
            <TextContent style={styles.detailValue} accessible={false}>
              {languageStrings.default.subscriptionValidLabel}
              {props.subscriptionEndDate}
            </TextContent>
          )}
          {props.isTveAccount && (
            <TextContent style={styles.detailValue} accessible={false}>
              {languageStrings.default.subscriptionTveInfoDescription(props.tveProvider)}
            </TextContent>
          )}
        </View>

        {/*<View style={styles.detailContainer}>*/}
        {/*  /!* code below should be uncommented when this data is available *!/*/}
        {/*  /!* <TextContent*/}
        {/*    style={styles.detailLabel}*/}
        {/*  >*/}
        {/*    Detalles de Pago*/}
        {/*  </TextContent> *!/*/}
        {/*  /!* <TextContent*/}
        {/*    style={styles.detailValue}*/}
        {/*  >*/}
        {/*    [**** - **** - **** - XXXX]*/}
        {/*  </TextContent> *!/*/}
        {/*</View>*/}
      </View>
    </View>
  );
};

export default TVAccountDetailsComponent;
