import React from 'react';
import { Linking, Modal, View } from 'react-native';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { FooterData, footerStateStore } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import orderBy from 'lodash/orderBy';
import slice from 'lodash/slice';
import Constants from '../Utils/Constants';
import languageStrings from '../I18n';
import { isWebPlatform } from '../Utils/Platform';

export function callbackNavigateExternal(link: string): boolean {
  // For Security reasons lets enforce https
  const WWW_URL_PATTERN = new RegExp('^https?://', 'i');

  if (!WWW_URL_PATTERN.test(link)) {
    return false;
  } else {
    let returnVal = true;

    if (isWebPlatform()) {
      window.open(link, '_blank');
      returnVal = true;
    } else {
      Linking.canOpenURL(link).then((supported): void => {
        if (!supported) {
          returnVal = false;
        } else {
          Linking.openURL(link);
          returnVal = true;
        }
      });
    }

    return returnVal;
  }
}

export function callbackNavigateModal(link: string): boolean {
  footerStateStore({ ...footerStateStore(), visibleFooterModalIs: link });
  return true;
}

export function hideModals(): boolean {
  footerStateStore({ ...footerStateStore(), visibleFooterModalIs: '' });
  return true;
}

export const RenderCurrentModalHOC: React.FC<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  PrivacyPreferenceCenterModal?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  MinimumRequirementsModal?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  HelpModal?: any;
}> = ({ PrivacyPreferenceCenterModal, MinimumRequirementsModal, HelpModal }): JSX.Element | null => {
  const shouldShowFooterModal = footerStateStore().visibleFooterModalIs !== '';
  return shouldShowFooterModal ? (
    <View>
      <Modal visible={shouldShowFooterModal}>
        {footerStateStore().visibleFooterModalIs === 'PrivacyPreferenceCenterModal' ? (
          <PrivacyPreferenceCenterModal testID="PrivacyPreferenceCenterModal" />
        ) : (
          <View />
        )}

        {footerStateStore().visibleFooterModalIs === 'MinimumRequirementsModal' ? (
          <MinimumRequirementsModal testID="MinimumRequirementsModal" />
        ) : (
          <View />
        )}

        {footerStateStore().visibleFooterModalIs === 'HelpModal' ? <HelpModal testID="HelpModal" /> : <View />}
      </Modal>
    </View>
  ) : null;
};

export const getFooterLinks = (): FooterData[] => {
  const footerData = footerStateStore().footerData;
  const footerDataOrdered = orderBy(footerData, ['id'], ['asc']);
  const footerDataSliced = slice(footerDataOrdered, 0, 7);
  return footerDataSliced;
};

export const getFooterImageLinks = (): FooterData[] => {
  const footerData = footerStateStore().footerData;
  const footerDataOrdered = orderBy(footerData, ['id'], ['asc']);
  const footerDataSliced = slice(footerDataOrdered, 8, 13);
  return footerDataSliced;
};

export const getFooterCopyrightText = (): string => {
  return Constants.FOOTER_COPYRIGHT;
};

export const getFooterGoToText = (): string => {
  return languageStrings.FOOTER_LABEL_8;
};

export const getFooterGoToLink = (): string => {
  return Constants.FOOTER_LINK_8;
};

export const getFooterAppVersionText = (): string => {
  return Constants.FOOTER_VERSION;
};
