import { Dimensions } from 'react-native';

export const initialWindowWidth = Dimensions.get('window').width;
export const initialWindowHeight = Dimensions.get('window').height;

export const initialBreakpointSizes = {
  tny: 320,
  sm: 427,
  med: 767,
  lg: 1023,
  xl: 1439,
  xxl: 1919,
};

export const initalCurrentBreakpoints = {
  isTiny: initialWindowWidth <= initialBreakpointSizes.tny,
  isTnySm: initialWindowWidth > initialBreakpointSizes.tny && initialWindowWidth < initialBreakpointSizes.sm,
  isSmMed: initialWindowWidth > initialBreakpointSizes.sm && initialWindowWidth < initialBreakpointSizes.med,
  isMedLg: initialWindowWidth > initialBreakpointSizes.med && initialWindowWidth < initialBreakpointSizes.lg,
  isLgXl: initialWindowWidth > initialBreakpointSizes.lg && initialWindowWidth < initialBreakpointSizes.xl,
  isXlXxl: initialWindowWidth > initialBreakpointSizes.xl && initialWindowWidth < initialBreakpointSizes.xxl,
  isXxl: initialWindowWidth >= initialBreakpointSizes.xxl,
};

export const initialBreakpointHelpers = {
  isTvScreen: initalCurrentBreakpoints.isXxl,
  isWebScreen: initalCurrentBreakpoints.isLgXl || initalCurrentBreakpoints.isXlXxl,
  isMediumScreen: initalCurrentBreakpoints.isMedLg,
  isMobileScreen:
    initalCurrentBreakpoints.isTiny || initalCurrentBreakpoints.isTnySm || initalCurrentBreakpoints.isSmMed,
  isLandScape: Dimensions.get('window').width > Dimensions.get('window').height,
};
