import React, { useCallback, useMemo, useRef } from 'react';
import styles from './carousel.styles';
import { Dimensions, FlatList, FlatListProps, StyleProp, View, ViewStyle } from 'react-native';
import { DeviceTypes, useCarousel } from '../../hooks';

export interface CarouselProps<T> extends FlatListProps<T>, CarouselPropsExtra<T> {}

export interface CarouselPropsExtra<T> {
  /**
   * Carousel heading
   */
  headline?: string;
  /**
   * Current active index
   */
  index: number;
  /**
   * Full lane width
   */
  laneWidth: number;
  /**
   * Width of each item in the carousel
   */
  itemWidth?: number;
  /**
   * Custom string for carousel type.
   */
  type?: string;
  /**
   * Custom string for carousel format.
   */
  format?: string | null;
  /**
   * (Optional) Function to call when item is click/tap
   */
  action?: (item: T) => void;
  /**
   * (Optional) Function to call when index is updated
   */
  indexUpdate?: (index: number) => void;
  /**
   * (Optional) Style to be applied to carousel container
   */
  containerStyle?: StyleProp<ViewStyle>;
  /**
   * (Optional) Style to be applied to carousel container
   */
  contentContainerStyle?: StyleProp<ViewStyle>;
  /**
   * (Optional) Called after drag events are complete and new indexes are set.  New index is passed.
   */

  itemWidthMarginAllowance?: number;

  device: DeviceTypes;
}

const { width } = Dimensions.get('window');

export function Carousel<T>({
  index = 0,
  indexUpdate = () => null,
  laneWidth = width,
  itemWidth = width,
  horizontal = true,
  initialScrollIndex = 0,
  showsHorizontalScrollIndicator = false,
  showsVerticalScrollIndicator = false,
  snapToAlignment = 'start',
  itemWidthMarginAllowance = 0,
  device,
  ...props
}: CarouselProps<T>) {
  const dataLength = props?.data?.length;
  const refCarousel = useRef<FlatList>(null);
  const { endDrag } = useCarousel(index, refCarousel, itemWidth, indexUpdate, device, dataLength);
  const viewAbilityConfig = useMemo(
    () =>
      props.viewabilityConfig
        ? props.viewabilityConfig
        : {
            itemVisiblePercentThreshold: 90,
            minimumViewTime: 1000,
          },
    [props.viewabilityConfig]
  );

  const contentContainerStyle = useMemo(
    () => [
      { paddingRight: laneWidth - itemWidth },
      { width: (itemWidth + itemWidthMarginAllowance) * (dataLength || 1) },
    ],
    [laneWidth, itemWidthMarginAllowance, dataLength, itemWidth]
  );

  const getItemLayout = useCallback(
    (data: T[] | null | undefined, index: number) => ({
      length: itemWidth,
      offset: itemWidth * index,
      index,
    }),
    [itemWidth]
  );
  const keyExtractor = useCallback((item, index) => `${index}-${item.id}-${item.key}`, []);

  return (
    <View style={[styles.swimContain, props.containerStyle ? props.containerStyle : {}]}>
      <FlatList<T>
        ref={refCarousel}
        horizontal={horizontal}
        initialScrollIndex={initialScrollIndex}
        snapToAlignment={snapToAlignment}
        snapToInterval={itemWidth}
        {...(device.isWeb && { viewabilityConfig: viewAbilityConfig })}
        getItemLayout={getItemLayout}
        {...(device.isWeb ? { onScroll: endDrag } : { onMomentumScrollEnd: endDrag })}
        contentContainerStyle={[contentContainerStyle]}
        showsHorizontalScrollIndicator={showsHorizontalScrollIndicator}
        showsVerticalScrollIndicator={showsVerticalScrollIndicator}
        decelerationRate="fast"
        keyExtractor={keyExtractor}
        {...props}
      />
    </View>
  );
}

export default Carousel;
