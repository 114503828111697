export const enum ErrorCodes {
  APP_GEO_CALL_FAILED = 'A1000',

  TVE_AUTH_FAILED = 'G1001',
  TVE_AUTH_CANCELED = 'AN01SH0632', // Adobe code when user cancels their TVE code, by requesting a new one.

  TVE_INIT_FAILED = 'TVA1001',
  ACT_CODE_RETRIEVE_ERROR = 'TVA1002',

  DETAILS_CONTENT_API_ERROR = 'D1001',
  DETAILS_CONTENT_UNEXPECTED_ERROR = 'D1002',

  CLIPS_CONTENT_API_ERROR = 'C1001',
  CLIPS_CONTENT_UNEXPECTED_ERROR = 'C1002',
  CLIPS_CONTENT_MEDIUM_RESOLUTION_ERROR = 'C1003',
  LIVE_TV_UNEXPECTED_ERROR = 'LTV1001',

  AUDIO_CONTENT_API_ERROR = 'AU1001',
  AUDIO_CONTENT_UNEXPECTED_ERROR = 'AU1002',
  AUDIO_PLAYER_SETUP_FAILED = 'AU1003',

  PLAYBACK_CONTEXT_NULL = 'T1001',
  PLAYBACK_API_ERROR_RESP = 'T1002',
  TVE_CONTENT_TOKEN_API_ERROR = 'T1003',
  TVE_CONTENT_TOKEN_EXPIRED = 'T1004',
  TVE_CONTENT_UNAUTHORIZED = 'T1005',
  PLAYBACK_MEDIAID_MISSING = 'T1006',

  PLAYER_GENERIC_ERROR = 'P0000',
  PLAYER_UNEXPECT_ERROR = 'P1001',
  PLAYER_SETUP_FAILED = 'P1002',
  PLAYER_LOADING_FAILED = 'P1003',
  PLAYER_MEDIA_JSON_ERROR = 'P1004',
  PLAYER_DEVICE_IFA_API_ERROR = 'P1005',
  PLAYER_DRM_TOKEN_API_ERROR = 'P1006',
  PLAYER_BUFFERING_TIMEOUT = 'P1007',
  PLAYER_AD_ERROR = 'P1008',

  TVE_PROVIDER_NOT_IN_LIST = 'TVE1003',
}
