import React, { ReactNode } from 'react';
import { IconButton } from 'react-native-paper';
import { View } from 'react-native';
import { getStyles } from './footer.styles';
import { getFooterImageLinks } from '@warnermmedia/gsp-core/brands/estadio/feature';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';
import { FooterLink } from './footerLink';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import languageStrings from '../../../../data-access/src/lib/I18n';
import { FocusKeys } from '@warnermmedia/gsp-core/sdk/data-access';

export interface FooterImagesLinksProps {
  breakpoints: Breakpoints;
  colors: ReactNativePaper.ThemeColors;
}
export const renderFooterImagesLinks = ({ breakpoints, colors }: FooterImagesLinksProps): ReactNode => {
  const footerDataSliced = getFooterImageLinks();
  const styles = getStyles(breakpoints, colors);

  return (
    <>
      {footerDataSliced.map((item, index) => (
        <FooterLink
          key={item.id}
          index={index}
          focusKey={index === 0 ? FocusKeys.FOOTER_IMAGES_ROW : item.title ?? null}
          onPress={() => item.callback && item.callback(`${item.link}`)}
          leftFocusKey={languageStrings.FOOTER_LABEL_7}
          dataLength={footerDataSliced.length - 1}
          focusStyle={styles.focusStyle}
          overrideStyle={{ height: 40 }}
          isNavMobile={false}
        >
          <IconButton
            key={Number(item.id)}
            icon={() => item?.imageIcon}
            onPress={() => item.callback && item.callback(`${item.link}`)}
            style={styles.socialIconList}
          />
        </FooterLink>
      ))}
    </>
  );
};

export const FooterRight = ({ breakpoints, colors }: FooterImagesLinksProps) => {
  const styles = getStyles(breakpoints, colors);
  return <View style={styles.iconsContainer}>{renderFooterImagesLinks({ breakpoints, colors })}</View>;
};
