import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  topContent: {
    marginBottom: 20,
  },
  ctaBtn: {
    backgroundColor: '#ff0085',
  },
  toRow: {
    flexDirection: 'row',
  },
  wrapText: {
    flex: 1,
    flexWrap: 'wrap',
  },
  inputContainer: {
    marginBottom: 15,
  },
  title: {
    fontSize: 22,
    textTransform: 'uppercase',
  },
  instruction: {
    fontSize: 16,
  },
  textLabel: {
    fontSize: 16,
    fontWeight: '500',
    color: '#fff',
  },
  rule: {
    color: '#fff',
    fontSize: 12,
  },
});
