import { Breakpoints, Carousel, CarouselPropsExtra, DeviceTypes } from '@warnermmedia/gsp-core/sdk/ui';
import { EpgCard } from '../../cards/epg/epgCard';
import { DirectionalArrow } from '../directionalArrow/directionalArrow';
import React, { useCallback } from 'react';
import { ListRenderItemInfo, Text, View } from 'react-native';
import { getStyles } from './epg.styles';
import { carouselBase, useHideSwimlaneArrow } from '@warnermmedia/gsp-core/brands/estadio/feature';

export interface EpgProps extends CarouselPropsExtra<carouselBase> {
  /**
   * Array of carousel items
   */
  data: carouselBase[];
  headline?: string;
  breakpoints: Breakpoints;
  device: DeviceTypes;
  colors: ReactNativePaper.ThemeColors;
}

export const Epg = ({
  headline = 'Programming',
  index: slideIndex,
  laneWidth,
  indexUpdate,
  data,
  breakpoints,
  colors,
  device,
  ...props
}: EpgProps) => {
  const styles = getStyles(breakpoints, device.isTv, colors);
  const cardWidth: number = breakpoints.currentBreakpoints.isXxl
    ? 239
    : breakpoints.currentBreakpoints.isMedLg || breakpoints.currentBreakpoints.isLgXl
    ? 218
    : 270;
  const windowLength = Math.floor(breakpoints.windowWidth / cardWidth) - 1;
  const { hideRightArrow } = useHideSwimlaneArrow(windowLength, slideIndex, data.length);
  const epgSlide = useCallback(
    (slideProps: ListRenderItemInfo<carouselBase>) => (
      <EpgCard
        currentIndex={slideIndex}
        laneWidth={laneWidth}
        device={device}
        colors={colors}
        breakpoints={breakpoints}
        {...slideProps}
      />
    ),
    [breakpoints, colors, device, laneWidth, slideIndex]
  );

  return (
    <View style={styles.epgCardsLayout}>
      <Text style={styles.headline}>{headline}</Text>
      <View>
        {slideIndex !== 0 && data.length > 0 && slideIndex in data && (
          <DirectionalArrow
            dataLength={data.length}
            index={slideIndex}
            indexUpdate={indexUpdate}
            isLeftPoint={true}
            device={device}
            colors={colors}
            breakpoints={breakpoints}
            includeGrad={true}
            overrideStyle={styles.backArrow}
            gradStyle={styles.backArrowGrad}
          />
        )}
        <Carousel
          index={slideIndex}
          indexUpdate={indexUpdate}
          initialScrollIndex={slideIndex}
          data={data}
          renderItem={epgSlide}
          laneWidth={laneWidth}
          itemWidth={cardWidth}
          contentContainerStyle={{ paddingRight: 0 }}
          onEndReachedThreshold={0.01}
          snapToOffsets={data?.map((x, index) => index * cardWidth)}
          itemWidthMarginAllowance={1}
          device={device}
          {...props}
        ></Carousel>

        {!hideRightArrow && (
          <DirectionalArrow
            dataLength={data.length}
            index={slideIndex}
            indexUpdate={indexUpdate}
            isLeftPoint={false}
            device={device}
            colors={colors}
            breakpoints={breakpoints}
            includeGrad={true}
            overrideStyle={styles.forwardArrow}
            gradStyle={styles.forwardArrowGrad}
          />
        )}
      </View>
    </View>
  );
};

export default Epg;
