import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    paddingHorizontal: 10,
    paddingVertical: 5,
    alignItems: 'flex-end',
  },
  imageContainer: {
    paddingHorizontal: 7,
  },
  imageStyle: {
    width: 80,
    height: 100,
  },
  hideRightMargin: { marginRight: 0 },
  textContainer: {
    flexShrink: 1,
  },
  titleStyle: {
    textTransform: 'uppercase',
    fontWeight: '400',
    fontSize: 40,
  },
  description: {
    width: '70%',
    fontSize: 16,
    fontWeight: '100',
  },
});
