import { StyleSheet } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';

export const getStyles = (
  isMobileSize: boolean,
  breakpoints: Breakpoints,
  colors: ReactNativePaper.ThemeColors,
  isMobileDevice: boolean,
  isIosDevice: boolean
) => {
  const styles = StyleSheet.create({
    fullScreenContainer: {
      backgroundColor: colors.keyboardFocus.dark,
      opacity: 0.9,
      position: 'absolute',
      width: '100%',
      height: '100%',
    },
    centeredView: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    centeredViewMobile: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      paddingHorizontal: isIosDevice ? 0 : 20,
    },
    container: {
      backgroundColor: colors.tenantBackground.dark.surface03,
      alignItems: 'center',
      justifyContent: 'center',
      elevation: 5,
      width: isMobileDevice ? '90%' : undefined,
    },
    mainTextContainer: {
      width: '80%',
      paddingBottom: 48,
    },
    closeBtn: {
      backgroundColor: colors.tenantBackground.dark.surface03,
      borderColor: 'transparent',
      alignSelf: 'flex-end',
      padding: 10,
    },
    closeBtnText: {
      fontSize: 16,
      color: colors.keyboardFocus.light,
      fontFamily: 'Rubik-Regular',
    },
    descriptionContainer: {
      width: isMobileSize ? '90%' : '80%',
      alignSelf: 'center',
    },
    description: {
      fontSize: 16,
      color: colors.keyboardFocus.light,
      fontFamily: 'Rubik-Regular',
      lineHeight: 20,
      fontWeight: 'normal',
      textAlign: 'center',
      marginBottom: 14,
    },
    dropdownTitle: {
      fontSize: 16,
      color: colors.keyboardFocus.light,
      fontFamily: 'Rubik-Medium',
      lineHeight: 20,
      textAlign: 'left',
      fontWeight: '500',
      marginTop: isMobileSize ? 20 : 5,
      marginBottom: 5,
    },
    dropdownInput: {
      borderRadius: 4,
      height: 40,
      backgroundColor: colors.tenantBackground.light.surfaceBase,
    },
    headerText: {
      fontSize: isMobileSize ? 20 : 22,
      color: colors.keyboardFocus.light,
      fontFamily: 'Oswald-SemiBold',
      textTransform: 'uppercase',
      paddingBottom: 24,
      alignSelf: 'center',
    },
    ctaContainer: {
      paddingHorizontal: isMobileSize ? 0 : 50,
    },
    errorDisplay: {
      marginVertical: 10,
    },
    buttonLabel: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Oswald-Medium',
      fontSize: 20,
      fontWeight: '500',
      lineHeight: 24,
    },
    buttonSpace: {
      marginTop: 20,
      marginBottom: 10,
    },
    skipButtonStyle: {
      borderWidth: 2,
      borderColor: colors.tenantBackground.light.surfaceBase,
    },
    dropDownTextStyle: {
      fontSize: breakpoints.currentBreakpoints.isTiny ? 14 : 16,
      fontFamily: 'Rubik-Regular',
      fontWeight: 'normal',
      color: colors.keyboardFocus.dark,
    },
    hoverStyle: {
      backgroundColor: colors.fill.action.accent02,
    },
    dropDownItemHoverStyle: {
      color: colors.tenantBackground.light.surfaceBase,
    },
    inputClickStyle: {
      borderWidth: 2,
      borderColor: colors.fill.action.accent02,
    },
  });

  return styles;
};
