import * as React from 'react';
import { View } from 'react-native';
import { IconButton } from 'react-native-paper';
import {
  Dalton,
  languageStrings,
  ScreenEventType,
  useMparticleScreenEvent,
} from '@warnermmedia/gsp-core/brands/estadio/feature';

import { MessageDisplay, validateEmail, Variant } from '@warnermmedia/gsp-core/sdk/ui';
import {
  EstadioForgotPasswordForm,
  LayoutType,
  LayoutWrapper,
  TitleType,
} from '@warnermmedia/gsp-core/brands/estadio/ui';
import { breakpointsStateStore } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { getStyles } from '../forgotPassword.styles';
import { forgotPasswordContext } from '../forgotPasswordContext';
import { useTheme } from 'react-native-paper';
import { useReactiveVar } from '@apollo/client';

interface PasswordRecoveryProps {
  validationCode?: string;
}

export function PasswordRecovery({ validationCode }: PasswordRecoveryProps) {
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const { colors } = useTheme();
  const styles = getStyles(breakpoints, colors);
  const passwordResetContextValue = React.useContext(forgotPasswordContext);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [emailError, setEmailError] = React.useState<string>('');
  const [isSuccessful, setIsSuccessful] = React.useState(false);
  const dalton = Dalton();
  useMparticleScreenEvent(
    ScreenEventType.PasswordRecovery,
    isSuccessful ? languageStrings.default.checkEmailContentTitle : ''
  );

  if (!passwordResetContextValue) {
    return null;
  }

  const { handleEmailSuccess } = passwordResetContextValue;

  function sendRequest(userCredential: string) {
    setEmailError('');
    setLoading(true);
    dalton.forgotPassword(
      userCredential,
      (result) => {
        setIsSuccessful(true);
        setLoading(false);
      },
      () => {
        setIsSuccessful(false);
        setLoading(false);
      }
    );
  }

  function handleSuccess() {
    if (isSuccessful) {
      handleEmailSuccess();
    }
  }

  if (isSuccessful) {
    return (
      <LayoutWrapper
        layoutType={LayoutType.Center}
        titleType={TitleType.Left}
        pageTitle={languageStrings.default.emailSentText}
      >
        <MessageDisplay
          displayMessage={languageStrings.default.passwordRecoverySuccess}
          textStyle={{
            fontFamily: 'Rubik-Regular',
            fontSize: 16,
            lineHeight: 20,
          }}
          onClick={handleSuccess}
          variant={Variant.Generic}
          btnText={languageStrings.default.loginText}
          btnLabelStyle={{
            fontFamily: 'Oswald-Medium',
            fontSize: 20,
            marginVertical: 0,
          }}
          btnStyle={{ marginTop: 32 }}
          displayIcon={
            <IconButton
              style={styles.displayIcon}
              icon="check-circle-outline"
              color={colors.tenantBackground.light.surfaceBase}
              size={30}
            />
          }
        />
      </LayoutWrapper>
    );
  }

  return (
    <LayoutWrapper
      layoutType={LayoutType.Center}
      titleType={TitleType.Left}
      pageTitle={languageStrings.default.passwordRecoveryPage}
      subTitle={
        !validationCode
          ? languageStrings.default.passwordRecoverySubTitle
          : languageStrings.default.passwordRecoverySubWithCodeTitle
      }
    >
      <EstadioForgotPasswordForm
        onRequestSend={sendRequest}
        label={languageStrings.default.emailText}
        ctaBtnLabel={languageStrings.default.recoveryEmailLabel}
        usernameInputError={languageStrings.default.recoveryEmailInputError}
        isLoading={loading}
        error={emailError}
        validateUsername={validateEmail}
      />
    </LayoutWrapper>
  );
}

export default React.memo(PasswordRecovery);
