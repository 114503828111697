import * as React from 'react';
import { Linking, Text, View } from 'react-native';
import { MessageDisplay, validatePasswordRule, Variant } from '@warnermmedia/gsp-core/sdk/ui';
import {
  EstadioResetPasswordForm,
  LayoutType,
  LayoutWrapper,
  TitleType,
} from '@warnermmedia/gsp-core/brands/estadio/ui';
import { IconButton, useTheme } from 'react-native-paper';
import {
  Dalton,
  languageStrings,
  ScreenEventType,
  useMparticleCustomEventObject,
  useMparticleScreenEvent,
} from '@warnermmedia/gsp-core/brands/estadio/feature';

import { getStyles } from '../forgotPassword.styles';
import { forgotPasswordContext } from '../forgotPasswordContext';
import { MParticleCustomEventTypes, mParticleEventProcessor } from '@warnermmedia/gsp-core/sdk/data-access';
import { breakpointsStateStore } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { useReactiveVar } from '@apollo/client';

interface ResetPasswordProps {
  userEmail: string;
  resetToken: string;
}

export function ResetPassword(props: ResetPasswordProps) {
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const { colors } = useTheme();
  const styles = getStyles(breakpoints, colors);
  const passwordResetContextValue = React.useContext(forgotPasswordContext);
  const [loading, setLoading] = React.useState(false);
  const [isSuccessful, setIsSuccessful] = React.useState(false);
  const [error, setError] = React.useState('');
  const dalton = Dalton();
  const contentTitle = isSuccessful ? languageStrings.default.resetPasswordSuccessContentTitle : '';
  const mParticleEventData = useMparticleCustomEventObject({ contentTitle });
  useMparticleScreenEvent(ScreenEventType.ResetPassword, contentTitle);

  if (!passwordResetContextValue) {
    return null;
  }

  const { handlePasswordSuccess } = passwordResetContextValue;

  // Implement API call for password reset
  function resetPassword(password: string) {
    setError('');
    setLoading(true);
    mParticleEventProcessor.pushMParticleEvent(MParticleCustomEventTypes.AccountRecoveryStartEvent, mParticleEventData);
    dalton.updatePassword(
      props.userEmail,
      password,
      props.resetToken,
      () => {
        mParticleEventProcessor.pushMParticleEvent(
          MParticleCustomEventTypes.AccountRecoveryCompleteEvent,
          mParticleEventData
        );
        setIsSuccessful(true);
        setLoading(false);
      },
      () => {
        setError(languageStrings.default.resetPasswordErrorMessage);
        setIsSuccessful(false);
        setLoading(false);
      }
    );
  }

  const resetPasswordErrorMessageLink = (
    <Text
      style={styles.errorMessageLinkText}
      onPress={() => {
        Linking.openURL('/forgot-password');
      }}
    >
      {languageStrings.default.resetPasswordErrorMessageLink}
    </Text>
  );

  const ResetPasswordErrorMessage = () => (
    <Text style={styles.errorMessageText}>
      {languageStrings.default.resetPasswordErrorMessage} {resetPasswordErrorMessageLink}
    </Text>
  );

  function handleSuccess() {
    if (isSuccessful) {
      handlePasswordSuccess();
    }
  }

  if (isSuccessful) {
    return (
      <LayoutWrapper
        layoutType={LayoutType.Center}
        titleType={TitleType.Left}
        pageTitle={languageStrings.default.resetPasswordSuccessTitle}
      >
        <MessageDisplay
          displayMessage={languageStrings.default.resetPasswordSuccessSuccess}
          btnText={languageStrings.default.loginText}
          onClick={handleSuccess}
          variant={Variant.Generic}
          btnLabelStyle={{
            fontFamily: 'Oswald-Medium',
            fontSize: 20,
            marginVertical: 0,
            letterSpacing: 0,
          }}
          displayIcon={
            <IconButton
              style={styles.displayIcon}
              icon="check-circle-outline"
              color={colors.tenantBackground.light.surfaceBase}
              size={22}
            />
          }
        />
      </LayoutWrapper>
    );
  }

  return (
    <View>
      {error ? (
        <View style={styles.topMargin}>
          <MessageDisplay
            displayMessage={<ResetPasswordErrorMessage />}
            variant={Variant.Error}
            wrapperStyle={styles.messageWrapper}
          />
        </View>
      ) : null}
      <LayoutWrapper
        layoutType={LayoutType.Center}
        titleType={TitleType.Left}
        pageTitle={languageStrings.default.resetPasswordPage}
        subTitle={languageStrings.default.resetPasswordSubTitle}
      >
        <EstadioResetPasswordForm
          passwordLabel={languageStrings.default.passwordText}
          confirmPasswordLabel={languageStrings.default.resetPasswordConfirmLabel}
          passwordRule={languageStrings.default.resetPasswordRule}
          ctaBtnLabel={languageStrings.default.resetPasswordUpdateLabel}
          passwordMatchError={languageStrings.default.passwordMatchError}
          onRequestSend={resetPassword}
          isLoading={loading}
          error={error}
          ruleMismatchError={languageStrings.default.ruleMismatchError}
          validatePassword={validatePasswordRule}
        />
      </LayoutWrapper>
    </View>
  );
}

export default React.memo(ResetPassword);
