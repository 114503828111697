import React from 'react';
import { View } from 'react-native';
import { useTheme } from 'react-native-paper';
import styles from './vertical-navbar.styles';

type VerticalNavbarProps = React.ComponentPropsWithRef<typeof View> & {
  /**
   * Content of the `Navbar`.
   */
  children: React.ReactNode;
  /**
   * Color for background.
   */
  backgroundColor?: string;
};

export function VerticalNavbar({ children, backgroundColor }: VerticalNavbarProps) {
  const { colors } = useTheme();
  return (
    <View style={styles.container}>
      <View
        style={[
          styles.content,
          {
            backgroundColor: backgroundColor || colors.navbarBackground,
          },
        ]}
      >
        {children}
      </View>
    </View>
  );
}

export default VerticalNavbar;
