import React from 'react';
import { DefaultTheme, Provider } from 'react-native-paper';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace ReactNativePaper {
    interface ThemeColors {
      navbarBackground?: string;
      navItemHoverBackground?: string;
      navItemActiveBackground?: string;
      navbarColor?: string;
      navItemHoverColor?: string;
      navItemActiveColor?: string;
      itemFocusedOutlineColor?: string;
      /* eslint-disable @typescript-eslint/no-explicit-any */
      tenantBackground?: any;
      fill?: any;
      foreground?: any;
      stroke?: any;
      keyboardFocus?: any;
    }
    interface Theme {
      defaults?: {
        fontSize?: number;
        fontWeight?: 'normal' | 'bold' | '100' | '200' | '300' | '400' | '500' | '600' | '700' | '800' | '900';
        textAlign?: 'auto' | 'left' | 'right' | 'center' | 'justify';
      };
      messages: {
        disabledLabelColor: string;
        disabledBackgroundColor: string;
        success: {
          icon: string;
          labelColor: string;
          backgroundColor: string;
        };
        error: {
          icon: string;
          labelColor: string;
          backgroundColor: string;
        };
        warning: {
          icon: string;
          labelColor: string;
          backgroundColor: string;
        };
        generic: {
          icon: string;
          labelColor: string;
          backgroundColor: string;
        };
      };
    }
  }
}

/* eslint-disable-next-line */
interface PaperProviderProps {
  theme: ReactNativePaper.Theme;
  children?: React.ReactNode;
}

export function PaperProvider(props: PaperProviderProps) {
  return <Provider theme={props.theme}>{props.children}</Provider>;
}

export const defaultTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    navbarBackground: '#666',
    navItemHoverBackground: '#666',
    navItemHoverColor: '#ccc',
    navItemActiveColor: '#ccc',
    navbarColor: '#fff',
  },
  fonts: {
    ...DefaultTheme.fonts,
  },
  fontsFamily: {
    selectFont: 'Rubik-Regular',
  },
  messages: {
    disabledLabelColor: '',
    disabledBackgroundColor: '#ff0085',
    success: {
      icon: 'check',
      labelColor: '#fff',
      backgroundColor: '#0b7d3a',
    },
    error: {
      icon: 'alert-circle-outline',
      labelColor: '#fff',
      backgroundColor: '#cc1f47',
    },
    warning: {
      icon: 'alert-circle-outline',
      labelColor: '#fff',
      backgroundColor: '#ff9966',
    },
    generic: {
      icon: 'alert-circle-outline',
      labelColor: '#fff',
      backgroundColor: '#ff0085',
    },
  },
};

export default PaperProvider;
