import React, { FC } from 'react';
import { Skeleton, useGetDevice } from '@warnermmedia/gsp-core/sdk/ui';
import { View, ViewStyle } from 'react-native';
import { useReactiveVar } from '@apollo/client';
import { breakpointsStateStore } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { getStyles } from './skeletonDetailPage.styles';
import { isInRoute } from '@warnermmedia/gsp-core/brands/estadio/feature';
import { useLocation } from 'react-router';

export interface SkeletonDetailProps {
  overrideWrapperStyle?: ViewStyle | ViewStyle[];
  windowHeightStartTop?: number;
}

export const SkeletonDetailPage: FC<SkeletonDetailProps> = ({ windowHeightStartTop = 0, overrideWrapperStyle }) => {
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const width = breakpoints.windowWidth;
  const height = breakpoints.windowHeight;
  const { isMobileDevice, isTv } = useGetDevice();
  const styles = getStyles(breakpoints, isTv);
  const isMobileSize = isMobileDevice || width <= breakpoints.breakpointSizes.med;
  const generalMargin = 40;
  const cardWidth = isMobileSize ? 270 : 239;
  const cardHeight = isMobileSize ? 152 : 135;
  const cardsWindow = isMobileSize ? 1 : Math.floor((width - generalMargin) / cardWidth) - 1;
  const carouselRowsLength = Math.floor((height - windowHeightStartTop) / cardHeight);
  const carouselRows = carouselRowsLength > 0 ? new Array(carouselRowsLength).fill('') : [];
  const cardsArray = cardsWindow > 0 ? new Array(cardsWindow).fill('') : [];
  const location = useLocation();
  const isShowDetailPages = isInRoute('/show/', location.pathname);
  const isDetailPages = isInRoute('/tournaments/', location.pathname) || isInRoute('/team/', location.pathname);

  return (
    <View style={[styles.skeletonDetailWrapper, overrideWrapperStyle]}>
      <View style={styles.contentLayout}>
        {isShowDetailPages ? (
          <View style={styles.showTitleWrapper}>
            <Skeleton hasSubTitle={false} overrideContentStyle={styles.pageTitle} />
            <Skeleton hasSubTitle={false} overrideContentStyle={styles.pageDescription} />
          </View>
        ) : null}
        {isDetailPages ? (
          <View style={styles.titleWrapper}>
            <View>
              <Skeleton hasSubTitle={false} overrideContentStyle={styles.teamBadge} />
            </View>
            <View style={styles.textBoxStyle}>
              <Skeleton hasSubTitle={false} overrideContentStyle={styles.pageTitle} />
              <Skeleton hasSubTitle={false} overrideContentStyle={styles.pageDescription} />
            </View>
          </View>
        ) : null}
        {carouselRows.map((_, index) => {
          return (
            <View key={index} style={[styles.detailContentContainer]}>
              <Skeleton hasSubTitle={true} hasBody={false} />
              <View style={styles.skeletonDetailCardContainer}>
                {cardsArray.map((_, index) => (
                  <Skeleton hasTitle={false} key={index} overrideContentStyle={styles.skeletonDetailCard} />
                ))}
              </View>
            </View>
          );
        })}
      </View>
    </View>
  );
};

export default SkeletonDetailPage;
