import { StyleSheet, Platform } from 'react-native';

export default StyleSheet.create({
  tooltipWrapper: {
    alignSelf: 'center',
    width: '100%',
  },
  tooltipContainer: {
    alignSelf: 'center',
    padding: 10,
    borderRadius: 5,
    width: '100%',
    ...Platform.select({
      web: {
        left: 0,
        right: 0,
      },
      android: {
        top: 10,
        left: 0,
        right: 0,
      },
      ios: {
        top: 10,
        left: 0,
        right: 0,
      },
    }),
  },
  floatingContainer: {
    position: 'absolute',
  },
  tooltipContainerShadow: {
    ...Platform.select({
      web: {
        top: 10,
        left: 0,
        right: 0,
      },
      android: {
        elevation: 2,
      },
      ios: {
        shadowColor: 'black',
        shadowOpacity: 0.3,
        shadowOffset: { height: 2, width: 0 },
        shadowRadius: 5,
      },
    }),
  },
  tooltipArrow: {
    alignSelf: 'center',
    position: 'absolute',
    top: -10,
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderBottomWidth: 10,
    borderRightWidth: 10,
    borderTopWidth: 0,
    borderLeftWidth: 10,
    borderColor: 'transparent',
  },
  tooltipText: {
    textAlign: 'left',
    color: 'rgb(234, 234, 234)',
    fontSize: 16,
    fontWeight: '500',
  },
  rightPositionContainer: {
    alignSelf: 'flex-end',
  },
  leftPositionContainer: {
    alignSelf: 'flex-start',
  },
  rightPositionTooltip: {
    alignSelf: 'flex-end',
    right: 10,
  },
  leftPositionTooltip: {
    alignSelf: 'flex-start',
    left: 10,
  },
});
