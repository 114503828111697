import { Variant } from '@warnermmedia/gsp-core/sdk/ui';
import { statusMsgStore } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { useCallback, useEffect, useState } from 'react';
import { useIsMountedRef } from '../useIsMountedRef';

interface StatusMessage {
  type: Variant;
  message: string | null;
}

interface StatusMessageProps {
  timeout?: number;
  shouldTimeout?: boolean;
}

interface StatusMessageActions {
  setStatusMessage: (props: StatusMessage | null) => void;
  cleanStatusMessage: () => void;
}

export const useStatusMessage = ({
  timeout = 3000,
  shouldTimeout = true,
}: StatusMessageProps = {}): StatusMessageActions => {
  const [clearMessageTimeoutId, setClearMessageTimeoutId] = useState<ReturnType<typeof setTimeout>>();
  const [message, setMessage] = useState<StatusMessage | null>(null);
  const isMountedRef = useIsMountedRef();

  const setStatusMessage = useCallback((props: StatusMessage | null) => {
    setMessage(props);
  }, []);

  const cleanStatusMessage = useCallback(() => {
    isMountedRef.current && setMessage(null);
    statusMsgStore({ ...statusMsgStore(), message: null });
  }, [isMountedRef]);

  useEffect(() => {
    if (message && isMountedRef.current) {
      statusMsgStore(message);
      shouldTimeout &&
        setClearMessageTimeoutId(
          setTimeout(() => {
            cleanStatusMessage();
          }, timeout)
        );
    }
    return () => clearTimeout(clearMessageTimeoutId);
  }, [message, isMountedRef, timeout]);

  return { setStatusMessage, cleanStatusMessage };
};
