import React from 'react';
import { View } from 'react-native';
import {
  CMSPageBuilder,
  LayoutType,
  LayoutWrapper,
  SkeletonDisplay,
  TitleType,
} from '@warnermmedia/gsp-core/brands/estadio/ui';
import {
  languageStrings,
  PagesUrl,
  QueryNames,
  useCMSQueryDataResults,
} from '@warnermmedia/gsp-core/brands/estadio/feature';
import { Page } from '@warnermmedia/gsp-core/brands/estadio/data-access';

export const Shows = () => {
  const { pageData: pageUriData, loading } = useCMSQueryDataResults<Page>({
    queryName: QueryNames.GET_PROGRAMS,
    uri: PagesUrl.Shows,
  });

  return (
    <View>
      {loading ? (
        <SkeletonDisplay route="/shows" />
      ) : (
        <LayoutWrapper
          layoutType={LayoutType.Full}
          titleType={TitleType.Left}
          pageTitle={languageStrings.default.showsPage}
          overrideChildrenWrapper={loading ? { paddingHorizontal: 0 } : {}}
        >
          <CMSPageBuilder data={pageUriData} />
        </LayoutWrapper>
      )}
    </View>
  );
};

export default Shows;
