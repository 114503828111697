import { CustomButton, MetaTags, TextContent } from '@warnermmedia/gsp-core/sdk/ui';
import React from 'react';
import { View } from 'react-native';
import { IconButton, useTheme } from 'react-native-paper';
import { breakpointsStateStore } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { LayoutType, LayoutWrapper, TitleType } from '../../layoutWrapper';
import { useReactiveVar } from '@apollo/client';
import { getStyles } from './subscriptionFailure.styles';
import {
  languageStrings,
  ScreenEventType,
  useMparticleScreenEvent,
} from '@warnermmedia/gsp-core/brands/estadio/feature';
import { FocusKeys } from '@warnermmedia/gsp-core/sdk/data-access';

interface SubscriptionFailureProps {
  handleTryAgain: () => void;
}

export const SubscriptionFailure = ({ handleTryAgain }: SubscriptionFailureProps) => {
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const { colors } = useTheme();
  const styles = getStyles(breakpoints, colors);
  useMparticleScreenEvent(ScreenEventType.SubscriptionFailure, languageStrings.default.subscriptionFailureContentTitle);

  return (
    <LayoutWrapper
      layoutType={LayoutType.Center}
      titleType={TitleType.Left}
      pageTitle={languageStrings.default.subscriptionFailurePage}
    >
      <MetaTags title={languageStrings.default.subscriptionFailureContentTitle} />
      <View style={styles.infoWrapper}>
        <IconButton
          style={styles.infoIcon}
          icon="alert-circle-outline"
          color={colors.tenantBackground.light.surfaceBase}
        />
        <View style={styles.infoTextWrapper}>
          <TextContent style={styles.info}>{languageStrings.default.subscriptionFailureInfo1}</TextContent>
          <TextContent style={styles.info}>{languageStrings.default.subscriptionFailureInfo2}</TextContent>
        </View>
      </View>
      <CustomButton
        label={languageStrings.default.tryAgainText}
        onPress={() => handleTryAgain()}
        mode="contained"
        btnStyle={[styles.btn, styles.homeBtn]}
        labelStyle={styles.btnLabel}
        focusKey={FocusKeys.SUBSCRIPTION_FAILURE}
      />
    </LayoutWrapper>
  );
};

export default SubscriptionFailure;
