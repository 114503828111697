import merge from 'lodash/merge';
import { StyleProp, ViewStyle, StyleSheet, TextStyle, ImageStyle } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';

interface Styles {
  landingOndemandWrapper: ViewStyle;
  landingContents: ViewStyle;
  landingOndemandImageWrapper: ViewStyle;
  landingOndemandImage: ImageStyle;
  liveEvent: ViewStyle;
  topHeader: ViewStyle;
  liveEventHeadline: TextStyle;
  ondemandHeadline: TextStyle;
  live: ViewStyle;
  liveIcon: ImageStyle;
  ondemandDescription: TextStyle;
  ondemandTextContent: ViewStyle;
  webContentLayout: ViewStyle;
}

export const getStyles = (breakpoints: Breakpoints, colors: ReactNativePaper.ThemeColors, width: number) => {
  const base: StyleProp<Styles> = {
    landingOndemandWrapper: {
      marginTop: 80,
      paddingHorizontal: 25,
      paddingBottom: 71,
    },
    landingContents: {},
    liveEvent: {},
    topHeader: {
      flexDirection: 'row',
      marginBottom: 16,
    },
    liveEventHeadline: {
      fontFamily: 'Oswald-Regular',
      fontSize: 33,
      textTransform: 'uppercase',
      lineHeight: 44,
      fontWeight: '600',
      color: colors.keyboardFocus.light,
    },
    live: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    liveIcon: {
      width: 58,
      height: 19,
      marginLeft: 14,
      alignItems: 'center',
    },
    landingOndemandImageWrapper: {
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      alignItems: 'center',
      marginBottom: 16,
    },
    landingOndemandImage: {
      width: '100%',
      height: width / 2.1,
      alignItems: 'center',
    },
    ondemandTextContent: {},
    ondemandHeadline: {
      fontFamily: 'Oswald-Regular',
      fontSize: 33,
      textTransform: 'uppercase',
      lineHeight: 44,
      fontWeight: '600',
      color: colors.keyboardFocus.light,
      marginBottom: 16,
    },
    ondemandDescription: {
      fontFamily: 'Rubik-Regular',
      fontSize: 16,
      lineHeight: 20,
      fontWeight: '400',
      color: colors.keyboardFocus.light,
      marginBottom: 22,
    },
    webContentLayout: {},
  };

  const mediaQueries =
    breakpoints.currentBreakpoints.isMedLg ||
    breakpoints.windowWidth === breakpoints.breakpointSizes.lg ||
    breakpoints.currentBreakpoints.isLgXl ||
    breakpoints.currentBreakpoints.isXlXxl ||
    breakpoints.currentBreakpoints.isXxl
      ? ({
          landingOndemandWrapper: {
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            marginTop: 132,
          },
          landingContents: {
            maxWidth: 1028,
            width: '100%',
            flexDirection: 'row',
          },
          liveEvent: {},
          landingOndemandImageWrapper: {
            alignItems: 'flex-start',
            width: '49%',
            alignSelf: 'flex-start',
          },
          landingOndemandImage: {
            alignItems: 'flex-start',
            width: '100%',
            maxHeight: 285,
          },
          ondemandTextContent: {},
          webContentLayout: {
            width: '35%',
            marginLeft: 103,
            justifyContent: 'center',
          },
        } as StyleProp<Styles>)
      : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
