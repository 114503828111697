import { ImageStyle, StyleProp, StyleSheet, TextStyle, ViewStyle } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';
import { BreakpointsSize, ScaleAction } from '@warnermmedia/gsp-core/brands/estadio/feature';

interface Styles {
  container: ViewStyle;
  navbar?: ViewStyle;
  navBarText?: TextStyle;
  row?: ViewStyle;
  chevron?: ViewStyle;
  logo?: ImageStyle;
  transparent?: ViewStyle;
}

export const getStyles = (breakpoints: Breakpoints, scaleSizing: ScaleAction, colors: ReactNativePaper.ThemeColors) => {
  const { scaleHeight, scaleWidth } = scaleSizing;
  const base: StyleProp<Styles> = {
    container: {
      padding: scaleSizing.scaleWidth(125, BreakpointsSize.Tv),
      width: '100%',
      height: '100%',
    },
    navbar: {
      marginBottom: scaleHeight(77, BreakpointsSize.Tv),
      justifyContent: 'center',
    },
    navBarText: {
      fontFamily: 'Rubik-Regular',
      fontWeight: '500',
      fontSize: scaleHeight(32, BreakpointsSize.Tv),
      lineHeight: scaleHeight(38, BreakpointsSize.Tv),
      color: colors.keyboardFocus.light,
      marginLeft: scaleWidth(9, BreakpointsSize.Tv),
      textTransform: 'capitalize',
    },
    row: {
      flexDirection: 'row',
      alignItems: 'center',
      alignSelf: 'flex-start',
    },
    chevron: {
      marginHorizontal: scaleWidth(16, BreakpointsSize.Tv),
      height: scaleHeight(24, BreakpointsSize.Tv),
      width: scaleHeight(24, BreakpointsSize.Tv),
    },
    transparent: {
      backgroundColor: 'transparent',
    },
  };
  return StyleSheet.create(base);
};
