import React, { useEffect, useState } from 'react';
import { Platform, TextStyle, View, ViewStyle } from 'react-native';
import Reaptcha from 'reaptcha';
import { Tooltip } from '../../tooltip/index';
import { TextContent } from '../../textcontent/index';
import { validateNonEmptyString } from '../../../utils/common';
import { Button, Checkbox, TextInput } from 'react-native-paper';
import styles from './loginform.styles';

export interface Data {
  username: string;
  password: string;
  rememberMe?: boolean;
}

export interface LoginFormProps {
  title?: string;
  subTitle?: string;
  usernameLabel?: string;
  invalidUsernameError?: string;
  invalidPasswordError?: string;
  usernamePlaceholder?: string;
  passwordLabel?: string;
  rememberMeText?: string;
  passwordPlaceholder?: string;
  forgotPassBtnText?: string;
  loginBtnText?: string;
  titleStyle?: TextStyle;
  subTitleStyle?: TextStyle;
  wrapperStyle?: ViewStyle;
  inputStyle?: ViewStyle;
  inputWrapperStyle?: ViewStyle;
  errorTextStyle?: ViewStyle;
  submitBtnStyle?: ViewStyle;
  loginBtnWrapperStyle?: ViewStyle;
  loginLabelStyle?: TextStyle;
  forgotPassBtnStyle?: ViewStyle;
  inputLabelStyle?: TextStyle;
  formHeaderStyle?: ViewStyle;
  formWrapperStyle?: TextStyle;
  rememberMeTextStyle?: TextStyle;
  forgotPassBtnWrapperStyle?: ViewStyle;
  forgotPassBtnLabelStyle?: TextStyle;
  handleForgotPasswordPress: () => void;
  handleLogin: (data: Data) => void;
  handleRecaptchaChange?: (value: string) => void;
  recaptchaSiteKey?: string;
  error?: string;
  checkboxColor?: 'string';
  inputIsFocusedColor?: 'string';
  inputHasErrorColor?: 'string';
  validateUsername: (username: string) => boolean;
  showForgotPassword?: boolean;
  renderRegisterBtn?: () => React.ReactNode;
}

export const LoginForm: React.FC<LoginFormProps> = ({
  titleStyle,
  subTitleStyle,
  title,
  subTitle,
  usernameLabel,
  invalidUsernameError,
  invalidPasswordError,
  usernamePlaceholder = 'Username',
  passwordLabel,
  rememberMeText,
  passwordPlaceholder,
  forgotPassBtnText,
  loginBtnText,
  wrapperStyle,
  inputStyle,
  inputWrapperStyle,
  errorTextStyle,
  submitBtnStyle,
  loginBtnWrapperStyle,
  loginLabelStyle,
  forgotPassBtnStyle,
  formWrapperStyle,
  formHeaderStyle,
  forgotPassBtnWrapperStyle,
  forgotPassBtnLabelStyle,
  rememberMeTextStyle,
  handleForgotPasswordPress,
  handleLogin,
  inputLabelStyle,
  error,
  checkboxColor,
  inputHasErrorColor,
  inputIsFocusedColor,
  recaptchaSiteKey,
  handleRecaptchaChange,
  validateUsername,
  showForgotPassword,
  renderRegisterBtn,
}) => {
  const [data, setData] = useState({
    username: 'testent1@mail.com',
    password: 'password',
    rememberMe: false,
  });
  const [inputError, setError] = useState({
    username: false,
    password: false,
  });
  const [focusObj, setFocus] = useState({
    username: false,
    password: false,
  });
  const [formError, setFormError] = useState(error);

  useEffect(() => {
    setFormError(error);
  }, [error]);

  const handleSubmit = () => {
    setFormError('');
    const usernameIsValid = validateUsername(data.username);
    const passwordIsValid = validateNonEmptyString(data.password);
    if (usernameIsValid && passwordIsValid) {
      return handleLogin(data);
    } else {
      setError({
        username: !usernameIsValid,
        password: !passwordIsValid,
      });
    }
  };

  const handleInputBorderState = (error: boolean, focus: boolean) => {
    if (error) {
      return {
        borderWidth: 1,
        borderColor: inputHasErrorColor || 'red',
      };
    }
    if (focus) {
      return {
        borderWidth: 1,
        borderColor: inputIsFocusedColor || 'blue',
      };
    }
    return {};
  };

  const isWeb = Platform.OS === 'web';
  const isIOS = Platform.OS === 'ios';

  return (
    <View style={[styles.wrapper, wrapperStyle]}>
      <View style={formHeaderStyle}>
        <TextContent style={[styles.title, titleStyle]}>{title || 'Welcome'}</TextContent>
        <TextContent style={[styles.subTitle, subTitleStyle]}>
          {subTitle || 'Input your credentials to log in.'}
        </TextContent>
      </View>
      <View style={formWrapperStyle}>
        {formError ? <TextContent style={[styles.errorText, errorTextStyle]}>{formError}</TextContent> : null}
        <View style={[styles.inputWrapper, inputWrapperStyle]}>
          {usernameLabel ? (
            <TextContent style={[styles.inputLabel, inputLabelStyle]}>{usernameLabel || 'Username'}</TextContent>
          ) : null}
          <TextInput
            placeholder={usernamePlaceholder}
            value={data.username}
            onChangeText={(username: string) => {
              setData((prevState) => ({
                ...prevState,
                username,
              }));
              if (inputError.username) {
                setError((prevState) => ({
                  ...prevState,
                  username: !validateUsername(data.username),
                }));
              }
            }}
            onBlur={() => {
              setFocus((prevState) => ({
                ...prevState,
                username: false,
              }));
              setError((prevState) => ({
                ...prevState,
                username: !validateUsername(data.username),
              }));
            }}
            onFocus={() => {
              setFocus((prevState) => ({
                ...prevState,
                username: true,
              }));
            }}
            textAlign="left"
            style={[styles.input, inputStyle, handleInputBorderState(inputError.username, focusObj.username)]}
            theme={{ colors: { text: 'red', primary: 'transparent' } }}
            underlineColor="transparent"
          />
          <Tooltip
            tooltipText={invalidUsernameError || 'Enter a valid Username to continue.'}
            shouldFloat
            opacity={1}
            visible={inputError.username}
            position="left"
          />
        </View>
        <View style={[styles.inputWrapper, inputWrapperStyle]}>
          {passwordLabel ? (
            <TextContent style={[styles.inputLabel, inputLabelStyle]}>{passwordLabel || 'Password'}</TextContent>
          ) : null}
          <TextInput
            placeholder={passwordPlaceholder ? passwordPlaceholder || 'Password' : ''}
            value={data.password}
            onChangeText={(password) => {
              setData((prevState) => ({
                ...prevState,
                password,
              }));
              if (inputError.password) {
                setError((prevState) => ({
                  ...prevState,
                  password: !validateNonEmptyString(data.password),
                }));
              }
            }}
            onBlur={() => {
              setFocus((prevState) => ({
                ...prevState,
                password: false,
              }));
              setError((prevState) => ({
                ...prevState,
                password: !validateNonEmptyString(data.password),
              }));
            }}
            onFocus={() => {
              setFocus((prevState) => ({
                ...prevState,
                password: true,
              }));
            }}
            textAlign="left"
            secureTextEntry
            style={[styles.input, inputStyle, handleInputBorderState(inputError.password, focusObj.password)]}
            theme={{ colors: { text: 'red', primary: 'transparent' } }}
            underlineColor="transparent"
          />
          <Tooltip
            tooltipText={invalidPasswordError || 'Please enter your password'}
            shouldFloat
            opacity={1}
            visible={inputError.password}
            position="left"
          />
        </View>
        {isWeb ? (
          <View style={styles.checkboxWrapper}>
            <Checkbox
              status={data.rememberMe ? 'checked' : 'unchecked'}
              onPress={() => {
                setData((prevState) => ({
                  ...prevState,
                  rememberMe: !data.rememberMe,
                }));
              }}
              uncheckedColor={checkboxColor || '#ff0085'}
              color={checkboxColor || '#ff0085'}
            />
            <TextContent style={[styles.rememberMeText, rememberMeTextStyle]}>
              {rememberMeText || 'Remember Me'}
            </TextContent>
          </View>
        ) : null}
        {isWeb && recaptchaSiteKey && handleRecaptchaChange ? (
          <Reaptcha sitekey={recaptchaSiteKey} onVerify={handleRecaptchaChange} />
        ) : null}
        <Button
          labelStyle={[styles.loginBtnText, loginLabelStyle]}
          style={[styles.loginBtnWrapper, loginBtnWrapperStyle]}
          contentStyle={[styles.loginBtn, submitBtnStyle]}
          mode="contained"
          onPress={() => handleSubmit()}
        >
          {loginBtnText || 'SUBMIT'}
        </Button>

        {showForgotPassword ? (
          <Button
            style={[styles.forgotPassBtnContainer, forgotPassBtnWrapperStyle]}
            labelStyle={[styles.forgotPassBtn, forgotPassBtnLabelStyle]}
            contentStyle={forgotPassBtnStyle}
            mode="text"
            onPress={() => handleForgotPasswordPress()}
          >
            {forgotPassBtnText || 'Forgot Password'}
          </Button>
        ) : null}

        {!isIOS && renderRegisterBtn ? renderRegisterBtn() : null}
      </View>
    </View>
  );
};

export default LoginForm;
