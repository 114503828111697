import React from 'react';
import Svg, { Circle, Rect } from 'react-native-svg';

/* eslint-disable-next-line */
export interface HamburgerProps {
  width?: number;
  height?: number;
  color?: string;
  iconColor?: string;
}

export function Hamburger(props: HamburgerProps) {
  const width = props.width || 20;
  const height = props.height || 20;
  const color = props.color || '#359fd7';
  const iconColor = props.iconColor || '#fff';

  return (
    <Svg fill="none" height={height} viewBox="0 0 20 20" width={width}>
      <Circle cx="10" cy="10" r="10" fill={color} />
      <Rect width="10" height="2" x="5" y="6" fill={iconColor} rx="1" />
      <Rect width="10" height="2" x="5" y="9" fill={iconColor} rx="1" />
      <Rect width="10" height="2" x="5" y="12" fill={iconColor} rx="1" />
    </Svg>
  );
}

export default Hamburger;
