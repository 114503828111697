import React, { useEffect, useState } from 'react';
import { useCMSPageData, carouselFormat } from '@warnermmedia/gsp-core/brands/estadio/feature';
import { componentTypes, Page, Product } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import Subscription from './subscription/subscription';

interface Props {
  data: Page | undefined;
  handleCTAPress?: (product: Product) => void;
  couponDeduction?: string;
  showMarketingImage?: boolean;
}

export const SubscriptionProducts = ({ data, handleCTAPress, couponDeduction, showMarketingImage }: Props) => {
  const [pageUriData, setPageUriData] = useState<Page>();
  const [display, setDisplay] = useState<carouselFormat[] | null>(null);
  const { formatData } = useCMSPageData();

  useEffect(() => {
    if (pageUriData && pageUriData.components) {
      setDisplay(formatData(pageUriData.components));
    }
  }, [pageUriData]);

  useEffect(() => {
    setPageUriData(data);
  }, [data]);

  const landingHero = display && display.filter(({ type }) => type === componentTypes.SCREEN);
  const products = landingHero ? landingHero[0]?.content : [];
  const productArray = products ? products[0]?.product : undefined;
  const product = productArray ? (productArray[0] as Product) : {};
  const marketingImage = landingHero ? landingHero[0].image?.url : '';

  return (
    <Subscription
      product={product}
      handleCTAPress={handleCTAPress}
      couponDeduction={couponDeduction}
      marketingImage={showMarketingImage ? marketingImage : ''}
    />
  );
};

export default SubscriptionProducts;
