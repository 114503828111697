import * as React from 'react';
import { View, StyleProp, ViewStyle, TextStyle } from 'react-native';
import { useTheme } from 'react-native-paper';
import { TextContent } from '../textcontent';
import styles from './tooltip.styles';

export interface TooltipProps {
  tooltipText: string;
  position?: 'right' | 'left' | 'center';
  opacity: number;
  visible: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  tooltipContainerShadow?: StyleProp<ViewStyle>;
  tooltipArrowStyle?: StyleProp<ViewStyle>;
  tooltipLabelStyle?: StyleProp<ViewStyle>;
  toolTipTextStyle?: StyleProp<TextStyle>;
  shouldFloat?: boolean;
}

export function Tooltip(props: TooltipProps) {
  const { colors } = useTheme();

  if (!props.visible) {
    return null;
  }

  const { tooltipText, position, opacity } = props;
  const isRightPositioned = 'right' === position;
  const isLeftPositioned = 'left' === position;

  return (
    <View
      style={[
        { opacity },
        styles.tooltipWrapper,
        isRightPositioned && styles.rightPositionContainer,
        isLeftPositioned && styles.leftPositionContainer,
        props.containerStyle,
      ]}
    >
      <View style={[styles.tooltipContainerShadow, props.tooltipContainerShadow]}>
        <View
          style={[
            { backgroundColor: colors.error },
            styles.tooltipContainer,
            props.shouldFloat && styles.floatingContainer,
            props.tooltipLabelStyle,
          ]}
        >
          <View
            style={[
              styles.tooltipArrow,
              { borderBottomColor: colors.error },
              isRightPositioned && styles.rightPositionTooltip,
              isLeftPositioned && styles.leftPositionTooltip,
              props.tooltipArrowStyle,
            ]}
          />
          <TextContent accessible={false} style={[styles.tooltipText, props.toolTipTextStyle]}>
            {tooltipText}
          </TextContent>
        </View>
      </View>
    </View>
  );
}

export default Tooltip;
