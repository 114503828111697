import merge from 'lodash/merge';
import { StyleProp, StyleSheet, TextStyle, ViewStyle } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';

interface Styles {
  topContent: ViewStyle;
  input: ViewStyle;
  inputFilled: ViewStyle;
  label: TextStyle;
  inputContainer: ViewStyle;
  instruction: TextStyle;
  btnWrapper: ViewStyle;
}

export const getStyles = (breakpoints: Breakpoints, colors: ReactNativePaper.ThemeColors) => {
  const base: StyleProp<Styles> = {
    input: {
      backgroundColor: colors.keyboardFocus.light,
      borderRadius: 5,
      borderWidth: 1,
      opacity: 0.8,
    },
    inputFilled: {
      backgroundColor: colors.keyboardFocus.light,
      borderRadius: 5,
      borderWidth: 1,
      opacity: 1,
    },
    topContent: {
      marginBottom: 32,
    },
    label: {
      color: colors.tenantBackground.light.surfaceBase,
    },
    inputContainer: {
      marginBottom: 15,
    },
    instruction: {
      fontSize: 16,
      marginBottom: 18,
      color: colors.keyboardFocus.light,
      fontWeight: 'normal',
    },
    btnWrapper: {
      height: 40,
    },
  };

  const mediaQueries =
    breakpoints.currentBreakpoints.isTiny ||
    breakpoints.currentBreakpoints.isTnySm ||
    breakpoints.currentBreakpoints.isSmMed
      ? ({} as StyleProp<Styles>)
      : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
