import React from 'react';
import { ImageAssets } from '@warnermmedia/gsp-core/brands/estadio/assets';
import { CustomButton, TextContent } from '@warnermmedia/gsp-core/sdk/ui';
import { Image, View } from 'react-native';
import { useReactiveVar } from '@apollo/client';
import { breakpointsStateStore } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { getStyles } from './payment.styles';
import { languageStrings } from '@warnermmedia/gsp-core/brands/estadio/feature';
import { useTheme } from 'react-native-paper';
import { FocusKeys } from '@warnermmedia/gsp-core/sdk/data-access';

interface Props {
  renderPayment: () => React.ReactNode;
  renderPayWithPayPal: () => React.ReactNode;
  setHasCoupon: (val: boolean) => void;
  isPaypal: boolean | null | undefined;
}

export const Payment = ({ renderPayment, setHasCoupon, renderPayWithPayPal, isPaypal }: Props) => {
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const { colors } = useTheme();
  const styles = getStyles(breakpoints, colors);

  return (
    <View style={styles.paymentWrapper}>
      <TextContent style={styles.payTitle}>{languageStrings.default.payTitleLabel}</TextContent>
      {!isPaypal && (
        <TextContent style={styles.payDescriptionLabel}>{languageStrings.default.payDescriptionLabel}</TextContent>
      )}
      <View style={styles.couponLabelWrapper}>
        <TextContent style={styles.couponLabelIcon}>+ </TextContent>
        <CustomButton
          onPress={() => {
            setHasCoupon(true);
          }}
          mode="text"
          label={languageStrings.default.couponButtonLabel}
          labelStyle={styles.couponLabel}
          focusKey={isPaypal ? FocusKeys.SUBSCRIPTION_PAYPAL : FocusKeys.SUBSCRIPTION_CARD}
        />
      </View>
      <View style={styles.paymentTypeWrapper}>
        {isPaypal ? (
          <View style={styles.paypalWrapper}>
            <View style={styles.payTypeHeader}>
              <TextContent style={styles.payLabel}>PayPal</TextContent>
              <View style={styles.cardWrapper}>
                <Image source={ImageAssets.payPal} style={styles.card} />
              </View>
            </View>
            {renderPayWithPayPal()}
          </View>
        ) : (
          <View>
            <View style={styles.payTypeHeader}>
              <TextContent style={styles.payLabel}>{languageStrings.default.payTypeHeaderLabel}</TextContent>
              <View style={styles.cardWrapper}>
                <Image source={ImageAssets.visa} style={styles.card} />
                <Image source={ImageAssets.masterCard} style={styles.card} />
                <Image source={ImageAssets.dinersClub} style={styles.card} />
                <Image source={ImageAssets.americanExpress} style={styles.card} />
              </View>
            </View>
            {<View>{renderPayment()}</View>}
          </View>
        )}
      </View>
    </View>
  );
};

export default Payment;
