import merge from 'lodash/merge';
import { StyleProp, StyleSheet, ViewStyle, TextStyle } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';

interface Styles {
  title: TextStyle;
  subtitle: TextStyle;
  inputWrapper: ViewStyle;
  input: TextStyle;
  btn: ViewStyle;
  anotherAccount: ViewStyle;
  underlinedText: TextStyle;
}

export const getStyles = (breakpoints: Breakpoints, colors: ReactNativePaper.ThemeColors) => {
  const base: StyleProp<Styles> = {
    title: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Oswald-Regular',
      fontSize: 22,
      fontWeight: '600',
      lineHeight: 33,
      fontStyle: 'normal',
    },
    subtitle: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Rubik-Regular',
      fontSize: 16,
      fontWeight: '400',
      lineHeight: 20,
      fontStyle: 'normal',
    },
    inputWrapper: {
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: 32,
    },
    input: {
      paddingHorizontal: 10,
      borderRadius: 4,
      height: 40,
      width: 40,
      backgroundColor: colors.tenantBackground.light.surfaceBase,
      color: colors.foreground.onLight.text01,
      fontFamily: 'Oswald-Regular',
      fontSize: 22,
      fontWeight: '600',
      lineHeight: 33,
      borderColor: 'transparent',
      textAlign: 'center',
      marginHorizontal: 8,
    },
    btn: {
      marginTop: 28,
      borderWidth: 0,
    },
    anotherAccount: {
      alignSelf: 'flex-start',
      paddingHorizontal: 0,
      marginTop: 32,
    },
    underlinedText: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Oswald-Regular',
      fontSize: 14,
      fontWeight: 'normal',
      lineHeight: 21,
      textDecorationLine: 'underline',
      textTransform: 'uppercase',
    },
  };

  const mediaQueries =
    breakpoints.currentBreakpoints.isTiny ||
    breakpoints.currentBreakpoints.isTnySm ||
    breakpoints.currentBreakpoints.isSmMed
      ? ({} as StyleProp<Styles>)
      : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
