import React from 'react';
import Svg, { Circle, ClipPath, Defs, G, Path } from 'react-native-svg';

/* eslint-disable-next-line */
export interface UrlProps {
  width?: number;
  height?: number;
  color?: string;
  iconColor?: string;
}

export function Url(props: UrlProps) {
  const width = props.width || 20;
  const height = props.height || 20;
  const color = props.color || '#359fd7';
  const iconColor = props.iconColor || '#fff';

  return (
    <Svg fill="none" height={height} viewBox="0 0 20 20" width={width}>
      <Circle cx="10" cy="10" r="10" fill={color} />
      <G fill={iconColor} clip-path="url(#clip0)">
        <Path d="M14.384 5h-3.439c-.34 0-.616.277-.616.616 0 .34.276.617.616.617h1.95L9.643 9.486c-.116.117-.18.271-.18.436 0 .165.064.32.18.436.117.116.271.18.436.18.165 0 .32-.064.436-.18l3.253-3.254v1.95c0 .34.277.617.617.617.34 0 .616-.276.616-.616V5.616c0-.34-.276-.616-.616-.616z" />
        <Path d="M12.084 13.943H6.057V7.916h4.533l1.057-1.057H5.528c-.291 0-.528.237-.528.528v7.085c0 .291.237.528.528.528h7.085c.291 0 .528-.237.528-.528V8.353L12.084 9.41v4.533z" />
      </G>
      <Defs>
        <ClipPath id="clip0">
          <Path fill={iconColor} d="M0 0H10V10H0z" transform="translate(5 5)" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default Url;
