import { useEffect } from 'react';
import { useGoBack } from '@warnermmedia/gsp-core/brands/estadio/feature';

export const useTizen = (device: string): void => {
  const { goBack } = useGoBack();

  const onButtonHandler = (event: KeyboardEvent) => {
    if (event.keyCode === 10009) goBack();
  };

  useEffect(() => {
    if (device === 'tizen') window.document.addEventListener('keydown', onButtonHandler, false);
    return () => {
      if (device === 'tizen') window.document.removeEventListener('keydown', onButtonHandler);
    };
  }, []);
};

export default useTizen;
