import Axios, { AxiosError, AxiosResponse } from 'axios';
import { AxiosTypes, SendSDKResponse } from '../types';
import { get } from 'lodash';

export async function callApiEndpoint(
  axiosType: string,
  baseApiPath: string,
  endpointPath: string,
  body: string | null,
  /* eslint-disable @typescript-eslint/no-explicit-any */
  headers?: any,
  userToken?: string,
  signal?: AbortSignal
): Promise<AxiosResponse | AxiosError | null> {
  const axiosConfig = {
    headers: headers
      ? headers
      : {
          'Content-Type': 'application/json',
          ...(userToken ? { Authorization: `${userToken}` } : {}),
        },
    signal,
  };

  try {
    let response = null;

    const url = `${baseApiPath}${endpointPath}`;
    switch (axiosType) {
      case AxiosTypes.GET:
        response = await Axios.get<AxiosResponse>(url, axiosConfig);
        break;
      case AxiosTypes.PUT:
        response = await Axios.put<AxiosResponse>(url, body, axiosConfig);
        break;
      case AxiosTypes.DELETE:
        response = await Axios.delete<AxiosResponse>(url, axiosConfig);
        break;
      default:
        response = await Axios.post<AxiosResponse>(url, body, axiosConfig);
    }
    return response;
  } catch (e) {
    return e as AxiosError;
  }
}
/* eslint-disable @typescript-eslint/no-explicit-any */
export function createApiResponse<T>(response: AxiosResponse<T> | null | AxiosError<any>): SendSDKResponse<T> {
  const successResponse = response as AxiosResponse;
  const errorResponse = response as AxiosError;
  if (successResponse && [200, 202, 220, 222].includes(successResponse.status)) {
    return {
      success: true,
      data: successResponse.data,
    };
  }

  return {
    success: false,
    error: errorResponse,
  };
}

export function getQueryParams<T>(params: T): string {
  return Object.keys(params)
    .map((key, index) => {
      const value = get(params, key, '');
      return index === 0 ? `?${key}=${value}` : `&${key}=${value}`;
    })
    .join('');
}
