import { useEffect, useState } from 'react';
import { Page, removePageNavHeader } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import {
  formatAssetTitle,
  languageStrings,
  PagesUrl,
  PLAYER_MAX_BITRATE,
  PLAYER_START_BITRATE,
  QueryNames,
  useCMSPageMetaTags,
  useCMSQueryDataResults,
  useIsMountedRef,
  useMparticleCustomEventObject,
  useMparticleScreenEvent,
  useVideoTag,
} from '@warnermmedia/gsp-core/brands/estadio/feature';
import { useReactiveVar } from '@apollo/client';
import { useRefreshToken } from '../useRefreshToken';
import { getDiff, now } from '@warnermmedia/gsp-core/sdk/ui';
import { MParticleCustomEventTypes, mParticleEventProcessor } from '@warnermmedia/gsp-core/sdk/data-access';

export const WITH_MEDIA_CONFIG = {
  startBitrate: PLAYER_START_BITRATE,
  maxBitrate: PLAYER_MAX_BITRATE,
  capBitrateToSize: false,
};

export enum LivePageFormat {
  SenalTv = 'senaltv',
  GolfChannel = 'golfchannel',
}

export const useLiveTvActions = (format: LivePageFormat, uri: PagesUrl) => {
  const [authToken, setAuthToken] = useState<string | null>(null);
  const [mediaId, setMediaId] = useState<string | null>(null);
  const [playerStartTime] = useState<string>(now().format());
  const removeNav = useReactiveVar(removePageNavHeader);
  const { pageData: pageUriData } = useCMSQueryDataResults<Page>({
    queryName: QueryNames.GET_COMMON_PAGE_DATA,
    uri,
  });
  const { type, title, cmsId, metaTags } = useCMSPageMetaTags(pageUriData);
  useMparticleScreenEvent(type, title, cmsId);
  const { refreshUserToken } = useRefreshToken();
  const mParticleEventData = useMparticleCustomEventObject();
  const isMountedRef = useIsMountedRef();
  const defaultTitle =
    format === LivePageFormat.SenalTv ? languageStrings.default.signalTvPage : languageStrings.default.golfChannelPage;
  const tags = useVideoTag({
    mediaId: mediaId ?? undefined,
    isLive: true,
    title: formatAssetTitle(title || defaultTitle),
  });

  const init = async () => {
    const authToken = await refreshUserToken();
    if (isMountedRef.current && authToken) {
      setAuthToken(authToken);
    }
  };

  useEffect(() => {
    if (isMountedRef.current) {
      init();
    }
  }, [isMountedRef]);

  useEffect(() => {
    if (isMountedRef.current && mediaId && authToken) {
      mParticleEventProcessor.pushMParticleEvent(MParticleCustomEventTypes.PlayerFetchDataCompleteEvent, {
        ...mParticleEventData,
        ...{ player_timer: getDiff(playerStartTime) },
      });
    }
  }, [mediaId, authToken]);

  useEffect(() => {
    if (isMountedRef.current && pageUriData) {
      const senalObj =
        pageUriData &&
        pageUriData?.components &&
        pageUriData?.components?.length > 0 &&
        pageUriData?.components.find((el) => {
          return el?.format === format && el.weight === 0;
        });
      const id = senalObj && senalObj?.content && senalObj?.content?.length > 0 && senalObj?.content[0]?.liveStream;
      id && setMediaId(id);
    }
  }, [isMountedRef, pageUriData]);

  return {
    authToken,
    removeNav,
    mediaId,
    metaTags,
    title,
    tags,
    playerStartTime,
    withMediaConfig: WITH_MEDIA_CONFIG,
  };
};
