import React, { useRef } from 'react';
import { Image, TouchableOpacity } from 'react-native';
import { ItemName, TveProvider } from '@warnermmedia/gsp-core/sdk/data-access';
import styles from './provider.styles';
import { getUniqueId } from '@warnermmedia/gsp-core/brands/estadio/feature';
import { useSpatialNavigation } from '../spatialNavigation';

export interface ProviderRenderProps {
  onPress: (provider: TveProvider) => void;
  provider: TveProvider;
  disabled?: boolean;
  dataLength: number;
  index: number;
  focusKey?: string | null;
}

export function Provider({ onPress, provider, disabled, dataLength, index, focusKey }: ProviderRenderProps) {
  const providerFocusKey = useRef(focusKey ?? getUniqueId(ItemName.PROVIDER_ITEM)).current;
  const { ref, focusSelf } = useSpatialNavigation({
    focusKey: providerFocusKey,
    onEnterPress: () => {
      onPress(provider);
    },
    onArrowPress: (direction) => {
      if (direction === 'right' && dataLength === index) {
        focusSelf();
        return false;
      }

      if (
        direction === 'right' &&
        document &&
        (document?.activeElement?.nextElementSibling as HTMLElement)?.offsetLeft === 0
      ) {
        focusSelf();
      }

      if (direction === 'left' && document && (document?.activeElement as HTMLElement)?.offsetLeft === 0) {
        focusSelf();
      }

      return true;
    },
  });

  return (
    <TouchableOpacity
      disabled={disabled}
      key={provider.shortName}
      style={styles.provider}
      onPress={() => onPress(provider)}
      ref={ref}
    >
      <Image
        source={{
          uri: provider.logo,
        }}
        style={styles.providerLogoImage}
        resizeMode="contain"
      />
    </TouchableOpacity>
  );
}

export default Provider;
