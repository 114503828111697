import React from 'react';
import { defaultTheme, PaperProvider } from '@warnermmedia/gsp-core/sdk/ui';

interface ThemeProps {
  children?: React.ReactNode;
}

export interface EstadioColorToken {
  tenantBackground: {
    dark: {
      surfaceBase: string;
      surfaceBaseFilmGrad: {
        start: string;
        end: string;
      };
      surface02: string;
      surface02FilmGrad: {
        start: string;
        end: string;
      };
      surface02OndemandGrad: {
        start: string;
        end: string;
      };
      surface03: string;
      surface04: string;
      surface04FilmGrad: {
        start: string;
        end: string;
      };
      surface05: {
        start: string;
        end: string;
      };
      scrim01: string;
      notifySuccess: string;
      notifyError: string;
      modalBackground: string;
    };
    light: {
      surfaceBase: string;
      surfaceFilm: string;
      surfaceBaseGlass: string;
    };
  };
  fill: {
    action: {
      accent01: string;
      accent01Pressed: string;
      disabled01: string;
      accent02: string;
      accent03: string;
      accent04: string;
    };
  };
  foreground: {
    onDark: {
      text01: string;
      text02: string;
    };
    onLight: {
      actionAccent01: string;
      text01: string;
    };
  };
  stroke: {
    onDark: {
      outline01: string;
      outline02: string;
      outline03: string;
      outlineError: string;
    };
    onLight: {
      outline01: string;
    };
  };
  keyboardFocus: {
    light: string;
    dark: string;
  };
}

const colorToken: EstadioColorToken = {
  tenantBackground: {
    dark: {
      surfaceBase: '#191C36',
      surfaceBaseFilmGrad: {
        start: '#191C3600',
        end: '#191C36',
      },
      surface02: '#070C2E',
      surface02FilmGrad: {
        start: '#070C2E00',
        end: '#070C2E',
      },
      surface02OndemandGrad: {
        start: '#070C2E',
        end: '#00005E',
      },
      surface03: '#000A5A',
      surface04: '#220686',
      surface04FilmGrad: {
        start: '#22068600',
        end: '#220686',
      },
      surface05: {
        start: '#5600CD',
        end: '#070C2E',
      },
      scrim01: '#00000080',
      notifySuccess: '#00B263',
      notifyError: '#CC1F47',
      modalBackground: 'rgba(0,0,0, .5)',
    },
    light: {
      surfaceBase: '#FFFFFF',
      surfaceFilm: '#FFFFFF80',
      surfaceBaseGlass: '#FFFFFF20',
    },
  },
  fill: {
    action: {
      accent01: '#FF0085',
      accent01Pressed: '#B5015B',
      disabled01: '#191C3660',
      accent02: '#359FD7',
      accent03: '#2D88C6',
      accent04: '#220686',
    },
  },
  foreground: {
    onDark: {
      text01: '#FFFFFF',
      text02: '#EAEAEA',
    },
    onLight: {
      actionAccent01: '#000A5A',
      text01: '#000000',
    },
  },
  stroke: {
    onDark: {
      outline01: '#FFFFFF',
      outline02: '#FFFFFF40',
      outline03: '#359FD701',
      outlineError: '#CC1F47',
    },
    onLight: {
      outline01: '#000000',
    },
  },
  keyboardFocus: {
    light: '#FFFFFF',
    dark: '#000000',
  },
};

export const estadioTheme = {
  ...defaultTheme,
  colors: {
    ...defaultTheme.colors,
    navbarBackground: '#5600cc',
    navItemHoverBackground: '#070c2e',
    navItemHoverColor: '#fff',
    navItemActiveBackground: '#fff',
    itemFocusedOutlineColor: '#fff',
    navItemActiveColor: '#2b0594',
    navbarColor: '#fff',
    text: '#fff',
    ...colorToken,
  },
  defaults: {
    fontSize: 16,
    fontWeight: '500',
    textAlign: 'left',
  },
  messages: {
    disabledLabelColor: '',
    disabledBackgroundColor: '#ff0085',
    success: {
      icon: 'check',
      labelColor: '#fff',
      backgroundColor: '#0b7d3a',
    },
    error: {
      icon: 'alert-circle-outline',
      labelColor: '#fff',
      backgroundColor: '#cc1f47',
    },
    warning: {
      icon: 'alert-circle-outline',
      labelColor: '#fff',
      backgroundColor: '#ff9966',
    },
    generic: {
      icon: 'alert-circle-outline',
      labelColor: '#fff',
      backgroundColor: '#ff0085',
    },
  },
};

export function Theme(props: ThemeProps) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <PaperProvider theme={estadioTheme}>{props.children}</PaperProvider>;
}

export default Theme;
