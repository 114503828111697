import React from 'react';
import { Image, ImageStyle, TextStyle, View, ViewStyle } from 'react-native';
import { useReactiveVar } from '@apollo/client';
import { TextContent, useGetDevice } from '@warnermmedia/gsp-core/sdk/ui';
import { breakpointsStateStore } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { useTheme } from 'react-native-paper';

import { StatusMessage } from './statusMessage';

import { getStyles } from './layoutWrapper.styles';

export enum TitleType {
  Center = 'center',
  Left = 'left',
}

export enum LayoutType {
  Center = 'center',
  Full = 'full',
}

interface LayoutProps {
  pageTitle?: string;
  subTitle?: string;
  titleImage?: string;
  children: React.ReactNode;
  titleType: TitleType;
  layoutType: LayoutType;
  overrideWrapperStyle?: ViewStyle;
  overrideTitleWrapperStyle?: ViewStyle;
  overrideTitleStyle?: TextStyle;
  overrideSubTitleStyle?: TextStyle;
  overrideTitleImageStyle?: ImageStyle;
  overrideChildrenWrapper?: ViewStyle;
}

export const LayoutWrapper: React.FC<LayoutProps> = ({
  children,
  pageTitle = '',
  subTitle = '',
  titleImage,
  titleType,
  layoutType,
  overrideWrapperStyle,
  overrideTitleWrapperStyle,
  overrideTitleStyle,
  overrideSubTitleStyle,
  overrideTitleImageStyle,
  overrideChildrenWrapper,
}) => {
  const { isWeb, isTv } = useGetDevice();
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const { colors } = useTheme();
  const styles = getStyles(breakpoints, isWeb, isTv, colors);
  const isCenterLayout = layoutType === LayoutType.Center;
  const layoutTitleStyle = isCenterLayout ? styles.centerContainerTitle : styles.fullContainerTitle;
  const wrapperStyle = isCenterLayout ? styles.centeredWrapper : styles.fullWidthWrapper;
  const titleWrapperStyle = titleType === TitleType.Center ? styles.centerTitleWrapper : styles.leftTitleWrapper;
  const subTitleStyle = isCenterLayout ? styles.centerSubTitle : styles.leftSubTitle;
  const titleStyle = isCenterLayout ? styles.centerLayoutTitle : styles.fullLayoutTitle;

  return (
    <View style={styles.container}>
      <View style={[wrapperStyle, overrideWrapperStyle]}>
        <View style={styles.messageContainer}>
          <StatusMessage />
        </View>
        {pageTitle ? (
          <View style={[styles.titleContainer, titleWrapperStyle, layoutTitleStyle, overrideTitleWrapperStyle]}>
            {titleImage ? (
              <View>
                <Image style={[styles.titleImage, overrideTitleImageStyle]} source={{ uri: titleImage }} />
              </View>
            ) : null}
            <View style={styles.titleTextContainer}>
              <TextContent accessible={false} style={[styles.title, titleStyle, overrideTitleStyle]}>
                {pageTitle}
              </TextContent>
              {subTitle ? (
                <TextContent style={[styles.subTitle, subTitleStyle, overrideSubTitleStyle]}>{subTitle}</TextContent>
              ) : null}
            </View>
          </View>
        ) : null}
        <View style={[styles.childrenWrapper, overrideChildrenWrapper]}>{children}</View>
      </View>
    </View>
  );
};

export default LayoutWrapper;
