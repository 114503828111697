import { useContext, useEffect } from 'react';
import { CID, HistoryContext } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { getUrlParam, useIsMountedRef } from '@warnermmedia/gsp-core/brands/estadio/feature';
import { saveData } from '@warnermmedia/gsp-core/sdk/data-access';

export const useSetCampaignId = () => {
  const historyContext = useContext(HistoryContext);
  const history = historyContext?.ready ? historyContext?.useHistory() : null;
  const cid = history ? getUrlParam('cid', history.location.search) : '';
  const isMountedRef = useIsMountedRef();

  useEffect(() => {
    if (isMountedRef.current && cid) {
      saveData(CID, cid);
    }
  }, [isMountedRef, cid]);
};

export default useSetCampaignId;
