import { mParticleEventProcessor, VideoErrorMessages } from '@warnermmedia/gsp-core/sdk/data-access';
import { Maybe } from '@warnermmedia/gsp-core/sdk/util';

import { MParticleAppConfigObject, MParticleCustomEventTypes } from '../mParticleEventProcessor';

export const recordVideoMparticleError = (errorMessage: string, mParticleEventData: MParticleAppConfigObject) => {
  let eventType: Maybe<MParticleCustomEventTypes> = undefined;

  if (errorMessage === VideoErrorMessages.GEOBLOCK_EVENT_ERROR) {
    eventType = MParticleCustomEventTypes.GeoblockEvent;
  } else if (errorMessage === VideoErrorMessages.CONCURRENCY_LIMIT_EVENT_ERROR) {
    eventType = MParticleCustomEventTypes.ConcurrencyLimitEvent;
  }

  if (eventType) {
    mParticleEventProcessor.pushMParticleEvent(eventType, mParticleEventData);
  }
};
