import { StyleProp, StyleSheet, TextStyle, ViewStyle } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';
import { merge } from 'lodash';

interface Styles {
  productWrapper: ViewStyle;
  duration: TextStyle;
  price: TextStyle;
  headline: TextStyle;
  details: TextStyle;
  disclaimer: TextStyle;
}

export const getStyles = (breakpoints: Breakpoints) => {
  const base: StyleProp<Styles> = {
    productWrapper: {
      width: '100%',
      borderRadius: 5,
      justifyContent: 'center',
      alignItems: 'center',
      marginVertical: 5,
      paddingHorizontal: 20,
      paddingVertical: 30,
    },
    duration: {
      fontFamily: 'Rubik-Regular',
      fontSize: 12,
      fontWeight: 'normal',
      lineHeight: 18,
      marginBottom: 10,
    },
    price: {
      fontFamily: 'Oswald-SemiBold',
      fontSize: 33,
      fontWeight: '600',
      lineHeight: 49,
    },
    headline: {
      fontFamily: 'Rubik-Regular',
      fontSize: 16,
      fontWeight: 'normal',
      lineHeight: 21,
      marginBottom: 7,
      textAlign: 'center',
    },
    details: {
      fontFamily: 'Rubik-Regular',
      fontSize: 14,
      fontWeight: '600',
      lineHeight: 20,
      maxWidth: 600,
      textAlign: 'center',
    },
    disclaimer: {
      fontFamily: 'Rubik-Regular',
      fontSize: 12,
      fontWeight: '600',
      lineHeight: 18,
      marginTop: 10,
    },
  };

  const mediaQueries =
    breakpoints.windowWidth <= 342
      ? ({
          productWrapper: {
            height: 340,
            width: '100%',
          },
        } as StyleProp<Styles>)
      : breakpoints.currentBreakpoints.isMedLg || breakpoints.currentBreakpoints.isLgXl
      ? ({
          productWrapper: {
            height: 226,
            width: '100%',
          },
          headline: {
            marginBottom: 10,
          },
        } as StyleProp<Styles>)
      : breakpoints.currentBreakpoints.isXlXxl
      ? ({
          productWrapper: {
            height: 272,
            width: '100%',
          },
        } as StyleProp<Styles>)
      : breakpoints.currentBreakpoints.isXlXxl || breakpoints.currentBreakpoints.isXxl
      ? ({
          productWrapper: {
            height: 272,
            width: '100%',
          },
        } as StyleProp<Styles>)
      : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
