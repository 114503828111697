import React, { useRef } from 'react';
import { getUniqueId } from '@warnermmedia/gsp-core/brands/estadio/feature';
import { ItemName } from '@warnermmedia/gsp-core/sdk/data-access';
import { useSpatialNavigation } from '@warnermmedia/gsp-core/sdk/ui';

export interface OverlayPlayerButtonProps {
  icon: JSX.Element;
  onClick: () => void;
  focusKey?: string;
}

export const OverlayPlayerButton = ({ icon, onClick, focusKey }: OverlayPlayerButtonProps) => {
  const buttonFocusKey = useRef(focusKey ?? getUniqueId(ItemName.PLAYER_BUTTON)).current;
  const { ref } = useSpatialNavigation({
    focusKey: buttonFocusKey,
    onEnterPress: () => {
      onClick();
    },
  });

  return (
    <div className="overlay-modal">
      <div onClick={onClick} tabIndex={0} className="replay-modal" ref={ref} id={buttonFocusKey}>
        <button>{icon}</button>
      </div>
    </div>
  );
};

export default OverlayPlayerButton;
