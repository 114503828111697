export enum AmazonEvents {
  getUserIdResponse = 'getUserIdResponse',
  purchaseResponse = 'purchaseResponse',
  getPurchaseUpdatesResponse = 'getPurchaseUpdatesResponse',
  getEntitlements = 'itemDataResponse',
  getSubscriptions = 'itemDataResponse',
  sdkAvailable = 'sdkAvailable',
}

export interface AmazonIap {
  addListener: (eventName: AmazonEvents, listener: (event: never) => void) => void;
  removeListener: (eventName: AmazonEvents) => void;
  purchase: (sku: string) => void;
  getItemData: (skus: Array<string>) => void;
}

export enum GetUserIdRequestStatus {
  SUCCESSFUL = 'SUCCESSFUL',
  FAILED = 'FAILED',
}

export enum ItemDataRequestStatus {
  INVALID_INPUT = 'INVALID_INPUT',
  SUCCESSFUL = 'SUCCESSFUL',
  FAILED = 'FAILED',
  SUCCESSFUL_WITH_UNAVAILABLE_SKU = 'SUCCESSFUL_WITH_UNAVAILABLE_SKU',
}

export enum PurchaseRequestStatus {
  INVALID_INPUT = 'INVALID_INPUT',
  SUCCESSFUL = 'SUCCESSFUL',
  FAILED = 'FAILED',
  INVALID_SKU = 'INVALID_SKU',
  ALREADY_ENTITLED = 'ALREADY_ENTITLED',
}

export enum PurchaseUpdatesRequestStatus {
  INVALID_INPUT = 'INVALID_INPUT',
  SUCCESSFUL = 'SUCCESSFUL',
  FAILED = 'FAILED',
}

export interface OperationalError {
  error: string;
}

export interface UserIdResponse {
  requestId: string;
  getUserIdRequestStatus: GetUserIdRequestStatus;
  userId: string;
}

export interface ProductDataResponse {
  requestId: string;
  getUserIdRequestStatus: GetUserIdRequestStatus;
  userId: string;
}

export interface UserIdResponseEvent {
  response: UserIdResponse;
}

export enum ItemType {
  CONSUMABLE = 'CONSUMABLE',
  ENTITLEMENT = 'ENTITLEMENT',
  SUBSCRIPTIONS = 'SUBSCRIPTIONS',
}

export interface IapItem {
  itemType: ItemType;
  price: string;
  title: string;
  description: string;
  smallIconUrl: string;
}

export interface ItemDataResponse {
  requestId: string;
  itemDataRequestStatus: ItemDataRequestStatus;
  itemData: {
    [sku: string]: IapItem;
  };
}

export interface ItemDataResponseEvent {
  response: ItemDataResponse;
}

export interface PurchaseReceipt extends Receipt {
  userId: string;
}
export interface Receipt {
  sku: string;
  itemType: ItemType;
  purchaseToken: string;
  cancelDate: string | null;
  subscriptionPeriod: {
    startDate: string;
    endDate: string;
  } | null; // (null for non-subscription receipts)
}

export interface AmazonIAPPurchaseResponse {
  requestId: string;
  userId: string;
  purchaseRequestStatus: PurchaseRequestStatus;
  receipt: Receipt;
}

export interface PurchaseResponseEvent {
  response: AmazonIAPPurchaseResponse;
}

export interface PurchaseUpdatesResponse {
  requestId: string;
  purchaseUpdatesRequestStatus: PurchaseUpdatesRequestStatus;
  receipts: Array<Receipt>;
  revokedSkus: Array<string>;
  offSet: string | null;
  hasMore: boolean;
}

export interface PurchaseUpdatesResponseEvent {
  response: PurchaseUpdatesResponse;
}

// export interface IapModel {
//   smallIconUrl: string
//   title: string
//   itemType: string
//   price: number
//   description: string
//   languageTitleMap: LanguageTitleMap
//   languageDescriptionMap: LanguageDescriptionMap
//   currencyPriceMap: CurrencyPriceMap
// }

// export interface LanguageTitleMap {
//   US: string
// }

// export interface LanguageDescriptionMap {
//   US: string
// }

// export interface CurrencyPriceMap {
//   BR: number
//   DE: number
//   AU: number
//   IN: number
//   JP: number
//   GB: number
//   IT: number
//   FR: number
//   US: number
//   ES: number
//   CA: number
// }
