import React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';

/* eslint-disable-next-line */
export interface TwitterProps {
  width?: number;
  height?: number;
  color?: string;
  iconColor?: string;
}

export function Twitter(props: TwitterProps) {
  const width = props.width || 20;
  const height = props.height || 20;
  const color = props.color || '#359fd7';
  const iconColor = props.iconColor || '#fff';

  return (
    <Svg fill="none" height={height} viewBox="0 0 28 28" width={width}>
      <Circle cx="14" cy="14" r="14" fill={color} />
      <Path
        d="M 15.3319 12.8482 L 20.5437 6.5 H 19.3087 L 14.7833 12.0121 L 11.1688 6.5 H 7 L 12.4657 14.8352 L 7 21.4923 H 8.2351 L 13.0141 15.6714 L 16.8312 21.4923 H 21 L 15.3316 12.8482 H 15.3319 Z M 13.6403 14.9087 L 13.0865 14.0787 L 8.6801 7.4743 H 10.5772 L 14.1331 12.8042 L 14.6869 13.6342 L 19.3093 20.5623 H 17.4122 L 13.6403 14.909 V 14.9087 Z"
        fill={iconColor}
      />
    </Svg>
  );
}

export default Twitter;
