/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import React from 'react';
import { Maybe } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { estadioComponent } from './cmsPageData';
import { carouselBase } from '../../Utils/Interfaces';
import get from 'lodash/get';
import { TeamListItem } from '@warnermmedia/gsp-core/brands/estadio/ui';

interface CMSTeamsDataProps {
  formatTeamData(queryData: Maybe<estadioComponent>[]): carouselBase[];
  formatTeamItem(teams: carouselBase[]): TeamListItem[];
}

export const useCMSTeamsData = (): CMSTeamsDataProps => {
  const formatTeamData = (data: Maybe<estadioComponent>[]): carouselBase[] => {
    return get(data, '[0].allItems', []).filter((v: carouselBase) => !!v);
  };

  const formatTeamItem = (teams: carouselBase[]): TeamListItem[] => {
    return teams.map((competitor: carouselBase) => ({
      value: competitor?.title ?? '',
      label: competitor?.title ?? '',
    }));
  };

  return {
    formatTeamData: (queryData: Maybe<estadioComponent>[]): carouselBase[] => formatTeamData(queryData),
    formatTeamItem: (teams: carouselBase[]): TeamListItem[] => formatTeamItem(teams),
  };
};
