import { ImageStyle, StyleProp, StyleSheet, TextStyle, ViewStyle } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';
import { merge } from 'lodash';

interface Styles {
  paymentWrapper: ViewStyle;
  payTitle: TextStyle;
  couponLabel: TextStyle;
  payLabel: TextStyle;
  couponLabelWrapper: ViewStyle;
  couponLabelIcon: TextStyle;
  paymentTypeWrapper: ViewStyle;
  payTypeHeader: ViewStyle;
  cardWrapper: ViewStyle;
  paypalWrapper: ViewStyle;
  card: ImageStyle;
  doNotRefreshLabel: TextStyle;
  payDescriptionLabel: TextStyle;
}

export const getStyles = (breakpoints: Breakpoints, colors: ReactNativePaper.ThemeColors) => {
  const base: StyleProp<Styles> = {
    paymentWrapper: {
      width: '100%',
      borderRadius: 5,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: colors.tenantBackground.dark.surface04,
      marginVertical: 5,
      paddingVertical: 25,
      paddingHorizontal: 20,
      height: '100%',
    },
    payTitle: {
      fontFamily: 'Rubik-Regular',
      fontSize: 18,
      fontWeight: '600',
      lineHeight: 20,
    },
    payDescriptionLabel: {
      fontFamily: 'Rubik-Regular',
      fontSize: 14,
      fontWeight: '600',
      lineHeight: 20,
      maxWidth: 600,
      textAlign: 'center',
      marginTop: 10,
    },
    couponLabel: {
      fontFamily: 'Oswald-SemiBold',
      fontSize: 14,
      fontWeight: '600',
      lineHeight: 21,
      textTransform: 'uppercase',
      textDecorationLine: 'underline',
      textDecorationStyle: 'solid',
      textDecorationColor: colors.tenantBackground.light.surfaceBase,
      color: colors.keyboardFocus.light,
    },
    payLabel: {
      fontFamily: 'Rubik-Medium',
      fontSize: 16,
      fontWeight: '500',
      lineHeight: 18,
    },
    couponLabelWrapper: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginVertical: 15,
    },
    couponLabelIcon: {
      fontFamily: 'Oswald-SemiBold',
      fontSize: 20,
      fontWeight: '600',
      height: 18,
      lineHeight: 18,
    },
    paymentTypeWrapper: {
      maxWidth: 365,
      width: '100%',
      flex: 1,
      marginBottom: 10,
    },
    payTypeHeader: {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    cardWrapper: {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    paypalWrapper: {
      width: '100%',
      flex: 1,
      justifyContent: 'space-between',
    },
    card: {
      width: 22,
      height: 16,
      marginHorizontal: 2,
    },
    doNotRefreshLabel: {
      color: colors.keyboardFocus.light,
      marginTop: 15,
      textAlign: 'center',
      textTransform: 'uppercase',
      fontFamily: 'Rubik-Regular',
    },
  };

  const mediaQueries =
    breakpoints.currentBreakpoints.isMedLg || breakpoints.currentBreakpoints.isLgXl
      ? ({
          paymentWrapper: {
            width: '100%',
          },
        } as StyleProp<Styles>)
      : breakpoints.currentBreakpoints.isXlXxl
      ? ({
          paymentWrapper: {
            width: '100%',
          },
        } as StyleProp<Styles>)
      : breakpoints.currentBreakpoints.isXlXxl || breakpoints.currentBreakpoints.isXxl
      ? ({
          paymentWrapper: {
            width: '100%',
          },
        } as StyleProp<Styles>)
      : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
