import React, { FC } from 'react';
import { ImageBackground, Text, View } from 'react-native';
import { getStyles } from './landingProduct.style';
import LinearGradient from 'react-native-linear-gradient';
import { WITH_MEDIA_CONFIG } from '@warnermmedia/gsp-core/brands/estadio/feature';
import { VideoPlayer } from '../../../videoPlayer/VideoPlayer';
import { Video } from '@warnermmedia/gsp-core/brands/estadio/data-access';

import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';

interface LandingProductProps {
  productHeadline?: string;
  productDescription?: string | null | undefined;
  productImage?: string;
  productClip: Video | null;
  breakpoints: Breakpoints;
  colors: ReactNativePaper.ThemeColors;
  width: number;
  isRegisterEnabled?: boolean;
}

export const LandingProduct: FC<LandingProductProps> = ({
  productHeadline,
  productDescription,
  productImage,
  productClip,
  breakpoints,
  width,
  colors,
}) => {
  const styles = getStyles(breakpoints, colors, width);
  const isVideoContent = !!productClip;

  return (
    <View style={styles.productWrapper}>
      <View style={isVideoContent ? styles.videoProductContainer : styles.productContainer}>
        <View style={isVideoContent ? styles.videoProductTextContent : styles.productTextContent}>
          <Text style={styles.productHeadline}>{productHeadline}</Text>
          <Text style={isVideoContent ? styles.videoProductDescription : styles.productDescription}>
            {productDescription}
          </Text>
        </View>
        <View style={isVideoContent ? styles.videoContentContainer : styles.productContentContainer}>
          {productClip ? (
            <VideoPlayer
              mediaId={productClip.mediaId ?? ''}
              isLive={false}
              title={productClip.title ?? ''}
              showBackBtn={false}
              withMediaConfig={WITH_MEDIA_CONFIG}
              playerStartTime={''}
              isFreeVideo={true}
              posterImage={productClip.thumbnail}
              autoPlay={false}
            />
          ) : (
            <ImageBackground source={{ uri: productImage }} imageStyle={styles.productImage} resizeMode="contain">
              <LinearGradient
                colors={[
                  colors.tenantBackground.dark.surface02FilmGrad.start,
                  colors.tenantBackground.dark.surface02FilmGrad.end,
                ]}
                style={styles.productImageOveray}
              ></LinearGradient>
            </ImageBackground>
          )}
        </View>
      </View>
    </View>
  );
};

export default LandingProduct;
