import { StyleSheet, Platform } from 'react-native';

export const styles = StyleSheet.create({
  webModalContainer: {
    width: '35%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  modalContainer: {
    width: '60%',
    marginHorizontal: '20%',
  },
  textStyle: {
    color: 'black',
  },
  btnStyle: {
    marginTop: 30,
  },
});
