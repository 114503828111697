import { StyleSheet, TextStyle, ViewStyle, StyleProp } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';
import merge from 'lodash/merge';

interface Styles {
  fullScreenContainer: ViewStyle;
  mainTextContainer: ViewStyle;
  descriptionContainer: ViewStyle;
  description: TextStyle;
  dropdownTitle: TextStyle;
  dropdownInput: ViewStyle;
  headerText: TextStyle;
  ctaContainer: ViewStyle;
  errorDisplay: ViewStyle;
  buttonLabel: TextStyle;
  buttonSpace: ViewStyle;
  skipButtonStyle: ViewStyle;
  dropDownTextStyle: TextStyle;
  hoverStyle: ViewStyle;
  dropDownItemHoverStyle: TextStyle;
  inputClickStyle: ViewStyle;
}

export const getStyles = (isMobileSize: boolean, breakpoints: Breakpoints, colors: ReactNativePaper.ThemeColors) => {
  const isMobileLandscape = breakpoints.breakpointHelpers.isLandScape;

  const base: StyleProp<Styles> = {
    fullScreenContainer: {},
    mainTextContainer: {
      width: '80%',
      paddingBottom: 48,
    },
    descriptionContainer: {
      width: isMobileSize ? '90%' : '80%',
      alignSelf: 'center',
    },
    description: {
      fontSize: 16,
      color: colors.keyboardFocus.light,
      fontFamily: 'Rubik-Regular',
      lineHeight: 20,
      fontWeight: 'normal',
      textAlign: 'center',
      marginBottom: 14,
    },
    dropdownTitle: {
      fontSize: 16,
      color: colors.keyboardFocus.light,
      fontFamily: 'Rubik-Medium',
      lineHeight: 20,
      textAlign: 'left',
      fontWeight: '500',
      marginTop: 5,
      marginBottom: 5,
    },
    dropdownInput: {
      borderRadius: 4,
      height: 40,
      backgroundColor: colors.tenantBackground.light.surfaceBase,
    },
    headerText: {
      fontSize: isMobileSize ? 20 : 22,
      color: colors.keyboardFocus.light,
      fontFamily: 'Oswald-SemiBold',
      textTransform: 'uppercase',
      paddingBottom: 24,
      alignSelf: 'center',
    },
    ctaContainer: {
      paddingHorizontal: isMobileSize ? 0 : 50,
    },
    errorDisplay: {
      marginVertical: 10,
    },
    buttonLabel: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Oswald-Medium',
      fontSize: 20,
      fontWeight: '500',
      lineHeight: 24,
    },
    buttonSpace: {
      marginTop: 20,
      marginBottom: 10,
    },
    skipButtonStyle: {
      borderWidth: 2,
      borderColor: colors.tenantBackground.light.surfaceBase,
    },
    dropDownTextStyle: {
      fontSize: breakpoints.currentBreakpoints.isTiny ? 14 : 16,
      fontFamily: 'Rubik-Regular',
      fontWeight: 'normal',
      color: colors.keyboardFocus.dark,
    },
    hoverStyle: {
      backgroundColor: colors.fill.action.accent02,
    },
    dropDownItemHoverStyle: {
      color: colors.tenantBackground.light.surfaceBase,
    },
    inputClickStyle: {
      borderWidth: 2,
      borderColor: colors.fill.action.accent02,
    },
  };

  const mediaQueries =
    breakpoints.currentBreakpoints.isTiny ||
    breakpoints.currentBreakpoints.isTnySm ||
    breakpoints.currentBreakpoints.isSmMed
      ? ({
          fullScreenContainer: {
            justifyContent: 'center',
          },
          mainTextContainer: {
            width: isMobileLandscape ? '90%' : '80%',
            paddingBottom: isMobileLandscape ? 20 : 48,
          },
          headerText: {
            paddingBottom: isMobileLandscape ? 5 : 24,
          },
          description: {
            marginBottom: isMobileLandscape ? 8 : 14,
          },
          buttonSpace: {
            marginTop: isMobileLandscape ? 5 : 20,
            marginBottom: isMobileLandscape ? 5 : 10,
          },
        } as StyleProp<Styles>)
      : breakpoints.currentBreakpoints.isMedLg || breakpoints.windowWidth === breakpoints.breakpointSizes.lg
      ? ({} as StyleProp<Styles>)
      : {};
  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
