import { useContext, useEffect } from 'react';
import { useGetDevice } from '@warnermmedia/gsp-core/sdk/ui';
import { useTheme } from 'react-native-paper';
import { EstadioContext, modalStateStore } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { useReactiveVar } from '@apollo/client';
import get from 'lodash/get';

export const useFocusType = () => {
  const { isTv, isPwa } = useGetDevice();
  const { colors } = useTheme();
  const { magicRemoteVisible } = useContext(EstadioContext);
  const modalState = useReactiveVar(modalStateStore);

  useEffect(() => {
    const styleTag = isPwa && document.getElementById('styleFontsTag');
    const isModalOpen =
      isTv && magicRemoteVisible && Object.keys(modalState).some((key) => get(modalState, key, false));
    const rootTag = isPwa && document.getElementById(isModalOpen ? 'modal-wrapper' : 'root');
    const otherPlatformStyle = `:focus {outline: 2px solid ${colors.stroke.onDark.outline01}; box-shadow: none;}`;
    const generalStyle = `
    #main_nav #nav_item :focus {
      background-color: ${colors.stroke.onDark.outline01}; 
    } 
    #main_nav #nav_item :focus #navItemLabel {
      color: ${colors.fill.action.accent04}
    } 
  `;
    const focusStyle = `${generalStyle}
      :focus {
         outline: none;
         box-shadow:inset 0 0 0 4pt ${colors.stroke.onDark.outline01};
        -webkit-box-shadow: inset 0 0 0 4pt ${colors.stroke.onDark.outline01};
        -moz-box-shadow: inset 0 0 0 4pt ${colors.stroke.onDark.outline01};
      }
    `;

    const removesFocusStyle = `
      :focus {
         outline: none;
         box-shadow:none;
        -webkit-box-shadow: none;
        -moz-box-shadow:none;
      }
    `;

    const focus = isTv ? (magicRemoteVisible ? removesFocusStyle : focusStyle) : otherPlatformStyle;

    if (styleTag) {
      styleTag.append(focus);
    }
    if (rootTag && isTv) {
      magicRemoteVisible
        ? rootTag.setAttribute('class', 'magicRemoteHover')
        : rootTag.classList.remove('magicRemoteHover');
    }
  }, [
    isTv,
    isPwa,
    modalState,
    magicRemoteVisible,
    colors.stroke.onDark.outline01,
    colors.fill.action.accent04,
    colors.fill.action.accent02,
  ]);
};

export default useFocusType;
