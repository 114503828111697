import React from 'react';

export interface CurrentStepProps {
  recoveryEmail?: boolean;
  resetPassword?: boolean;
}

export type Pages = 1 | 2;

interface ForgotPasswordContextProps {
  handleEmailSuccess: () => void;
  handlePasswordSuccess: () => void;
}

export const forgotPasswordContext = React.createContext<ForgotPasswordContextProps | null>(null);
