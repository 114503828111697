import merge from 'lodash/merge';
import { StyleProp, ViewStyle, StyleSheet, TextStyle, ImageStyle } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';

interface Styles {
  productWrapper: ViewStyle;
  productContainer: ViewStyle;
  videoProductContainer: ViewStyle;
  productTextContent: ViewStyle;
  videoProductTextContent: ViewStyle;
  productHeadline: TextStyle;
  productDescription: TextStyle;
  videoProductDescription: TextStyle;
  productContentContainer: ViewStyle;
  videoContentContainer: ViewStyle;
  productImage: ImageStyle;
  productImageOveray: ViewStyle;
}

export const getStyles = (breakpoints: Breakpoints, colors: ReactNativePaper.ThemeColors, width: number) => {
  const base: StyleProp<Styles> = {
    productWrapper: {
      paddingHorizontal: 25,
      minHeight: 600,
    },
    productContainer: {
      flexDirection: 'column-reverse',
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      paddingBottom: 80,
    },
    videoProductContainer: {
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      paddingBottom: 80,
    },
    productTextContent: {
      flexDirection: 'column',
      marginTop: -50,
      zIndex: 999,
    },
    videoProductTextContent: {
      flexDirection: 'column',
    },
    productHeadline: {
      fontFamily: 'Oswald-Regular',
      fontSize: 33,
      textTransform: 'uppercase',
      lineHeight: 44,
      fontWeight: '600',
      color: colors.keyboardFocus.light,
      marginBottom: 16,
    },
    productDescription: {
      fontFamily: 'Rubik-Regular',
      fontSize: 16,
      lineHeight: 20,
      fontWeight: '400',
      color: colors.keyboardFocus.light,
      marginBottom: 22,
    },
    videoProductDescription: {
      fontFamily: 'Rubik-Regular',
      fontSize: 16,
      lineHeight: 20,
      fontWeight: '400',
      color: colors.keyboardFocus.light,
      marginBottom: 22,
    },
    productContentContainer: {},
    videoContentContainer: {
      marginBottom: 16,
    },
    productImage: {
      width: '100%',
      height: width / 1.8,
    },
    productImageOveray: {
      width: '100%',
      height: width / 1.8,
    },
  };

  const mediaQueries =
    breakpoints.currentBreakpoints.isMedLg ||
    breakpoints.windowWidth === breakpoints.breakpointSizes.lg ||
    breakpoints.currentBreakpoints.isLgXl ||
    breakpoints.currentBreakpoints.isXlXxl ||
    breakpoints.currentBreakpoints.isXxl
      ? ({
          productWrapper: {
            alignItems: 'center',
            width: '100%',
            paddingBottom: 40,
            minHeight: 400,
          },
          productContainer: {
            flexDirection: 'row',
            maxWidth: 1028,
          },
          videoProductContainer: {
            flexDirection: 'row',
            maxWidth: 1028,
          },
          productTextContent: {
            width: '50%',
            marginTop: 40,
            marginRight: -100,
          },
          videoProductTextContent: {
            width: '50%',
          },
          productHeadline: {},
          productDescription: {},
          videoProductDescription: {},
          productContentContainer: {
            width: '60%',
          },
          videoContentContainer: {
            width: '50%',
            marginLeft: 16,
          },
          productImage: {
            width: '100%',
            maxHeight: 324,
            height: width * 0.5,
          },
          productImageOveray: {
            width: '100%',
            maxHeight: 324,
            height: width * 0.5,
          },
        } as StyleProp<Styles>)
      : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
