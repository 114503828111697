import { AxiosError } from 'axios';

// @flow

function handleError(func: string, param: string): Promise<string> {
  let message;
  if (!param) {
    message = func;
  } else {
    message = `${func}() requires at least ${param} as its first parameter.`;
  }
  console.warn(message); // eslint-disable-line no-console
  return Promise.reject(message);
}

export default handleError;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleApiError = (err: AxiosError<any>) => {
  let message = '';
  let status;
  let error = '';
  if (err?.response) {
    console.log(err.response, 'err response');
    status = err.response.status;
    message = err.response?.data?.message || err.response?.data?.error;
    error = err.response?.data?.error;
  }

  if (err?.request && !err?.response) {
    // a network error
    console.log(err.request, 'err request');
    if (err.request?._response?.indexOf('internet') !== -1) {
      message = err.request._response;
    }
  }

  if (status === 503) {
    message = 'Service Temporarily Unavailable';
  }
  message = message || 'Something went wrong. Please try again';
  console.log(err, 'err 503');
  return {
    error: {
      message,
      status,
      error,
    },
  };
};
