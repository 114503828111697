import React from 'react';

/* eslint-disable-next-line */
export interface SdkAssetsProps {}

export function SdkAssets(props: SdkAssetsProps) {
  return (
    <div>
      <h1>Welcome to sdk-assets!</h1>
    </div>
  );
}

export default SdkAssets;
