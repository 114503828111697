import { useHistory, useParams, useLocation } from 'react-router-dom';

export const useHistoryHook = useHistory;
export const useParamsHook = useParams;
export const useLocationHook = useLocation;

export type HistoryType = typeof useHistory;
export type ParamsType = typeof useParams;
export type LocationType = typeof useLocation;

export type UseRouterType = {
  useHistory: typeof useHistory;
  useParams: typeof useParams;
  useLocation: typeof useLocation;
  ready: boolean;
};
