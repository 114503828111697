import { useMemo } from 'react';
import { carouselBase } from '../../Utils/Interfaces';

export const useCardFilters = (content: carouselBase[] | undefined): carouselBase[] => {
  const removeIncompleteCards = (item: carouselBase[]) => {
    return item?.filter((elem) => {
      switch (elem?.type) {
        case 'video': {
          return !!elem.mediaId;
        }
        case 'manual': {
          return !!elem.has_media;
        }
        case 'match': {
          return !!elem?.match?.eventId && !!elem?.has_media;
        }
        case 'epg': {
          // We remove epg items with no image because they are redundant with the match cards.
          // The null image indicates our cms does not have a record of this item.
          // This is a hack for now until epg api provides an option to filter match episodes.
          return !(!elem.key || !elem.image);
        }
        case 'item': {
          return !!elem.callToAction?.url || !!elem.image;
        }
        default: {
          return true;
        }
      }
    });
  };

  const cardItems = useMemo(() => {
    if (content) {
      return removeIncompleteCards(content as carouselBase[]);
    }
    return [];
  }, [content]);

  return cardItems;
};
