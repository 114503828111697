import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  input: {
    padding: 5,
    height: 40,
    flex: 1,
    borderColor: '#000000',
    borderRadius: 5,
    borderWidth: 1,
    fontSize: 16,
  },
  inputWithLeftIcon: {
    paddingLeft: 30,
  },
  inputWithRightIcon: {
    paddingRight: 30,
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    borderWidth: 0,
  },
  icon: {
    width: 15,
    height: 15,
    marginHorizontal: 5,
  },
  svgIcon: {
    marginHorizontal: 5,
  },
  iconWrapper: {
    backgroundColor: 'transparent',
    position: 'absolute',
    zIndex: 999,
  },
  leftIcon: {
    left: 5,
  },
  rightIcon: {
    right: 5,
  },
  error: {
    borderColor: '#CC1F47',
    borderWidth: 2,
  },
  focused: {
    borderColor: '#359FD7',
    borderWidth: 1,
  },
});
