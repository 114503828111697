export { default as OswaldBold } from './Assets/Oswald-Bold.ttf';
export { default as OswaldExtraLight } from './Assets/Oswald-ExtraLight.ttf';
export { default as OswaldLight } from './Assets/Oswald-Light.ttf';
export { default as OswaldMedium } from './Assets/Oswald-Medium.ttf';
export { default as OswaldRegular } from './Assets/Oswald-Regular.ttf';
export { default as OswaldSemiBold } from './Assets/Oswald-SemiBold.ttf';
export { default as RubikBlack } from './Assets/Rubik-Black.ttf';
export { default as RubikBlackItalic } from './Assets/Rubik-BlackItalic.ttf';
export { default as RubikBold } from './Assets/Rubik-Bold.ttf';
export { default as RubikBoldItalic } from './Assets/Rubik-BoldItalic.ttf';
export { default as RubikExtraBold } from './Assets/Rubik-ExtraBold.ttf';
export { default as RubikExtraBoldItalic } from './Assets/Rubik-ExtraBoldItalic.ttf';
export { default as RubikItalic } from './Assets/Rubik-Italic.ttf';
export { default as RubikLight } from './Assets/Rubik-Light.ttf';
export { default as RubikLightItalic } from './Assets/Rubik-LightItalic.ttf';
export { default as RubikMedium } from './Assets/Rubik-Medium.ttf';
export { default as RubikMediumItalic } from './Assets/Rubik-MediumItalic.ttf';
export { default as RubikRegular } from './Assets/Rubik-Regular.ttf';
export { default as RubikSemiBold } from './Assets/Rubik-SemiBold.ttf';
export { default as RubikSemiBoldItalic } from './Assets/Rubik-SemiBoldItalic.ttf';
