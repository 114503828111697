import { breakpointsStateStore } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { useReactiveVar } from '@apollo/client';
import { Dimensions } from 'react-native';
import { useGetDevice } from '@warnermmedia/gsp-core/sdk/ui';
import { useCallback, useEffect, useState } from 'react';
import { useIsMountedRef } from '../useIsMountedRef';

export const useReducedHeroWidth = (
  laneWidth: number,
  webScreen: number,
  ipadScreen: number,
  mobileScreen: number,
  mobileLandScape: number
): number => {
  const { width, height } = Dimensions.get('window');
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const { isMobileDevice } = useGetDevice();
  const isTvScreen = breakpoints.currentBreakpoints.isXxl;
  const isWebScreen =
    (breakpoints.currentBreakpoints.isLgXl && !isMobileDevice) || breakpoints.currentBreakpoints.isXlXxl;
  const isMediumScreen =
    breakpoints.currentBreakpoints.isSmMed ||
    breakpoints.currentBreakpoints.isMedLg ||
    (breakpoints.currentBreakpoints.isLgXl && isMobileDevice) ||
    breakpoints.windowWidth === breakpoints.breakpointSizes.lg;
  const isMobileScreen =
    breakpoints.currentBreakpoints.isTnySm ||
    breakpoints.currentBreakpoints.isTiny ||
    width <= breakpoints.breakpointSizes.med;
  const isLandScape = width > height;
  const isMountedRef = useIsMountedRef();

  const getReducedItemWidth = useCallback(() => {
    if (isWebScreen || isTvScreen) return laneWidth - webScreen;
    else if (isMediumScreen) return laneWidth - ipadScreen;
    else if (isMobileScreen) return laneWidth - mobileScreen;
    else if (isLandScape && isMobileDevice) return laneWidth - mobileLandScape;
    else return laneWidth - 10;
  }, [
    isWebScreen,
    isTvScreen,
    isMediumScreen,
    laneWidth,
    webScreen,
    isMobileScreen,
    mobileScreen,
    isLandScape,
    isMobileDevice,
    mobileLandScape,
    ipadScreen,
  ]);

  const [reducedItemWidth, setReducedItemWidth] = useState(getReducedItemWidth());

  useEffect(() => {
    if (isMountedRef.current) {
      setReducedItemWidth(getReducedItemWidth());
    }
  }, [width, height, isMountedRef, getReducedItemWidth]);

  return reducedItemWidth;
};

export default useReducedHeroWidth;
