import dayjs, { Dayjs } from 'dayjs';
import es from 'dayjs/locale/es';

import { isWebPlatform } from '../Utils/Platform';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  CHILE_TIMEZONE,
  CID,
  CMSAPIModels,
  COUPON,
  errorMsgStore,
  HIGHEST_IMAGE_BREAKPOINT,
  ImageWithStyle,
  isTveAuth,
  isUserEmailVerified,
  isUserLoggedIn,
  LAST_ROUTE,
  loginAPIStateStore,
  Maybe,
  PIN_CODE,
  PROVIDER_LOGIN_REDIRECT_TIME,
  SortDirections,
  Tournament,
  tveProvider,
  USER_FAVORITE_TEAM,
  userHasSubscription,
} from '@warnermmedia/gsp-core/brands/estadio/data-access';

import { ImageAssets } from '@warnermmedia/gsp-core/brands/estadio/assets';
import { carouselBase, EstadioImageWithStyle, match } from '../Utils/Interfaces';
import { languageStrings } from '../../index';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  APP_VERSION,
  formatTimestamp,
  getDate,
  getDuration,
  getDurationTime,
  getLocalDate,
  now,
} from '@warnermmedia/gsp-core/sdk/ui';
import { ReadFieldFunction } from '@apollo/client/cache/core/types/common';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  CALLED_USER_EMAIL_VALIDATION,
  ChargeType,
  clearListData,
  DALTON_AUTH_TOKEN,
  FocusKeys,
  loadData,
  ROUTE_FOCUS_KEY,
  ROUTE_LAST_FOCUSED_KEY,
  saveData,
  SendSDKResponse,
  TVE_TOOLBOX_AUTH_TOKEN,
  User,
  USER_EMAIL_STATUS,
  USER_PREAUTZS,
  UserEmailResponsesEntity,
  UserProfileResponse,
} from '@warnermmedia/gsp-core/sdk/data-access';
import { AxiosError } from 'axios';
import { castArray, get } from 'lodash';
import 'react-native-get-random-values';
import { v4 as uuidv4 } from 'uuid';
import { es as pickerLocale, registerTranslation } from 'react-native-paper-dates';
import { Dimensions } from 'react-native';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { RouteObj } from '@warnermmedia/gsp-core/brands/estadio/ui';

dayjs.locale(es);
registerTranslation('es', pickerLocale);

export function countProperties(obj: { [key: string]: string }): number {
  let count = 0;

  for (const property in obj) {
    count++;
  }

  return count;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function importReactRouter(): Promise<any> {
  const importedModule = isWebPlatform() ? await import('react-router-dom') : await import('react-router-native');
  return importedModule;
}

export const getUrlParam = (name: string, search: string): string => {
  name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  const results = regex.exec(search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export const getFormattedStatus = (status = ''): number => {
  if (status && [1, 2, 3, 4].includes(parseInt(status))) {
    return parseInt(status);
  }
  return status === 'live' ? 3 : status === 'upcoming' ? 2 : status === 'ended' || status === 'replay' ? 4 : 1;
};

export const getEventMediaId = (
  isLive: boolean,
  hasMedia: boolean,
  mediaVodId: string,
  mediaLiveId: string
): { mediaId: string; live: boolean } => {
  // When an event has a VOD we always use it.  No matter what isLive is set to.
  if (hasMedia && mediaVodId) {
    return {
      mediaId: mediaVodId,
      live: false,
    };
  }
  if (hasMedia && isLive && mediaLiveId) {
    return {
      mediaId: mediaLiveId,
      live: true,
    };
  }
  return {
    mediaId: '',
    live: false,
  };
};

export const isStartingTimeLessThanInHours = (date: Dayjs | null, hours = 2) => {
  return date && date.isValid() && date.isBetween(now(), now().add(hours, 'hour'));
};

export const getUpcomingHeader = (startDate = '', todayText: string, type?: string): string => {
  const date = getLocalDate(startDate, type && type === 'epg' ? CHILE_TIMEZONE : null);

  if (date && date.isValid()) {
    if (isStartingTimeLessThanInHours(date)) {
      return `${getDurationTime(now().diff(date))}`;
    } else if (date.isToday()) {
      return `${todayText} - ${date.format('HH:mm')}`;
    } else {
      return `${date.format('DD')}/${date.format('MM')} - ${date.format('HH:mm')}`;
    }
  }

  return '00:00:00';
};

export const getEventSubtitleData = (tournamentName: string, seriesName: string) => {
  if (tournamentName && seriesName) {
    return `${tournamentName} | ${seriesName}`;
  } else if (seriesName) {
    return seriesName;
  } else {
    return '';
  }
};

export const getEventHeading = (scheduledTime: string, dateFormat = 'DD MMM YYYY'): string => {
  const date = getLocalDate(scheduledTime);
  let eventHeading = '-';
  if (date) {
    eventHeading = `${date.format(dateFormat)} | ${date.format('HH:mm:ss')}`;
  }
  return eventHeading;
};

export const getImageCardWidth = (highest: number, lowest: number): number => {
  // Since we can not use the breakpoint functions, we check the window size to return the image size for cropping
  const width = Dimensions.get('window').width;
  // The highest value will be return for sizes greater than 1439
  return width >= HIGHEST_IMAGE_BREAKPOINT ? highest : lowest;
};

export const getImageObject = (
  images:
    | ImageWithStyle[]
    | Maybe<ImageWithStyle>[]
    | ReadonlyArray<CMSAPIModels.ImageWithStyle>
    | EstadioImageWithStyle[]
    | Maybe<EstadioImageWithStyle>[]
    | string
):
  | ImageWithStyle[]
  | Maybe<ImageWithStyle>[]
  | ReadonlyArray<CMSAPIModels.ImageWithStyle>
  | EstadioImageWithStyle[]
  | Maybe<EstadioImageWithStyle>[] => {
  return typeof images === 'string'
    ? [
        {
          image: {
            url: images,
          },
        },
      ]
    : images;
};

export const getImageWithStyle = (
  image: string | null | undefined | EstadioImageWithStyle[] | Maybe<EstadioImageWithStyle>[]
): EstadioImageWithStyle | Maybe<EstadioImageWithStyle> | null => {
  const images = getImageObject(image ?? '');
  return get(images, '[0]', null);
};

export const getImageArray = (
  images: ImageWithStyle[] | Maybe<ImageWithStyle>[] | ReadonlyArray<CMSAPIModels.ImageWithStyle> | string,
  width: number
): EstadioImageWithStyle[] => {
  const imageArray = getImageObject(images);
  const imagesWithStyle: EstadioImageWithStyle[] = [];

  imageArray.forEach(
    (
      imageObject:
        | ImageWithStyle
        | Maybe<ImageWithStyle>
        | CMSAPIModels.ImageWithStyle
        | EstadioImageWithStyle
        | Maybe<EstadioImageWithStyle>
    ) => {
      const imageUrl = get(imageObject, 'image.url', '');
      const image = get(imageObject, 'image', null);
      if (image) {
        imagesWithStyle.push({
          ...imageObject,
          image: {
            ...image,
            ...{ url: imageUrl.includes(['?w=', '?h='].some((v) => v)) ? imageUrl : `${imageUrl}?w=${width}` },
          },
          originalImage: image,
        });
      }
    }
  );
  return imagesWithStyle;
};

export const videoToCarouselBase = (event: Maybe<CMSAPIModels.Video>): carouselBase => {
  return {
    id: event?._id ?? '',
    key: event?._id ?? '',
    type: 'video',
    title: event?.headline || event?.title || '',
    subHeadline: event?.subHeadline ?? '',
    duration: event?.duration ?? '',
    cardLabel: event?.cardLabel ?? '',
    mediaId: event?.mediaId ?? '',
    image: event?.thumbnail ? getImageArray(getImageObject(event.thumbnail), getImageCardWidth(640, 320)) : null,
  };
};

export const getCallToActionIcon = (scheduled_utc: string, status?: number | undefined): string => {
  let iconName = '';
  if (status === 3) {
    iconName = 'play';
  } else if (status === 4) {
    iconName = 'refresh';
  } else {
    iconName = 'calendar';
  }
  return iconName;
};

export const getCallToActionText = (scheduled_utc: string, status?: number): string => {
  let actionText = '';
  if (status === 3) {
    actionText = languageStrings.default.liveLabel;
  } else if (status === 4) {
    actionText = languageStrings.default.revealLabel;
  } else {
    actionText = getUpcomingHeader(scheduled_utc, languageStrings.default.todayLabel);
  }
  return actionText;
};

export const formatVideoDuration = (totalSeconds: number): string => {
  // if longer than an hour show hh:mm:ss, otherwise mm:ss
  const duration = getDuration(totalSeconds, 'seconds');
  return duration.hours() >= 1 ? duration.format('HH:mm:ss') : duration.format('mm:ss');
};

export const formatSortDate = (stringDate: string, elementType = ''): string => {
  const date = elementType === 'video' ? parseFloat(stringDate) * 1000 : stringDate;
  const formattedDate = getDate(date);
  return formattedDate.toISOString();
};

const getSortValue = (aDate: Dayjs | null, bDate: Dayjs | null, order?: string | SortDirections | null): number => {
  if (bDate && aDate && bDate.isBefore(aDate)) {
    return order && order === 'asc' ? 1 : -1;
  } else if (aDate && bDate && bDate.isAfter(aDate)) {
    return order && order === 'asc' ? -1 : 1;
  } else {
    return 0;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sortContent = (content: any[] = [], readField: any, order?: string | null | undefined): any[] => {
  return [...content].sort((a, b) => {
    const newA = readField('sortDate', a);
    const newB = readField('sortDate', b);
    const aDate = getLocalDate(newA);
    const bDate = getLocalDate(newB);
    return getSortValue(aDate, bDate, order);
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sortContentByWeight = (content: CMSAPIModels.Content[] = [], readField: any) => {
  return [...content].sort((itemA: CMSAPIModels.Content, itemB: CMSAPIModels.Content) => {
    const weightA = readField('weight', itemA) as number;
    const weightB = readField('weight', itemB) as number;
    if (weightA > weightB) {
      return 1;
    } else if (weightA < weightB) {
      return -1;
    } else {
      return 0;
    }
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sortCarouselContentByDate = (content: carouselBase[] = [], order?: SortDirections): any[] => {
  return [...content].sort((a, b) => {
    const aDate = getLocalDate(a?.match?.startDateTime ?? '');
    const bDate = getLocalDate(b?.match?.startDateTime ?? '');

    return getSortValue(aDate, bDate, order);
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sortChargesByDate = (content: ChargeType[] = [], order?: SortDirections): any[] => {
  return [...content].sort((a, b) => {
    const aDate = getLocalDate(a?.chargeTimestamp ?? '');
    const bDate = getLocalDate(b?.chargeTimestamp ?? '');

    return getSortValue(aDate, bDate, order);
  });
};

export const getGameTeam = (
  team: CMSAPIModels.Competitor = {},
  readField: ReadFieldFunction,
  score: Maybe<number> | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
  const logo: string | undefined = readField('logo_dark', team);
  return {
    name: readField('name', team),
    tricode: readField('team_abbr', team),
    score: score,
    logo: logo ? getImageArray(getImageObject(logo), getImageCardWidth(320, 160)) : ImageAssets.defaultTeamLogo,
  };
};

export const getSecondsDiff = (begin: string, end: string): number | null => {
  const startDate = getLocalDate(begin);
  const endDate = getLocalDate(end);
  if (startDate && endDate) {
    return Math.abs(endDate.diff(startDate, 'seconds'));
  }
  return null;
};

export const eventToCarouselBase = (event: Maybe<CMSAPIModels.Event>): carouselBase => {
  const status = getFormattedStatus(event?.status ?? '');
  const eventMedia = getEventMediaId(
    status === 3,
    event?.has_media ?? false,
    event?.media_asset_id_vod ?? '',
    event?.media_asset_id_live ?? ''
  );
  const homeTeamLogo: string | undefined | null = event?.home?.logo_dark;
  const awayTeamLogo: string | undefined | null = event?.away?.logo_dark;

  return {
    id: event?.id ?? '',
    key: event?.id ?? '',
    type: 'match',
    has_media: event?.has_media ?? false,
    heading: getEventHeading(event?.scheduled_utc ?? ''),
    status: status as 1 | 2 | 3 | 4 | undefined,
    tournament: event?.tournament as Tournament,
    title: event?.title ?? '',
    subtitle: getEventSubtitleData(event?.tournament?.display_name ?? '', event?.series_name ?? ''),
    mediaId: eventMedia?.mediaId ?? '',
    match: {
      homeTeam: {
        id: event?.home?.id ?? '',
        name: event?.home?.name ?? '',
        tricode: event?.home?.team_abbr ?? '',
        score: event?.game?.score?.home as number | undefined,
        logo: homeTeamLogo
          ? getImageArray(getImageObject(homeTeamLogo), getImageCardWidth(320, 160))
          : ImageAssets.defaultTeamLogo,
      },
      awayTeam: {
        id: event?.away?.id ?? '',
        name: event?.away?.name ?? '',
        tricode: event?.away?.team_abbr ?? '',
        score: event?.game?.score?.away as number | undefined,
        logo: awayTeamLogo
          ? getImageArray(getImageObject(awayTeamLogo), getImageCardWidth(320, 160))
          : ImageAssets.defaultTeamLogo,
      },
      live: eventMedia?.live,
      startDateTime: event?.scheduled_utc ? getDate(event?.scheduled_utc).unix() : 0,
      gameStatus: status ?? undefined,
      gamestateDisplay: event?.status && ['postponed'].includes(event?.status ?? '') ? event?.status : '',
      eventId: (event?.game?.sourceGameId || event?.sportradar_id || '').toString().split(':')[2],
      eventLightLogo: event?.logo_light ? getImageArray(event?.logo_light, getImageCardWidth(960, 640)) : null,
      eventDarkLogo: event?.logo_dark ? getImageArray(event?.logo_dark, getImageCardWidth(1260, 688)) : null,
    },
  };
};

export const clearLocalUserData = (): void => {
  clearListData([
    COUPON,
    CID,
    PIN_CODE,
    APP_VERSION,
    LAST_ROUTE,
    USER_PREAUTZS,
    USER_EMAIL_STATUS,
    DALTON_AUTH_TOKEN,
    USER_FAVORITE_TEAM,
    TVE_TOOLBOX_AUTH_TOKEN,
    PROVIDER_LOGIN_REDIRECT_TIME,
    CALLED_USER_EMAIL_VALIDATION,
    ROUTE_LAST_FOCUSED_KEY,
  ]);
};

export const logoutEstadioApplication = (): void => {
  clearLocalUserData();
  isUserLoggedIn(false);
  isUserEmailVerified(null);
  userHasSubscription(null);
  tveProvider(null);
  isTveAuth(false);
  loginAPIStateStore({
    ...loginAPIStateStore(),
    user: null,
    authToken: null,
    isTveAuth: false,
    tveUserId: null,
    checkBillingProfile: true,
    isNewUser: false,
    entitlements: null,
    preAuthorizedEntitlements: [],
    preferences: null,
    preferencesProcessed: false,
    preferencesFetched: false,
    gettingPreferences: false,
    isLoggedOut: true,
    lastLogoutTimestamp: getTimestampNow(),
  });
};

const getTimestampNow = () => {
  return new Date().toUTCString();
};

export const getUserPrimaryEmail = (
  data: UserProfileResponse | { user: User | null }
): UserEmailResponsesEntity | undefined => {
  return (data?.user?.userEmailResponses || []).find((email) => {
    const emailItem = (email as unknown) as UserEmailResponsesEntity;
    return emailItem.primary === true;
  });
};

export const getMatchPageTitle = (match?: match): string => {
  const homeTeamName = match?.homeTeam?.name;
  const awayTeamName = match?.awayTeam?.name;
  return homeTeamName && awayTeamName ? `${homeTeamName} V/S ${awayTeamName}` : '';
};

export const getContentTitle = (title: string): string => {
  let pageTitle = languageStrings.default.contentTitleSuffix;
  if (title) {
    pageTitle = !title.includes(languageStrings.default.contentTitleSuffix)
      ? `${title} - ${languageStrings.default.contentTitleSuffix}`
      : title;
  }
  return pageTitle;
};

export const formatAssetTitle = <T extends string>(title: T): string => {
  let formattedTitle = (title ?? '') as string;
  if (title && title.includes(languageStrings.default.contentTitleSuffix)) {
    const formatted = title.split('-');
    formattedTitle = formatted[0];
  }
  return formattedTitle.trim();
};

export const getUniqueId = (prefix = '') => {
  return prefix ? `${prefix}-${uuidv4()}` : uuidv4();
};

export const handleApiCallError = (
  error: AxiosError,
  errorMessage: string | null = null,
  allowedErrorCodes: number[] = []
) => {
  const status = error?.response?.status ?? null;
  const message = errorMessage || error?.message;
  if (status && allowedErrorCodes.includes(status)) {
    errorMsgStore({
      type: 'AXIOS_ERROR',
      message,
    });
  }
  return {
    success: false,
    error,
  };
};

export async function handleApiCall<T>(apiPromise: Promise<SendSDKResponse<T>>): Promise<SendSDKResponse<T>> {
  try {
    const promiseResponse = await apiPromise;
    if (promiseResponse.success) {
      return promiseResponse;
    }
    throw promiseResponse.error;
  } catch (e) {
    return handleApiCallError(e as AxiosError);
  }
}

export const getAuthData = (
  key: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
  const authData = loadData(key);
  return authData && typeof authData === 'string' ? JSON.parse(authData) : authData;
};

export function getStoredAccessProperty<T>(key: string, propertyName: string): T {
  const authData = getAuthData(key);
  return get(authData, propertyName);
}

export const isInRoute = (route: string | string[], pathname: string): boolean => {
  return castArray(route).some((r) => pathname.indexOf(r) > -1);
};

export const getLocalLink = (uri?: string | null | undefined): string => {
  let url = '';
  if (uri) {
    url = uri.replace('internal:/', '/');
  }
  return url;
};

export const isCssSupported = (property: string, value: string): boolean => {
  return CSS && CSS.supports && CSS.supports(property, value);
};

export const isJwtTokenExpired = (token = ''): boolean => {
  try {
    const tokenSplit = token.split('.');
    const decodedData =
      tokenSplit.length > 1 ? JSON.parse(Buffer.from(tokenSplit[1], 'base64').toString('binary')) : {};
    const exp = get(decodedData, 'exp', null);
    const date = exp && token ? formatTimestamp(exp) : null;

    return !!date && date.isBefore(now());
  } catch (e) {
    return false;
  }
};

export const isElementAvailable = (key: FocusKeys): boolean => {
  const element = global.document ? global.document.getElementById(key) : null;
  return !!element;
};

export const isTickerAvailable = (): boolean => {
  const ticker = global.document ? global.document.getElementById(FocusKeys.TICKER) : null;
  return !!ticker;
};

export const hasPrevFocusKey = (parentFocusKey: string): boolean => {
  const routeKey = loadData(ROUTE_FOCUS_KEY);
  return routeKey !== parentFocusKey || isTickerAvailable();
};

export const isElementInViewport = (element?: HTMLElement): boolean => {
  const rect = element?.getBoundingClientRect();
  const height = (window && window.innerHeight) || (global.document && global.document.documentElement.clientHeight);
  const width = (window && window.innerWidth) || (global.document && global.document.documentElement.clientWidth);
  return !!rect && rect.top >= 0 && rect.left >= 0 && rect.bottom <= height && rect.right <= width;
};

export const setRouseLastFocusedKey = (focusKey: string | null = null, pathname: string | null = null): void => {
  const lastFocusedKey = pathname
    ? Object.assign(
        {},
        {
          [pathname]: focusKey,
        }
      )
    : {};
  saveData(ROUTE_LAST_FOCUSED_KEY, lastFocusedKey);
};

export const getUnknownRoute = (
  isAppEnabled: boolean,
  isLoggedIn: boolean,
  isEmailVerified: boolean | null,
  hasSubscription: boolean | null
): string => {
  let route = '/register';
  if (isAppEnabled) {
    if (isLoggedIn && !!isEmailVerified && !!hasSubscription) {
      route = '/home';
    } else if (isLoggedIn && !isEmailVerified) {
      route = '/resend-email';
    } else if (isLoggedIn && !!isEmailVerified && !hasSubscription) {
      route = '/subscribe';
    } else {
      route = '/register';
    }
  }
  return route;
};

export const getRoutes = (routes: RouteObj[], isAppEnabled: boolean): RouteObj[] => {
  if (isAppEnabled) {
    return routes;
  }
  return routes.filter(({ route }) => ['/register', '/consulta-boleta', '/'].includes(route));
};
