import React, { FC } from 'react';
import { Skeleton, useGetDevice } from '@warnermmedia/gsp-core/sdk/ui';
import { View } from 'react-native';
import { useReactiveVar } from '@apollo/client';
import { breakpointsStateStore } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { getStyles } from './skeletonNavBar.style';

export const SkeletonNavBar: FC<{ windowHeightStartTop?: number }> = () => {
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const { isTv } = useGetDevice();
  const styles = getStyles(breakpoints, isTv);

  return (
    <View style={styles.skeletonNavContainer}>
      <Skeleton hasTitle={false} overrideContentStyle={styles.skeletonNav} />
    </View>
  );
};

export default SkeletonNavBar;
