import React from 'react';
import { Platform, View, Image } from 'react-native';
import { ImageAssets } from '@warnermmedia/gsp-core/brands/estadio/assets';

// TODO: FIx android images below with proper typing
export interface ImgfacadeProps {
  imageName: string;
  imageExt: string;
}

export function Imgfacade({ imageName, imageExt }: ImgfacadeProps) {
  if (!Platform || Platform?.OS === 'web') {
    return <img src={`assets/${imageName}.${imageExt}`} />;
  } else {
    // Code below is broken
    // return <Image source={ImageAssets[imageName]} />;
    console.log('fix me pls');
    return <view />;
  }
}

export default Imgfacade;
