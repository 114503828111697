import { useHistory, useParams, useLocation } from 'react-router-dom';
import { importReactRouter } from '../../Services/CommonFunctions';
import { UseRouterType } from '../../Types/UseRouterTypes';

export const useReactRouter = async (): Promise<UseRouterType> => {
  const importedModule = await importReactRouter();

  return {
    useHistory: importedModule && importedModule.useHistory ? importedModule.useHistory : useHistory,
    useParams: importedModule && importedModule.useParams ? importedModule.useParams : useParams,
    useLocation: importedModule && importedModule.useLocation ? importedModule.useLocation : useLocation,
    ready: importedModule ? true : false,
  };
};
