import { ImageStyle, StyleProp, StyleSheet, TextStyle, ViewStyle } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';
import { BreakpointsSize, ScaleAction } from '@warnermmedia/gsp-core/brands/estadio/feature';

interface Styles {
  background: ViewStyle;
  accountWrapper: ViewStyle;
  cardWrapper: ViewStyle;
  titleStyle: TextStyle;
  subTitleStyle: TextStyle;
  detailsWrapper: ViewStyle;
  details: ViewStyle;
  teamDetailsWrapper: ViewStyle;
  detailContainer: ViewStyle;
  detailLabel: TextStyle;
  detailValue: TextStyle;
  actionBtn: ViewStyle;
  actionBtnText: TextStyle;
  teamLogo: ImageStyle;
  overlay: ViewStyle;
  favoriteTeamContainer: ViewStyle;
  teamLabel: TextStyle;
  teamName: TextStyle;
  tveCardWrapper: ViewStyle;
}

export const getStyles = (breakpoints: Breakpoints, scaling: ScaleAction, colors: ReactNativePaper.ThemeColors) => {
  const { scaleHeight, scaleWidth } = scaling;
  const base: StyleProp<Styles> = {
    background: {
      height: scaleHeight(200, BreakpointsSize.Tv),
      width: scaleWidth(200, BreakpointsSize.Tv),
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: scaleHeight(5, BreakpointsSize.Tv),
    },
    accountWrapper: {
      maxWidth: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    cardWrapper: {
      flex: 1,
      borderRadius: scaleHeight(6, BreakpointsSize.Tv),
      backgroundColor: colors.tenantBackground.dark.surface03,
      marginRight: scaleWidth(17, BreakpointsSize.Tv),
      paddingVertical: scaleHeight(30, BreakpointsSize.Tv),
      paddingHorizontal: scaleWidth(40, BreakpointsSize.Tv),
      flexDirection: 'column',
    },
    tveCardWrapper: {
      maxWidth: scaleWidth(824, BreakpointsSize.Tv),
      height: scaleHeight(411, BreakpointsSize.Tv),
    },
    titleStyle: {
      color: colors.keyboardFocus.light,
      fontSize: scaleHeight(33, BreakpointsSize.Tv),
      fontFamily: 'Oswald-SemiBold',
      fontWeight: '600',
      textTransform: 'uppercase',
    },
    subTitleStyle: {
      color: colors.keyboardFocus.light,
      fontSize: scaleHeight(22, BreakpointsSize.Tv),
      fontFamily: 'Oswald-SemiBold',
      fontWeight: '600',
      textTransform: 'uppercase',
    },
    detailsWrapper: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      flexWrap: 'nowrap',
      alignItems: 'flex-start',
    },
    details: {
      maxWidth: '50%',
      justifyContent: 'space-between',
    },
    teamDetailsWrapper: {
      maxWidth: '50%',
    },
    detailContainer: {
      flexDirection: 'column',
      marginTop: scaleHeight(24, BreakpointsSize.Tv),
    },
    detailLabel: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Rubik-Medium',
      fontSize: scaleHeight(20, BreakpointsSize.Tv),
      fontWeight: '500',
      height: scaleHeight(32, BreakpointsSize.Tv),
      lineHeight: scaleHeight(32, BreakpointsSize.Tv),
      marginVertical: scaleHeight(4, BreakpointsSize.Tv),
    },
    detailValue: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Rubik-Regular',
      fontSize: scaleHeight(20, BreakpointsSize.Tv),
      fontWeight: 'normal',
      height: scaleHeight(32, BreakpointsSize.Tv),
      lineHeight: scaleHeight(32, BreakpointsSize.Tv),
    },
    actionBtn: {
      backgroundColor: colors.fill.action.accent01,
      borderWidth: 0,
    },
    actionBtnText: {
      fontFamily: 'Oswald-Medium',
    },
    teamLogo: {
      width: scaleWidth(150, BreakpointsSize.Tv),
      height: scaleHeight(150, BreakpointsSize.Tv),
      marginVertical: scaleHeight(16, BreakpointsSize.Tv),
    },
    overlay: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      backgroundColor: colors.fill.action.accent02,
      opacity: 0.9,
      borderRadius: scaleHeight(5, BreakpointsSize.Tv),
    },
    favoriteTeamContainer: {
      marginTop: scaleHeight(24, BreakpointsSize.Tv),
    },
    teamLabel: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Rubik-Medium',
      fontSize: scaleHeight(20, BreakpointsSize.Tv),
      fontWeight: '500',
      height: scaleHeight(32, BreakpointsSize.Tv),
      lineHeight: scaleHeight(20, BreakpointsSize.Tv),
      marginVertical: scaleHeight(10, BreakpointsSize.Tv),
    },
    teamName: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Rubik-Medium',
      fontSize: scaleHeight(20, BreakpointsSize.Tv),
      fontWeight: '500',
      height: scaleHeight(28, BreakpointsSize.Tv),
      lineHeight: scaleHeight(20, BreakpointsSize.Tv),
      marginVertical: scaleHeight(10, BreakpointsSize.Tv),
    },
  };
  return StyleSheet.create(base);
};
