/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import React from 'react';
import { CMSAPIModels } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { carouselBase } from '../../Utils/Interfaces';

interface CMSPageDataProps {
  formatEPGData(queryData: CMSAPIModels.Maybe<CMSAPIModels.Schedule> | undefined): carouselBase[];
}

export const useEPGData = (): CMSPageDataProps => {
  const formatEPGData = (
    data: CMSAPIModels.Maybe<CMSAPIModels.Schedule> | undefined = {} as CMSAPIModels.Schedule
  ): carouselBase[] => {
    const feeds: CMSAPIModels.Maybe<CMSAPIModels.Feed>[] =
      data?.feeds ?? ([] as CMSAPIModels.Maybe<CMSAPIModels.Feed>[]);
    const shows: CMSAPIModels.Maybe<CMSAPIModels.Show>[] =
      feeds[0]?.shows ?? ([] as CMSAPIModels.Maybe<CMSAPIModels.Show>[]);

    const carouselData = shows.map((swimlane: CMSAPIModels.Maybe<CMSAPIModels.Show>) => {
      return {
        key: swimlane?.id,
        id: swimlane?.id,
        type: 'show',
        show: {
          title: swimlane?.title,
          airDateTime: swimlane?.airDateTime,
          startDate: swimlane?.begin,
          startTime: swimlane?.end,
          episode: swimlane?.episode,
          originalTitle: swimlane?.originalTitle,
        },
      } as carouselBase;
    });
    return carouselData;
  };

  return {
    formatEPGData: (queryData: CMSAPIModels.Maybe<CMSAPIModels.Schedule> | undefined): carouselBase[] =>
      formatEPGData(queryData),
  };
};
