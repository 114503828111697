/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { CMSAPIModels } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { carouselBase, carouselCallToAction } from '../../Utils/Interfaces';
import { getFormattedStatus } from '../../..';
import { useCallback } from 'react';

interface CMSPageDataProps {
  formatData(queryData: CMSAPIModels.Maybe<CMSAPIModels.Event>[] | undefined): carouselBase[];
}

export const useTickerData = (): CMSPageDataProps => {
  const formatData = useCallback((data = []) => {
    const carouselData = data.map((swimlane: CMSAPIModels.Maybe<CMSAPIModels.Event>) => {
      const status = getFormattedStatus(swimlane?.status ?? '');
      return {
        key: swimlane?.id,
        id: swimlane?.id,
        type: 'match',
        callToAction: {
          text: 'HOY 18:40',
          icon: 'calendar',
          action: 'https://google.com',
        } as carouselCallToAction,
        match: {
          homeTeam: {
            id: swimlane?.home?.name,
            name: swimlane?.home?.name,
            tricode: swimlane?.home?.team_abbr,
            score: swimlane?.game?.score?.home,
          },
          awayTeam: {
            id: swimlane?.away?.name,
            name: swimlane?.away?.name,
            tricode: swimlane?.away?.team_abbr,
            score: swimlane?.game?.score?.away,
          },
          live: swimlane?.is_live,
          startDateTime: swimlane?.scheduled_utc ? swimlane?.scheduled_utc : 0,
          gameStatus: status,
          gamestateDisplay: ['postponed'].includes(swimlane?.status ?? '') ? swimlane?.status : '',
          eventId: (swimlane?.game?.sourceGameId || swimlane?.sportradar_id || '').toString().split(':')[2],
        },
      } as carouselBase;
    });
    return carouselData;
  }, []);

  return { formatData };
};
