import merge from 'lodash/merge';
import { StyleProp, ViewStyle, StyleSheet, TextStyle, ImageStyle } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';

interface Styles {
  valueWrapper: ViewStyle;
  valueHeaderContainer: ViewStyle;
  valueHeaderTextContent: ViewStyle;
  header: TextStyle;
  description: TextStyle;
  valueImageContainer: ViewStyle;
  valueImage: ImageStyle;
  devider: ViewStyle;
  deviceListContainer: ViewStyle;
  devices: ViewStyle;
  devicesHeader: TextStyle;
  devicesSubHeader: TextStyle;
  devicesDescription: TextStyle;
  submitButton: ViewStyle;
}

export const getStyles = (breakpoints: Breakpoints, colors: ReactNativePaper.ThemeColors, width: number) => {
  const base: StyleProp<Styles> = {
    valueWrapper: {
      paddingHorizontal: 25,
      marginBottom: 4,
    },
    valueHeaderContainer: {
      flexDirection: 'column',
      width: '100%',
      height: '100%',
    },
    valueHeaderTextContent: {},
    header: {
      fontFamily: 'Oswald-Regular',
      fontSize: 33,
      textTransform: 'uppercase',
      lineHeight: 44,
      fontWeight: '500',
      color: colors.keyboardFocus.light,
      marginBottom: 22,
    },
    description: {
      fontFamily: 'Rubik-Regular',
      fontSize: 16,
      lineHeight: 20,
      fontWeight: '400',
      color: colors.keyboardFocus.light,
      marginBottom: 18,
    },
    valueImageContainer: {
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      alignItems: 'center',
      marginBottom: 16,
    },
    valueImage: {
      width: '100%',
      height: width / 1.82,
    },
    devider: {
      borderColor: colors.keyboardFocus.light,
      borderWidth: 1,
      marginVertical: 22,
      borderRadius: 4,
    },
    deviceListContainer: {
      flexDirection: 'column',
      justifyContent: 'center',
    },
    devices: {
      marginBottom: 22,
    },
    devicesHeader: {
      fontFamily: 'Rubik-bold',
      fontSize: 16,
      lineHeight: 21,
      fontWeight: '700',
      color: colors.keyboardFocus.light,
      textAlign: 'center',
      marginBottom: 8,
    },
    devicesSubHeader: {
      fontFamily: 'Rubik-Regular',
      fontSize: 16,
      lineHeight: 25,
      fontWeight: '400',
      color: colors.keyboardFocus.light,
      textAlign: 'center',
      marginBottom: 8,
    },
    devicesDescription: {
      fontFamily: 'Rubik-Regular',
      fontSize: 16,
      lineHeight: 20,
      fontWeight: '400',
      color: colors.keyboardFocus.light,
      textAlign: 'center',
      marginBottom: 8,
    },
    submitButton: {
      marginTop: 43,
      width: '100%',
      height: 40,
    },
  };

  const mediaQueries =
    breakpoints.currentBreakpoints.isMedLg ||
    breakpoints.currentBreakpoints.isLgXl ||
    breakpoints.windowWidth === breakpoints.breakpointSizes.lg
      ? ({
          valueWrapper: {
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          },
          valueHeaderContainer: {
            flexDirection: 'row',
            maxWidth: 1028,
          },
          valueHeaderTextContent: {
            flexDirection: 'column',
            width: '49%',
            marginRight: 20,
          },
          valueImageContainer: {
            width: '49%',
          },
          valueImage: {
            width: '100%',
            maxHeight: 182,
          },
          devider: {
            width: '100%',
            maxWidth: 1028,
          },
          deviceListContainer: {
            flexDirection: 'row',
            alignContent: 'flex-start',
            justifyContent: 'center',
            width: '100%',
            maxWidth: 1028,
          },
          devices: {
            flex: 1,
            width: '85%',
          },
          devicesHeader: {
            marginBottom: 8,
          },
          devicesSubHeader: {
            marginBottom: 8,
          },
          devicesDescription: {
            marginBottom: 8,
          },
          submitButton: {
            width: 335,
          },
        } as StyleProp<Styles>)
      : breakpoints.currentBreakpoints.isXlXxl || breakpoints.currentBreakpoints.isXxl
      ? ({
          valueWrapper: {
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          },
          valueHeaderContainer: {
            flexDirection: 'row',
            maxWidth: 1028,
          },
          valueHeaderTextContent: {
            flexDirection: 'column',
            width: '49%',
            marginRight: 20,
          },
          valueImageContainer: {
            width: '49%',
          },
          valueImage: {
            width: '100%',
            maxHeight: 182,
          },
          devider: {
            width: '100%',
            maxWidth: 1028,
          },
          deviceListContainer: {
            flexDirection: 'row',
            alignContent: 'flex-start',
            justifyContent: 'center',
            width: '100%',
            maxWidth: 1028,
          },
          devices: {
            flex: 1,
            width: '85%',
          },
          devicesHeader: {
            marginBottom: 8,
          },
          devicesSubHeader: {
            marginBottom: 8,
          },
          devicesDescription: {
            marginBottom: 8,
          },
          submitButton: {
            maxWidth: 590,
          },
        } as StyleProp<Styles>)
      : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
