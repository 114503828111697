import React from 'react';

/* eslint-disable-next-line */
export interface BrandsEstadioUiProps {}

export function BrandsEstadioUi(props: BrandsEstadioUiProps) {
  return (
    <div>
      <h1>Welcome to brands-estadio-ui!</h1>
    </div>
  );
}

export default BrandsEstadioUi;
