import React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';

/* eslint-disable-next-line */
export interface WhistleProps {
  width?: number;
  height?: number;
  color?: string;
  iconColor?: string;
}

export function Whistle(props: WhistleProps) {
  const width = props.width || 20;
  const height = props.height || 20;
  const color = props.color || '#359fd7';
  const iconColor = props.iconColor || '#fff';

  return (
    <Svg fill="none" height={height} viewBox="0 0 20 20" width={width}>
      <Circle cx="10" cy="10" r="10" fill={color} />
      <Path
        fill={iconColor}
        d="M14.688 8.125h-1.876c-.083 0-.162.033-.22.091l-.534.534h-.808v-.313c0-.172-.14-.312-.313-.312H8.126C6.402 8.125 5 9.527 5 11.25s1.402 3.125 3.125 3.125c1.547 0 2.842-1.114 3.086-2.623l3.594-1.463c.118-.048.195-.162.195-.289V8.437c0-.172-.14-.312-.313-.312zm-6.563 4.688c-.863 0-1.563-.7-1.563-1.563s.7-1.563 1.563-1.563 1.563.7 1.563 1.563-.7 1.563-1.563 1.563zM11.875 5c-.172 0-.313.14-.313.313v1.875c0 .172.14.312.313.312.172 0 .313-.14.313-.313V5.313c0-.172-.14-.312-.313-.312zM10.875 7l-.938-1.25c-.103-.139-.3-.166-.437-.063-.138.104-.166.3-.063.438l.938 1.25c.061.082.155.125.25.125.065 0 .131-.02.188-.063.138-.103.165-.299.062-.437zM14.25 5.688c-.138-.103-.334-.077-.438.062L12.874 7c-.103.138-.075.334.063.438.057.041.123.062.188.062.094 0 .189-.043.25-.125l.938-1.25c.103-.138.074-.334-.064-.438z"
      />
    </Svg>
  );
}

export default Whistle;
