import { useReactiveVar } from '@apollo/client';
import {
  isUserLoggedIn,
  loginAPIStateStore,
  userHasSubscription,
} from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { useCallback, useEffect, useState } from 'react';
import { Dalton } from '../dalton';
import { useIsMountedRef } from '../useIsMountedRef';

export const useGetBillingStatus = () => {
  const isLoggedIn = useReactiveVar(isUserLoggedIn);
  const hasSubscription = useReactiveVar(userHasSubscription);
  const { user, preAuthorizedEntitlements, isNewUser, checkBillingProfile, checkingBillingProfile } = useReactiveVar(
    loginAPIStateStore
  );
  const isMountedRef = useIsMountedRef();
  const dalton = Dalton();
  const [loadingBillingProfile, setLoadingBillingProfile] = useState(
    isLoggedIn && checkingBillingProfile && !isNewUser && checkBillingProfile && !hasSubscription
  );

  const handleBilling = useCallback(async () => {
    setLoadingBillingProfile(true);
    try {
      await dalton.getBillingProfile();
      setLoadingBillingProfile(false);
    } catch (e) {
      setLoadingBillingProfile(false);
    }
  }, [dalton]);

  useEffect(() => {
    (async (): Promise<void> => {
      if (
        isMountedRef.current &&
        isLoggedIn &&
        !preAuthorizedEntitlements.length &&
        !isNewUser &&
        checkBillingProfile &&
        !hasSubscription
      ) {
        if (user && !checkingBillingProfile) {
          await handleBilling();
        }
        setLoadingBillingProfile(false);
      }
    })();
  }, [
    isLoggedIn,
    preAuthorizedEntitlements.length,
    isNewUser,
    user,
    checkBillingProfile,
    checkingBillingProfile,
    hasSubscription,
    handleBilling,
    isMountedRef,
  ]);

  return loadingBillingProfile;
};

export default useGetBillingStatus;
