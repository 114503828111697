import React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';

/* eslint-disable-next-line */
export interface SearchProps {
  width?: number;
  height?: number;
  color?: string;
  iconColor?: string;
}

export function Search(props: SearchProps) {
  const width = props.width || 20;
  const height = props.height || 20;
  const color = props.color || '#359fd7';
  const iconColor = props.iconColor || '#fff';

  return (
    <Svg fill="none" height={height} viewBox="0 0 20 20" width={width}>
      <Circle cx="10" cy="10" r="10" fill={color} />
      <Path
        fill={iconColor}
        d="M9.099 13.198c.844 0 1.623-.258 2.274-.691l2.355 2.354c.185.185.49.185.683 0l.458-.458c.185-.185.185-.49 0-.683l-2.362-2.347c.433-.65.69-1.43.69-2.274C13.198 6.84 11.358 5 9.1 5 6.84 5 5 6.84 5 9.099c0 2.258 1.832 4.099 4.099 4.099zm0-6.59c1.374 0 2.491 1.117 2.491 2.49 0 1.375-1.117 2.492-2.491 2.492S6.607 10.473 6.607 9.1 7.725 6.607 9.1 6.607z"
      />
    </Svg>
  );
}

export default Search;
