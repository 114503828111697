import React from 'react';
import { View } from 'react-native';
import { Helmet } from 'react-helmet';

type Maybe<T> = T | null;

export interface metaTag {
  [x: string]: number | Maybe<string> | undefined;
  key: number;
  content: Maybe<string> | undefined;
}

export interface metaTagProps {
  data?: metaTag[];
  title?: string;
}

export function MetaTags({ data = [], title }: metaTagProps) {
  return (
    <View>
      <Helmet>
        {title && <title>{title}</title>}
        {data.map((metaTag) => (
          /* @ts-expect-error: metaTag interface not going to match with meta type */
          <meta {...metaTag} />
        ))}
      </Helmet>
    </View>
  );
}

export default MetaTags;
