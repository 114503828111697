import React from 'react';
import Svg, { G, Path } from 'react-native-svg';

/* eslint-disable-next-line */
export interface PauseProps {
  width?: number;
  height?: number;
  color?: string;
  iconColor?: string;
  opacity?: number;
}

export function Pause(props: PauseProps) {
  const width = props.width || 20;
  const height = props.height || 20;
  const color = props.color || '#359fd7';
  const iconColor = props.iconColor || '#fff';
  const opacity = props.opacity || 1;
  return (
    <svg className="button" viewBox="0 0 60 60" height={height} width={width}>
      <polygon fill={iconColor} points="0,0 15,0 15,60 0,60" />
      <polygon fill={color} points="25,0 40,0 40,60 25,60" />
    </svg>
  );
}

export default Pause;
