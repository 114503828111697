import React, { useRef } from 'react';
import { Image, ImageBackground, Pressable, View } from 'react-native';
import { Maybe } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import {
  Breakpoints,
  DeviceTypes,
  PressableWithOpacity,
  TextContent,
  useSpatialNavigation,
} from '@warnermmedia/gsp-core/sdk/ui';

import { getStyles } from './teamCard.styles';
import {
  CardDefaultBackground,
  carouselBase,
  getImageWithStyle,
  getUniqueId,
  useCardItemImage,
} from '@warnermmedia/gsp-core/brands/estadio/feature';
import { ItemName } from '@warnermmedia/gsp-core/sdk/data-access';

interface TeamsCardProps {
  item: carouselBase;
  type: Maybe<string>;
  index: number;
  onPress?: (item: carouselBase | null) => void | null;
  breakpoints: Breakpoints;
  device: DeviceTypes;
  colors: ReactNativePaper.ThemeColors;
  dataLength?: number;
  focusKey?: string | null;
  parentFocusKey?: string;
}

export const TeamCard = ({
  item,
  onPress = () => {
    return null;
  },
  breakpoints,
  colors,
  device,
  index,
  dataLength,
  focusKey,
  parentFocusKey,
}: TeamsCardProps) => {
  const styles = getStyles(breakpoints, device.isTv, colors);
  const { getCardImageSource } = useCardItemImage();
  const teamLogo = getCardImageSource({
    fallbackName: CardDefaultBackground.Team,
    image: item?.image ? getImageWithStyle(item?.image) : null,
  });

  const TeamCardWrapper = device.isMobileDevice ? PressableWithOpacity : Pressable;
  const cardFocusKey = useRef(focusKey ?? getUniqueId(`${ItemName.TEAM_CARD}_${index}`)).current;
  const { ref, onGridCardArrowPress } = useSpatialNavigation({
    focusKey: cardFocusKey,
    onEnterPress: () => {
      onPress?.(item);
    },
    onArrowPress: (direction, props, details): boolean => {
      return onGridCardArrowPress(direction, props, details, index, parentFocusKey, dataLength);
    },
  });

  return (
    <TeamCardWrapper onPress={() => onPress(item)} style={styles.teamCardLayout} nativeID={cardFocusKey} ref={ref}>
      <View style={styles.wrapper}>
        <ImageBackground source={teamLogo} style={styles.background}>
          <View style={styles.overlay} />
          <Image source={teamLogo} style={styles.teamLogoStyle} />
        </ImageBackground>
        <TextContent style={styles.titleStyle}>{item?.title || ''}</TextContent>
      </View>
    </TeamCardWrapper>
  );
};

export default TeamCard;
