import React from 'react';
import { Text, View } from 'react-native';
import { Calendar, ImageAssets, Play, Refresh } from '@warnermmedia/gsp-core/brands/estadio/assets';
import { useGetDevice } from '@warnermmedia/gsp-core/sdk/ui';
import { getStyles } from './../video/videoCard.styles';
import { getCardStyles } from './cardsAtoms.styles';
import { useReactiveVar } from '@apollo/client';
import { breakpointsStateStore } from '../../../../../data-access/src';
import { useTheme } from 'react-native-paper';

export interface CardSettingsProps {
  defaultBackground: JSX.Element;
  playButton: JSX.Element;
  upcomingCTA: JSX.Element;
  playCTA: JSX.Element;
  playLabel: JSX.Element;
  playLabelTv: JSX.Element;
  refreshCTA: JSX.Element;
  refreshLabel: JSX.Element;
  refreshLabelTv: JSX.Element;
}

export const getCardSettings = (colors: ReactNativePaper.ThemeColors): CardSettingsProps => {
  const cardSettings = {
    defaultBackground: ImageAssets.stadiumBackground,
    playButton: (
      <Play
        color={colors.tenantBackground.light.surfaceBase}
        iconColor={colors.tenantBackground.light.surfaceBase}
        height={50}
        width={50}
        opacity={0.2}
      />
    ),
    upcomingCTA: (
      <Calendar color={colors.fill.action.accent02} iconColor={colors.fill.action.accent04} height={38} width={38} />
    ),
    playCTA: (
      <Play
        color={colors.tenantBackground.light.surfaceBase}
        iconColor={colors.fill.action.accent01}
        height={38}
        width={38}
      />
    ),
    playLabel: (
      <Play
        color={colors.tenantBackground.light.surfaceBase}
        iconColor={colors.fill.action.accent01}
        height={20}
        width={20}
      />
    ),
    playLabelTv: (
      <Play
        color={colors.tenantBackground.light.surfaceBase}
        iconColor={colors.fill.action.accent01}
        height={32}
        width={32}
      />
    ),
    refreshCTA: (
      <Refresh color={colors.fill.action.accent02} iconColor={colors.fill.action.accent04} height={38} width={38} />
    ),
    refreshLabel: (
      <Refresh color={colors.fill.action.accent02} iconColor={colors.fill.action.accent04} height={20} width={20} />
    ),
    refreshLabelTv: (
      <Refresh color={colors.fill.action.accent02} iconColor={colors.fill.action.accent04} height={32} width={32} />
    ),
  };
  return cardSettings;
};

export const SlideTop = ({
  left = '',
  right = '',
  isLive = false,
}: {
  left?: string;
  right?: string;
  isLive?: boolean;
}) => {
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const { isTv } = useGetDevice();
  const { colors } = useTheme();
  const styles = getStyles(breakpoints, isTv, colors);
  const cardStyle = getCardStyles(colors);
  const cardSettings = getCardSettings(colors);

  if (isLive) {
    return (
      <View
        style={[
          styles.slideTopTitle,
          right === '' && {
            justifyContent: 'flex-start',
          },
        ]}
      >
        <View style={[cardStyle.viewStyle, { backgroundColor: colors.fill.action.accent01 }]}>
          <View style={cardStyle.iconStyle}>{cardSettings.playLabel}</View>
          <Text style={cardStyle.textStyle}>En Vivo</Text>
        </View>
        {right !== '' && (
          <View style={styles.clipDurationView}>
            <Text style={styles.clipDurationText}>{right}</Text>
          </View>
        )}
      </View>
    );
  }
  return (
    <View
      style={[
        styles.slideTopTitle,
        left === '' &&
          right !== '' && {
            justifyContent: 'flex-end',
          },
        right === '' &&
          left !== '' && {
            justifyContent: 'flex-start',
          },
      ]}
    >
      {left !== '' && (
        <View style={styles.clipLabelView}>
          <Text style={styles.clipLabelText}>{left}</Text>
        </View>
      )}
      {right !== '' && (
        <View style={styles.clipDurationView}>
          <Text style={styles.clipDurationText}>{right}</Text>
        </View>
      )}
    </View>
  );
};

export default SlideTop;
