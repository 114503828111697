import { ViewStyle, StyleSheet, StyleProp, TextStyle } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';
import { merge } from 'lodash';

interface Styles {
  wrapper: ViewStyle;
  subTitle: TextStyle;
}

export const getStyles = (breakpoints: Breakpoints, isTv: boolean) => {
  const base: StyleProp<Styles> = {
    wrapper: {
      width: '100%',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    subTitle: {
      fontFamily: 'Oswald-SemiBold',
      fontSize: 22,
      fontWeight: '600',
      textTransform: 'uppercase',
      alignSelf: 'flex-start',
      marginBottom: 16,
      paddingLeft: 4,
    },
  };

  const mediaQueries = isTv
    ? ({
        subTitle: {
          fontSize: 33,
          marginBottom: 24,
        },
      } as StyleProp<Styles>)
    : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
