import React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';

/* eslint-disable-next-line */
export interface ArrowProps {
  width?: number;
  height?: number;
  color?: string;
  iconColor?: string;
  isLeftPoint?: boolean;
}

export function Arrow(props: ArrowProps) {
  const width = props.width || 20;
  const height = props.height || 20;
  const color = props.color || '#359fd7';
  const iconColor = props.iconColor || '#fff';

  return (
    <Svg fill="none" height={height} viewBox="7 7 6 6" width={width}>
      <Circle cx="10" cy="10" r="10" fill={color} />
      <Path
        fill={iconColor}
        d="M12.678 9.332c.37.369.43.907.134 1.203l-4.285 4.277c-.296.296-.835.236-1.205-.133-.37-.37-.43-.908-.134-1.203l4.285-4.278c.295-.295.835-.236 1.205.134z"
        transform={props.isLeftPoint ? 'scale(-1 1) translate(-20 0)' : undefined}
      />
      <Path
        fill={iconColor}
        d="M12.678 10.668c-.37.37-.91.43-1.205.134L7.188 6.524c-.295-.295-.236-.834.134-1.203.37-.369.91-.429 1.205-.133l4.285 4.277c.296.296.236.834-.134 1.203z"
        transform={props.isLeftPoint ? 'scale(-1 1) translate(-20 0)' : undefined}
      />
    </Svg>
  );
}

export default Arrow;
