import React, { useContext, useEffect } from 'react';
import {
  MparticleEventDefaultPropertyValue,
  useMparticleCustomEventObject,
} from '@warnermmedia/gsp-core/brands/estadio/feature';
import { SubscriptionFailure } from '../subscriptionFailure';
import { MParticleCustomEventTypes, mParticleEventProcessor } from '@warnermmedia/gsp-core/sdk/data-access';
import { HistoryContext } from '@warnermmedia/gsp-core/brands/estadio/data-access';

export const PayPalSubscriptionFailure = () => {
  const historyContext = useContext(HistoryContext);
  const history = historyContext?.ready ? historyContext?.useHistory() : null;
  const mParticleEventData = useMparticleCustomEventObject();

  useEffect(() => {
    mParticleEventProcessor.pushMParticleEvent(MParticleCustomEventTypes.SubscriptionFailEvent, {
      ...mParticleEventData,
      ...{ error_code: MparticleEventDefaultPropertyValue },
    });
  }, []);

  return <SubscriptionFailure handleTryAgain={() => history?.push('/subscribe')} />;
};

export default PayPalSubscriptionFailure;
