import { BackwardsCompatibleKeyMap } from '@noriginmedia/norigin-spatial-navigation';

/**
 * Direction is an enum of possible gamepad events which can fire.
 */
export enum Button {
  Submit = 0,
  Back = 1,
  X = 2,
  Y = 3,
  TabLeft = 4, // Left Bumper
  TabRight = 5, // Right Bumper
  TabUp = 6, // Left Trigger
  TabDown = 7, // Right Trigger
  View = 8, // Left small button, aka start
  Menu = 9, // Right small button
  Up = 12,
  Down = 13,
  Left = 14,
  Right = 15,
  ArrowUp = 38,
  ArrowDown = 40,

  Rewind = 89,
  FastForward = 90,
  PlayPause = 85,
  Play = 415, //lg and samsung
  Pause = 19, //lg and samsung
  RWD = 412, //lg and samsung
  FWD = 417, //lg and samsung

  FireMediaPlayPause = 179,
  FireMediaFastForward = 228,
  FireMediaRewind = 227,

  SAMSUNG_CANCEL = 65385,
  SAMSUNG_DONE = 65376,
  SAMSUNG_EXIT = 10009,

  SAMSUNG_PLAYPAUSE = 10252,
}

/**
 * The set of left/right/up/down directional buttons.
 */
export const directionalButtons: ReadonlySet<Button> = new Set([Button.Left, Button.Right, Button.Up, Button.Down]);

/**
 * The set of gamepad buttons that aren't left/right/up/down focuses.
 */
export const nonDirectionalButtons = new Set([
  Button.Submit,
  Button.Back,
  Button.X,
  Button.Y,
  Button.TabLeft,
  Button.TabRight,
  Button.TabUp,
  Button.TabDown,
  Button.View,
  Button.Menu,
  Button.FastForward,
  Button.Rewind,
  Button.PlayPause,
]);

/**
 * The set of all button codes.
 */
export const buttons: ReadonlySet<Button> = new Set([...nonDirectionalButtons, ...directionalButtons]);

/**
 * Returns if the direction is left or right.
 */
export function isHorizontal(direction: Button): boolean {
  return direction === Button.Left || direction === Button.Right;
}

/**
 * Returns if the direction is up or down.
 */
export function isVertical(direction: Button): boolean {
  return direction === Button.Up || direction === Button.Down;
}

/**
 * Returns whether the button press is directional.
 */
export function isDirectional(direction: Button): boolean {
  return (
    direction === Button.Up || direction === Button.Down || direction === Button.Left || direction === Button.Right
  );
}

export enum CommonKeys {
  Left = 0,
  Right = 1,
  Up = 2,
  Down = 3,
  Submit = 4,
  Back = 5,
  X = 6,
  Y = 7,
  TabLeft = 8,
  TabRight = 9,
  TabUp = 10,
  TabDown = 11,
  View = 12,
  Menu = 13,
}

export enum KeyCodes {
  Backspace = 8,
  Tab = 9,
  Enter = 13,
  Shift = 16,
  Ctrl = 17,
  Alt = 18,
  Pause = 19,
  CapsLock = 20,
  Escape = 27,
  Space = 32,
  PageUp = 33,
  PageDown = 34,
  End = 35,
  Home = 36,
  LeftArrow = 37,
  UpArrow = 38,
  RightArrow = 39,
  DownArrow = 40,
  Insert = 45,
  Delete = 46,
  Digit0 = 48,
  Digit1 = 49,
  Digit2 = 50,
  Digit3 = 51,
  Digit4 = 52,
  Digit5 = 53,
  Digit6 = 54,
  Digit7 = 55,
  Digit8 = 56,
  Digit9 = 57,
  a = 65,
  b = 66,
  c = 67,
  d = 68,
  e = 69,
  f = 70,
  g = 71,
  h = 72,
  i = 73,
  j = 74,
  k = 75,
  l = 76,
  m = 77,
  n = 78,
  o = 79,
  p = 80,
  q = 81,
  r = 82,
  s = 83,
  t = 84,
  // u = 85,
  v = 86,
  w = 87,
  x = 88,
  // y = 89,
  // z = 90,
  LeftWindowLey = 91,
  RightWindowKey = 92,
  SelectKey = 93,
  Numpad0 = 96,
  Numpad1 = 97,
  Numpad2 = 98,
  Numpad3 = 99,
  Numpad4 = 100,
  Numpad5 = 101,
  Numpad6 = 102,
  Numpad7 = 103,
  Numpad8 = 104,
  Numpad9 = 105,
  Multiply = 106,
  Add = 107,
  Subtract = 109,
  DecimalPoint = 110,
  Divide = 111,
  F1 = 112,
  F2 = 113,
  F3 = 114,
  F4 = 115,
  F5 = 116,
  F6 = 117,
  F7 = 118,
  F8 = 119,
  F9 = 120,
  F10 = 121,
  F11 = 122,
  F12 = 123,
  NavigationLeft = 140,
  NavigationRight = 141,
  NavigationAccept = 142,
  NavigationUp = 138,
  NavigationDown = 139,
  NumLock = 144,
  ScrollLock = 145,
  SemiColon = 186,
  EqualSign = 187,
  Comma = 188,
  Dash = 189,
  Period = 190,
  ForwardSlash = 191,
  GraveAccent = 192,
  OpenBracket = 219,
  BackSlash = 220,
  CloseBraket = 221,
  SingleQuote = 222,
  GamepadA = 195,
  GamepadB = 196,
  GamepadX = 197,
  GamepadY = 198,
  GamepadRightShoulder = 199,
  GamepadLeftShoulder = 200,
  GamepadLeftTrigger = 201,
  GamepadRightTrigger = 202,
  GamepadDPadUp = 203,
  GamepadDPadDown = 204,
  GamepadDPadLeft = 205,
  GamepadDPadRight = 206,
  GamepadMenu = 207,
  GamepadView = 208,
  GamepadLeftThumbstick = 209,
  GamepadRightThumbstick = 210,
  GamepadLeftThumbstickUp = 211,
  GamepadLeftThumbstickDown = 212,
  GamepadLeftThumbstickRight = 213,
  GamepadLeftThumbstickLeft = 214,
  GamepadRightThumbstickUp = 215,
  GamepadRightThumbstickDown = 216,
  GamepadRightThumbstickRight = 217,
  GamepadRightThumbstickLeft = 218,

  // FireTV
  FireMediaPlayPause = 179,
  FireMediaRewind = 227,
  FireMediaFastForward = 228,

  PlayPause = 85,
  Rewind = 89,
  FastForward = 90,
  LgPlay = 415,
  LgPause = 19,
  LgRWD = 412,
  LgFWD = 417,

  SamsungEnter = 13,
  SamsungRWD = 412,
  SamsungFWD = 417,
  SamsungPlay = 415,
  SamsungPause = 19,
  SamsungStop = 413,
  SamsungBack = 10009,
  SamsungLeft = 37,
  SamsungUp = 38,
  SamsungRight = 39,
  SamsungDown = 40,
  SamsungReturn = 10009,
  SamsungExit = 10182,
  SamsungPLAYPAUSE = 10252,
}

export enum Keyboard_KEYS {
  Backspace = 8,
  Tab = 9,
  Enter = 13,
  Shift = 16,
  Ctrl = 17,
  Alt = 18,
  Pause = 19,
  CapsLock = 20,
  Escape = 27,
  Space = 32,
  PageUp = 33,
  PageDown = 34,
  End = 35,
  Home = 36,
  LeftArrow = 37,
  UpArrow = 38,
  RightArrow = 39,
  DownArrow = 40,
  Insert = 45,
  Delete = 46,
  Digit0 = 48,
  Digit1 = 49,
  Digit2 = 50,
  Digit3 = 51,
  Digit4 = 52,
  Digit5 = 53,
  Digit6 = 54,
  Digit7 = 55,
  Digit8 = 56,
  Digit9 = 57,
  a = 65,
  b = 66,
  c = 67,
  d = 68,
  e = 69,
  f = 70,
  g = 71,
  h = 72,
  i = 73,
  j = 74,
  k = 75,
  l = 76,
  m = 77,
  n = 78,
  o = 79,
  p = 80,
  q = 81,
  r = 82,
  s = 83,
  t = 84,
  // u = 85,
  v = 86,
  w = 87,
  x = 88,
  // y = 89,
  // z = 90,
  LeftWindowLey = 91,
  RightWindowKey = 92,
  SelectKey = 93,
  Numpad0 = 96,
  Numpad1 = 97,
  Numpad2 = 98,
  Numpad3 = 99,
  Numpad4 = 100,
  Numpad5 = 101,
  Numpad6 = 102,
  Numpad7 = 103,
  Numpad8 = 104,
  Numpad9 = 105,
  Multiply = 106,
  Add = 107,
  Subtract = 109,
  DecimalPoint = 110,
  Divide = 111,
  F1 = 112,
  F2 = 113,
  F3 = 114,
  F4 = 115,
  F5 = 116,
  F6 = 117,
  F7 = 118,
  F8 = 119,
  F9 = 120,
  F10 = 121,
  F11 = 122,
  F12 = 123,
  NavigationLeft = 140,
  NavigationRight = 141,
  NavigationAccept = 142,
  NavigationUp = 138,
  NavigationDown = 139,
  NumLock = 144,
  ScrollLock = 145,
  SemiColon = 186,
  EqualSign = 187,
  Comma = 188,
  Dash = 189,
  Period = 190,
  ForwardSlash = 191,
  GraveAccent = 192,
  OpenBracket = 219,
  BackSlash = 220,
  CloseBraket = 221,
  SingleQuote = 222,
}

export enum GAMEPADKEYS {
  GamepadA = 195,
  GamepadB = 196,
  GamepadX = 197,
  GamepadY = 198,
  GamepadRightShoulder = 199,
  GamepadLeftShoulder = 200,
  GamepadLeftTrigger = 201,
  GamepadRightTrigger = 202,
  GamepadDPadUp = 203,
  GamepadDPadDown = 204,
  GamepadDPadLeft = 205,
  GamepadDPadRight = 206,
  GamepadMenu = 207,
  GamepadView = 208,
  GamepadLeftThumbstick = 209,
  GamepadRightThumbstick = 210,
  GamepadLeftThumbstickUp = 211,
  GamepadLeftThumbstickDown = 212,
  GamepadLeftThumbstickRight = 213,
  GamepadLeftThumbstickLeft = 214,
  GamepadRightThumbstickUp = 215,
  GamepadRightThumbstickDown = 216,
  GamepadRightThumbstickRight = 217,
  GamepadRightThumbstickLeft = 218,
}

export enum SAMSUNG_KEYS {
  SamsungEnter = 13,
  SamsungRWD = 412,
  SamsungFWD = 417,
  SamsungPlay = 415,
  SamsungPause = 19,
  SamsungStop = 413,
  SamsungBack = 10009,
  SamsungLeft = 37,
  SamsungUp = 38,
  SamsungRight = 39,
  SamsungDown = 40,
  SamsungReturn = 10009,
  SamsungExit = 10182,
  SamsungPLAYPAUSE = 10252,
}

export enum SamsungMisc {
  SAMSUNG_CANCEL = 65385,
  SAMSUNG_DONE = 65376,
  SAMSUNG_EXIT = 10009,
}

export enum FIRE_TV_KEYS {
  // FireTV
  FireMediaPlayPause = 179,
  FireMediaRewind = 227,
  FireMediaFastForward = 228,

  PlayPause = 85,
  Rewind = 89,
  FastForward = 90,
  LgPlay = 415,
  LgPause = 19,
  LgRWD = 412,
  LgFWD = 417,
}

export enum WEBOS_KEYS {
  Left = 37,
  Up = 38,
  Right = 39,
  Down = 40,
  OK = 13,
  Back = 461,
  Red = 403,
  Green = 404,
  Yellow = 405,
  Blue = 406,
}

export const AllkeyMap: BackwardsCompatibleKeyMap = {
  left: [WEBOS_KEYS.Left, SAMSUNG_KEYS.SamsungLeft, Keyboard_KEYS.LeftArrow, CommonKeys.Left],
  up: [WEBOS_KEYS.Up, SAMSUNG_KEYS.SamsungUp, Keyboard_KEYS.UpArrow, CommonKeys.Up],
  right: [WEBOS_KEYS.Right, SAMSUNG_KEYS.SamsungRight, Keyboard_KEYS.RightArrow, CommonKeys.Right],
  down: [WEBOS_KEYS.Down, SAMSUNG_KEYS.SamsungDown, Keyboard_KEYS.DownArrow, CommonKeys.Down],
  enter: [WEBOS_KEYS.OK, SAMSUNG_KEYS.SamsungEnter, Keyboard_KEYS.Enter, CommonKeys.Submit],
};

export const getAndroidTVKeyMap = () => {
  return {
    left: [],
    up: [],
    right: [],
    down: [],
    enter: [],
  };
};

export const getTizenKeyMap = () => {
  return {
    left: [SAMSUNG_KEYS.SamsungLeft],
    up: [SAMSUNG_KEYS.SamsungUp],
    right: [SAMSUNG_KEYS.SamsungRight],
    down: [SAMSUNG_KEYS.SamsungDown],
    enter: [SAMSUNG_KEYS.SamsungEnter],
  };
};

export const getWebKeyMap = () => {
  return {
    left: [Keyboard_KEYS.LeftArrow],
    up: [Keyboard_KEYS.UpArrow],
    right: [Keyboard_KEYS.RightArrow],
    down: [Keyboard_KEYS.DownArrow],
    enter: [Keyboard_KEYS.Enter],
  };
};

export const getWebOSKeyMap = () => {
  return {
    left: [WEBOS_KEYS.Left],
    up: [WEBOS_KEYS.Up],
    right: [WEBOS_KEYS.Right],
    down: [WEBOS_KEYS.Down],
    enter: [WEBOS_KEYS.OK],
  };
};
