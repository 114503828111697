import { loginAPIStateStore } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { Dalton } from '@warnermmedia/gsp-core/brands/estadio/feature';

interface UseRefreshTokenProps {
  refreshUserToken: () => Promise<string | null>;
}

export const useRefreshToken = (): UseRefreshTokenProps => {
  const { refreshToken } = Dalton();

  const refreshUserToken = async (): Promise<string | null> => {
    let authToken = null;
    if (loginAPIStateStore().isTveAuth) {
      authToken = loginAPIStateStore().authToken;
    } else {
      const tokenResponse = await refreshToken();
      authToken = tokenResponse?.data?.authToken ?? null;
    }
    return authToken;
  };

  return {
    refreshUserToken,
  };
};
