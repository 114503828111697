import { StyleProp, StyleSheet, TextStyle, ViewStyle } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';
import merge from 'lodash/merge';

interface Styles {
  activateBtn: ViewStyle;
  activateBtnText: TextStyle;
  cancelBtn: ViewStyle;
  cancelBtnText: TextStyle;
  canceledText: TextStyle;
  descriptionText: TextStyle;
}

export const getStyles = (breakpoints: Breakpoints, colors: ReactNativePaper.ThemeColors) => {
  const base: StyleProp<Styles> = {
    activateBtn: {
      maxWidth: 303,
      backgroundColor: 'transparent',
      borderWidth: 2,
      borderColor: colors.tenantBackground.light.surfaceBase,
      marginVertical: 12,
    },
    activateBtnText: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Oswald-Medium',
      fontSize: 20,
    },
    descriptionText: {
      fontSize: 16,
      lineHeight: 20,
      marginVertical: 12,
      fontFamily: 'Rubik-Regular',
    },
    cancelBtn: {
      maxWidth: 302,
      marginVertical: 12,
    },
    cancelBtnText: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Oswald-Medium',
      fontSize: 20,
    },
    canceledText: {
      marginBottom: 24,
      width: '100%',
    },
  };

  const mediaQueries =
    breakpoints.currentBreakpoints.isTiny ||
    breakpoints.currentBreakpoints.isTnySm ||
    breakpoints.currentBreakpoints.isSmMed
      ? ({
          activateBtn: {
            maxWidth: '100%',
          },
          cancelBtn: {
            maxWidth: '100%',
          },
        } as StyleProp<Styles>)
      : breakpoints.currentBreakpoints.isMedLg
      ? ({
          activateBtn: {
            maxWidth: 335,
          },
          cancelBtn: {
            maxWidth: 335,
          },
        } as StyleProp<Styles>)
      : breakpoints.currentBreakpoints.isLgXl ||
        breakpoints.currentBreakpoints.isXlXxl ||
        breakpoints.currentBreakpoints.isXxl
      ? ({} as StyleProp<Styles>)
      : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
