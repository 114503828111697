import React, { useCallback, useEffect, useState } from 'react';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  carouselBase,
  Dalton,
  languageStrings,
  MparticleEventDefaultPropertyValue,
  QueryNames,
  TeamPagesUrl,
  useCMSQueryDataResults,
  useCMSTeamsData,
  useEmailStatus,
  useIsMountedRef,
  useMparticleCustomEventObject,
  useProfileData,
} from '@warnermmedia/gsp-core/brands/estadio/feature';
import { TeamListItem } from '@warnermmedia/gsp-core/brands/estadio/ui';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  isTveAuth,
  isUserLoggedIn,
  loginAPIStateStore,
  Page,
  USER_FAVORITE_TEAM,
} from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { useReactiveVar } from '@apollo/client';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  loadData,
  MParticleCustomEventTypes,
  mParticleEventProcessor,
  Preference,
} from '@warnermmedia/gsp-core/sdk/data-access';
import FavTeamModal from '../modal/favTeamModal/favTeamModal';
import { useGetDevice } from '@warnermmedia/gsp-core/sdk/ui';
import { cloneDeep } from 'lodash';

const FavoriteTeam = () => {
  const { isTv } = useGetDevice();
  const dalton = Dalton();
  const { userData } = useProfileData();
  const { isEmailVerified } = useEmailStatus();
  const [visible, setVisible] = useState(false);
  const [skipFavoriteTeamModal, setSkipFavoriteTeamModal] = useState(false);
  const [isFavoriteTeamModalSaved, setIsFavoriteTeamModalSaved] = useState(false);
  const [favoriteTeamErrorMessage, setFavoriteTeamErrorMessage] = useState('');
  const { pageData: pageUriData } = useCMSQueryDataResults<Page>({
    queryName: QueryNames.GET_TEAMS,
    uri: TeamPagesUrl.TeamsList,
  });
  const { formatTeamData, formatTeamItem } = useCMSTeamsData();
  const [teams, setTeams] = useState<TeamListItem[]>([]);
  const [rawTeamsObj, setRawTeamsObj] = useState<carouselBase[]>([]);
  const isLogged = useReactiveVar(isUserLoggedIn);
  const { preferences } = useReactiveVar(loginAPIStateStore);
  const mParticleEventData = useMparticleCustomEventObject();
  const isTveAuthenticated = useReactiveVar(isTveAuth);
  const isMountedRef = useIsMountedRef();

  useEffect(() => {
    if (isMountedRef.current && preferences !== null) {
      const faveTeamData = !!userData && !!loadData(USER_FAVORITE_TEAM) && loginAPIStateStore().preferencesProcessed;
      const hasFavoriteTeam =
        !faveTeamData &&
        teams.length > 0 &&
        isLogged &&
        isEmailVerified &&
        !skipFavoriteTeamModal &&
        !isFavoriteTeamModalSaved &&
        // The TVE check needs to be removed on TVE face 2
        !isTveAuthenticated;
      hasFavoriteTeam && setVisible(true);
    }
  }, [
    isEmailVerified,
    isFavoriteTeamModalSaved,
    isLogged,
    isMountedRef,
    isTveAuthenticated,
    preferences,
    skipFavoriteTeamModal,
    teams.length,
    userData.id,
  ]);

  useEffect(() => {
    if (isMountedRef.current && !isLogged && skipFavoriteTeamModal) {
      setSkipFavoriteTeamModal(false);
    }
  }, [isMountedRef, isLogged, skipFavoriteTeamModal]);

  useEffect(() => {
    if (pageUriData && pageUriData.components && isMountedRef.current) {
      const teamsData = formatTeamData(pageUriData.components);
      if (teamsData && teamsData.length > 0) {
        const teams = formatTeamItem(teamsData);
        loginAPIStateStore({ ...loginAPIStateStore(), ...{ competitors: cloneDeep(teamsData) } });
        setTeams(teams);
        setRawTeamsObj(teamsData);
      }
    }
  }, [pageUriData, isMountedRef]);

  const handleCloseFavoriteModal = useCallback(
    (teamSaved = false) => {
      setVisible(false);
      setSkipFavoriteTeamModal(true);
      !teamSaved &&
        mParticleEventProcessor.pushMParticleEvent(MParticleCustomEventTypes.FavoriteTeamPostponeEvent, {
          ...mParticleEventData,
          ...{ favorite_team: MparticleEventDefaultPropertyValue },
        });
    },
    [mParticleEventData]
  );

  const getUserPreferences = (teamName: string, userPreferences: Preference[] = []): Preference[] => {
    const teamObj = rawTeamsObj.find((teamData) => teamData.title === teamName);
    const teamPreferences: Preference[] = [];

    if (teamObj) {
      const favoriteTeamId = teamObj?.sportRadarId ?? '';
      teamPreferences.push(
        ...[
          {
            collection: 'favoriteTeam',
            selections: [teamName],
          },
          {
            collection: 'favoriteTeamId',
            selections: [favoriteTeamId],
          },
        ]
      );
    }
    const keysToFilter: string[] = teamPreferences.map((item) => item.collection);
    return [...userPreferences.filter((item) => !keysToFilter.includes(item.collection)), ...teamPreferences];
  };

  const saveFavoriteTeam = async (selected: string) => {
    try {
      if (selected) {
        const userPreferences = getUserPreferences(selected, preferences ?? []);
        const preferenceRes = await dalton.updateUserPreferences({ preferences: userPreferences });

        if (preferenceRes && preferenceRes.success) {
          loginAPIStateStore({ ...loginAPIStateStore(), ...{ preferences: userPreferences } });
          mParticleEventProcessor.pushMParticleEvent(MParticleCustomEventTypes.FavoriteTeamSelectEvent, {
            ...mParticleEventData,
            ...{ favorite_team: selected },
          });
          setIsFavoriteTeamModalSaved(true);
          handleCloseFavoriteModal(true);
        }
      }
    } catch (e) {
      setFavoriteTeamErrorMessage(languageStrings.default.favoriteTeamErrorMessage);
    }
  };

  return !isTv && visible ? (
    <FavTeamModal
      list={teams}
      visible={!isTv && visible}
      onSave={saveFavoriteTeam}
      onRequestClose={handleCloseFavoriteModal}
      headerText={languageStrings.default.favoriteTeamModalHeaderText}
      messageText={languageStrings.default.favoriteTeamModalMessageText}
      skipLabel={languageStrings.default.favoriteTeamModalSkipLabel}
      saveLabel={languageStrings.default.saveText}
      dropDownTitle={languageStrings.default.favoriteTeamModalDropdownTitle}
      errorMessage={favoriteTeamErrorMessage}
      dropDownPlaceholder={languageStrings.default.favoriteTeamModalDropdownPlaceholder}
    />
  ) : null;
};

export default FavoriteTeam;
