// CMS Page component types
export const componentTypes = {
  TOUT: 'tout',
  SCREEN: 'screen',
};

export const estadioErrorMessages: Record<string, Record<string | number, string>> = {
  signup: {
    429: 'La identidad (correo electrónico) ya existe',
    420: 'No se pudo completar el procesamiento debido a una solicitud incorrecta.',
    401: 'Usuario no autorizado para realizar esta llamada ',
    424: 'Usuario no encontrado o dirección de correo electrónico no encontrada',
    general: 'Hubo un problema al registrarse',
  },
};

export const USER_FAVORITE_TEAM = 'USER_FAVORITE_TEAM';
export const LAST_ROUTE = 'LAST_ROUTE';
export const PROVIDER_LOGIN_REDIRECT_TIME = 'PROVIDER_LOGIN_REDIRECT_TIME';
export const PROVIDER_LOGIN_CODE_VALIDATION = 'PROVIDER_LOGIN_CODE_VALIDATION';
export const PIN_CODE = 'PIN_CODE';
export const COUPON = 'COUPON';
export const ESTADIO_NON_LAZY_ROUTES = [
  '/login',
  '/resend-email',
  '/register',
  '/login-provider',
  '/profile',
  '/logintv',
  '/logintv-success',
  '/pinlogin',
  '/home',
  '/resend-email',
  '/subscribe',
  '/login-provider',
  '/consulta-boleta',
];
export const LOGIN_TIMEOUT = 5000;

export const CHILE_TIMEZONE = 'America/Santiago';

export const CID = 'CID';

export const HIGHEST_IMAGE_BREAKPOINT = 1439;

export const SKELETON_NAVBAR_WIDTH = 108; // 108px is the width of the navbar when it's collapsed

export const MAX_URL = 'https://www.max.com/';
