import { ViewStyle, StyleSheet, Platform, StyleProp } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';
import { merge } from 'lodash';

interface Styles {
  statusBackground: ViewStyle;
  header: ViewStyle;
}

export const getStyles = (breakpoints: Breakpoints) => {
  const base: StyleProp<Styles> = {
    statusBackground: {
      borderRadius: 5,
      height: '50%',
      marginTop: 4,
      position: 'absolute',
      width: '100%',
    },
    header: {
      alignItems: 'center',
      width: '100%',
      zIndex: 101,
      ...Platform.select({
        android: {
          elevation: 101,
        },
      }),
    },
  };

  const mediaQueries =
    breakpoints.currentBreakpoints.isMedLg || breakpoints.currentBreakpoints.isLgXl
      ? ({} as StyleProp<Styles>)
      : breakpoints.currentBreakpoints.isXlXxl
      ? ({} as StyleProp<Styles>)
      : breakpoints.currentBreakpoints.isXlXxl || breakpoints.currentBreakpoints.isXxl
      ? ({} as StyleProp<Styles>)
      : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
