import merge from 'lodash/merge';
import { StyleProp, StyleSheet, ViewStyle, TextStyle } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';

interface Styles {
  container: ViewStyle;
  topContent: ViewStyle;
  ctaBtn: ViewStyle;
  toRow: ViewStyle;
  wrapText: ViewStyle;
  inputContainer: ViewStyle;
  title: TextStyle;
  instruction: TextStyle;
  topMargin: ViewStyle;
  displayIcon: ViewStyle;
  messageWrapper: ViewStyle;
  errorMessageText: TextStyle;
  errorMessageLinkText: TextStyle;
  subTitle: TextStyle;
}

export const getStyles = (breakpoints: Breakpoints, colors: ReactNativePaper.ThemeColors) => {
  const base: StyleProp<Styles> = {
    container: {
      backgroundColor: colors.tenantBackground.dark.surfaceBase,
      flex: 1,
      height: '100%',
    },
    topContent: {
      marginBottom: 20,
    },
    ctaBtn: {
      backgroundColor: colors.fill.action.accent01,
    },
    toRow: {
      flexDirection: 'row',
    },
    wrapText: {
      flex: 1,
      flexWrap: 'wrap',
    },
    inputContainer: {
      marginBottom: 15,
    },
    title: {
      fontSize: 22,
      textTransform: 'uppercase',
      marginBottom: 18,
      fontWeight: '600',
      color: colors.keyboardFocus.light,
    },
    instruction: {
      fontSize: 16,
      marginBottom: 18,
      color: colors.keyboardFocus.light,
      fontWeight: 'normal',
    },
    topMargin: {
      marginTop: 10,
    },
    displayIcon: {
      margin: 0,
      padding: 0,
      justifyContent: 'flex-start',
    },
    messageWrapper: {
      width: '80%',
      height: '100%',
      maxWidth: 450,
      alignSelf: 'center',
    },
    errorMessageText: {
      fontFamily: 'Rubik-Regular',
    },
    errorMessageLinkText: {
      fontFamily: 'Rubik-Regular',
      textDecorationLine: 'underline',
    },
    subTitle: {
      fontFamily: 'Rubik-Regular',
      color: colors.keyboardFocus.light,
      fontSize: 16,
      textTransform: 'none',
      textAlign: 'left',
      width: '100%',
      lineHeight: 24,
      marginVertical: 24,
    },
  };

  const mediaQueries =
    breakpoints.currentBreakpoints.isTiny ||
    breakpoints.currentBreakpoints.isTnySm ||
    breakpoints.currentBreakpoints.isSmMed
      ? ({} as StyleProp<Styles>)
      : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
