import { Image, ImageResolvedAssetSource, ImageSourcePropType, Platform } from 'react-native';

const nullResource: ImageResolvedAssetSource = {
  height: 0,
  width: 0,
  scale: 0,
  uri: '',
};

const getAssetSource = (file: ImageSourcePropType): ImageResolvedAssetSource => {
  if (Platform?.OS !== 'web') return Image.resolveAssetSource(file);
  return nullResource;
};

export const ImageAssets = {
  closeModalBar: getAssetSource(require('../closeModalBar.png')),
  tournamentBg: require('../tournamentbg.png'),
  defaultTeamLogo: require('../default-logo.png'),
  americanExpress: require('../AmericanExpress.png'),
  dinersClub: require('../Dinersclub.png'),
  masterCard: require('../Mastercard.png'),
  payPal: require('../PayPal.png'),
  visa: require('../Visa.png'),
  stadiumBackground: require('../stadium-background.jpeg'),
  downArrow: require('../DownArrow.png'),
  rewind: getAssetSource(require('../rewind.png')),
  fastforward: getAssetSource(require('../fast-forward.png')),
  championship: require('../championship.png'),
  closedNavLogo: require('../Compact.png'),
  checkedIcon: require('../icon-check.png'),
  heroBackground: require('../hero_bg.png'),
  matchBackground: require('../match_bg.png'),
  pinLoginBackground: require('../tvlandingbg.png'),
  replay: require('../replay-button.png'),
  live: require('../liveIcon.png'),
  logoTntRebrand: require('../tntSportsLogo.png'),
};

export default ImageAssets;

export * from './AppLogo/navbarNarrow';
export * from './AppLogo/navbarWide';
export * from './AppLogo/navbarNarrowNoGradient';
export * from './AppLogo/navbarWideNoGradient';
export * from './Icons/arrow';
export * from './Icons/calendar';
export * from './Icons/close';
export * from './Icons/competition';
export * from './Icons/credit-card';
export * from './Icons/facebook';
export * from './Icons/field';
export * from './Icons/hamburger';
export * from './Icons/heart';
export * from './Icons/home';
export * from './Icons/instagram';
export * from './Icons/url';
export * from './Icons/pin';
export * from './Icons/play';
export * from './Icons/pause';
export * from './Icons/question';
export * from './Icons/refresh';
export * from './Icons/search';
export * from './Icons/sheet';
export * from './Icons/sign-out';
export * from './Icons/signal';
export * from './Icons/stats';
export * from './Icons/team';
export * from './Icons/hoy';
export * from './Icons/tv';
export * from './Icons/twitter';
export * from './Icons/user';
export * from './Icons/whistle';
export * from './PartnerLogos/tntSports';
export * from './Icons/youtube';
export * from './Icons/iconMenu';
export * from './Icons/castTv';
export * from './Icons/chevron-left';
export * from './Icons/tvReady';
export * from './Icons/passwordShow';
export * from './Icons/passwordHide';
