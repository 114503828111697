import * as React from 'react';
import { StyleProp, TextStyle, View, ViewStyle } from 'react-native';
import { Button, TextInput } from 'react-native-paper';
import { Tooltip } from '../../tooltip/tooltip';
import { TextContent } from '../../textcontent/index';
import { validatePasswordRule } from '../../../utils/common';

import styles from './resetPasswordForm.styles';

export interface ResetPasswordFormProps {
  isLoading?: boolean;
  onRequestSend(password: string): void;
  error?: string;
  topInstruction?: string;
  title?: string;
  formContainerStyle?: StyleProp<ViewStyle>;
  disabledBtnColor?: string;
  btnColor?: string;
  textInputColor?: string;
  passwordLabel: string;
  confirmPasswordLabel: string;
  passwordRule?: string;
  ctaBtnLabel: string;
  labelStyle?: StyleProp<TextStyle>;
  passwordRuleStyle?: StyleProp<TextStyle>;
  btnStyle?: StyleProp<ViewStyle>;
  textInputStyle?: StyleProp<TextStyle>;
  passwordMatchError?: string;
  validatePassword?: (password: string) => boolean;
  ruleMismatchError?: string;
}

export function ResetPasswordForm(props: ResetPasswordFormProps) {
  const {
    isLoading,
    onRequestSend,
    error,
    passwordLabel,
    passwordRule,
    labelStyle,
    passwordRuleStyle,
    btnStyle,
    passwordMatchError,
    ruleMismatchError,
    disabledBtnColor,
    btnColor,
    formContainerStyle,
    textInputColor,
    confirmPasswordLabel,
    ctaBtnLabel,
    textInputStyle,
    validatePassword,
  } = props;

  const [passwordVisibility, setPasswordVisibility] = React.useState(true);
  const [confirmPasswordVisibility, setConfirmPasswordVisibility] = React.useState(true);
  const [isPasswordValid, setPasswordValid] = React.useState(true);
  const [fields, setFields] = React.useState({
    password: {
      value: '',
      error: '',
    },
    confirmPassword: {
      value: '',
      error: '',
    },
  });

  const {
    password: { value: password, error: passwordError },
    confirmPassword: { value: confirmPassword, error: confirmPasswordError },
  } = fields;

  const disabled = isLoading || !password || !confirmPassword;
  const misMatchError = ruleMismatchError || 'The password does not meet the minimum requirements for us to confirm it';

  function onValueChange(field: string) {
    return function (value: string) {
      setFields({
        ...fields,
        [field]: {
          value,
          error: '',
        },
      });
    };
  }

  function sendRequest() {
    if (disabled) {
      return;
    }
    if (password !== confirmPassword) {
      return setFields((inputs) => ({
        ...inputs,
        confirmPassword: {
          value: confirmPassword,
          error: passwordMatchError || 'Passwords do not match. They must match to confirm your new password.',
        },
      }));
    }
    if (validatePassword) {
      if (validatePassword(password)) {
        setPasswordValid(true);
        onRequestSend && onRequestSend(password);
      } else {
        setPasswordValid(false);
      }
    } else {
      if (validatePasswordRule(password)) {
        setPasswordValid(true);
        return onRequestSend && onRequestSend(password);
      }
      setPasswordValid(false);
    }
  }

  function onBlur() {
    if (confirmPassword && validatePassword) {
      if (validatePassword(confirmPassword)) {
        setPasswordValid(true);
      }
    }
  }

  return (
    <View style={formContainerStyle}>
      <View style={styles.topContent}>
        <View style={styles.inputContainer}>
          <TextContent style={[styles.textLabel, labelStyle]}>{passwordLabel || 'Password'} </TextContent>
          <TextInput
            value={password}
            mode="outlined"
            style={textInputStyle}
            secureTextEntry={passwordVisibility}
            onChangeText={onValueChange('password')}
            right={
              <TextInput.Icon
                color="#000"
                name={passwordVisibility ? 'eye' : 'eye-off'}
                onPress={() => setPasswordVisibility(!passwordVisibility)}
              />
            }
            textAlign="left"
            error={!!error || !!passwordError}
            theme={{ colors: { text: textInputColor || '#000' } }}
          />
          {passwordError ? <Tooltip tooltipText={passwordError} opacity={1} visible={true} position="left" /> : null}
        </View>
        <View style={styles.inputContainer}>
          <TextContent style={[styles.textLabel, labelStyle]}>
            {confirmPasswordLabel || 'Confirm Password'}{' '}
          </TextContent>
          <TextInput
            value={confirmPassword}
            mode="outlined"
            style={textInputStyle}
            secureTextEntry={confirmPasswordVisibility}
            onChangeText={onValueChange('confirmPassword')}
            textAlign="left"
            onBlur={onBlur}
            right={
              <TextInput.Icon
                color="#000"
                name={confirmPasswordVisibility ? 'eye' : 'eye-off'}
                onPress={() => setConfirmPasswordVisibility(!confirmPasswordVisibility)}
              />
            }
            error={!!error || !!confirmPasswordError}
            theme={{ colors: { text: textInputColor || '#000' } }}
          />
          {confirmPasswordError || !isPasswordValid ? (
            <Tooltip
              tooltipText={confirmPasswordError || misMatchError}
              opacity={1}
              visible={true}
              position={confirmPasswordError ? 'left' : 'center'}
            />
          ) : null}
        </View>
        <TextContent style={[styles.rule, passwordRuleStyle]}>
          {passwordRule ||
            'Passwords must contain a minimum of 8 characters: 1 uppercase, 1 lowercase, 1 number or symbol'}
        </TextContent>
      </View>
      <Button
        color={disabled ? disabledBtnColor || '#710d53' : btnColor || '#ff0085'}
        style={btnStyle}
        loading={isLoading}
        mode="contained"
        onPress={sendRequest}
      >
        {ctaBtnLabel || 'Update Password'}
      </Button>
    </View>
  );
}

export default React.memo(ResetPasswordForm);
