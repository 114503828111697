import { gql } from '@apollo/client';
import { Platform } from 'react-native';

// Persistence Decorator Switch
const persistDecorator = Platform && Platform?.OS && Platform?.OS === 'web' ? '' : '@persist';

const IMAGE = gql`
  fragment GetImage on Image {
    url
    alt
    width
    height
  }
`;

const METATAGS = gql`
  fragment GetMetatags on PageMetatags {
    type
    key
    content
  }
`;

const SHOW = gql`
  fragment GetShow on Show {
    id
    titleId
    title
    originalTitle
    genreList
    highlightList
    airDateTime
    lang
    begin
    end
    rate
    serie
    season
    episode
    storyline
    franchiseName
    franchiseId
    ratingArgentina
    ratingBrasil
    ratingMexico
    length
    summary
    status
    is_live @client
    taxonomy(tenant: $tenant) {
      parentId
      image {
        ...GetImage
      }
    }
  }
  ${IMAGE}
`;

const SCHEDULE_SHOW = gql`
  fragment GetScheduleShow on Show {
    id
    titleId
    title
    originalTitle
    genreList
    highlightList
    airDateTime
    lang
    begin
    end
    rate
    serie
    season
    episode
    storyline
  }
`;

const CLIP = gql`
  fragment GetClip on Video {
    _id
    key @client
    subtitle @client
    image @client
    callToAction @client
    headline
    subHeadline
    thumbnail
    mediaId
    title
    created
    sortDate
    cardLabel
    duration
  }
`;

const TOURNAMENT = gql`
  fragment GetTournament on Tournament {
    id
    name
    display_name
  }
`;

const PRODUCT = gql`
  fragment GetProduct on Product {
    _id
    headline
    subHeadline
    productId
    cta
    price
    disclaimer
    isPayPalEnabled
  }
`;

const COMPETITOR = gql`
  fragment GetCompetitor on Competitor {
    id
    name
    team_abbr
    team_alias
    description
    sportradar_id
    logo_dark
    logo_light
    logo_split
    header_image_bucket
  }
`;

const GAME = gql`
  fragment GetGame on Game {
    id
    sourceGameId
    score {
      away
      home
    }
    gameDate {
      epoch
      iso8501
    }
  }
`;

const EVENT = gql`
  fragment GetEvent on Event {
    id
    status
    derived_status
    is_live
    event_image
    logo_light
    logo_dark
    scheduled_utc
    type
    title
    has_media
    sportradar_id
    media_asset_id_live
    media_asset_id_vod
    series_id
    series_name
    season
    card_type
    key @client
    subtitle @client
    heading @client
    match @client
    venue {
      name
    }
    game {
      ...GetGame
    }
    home {
      ...GetCompetitor
    }
    away {
      ...GetCompetitor
    }
    tournament {
      ...GetTournament
    }
  }
  ${GAME}
  ${COMPETITOR}
  ${TOURNAMENT}
`;

const SERIES = gql`
  fragment GetSeries on Series {
    id
    season
    series_name
    territories_available
    tournament {
      ...GetTournament
    }
    taxonomy {
      description
      image {
        ...GetImage
      }
    }
  }
  ${IMAGE}
  ${TOURNAMENT}
`;

const SERIES_WITH_EVENTS = gql`
  fragment GetSeriesWithEvents on Series {
    id
    season
    series_name
    territories_available
    tournament {
      ...GetTournament
    }
    events {
      ...GetEvent
    }
    taxonomy {
      description
      image {
        ...GetImage
      }
    }
  }
  ${IMAGE}
  ${EVENT}
  ${TOURNAMENT}
`;

const ITEM = gql`
  fragment GetItem on Item {
    _id
    headline
    subheadline
    description
    style
    startTime
    endTime
    link {
      uri
      text
    }
    images {
      image {
        ...GetImage
      }
      style
    }
  }
  ${IMAGE}
`;

export const GET_HOME_PAGE = gql`
    query estadio_getHomePage (
	        $uri: String!
          $tenant: Tenant!
          $teamId: String!
        ) ${persistDecorator} {
            getPageByUri(uri: $uri, tenant: $tenant, componentFilters: { team: $teamId }) {
                _id
                title
                created
                type
                headline
                metatags {
                  ...GetMetatags
                }
                components {
                    _id
                    id
                    type
                    format
                    headline
                    weight
                    subheadline
                    description
                    image {
                      ...GetImage
                    }
                    images {
                      image {
                        ...GetImage
                      }
                      style
                    }
                    link {
                      uri
                      text
                    }
                    allItems @client {
                        id
                        _id
                        subtitle
                    }
                    content {
                        _id
                        weight
                        liveStream
                        sort {
                          order
                        }
                       filters {
                          maxItems
                       }
                       clips {
                        ...GetClip
                       }
                       events {
                        ...GetEvent
                       }
                       episodes {
                        ...GetShow
                        }
                      item {
                        ...GetItem
                      }
                    }
                }
            }
        }
    ${METATAGS}
    ${SHOW}
    ${CLIP}
    ${EVENT}
    ${IMAGE}
    ${ITEM}
`;

export const GET_HOME_PAGE_CACHED = gql`
    query estadio_getHomePageCached (
	        $uri: String!
          $tenant: Tenant!
          $teamId: String!
        ) ${persistDecorator} {
            getPageByUri(uri: $uri, tenant: $tenant, componentFilters: { team: $teamId }) {
                _id
                title
                created
                type
                headline
                metatags {
                  ...GetMetatags
                }
                components {
                    _id
                    headline
                    id
                    type
                    format
                    weight
                    description
                    image {
                      ...GetImage
                    }
                    images {
                      image {
                        ...GetImage
                      }
                      style
                    }
                    link {
                      uri
                      text
                    }
                    allItems @client {
                        id
                        _id
                        key
                        type
                        subtitle
                        title
                        heading
                        subHeadline
                        match
                        scheduled_utc
                        status
                        is_live
                        image
                        has_media
                        callToAction
                        mediaId
                        sortDate
                        headline
                        productId
                        cta
                        price
                        disclaimer
                        isPayPalEnabled
                        cardLabel
                        duration
                    }
                    content {
                        _id
                        weight
                        liveStream
                        sort {
                          order
                        }
                        filters {
                            maxItems
                        }
                    }
                }
            }
        }
    ${METATAGS}
    ${IMAGE}
`;

export const GET_ALL_EVENTS = gql`
    query estadio_getAllEvents (
        $start_date: String
        $end_date: String
        $count_start: String
        $count_end: String
        $sort: EventSort
        $order: SortDirections
        $has_media: Boolean
        $season: Int
        $tenant: Tenants!
        $media_filter: String
        $filterId: [EventFilterType]
    ) ${persistDecorator} {
      getAllEvents(
        start_date: $start_date,
        end_date: $end_date,
        count_start: $count_start
        count_end: $count_end
        sort: $sort
        order: $order,
        season: $season,
        tenant: $tenant,
        has_media: $has_media,
        media_filter: $media_filter,
        filterId: $filterId
      ) {
        ...GetEvent
      }
    }
    ${EVENT}
`;

export const GET_EVENT_BY_ID = gql`
    query estadio_getEventById (
        $id: String!
        $tenant: Tenants!
        $isSportRadar: Boolean
    ) ${persistDecorator} {
        getEventById(
          id: $id,
          tenant: $tenant,
          isSportRadar: $isSportRadar
          ) {
        ...GetEvent
      }
    }
    ${EVENT}
`;

export const GET_VIDEO_BY_ID = gql`
    query estadio_getVideoById (
        $tenant: Tenant!
        $id: String
        $publishedOnly: Boolean
    )
     ${persistDecorator} {
        getVideoById(
            tenant: $tenant,
            id: $id,
            publishedOnly: $publishedOnly
        ) {
            _id
            mediaId
            title
            duration
            tagging {
              events {
                id
                home {
                  id
                  name
                }
                away {
                  id
                  name
                }
              }
            }
        }
    }
`;

export const GET_VIDEO_BY_EVENT_ID = gql`
    query estadio_getVideosByEventId (
        $tenant: Tenant!
        $eventId: String
    )
     ${persistDecorator} {
        getVideosByEventId(
            tenant: $tenant,
            eventId: $eventId
        ) {
            _id
            key @client
            subtitle @client
            image @client
            callToAction @client
            headline
            subHeadline
            thumbnail
            mediaId
            title
            created
            sortDate
            duration
        }
    }
`;

export const GET_SCHEDULE_FEED = gql`
    query estadio_getScheduleByFeeds (
        $feed: Feeds!
        $from: String
        $to: String
    ) ${persistDecorator} {
      getScheduleByFeeds(feed: $feed, from: $from, to: $to) {
        feeds {
          code
          link
          logo
          description
          shows {
            ...GetScheduleShow
          }
        }
      }
    }
    ${SCHEDULE_SHOW}
`;

export const GET_SERIES_BY_ID = gql`
    query estadio_getSeriesById (
        $id: String!
        $tenant: Tenants!
    ) ${persistDecorator} {
        getSeriesById(
          id: $id,
          tenant: $tenant
          ) {
          ...GetSeries
        }
    }
    ${SERIES}
`;

export const GET_COMPETITOR_BY_ID = gql`
    query estadio_getCompetitorById (
        $tenant: Tenants!
        $teamId: String!
    )
     ${persistDecorator} {
      getCompetitorById(
            tenant: $tenant,
            id: $teamId,
        ) {
         ...GetCompetitor
          }
    }
    ${COMPETITOR}
`;

export const GET_LATEST_CLIPS = gql`
    query estadio_getVideosByTeamId (
        $tenant: Tenant!
        $teamId: String!
    )
     ${persistDecorator} {
      getVideosByTeamId(
            tenant: $tenant,
            teamId: $teamId,
        ) {
            _id
            thumbnail
            mediaId
            title
            headline
            subHeadline
            duration
            cardLabel
            tagging {
              events {
                id
                sportradar_id
                series_name
                home {
                  id
                  name
                }
                away {
                  id
                  name
                }
              }
              competitors {
                id
                name
              }
            }
          }
      }
`;

export const GET_SERIES_LIST = gql`
    query estadio_getSeriesList (
        $tenant: Tenants!
        $season: String
        $tournament_id: String
        $sort: SeriesSort 
        $order: SortDirections
        $count_start: Int
        $count_end: Int
    ) ${persistDecorator} {
        getSeriesList(
          tenant: $tenant
          season: $season,
          sort: $sort,
          order: $order,
          tournament_id: $tournament_id,
          count_start: $count_start
          count_end: $count_end
          ) {
            series {
              ...GetSeriesWithEvents
            }
            total
            index_end
            index_start
        }
    }
    ${SERIES_WITH_EVENTS}
`;

export const GET_TEAMS = gql`
    query estadio_getTeams (
	        $uri: String!
          $tenant: Tenant!
        ) ${persistDecorator} {
            getPageByUri(uri: $uri, tenant: $tenant) {
                _id
                title
                created
                type
                headline
                metatags {
                   ...GetMetatags
                }
                components {
                    _id
                    id
                    type
                    format
                    headline
                    weight
                    image {
                      ...GetImage
                    }
                    images {
                      image {
                        ...GetImage
                      }
                      style
                    }
                    link {
                      uri
                      text
                    }
                    allItems @client {
                        id
                        _id
                        subtitle
                    }
                    content {
                        _id
                        sort {
                          order
                        }
                        teams {
                            id
                            name
                            logo_light
                            logo_dark
                            sportradar_id
                        }
                    }
                }
            }
        }
    ${METATAGS}
    ${IMAGE}
`;

export const GET_TEAMS_CACHED = gql`
    query estadio_getTeams (
	        $uri: String!
          $tenant: Tenant!
        ) ${persistDecorator} {
            getPageByUri(uri: $uri, tenant: $tenant) {
                _id
                title
                created
                type
                headline
                metatags {
                   ...GetMetatags
                }
                components {
                    _id
                    id
                    type
                    format
                    headline
                    weight
                    image {
                      ...GetImage
                    }
                    images {
                      image {
                        ...GetImage
                      }
                      style
                    }
                    link {
                      uri
                      text
                    }
                     allItems @client {
                        id
                        _id
                        key
                        type
                        subtitle
                        title
                        heading
                        subHeadline
                        match
                        scheduled_utc
                        status
                        is_live
                        image
                        has_media
                        callToAction
                        mediaId
                        sortDate
                        headline
                        productId
                        cta
                        price
                        disclaimer
                        isPayPalEnabled
                        cardLabel
                        duration
                        sportRadarId
                    }
                }
            }
        }
    ${METATAGS}
    ${IMAGE}
`;

export const GET_TICKER_EVENTS = gql`
    query estadio_getTickerEvents (
        $start_date: String
        $end_date: String
        $sort: EventSort
        $order: SortDirections
        $has_media: Boolean
        $type: EventTypes
        $tenant: Tenants!
    ) ${persistDecorator} {
      getAllEvents(
        start_date: $start_date,
        end_date: $end_date,
        sort: $sort
        order: $order,
        has_media: $has_media,
        type: $type
        tenant: $tenant
      ) {
        id
        key @client
        subtitle @client
        heading @client
        match @client
        status
        derived_status
        is_live
        scheduled_utc
        type
        title
        sportradar_id
        game {
          ...GetGame
        }
        home {
            id
            name
            team_abbr
        }
        away {
            id
            name
            team_abbr
        }
      }
    }
    ${GAME}
`;

export const GET_TOURNAMENTS = gql`
    query estadio_getTournaments (
	        $uri: String!
          $tenant: Tenant!
        ) ${persistDecorator} {
            getPageByUri(uri: $uri, tenant: $tenant) {
                _id
                title
                created
                type
                headline
                metatags {
                   ...GetMetatags
                }
                components {
                    _id
                    id
                    type
                    format
                    headline
                    weight
                    image {
                      ...GetImage
                    }
                    images {
                      image {
                        ...GetImage
                      }
                      style
                    }
                    link {
                      uri
                      text
                    }
                    content {
                        _id
                      series {
                        ...GetSeries
                      }
                    }
                }
            }
        }
    ${METATAGS}
    ${SERIES}
    ${IMAGE}
`;

export const GET_TOURNAMENTS_CACHED = gql`
    query estadio_getTournaments (
	        $uri: String!
          $tenant: Tenant!
        ) ${persistDecorator} {
            getPageByUri(uri: $uri, tenant: $tenant) {
                _id
                title
                created
                type
                headline
                metatags {
                   ...GetMetatags
                }
                components {
                    _id
                    id
                    type
                    format
                    headline
                    weight
                    image {
                      ...GetImage
                    }
                    images {
                      image {
                        ...GetImage
                      }
                      style
                    }
                    link {
                      uri
                      text
                    }
                    allItems @client {
                        id
                        _id
                        key
                        type
                        subtitle
                        title
                        heading
                        subHeadline
                        match
                        scheduled_utc
                        status
                        is_live
                        image
                        has_media
                        callToAction
                        mediaId
                        sortDate
                        headline
                        productId
                        cta
                        price
                        disclaimer
                        isPayPalEnabled
                        cardLabel
                        duration
                        sportRadarId
                    }         
                }
            }
        }
    ${METATAGS}
    ${IMAGE}
`;

export const GET_PROGRAMS = gql`
    query estadio_getPrograms (
	        $uri: String!
          $tenant: Tenant!
        ) ${persistDecorator} {
            getPageByUri(uri: $uri, tenant: $tenant) {
                _id
                title
                created
                type
                headline
                metatags {
                   ...GetMetatags
                }
                components {
                    _id
                    id
                    type
                    format
                    headline
                    weight
                    image {
                      ...GetImage
                    }
                    images {
                      image {
                        ...GetImage
                      }
                      style
                    }
                    link {
                      uri
                      text
                    }
                    content {
                        _id
                        show {
                          id
                          title
                          image {
                            ...GetImage
                          }
                        }
                    }
                }
            }
        }
    ${METATAGS}
    ${IMAGE}
`;

export const GET_PROGRAMS_CACHED = gql`
    query estadio_getPrograms (
	        $uri: String!
          $tenant: Tenant!
        ) ${persistDecorator} {
            getPageByUri(uri: $uri, tenant: $tenant) {
                _id
                title
                created
                type
                headline
                metatags {
                   ...GetMetatags
                }
                components {
                    _id
                    id
                    type
                    format
                    headline
                    weight
                    image {
                      ...GetImage
                    }
                    images {
                      image {
                        ...GetImage
                      }
                      style
                    }
                    link {
                      uri
                      text
                    }
                    allItems @client {
                        id
                        _id
                        key
                        type
                        subtitle
                        title
                        heading
                        subHeadline
                        match
                        scheduled_utc
                        status
                        is_live
                        image
                        has_media
                        callToAction
                        mediaId
                        sortDate
                        headline
                        productId
                        cta
                        price
                        disclaimer
                        isPayPalEnabled
                        cardLabel
                        duration
                        sportRadarId
                    }
                }
            }
        }
    ${METATAGS}
    ${IMAGE}
`;

export const GET_PROGRAM = gql`
    query estadio_getProgram (
	        $uri: String!
          $tenant: Tenant!
        ) ${persistDecorator} {
            getPageByUri(uri: $uri, tenant: $tenant) {
                _id
                title
                created
                type
                headline
                metatags {
                   ...GetMetatags
                }
                components {
                    _id
                    id
                    type
                    format
                    headline
                    weight
                    description
                    image {
                      ...GetImage
                    }
                    images {
                      image {
                        ...GetImage
                      }
                      style
                    }
                    link {
                      uri
                      text
                    }
                    content {
                       _id
                        sort {
                          order
                        }
                        filters {
                            maxItems
                        }
                       clips {
                        ...GetClip
                       }
                    }
                }
            }
        }
    ${METATAGS}
    ${CLIP}
    ${IMAGE}
`;

export const GET_PROGRAM_CACHED = gql`
    query estadio_getProgramCached (
	        $uri: String!
          $tenant: Tenant!
        ) ${persistDecorator} {
            getPageByUri(uri: $uri, tenant: $tenant) {
                _id
                title
                created
                type
                headline
                metatags {
                  type
                  key
                  content
                }
                components {
                    _id
                    headline
                    id
                    type
                    format
                    weight
                    description
                    image {
                      ...GetImage
                    }
                    images {
                      image {
                        ...GetImage
                      }
                      style
                    }
                    link {
                      uri
                      text
                    }
                    allItems @client {
                        id
                        _id
                        key
                        type
                        subtitle
                        title
                        heading
                        subHeadline
                        match
                        scheduled_utc
                        status
                        is_live
                        image
                        has_media
                        callToAction
                        mediaId
                        sortDate
                        headline
                        productId
                        cta
                        price
                        disclaimer
                        isPayPalEnabled
                        cardLabel
                        duration
                        sportRadarId
                    }
                    content {
                        _id
                        sort {
                          order
                        }
                        filters {
                            maxItems
                        }
                    }
                }
            }
        }
    ${IMAGE}
`;

export const GET_PRODUCTS = gql`
    query estadio_getProducts (
	        $uri: String!
          $tenant: Tenant!
        ) ${persistDecorator} {
            getPageByUri(uri: $uri, tenant: $tenant) {
                _id
                title
                created
                type
                headline
                metatags {
                   ...GetMetatags
                }
                components {
                    _id
                    id
                    type
                    format
                    headline
                    weight
                    description
                    image {
                      ...GetImage
                    }
                    images {
                      image {
                      ...GetImage
                      }
                      style
                    }
                    link {
                      uri
                      text
                    }
                    content {
                        _id
                      item {
                        ...GetItem
                      }
                      clips {
                        ...GetClip
                      }
                      product {
                        ...GetProduct
                      }
                    }
                }
            }
        }
    ${METATAGS}
    ${IMAGE}
    ${PRODUCT}
    ${CLIP}
    ${ITEM}
`;

export const GET_COMMON_PAGE_DATA = gql`
    query estadio_getCommonPageData (
	        $uri: String!
          $tenant: Tenant!
        ) ${persistDecorator} {
            getPageByUri(uri: $uri, tenant: $tenant) {
                _id
                title
                created
                type
                headline
                metatags {
                   ...GetMetatags
                }
                components {
                    _id
                    id
                    type
                    format
                    headline
                    subheadline
                    weight
                    description
                    link {
                      text
                      uri
                    }
                    images {
                      image {
                        ...GetImage
                      }
                      style
                    }
                    image {
                      ...GetImage
                    }
                    content {
                        _id
                        weight
                        liveStream
                        sort {
                          order
                        }
                        form {
                          element {
                            weight
                            type
                            headline
                            options
                          }
                       }
                    }
                }
            }
        }
    ${METATAGS}
    ${IMAGE}
`;
