import merge from 'lodash/merge';
import { StyleProp, StyleSheet, TextStyle, ViewStyle } from 'react-native';

interface Styles {
  button: ViewStyle;
  currentEnvStyle: ViewStyle;
  buttonLabel: TextStyle;
}

export const getStyles = (colors: ReactNativePaper.ThemeColors) => {
  const base: StyleProp<Styles> = {
    button: {
      marginVertical: 10,
      width: 100,
      height: 40,
      backgroundColor: colors.tenantBackground.dark.surface05.start,
      borderWidth: 0,
      borderColor: 'transparent',
    },
    currentEnvStyle: {
      borderWidth: 3,
      borderColor: colors.tenantBackground.light.surfaceBase,
    },
    buttonLabel: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Oswald-Medium',
      fontSize: 16,
      fontWeight: '500',
      textTransform: 'uppercase',
      alignSelf: 'center',
    },
  };

  const mergedStyles = merge(base);

  return StyleSheet.create(mergedStyles);
};
