import { ViewStyle, StyleSheet, Platform, StyleProp } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';
import { merge } from 'lodash';

interface Styles {
  slide: ViewStyle;
  content: ViewStyle;
  slideLayout: ViewStyle;
  featuredSlide: ViewStyle;
}

export const getStyles = (breakpoints: Breakpoints, isTv: boolean) => {
  const base: StyleProp<Styles> = {
    slide: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: 270,
      padding: 0,
      marginHorizontal: 4,
    },
    slideLayout: {
      flexDirection: 'column',
      marginHorizontal: 4,
      marginVertical: 16,
      borderRadius: 10,
    },
    content: {
      width: '100%',
      padding: 0,
      margin: 0,
      zIndex: 100,
      ...Platform.select({
        android: {
          elevation: 100,
        },
      }),
    },
    featuredSlide: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: 0,
      width: 158,
      marginHorizontal: 4,
    },
  };

  const mediaQueries = isTv
    ? ({
        slide: {
          width: 405,
          marginHorizontal: 8,
          marginBottom: 8,
        },
        slideLayout: {
          marginTop: 24,
          marginHorizontal: 0,
        },
        featuredSlide: {
          width: 264,
          marginHorizontal: 8,
          marginBottom: 8,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isMedLg ||
      breakpoints.currentBreakpoints.isLgXl ||
      breakpoints.windowWidth === breakpoints.breakpointSizes.lg
    ? ({
        slide: {
          width: 218,
        },
        featuredSlide: {
          width: 160,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isXlXxl || breakpoints.currentBreakpoints.isXxl
    ? ({
        slide: {
          width: 239,
        },
        featuredSlide: {
          width: 240,
        },
      } as StyleProp<Styles>)
    : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
