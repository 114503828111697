import React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';

/* eslint-disable-next-line */
export interface SignalProps {
  width?: number;
  height?: number;
  color?: string;
  iconColor?: string;
  detailColor?: string;
}

export function Signal(props: SignalProps) {
  const width = props.width || 20;
  const height = props.height || 20;
  const color = props.color || '#359fd7';
  const iconColor = props.iconColor || '#fff';
  const detailColor = props.detailColor || '#D2D2D2';

  return (
    <Svg fill="none" height={height} viewBox="0 0 20 20" width={width}>
      <Circle cx="10" cy="10" r="10" fill={color} />
      <Path
        fill={detailColor}
        d="M14.6 8.53c.077.076.182.123.297.123.232 0 .42-.188.42-.42 0-1.957-1.593-3.55-3.551-3.55-.232 0-.42.188-.42.42 0 .23.188.419.42.419 1.495 0 2.712 1.216 2.712 2.712 0 .115.047.22.123.296z"
      />
      <Path
        fill={detailColor}
        d="M13.016 8.567c.076.076.18.123.296.123.232 0 .42-.187.42-.419 0-1.117-.91-2.026-2.026-2.026-.232 0-.42.188-.42.42 0 .231.188.419.42.419.654 0 1.187.532 1.187 1.187 0 .116.047.22.123.296z"
      />
      <Path
        fill={iconColor}
        d="M10.493 14.837c1.119 0 2.188-.34 3.09-.986.182-.13.298-.334.316-.557.018-.224-.062-.443-.22-.601l-2.648-2.648.762-.761c.297-.298.297-.78 0-1.077-.298-.297-.78-.297-1.077 0l-.762.762L7.307 6.32c-.158-.158-.38-.238-.6-.22-.224.018-.428.134-.558.315-.645.903-.987 1.972-.987 3.091 0 1.424.555 2.763 1.562 3.769 1.006 1.007 2.345 1.561 3.769 1.561z"
      />
    </Svg>
  );
}

export default Signal;
