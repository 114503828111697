import React from 'react';
import { View, ViewStyle } from 'react-native';
import QRCodeElement from 'react-qr-code';

interface QrCodeProps {
  qrCodeString: string;
  qrContainer?: ViewStyle;
  qrContainerBorder?: ViewStyle;
  focusKey?: string;
  size: number;
  color?: string;
}

export const QrCode = ({ qrCodeString, qrContainer = {}, qrContainerBorder = {}, size, color = '' }: QrCodeProps) => (
  <View style={qrContainer}>
    <View style={qrContainerBorder}>
      <QRCodeElement value={qrCodeString} size={size} fgColor={color} />
    </View>
  </View>
);

export default QrCode;
