import { useEffect } from 'react';
import { Dimensions, useWindowDimensions } from 'react-native';
import { breakpointsStateStore } from '@warnermmedia/gsp-core/brands/estadio/data-access';

export function useBreakpoints() {
  const windowWidth = useWindowDimensions().width;
  const windowHeight = useWindowDimensions().height;

  const tny = 320;
  const sm = 427;
  const med = 767;
  const lg = 1023;
  const xl = 1439;
  const xxl = 1919;

  const breakpointSizes = { tny, sm, med, lg, xl, xxl };

  const isTiny = windowWidth <= tny;
  const isTnySm = windowWidth > tny && windowWidth < sm;
  const isSmMed = windowWidth > sm && windowWidth < med;
  const isMedLg = windowWidth > med && windowWidth < lg;
  const isLgXl = windowWidth > lg && windowWidth < xl;
  const isXlXxl = windowWidth > xl && windowWidth < xxl;
  const isXxl = windowWidth >= xxl;

  const isTvScreen = isXxl;
  const isWebScreen = isLgXl || isXlXxl;
  const isMediumScreen = isMedLg;
  const isMobileScreen = isTiny || isTnySm || isSmMed;
  const isLandScape = Dimensions.get('window').width > Dimensions.get('window').height;

  const currentBreakpoints = { isTiny, isTnySm, isSmMed, isMedLg, isLgXl, isXlXxl, isXxl };
  const breakpointHelpers = {
    isTvScreen,
    isWebScreen,
    isMediumScreen,
    isMobileScreen,
    isLandScape,
  };

  useEffect(() => {
    breakpointsStateStore({ breakpointSizes, currentBreakpoints, windowWidth, windowHeight, breakpointHelpers });
  });

  return null;
}
