import React from 'react';
import { Image, TouchableOpacity, View } from 'react-native';
import styles from './countryDropdown.styles';
import { Text } from 'react-native-paper';
import { SvgUri } from 'react-native-svg';
import { DownArrow } from '@warnermmedia/gsp-core/sdk/assets';

export interface CountryDropdownProps {
  toggleShowFlag: () => void;
  country: string;
  flagUrl: string;
  isMobileDevice: boolean;
}

export function CountryDropdown({ country, flagUrl, toggleShowFlag, isMobileDevice }: CountryDropdownProps) {
  return (
    <View style={styles.topbar}>
      <TouchableOpacity style={styles.countryDropdown} onPress={toggleShowFlag}>
        <Text style={styles.countryLabel}>{country}</Text>
        <View style={styles.countryFlag}>
          {isMobileDevice ? (
            <SvgUri uri={flagUrl} style={styles.flagImage} width={32} height={24} />
          ) : (
            <Image source={{ uri: flagUrl }} style={styles.flagImage} resizeMode="cover" />
          )}
          <View style={{ transform: [{ rotate: '90deg' }] }}>
            <DownArrow color="transparent" />
          </View>
        </View>
      </TouchableOpacity>
    </View>
  );
}

export default CountryDropdown;
