import { Platform } from 'react-native';
import SyncStorage from './rn-localstorage';
import { decodeBase64, encodeBase64 } from '../../../ui/src/utils/common';

SyncStorage.init();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function saveData(key: string, data: string | Record<string, any>): void {
  if (Platform.OS === 'web') {
    const dataToSave = typeof data === 'string' ? data : JSON.stringify(data);
    window.localStorage.setItem(key, encodeBase64(dataToSave));
  } else {
    SyncStorage.set(key, data);
  }
}

export function loadData(key: string) {
  if (Platform.OS === 'web') {
    const keyData = window ? window?.localStorage?.getItem(key) ?? '' : '';
    return keyData ? decodeBase64(keyData) : '';
  } else if (Platform.OS === 'ios' || Platform.OS === 'android') {
    return SyncStorage?.get(key) ?? '';
  } else {
    return '';
  }
}

export function clearData(key: string): void {
  if (Platform.OS === 'web') {
    window.localStorage.removeItem(key);
  } else {
    SyncStorage.remove(key);
  }
}

export function clearListData(keys: string[] = []): void {
  keys.forEach((key) => clearData(key));
}
