import merge from 'lodash/merge';
import { StyleProp, StyleSheet, ViewStyle, TextStyle, ImageStyle } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';

interface Styles {
  menu: ViewStyle;
  header: ViewStyle;
  logo: ViewStyle;
  navbar: ViewStyle;
  logoContain: ViewStyle;
  navTitle: TextStyle;
  navTitleContainer: ViewStyle;
  navButtonContainer: ViewStyle;
  navIcon: ViewStyle;
  mobileNavIconStyle: ImageStyle;
  imageContainer: ViewStyle;
}

export const getStyles = (breakpoints: Breakpoints, colors: ReactNativePaper.ThemeColors) => {
  const base: StyleProp<Styles> = {
    menu: {
      position: 'relative',
    },
    header: {
      backgroundColor: colors.tenantBackground.dark.surfaceBase,
      flexDirection: 'row',
      display: 'flex',
      alignItems: 'center',
    },
    logo: {
      alignItems: 'center',
      alignSelf: 'flex-start',
    },
    navbar: {
      flexDirection: 'row',
      backgroundColor: colors.tenantBackground.dark.surfaceBase,
      paddingHorizontal: 16,
      alignItems: 'flex-start',
      justifyContent: 'center',
      height: 60,
    },
    logoContain: {
      width: 140,
    },
    navTitle: {
      color: colors.keyboardFocus.light,
      textAlign: 'left',
      fontSize: 16,
      fontFamily: 'Oswald-Medium',
      textTransform: 'uppercase',
    },
    navTitleContainer: {
      alignSelf: 'center',
    },
    navButtonContainer: {
      alignSelf: 'center',
      paddingTop: 4,
    },
    navIcon: {
      flex: 1,
      alignSelf: 'center',
    },
    mobileNavIconStyle: {
      height: 20,
      width: 34,
    },
    imageContainer: {
      flex: 1,
      flexDirection: 'row',
    },
  };

  const mediaQueries =
    breakpoints.currentBreakpoints.isTiny ||
    breakpoints.currentBreakpoints.isTnySm ||
    breakpoints.currentBreakpoints.isSmMed
      ? ({} as StyleProp<Styles>)
      : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
