import { Platform } from 'react-native';

export enum GspDevice {
  MACOS = 'macos',
  WINDOWS = 'windows',
  IOS = 'ios',
  ANDROID = 'android',
  WEB = 'web',
  WEBOS = 'webos',
  TIZEN = 'tizen',
  ANDROID_TV = 'androidtv',
  FIRE_TV = 'firetv',
  UNKNOWN = 'unknown',
}

export interface DeviceTypes {
  device: string;
  isWeb: boolean;
  isTv: boolean;
  isWebOS4: boolean;
  isNotWeb: boolean;
  isPwa: boolean;
  isMobileDevice: boolean;
  isIosDevice: boolean;
  isAndroidDevice: boolean;
  isAndroidtv: boolean;
  isMobileDeviceBrowser: boolean;
  isIpadOldBrowser: boolean;
  isWebOS: boolean;
  isTizen: boolean;
  isIosDeviceBrowser: boolean;
}

export const useGetDevice = (): DeviceTypes => {
  let device = '';
  let isWebOS4 = false;
  let isMobileDeviceBrowser = false;
  let isIpadOldBrowser = false;
  let isWebOSTv = false;
  let isTizenTv = false;
  let isIosDeviceBrowser = false;

  const wrapperDevice = getPwaWrapperDeviceType();

  if (wrapperDevice !== null) {
    device = wrapperDevice;
  } else if (Platform.OS === 'web') {
    const deviceAgent = navigator.userAgent.toLowerCase();
    const isSmartTv = deviceAgent.match(/(smart-tv)/) || deviceAgent.match(/(smarttv)/);

    const tvAgent = deviceAgent.toLowerCase();
    const isWebOS = tvAgent.match(/(web0s)/) || tvAgent.match(/(webos)/);
    isWebOSTv = !!isWebOS;
    isWebOS4 = !!isWebOS && tvAgent.indexOf('chrome/53.0.2785.34') !== -1;
    const isTizen = tvAgent.match(/(tizen)/) || tvAgent.match(/(samsung)/);
    isTizenTv = !!isTizen;
    const mobileDeviceBrowser =
      deviceAgent.match(/(android)/) ||
      deviceAgent.match(/(iphone)/) ||
      deviceAgent.match(/(ipad)/) ||
      deviceAgent.match(/(ipod)/) ||
      deviceAgent.match(/(blackberry)/) ||
      deviceAgent.match(/(iemobile)/) ||
      deviceAgent.match(/(opera mini)/);

    if (deviceAgent.match(/(ipad)/)) {
      const chromeVersion = deviceAgent
        .substring(deviceAgent.indexOf('crios') + 6)
        .substring(0, 10)
        .trim();
      const safariVersion = deviceAgent
        .substring(deviceAgent.indexOf('safari') + 7)
        .substring(0, 7)
        .trim();
      isIpadOldBrowser = parseFloat(chromeVersion) < 103 || parseFloat(safariVersion) < 610;
    }

    const iosDeviceBrowser =
      deviceAgent.match(/(iphone)/) || deviceAgent.match(/(ipad)/) || deviceAgent.match(/(ipod)/);

    isMobileDeviceBrowser = !!mobileDeviceBrowser;
    isIosDeviceBrowser = !!iosDeviceBrowser;

    if (isSmartTv) {
      if (isWebOS) device = 'webos';
      if (isTizen) device = 'tizen';
      if (!isWebOS && !isTizen) device = 'web';
    } else {
      device = 'web';
    }
  } else {
    device = Platform.OS;
  }

  return {
    device,
    isWeb: device === 'web',
    isNotWeb: device !== 'web',
    isTv: device === 'webos' || device === 'tizen' || device === 'androidtv' || device === 'firetv',
    isWebOS4: isWebOS4,
    isPwa: Platform.OS === 'web',
    isMobileDevice: Platform.OS === 'ios' || Platform.OS === 'android',
    isIosDevice: Platform.OS === 'ios',
    isAndroidDevice: Platform.OS === 'android',
    isAndroidtv: device === 'androidtv' || device === 'firetv',
    isMobileDeviceBrowser: isMobileDeviceBrowser,
    isIpadOldBrowser: isIpadOldBrowser,
    isWebOS: isWebOSTv,
    isTizen: isTizenTv,
    isIosDeviceBrowser: isIosDeviceBrowser,
  };
};

function getPwaWrapperDeviceType(): string | null {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if ((window as any)['Android'] !== undefined) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const android: Android = (window as any).Android;
    if (android.getDeviceType().toLowerCase() === GspDevice.FIRE_TV) {
      return 'firetv';
    } else if (android.getDeviceType().toLowerCase() === GspDevice.ANDROID_TV) {
      return 'androidtv';
    }
  }
  return null;
}
