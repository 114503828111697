import { VideoErrorMessages } from '@warnermmedia/gsp-core/sdk/data-access';
import { languageStrings } from '@warnermmedia/gsp-core/brands/estadio/feature';

export const videoPlayerError = (errMessage: string): string => {
  let playerError: string;

  switch (errMessage) {
    case 'Token expired':
      playerError = languageStrings?.default?.tokenExpiredError;
      break;
    case VideoErrorMessages.GEOBLOCK_EVENT_ERROR:
      playerError = '';
      break;
    case 'Sorry, you do not have access to this content. Contact us for more information.':
      playerError = languageStrings?.default?.noAccessToContent;
      break;
    case VideoErrorMessages.CONCURRENCY_LIMIT_EVENT_ERROR:
      playerError = '';
      break;
    case VideoErrorMessages.AUTHORISATION_ERROR:
      playerError = languageStrings?.default?.authorisationError;
      break;
    default:
      playerError = languageStrings?.default?.tryAgainLater;
      break;
  }

  return playerError;
};
