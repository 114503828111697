import React, { useEffect, useState } from 'react';
import { Animated, Easing } from 'react-native';
import { useReactiveVar } from '@apollo/client';

import { errorMsgStore } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import styles from './errorOverlay.styles';
import { MessageDisplay, Variant } from '@warnermmedia/gsp-core/sdk/ui';
import { ErrorMsgState } from '@warnermmedia/gsp-core/sdk/data-access';

export const ErrorOverlay = () => {
  const [opacity, setOpacity] = useState(new Animated.Value(1));
  const [visible, setVisible] = useState(false);
  const [error, setError] = useState('We are having issues');
  let _hideMessageTimeoutId: ReturnType<typeof setTimeout>;
  const errorStore = useReactiveVar<ErrorMsgState>(errorMsgStore);

  useEffect(() => {
    if (errorStore.message) {
      setError(errorStore.message);
      newMessage();
    }

    return (): void => {
      clearTimeout(_hideMessageTimeoutId);
    };
  }, [errorStore]);

  const hideMessage = () => {
    clearTimeout(_hideMessageTimeoutId);
    Animated.timing(opacity, {
      toValue: 0,
      duration: 200,
      easing: Easing.in(Easing.ease),
      useNativeDriver: true,
    }).start(() => {
      setVisible(false);
      // clear Message
      errorMsgStore({
        ...errorMsgStore(),
        message: null,
      });
    });
  };

  const newMessage = () => {
    clearTimeout(_hideMessageTimeoutId);
    opacity.setValue(1);
    setVisible(true);
    _hideMessageTimeoutId = setTimeout(hideMessage, 5000);
  };

  return visible ? (
    <Animated.View style={[{ opacity }, styles.messageOverlay]}>
      <MessageDisplay variant={Variant.Error} displayMessage={error} />
    </Animated.View>
  ) : null;
};
