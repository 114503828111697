import React, { useCallback, useContext, useMemo } from 'react';
import { GestureResponderEvent, Pressable, View, ViewStyle } from 'react-native';
import { getStyles } from './directionalArrow.styles';
import { Arrow } from '@warnermmedia/gsp-core/brands/estadio/assets';
import { EstadioContext } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import LinearGradient from 'react-native-linear-gradient';
import { Breakpoints, DeviceTypes } from '@warnermmedia/gsp-core/sdk/ui';

export interface DirectionalArrowProps {
  indexUpdate?: (index: number) => void;
  index: number;
  isLeftPoint: boolean;
  cardHeight?: number;
  overrideStyle?: ViewStyle;
  gradStyle?: ViewStyle;
  itemWidth?: number;
  dataLength: number;
  slidesToMove?: number;
  breakpoints: Breakpoints;
  device: DeviceTypes;
  colors: ReactNativePaper.ThemeColors;
  includeGrad?: boolean;
}

export const DirectionalArrow = ({
  index,
  indexUpdate,
  isLeftPoint,
  cardHeight,
  overrideStyle,
  gradStyle,
  dataLength,
  breakpoints,
  device,
  colors,
  slidesToMove = 1,
  includeGrad = false,
}: DirectionalArrowProps) => {
  const { magicRemoteVisible } = useContext(EstadioContext);
  const styles = getStyles(breakpoints);
  const isMagicRemoteVisible = useMemo(() => device.isTv && magicRemoteVisible, [device.isTv, magicRemoteVisible]);

  const showArrows = useMemo(
    () =>
      !device.isMobileDevice &&
      ((device.isWeb && !device.isTv) || isMagicRemoteVisible) &&
      !(device.isMobileDeviceBrowser && breakpoints.breakpointHelpers.isMobileScreen),
    [
      breakpoints.breakpointHelpers.isMobileScreen,
      device.isMobileDevice,
      device.isMobileDeviceBrowser,
      device.isTv,
      device.isWeb,
      isMagicRemoteVisible,
    ]
  );
  const incrementIndex = useCallback(
    (e?: GestureResponderEvent) => {
      requestAnimationFrame(() => {
        e?.preventDefault();
        if (!indexUpdate) return;
        const prevIndex = index - slidesToMove < 0 ? 0 : index - slidesToMove;
        const nextIndex = index + slidesToMove >= dataLength ? dataLength - 1 : index + slidesToMove;
        const newIndex = isLeftPoint ? prevIndex : nextIndex;
        indexUpdate(newIndex);
      });
    },
    [index, dataLength, isLeftPoint, slidesToMove, indexUpdate]
  );

  const getDirectionalArrow = useCallback(() => {
    return (
      <View
        style={[
          isLeftPoint ? styles.backArrow : styles.forwardArrow,
          cardHeight ? { height: cardHeight } : {},
          overrideStyle,
        ]}
      >
        <LinearGradient
          start={{ x: 0, y: 0 }}
          end={{ x: 1, y: 0 }}
          colors={
            includeGrad && isLeftPoint
              ? [colors.tenantBackground.dark.surfaceBase, '#ffffff00']
              : includeGrad
              ? ['#ffffff00', colors.tenantBackground.dark.surfaceBase]
              : ['transparent', 'transparent']
          }
          style={gradStyle}
        >
          <Pressable style={styles.arrowInner} onPress={incrementIndex} nativeID="directional-arrow">
            <View style={styles.arrow}>
              <Arrow width={12} height={20} isLeftPoint={isLeftPoint} color={'none'} />
            </View>
          </Pressable>
        </LinearGradient>
      </View>
    );
  }, [
    cardHeight,
    incrementIndex,
    isLeftPoint,
    overrideStyle,
    styles.arrow,
    styles.arrowInner,
    styles.backArrow,
    styles.forwardArrow,
    gradStyle,
    colors.tenantBackground.dark.surfaceBase,
    includeGrad,
  ]);

  return showArrows ? getDirectionalArrow() : null;
};

export default DirectionalArrow;
