export { default as OswaldBold } from './Oswald-Bold.ttf';
export { default as OswaldExtraLight } from './Oswald-ExtraLight.ttf';
export { default as OswaldLight } from './Oswald-Light.ttf';
export { default as OswaldMedium } from './Oswald-Medium.ttf';
export { default as OswaldRegular } from './Oswald-Regular.ttf';
export { default as OswaldSemiBold } from './Oswald-SemiBold.ttf';
export { default as RubikBlack } from './Rubik-Black.ttf';
export { default as RubikBlackItalic } from './Rubik-BlackItalic.ttf';
export { default as RubikBold } from './Rubik-Bold.ttf';
export { default as RubikBoldItalic } from './Rubik-BoldItalic.ttf';
export { default as RubikExtraBold } from './Rubik-ExtraBold.ttf';
export { default as RubikExtraBoldItalic } from './Rubik-ExtraBoldItalic.ttf';
export { default as RubikItalic } from './Rubik-Italic.ttf';
export { default as RubikLight } from './Rubik-Light.ttf';
export { default as RubikLightItalic } from './Rubik-LightItalic.ttf';
export { default as RubikMedium } from './Rubik-Medium.ttf';
export { default as RubikMediumItalic } from './Rubik-MediumItalic.ttf';
export { default as RubikRegular } from './Rubik-Regular.ttf';
export { default as RubikSemiBold } from './Rubik-SemiBold.ttf';
export { default as RubikSemiBoldItalic } from './Rubik-SemiBoldItalic.ttf';
