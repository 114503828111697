import React from 'react';
import { View, ViewStyle } from 'react-native';
import { animated, useSpring } from 'react-spring';
import styles from './skeleton.styles';

const AnimatedView = animated(View);

export interface SkeletonProps {
  isLoading?: boolean;
  overrideContentStyle?: ViewStyle | ViewStyle[];
  overrideTitleStyle?: ViewStyle;
  overrideSubTitleStyle?: ViewStyle;
  hasTitle?: boolean;
  hasSubTitle?: boolean;
  duration?: number;
  hasBody?: boolean;
}

export const Skeleton = ({
  isLoading = true,
  overrideContentStyle,
  overrideTitleStyle,
  overrideSubTitleStyle,
  hasTitle = false,
  hasSubTitle = false,
  duration = 1000,
  hasBody = true,
}: SkeletonProps) => {
  const props = useSpring({
    loop: true,
    to: { opacity: 1 },
    from: { opacity: 0.2 },
    config: {
      duration,
    },
  });

  if (!isLoading) {
    return null;
  }
  return (
    <View style={styles.wrapper}>
      {hasTitle && (
        // this is an extension of View but ts says it can't have children
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <AnimatedView style={props}>
          <View style={[styles.title, overrideTitleStyle]} />
        </AnimatedView>
      )}
      {hasSubTitle && (
        // this is an extension of View but ts says it can't have children
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <AnimatedView style={props}>
          <View style={[styles.subTitle, overrideSubTitleStyle]} />
        </AnimatedView>
      )}
      {hasBody && (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <AnimatedView style={props}>
          <View style={[styles.content, overrideContentStyle]} />
        </AnimatedView>
      )}
    </View>
  );
};

export default Skeleton;
