import merge from 'lodash/merge';
import { StyleProp, StyleSheet, ViewStyle, TextStyle } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';

interface Styles {
  title: TextStyle;
  subtitle: TextStyle;
  btn: ViewStyle;
  newsLink: ViewStyle;
  underlinedText: TextStyle;
}

export const getStyles = (breakpoints: Breakpoints, colors: ReactNativePaper.ThemeColors) => {
  const base: StyleProp<Styles> = {
    title: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Oswald-Regular',
      fontSize: 22,
      fontWeight: '600',
      lineHeight: 33,
      fontStyle: 'normal',
    },
    subtitle: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Rubik-Regular',
      fontSize: 16,
      fontWeight: '400',
      lineHeight: 20,
      fontStyle: 'normal',
    },
    btn: {
      marginTop: 28,
    },
    newsLink: {
      alignSelf: 'flex-start',
      paddingHorizontal: 0,
      marginTop: 32,
    },
    underlinedText: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Oswald-Regular',
      fontSize: 14,
      fontWeight: '500',
      lineHeight: 21,
      fontStyle: 'normal',
      textDecorationLine: 'underline',
      textTransform: 'uppercase',
    },
  };
  const mediaQueries =
    breakpoints.currentBreakpoints.isTiny ||
    breakpoints.currentBreakpoints.isTnySm ||
    breakpoints.currentBreakpoints.isSmMed
      ? ({} as StyleProp<Styles>)
      : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
