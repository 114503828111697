import merge from 'lodash/merge';
import { StyleProp, StyleSheet, ViewStyle, TextStyle } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';

interface Styles {
  topContent: ViewStyle;
  ctaBtn: ViewStyle;
  input: ViewStyle;
  inputFilled: ViewStyle;
  toRow: ViewStyle;
  wrapText: ViewStyle;
  inputContainer: ViewStyle;
  title: TextStyle;
  instruction: TextStyle;
  textLabel: TextStyle;
  rule: TextStyle;
}

export const getStyles = (breakpoints: Breakpoints, colors: ReactNativePaper.ThemeColors) => {
  const base: StyleProp<Styles> = {
    input: {
      backgroundColor: colors.keyboardFocus.light,
      borderRadius: 5,
      borderWidth: 1,
      opacity: 0.8,
    },
    inputFilled: {
      backgroundColor: colors.keyboardFocus.light,
      borderRadius: 5,
      borderWidth: 1,
      opacity: 1,
    },
    topContent: {
      marginBottom: 20,
    },
    ctaBtn: {
      backgroundColor: colors.fill.action.accent01,
    },
    toRow: {
      flexDirection: 'row',
    },
    wrapText: {
      flex: 1,
      flexWrap: 'wrap',
    },
    inputContainer: {
      marginBottom: 15,
    },
    title: {
      fontSize: 22,
      textTransform: 'uppercase',
    },
    instruction: {
      fontSize: 16,
    },
    textLabel: {
      fontSize: 16,
      fontWeight: '500',
      color: colors.keyboardFocus.light,
    },
    rule: {
      color: colors.keyboardFocus.light,
      fontSize: 12,
      fontFamily: 'Rubik-Regular',
      lineHeight: 16,
      fontWeight: 'normal',
    },
  };

  const mediaQueries =
    breakpoints.currentBreakpoints.isTiny ||
    breakpoints.currentBreakpoints.isTnySm ||
    breakpoints.currentBreakpoints.isSmMed
      ? ({} as StyleProp<Styles>)
      : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
