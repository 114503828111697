import React from 'react';
import { Text, View } from 'react-native';

import { styles } from './PreLoadFonts.styles';

export const PreLoadFonts = () => (
  <View style={styles.webFixWrapper}>
    <Text style={[styles.label, styles.font1]}> </Text>
    <Text style={[styles.label, styles.font2]}> </Text>
    <Text style={[styles.label, styles.font3]}> </Text>
    <Text style={[styles.label, styles.font4]}> </Text>
    <Text style={[styles.label, styles.font5]}> </Text>
    <Text style={[styles.label, styles.font6]}> </Text>
    <Text style={[styles.label, styles.font7]}> </Text>
    <Text style={[styles.label, styles.font8]}> </Text>
  </View>
);
