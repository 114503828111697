import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { View } from 'react-native';
import { Data, MessageDisplay, useGetDevice, validateEmail, Variant } from '@warnermmedia/gsp-core/sdk/ui';
import {
  AuthState,
  CALLED_USER_EMAIL_VALIDATION,
  clearData,
  EMAIL_VERIFICATION_STATUS,
  loadData,
  MParticleAppConfigParams,
  MParticleCustomEventTypes,
  mParticleEventProcessor,
  saveData,
  setMparticleUserId,
  SubscriptionStatus,
  UserEmailResponsesEntity,
  UserProfileResponse,
} from '@warnermmedia/gsp-core/sdk/data-access';
import {
  callbackNavigateExternal,
  Dalton,
  getUserPrimaryEmail,
  languageStrings,
  useIsMountedRef,
  useMparticleCustomEventObject,
  useMparticleScreenEvent,
  usePinAndPairActions,
} from '@warnermmedia/gsp-core/brands/estadio/feature';
import { EstadioLoginForm, LOGIN_USER_ID } from '../../forms/login';
import { AfterLoginRouteType, QrCodeModal } from '@warnermmedia/gsp-core/brands/estadio/ui';
import { getStyles } from './loginform.styles';
import {
  AppConfigContext,
  breakpointsStateStore,
  isAppTransitioning,
  loginAPIStateStore,
  modalStateStore,
  PIN_CODE,
  USER_FAVORITE_TEAM,
} from '@warnermmedia/gsp-core/brands/estadio/data-access';

import { AxiosError } from 'axios';
import { useReactiveVar } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import { useTheme } from 'react-native-paper';

interface Props {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  handleNavigation: (route: string, state?: any) => void;
  verifiedEmailCode: string;
  confirmVerificationCodeMessage?: string;
  verificationMessage?: string;
  mParticleEventLoginOptions?: MParticleAppConfigParams;
}

const language = languageStrings.default;

export const LoginFormComponent = ({
  handleNavigation,
  verifiedEmailCode,
  mParticleEventLoginOptions = {},
  confirmVerificationCodeMessage = languageStrings.default.confirmVerificationCodeMessage,
  verificationMessage = languageStrings.default.verificationMessageTokenExpired,
}: Props) => {
  const { isTv } = useGetDevice();
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const { colors } = useTheme();
  const styles = getStyles(breakpoints, colors);
  const appConfig = useContext(AppConfigContext);
  const webVerificationLink = appConfig?.appConfig?.webUrl ?? '';
  const forgotPasswordLink = `${appConfig?.appConfig?.webUrl}/forgot-password`;
  const isRegisterEnabled = appConfig?.appConfig?.registerEnabled ?? true;
  const [error, setError] = useState('');
  const device = useRef(useGetDevice());
  const [forgotModalVisible, setForgotModalVisible] = useState(false);
  const [registerModalVisible, setRegisterModalVisible] = useState(false);
  const [afterLoginRoute, setAfterLoginRoute] = useState<AfterLoginRouteType>({} as AfterLoginRouteType);
  const dalton = Dalton();
  const isMountedRef = useIsMountedRef();
  const isTransitioning = useReactiveVar(isAppTransitioning);
  const validationCode = loadData(PIN_CODE);
  const mParticleEventData = useMparticleCustomEventObject(mParticleEventLoginOptions);
  const { claimCode } = usePinAndPairActions();
  useMparticleScreenEvent(
    mParticleEventLoginOptions?.section ?? '',
    mParticleEventLoginOptions?.contentTitle ?? '',
    mParticleEventLoginOptions?.contentId ?? ''
  );

  useEffect(() => {
    if (isMountedRef.current && afterLoginRoute.route) {
      handleNavigation(afterLoginRoute.route, afterLoginRoute.state);
    }
  }, [afterLoginRoute, isMountedRef, handleNavigation]);

  const handleForgotPasswordPress = useCallback(() => {
    const forgotPasswordUrl = validationCode ? `/forgot-password?validationCode=${validationCode}` : '/forgot-password';
    clearData(PIN_CODE);
    if (device.current.isWeb) {
      handleNavigation(forgotPasswordUrl);
    } else if (device.current.isMobileDevice) {
      callbackNavigateExternal(webVerificationLink ? `${webVerificationLink}${forgotPasswordUrl}` : '');
    } else {
      // show modal if is TV
      setForgotModalVisible(true);
    }
  }, [device, webVerificationLink, handleNavigation, setForgotModalVisible, validationCode]);

  const handleRegisterPress = useCallback(() => {
    if (device.current.isWeb) {
      handleNavigation('/register');
    } else if (device.current.device === 'android') {
      callbackNavigateExternal(webVerificationLink ? `${webVerificationLink}/register` : '');
    } else {
      // show modal if is TV
      setRegisterModalVisible(true);
    }
  }, [device, webVerificationLink, handleNavigation, setRegisterModalVisible]);

  const handleConfirmVerificationCode = useCallback(async (): Promise<void> => {
    saveData(CALLED_USER_EMAIL_VALIDATION, 'true');
    mParticleEventProcessor.pushMParticleEvent(MParticleCustomEventTypes.EmailValidationStartEvent, mParticleEventData);
    dalton.confirmVerificationCode(
      verifiedEmailCode,
      async () => {
        dalton.updateUserEmailStatus(EMAIL_VERIFICATION_STATUS.CONFIRMED);
        mParticleEventProcessor.pushMParticleEvent(
          MParticleCustomEventTypes.EmailValidationCompleteEvent,
          mParticleEventData
        );
      },
      (error) => {
        const errorMessage = error?.message ?? '';
        const resendRouteState = errorMessage.toLowerCase().includes('expired')
          ? { verificationMessage: verificationMessage }
          : {};
        setAfterLoginRoute({ route: '/resend-email', state: resendRouteState });
      }
    );
  }, [mParticleEventData, verifiedEmailCode, verificationMessage, dalton, setAfterLoginRoute]);

  const handleNewAccount = useCallback(
    async (verifiedEmailCode: string): Promise<void> => {
      const called = loadData(CALLED_USER_EMAIL_VALIDATION);
      if (isRegisterEnabled && verifiedEmailCode && !called) {
        await handleConfirmVerificationCode();
      }
    },
    [handleConfirmVerificationCode, isRegisterEnabled]
  );

  const handleLoginRedirection = useCallback(
    async (primaryEmail: UserEmailResponsesEntity | undefined): Promise<void> => {
      if (primaryEmail?.status === EMAIL_VERIFICATION_STATUS.NEW) {
        await handleNewAccount(verifiedEmailCode);
      }
    },
    [verifiedEmailCode, handleNewAccount]
  );

  const handleClaimCode = useCallback(
    async (userId: string, primaryEmail: UserEmailResponsesEntity | undefined): Promise<void> => {
      const hasSubscription = dalton.validateSubscription(loginAPIStateStore().preAuthorizedEntitlements);
      if (!isEmpty(validationCode) && hasSubscription && primaryEmail?.status === EMAIL_VERIFICATION_STATUS.CONFIRMED) {
        await claimCode(
          validationCode,
          userId,
          () => null,
          () => {
            setAfterLoginRoute({ route: '/logintv-success' });
          }
        );
      }
    },
    [claimCode, validationCode, setAfterLoginRoute]
  );

  const handleLoginError = useCallback(
    (response: AxiosError) => {
      switch (response.request.status) {
        case 401:
          setError(language.error401);
          break;
        case 420:
          setError(language.error420);
          break;
        default:
          setError(language.errorDefault);
          break;
      }
      mParticleEventProcessor.pushMParticleEvent(MParticleCustomEventTypes.LoginErrorEvent, {
        ...mParticleEventData,
        ...{ error_code: response.request.status },
      });
      isAppTransitioning(false);
    },
    [mParticleEventData]
  );

  const addUserToAppsFlyer = (userId: string) => {
    if (device.current.isWeb) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const AF = window.AF;
      AF && AF('pba', 'setCustomerUserId', userId);
    }
  };

  const handleLogin = useCallback(
    async (data: Data) => {
      setError('');
      isAppTransitioning(true);

      // clear remember me early due to component mount/unmount
      clearData(LOGIN_USER_ID);

      mParticleEventProcessor.pushMParticleEvent(MParticleCustomEventTypes.LoginStartEvent, mParticleEventData);
      await dalton.login(
        data.username.trim(),
        data.password,
        async (response: UserProfileResponse) => {
          const userPrimaryEmail = getUserPrimaryEmail(response);
          setMparticleUserId(response.user.tid, userPrimaryEmail);
          addUserToAppsFlyer(response.user.tid);

          if (userPrimaryEmail && data?.rememberMe) {
            const email = userPrimaryEmail?.emailAddress;
            saveData(LOGIN_USER_ID, email);
          }

          mParticleEventProcessor.pushMParticleEvent(MParticleCustomEventTypes.LoginCompleteEvent, {
            ...mParticleEventData,
            ...{
              auth_state: AuthState.Authenticated,
              customer_id: response.user.tid,
              subscription_status:
                loginAPIStateStore().preAuthorizedEntitlements?.length > 0
                  ? SubscriptionStatus.Active
                  : SubscriptionStatus.Inactive,
            },
            ...(loadData(USER_FAVORITE_TEAM) && { favorite_team: loadData(USER_FAVORITE_TEAM) }),
          });
          await handleClaimCode(response.user.tid, userPrimaryEmail);
          // TODO: check is this needs to be call
          await handleLoginRedirection(userPrimaryEmail);
          isAppTransitioning(false);
        },
        (error) => {
          handleLoginError(error);
        }
      );
    },
    [dalton, mParticleEventData, setError, handleLoginError, handleClaimCode, handleLoginRedirection]
  );

  const handleModalOkPress = () => {
    setForgotModalVisible(false);
    setRegisterModalVisible(false);
  };

  const handleLoginWithProvider = useCallback(() => {
    if (device.current.isWeb || device.current.isMobileDevice) {
      handleNavigation('/login-provider');
    }
  }, [device, handleNavigation]);

  return (
    <View style={styles.wrapper}>
      {isRegisterEnabled && !!verifiedEmailCode && (
        <MessageDisplay
          displayMessage={confirmVerificationCodeMessage}
          variant={Variant.Warning}
          wrapperStyle={styles.message}
        />
      )}
      <View style={[styles.loginFormLayout, device.current.isMobileDevice && { width: '100%' }]}>
        <EstadioLoginForm
          handleLogin={handleLogin}
          handleForgotPasswordPress={handleForgotPasswordPress}
          error={error}
          isLoading={isTransitioning}
          validateUsername={validateEmail}
          usernameLabel={language.emailText}
          passwordLabel={language.password}
          loginBtnText={isTransitioning ? language.loadingText : language.enterText}
          forgotPassBtnText={language.forgotPassword}
          emptyUsernameError={language.emptyUsernameError}
          emptyPasswordError={language.missingPassword}
          invalidPasswordError={language.missingPassword2}
          invalidUsernameError={language.invalidUsernameError}
          rememberMeText={language.rememberMeText}
          onRegisterPress={handleRegisterPress}
          registerText={isTv ? language.registerTextTV : language.registerText}
          handleLoginWithProvider={handleLoginWithProvider}
          loginWithProviderLabel={language.providerLoginText}
          showRegister={device.current.device !== 'ios' && isRegisterEnabled && isEmpty(validationCode)}
          isValidatingCode={isRegisterEnabled && !isEmpty(validationCode)}
        />
      </View>
      <QrCodeModal
        visible={forgotModalVisible}
        onChangeVisible={() => setForgotModalVisible(!forgotModalVisible)}
        title={language.forgotpasswordQRTitle}
        description={language.forgotModalDescription(forgotPasswordLink)}
        buttonLabel={language.forgotpasswordQRButtonLabel}
        qrCodeString={forgotPasswordLink}
        onOkPress={handleModalOkPress}
        device={device.current.device}
      />
      <QrCodeModal
        visible={registerModalVisible}
        onChangeVisible={() => setRegisterModalVisible(!registerModalVisible)}
        title={language.registerQRTitle}
        description={language.registerModalDescription(webVerificationLink)}
        buttonLabel={language.registerQRButtonLabel}
        qrCodeString={webVerificationLink}
        onOkPress={handleModalOkPress}
        device={device.current.device}
      />
    </View>
  );
};

export default LoginFormComponent;
