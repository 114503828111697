import { StyleProp, ViewStyle, StyleSheet, TextStyle } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';
import merge from 'lodash/merge';

interface Styles {
  left: ViewStyle;
  textStyle: TextStyle;
}

export const getStyles = (breakpoints: Breakpoints, isTv: boolean, colors: ReactNativePaper.ThemeColors) => {
  const base: StyleProp<Styles> = {
    left: {
      width: '100%',
      justifyContent: 'flex-start',
    },
    textStyle: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Oswald-SemiBold',
      fontSize: 22,
      fontWeight: '600',
      paddingTop: 30,
      marginLeft: 25,
      textTransform: 'uppercase',
    },
  };
  const mediaQueries = isTv
    ? ({
        textStyle: {
          fontSize: 33,
          paddingTop: 41,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isTiny || breakpoints.currentBreakpoints.isTnySm
    ? ({
        textStyle: {
          marginLeft: 40,
          paddingTop: 33,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isSmMed
    ? ({
        textStyle: {
          marginLeft: 40,
          paddingTop: 33,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isMedLg
    ? ({
        textStyle: {
          marginLeft: 40,
          paddingTop: 28,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isXlXxl || breakpoints.currentBreakpoints.isXxl
    ? ({
        textStyle: {
          fontSize: 33,
        },
      } as StyleProp<Styles>)
    : {};
  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
