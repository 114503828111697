import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  topContent: {
    marginBottom: 20,
  },
  label: {
    color: '#fff',
  },
  inputContainer: {
    marginBottom: 15,
  },
  instruction: {
    fontSize: 16,
    marginBottom: 18,
    color: '#fff',
    fontWeight: 'normal',
  },
});
