import React, { Suspense, useContext } from 'react';
import {
  AuthGuard,
  ErrorOverlay,
  LayoutComponent,
  RouteObj,
  SkeletonDisplay,
  Theme,
} from '@warnermmedia/gsp-core/brands/estadio/ui';
import { BrowserRouter, Redirect, Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom';
import {
  AppConfigContext,
  ESTADIO_NON_LAZY_ROUTES,
  HistoryContext,
  isUserEmailVerified,
  isUserLoggedIn,
  userHasSubscription,
} from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { useReactiveVar } from '@apollo/client';
import { useOneTrustTag } from '../hooks/useOneTrust';
import { useMparticle } from '../hooks/useMparticle';
import { useGetDevice } from '@warnermmedia/gsp-core/sdk/ui';

import GoBack from '../components/GoBack/GoBack';
import { useAppsFlyerTag } from '../hooks/useAppFlyer';
import { getRoutes, getUnknownRoute, languageStrings } from '@warnermmedia/gsp-core/brands/estadio/feature';

import Home from '../pages/Home';
import Register from '../pages/Register';
import Login from '../pages/Login';
import ResendVerificationEmail from '../pages/ResendVerificationEmail';
import Subscription from '../pages/Subscription';
import ProviderLogin from '../pages/ProviderLogin';
import TVPinLogin from '../pages/PinLogin';
import Profile from '../pages/Profile';
import CodeValidation from '../pages/CodeValidation';
import CodeValidationSuccess from '../pages/CodeValidationSuccess';
import Invoice from '../pages/Invoice';

const ForgotPassword = React.lazy(() => import('../pages/ForgotPassword'));
const ResetPassword = React.lazy(() => import('../pages/ResetPassword'));
const Help = React.lazy(() => import('../pages/Help'));
const Shows = React.lazy(() => import('../pages/Shows'));
const Teams = React.lazy(() => import('../pages/Teams'));
const Tournaments = React.lazy(() => import('../pages/Tournaments'));
const TVSignal = React.lazy(() => import('../pages/TVSignal/index'));
const TVSignalTV = React.lazy(() => import('../pages/TVSignal/TVSignalTV'));
const Video = React.lazy(() => import('../pages/Video/index'));
const VideoTV = React.lazy(() => import('../pages/Video/VideoTV'));
const AppInfo = React.lazy(() => import('../pages/AppInfo'));
const ShowDetails = React.lazy(() => import('../pages/ShowDetails'));
const PayPalSuccess = React.lazy(() => import('../pages/PayPalSubscriptionSuccess'));
const PayPalFailure = React.lazy(() => import('../pages/PayPalSubscriptionFailure'));
const Matches = React.lazy(() => import('../pages/Matches'));
const Billing = React.lazy(() => import('../pages/Billing'));
const RedeemCoupon = React.lazy(() => import('../pages/RedeemCoupon'));
const CancelSubscription = React.lazy(() => import('../pages/CancelSubscription'));
const TeamDetails = React.lazy(() => import('../pages/TeamDetails'));
const Tournament = React.lazy(() => import('../pages/Tournament'));
const GolfChannel = React.lazy(() => import('../pages/GolfChannel/index'));
const TVGolfChannel = React.lazy(() => import('../pages/GolfChannel/TVGolfChannel'));

const Routes: React.FC = () => {
  const isLoggedIn = useReactiveVar(isUserLoggedIn);
  const isEmailVerified = useReactiveVar(isUserEmailVerified);
  const hasSubscription = useReactiveVar(userHasSubscription);
  const appConfig = useContext(AppConfigContext);
  const isTveEnable = appConfig?.appConfig.tve.enabled;
  const isAppEnabled = appConfig?.appConfig.appEnabled ?? true;
  const { device, isWeb, isTv } = useGetDevice();

  // First page on web is different from others.
  // Login for all others
  const firstPage = isLoggedIn ? Home : isWeb ? Register : isTv ? TVPinLogin : Login;
  const TVSignalPage = isWeb ? TVSignal : TVSignalTV;
  const GolfChannelPage = isWeb ? GolfChannel : TVGolfChannel;
  const VideoPage = isWeb ? Video : VideoTV;
  useOneTrustTag();
  useMparticle();
  useAppsFlyerTag();

  // TODO: standardize route paths into enum
  const routes: RouteObj[] = [
    {
      component: firstPage,
      route: '/',
      isProtected: isLoggedIn,
    },
    {
      component: Home,
      route: '/home',
      isProtected: true,
    },
    {
      component: Profile,
      route: '/profile',
      isProtected: true,
    },
    {
      component: Shows,
      route: '/shows',
      isProtected: true,
    },
    {
      component: ShowDetails,
      route: '/show/:id',
      isProtected: true,
    },
    {
      component: Teams,
      route: '/teams',
      isProtected: true,
    },
    {
      component: Tournaments,
      route: '/tournaments',
      isProtected: true,
    },
    {
      component: TVSignalPage,
      route: '/tvsignal',
      isProtected: true,
    },
    {
      component: GolfChannelPage,
      route: '/golfchannel',
      isProtected: true,
    },
    {
      component: VideoPage,
      route: '/video/:type/:id',
      isProtected: true,
    },
    {
      component: Matches,
      route: '/match/:id',
      isProtected: true,
    },
    {
      component: Register,
      route: '/register',
      isProtected: false,
    },
    {
      component: isTv ? TVPinLogin : Login,
      route: '/login',
      isProtected: false,
    },
    {
      component: ForgotPassword,
      route: '/forgot-password',
      isProtected: false,
    },
    {
      component: ResetPassword,
      route: '/reset-password',
      isProtected: false,
    },
    {
      component: AppInfo,
      route: '/appinfo',
      isProtected: true,
    },
    {
      component: Billing,
      route: '/profile/billing',
      isProtected: true,
    },
    {
      component: ResendVerificationEmail,
      route: '/resend-email',
      isProtected: true,
    },
    {
      component: Subscription,
      route: '/subscribe',
      isProtected: true,
    },
    {
      component: RedeemCoupon,
      route: '/redeemcoupon',
      isProtected: true,
    },
    {
      component: PayPalSuccess,
      route: '/subscriptions/paypal/success',
      isProtected: true,
    },
    {
      component: PayPalFailure,
      route: '/subscriptions/paypal/failure',
      isProtected: true,
    },
    {
      component: CancelSubscription,
      route: '/cancel-subscription',
      isProtected: true,
    },
    {
      component: TeamDetails,
      route: '/team/:teamId',
      isProtected: true,
    },
    {
      component: Tournament,
      route: '/tournaments/:season/:id',
      isProtected: true,
    },
    {
      component: Invoice,
      route: '/consulta-boleta',
      isProtected: false,
    },

    {
      component: CodeValidation,
      route: '/logintv',
      isProtected: false,
    },
    {
      component: CodeValidationSuccess,
      route: '/logintv-success',
      isProtected: true,
    },
    ...(isTveEnable
      ? [
          {
            component: ProviderLogin,
            route: '/login-provider',
            isProtected: false,
          },
        ]
      : []),
    ...(isTv
      ? [
          {
            component: TVPinLogin,
            route: '/pinlogin',
            isProtected: false,
          },
        ]
      : []),
  ];

  const SuspenseWrapper = ({ route = '', children }: { route?: string; children: React.ReactElement }) => (
    <Suspense fallback={<SkeletonDisplay route={route} />}>{children}</Suspense>
  );

  const unknownRouteRedirect = () => (
    <Redirect to={getUnknownRoute(isAppEnabled, isLoggedIn, isEmailVerified, hasSubscription)} />
  );

  const helpComponent = () => (
    <SuspenseWrapper>
      <Help />
    </SuspenseWrapper>
  );

  return (
    <Theme>
      <BrowserRouter>
        <HistoryContext.Provider
          value={{
            useHistory: useHistory,
            useLocation: useLocation,
            useParams: useParams,
            ready: true,
          }}
        >
          <LayoutComponent pageTitle={languageStrings.default.homePage}>
            <ErrorOverlay />
            <GoBack device={device} />
            <Switch>
              {getRoutes(routes, isAppEnabled).map(({ component, isProtected, route }) => {
                const Component = component;
                const SuspenseComponent = () => (
                  <SuspenseWrapper route={route}>
                    <Component />
                  </SuspenseWrapper>
                );
                const authGuard = () => {
                  return (
                    <AuthGuard isProtected={isProtected} route={route} isAppEnabled={isAppEnabled}>
                      {ESTADIO_NON_LAZY_ROUTES.includes(route) ? <Component /> : <SuspenseComponent />}
                    </AuthGuard>
                  );
                };
                return <Route exact path={route} key={route} render={authGuard} />;
              })}
              {isTv && isAppEnabled && <Route exact path={'/help'} render={helpComponent} />}
              <Route exact path="*" render={unknownRouteRedirect} />
            </Switch>
          </LayoutComponent>
        </HistoryContext.Provider>
      </BrowserRouter>
    </Theme>
  );
};

export default Routes;
