import React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';

/* eslint-disable-next-line */
export interface SignOutProps {
  width?: number;
  height?: number;
  color?: string;
  iconColor?: string;
}

export function SignOut(props: SignOutProps) {
  const width = props.width || 20;
  const height = props.height || 20;
  const color = props.color || '#359fd7';
  const iconColor = props.iconColor || '#fff';

  return (
    <Svg fill="none" height={height} viewBox="0 0 20 20" width={width}>
      <Circle cx="10" cy="10" r="10" fill={color} />
      <Path
        fill={iconColor}
        d="M10 9.833c.226 0 .421-.082.586-.247.165-.165.248-.36.248-.586V4.833c0-.225-.083-.42-.248-.585C10.421 4.082 10.226 4 10 4c-.225 0-.42.083-.586.248-.165.164-.247.36-.247.585V9c0 .226.082.421.247.586.165.165.36.247.586.247z"
      />
      <Path
        fill={iconColor}
        d="M14.476 7.6c-.35-.698-.84-1.284-1.474-1.757-.183-.14-.389-.194-.619-.163-.23.03-.412.139-.547.325-.139.183-.192.388-.16.615.033.228.14.412.323.55.425.322.754.715.986 1.18.232.463.348.958.348 1.483 0 .452-.088.883-.263 1.293-.176.41-.414.765-.713 1.064-.3.3-.654.537-1.065.713-.41.176-.84.264-1.292.264-.451 0-.882-.088-1.292-.264-.41-.176-.765-.413-1.065-.713-.3-.3-.537-.654-.713-1.064-.175-.41-.263-.841-.263-1.293 0-.525.116-1.02.348-1.484.232-.464.561-.857.986-1.178.183-.14.29-.323.323-.55.032-.228-.02-.433-.16-.616-.134-.186-.316-.295-.543-.325-.228-.03-.436.024-.622.163-.634.473-1.125 1.059-1.475 1.757C5.174 8.3 5 9.044 5 9.833c0 .678.132 1.324.397 1.94.265.617.62 1.149 1.068 1.596.447.447.979.803 1.595 1.067.616.265 1.263.398 1.94.398s1.324-.133 1.94-.398c.617-.264 1.148-.62 1.595-1.067.447-.447.803-.98 1.068-1.595.265-.617.397-1.263.397-1.94 0-.79-.174-1.535-.524-2.234z"
      />
    </Svg>
  );
}

export default SignOut;
