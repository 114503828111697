import React, { useCallback, useContext } from 'react';
import { useGetDevice } from '@warnermmedia/gsp-core/sdk/ui';
import { useReactiveVar } from '@apollo/client';
import { breakpointsStateStore } from '@warnermmedia/gsp-core/brands/estadio/data-access';

interface ScrollToTopAction {
  scrollToTop: () => void;
}

export const webScrollToTop = (isMobileDeviceBrowser = false, isMobileScreen = false) => {
  // scroll({}) causes error on older browsers (chrome 53)
  try {
    if (isMobileDeviceBrowser && isMobileScreen) {
      // Work around to make the scroll work on mobile web
      setTimeout(() => {
        window.scroll({ top: 0, behavior: 'smooth' });
      }, 500);
    } else {
      window.scroll({ top: 0, behavior: 'smooth' });
    }
  } catch (error) {
    window.scroll(0, 0);
  }
};

export const useScrollToTop = (ScrollViewRefContext: React.Context<null>): ScrollToTopAction => {
  const scrollViewRef = useContext(ScrollViewRefContext);
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const { isWeb, isMobileDeviceBrowser } = useGetDevice();

  const scrollToTop = useCallback(() => {
    if (isWeb) {
      webScrollToTop(isMobileDeviceBrowser, breakpoints.breakpointHelpers.isMobileScreen);
    } else {
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      /* @ts-ignore: Using scrollTo to move page up */
      scrollViewRef?.current && scrollViewRef.current.scrollTo({ y: 0 });
    }
  }, [scrollViewRef]);

  return {
    scrollToTop,
  };
};

export default useScrollToTop;
