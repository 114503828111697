import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Dimensions, GestureResponderEvent, ScrollView, View } from 'react-native';
import { getStyles } from './sidebar.styles';
import LinearGradient from 'react-native-linear-gradient';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  Competition,
  CreditCard,
  Home,
  Question,
  Signal,
  SignOut,
  Team,
  Tv,
  User,
} from '@warnermmedia/gsp-core/brands/estadio/assets';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Breakpoints, DeviceTypes, Navbar, NavItem, useGetEnvironment } from '@warnermmedia/gsp-core/sdk/ui';

import { IconSource } from 'react-native-paper/lib/typescript/components/Icon';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  EstadioContext,
  HistoryContext,
  isNavOpen,
  LAST_ROUTE,
  userHasSubscription,
} from '@warnermmedia/gsp-core/brands/estadio/data-access';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { callbackNavigateExternal, isInRoute, languageStrings } from '@warnermmedia/gsp-core/brands/estadio/feature';
import BuildConfig from 'react-native-build-config';
import { useReactiveVar } from '@apollo/client';
import { IconButton } from 'react-native-paper';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  EMAIL_VERIFICATION_STATUS,
  FocusKeys,
  loadData,
  ROUTE_FOCUS_KEY,
  ROUTE_LAST_FOCUSED_KEY,
  saveData,
  USER_EMAIL_STATUS,
} from '@warnermmedia/gsp-core/sdk/data-access';
import { SkeletonNavBar } from '../skeletonDisplay';
import { useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import SidebarLogo from '../sidebarLogo/sidebarLogo';
import get from 'lodash/get';

interface RouteLink {
  label: string;
  link: string;
  icon: React.ElementType;
  detailColor?: string;
  handlePress?: (e?: Event | GestureResponderEvent) => void;
}

interface SidebarProps {
  opened: boolean;
  isLogged: boolean;
  expanded: boolean;
  navMobile: boolean;
  activeRouteName: string;
  closeNavIsTv: () => void;
  handleToggleNav: () => void;
  setExpanded: (expanded: boolean) => void;
  goToHome: () => void;
  handleLogout: (e?: Event | GestureResponderEvent) => void;
  devices: DeviceTypes;
  breakpoints: Breakpoints;
  colors: ReactNativePaper.ThemeColors;
  fonts: ReactNativePaper.ThemeFonts;
}

const helpLink = 'https://estadiocl.zendesk.com/hc/es';

export const Sidebar = ({
  opened,
  colors,
  fonts,
  devices,
  expanded,
  goToHome,
  isLogged,
  navMobile,
  breakpoints,
  setExpanded,
  handleLogout,
  closeNavIsTv,
  handleToggleNav,
  activeRouteName,
}: SidebarProps) => {
  const screenHeight = Dimensions.get('window').height;
  const scrollViewRef = useRef();
  const historyContext = useContext(HistoryContext);
  const history = historyContext?.ready ? historyContext?.useHistory() : null;
  const { isTv, isPwa, isMobileDevice, isIosDevice, isAndroidDevice, isWebOS } = devices;
  const [hoveredControl, setHoveredControl] = useState(false);
  const [routeLinks, setRouteLinks] = useState<RouteLink[]>([]);
  const isFocused = isTv && isLogged;
  const isOpen = isFocused && (expanded || hoveredControl) ? true : false;
  isNavOpen(isOpen);
  const { setFocus, getCurrentFocusKey } = useFocusable();
  const hasSubscription = useReactiveVar(userHasSubscription);
  const isUserEmailVerified = loadData(USER_EMAIL_STATUS) === EMAIL_VERIFICATION_STATUS.CONFIRMED;

  const isVideoPage = isInRoute('/video/', activeRouteName);

  const styles = getStyles(navMobile, isPwa, isVideoPage);

  const currentWindowWidth = breakpoints.windowWidth;
  const navIconColor = colors.tenantBackground.dark.surface04;
  const { isDevelopment } = useGetEnvironment();
  const { magicRemoteVisible } = useContext(EstadioContext);

  const iconSettingsDefault = useMemo(
    () => ({
      height: 50,
      width: 50,
      iconColor: navIconColor,
    }),
    [navIconColor]
  );

  const iconSettingsSelected = useMemo(
    () => ({
      height: 50,
      width: 50,
      color: colors.fill.action.accent01,
      iconColor: colors.tenantBackground.light.surfaceBase,
    }),
    [colors.fill.action.accent01, colors.tenantBackground.light.surfaceBase]
  );

  const navItemProps = useMemo(
    () => ({
      expanded: expanded,
      style: styles.viewStyle,
      labelStyle: styles.labelStyle,
      iconSize: 50,
    }),
    [expanded, styles.labelStyle, styles.viewStyle]
  );

  const scrollSidebarToTop = useCallback(() => {
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    /* @ts-ignore: Using scrollTo to reset scrollview */
    scrollViewRef?.current?.scrollTo({ y: 0, animated: true });
  }, []);

  useEffect(() => {
    if (!hoveredControl) {
      scrollSidebarToTop();
    }
  }, [hoveredControl, expanded, scrollSidebarToTop]);

  const authLinks: RouteLink[] = useMemo(() => {
    return [
      {
        label: languageStrings.default.navHome,
        link: '/home',
        icon: Home,
        detailColor: 'none',
      },
      {
        label: languageStrings.default.navSignalTvPage,
        link: '/tvsignal',
        icon: Signal,
        detailColor: navIconColor,
      },
      {
        label: languageStrings.default.navTeamsPage,
        link: '/teams',
        icon: Team,
      },
      {
        label: languageStrings.default.navTournamentsPage,
        link: '/tournaments',
        icon: Competition,
        detailColor: colors.fill.action.accent02,
      },
      {
        label: languageStrings.default.navShowsPage,
        link: '/shows',
        icon: Tv,
      },
      {
        label: languageStrings.default.navProfilePage,
        link: '/profile',
        icon: User,
      },
      {
        label: languageStrings.default.navHelp,
        link: '/help',
        icon: Question,
      },
      {
        label: languageStrings.default.navLogout,
        link: '/signout',
        icon: SignOut,
        handlePress: (e?: Event | GestureResponderEvent) => {
          handleLogout(e);
          setHoveredControl(false);
        },
      },
      ...(isDevelopment || (isAndroidDevice && BuildConfig.BUILD_TYPE !== 'release')
        ? [{ label: 'App Info', link: '/appinfo', icon: CreditCard }]
        : []),
    ];
  }, [handleLogout, colors.fill.action.accent02, navIconColor, isDevelopment, isAndroidDevice]);

  const unSubLinks: RouteLink[] = useMemo(() => {
    return [
      {
        // Changing the route name for Ios
        label: isIosDevice ? languageStrings.default.navSubscription : languageStrings.default.navSubscribe,
        link: '/subscribe',
        icon: CreditCard,
        detailColor: 'none',
      },
      {
        label: languageStrings.default.navProfilePage,
        link: '/profile',
        icon: User,
      },
      {
        label: languageStrings.default.navHelp,
        link: '/help',
        icon: Question,
      },
      {
        label: languageStrings.default.navLogout,
        link: '/signout',
        icon: SignOut,
        handlePress: (e) => {
          handleLogout(e);
          setHoveredControl(false);
        },
      },
    ];
  }, [handleLogout, isIosDevice]);

  useEffect(() => {
    if (isLogged && hasSubscription) {
      setRouteLinks(authLinks);
    }
    if (isLogged && !hasSubscription && hasSubscription !== null && isUserEmailVerified) {
      setRouteLinks(unSubLinks);
    }
  }, [isLogged, hasSubscription, authLinks, unSubLinks, isUserEmailVerified]);

  const handleEnterNavbar = () => {
    setHoveredControl(true);
  };

  const handleLeaveNavbar = useCallback(() => {
    setHoveredControl(false);
  }, []);

  const handleOutgoing = useCallback((): void => {
    !navMobile && setExpanded(false);
    handleLeaveNavbar();
    scrollSidebarToTop();
  }, [handleLeaveNavbar, navMobile, scrollSidebarToTop, setExpanded]);

  const handleIncoming = useCallback(
    (shouldScroll = true): void => {
      setExpanded(true);

      shouldScroll && scrollSidebarToTop();
    },
    [scrollSidebarToTop, setExpanded]
  );

  // TODO: Not having this wrapped in useCallback is causing renderNavBar to
  // rerender.  However if useCallback is used then the sidebar does not appear
  // when focused.  Need to dig into why.
  const isActivePage = (activeRouteName: string, link: string): boolean => {
    const isLoginPage = activeRouteName === '/' && link === '/login';
    const isHomePage = activeRouteName === '/' && link === '/home' && isLogged;
    const isTeamPage = activeRouteName.startsWith('/team/') && link === '/teams';
    const isShowPage = activeRouteName.startsWith('/show/') && link === '/shows';
    const profileRoutes = ['/cancel-subscription', '/redeemcoupon'];
    const isProfilePage =
      profileRoutes.some((profileRoute) => activeRouteName.startsWith(profileRoute)) && link === '/profile';

    return activeRouteName.startsWith(link) || isLoginPage || isHomePage || isTeamPage || isShowPage || isProfilePage;
  };

  const scrollToFocusKey = useCallback(
    (activeFocusKey: string) => {
      const focusKeyIndex = routeLinks.findIndex((key) => activeFocusKey === key.label);
      const middle = Math.floor((routeLinks.length - 1) / 2);
      if (activeFocusKey === FocusKeys.NAVLOGO || focusKeyIndex <= middle) {
        scrollSidebarToTop();
      }
    },
    [routeLinks, scrollSidebarToTop]
  );

  const onNavFocus = useCallback(
    (activeFocusKey: string) => {
      handleIncoming(false);
      scrollToFocusKey(activeFocusKey);
    },
    [handleIncoming, scrollToFocusKey]
  );

  const getPreferredChildFocusKey = useCallback(() => {
    const activeFocusKey = routeLinks.find((link) => isActivePage(activeRouteName, link.link))?.label ?? '';
    return activeFocusKey ? activeFocusKey : isTv ? get(routeLinks, '[0].label', '') : FocusKeys.NAVLOGO;
  }, [routeLinks, activeRouteName, isActivePage, isTv]);

  const rightDirectionPress = useCallback(() => {
    handleOutgoing();
    const routeSavedKey = loadData(ROUTE_LAST_FOCUSED_KEY);
    const focusKey = get(routeSavedKey ? JSON.parse(routeSavedKey) : {}, activeRouteName, loadData(ROUTE_FOCUS_KEY));
    focusKey && setFocus(focusKey);
  }, [handleOutgoing, setFocus, activeRouteName]);

  useEffect(() => {
    if (getCurrentFocusKey() === FocusKeys.SIDEBAR) {
      setFocus(getPreferredChildFocusKey());
    }
  }, [getCurrentFocusKey, setFocus, getPreferredChildFocusKey]);

  useEffect(() => {
    if (!magicRemoteVisible && isWebOS && hoveredControl) {
      handleLeaveNavbar();
    }
  }, [magicRemoteVisible, handleLeaveNavbar, isWebOS, hoveredControl]);

  const handleNavigation = useCallback(
    (routeName: string, e?: Event | GestureResponderEvent) => {
      e?.preventDefault();
      if (routeName === '/help' && !isTv) {
        callbackNavigateExternal(helpLink);
      } else {
        if (navMobile) handleToggleNav();
        history?.push(routeName);
      }

      if (!isPwa) {
        saveData(LAST_ROUTE, routeName);
      }
      isTv && closeNavIsTv();
    },
    [isTv, history, navMobile, isPwa, handleToggleNav, closeNavIsTv]
  );

  const renderNavBar = useCallback(() => {
    const shouldExpand = hoveredControl || expanded;

    const expandedWidth =
      shouldExpand && currentWindowWidth <= breakpoints.breakpointSizes.lg
        ? styles.navWebFullWidth
        : shouldExpand && breakpoints.currentBreakpoints.isLgXl
        ? styles.navWebMediumWidth
        : {};

    return (opened && navMobile) || !navMobile ? (
      <LinearGradient
        colors={[colors.tenantBackground.dark.surface05.start, colors.tenantBackground.dark.surface05.end]}
        style={[
          styles.navContain,
          expandedWidth,
          isPwa && { overflow: 'visible' },
          isMobileDevice && { maxWidth: 375 },
        ]}
      >
        <ScrollView
          style={{ height: screenHeight }}
          showsVerticalScrollIndicator={false}
          /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
          /* @ts-ignore: Using refs. */
          ref={scrollViewRef}
          onMouseLeave={handleLeaveNavbar}
          onMouseEnter={handleEnterNavbar}
          bounces={false}
        >
          <Navbar type="vertical" active={activeRouteName} opened={opened} backgroundColor="transparent">
            <View style={shouldExpand ? styles.expandedHeader : styles.collapsedHeader}>
              <View style={styles.navHeader}>
                <View style={styles.navHeaderLeft}>
                  <SidebarLogo
                    goToHome={goToHome}
                    devices={devices}
                    shouldExpand={shouldExpand}
                    rightDirectionOnPress={handleOutgoing}
                    onNavFocus={onNavFocus}
                    downFocusKey={get(routeLinks, '[0].label', '')}
                  />
                </View>
                {navMobile && (
                  <View style={styles.navHeaderRight}>
                    <IconButton
                      color={colors.tenantBackground.light.surfaceBase}
                      icon="close"
                      size={25}
                      onPress={handleToggleNav}
                    />
                  </View>
                )}
              </View>
            </View>
            <View style={styles.navBarScroll}>
              {routeLinks.map((routeLink, index) => {
                const LinkIcon = routeLink.icon;
                const isActive = isActivePage(activeRouteName, routeLink.link);
                const iconData = isActive ? iconSettingsSelected : iconSettingsDefault;
                return (
                  <NavItem
                    key={routeLink.link}
                    navigateTo={routeLink.link}
                    label={routeLink.label}
                    accessibilityLabel={routeLink.label}
                    isFirstChild={index === 0}
                    isLastChild={index === routeLinks?.length - 1}
                    rightDirectionOnPress={rightDirectionPress}
                    colors={colors}
                    fonts={fonts}
                    devices={devices}
                    icon={() =>
                      (<LinkIcon detailColor={!isActive && routeLink?.detailColor} {...iconData} />) as IconSource
                    }
                    onPress={(e) => {
                      isPwa && rightDirectionPress();
                      routeLink.handlePress ? routeLink.handlePress(e) : handleNavigation(routeLink.link, e);
                    }}
                    {...navItemProps}
                    expanded={shouldExpand}
                    onNavFocus={onNavFocus}
                  />
                );
              })}
            </View>
          </Navbar>
        </ScrollView>
      </LinearGradient>
    ) : null;
  }, [
    hoveredControl,
    expanded,
    opened,
    navMobile,
    currentWindowWidth,
    breakpoints.breakpointSizes.lg,
    breakpoints.currentBreakpoints.isLgXl,
    styles.navWebFullWidth,
    styles.navWebMediumWidth,
    styles.navContain,
    styles.expandedHeader,
    styles.collapsedHeader,
    styles.navHeader,
    styles.navHeaderLeft,
    styles.navHeaderRight,
    styles.navBarScroll,
    colors,
    isPwa,
    isMobileDevice,
    screenHeight,
    handleLeaveNavbar,
    activeRouteName,
    goToHome,
    devices,
    handleOutgoing,
    onNavFocus,
    routeLinks,
    handleToggleNav,
    isActivePage,
    iconSettingsSelected,
    iconSettingsDefault,
    rightDirectionPress,
    fonts,
    navItemProps,
    handleNavigation,
  ]);

  if (isVideoPage && !navMobile) {
    return null;
  }
  if (routeLinks.length === 0 && !navMobile) {
    return <SkeletonNavBar />;
  } else if (opened && !navMobile) {
    return <View style={styles.navContain}>{renderNavBar()}</View>;
  } else {
    return renderNavBar();
  }
};

export default Sidebar;
