import { useEffect, useState } from 'react';
import {
  ContentEntryOptionalData,
  MediaJSONPlayData,
  Player,
  PlayerConfigBuilder,
  PlayerMediaStateChangedResult,
} from '@top/player-block-web';
import { PlayerState, videoStates } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import {
  MParticleCustomEventTypes,
  mParticleEventProcessor,
  playerMediaStateChangedEvents,
  recordVideoMparticleError,
} from '@warnermmedia/gsp-core/sdk/data-access';
import {
  HeartBeatParams,
  useHeartBeat,
  useIsMountedRef,
  useMparticleCustomEventObject,
  videoPlayerError,
} from '@warnermmedia/gsp-core/brands/estadio/feature';
import { TOP } from './TopPlayerSingleton';
import { ReactiveVar } from '@apollo/client';
import { getDiff } from '@warnermmedia/gsp-core/sdk/ui';

export const useVideoPlayer = (playerStateStore: ReactiveVar<PlayerState>, playerStartTime: string) => {
  const [player] = useState(new Player());
  const [videoError, setVideoError] = useState('');
  const [chromeClicked, setchromeClicked] = useState<boolean>(false);
  const [contentState, setContentState] = useState<PlayerMediaStateChangedResult>();
  const [refreshToken, setRefreshToken] = useState<boolean>(false);
  const [heartBeatParams, setHeartbeatParams] = useState<HeartBeatParams>({
    mediaId: '',
    appId: '',
    tokenUrl: '',
    heartbeatToken: '',
    assetId: '',
  });
  const mParticleEventData = useMparticleCustomEventObject();

  const { mediaId, appId, assetId, tokenUrl, heartbeatToken } = heartBeatParams;

  const { heartBeatError, setPoll, setHeartBeatError } = useHeartBeat({
    mediaId,
    heartbeatToken,
    appId,
    assetId,
    tokenUrl,
  });
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [mediaPaused, setMediaPause] = useState(false);
  const isMountedRef = useIsMountedRef();

  useEffect(() => {
    if (heartBeatError) {
      const messageDisplay = videoPlayerError(heartBeatError);
      playerStateStore({
        type: videoStates.CONTENT_ERROR,
        message: messageDisplay,
      });

      setVideoError(heartBeatError);

      setPoll(false);
      setHeartBeatError('');
      player.stop();
    }
  }, [heartBeatError]);

  useEffect(() => {
    if (isMountedRef.current) {
      TOP.topPlayer = player;
      player.events.playerReady.listen(() => {
        playerStateStore({
          type: videoStates.PLAYER_READY,
          message: '',
        });
      });
      player.events.mediaError.listen((evt) => {
        playerStateStore({
          type: videoStates.MEDIA_ERROR,
          message: '',
        });
      });
      player.events.playerError.listen((evt) => {
        const errorMsg = evt?.error?.toJSON()?.metadata?.subErrorMessage;
        if (errorMsg === 'Token expired') {
          setRefreshToken(true);
        } else {
          playerStateStore({
            type: videoStates.CONTENT_ERROR,
            message: errorMsg,
          });
        }
      });

      player.events.messageFromUI.listen((evt) => {
        if (evt.name === 'controlBarVisibilityChanged') {
          const videoStateControlType = evt.payload.visible
            ? videoStates.CONTROLS_VISIBLE
            : videoStates.CONTROLS_HIDDEN;
          playerStateStore({
            type: videoStateControlType,
            message: '',
          });
        }
      });

      player.events.contentError.listen((evt) => {
        const errorMsg = evt?.error?.toJSON()?.metadata?.subErrorMessage;
        const errorDisplay = evt?.error?.toJSON()?.message;
        if (errorMsg === 'Token expired') {
          setRefreshToken(true);
        } else if (errorDisplay === 'Connection with content lost, playback stopped and reset') {
          setRefreshToken(true);
        } else {
          playerStateStore({
            type: videoStates.CONTENT_ERROR,
            message: errorMsg,
          });

          setVideoError(errorMsg);
        }
      });

      player.events.contentEnded.listen((evt) => {
        setPoll(false);
        setIsPlaying(false);
        playerStateStore({
          type: videoStates.CONTENT_ENDED,
          message: '',
        });
      });

      player.events.mediaPaused.listen((evt) => {
        setMediaPause(true);
        setIsPlaying(false);
        playerStateStore({
          type: videoStates.MEDIA_PAUSED,
          message: '',
        });
      });

      player.events.mediaResumed.listen((evt) => {
        setMediaPause(false);
        setIsPlaying(true);
        playerStateStore({
          type: videoStates.MEDIA_RESUMED,
          message: '',
        });
      });

      player.events.tokenResponseReceived.listen(function (result) {
        let assetId = '';
        if (player.playlistItem?.entry.files && player.playlistItem?.entry.files.length > 0) {
          player.playlistItem?.entry.files.forEach((file) => {
            if (file.contentProtection === 'widevine') {
              assetId = file.assetId ?? '';
            }
          });
        }
        setHeartbeatParams({
          ...heartBeatParams,
          mediaId: player.playlistItem?.entry.id ?? '',
          tokenUrl: result.requestUrl ?? '',
          assetId,
          appId: player.config.metadata.appId ?? '',
          heartbeatToken: result?.turnerToken?.token ?? '',
        });
        setPoll(!!result?.turnerToken?.token);
        setIsPlaying(true);
      });

      player?.events.messageFromUI.listen((result) => {
        if (result?.name === 'chromeCastRequested') {
          setchromeClicked((chromeClicked) => !chromeClicked);
        }
      });
      /**
       * Determine if the video is in fullscreen mode or windowed mode
       */
      player?.events.viewModeChanged.listen((result) => {
        const res = result && result?.currentValue.toLowerCase() === 'fullscreen';
        setIsFullScreen(res);
      });

      player?.events.mediaStateChanged.listen((result) => {
        playerMediaStateChangedEvents(result, mParticleEventData, playerStartTime);
        setContentState(result);
        playerStateStore({
          type: videoStates.MEDIA_STATES,
          message: '',
        });
      });
    }
  }, [isMountedRef]);

  useEffect(() => {
    recordVideoMparticleError(videoError, mParticleEventData);
  }, [videoError, mParticleEventData]);

  // Re-enable this if you want to view TOP video logs for debugging
  //Log.setTheme(LogTheme.Dark);
  //Log.setEnabled(true);

  useEffect(() => {
    return (): void => {
      setPoll(false);
      setIsPlaying(false);
      TOP.topPlayer.events.unlistenAll();
      player.events.unlistenAll();
      player.stop();
      player.destroy();
      TOP.topPlayer.stop();
      TOP.topPlayer.destroy();
    };
  }, []);

  const playerConfigSetup = (config: PlayerConfigBuilder) => {
    player.setup(config);
    mParticleEventProcessor.pushMParticleEvent(MParticleCustomEventTypes.PlayerLoadEvent, {
      ...mParticleEventData,
      ...{ player_timer: getDiff(playerStartTime) },
    });
  };

  const playByUrl = (videoStreamLink: string, entryData: ContentEntryOptionalData | undefined) => {
    player.play(videoStreamLink, entryData);
  };

  const playByMediaJson = (playData: MediaJSONPlayData, entryData: ContentEntryOptionalData | undefined) => {
    player.playByMediaJson(playData, entryData);
  };

  return {
    playerConfigSetup,
    playByUrl,
    videoError,
    setVideoError,
    playByMediaJson,
    chromeClicked,
    setchromeClicked,
    contentState,
    refreshToken,
    setRefreshToken,
    isFullScreen,
    setIsFullScreen,
    isPlaying,
    mediaPaused,
    player,
  };
};
