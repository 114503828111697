import React, { useEffect } from 'react';
import { View } from 'react-native';
import {
  CMSPageBuilder,
  LayoutType,
  LayoutWrapper,
  SkeletonDetailPage,
  TitleType,
} from '@warnermmedia/gsp-core/brands/estadio/ui';
import { useReactiveVar } from '@apollo/client';
import {
  PagesUrl,
  QueryNames,
  estadioComponent,
  useCMSPageData,
  useCMSQueryDataResults,
  useRouter,
} from '@warnermmedia/gsp-core/brands/estadio/feature';
import { breakpointsStateStore, Maybe, Page } from '@warnermmedia/gsp-core/brands/estadio/data-access';

import { getStyles } from './showDetails.styles';
import { useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { CAROUSEL_KEY, FocusKeys, ROUTE_FOCUS_KEY, saveData } from '@warnermmedia/gsp-core/sdk/data-access';

type ShowDetailsParams = { id: string };

export const ShowDetails = () => {
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const styles = getStyles(breakpoints);
  const { firstCarouselIndex } = useCMSPageData();

  const params = useRouter().useParams<ShowDetailsParams>();
  const { pageData: pageUriData, loading } = useCMSQueryDataResults<Page>({
    queryName: QueryNames.GET_PROGRAM,
    uri: PagesUrl.Shows,
    showId: params.id,
  });
  const { setFocus } = useFocusable();
  const showHeadingsObj = pageUriData?.components?.find((item) => item && item?.type === 'screen');

  // We want to focus the first carousel that has clips
  const firstCarousel = firstCarouselIndex(pageUriData?.components as Maybe<estadioComponent>[]);

  useEffect(() => {
    let key: string = FocusKeys.TICKER;
    if (!loading && pageUriData) {
      key = CAROUSEL_KEY(firstCarousel);
    }
    setFocus(key);
    saveData(ROUTE_FOCUS_KEY, key);
  }, [pageUriData, loading, setFocus, firstCarousel]);

  return (
    <View>
      {loading ? (
        <SkeletonDetailPage windowHeightStartTop={200} />
      ) : (
        <LayoutWrapper
          layoutType={LayoutType.Full}
          titleType={TitleType.Left}
          pageTitle={showHeadingsObj?.headline || ''}
          subTitle={showHeadingsObj?.description || ''}
          overrideChildrenWrapper={styles.layoutWrapperStyle}
          overrideTitleStyle={styles.title}
          overrideSubTitleStyle={styles.subtitle}
        >
          <CMSPageBuilder data={pageUriData} />
        </LayoutWrapper>
      )}
    </View>
  );
};

export default ShowDetails;
