import React from 'react';
import { Redirect } from 'react-router';
import { useReactiveVar } from '@apollo/client';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  useFirstLoadingStatus,
  useSetCampaignId,
  useValidateEmailCode,
} from '@warnermmedia/gsp-core/brands/estadio/feature';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  isAppTransitioning,
  isUserEmailVerified,
  isUserLoggedIn,
  userHasSubscription,
} from '@warnermmedia/gsp-core/brands/estadio/data-access';
import SkeletonDisplay from '../skeletonDisplay/skeletonDisplay';

export interface RouteObj {
  component: React.ElementType;
  route: string;
  isProtected?: boolean;
}

interface AuthGuardProps {
  children: React.ReactElement;
  isProtected?: boolean;
  route: string;
  isAppEnabled: boolean;
}

export const AuthGuard: React.FC<AuthGuardProps> = ({ children, isProtected, route, isAppEnabled }) => {
  useSetCampaignId();
  const isLoggedIn = useReactiveVar(isUserLoggedIn);
  const isEmailVerified = useReactiveVar(isUserEmailVerified);
  const hasSub = useReactiveVar(userHasSubscription);
  const { verifiedRoute } = useValidateEmailCode();
  const isAppLoading = useFirstLoadingStatus();
  const isTransitioning = useReactiveVar(isAppTransitioning);
  const subscriptionRoutes = ['/subscribe', '/subscriptions/paypal/success', '/subscriptions/paypal/failure'];
  const isSubscriptionRoute = (route: string) => subscriptionRoutes.includes(route);
  const isSubscriptionSkipRoute = (route: string) =>
    !['/profile', '/resend-email', '/login-provider', '/consulta-boleta', '/profile/billing', '/redeemcoupon'].includes(
      route
    );
  const isResendEmailSkipRoute = (route: string) =>
    !['/resend-email', '/login-provider', '/consulta-boleta'].includes(route);
  const isAllowedTransitionHomeRoute = (route: string) =>
    [...subscriptionRoutes, ...['/login', '/login-provider', '/logintv-success']].includes(route);
  const isHomeSkipRoute = (route: string) => !['/logintv-success'].includes(route);
  const isAppDisabledRoute = (route: string) => ['/register', '/consulta-boleta'].includes(route);

  if (isAppLoading) {
    return <SkeletonDisplay route={route} />;
  }

  if (!isAppEnabled && !isAppDisabledRoute(route) && !isAppLoading) {
    return <Redirect to="/register" />;
  }

  if (
    isLoggedIn &&
    !hasSub &&
    isEmailVerified &&
    !verifiedRoute?.pathname &&
    !isSubscriptionRoute(route) &&
    isSubscriptionSkipRoute(route) &&
    !isTransitioning
  ) {
    return <Redirect to="/subscribe" />;
  }

  if (isLoggedIn && !isEmailVerified && !verifiedRoute?.pathname && isResendEmailSkipRoute(route) && !isTransitioning) {
    return <Redirect to="/resend-email" />;
  }

  if (
    isLoggedIn &&
    hasSub &&
    isEmailVerified &&
    isAllowedTransitionHomeRoute(route) &&
    isHomeSkipRoute(route) &&
    !isTransitioning
  ) {
    return <Redirect to="/home" />;
  }

  if (isLoggedIn && verifiedRoute?.pathname && !isTransitioning) {
    return <Redirect to={verifiedRoute} />;
  }

  if (!isLoggedIn && isProtected && !isTransitioning) {
    return <Redirect to="/login" />;
  }

  return children;
};
