import { StyleProp, StyleSheet, TextStyle, ViewStyle } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';
import merge from 'lodash/merge';

interface Styles {
  container: ViewStyle;
  title: TextStyle;
  titleHeader: TextStyle;
  headerBar: ViewStyle;
  tableContainer: ViewStyle;
  billingText: TextStyle;
  pageControlItem: TextStyle;
  rowWrapper: ViewStyle;
  activePageControlItem: TextStyle;
  rowLabel: TextStyle;
  rowData: TextStyle;
  groupWrapper: ViewStyle;
  footerContainer: ViewStyle;
  backBtn: ViewStyle;
  controlWrapper: ViewStyle;
  headerWrapper: ViewStyle;
  dataContainer: ViewStyle;
  goBackButton: TextStyle;
}

export const getStyles = (breakpoints: Breakpoints, hasPageControl: boolean, colors: ReactNativePaper.ThemeColors) => {
  const base: StyleProp<Styles> = {
    container: {},
    backBtn: {
      marginTop: 40,
    },
    controlWrapper: {
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: 16,
    },
    title: {
      color: colors.keyboardFocus.light,
      fontSize: 33,
      fontWeight: '600',
      fontFamily: 'Oswald-SemiBold',
      textTransform: 'uppercase',
      marginLeft: 8,
      textAlign: 'left',
    },
    titleHeader: {
      color: colors.keyboardFocus.light,
      fontSize: 22,
      fontWeight: '600',
      fontFamily: 'Oswald-SemiBold',
      textTransform: 'uppercase',
    },
    headerWrapper: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'space-evenly',
      flexGrow: 1,
    },

    headerBar: {
      height: 2,
      backgroundColor: colors.tenantBackground.light.surfaceBase,
      opacity: 0.2,
      width: '100%',
      marginHorizontal: 4,
      marginVertical: 10,
      borderRadius: 4,
    },
    tableContainer: {
      paddingVertical: 15,
      paddingHorizontal: 10,
      flexGrow: 1,
    },
    billingText: {
      color: colors.keyboardFocus.light,
      fontSize: 20,
      marginTop: 10,
      fontFamily: 'Rubik-Regular',
      fontWeight: 'normal',
    },
    dataContainer: {
      flexGrow: 1,
    },
    rowWrapper: {
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      flexGrow: 1,
      marginTop: 10,
    },
    pageControlItem: {
      fontFamily: 'Oswald-SemiBold',
      fontWeight: '600',
      fontSize: 20,
      textAlign: 'center',
      width: 30,
      height: 30,
      lineHeight: 30,
      borderRadius: 30,
    },
    activePageControlItem: {
      fontFamily: 'Oswald-SemiBold',
      fontWeight: '600',
      fontSize: 20,
      backgroundColor: colors.fill.action.accent01,
      borderRadius: 30,
      width: 30,
      height: 30,
      lineHeight: 30,
      textAlign: 'center',
    },
    rowLabel: {
      color: colors.keyboardFocus.light,
      fontSize: 16,
      fontFamily: 'Oswald-SemiBold',
      fontWeight: '600',
      textTransform: 'uppercase',
      width: '50%',
    },
    rowData: {
      color: colors.keyboardFocus.light,
      fontSize: 16,
      fontWeight: 'normal',
      fontFamily: 'Rubik-Regular',
      width: '50%',
    },
    groupWrapper: {
      borderTopColor: 'rgba(255, 255, 255, 0.4)',
      borderTopWidth: 2,
      paddingTop: 6,
      paddingBottom: 16,
    },
    footerContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: hasPageControl ? 'space-between' : 'flex-end',
    },
    goBackButton: {
      fontFamily: 'Oswald-Regular',
      minWidth: 175,
      textTransform: 'uppercase',
    },
  };

  const mediaQueries =
    breakpoints.currentBreakpoints.isTiny ||
    breakpoints.currentBreakpoints.isTnySm ||
    breakpoints.currentBreakpoints.isSmMed
      ? ({
          title: {
            maxWidth: '60%',
          },
          rowWrapper: {
            flexDirection: 'row',
            justifyContent: 'flex-start',
            flexGrow: 1,
          },
          footerContainer: {
            flexDirection: 'column',
            alignItems: 'center',
          },
          backBtn: {
            width: '100%',
          },
          pageControlItem: {
            fontSize: 16,
            width: 25,
            lineHeight: 25,
          },
          activePageControlItem: {
            fontSize: 16,
            width: 25,
            lineHeight: 25,
            borderRadius: 12.5,
          },
        } as StyleProp<Styles>)
      : breakpoints.currentBreakpoints.isMedLg
      ? ({} as StyleProp<Styles>)
      : breakpoints.currentBreakpoints.isLgXl ||
        breakpoints.currentBreakpoints.isXlXxl ||
        breakpoints.currentBreakpoints.isXxl
      ? ({} as StyleProp<Styles>)
      : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
