import React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';

/* eslint-disable-next-line */
export interface FieldProps {
  width?: number;
  height?: number;
  color?: string;
  iconColor?: string;
}

export function Field(props: FieldProps) {
  const width = props.width || 20;
  const height = props.height || 20;
  const color = props.color || '#359fd7';
  const iconColor = props.iconColor || '#fff';

  return (
    <Svg fill="none" height={height} viewBox="0 0 20 20" width={width}>
      <Circle cx="10" cy="10" r="10" fill={color} />
      <Path
        fill={iconColor}
        d="M15 13.188V6.311C15 6.14 14.86 6 14.687 6H5.313C5.14 6 5 6.14 5 6.313v6.875c0 .172.14.312.313.312h9.375c.172 0 .312-.14.312-.313z"
      />
      <Path fill={color} d="M10.313 13.5V6h-.626v7.5h.626z" />
      <Path
        fill={color}
        d="M8.125 9.75c0-1.034.841-1.875 1.875-1.875s1.875.841 1.875 1.875-.841 1.875-1.875 1.875-1.875-.841-1.875-1.875zm3.125 0c0-.69-.56-1.25-1.25-1.25s-1.25.56-1.25 1.25S9.31 11 10 11s1.25-.56 1.25-1.25zM13.125 10.688V8.812c0-.172.14-.312.313-.312H15v.625h-1.25v1.25H15V11h-1.563c-.172 0-.312-.14-.312-.313zM5 11v-.625h1.25v-1.25H5V8.5h1.563c.172 0 .312.14.312.313v1.874c0 .173-.14.313-.313.313H5z"
      />
    </Svg>
  );
}

export default Field;
