import { CMSAPIModels, HistoryContext } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { useContext, useEffect, useState } from 'react';
import {
  getEventMediaId,
  getFormattedStatus,
  QueryNames,
  QueryVariablesType,
  useCMSQueryDataResults,
  useIsMountedRef,
  useMparticleCustomEventObject,
  useVideoTag,
  UseVideoTagParams,
} from '../../..';
import { PlayerMediaConfig } from '@warnermedia/gsp-core/sdk/video';
import { getDiff, now } from '@warnermmedia/gsp-core/sdk/ui';
import { MParticleCustomEventTypes, mParticleEventProcessor } from '@warnermmedia/gsp-core/sdk/data-access';

type VideoParams = { id: string; type: string };

export const PLAYER_START_BITRATE = 400;
export const PLAYER_MAX_BITRATE = 4000;

export const useVideoData = (): {
  videoData: UseVideoTagParams;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tags: Record<string, any>;
  withMediaConfig: PlayerMediaConfig;
  playerStartTime: string;
} => {
  const historyContext = useContext(HistoryContext);
  const params = historyContext?.ready ? historyContext?.useParams<VideoParams>() : null;
  const [eventData, setEventData] = useState<CMSAPIModels.Event | CMSAPIModels.Video>();
  const [queryVars, setQueryVars] = useState<QueryVariablesType>({} as QueryVariablesType);
  const [videoData, setVideoData] = useState<UseVideoTagParams>({} as UseVideoTagParams);
  const [playerStartTime] = useState<string>(now().format());
  const mParticleEventData = useMparticleCustomEventObject();
  const isMountedRef = useIsMountedRef();

  useEffect(() => {
    if (isMountedRef.current) {
      switch (params?.type) {
        case 'match':
          setQueryVars({
            queryName: QueryNames.GET_EVENT_BY_ID,
            id: `sr:match:${params?.id}` ?? '',
            isSportRadar: true,
          });
          break;
        case 'manual':
          setQueryVars({
            queryName: QueryNames.GET_EVENT_BY_ID,
            id: params?.id ?? '',
            isSportRadar: false,
          });
          break;
        case 'media':
          setQueryVars({
            queryName: QueryNames.GET_VIDEO_BY_ID,
            id: params?.id ?? '',
          });
          break;
      }
    }
  }, [params, isMountedRef]);

  useEffect(() => {
    if (eventData && isMountedRef.current) {
      const defaultType = (eventData.__typename ?? '').toLocaleLowerCase();

      switch (eventData.__typename) {
        case 'Event': {
          const status = getFormattedStatus(eventData?.status ?? '');
          const eventMedia = getEventMediaId(
            status === 3,
            eventData?.has_media ?? false,
            eventData?.media_asset_id_vod ?? '',
            eventData?.media_asset_id_live ?? ''
          );
          setVideoData({
            awayTeam: eventData?.away?.name ?? '',
            homeTeam: eventData?.home?.name ?? '',
            scheduled_date: eventData?.scheduled_utc ?? '',
            type: eventData?.type ?? defaultType,
            mediaId: eventMedia.mediaId,
            isLive: eventMedia.live,
            title: eventData?.title ?? '',
            season: eventData.season ?? '',
            image: eventData.event_image ?? '',
            tournament: eventData?.tournament?.display_name ?? '',
          });
          break;
        }
        case 'Video': {
          setVideoData({
            awayTeam: eventData?.tagging?.events?.[0]?.away?.name ?? '',
            homeTeam: eventData?.tagging?.events?.[0]?.home?.name ?? '',
            scheduled_date: eventData?.tagging?.events?.[0]?.scheduled_utc ?? '',
            type: eventData?.tagging?.events?.[0]?.type ?? defaultType,
            mediaId: eventData?.mediaId ?? '',
            isLive: false,
            title: eventData?.title ?? '',
            duration: eventData.duration ?? '',
            image: eventData.thumbnail ?? '',
          });
          break;
        }
      }
    }
  }, [eventData, isMountedRef]);

  const { pageData: videoResult } = useCMSQueryDataResults<CMSAPIModels.Event | CMSAPIModels.Video>(queryVars);

  useEffect(() => {
    if (isMountedRef.current) {
      setEventData(videoResult);
    }
  }, [videoResult, isMountedRef]);

  useEffect(() => {
    if (isMountedRef.current && videoData.mediaId) {
      mParticleEventProcessor.pushMParticleEvent(MParticleCustomEventTypes.PlayerFetchDataCompleteEvent, {
        ...mParticleEventData,
        ...{ player_timer: getDiff(playerStartTime) },
      });
    }
  }, [videoData, isMountedRef]);

  const tags = useVideoTag(videoData);

  return {
    videoData,
    tags,
    playerStartTime,
    withMediaConfig: {
      startBitrate: PLAYER_START_BITRATE,
      maxBitrate: PLAYER_MAX_BITRATE,
      capBitrateToSize: false,
    },
  };
};
