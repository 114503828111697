import merge from 'lodash/merge';
import { StyleProp, StyleSheet, ViewStyle, TextStyle } from 'react-native';

interface Styles {
  viewStyle: ViewStyle;
  iconStyle: ViewStyle;
  textStyle: TextStyle;
}

export const getCardStyles = (colors: ReactNativePaper.ThemeColors) => {
  const base: StyleProp<Styles> = {
    viewStyle: {
      alignItems: 'center',
      flexDirection: 'row',
      height: 16,
      marginLeft: 10,
      marginVertical: 2,
    },
    iconStyle: {
      marginLeft: -10,
      marginVertical: -2,
    },
    textStyle: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Oswald-SemiBold',
      fontSize: 10,
      paddingHorizontal: 10,
      marginTop: -2, // Oswald-Regular seems to have extra top padding
      textTransform: 'uppercase',
    },
  };

  const mergedStyles = merge(base);

  return StyleSheet.create(mergedStyles);
};
