import React from 'react';
import Svg, { Circle, Rect } from 'react-native-svg';

/* eslint-disable-next-line */
export interface StatsProps {
  width?: number;
  height?: number;
  color?: string;
  iconColor?: string;
}

export function Stats(props: StatsProps) {
  const width = props.width || 20;
  const height = props.height || 20;
  const color = props.color || '#359fd7';
  const iconColor = props.iconColor || '#fff';

  return (
    <Svg fill="none" height={height} viewBox="0 0 20 20" width={width}>
      <Circle cx="10" cy="10" r="10" fill={color} />
      <Rect width="8" height="2" x="14" y="7" fill={iconColor} rx="1" transform="rotate(90 14 7)" />
      <Rect width="10" height="2" x="11" y="5" fill={iconColor} rx="1" transform="rotate(90 11 5)" />
      <Rect width="6" height="2" x="8" y="9" fill={iconColor} rx="1" transform="rotate(90 8 9)" />
    </Svg>
  );
}

export default Stats;
