import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  button: {
    padding: 5,
    paddingHorizontal: 10,
    minHeight: 40,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  outlineBtn: {
    borderColor: '#5992f5',
    borderRadius: 5,
    borderWidth: 2,
  },
  containedBtn: {
    elevation: 1,
    borderColor: '#5992f5',
    backgroundColor: '#5992f5',
    borderRadius: 5,
    borderWidth: 2,
  },
  textBtn: {
    borderColor: 'transparent',
    borderRadius: 0,
    borderWidth: 0,
  },
  icon: {
    width: 15,
    height: 15,
    marginHorizontal: 5,
  },
  label: {
    textTransform: 'uppercase',
    color: '#5992f5',
  },
  containedBtnLabel: {
    color: 'rgb(255, 255, 255)',
    fontFamily: 'Oswald-Medium',
    fontSize: 20,
    fontWeight: '500',
    lineHeight: 24,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
});
