import { formatDateDDMMYYYY } from '@warnermmedia/gsp-core/sdk/ui';

export default {
  app_name: 'EstadioTNTSports',
  app_version: 'Versión',
  SplashScreenText: 'ESTRALLA POTENCIAL',
  dateFormat: 'DD/MM/YYYY',
  app_web_title: 'Estadio TNT Sports Web',

  // FOOTER STRINGS
  FOOTER_LABEL_1: 'TÉRMINOS Y CONDICIONES',
  FOOTER_LABEL_2: 'POLÍTICA DE COOKIES',
  FOOTER_LABEL_3: 'CENTRO DE PREFERENCIAS',
  FOOTER_LABEL_4: 'FAQ',
  FOOTER_LABEL_5: 'REQS. MÍNIMOS',
  FOOTER_LABEL_6: 'CONSULTA BOLETA',
  FOOTER_LABEL_7: 'TNTSports.cl',
  FOOTER_LABEL_8: 'IR A ESTADIO ARGENTINA',
  FOOTER_LABEL_9: 'AYUDA',
  FOOTER_LABEL_10: '[NUEVA] POLÍTICA DE PRIVACIDAD',
  FOOTER_COPYRIGHT_1: 'ESTADIO TNT SPORTS',
  FOOTER_COPYRIGHT_2: ', TODOS LOS DERECHOS RESERVADOS.',
  //////////////////////////////

  // Card State Labels
  todayLabel: 'Hoy',
  revealLabel: 'Revívelo',
  liveLabel: 'En Vivo',
  dayLabel: 'Fecha',

  // Error MessageDisplay
  contentLockError: 'Lo lamentamos, no tienes acceso a este contenido. Contáctanos para más información.',
  noAccessError: 'Lo lamentamos, no tienes acceso a este contenido. Contáctanos para más información.',
  cantPlayContentError: 'No puedes acceder al contenido en este momento. Por favor intenta nuevamente.',
  tokenExpiredError: 'Tu sesión ha expirado. Por favor ingresa nuevamente.',
  locationError: 'Contenido bloqueado debido a la ubicación',
  noAccessToContent: 'Lo sentimos, no tienes acceso a este contenido. Contáctenos para más información.',
  maxStream: 'Se ha excedido el número máximo de transmisiones permitidas',
  mediaNotAvailable: 'Media no disponible en este momento',
  authorisationError: 'error de autorización',
  tryAgainLater: 'Hubo un problema al reproducir el contenido. Por favor, inténtelo de nuevo más tarde.',

  // ResendEmailVerification
  emailVericationPageTitle: 'Debes validar tu cuenta',
  emailVericationPageTitleSuccess: 'Tu email se validó con éxito',
  emailVericationParagraph_1: 'Valida tu cuenta clickeando en el e-mail que enviamos a ',
  emailVerificationParagraph_2: 'Si no lo has recibido, clickea en el botón para enviarlo nuevamente.',
  emailVerificationParagraphPinCode:
    'Cuando finalices el proceso de validar tu cuenta, vuelve a iniciar el ingreso desde tu TV.',
  emailVericationCtaButton: 'Reenviar Email',
  emailVericationMessage: '¿Ya has validado tu cuenta?',
  emailVericationLogoutMessage: '¿Ingresaste un correo erróneo?',
  emailVericationLogoutLabel: 'Clickea aquí para empezar nuevamente.',
  emailVericationSuccessMessage: '¿No has recibido el email de validación?',
  emailVericationCheckButtonText: 'Chequear la validación',
  emailVericationApiSuccess:
    'Enviamos un email a la cuenta que utilizaste para registrarte.\n\nPor favor clickea en el email para validar tu cuenta.',
  emailVericationApiError: 'El e-mail no ha sido enviado, por favor intenta nuevamente.',
  emailVericationSuccessRouteMessage: 'Ya puedes ingresar a Estadio TNT Sports.',
  emailCheckvalidationMessageSuccess:
    'Hemos checkeado y tu email no está verificado aún.\nPor favor clickea en el email para validar tu cuenta.',
  emailCheckvalidationMessageError: 'Ocurrió un error comprobando la validación de tu cuenta.',
  checkEmailContentTitle: 'Revisa tu email',
  emailVerificationSuccessContentTitle: 'Validaste tu cuenta con éxito',

  // Update app modal
  updateAppModalTitle: 'Puedes actualizar tu aplicación',
  updateAppModalDescription:
    'Hay una nueva versión de esta aplicación. Actualiza para continuar disfrutando de Estadio TNT Sports.',
  updateAppModalBtnText: 'ACTUALIZAR',

  // My Account
  editProfileSuccessMessage: 'Tu información personal se actualizó con éxito.',
  tvEditProfileDescriptionLine1: 'Escanea el código QR o ingresa a ',
  tvEditProfileDescriptionLine2: ', y sigue los pasos indicados para editar los datos de tu cuenta.',
  tvEditProfileQRTitle: '¿Quieres editar la informacíon de tu cuenta en estadio tnt sports?',
  tvEditProfileQRButtonLabel: 'Entendido',
  tvEditProfileQREditButton: 'Editar Información',
  checkPaymentInfo: 'Ver información de pagos',
  userPreferencesErrorMessage:
    'No es posible modificar tu perfil en este momento, por favor inténtalo nuevamente más tarde.',

  // Subscription
  tvSubscriptionCTAButtonText: 'YA ACTIVE MI CUENTA',
  payPalBtnLabel: 'Continuar con paypal',
  subsMobilePageTitle: 'No tienes una suscripción activa.',
  subsWebPageTitle: 'COMIENZA A DISFRUTAR DE NUESTRO CONTENIDO',
  subsPinValidationSubtitle:
    'Cuando finalices el proceso de adquirir tu suscripción, vuelve a iniciar el ingreso desde tu TV.',
  noSubscriptionText:
    'No tienes una suscripción activa, por favor activa una para visualizar contenido en Estadio TNT Sports.',
  noSubscriptionEnabledText: 'No tienes una suscripción activa.',
  activateSubscription: 'ACTIVAR SUSCRIPCIÓN',
  iOSButtonText: 'Volver',
  androidButtonText: 'Suscríbete ahora',
  mobileMsgDisplayText: 'Lo lamentamos, para ingresar a Estadio TNT Sports necesitas una suscripción activa.',
  buyNowLabel: 'COMPRAR AHORA',
  subscriptionFailureMessage: 'Código de cupón inválido.',
  subscriptionInvalidPromocodeMessage: 'Código de promoción no válido.',
  subscriptionUsedPromocodeMessage: 'Código de promoción ya utilizado para esta cuenta.',
  subscriptionSuccessMessage: 'El cupón ha sido aplicado correctamente.',
  subscriptionPreviewFailedMessage: '¡La vista previa del producto falló!',
  subscriptionPaymentFailedMessage: '¡Error en el pago de la suscripción!',
  subscriptioncancelledMessage:
    'Suscripción actual ya cancelada, vuelva a suscribirse para usar el código de promoción.',
  doNotRefreshLabel: 'Por favor, no actualices ésta página',
  doNotRefreshDescription: 'Espera unos momentos mientras se procesa tu pago.',
  cardNameError: 'Ingresa el Nombre que figura en la tarjeta.',
  cardNumberError: 'Ingresa los 16 números de la tarjeta.',
  cardExpiryDateError: 'Por favor ingresa el vencimiento de la tarjeta.',
  cardCVNError: 'Por favor ingresa el código CVV de la tarjeta.',
  cardNumberLabel: 'Número de Tarjeta de Crédito',

  loadingText: 'Cargando...',
  subscriptionSuccessHomeBtnText: 'IR AL Inicio',
  subscriptionSuccessHomeQuestionBtnText: '¿Qué es Estadio TNT Sports?',
  subscriptionSuccessRecommendationBtnText: 'Recomendaciones básicas',
  subscriptionSuccessSolutionBtnText: 'Solución de problemas',
  subscriptionSuccessTitle: '¡Suscripción realizada con éxito!',
  subscriptionSuccessText1: 'Bienvenido, ya tienes tu asiento asegurado.',
  subscriptionSuccessText2: 'El cupón ha sido aplicado correctamente.',
  subscriptionSuccessText3: 'La transacción ha sido procesada correctamente.',
  subscriptionSuccessText4:
    'Puedes comenzar a disfrutar de todos los partidos del fútbol chileno en vivo y VOD, junto a la mejor programación de Estadio TNT Sports. Además te invitamos a revisar algunos consejos.',
  backToAccountLabel: 'volver a mi cuenta',
  returnLabel: 'Volver',
  subscribeNowLabel: 'Suscríbete ahora',
  cancelSubscriptionLabel: 'cancelar mi suscripción',
  subscriptionCancelledTitle: 'SUSCRIPCIÓN CANCELADA.',
  subscriptionCancelledLabel: 'Has cancelado tu suscripción.',
  subscriptionRequirementDescription:
    'Lo lamentamos, para ingresar a Estadio TNT Sports necesitas una suscripción activa',
  cancelSubscriptionWarningDescription: 'Estás a punto de cancelar tu suscripción. ¿Estás seguro?',
  cancelSubscriptionTitle: 'Antes de irte',
  cancelSubscriptionSubTitle:
    'Lamentamos que quieras cancelar tu suscripción en Estadio TNT Sports, por favor ayúdanos a mejorar!',
  paypalText:
    'Para finalizar el registro, haga clic en el botón Continuar a PayPal e inicie sesión en PayPal con su correo electrónico y contraseña.',
  tvSubscriptionText: (webUrlLink: string) => `Escanea el código QR o ingresa a ${webUrlLink}, y suscríbete ahora.`,
  couponButtonLabel: 'Tengo un Cupón',
  payTypeHeaderLabel: 'Tarjeta de Crédito / Débito',
  payTitleLabel: 'Ingrese sus detalles de pago',
  payDescriptionLabel: 'Tu suscripción comienza apenas completes tu información de pago.',
  cancelCommentTitle: 'Puedes agregar algún comentario más si quieres',
  subscriptionExpireDescription: (date: string) => `Podrás seguir disfrutando de Estadio TNT Sports hasta el ${date}.`,
  cancelSubscriptionContentTitle: 'Lamentamos verte ir',
  unsubscribeConfirmContentTitle: '¿Estás seguro?',
  subscriptionSuccessContentTitle: '¡Te damos la bienvenida!',
  subscriptionFailureContentTitle: 'Algo salió mal',
  subscriptionFailureInfo1: 'Se ha cancelado el proceso de pago',
  subscriptionFailureInfo2: 'No se han realizado cargos en tu tarjeta. Por favor, inténtalo nuevamente.',
  subscriptionButtonText: 'Enviar',

  // vindicia label
  accountName: 'Nombre en la tarjeta',
  accountCountry: 'Pais',
  cardNumber: 'Número de Tarjeta',
  expiryDate: 'Válida hasta',
  cvn: 'Código de seguridad (CVV)',

  cancel: 'Cancelar',
  lastName: 'Apellido',
  firstName: 'Nombre',
  alternativeEmail: 'Email Alternativo',
  birthdateText: 'Fecha de Nacimiento',
  genderText: 'Género',
  genderMaleText: 'Masculino',
  genderFemaleText: 'Femenino',
  countryText: 'País de Residencia',
  phone: 'Número de Teléfono',
  password: 'Contraseña',
  repeatPassword: 'Repite tu Contraseña',
  favoriteTeamLabel: 'Tu equipo Favorito',
  confirmPassword: 'Confirmación de contraseña',
  editAccountSubmit: 'GUARDAR INFORMACIÓN',
  accountInfoTitle: 'Mi información',
  editAccountSubTitle:
    'No dude en editar cualquiera de sus datos a continuación para que su cuenta esté totalmente actualizada.',
  editMyAccountBtnTex: 'EDITAR INFORMACIÓN',

  // register
  errorListLabel: 'El formulario tiene errores:',
  register: 'Registrarme',
  missingAnyField: 'Debes ingresar todos tus datos para registrarte en Estadio.',
  missingFirstName: 'Ingresa tu nombre para registrarte.',
  missingLastName: 'Ingresa tu apellido para registrarte.',
  missingEmail: 'Ingresa tu email para registrarte.',
  invalidEmail: 'Por favor ingresa un e-mail válido.',
  missingPassword: 'Por favor completa tu contraseña.',
  missingPassword2: 'Por favor completa tu contraseña otra vez.',
  differingPassword: 'Las contraseñas no coinciden. Por favor revísalas.',
  invalidCaptcha: 'El recaptcha no es correcto. Por favor intente nuevamente.',
  unacceptedTerms: 'Debes aceptar los términos y condiciones de Estadio para registrarte en la plataforma.',
  alreadyRegisteredEmail: 'El nombre de usuario o la contraseña son incorrectos. Por favor revísalos.',
  incorrectPassword: 'El nombre de usuario o la contraseña son incorrectos. Por favor revísalos.',
  unconfirmedAccount: 'Por favor confirma tu cuenta antes de ingresar a Estadio.',
  onlyAlphabeticCharactersError: 'Solo se aceptan caracteres alfabéticos.',
  registerFormTitle: 'Crea tu cuenta en Estadio TNT Sports.',
  registerFormDirectionsText: 'Ingresa tus datos para registrarte.',
  registerTermsLabelLine1: 'Accepto los',
  registerTermsLabelLine2: 'términos y condiciones',
  registerTermsLabelLine3: 'de uso',

  // EditAccountError
  firstNameErrorMsg: 'Nombre requerido!',
  lastNameErrorMsg: 'Apellido requerido!',
  dayErrorMsg: 'Ingrese un día válido!',
  monthErrorMsg: 'Por favor, introduzca un mes válido!',
  yearErrorMsg: 'Por favor, introduzca un año válido!',
  phoneErrorMsg: 'Ingrese un número de teléfono válido de 9 números!',
  alternativeEmailErrorMsg:
    'Ingrese un correo electrónico válido y debe ser diferente al correo electrónico principal!',
  passwordErrorMsg: 'La contraseña debe incluir al menos 8 caracteres: 1 mayúscula, 1 minúscula, 1 número o símbolo.',
  passwordConfirmationErrorMsg: '¡La contraseña y la contraseña de confirmación deben ser iguales!',
  rutErrorMsg: 'Ingrese un RUT válido de 9 números!',
  editFormError1: 'Fecha de nacimiento parcialmente llena!',
  editFormError2: 'Los cambios que ingresaste no se pudieron guardar, por favor vuelve a intentarlo.',
  saveDetailsError: 'La actualización del perfil falló;',
  saveEmailError: '¡el correo electrónico alternativo ya se usó,',
  saveContactError: 'durante la actualización; contacto móvil,',
  savePreferenceError: 'durante la actualización; preferencias,',
  profileError: 'durante la actualización; contacto móvil y preferencias.',
  savePasswordError: 'durante la actualización; Contraseña.',
  birthdateErrorMsg: 'Ingrese una fecha válida.',

  // redeemCoupon
  redeemCouponErrorMessage: 'Tu cupón no ha sido validado, por favor inténtalo nuevamente más tarde.',
  redeemCouponTitle: 'Valida tu cupón',
  redeemCouponSubTitle: 'Ingresá el código de tu cupón.',
  code: 'Código',
  valid: 'Validar',
  confirm: 'Confirmar',
  couponToolTip: '¡Ingrese el código de cupón para continuar!',
  contactUsMessage: '¿No puedes validar tu cupón?',
  contactUsMessageButton: 'Ponte en contacto con nosotros',
  couponRedemptionTitle: 'Estás a un paso de canjear tu cupón',
  couponRedemptionDescription:
    'Acepta los términos y condiciones y confirma el cupón para aplicar el descuento en tu próxima suscripción.',
  freeCouponRedemptionTitle: 'Estás a un paso de redimir tu cupón',
  freeCouponRedemptionDescription: (date: string | number | Date) =>
    `Acepta los términos y condiciones y confirma el cupón para disfrutar de Estadio TNT Sports gratis hasta el ${formatDateDDMMYYYY(
      date
    )}`,
  couponTermsText: 'Acepto los términos y condiciones de Uso de Estadio TNT Sports.',
  couponRedemptionToolTip:
    'Debes aceptar los términos y condiciones de Estadio para utilizar el cupón en la plataforma.',

  // retention
  retentionTitle: 'Estás a Punto de Cancelar tu Subscripción ¿Estás seguro?',
  promotionButtonLabel: 'ACTIVAR PROMOCIÓN',
  activatePromotionSuccess: 'Promoción registrada correctamente.',
  activatePromotionErr: 'Hubo un problema, por favor inténtalo nuevamente más tarde.',

  // invoice
  consult: 'Consultar',
  invoiceTitle: 'Busca tu boleta',
  invoiceTitleDownload: 'Tu boleta está disponible',
  invoiceSubtitle1: 'Completa todos los campos para encontrar tu boleta.',
  invoiceSubtitle2: 'Ya puedes descargarla clickeando en el botón de descarga.',
  invoiceNotFoundLink: 'comunícate con nuestro soporte',
  invoiceNotFound1:
    'No pudimos encontrar una boleta que coincida con los datos ingresados. Por favor, intenta nuevamente o',
  invoiceNotFound2: 'para que podamos ayudarte.',
  invoiceErrorMsg: 'El recaptcha no es correcto. Por favor intente nuevamente.',
  invoiceSuccessMsg: 'Debes completar todos los datos.',
  invoiceNumber: 'Número de boleta',
  invoiceNumberToolTip: '¡Ingrese el número de boleta para continuar!',
  invoiceDate: 'Fecha de emisión',
  invoiceDateToolTip: '¡Ingrese una fecha de emisión válida (DD/MM/YYYY) para continuar!',
  invoiceAmount: 'Monto Total',
  invoiceAmountToolTip: '¡Ingrese el monto total para continuar!',
  invoiceRUTToolTip: '¡Ingrese el RUT para continuar!',
  invoiceRecaptchaToolTip: '¡Ingrese el ReCAPTCHA para continuar!',

  // Metatags
  myAccountTitle: 'Mi Cuenta',
  appInfoTitle: 'Información de la aplicación',

  // Provider Login
  providerLoginTitle: 'Inicia Sesión',
  providerLoginDescription: 'Selecciona tu proveedor para ingresar a la plataforma',
  providerLoginSearchLabel: 'Buscar',
  providerLoginSearchPlaceholder: 'Busca tu proveedor',
  providerLoginWithAccountLabel: 'Ingresar con mi cuenta',
  providerLoginCountryLabel: 'País',
  providerLoginShowAllLabel: 'Mostrar todos',
  providerLoginCountrySelectionLabel: 'Selecciona tu país',
  providerLoginProviderError: 'No hay proveedores para mostrar.',
  providerLoginProviderListLoadingError: 'Ocurrió un error cargando la lista de proveedores.',
  providerLoginAuthenticationError: 'Ocurrió un error en la autenticación con el proveedor.',

  // TVE Errors
  unKnownLoginError: 'Ocurrió un error inesperado en la autenticación TVE.',
  unKnownLogoutError: 'Ocurrió un error inesperado cerrando la session TVE.',
  unKnownProviderError: 'Ocurrió un error inesperado cargando los proveedores.',
  unKnownTokenError: 'No tienes contratado el servicio para acceder a Estadio. Contacta a tu proveedor.',
  // login
  error401:
    'Por seguridad, se ha bloqueado esta cuenta. Para iniciar sesión nuevamente, tendrás que cambiar tu contraseña utilizando la opción RECUPERAR CONTRASEÑA ubicada debajo del botón INGRESAR.',
  error420:
    'El nombre de usuario o la contraseña son incorrectos. Si no recuerdas tu contraseña puedes generar una nueva con la opción RECUPERAR CONTRASEÑA ubicada debajo del botón INGRESAR.',
  errorDefault: 'Ocurrió un error al iniciar sesión.',
  forgotPassword: 'Recuperar contraseña',

  // Page Titles:
  homePage: 'Home',
  signalTvPage: 'Señal TV',
  loginPage: 'Login',
  cancellationPage: 'Antes de irte',
  profilePage: 'Mi Cuenta',
  showsPage: 'Programas',
  teamsPage: 'Equipos',
  tournamentsPage: 'Campeonatos',
  passwordRecoveryPage: '¿No puedes acceder a tu cuenta?',
  resetPasswordPage: 'Restablecer la contraseña',
  subscriptionFailurePage: 'Transacción fallida',
  landingPage: 'Te damos la bienvenida',
  billingPage: 'Tu historial de pagos',
  golfChannelPage: 'Golf Channel',

  // Cancellation
  cancellationSubTitle:
    'Lamentamos que quieras cancelar tu suscripción en Estadio TNT Sports, por favor ayúdanos a mejorar!',
  cancellationReturnToMyAccount: 'Volver a mi cuenta',

  // Password Recovery
  passwordRecoverySuccess:
    'Si existe un usuario registrado para el email has ingresado, le hemos enviado un email. Sigue los pasos indicados en el mismo para que recuperes tu cuenta.',
  passwordRecoverySubTitle:
    'Completa el email asociado a tu cuenta y te enviaremos los pasos para que puedas recuperar el acceso a Estadio.',
  passwordRecoverySubWithCodeTitle:
    'Completa el email asociado a tu cuenta y te enviaremos los pasos para que puedas recuperar el acceso a Estadio.\n\nCuando termines el proceso de recuperación de tu cuenta, vuelve a iniciar el ingreso desde tu TV.',
  recoveryEmailLabel: 'Enviar email de recuperación',
  recoveryEmailInputError: 'El email ingresado no tiene un formato válido. Por favor revísalo.',

  // Reset Password
  resetPasswordSuccessTitle: 'Cambio realizado con éxito',
  resetPasswordSuccessSuccess: 'Tu nueva contraseña ha sido guardada. Puedes ingresar a Estadio ahora.',
  resetPasswordSubTitle: 'Elige una nueva contraseña para tu cuenta en Estadio.',
  resetPasswordErrorMessage: 'Ha ocurrido un error. Falló la autenticación por token, por favor',
  resetPasswordErrorMessageLink: ' "inicia nuevamente el proceso."',
  resetPasswordConfirmLabel: 'Confirmar Contraseña',
  resetPasswordRule:
    'Las contraseñas deben contener un mínimo de 8 caracteres: 1 mayúscula, 1 minúscula, 1 número o símbolo',
  resetPasswordUpdateLabel: 'Actualizar Contraseña',
  passwordMatchError: 'Las contraseñas no coinciden. Deben ser iguales para confirmar tu nueva contraseña.',
  ruleMismatchError: 'Tu contraseña elegida no tiene los requisitos mínimos para que podamos confirmarla',
  resetPasswordSuccessContentTitle: 'Puedes ingresar ahora',

  // Landing
  landingSubTitle: 'Ingresa tus credenciales para entrar a Estadio TNT Sports.',

  // Login Form
  invalidPasswordError: 'Por favor completa tu contraseña.',
  emptyUsernameError: 'Ingresa tu email para ingresar.',
  invalidUsernameError: 'Por favor ingresa un e-mail válido.',
  rememberMeText: 'Recordar este usuario a futuro',
  registerTextTV: 'No tengo cuenta',
  registerText: 'Registrate en Estadio TNT Sports',
  forgotpasswordQRTitle: '¿no puedes acceder a tu cuenta en estadio tnt sports?',
  forgotpasswordQRButtonLabel: 'recuperé el acceso',
  registerQRTitle: '¿no tienes cuenta en estadio tnt sports?',
  registerQRButtonLabel: 'ya tengo mi cuenta',
  forgotModalDescription: (forgotPasswordLink: string) =>
    `Escanea el código QR o ingresa a ${forgotPasswordLink}, y sigue los pasos indicados para recuperar tu cuenta.`,
  registerModalDescription: (registerLink: string) =>
    `Escanea el código QR o ingresa a ${registerLink}, y sigue los pasos indicados para registrar tu cuenta.`,
  confirmVerificationCodeMessage: 'Por favor ingresa para verificar tu cuenta',
  verificationMessageTokenExpired:
    'Se acabó el tiempo que tenías para validar tu cuenta, es necesario que valides tu email para ingresar a Estadio.',
  providerLoginText: 'Ingresar con mi cuenta de proveedor',

  //Common button texts:
  continueText: 'Continuar',
  logoutText: 'Cerrar Sesión',
  emailSentText: 'Email Enviado',
  loginText: 'Iniciar Sesión',
  emailText: 'Email',
  passwordText: 'Contraseña',
  tryAgainText: 'Intentarlo nuevamente',
  enterText: 'Ingresar',
  stayInTouchText: 'Ponte en contacto con nosotros.',
  saveText: 'Guardar',
  closeButton: 'Cerrar este mensaje',

  // Register page
  consentMessageLine1: 'Al crear una cuenta, usted acepta los',
  consentMessageLine2: 'y reconoce que ha leído y acepta nuestra ',
  consentMessageLine3: `. Usted reconoce que Estadio TNT Sports y sus afiliados de Warner Bros. Discovery pueden usar su dirección de correo electrónico para enviar [actualizaciones/noticias], avisos, y ofertas. Puede cancelar o obtener más información sobre sus derechos a través de la `,
  termsAndConditionLabel: 'Términos de Uso',
  privacyPoliciesLabel: 'Política de Privacidad',

  //Account Detail
  accountDetailHeader: 'Detalles de Suscripción',
  subscriptionTitle: 'Tu Suscripción',
  subscriptionValidLabel: 'Tu suscripción es válida hasta el ',

  // Favorite Team
  favoriteTeamErrorMessage: 'Tu selección no ha sido guardada, por favor inténtalo nuevamente más tarde.',
  favoriteTeamModalHeaderText: 'Tu equipo favorito',
  favoriteTeamModalMessageText:
    'Selecciona un equipo de la lista y verás el contenido destacado en el inicio de Estadio TNT Sports.',
  favoriteTeamModalSkipLabel: 'En otro momento',
  favoriteTeamModalDropdownTitle: 'Equipos Chilenos',
  favoriteTeamModalDropdownPlaceholder: '- Selecciona un equipo -',

  // Geo block
  geoBlockMessageTitle: 'No es posible reproducir este contenido',
  geoBlockMessageDescription: 'Este contenido no está disponible en tu ubicación actual.',
  // Subscription Info
  subscriptionInfoTitle: 'Detalles de Suscripción',
  subscriptionInfoSubTitle: 'Tu Suscripción',
  subscriptionInfoDescription: (subscriptionEndDate: string) =>
    `Tu suscripción es válida hasta el ${subscriptionEndDate}`,
  subscriptionTveInfoDescription: (providerName: string) =>
    `Tu suscripción es válida a través de ${providerName}. Para más información sobre tu cuenta, ponte en contacto con ellos.`,
  subscriptionInfoCancelButton: 'CANCELAR SUSCRIPCIÓN',
  subscriptionInfoCoupon: 'Validar un cupón',

  // concurrency labels
  concurrencyModalTitle: 'YA ESTÁS REPRODUCIENDO CONTENIDO',
  concurrencyModalDescription: 'Has llegado al límite de dispositivos reproduciendo en simultáneo.',
  concurrencyModalCTALabel: 'VOLVER AL INICIO',
  toolboxConcurrencyModalTitle: '¡Ups! Tu sesión en este dispositivo ha caducado.',
  toolboxConcurrencyModalDescription:
    'Cierra tu sesión y vuelve a iniciarla para seguir disfrutando de tu contenido favorito.',
  toolboxConcurrencyModalCTALabel: 'Cerrar Sesión',

  // TermsCheckbox
  termsText: 'Acepto los términos y condiciones de uso de Estadio.',
  tooltipTermsText: 'Acepte los términos y condiciones para continuar!',

  epgSwimlaneHeadline: 'Programación',

  // CMS Carousel Headline
  teamDetailCarouselHeadline: 'Próximos Partidos',

  //PIN&PAIR
  pinPairTitle: 'Escanea el código QR o visita el link: ',
  pinPairDescription: 'Ingresa el código en tu dispositivo:	',
  buttonText: 'ACTUALIZA EL CÓDIGO',
  pinPairBack: 'Volver',
  pinPairLandingTitle: 'VIVE LA PASIÓN DEL FÚTBOL CHILENO',
  pinPairLandingSubtitle: 'ESTÉS DONDE ESTÉS',
  pinPairErrorTitle: 'Algo salió mal',
  pinPairErrordescription: 'Estamos experimentando algunos problemas, inténtalo de nuevo más tarde.',
  // Page title suffix
  contentTitleSuffix: 'Estadio TNT Sports',

  // Code Validation
  codeValidationTitle: 'Ingresa el código que se muestra en tu TV',
  codeValidationDescription:
    'Introduce el código de 6 dígitos que ves en la pantalla de tu tv, en el espacio de abajo.',
  codeValidationDescriptionUnLogged:
    'Toca en EMPAREJAR MI TV para que podamos identificar tu cuenta, podrás ingresar en el paso siguiente.',
  codeValidationDescriptionLogged: (username: string) =>
    `Toca en EMPAREJAR MI TV y utilizaremos los datos de la cuenta de abajo para ingresar a la plataforma \n\n ${username}`,
  codeValidationCta: 'Emparejar mi TV',
  codeValidationProcessingCta: 'Validando',
  codeValidationOtherAccountBtn: 'Usar otra cuenta',
  codeValidationIncorrectCodeError: 'El código ingresado no es válido. Por favor, intenta nuevamente.',
  codeValidationGenericError: 'Hubo un problema, por favor inténtalo nuevamente más tarde.',
  codeValidationLoginSubtitle: 'Ingresa tus credenciales para entrar a Estadio TNT Sports y conectar con tu TV.',

  // Code Validation Success
  codeValidationSuccessTitle: 'Tu TV está lista',
  codeValidationSuccessSubtitle:
    'Puede demorar un momento en cargar en tu TV.\n\nYa puedes cerrar ésta ventana y acceder a Estadio desde tu TV.',
  codeValidationSuccessNewsBtn: 'Lee las últimas noticias de TNT Sports.',

  // recaptch language
  recaptchaLang: 'es-419',

  // Payment Type
  paymentTypeCoupon: 'Cupón',

  // Navbar labels
  navHome: 'Inicio',
  navSignalTvPage: 'Señal TV',
  navTournamentsPage: 'Campeonatos',
  navTeamsPage: 'Equipos',
  navShowsPage: 'Programas',
  navProfilePage: 'Mi Cuenta',
  navLogout: 'Cerrar Sesión',
  navHelp: 'Ayuda',
  navSubscribe: 'Suscríbete',
  navSubscription: 'Suscripción',

  // ChangesNotSavedModal
  changesNotSavedModalTitle: 'Tienes cambios sin guardar',
  changesNotSavedModalDescription:
    '¿Estas seguro que quieres cancelar? Los cambios no guardados no se aplicarán a tu cuenta.',
  changesNotSavedModalEditBtn: 'Continuar editando',
  changesNotSavedModalDiscardBtn: 'Descartar cambios',

  //Discover Max Modal
  discoverMaxModalTitle: 'Descubre Max',
  discoverMaxModalText: 'Proveedor no disponible. Inicia sesión en max.com y disfruta de TNT Sports en Max.',
  discoverMaxModalButtonLabel: 'Volver',

  // Go to Max
  goToMax: 'Ir a Max',
};
