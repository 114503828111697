import React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';

/* eslint-disable-next-line */
export interface CloseProps {
  width?: number;
  height?: number;
  color?: string;
  iconColor?: string;
}

export function Close(props: CloseProps) {
  const width = props.width || 20;
  const height = props.height || 20;
  const color = props.color || '#359fd7';
  const iconColor = props.iconColor || '#fff';

  return (
    <Svg fill="none" height={height} viewBox="0 0 20 20" width={width}>
      <Circle cx="10" cy="10" r="10" fill={color} />
      <Path
        fill={iconColor}
        d="M13.536 6.465c.39.39.39 1.023 0 1.414l-5.657 5.657c-.39.39-1.024.39-1.414 0-.391-.39-.391-1.024 0-1.415l5.656-5.656c.39-.391 1.024-.391 1.415 0z"
      />
      <Path
        fill={iconColor}
        d="M6.464 6.465c.391-.391 1.024-.391 1.415 0l5.657 5.656c.39.39.39 1.024 0 1.415-.391.39-1.024.39-1.415 0L6.464 7.879c-.39-.39-.39-1.024 0-1.414z"
      />
    </Svg>
  );
}

export default Close;
