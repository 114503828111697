import * as React from 'react';
import Svg, { Defs, Path, G, Use } from 'react-native-svg';

export interface CastProps {
  width?: number;
  height?: number;
  color?: string;
}

export const CastTvComponent = ({ width = 14, height = 12, color = 'none', ...props }: CastProps) => (
  <Svg width={width} height={height} {...props}>
    <Defs>
      <Path
        d="M12.286 2.857a2 2 0 0 1 2 2V10a2 2 0 0 1-2 2H10.76a1.715 1.715 0 0 1-1.617 1.143H6.857a1.715 1.715 0 0 1-1.617-1.144L3.714 12a2 2 0 0 1-2-2V4.857a2 2 0 0 1 2-2h8.572Zm.285 1.714H3.43v5.715h9.142V4.57Z"
        id="a"
      />
    </Defs>
    <G transform="translate(-1 -2)" fill={color} fillRule="evenodd">
      <Path d="M0 0h16v16H0z" />
      <Use fill="#FFF" xlinkHref="#a" />
    </G>
  </Svg>
);

export default CastTvComponent;
