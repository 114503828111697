import * as React from 'react';
import { StyleProp, TextStyle, View, ViewStyle } from 'react-native';
import { Button, TextInput } from 'react-native-paper';
import { Tooltip } from '../../tooltip/tooltip';
import { TextContent } from '../../textcontent/textcontent';

import styles from './forgotPasswordForm.styles';

export interface ForgotPasswordFormProps {
  isLoading?: boolean;
  onRequestSend(username: string): void;
  error?: string;
  containerStyle?: StyleProp<ViewStyle>;
  btnColor?: string;
  disabledBtnColor?: string;
  label?: string;
  btnStyle?: StyleProp<ViewStyle>;
  textInputStyle?: StyleProp<TextStyle>;
  ctaBtnLabel?: string;
  placeholder?: string;
  usernameInputError?: string;
  validateUsername?: (username: string) => boolean;
}

export function ForgotPasswordForm({
  isLoading,
  onRequestSend,
  error,
  containerStyle,
  btnColor,
  disabledBtnColor,
  label,
  btnStyle,
  textInputStyle,
  ctaBtnLabel,
  placeholder,
  usernameInputError,
  validateUsername,
}: ForgotPasswordFormProps) {
  const [username, setUsername] = React.useState<string>('');
  const [inputError, setInputError] = React.useState<string>('');

  function setUserDetails(text: string) {
    setUsername(text);
    setInputError('');
  }

  function sendRequest() {
    if (disabled) {
      return;
    }
    if (validateUsername) {
      if (validateUsername(username)) {
        onRequestSend && onRequestSend(username);
      } else {
        setInputError(usernameInputError || "The provided username doesn't have a valid format. Please revise it.");
      }
    } else {
      onRequestSend && onRequestSend(username);
    }
  }

  const disabled = isLoading || !username;
  const errorMessage = inputError || error;

  return (
    <View style={containerStyle}>
      <View style={styles.topContent}>
        <TextContent style={styles.label}>{label} </TextContent>
        <TextInput
          value={username}
          mode="outlined"
          style={textInputStyle}
          placeholder={placeholder || 'username'}
          onChangeText={setUserDetails}
          textAlign="left"
          error={!!error || !!inputError}
          theme={{ colors: { text: '#000', primary: 'transparent' } }}
        />
        {errorMessage ? <Tooltip tooltipText={errorMessage} opacity={1} visible={true} position="left" /> : null}
      </View>
      <Button
        color={disabled ? disabledBtnColor || '#710d53' : btnColor || '#ff0085'}
        style={btnStyle}
        loading={isLoading}
        mode="contained"
        onPress={sendRequest}
      >
        {ctaBtnLabel || 'Send recovery mail'}
      </Button>
    </View>
  );
}

export default React.memo(ForgotPasswordForm);
