import { ImageStyle, StyleProp, StyleSheet, TextStyle, ViewStyle } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';
import { merge } from 'lodash';

interface Styles {
  bannerLayout: ViewStyle;
  backgroundImage: ImageStyle;
  backgroundOverlay: ViewStyle;
  cardContainer: ViewStyle;
  cardWrapper: ViewStyle;
  cardLayout: ViewStyle;
  liveBannerCard: ImageStyle;
  textContainer: ViewStyle;
  headLine: TextStyle;
  subHeadLine: TextStyle;
  ctaWrapper: ViewStyle;
  ctaText: TextStyle;
  labelContentStyle: ViewStyle;
  playLabelStyle: ViewStyle;
  maxCardLayout: ViewStyle;
  maxDescription: TextStyle;
}

export const getStyles = (breakpoints: Breakpoints, colors: ReactNativePaper.ThemeColors, isTv: boolean) => {
  const base: StyleProp<Styles> = {
    backgroundImage: {
      height: 201,
      width: '100%',
      marginBottom: 17,
    },
    bannerLayout: {
      marginLeft: -25,
      marginRight: -25,
    },
    backgroundOverlay: {
      height: '100%',
    },
    cardWrapper: {
      padding: 2,
      height: '100%',
    },
    cardLayout: {
      height: '100%',
      paddingLeft: 40,
    },
    cardContainer: {
      paddingTop: 16,
      width: 270,
    },
    liveBannerCard: {
      width: 214,
      height: 121,
    },
    textContainer: {
      marginTop: 8,
      marginLeft: 8,
    },
    headLine: {
      fontSize: 16,
      color: colors.keyboardFocus.light,
      fontFamily: 'Rubik-Medium',
      fontWeight: '500',
      lineHeight: 20,
    },
    subHeadLine: {
      fontSize: 14,
      color: colors.keyboardFocus.light,
      fontFamily: 'Rubik-Regular',
      fontWeight: '400',
      lineHeight: 16,
      marginTop: 4,
    },
    ctaWrapper: {
      marginBottom: 5,
      marginRight: 'auto',
      marginTop: 7,
      marginLeft: 10,
    },
    ctaText: {
      fontSize: 10,
      fontFamily: 'Oswald-Regular',
      textTransform: 'uppercase',
      lineHeight: 14,
    },
    labelContentStyle: {
      marginLeft: 15,
      marginRight: 10,
    },
    playLabelStyle: {
      backgroundColor: colors.fill.action.accent01,
      borderRadius: 2,
      marginLeft: 5,
    },
    maxCardLayout: {
      flex: 1,
      flexDirection: 'column',
      height: 60,
      marginTop: 20,
      backgroundColor: colors.fill.action.accent01,
    },
    maxDescription: {
      fontSize: 14,
      color: colors.keyboardFocus.light,
      fontFamily: 'Rubik-Regular',
      fontWeight: '400',
      lineHeight: 16,
      textAlign: 'center',
      padding: 10,
    },
  };

  const mediaQueries = isTv
    ? ({
        backgroundImage: {
          height: 344,
        },
        cardLayout: {
          paddingLeft: 26,
        },
        liveBannerCard: {
          width: 398,
          height: 224,
        },
        headLine: {
          fontSize: 20,
          lineHeight: 28,
        },
        subHeadLine: {
          fontSize: 16,
          lineHeight: 24,
        },
        ctaText: {
          fontSize: 16,
          lineHeight: 24,
          marginLeft: 20,
        },
        cardContainer: {
          margin: 25,
          paddingLeft: 0,
        },
        playLabelStyle: {},
        maxCardLayout: {
          height: 70,
        },
        maxDescription: {
          fontSize: 18,
          lineHeight: 20,
          fontWeight: '600',
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isTiny
    ? ({
        cardLayout: {
          paddingLeft: 29,
        },
        maxCardLayout: {
          height: 140,
        },
        maxDescription: {
          fontSize: 14,
          lineHeight: 16,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isTnySm
    ? ({
        maxCardLayout: {
          height: 120,
        },
        maxDescription: {
          fontSize: 14,
          lineHeight: 16,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isMedLg || breakpoints.windowWidth === breakpoints.breakpointSizes.lg
    ? ({
        backgroundImage: {
          height: 162,
        },
        bannerLayout: {
          marginLeft: -40,
          marginRight: -40,
        },
        liveBannerCard: {
          width: 161,
          height: 90,
        },
        ctaWrapper: {
          marginTop: 4,
        },
        cardContainer: {
          width: 219,
        },
        maxCardLayout: {
          height: 80,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isLgXl
    ? ({
        backgroundImage: {
          height: 180,
        },
        bannerLayout: {
          marginLeft: -25,
          marginRight: -25,
        },
        cardContainer: {
          width: 219,
        },
        liveBannerCard: {
          width: 177,
          height: 99.56,
        },
        cardLayout: {
          paddingLeft: 28,
        },
        maxCardLayout: {
          height: 70,
        },
        maxDescription: {
          fontSize: 14,
          lineHeight: 16,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isXlXxl || breakpoints.currentBreakpoints.isXxl
    ? ({
        backgroundImage: {
          height: 180,
        },
        cardContainer: {
          width: 239,
        },
        liveBannerCard: {
          width: 177,
          height: 99.56,
        },
        cardLayout: {
          paddingLeft: 28,
        },
        maxCardLayout: {
          height: 50,
        },
        maxDescription: {
          fontSize: 14,
          lineHeight: 16,
        },
      } as StyleProp<Styles>)
    : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
