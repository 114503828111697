import React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';

/* eslint-disable-next-line */
export interface TvProps {
  width?: number;
  height?: number;
  color?: string;
  iconColor?: string;
}

export function Tv(props: TvProps) {
  const width = props.width || 20;
  const height = props.height || 20;
  const color = props.color || '#359fd7';
  const iconColor = props.iconColor || '#fff';

  return (
    <Svg fill="none" height={height} viewBox="0 0 20 20" width={width}>
      <Circle cx="10" cy="10" r="10" fill={color} />
      <Path
        fill={iconColor}
        d="M11.058 13.468h-.724v-.684H9.67v.684h-.724c-.162 0-.294.16-.294.357 0 .197.132.357.294.357h2.112c.162 0 .294-.16.294-.357 0-.197-.132-.357-.294-.357z"
      />
      <Path
        fill={iconColor}
        d="M14.716 6H5.284C5.127 6 5 6.154 5 6.345v6.297c0 .19.127.345.284.345h9.432c.157 0 .284-.155.284-.345V6.345c0-.19-.127-.345-.284-.345z"
      />
    </Svg>
  );
}

export default Tv;
