/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import React from 'react';
import {
  Component,
  Content,
  Image,
  ImageWithStyle,
  Link,
  Maybe,
} from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { carouselBase } from '../../Utils/Interfaces';

// TODO: Need to make types more generic.  Get away from carousel/swimlane.
export type carouselFormat = {
  id: string | null | undefined;
  headline: string | undefined;
  description?: string | null | undefined;
  image?: Maybe<Image>;
  type: string | undefined;
  format: string | null | undefined;
  weight: number | null | undefined;
  order?: string | null | undefined;
  content: Maybe<Array<Maybe<Content>>> | undefined;
  allItems?: carouselBase[] | undefined;
  clickAction?: (item: carouselBase) => void;
  images?: Maybe<Maybe<ImageWithStyle>[]> | undefined;
  link?: Link | null | undefined;
  laneIndex?: number;
};

interface CMSPageDataProps {
  formatData(queryData: Maybe<estadioComponent>[], favoriteTeam?: string | null): carouselFormat[];
  firstCarouselIndex(queryData: Maybe<estadioComponent>[]): number;
}

export interface estadioComponent extends Component {
  allItems?: carouselBase[] | undefined;
}

export const useCMSPageData = (): CMSPageDataProps => {
  // TODO: Add screen and tout types to page builder
  const swimlaneTypes = ['hero', 'dpg-carousel', 'carousel', 'screen', 'tout', 'grid', 'banner'];

  const formatData = (data: Maybe<estadioComponent>[], favoriteTeam?: string | null): carouselFormat[] => {
    const carouselData = data
      .filter((swimlane) => {
        return swimlane?.type && swimlaneTypes.includes(swimlane?.type);
      })
      .map((swimlane) => {
        return {
          id: swimlane?.id,
          headline: getHeadline(swimlane?.headline, favoriteTeam),
          description: swimlane?.description,
          image: swimlane?.image,
          type: swimlane?.type ?? '',
          format: swimlane?.format,
          weight: Number(swimlane?.weight) + 1,
          order: swimlane?.content && swimlane?.content[0]?.sort?.order,
          content: swimlane?.content,
          allItems: setItemsTitle(swimlane?.allItems),
          images: swimlane?.images,
          link: swimlane?.link,
        };
      })
      .sort((a, b) => {
        if (a?.weight && b?.weight) {
          return a?.weight > b?.weight ? 1 : -1;
        }
        return 1;
      });
    return carouselData;
  };

  const firstCarouselIndex = (data: Maybe<estadioComponent>[]): number => {
    // Find the first carousel that has data
    let firstCarousel = 1;
    data
      ?.filter((item) => item && item?.type === 'carousel')
      .sort((a, b) => (a && a.weight && b && b.weight ? a.weight - b.weight : 0))
      .find((item) => {
        if (item?.allItems && item.allItems.length > 0) {
          // Found a carousel with data, we are done
          return true;
        } else {
          // No data in this carousel, increment and try the next
          firstCarousel++;
          return false;
        }
      });
    return firstCarousel;
  };

  return {
    formatData: (queryData: Maybe<estadioComponent>[], favoriteTeam?: string | null): carouselFormat[] =>
      formatData(queryData, favoriteTeam),
    firstCarouselIndex: (queryData: Maybe<estadioComponent>[]): number => firstCarouselIndex(queryData),
  };
};

const getHeadline = (headline: Maybe<string> | undefined, favoriteTeam: string | null = '') => {
  return (headline || '').replace('{{fav_team}}', favoriteTeam ?? '');
};

const setItemsTitle = (items: carouselBase[] = []): carouselBase[] => {
  //GSP-3461: CMS title field is for editorial use only, the FE code should use headline if present.
  return items.map((item) => Object.assign({}, item, { title: item?.headline || item.title }));
};
