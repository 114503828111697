import React, { memo } from 'react';
import { Image, ImageBackground, Text, View } from 'react-native';
import { CardProps } from '@warnermmedia/gsp-core/sdk/ui';
import { getStyles } from './featuredCard.styles';
import { carouselBase, getImageWithStyle, useCardItemImage } from '@warnermmedia/gsp-core/brands/estadio/feature';

const FeaturedCard = ({ item, breakpoints, colors, device }: CardProps<carouselBase>) => {
  const styles = getStyles(breakpoints, device.isTv, colors);
  const { getCardImageSource } = useCardItemImage();
  const itemImage = item?.image ? getImageWithStyle(item.image ?? '') : null;
  const image = getCardImageSource({ image: itemImage });
  const background = getCardImageSource({
    image: getImageWithStyle(item.image),
  });

  return (
    <View>
      <View>
        <ImageBackground source={background} style={styles.backgroundImage}>
          {image ? <Image source={image} resizeMethod="auto" resizeMode="contain" /> : null}
        </ImageBackground>
        <Text style={styles.title}>{item.title}</Text>
      </View>
    </View>
  );
};

export default memo(FeaturedCard);
