import React from 'react';
import { Image, ImageBackground, ImageSourcePropType, View } from 'react-native';
import { AccountDetailsProps, GenericAccountDetails, TextContent } from '@warnermmedia/gsp-core/sdk/ui';
import { getStyles } from './accountDetails.styles';
import { breakpointsStateStore, UserAccount } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { useReactiveVar } from '@apollo/client';
import { Preference } from '@warnermmedia/gsp-core/sdk/data-access';
import { languageStrings } from '@warnermmedia/gsp-core/brands/estadio/feature';
import { useTheme } from 'react-native-paper';

export interface Props {
  userData: UserAccount;
  toggleEditForm: () => void;
  favoriteTeamLogo: ImageSourcePropType | null;
  userPreferences?: Preference[];
  isTveAccount: boolean;
}

export const AccountDetailsComponent = (props: Props) => {
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const { colors } = useTheme();
  const styles = getStyles(breakpoints, colors);
  const language = languageStrings.default;

  const handleEditAccountInfo = () => {
    props.toggleEditForm();
  };

  const renderTeamComponent = () => {
    const { favoriteTeam, favoriteTeamId } = props.userData;
    const teamLogo = favoriteTeam && favoriteTeamId ? props.favoriteTeamLogo : null;
    return (
      <View>
        {teamLogo ? (
          <ImageBackground source={teamLogo} style={styles.background}>
            <View style={styles.overlay} />
            <Image source={teamLogo} style={styles.teamLogo} />
          </ImageBackground>
        ) : null}
        <TextContent style={styles.detailValue}>{favoriteTeam ? favoriteTeam : '-'}</TextContent>
      </View>
    );
  };

  const accountProps: AccountDetailsProps<UserAccount> = {
    subTitle: language.accountInfoTitle,
    details: props.userData,
    detailsOptions: {
      id: {
        label: '',
        type: 'string',
        isHidden: true,
      },
      firstName: {
        label: language.firstName,
        type: 'string',
        isHidden: props.isTveAccount,
      },
      lastName: {
        label: language.lastName,
        type: 'string',
        isHidden: props.isTveAccount,
      },
      email: {
        label: 'Email',
        type: 'string',
        isHidden: props.isTveAccount,
      },
      gender: {
        label: language.genderText,
        type: 'string',
        isHidden: props.isTveAccount,
      },
      birthdate: {
        label: language.birthdateText,
        type: 'date',
        isHidden: props.isTveAccount,
      },
      rut: {
        label: 'RUT',
        type: 'string',
        isHidden: props.isTveAccount,
      },
      countryOfResidence: {
        label: language.countryText,
        type: 'string',
        isHidden: props.isTveAccount,
      },
      alternativeEmail: {
        label: language.alternativeEmail,
        type: 'string',
        isHidden: props.isTveAccount,
      },
      phone: {
        label: language.phone,
        type: 'phone',
        isHidden: props.isTveAccount,
      },
      password: {
        label: language.password,
        type: 'password',
        isHidden: props.isTveAccount,
      },
      favoriteTeam: {
        label: language.favoriteTeamLabel,
        type: 'image',
        // This line need to be removed when we get the api to save the TVE user preferences
        isHidden: props.isTveAccount,
        overrideRenderDetail: renderTeamComponent,
      },
    },
    // This change will hide the button till we get the api to save the TVE user preferences
    actionBtnText: !props.isTveAccount ? language.editMyAccountBtnTex : '',
    handleActionBtnPress: handleEditAccountInfo,
    disabled: !props.userPreferences,
  };

  return (
    <GenericAccountDetails
      detailsStyle={styles.detailStyle}
      detailStyle={styles.detail}
      titleStyle={styles.titleStyle}
      subTitleStyle={styles.subTitleStyle}
      detailLabelStyle={styles.detailLabel}
      detailValueStyle={styles.detailValue}
      actionBtnStyle={styles.actionBtn}
      actionBtnTextStyle={styles.actionBtnText}
      wrapperStyle={styles.accountWrapper}
      {...accountProps}
    />
  );
};

export default AccountDetailsComponent;
