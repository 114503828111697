import { CustomCheckbox, TextContent, Tooltip } from '@warnermmedia/gsp-core/sdk/ui';
import React from 'react';
import { Linking, View, ViewStyle } from 'react-native';
import { breakpointsStateStore } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { getStyles } from './termsCheckbox.styles';
import { languageStrings } from '@warnermmedia/gsp-core/brands/estadio/feature';
import { useReactiveVar } from '@apollo/client';
import { useTheme } from 'react-native-paper';

const termsLink = `https://estadiocl.zendesk.com/hc/es/articles/360055102712-T%C3%89RMINOS-Y-CONDICIONES-DE-USO-DE-ESTADIO-TNT-SPORTS`;

interface Props {
  acceptTerms: boolean;
  setAcceptTerms: (term: boolean) => void;
  handleSetFormError: (label: string, val: boolean) => void;
  hasError: boolean;
  overrideCheckboxStyle?: ViewStyle;
  termsText?: string;
  overrideWrapperStyle?: ViewStyle;
  tooltipText?: string;
  overrideContainerStyle?: ViewStyle;
  disabled?: boolean;
  focusable?: boolean;
  focusKey?: string;
}

export const TermsCheckbox = ({
  acceptTerms,
  setAcceptTerms,
  handleSetFormError,
  hasError,
  overrideCheckboxStyle,
  termsText,
  overrideWrapperStyle,
  tooltipText,
  overrideContainerStyle,
  disabled = false,
  focusable = true,
  focusKey,
}: Props) => {
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const { colors } = useTheme();
  const styles = getStyles(breakpoints, colors);

  return (
    <View style={[styles.termsContainer, overrideContainerStyle]}>
      <View style={[styles.fieldGrpWrapper, styles.termsWrapper, overrideWrapperStyle]}>
        <CustomCheckbox
          checkboxStyle={[styles.checkbox, overrideCheckboxStyle as ViewStyle, hasError ? styles.checkboxError : {}]}
          isChecked={acceptTerms}
          handlePress={() => {
            if (!acceptTerms) {
              handleSetFormError('acceptTerms', false);
            }
            setAcceptTerms(!acceptTerms);
          }}
          disabled={disabled}
          focusable={focusable}
          focusKey={focusKey}
        />
        <TextContent style={styles.termsText} onPress={() => Linking.openURL(termsLink)}>
          {termsText || languageStrings.default.termsText}
        </TextContent>
      </View>
      <Tooltip
        tooltipText={tooltipText || languageStrings.default.tooltipTermsText}
        visible={hasError}
        opacity={1}
        position="left"
      />
    </View>
  );
};

export default TermsCheckbox;
