import React, { useContext, useEffect } from 'react';
import { View } from 'react-native';
import { useReactiveVar } from '@apollo/client';
import { MessageDisplay } from '@warnermmedia/gsp-core/sdk/ui';
import {
  breakpointsStateStore,
  HistoryContext,
  StatusMsgState,
  statusMsgStore,
} from '@warnermmedia/gsp-core/brands/estadio/data-access';

import { getStyles } from './statusMessage.styles';
import { useIsMountedRef, useStatusMessage } from '@warnermmedia/gsp-core/brands/estadio/feature';
import get from 'lodash/get';

export const StatusMessage = () => {
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const styles = getStyles(breakpoints);
  const historyContext = useContext(HistoryContext);
  const location = historyContext?.ready ? historyContext?.useLocation() : null;

  const isMountedRef = useIsMountedRef();
  const statusStore = useReactiveVar<StatusMsgState>(statusMsgStore);
  const { cleanStatusMessage } = useStatusMessage();

  useEffect(() => {
    if (isMountedRef.current && location?.pathname) {
      const cleanStatus = get(location, 'state.cleanStatusMessage', true);
      cleanStatus && cleanStatusMessage();
    }
  }, [isMountedRef, location?.pathname]);

  return statusStore.message ? (
    <View style={styles.statusContainerWrapper}>
      <MessageDisplay
        variant={statusStore.type}
        textStyle={styles.statusLabel}
        displayMessage={statusStore.message}
        wrapperStyle={styles.statusContainerStyle}
      />
    </View>
  ) : null;
};
