import { useEffect, useState } from 'react';
import {
  EMPTY_TVE_TOOLBOX_CONFIG,
  ErrorMsgState,
  loadData,
  PreAuthzs,
  saveData,
  TVE_URN_PARAM,
  USER_PREAUTZS,
  useStrapii,
  useTveToolbox,
} from '@warnermmedia/gsp-core/sdk/data-access';
import { AxiosError } from 'axios';
import { ReactiveVar } from '@apollo/client';

export interface UseVideoResponse {
  token: string;
  loading: boolean;
  getNewVideoToken(): Promise<string>;
}

export interface UseVideoParams {
  mediaId: string;
  token: string;
  errorMsgStore: ReactiveVar<ErrorMsgState>;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  appConfig: any;
  isTveAuth: boolean;
  tenant: string;
  languageStrings?: any;
  isFreeVideo?: boolean;
}

export const getUserPreauthorization = () => {
  const preauthzs = loadData(USER_PREAUTZS);
  if (preauthzs) {
    return preauthzs;
  }
  return null;
};

interface TokenResponse {
  token: string;
}

export const useInitVideo = ({
  mediaId,
  token,
  errorMsgStore,
  appConfig,
  isTveAuth,
  tenant,
  languageStrings = { default: {} },
  isFreeVideo = false,
}: UseVideoParams): UseVideoResponse => {
  const [preAuthz, setPreAuthz] = useState<PreAuthzs[] | null>(null);
  const [response, setResponse] = useState<string>('pending');
  const [loading, setLoading] = useState<boolean>(false);
  const urls = appConfig?.appConfig.apis;
  const tveConfig = appConfig?.appConfig.tve;
  const [apis, setApis] = useState<any | undefined>(urls);
  const strap = {
    regwallPreauth: `${apis?.daltonAPI}regwall/api/1/regwall/preauthorize`,
    authzToken: `${apis?.strappiAPI}strapii/api/1/authz/token`,
    authzView: `${apis?.strappiAPI}strapii/api/1/authz/view`,
  };
  const { getPreAuths, makeStrapiiCall } = useStrapii(strap, tenant, token);
  const { getTveVideoToken } = useTveToolbox(tveConfig ?? EMPTY_TVE_TOOLBOX_CONFIG);

  const getPreAuthz = (): void => {
    const { preAuthz } = getUserPreauthorization() || {};
    if (preAuthz && preAuthz[0]?.expirationTime && new Date(preAuthz[0]?.expirationTime) > new Date()) {
      setPreAuthz(preAuthz);
    } else {
      getPreAuthorization();
    }
  };

  const initToken = async (mediaId: string) => {
    if (isTveAuth) {
      const tveTokenResponse = await getVideoTokenFromTve([mediaId]);

      setResponse(Array.isArray(tveTokenResponse) ? tveTokenResponse[0].token : tveTokenResponse || 'error');
    } else {
      getPreAuthz();
    }
  };

  useEffect(() => {
    if (!isFreeVideo) {
      if (mediaId !== '') {
        initToken(mediaId);
      } else {
        errorMsgStore({
          type: 'AXIOS_ERROR',
          message: languageStrings?.default?.cantPlayContentError,
        });
      }
    }
  }, [mediaId, errorMsgStore, languageStrings, isFreeVideo]);

  useEffect(() => {
    if (preAuthz && !isFreeVideo) makeStrapCall(preAuthz, [mediaId]);
  }, [preAuthz, mediaId, isFreeVideo]);

  useEffect(() => {
    if (appConfig && appConfig.appConfig.apis) {
      setApis(appConfig.appConfig.apis);
    }
  }, [appConfig]);

  useEffect(() => {
    if (response) {
      setLoading(false);
    }
  }, [response]);

  const savePreAuthorization = (key: string, preAuthz: PreAuthzs[]) => {
    saveData(key, { preAuthz });
  };

  /**
   * get user preAuthorizedEntitlements
   */
  const getPreAuthorization = async () => {
    setLoading(true);
    await getPreAuths(token)
      .then((res) => {
        const response = res as PreAuthzs[] | null;
        if (response && response.length) {
          setPreAuthz(response);
          savePreAuthorization(USER_PREAUTZS, response);
        } else {
          setResponse('error , no preauthorization for user');
          errorMsgStore({
            type: 'AXIOS_ERROR',
            message: languageStrings?.default?.contentLockError,
          });
        }
      })
      .catch((err) => {
        const error = err as AxiosError;
        console.log(error, 'getPreAuthorisation error');
        setResponse('error in getPreAuthorization call');
        errorMsgStore({
          type: 'AXIOS_ERROR',
          message: languageStrings?.default?.cantPlayContentError,
        });
      });
  };

  /**
   * check if a user has access to view content/events.
   * @param preAuths
   * @param mediaIds
   */
  const makeStrapCall = async (preAuths: PreAuthzs[], mediaIds: string[]) => {
    try {
      setLoading(true);
      const ids = mediaIds.filter((id) => !!id);
      if (ids.length) {
        const response = await makeStrapiiCall(preAuths, ids);
        if (response.length) {
          setResponse(response[0].token);
        } else {
          setResponse('error in strappi call');
          errorMsgStore({
            type: 'MEDIA_ERROR',
            message: languageStrings?.default?.noAccessError,
          });
        }
      } else {
        setResponse('no mediaID for strapi');
      }
    } catch (error) {
      setResponse('error in strappi call');
      errorMsgStore({
        type: 'AXIOS_ERROR',
        message: languageStrings?.default?.cantPlayContentError,
      });
    }
  };

  const getVideoTokenFromTve = async (mediaIds: string[]): Promise<TokenResponse[] | string> => {
    try {
      const response = await getTveVideoToken({
        accessToken: token,
        mediaId: mediaIds[0],
        urn: tveConfig.urnParam ?? TVE_URN_PARAM,
        userId: 'user-a',
        network: tenant,
        requestor: tenant,
        mvpd: tenant,
      });

      if (response.success) {
        return [{ token: response.videoToken }];
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      try {
        const response = await getTveVideoToken({
          accessToken: token,
          mediaId: mediaIds[0],
          urn: tveConfig.urnParam ?? TVE_URN_PARAM,
          userId: 'user-a',
          network: tenant,
          requestor: tenant,
          mvpd: tenant,
        });

        if (response.success) {
          return [{ token: response.videoToken }];
        } else {
          errorMsgStore({
            type: 'AXIOS_ERROR',
            message: languageStrings?.default?.cantPlayContentError,
          });

          return response.error || 'error';
        }
      } catch (error) {
        errorMsgStore({
          type: 'AXIOS_ERROR',
          message: languageStrings?.default?.cantPlayContentError,
        });
        return 'error';
      }
    }
  };

  /**
   * @function getNewVideoToken
   * @returns string
   * @description get a new video token
   */
  const getNewVideoToken = async (): Promise<string> => {
    try {
      const mediaIds = [mediaId];
      const ids = mediaIds.filter((id) => !!id);
      if (ids.length) {
        const response = (await (isTveAuth
          ? getVideoTokenFromTve(ids)
          : makeStrapiiCall(preAuthz, ids))) as TokenResponse[];
        if (Array.isArray(response)) {
          return response[0].token;
        }
        return response;
      }
      return '';
    } catch (error) {
      return '';
    }
  };

  return {
    token: response,
    loading,
    getNewVideoToken,
  };
};
