import merge from 'lodash/merge';
import { StyleProp, StyleSheet, ViewStyle } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';

interface Styles {
  pageContainer: ViewStyle;
}

export const getStyles = (breakpoints: Breakpoints, colors: ReactNativePaper.ThemeColors) => {
  const base: StyleProp<Styles> = {
    pageContainer: {
      backgroundColor: colors.tenantBackground.dark.surface02,
    },
  };

  const mediaQueries =
    breakpoints.currentBreakpoints.isMedLg ||
    breakpoints.currentBreakpoints.isLgXl ||
    breakpoints.currentBreakpoints.isXlXxl ||
    breakpoints.currentBreakpoints.isXxl
      ? ({
          pageContainer: {
            justifyContent: 'center',
          },
        } as StyleProp<Styles>)
      : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
