import { ActivityIndicator, StyleProp, TextProps, TextStyle } from 'react-native';
import React from 'react';

interface LoadingAppIndicatorProps extends TextProps {
  style?: StyleProp<TextStyle>;
}

export function LoadingAppIndicator({ style = {} }: LoadingAppIndicatorProps) {
  return <ActivityIndicator size="large" color="#999999" style={[style, { padding: 20 }]} />;
}

export default LoadingAppIndicator;
