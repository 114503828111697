import merge from 'lodash/merge';
import { StyleProp, StyleSheet, ViewStyle } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';

interface Styles {
  arrow: ViewStyle;
  forwardArrow: ViewStyle;
  arrowInner: ViewStyle;
  backArrow: ViewStyle;
}

export const getStyles = (breakpoints: Breakpoints) => {
  const base: StyleProp<Styles> = {
    arrow: {
      zIndex: 300,
    },
    arrowInner: {
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
    },
    forwardArrow: {
      position: 'absolute',
      height: 0,
      paddingRight: 9,
      right: 40,
      zIndex: 102,
    },
    backArrow: {
      position: 'absolute',
      height: 0,
      paddingRight: 9,
      paddingLeft: 9,
      left: 40,
      zIndex: 102,
    },
  };

  const mediaQueries =
    breakpoints.currentBreakpoints.isTiny ||
    breakpoints.currentBreakpoints.isTnySm ||
    breakpoints.currentBreakpoints.isSmMed
      ? ({
          forwardArrow: {
            right: 40,
            paddingRight: 9,
          },
          backArrow: {
            left: 40,
          },
        } as StyleProp<Styles>)
      : breakpoints.currentBreakpoints.isMedLg || breakpoints.windowWidth === breakpoints.breakpointSizes.lg
      ? ({
          forwardArrow: {
            right: 45,
            paddingRight: 9,
          },
          backArrow: {
            left: 45,
          },
        } as StyleProp<Styles>)
      : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
