import React from 'react';
import { ImageStyle } from 'react-native';
import Svg, { Path } from 'react-native-svg';

export interface CheckMarkProps {
  width?: number | string;
  height?: number | string;
  fill?: string;
  overideStyle?: ImageStyle;
}

export function CheckMark(props: CheckMarkProps) {
  const width = props.width || 15;
  const height = props.height || 15;
  const fill = props.fill || '#000000';
  return (
    <Svg width={width} height={height} fill="none" viewBox="0 0 24 24" style={props.overideStyle}>
      <Path
        d="M9.00001 20.42L2.79001 14.21L5.62001 11.38L9.00001 14.77L18.88 4.88L21.71 7.71L9.00001 20.42Z"
        fill={fill}
      />
    </Svg>
  );
}

export default CheckMark;
