import { StyleProp, StyleSheet, TextStyle, ViewStyle } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';
import { BreakpointsSize, ScaleAction } from '@warnermmedia/gsp-core/brands/estadio/feature';

interface Styles {
  titleStyle?: TextStyle;
  descriptionStyle?: TextStyle;
  buttonStyle?: ViewStyle;
  buttonText?: TextStyle;
  textContainer?: ViewStyle;
  empty?: ViewStyle;
  loginBtn?: ViewStyle;
  wrapper?: ViewStyle;
}

export const getStyles = (breakpoints: Breakpoints, scaling: ScaleAction, colors: ReactNativePaper.ThemeColors) => {
  const { scaleHeight, scaleWidth } = scaling;
  const base: StyleProp<Styles> = {
    titleStyle: {
      fontFamily: 'Oswald-Regular',
      fontWeight: '600',
      fontSize: scaleHeight(55, BreakpointsSize.Tv),
      lineHeight: scaleHeight(82, BreakpointsSize.Tv),
      textTransform: 'uppercase',
      color: colors.keyboardFocus.light,
      marginTop: scaleHeight(191, BreakpointsSize.Tv),
      marginBottom: scaleHeight(8, BreakpointsSize.Tv),
      width: scaleWidth(828, BreakpointsSize.Tv),
      height: scaleHeight(82, BreakpointsSize.Tv),
    },
    descriptionStyle: {
      fontFamily: 'Oswald-Regular',
      fontWeight: '400',
      fontSize: scaleHeight(32, BreakpointsSize.Tv),
      lineHeight: scaleHeight(38, BreakpointsSize.Tv),
      textTransform: 'uppercase',
      color: colors.keyboardFocus.light,
      marginBottom: scaleHeight(280, BreakpointsSize.Tv),
      width: scaleWidth(546, BreakpointsSize.Tv),
      height: scaleHeight(76, BreakpointsSize.Tv),
    },
    buttonStyle: {
      height: scaleHeight(54, BreakpointsSize.Tv),
      borderColor: colors.keyboardFocus.light,
      borderWidth: scaleWidth(2, BreakpointsSize.Tv),
      borderRadius: scaleHeight(4, BreakpointsSize.Tv),
      backgroundColor: 'transparent',
      marginVertical: scaleHeight(0.5, BreakpointsSize.Tv),
      marginHorizontal: scaleWidth(0.5, BreakpointsSize.Tv),
      width: scaleWidth(545, BreakpointsSize.Tv),
    },
    buttonText: {
      fontFamily: 'Oswald-Regular',
      fontWeight: '500',
      fontSize: scaleHeight(24, BreakpointsSize.Tv),
      lineHeight: scaleHeight(36, BreakpointsSize.Tv),
      textAlign: 'center',
      textTransform: 'uppercase',
    },
    textContainer: {
      flexDirection: 'column',
      flex: 1,
    },
    empty: {
      flex: 1,
    },
    wrapper: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  };
  return StyleSheet.create(base);
};
