import React, { useRef } from 'react';
import { Image, ImageStyle, PressableProps, TextStyle, View, ViewStyle } from 'react-native';
import styles from './button.style';
import { TextContent } from '../../textcontent';
import { PressableWithOpacity } from '../pressableWithOpacity/pressableWithOpacity';
import { getUniqueId, isTickerAvailable } from '@warnermmedia/gsp-core/brands/estadio/feature';
import { FocusKeys, ItemName } from '@warnermmedia/gsp-core/sdk/data-access';
import { useSpatialNavigation } from '../../spatialNavigation';
import { useGetDevice } from '../../../hooks';

export interface ButtonProps extends PressableProps {
  btnStyle?: ViewStyle | ViewStyle[];
  mode?: 'text' | 'contained' | 'outline';
  label?: string;
  labelStyle?: TextStyle | TextStyle[];
  icon?: string | JSX.Element;
  iconStyle?: ImageStyle;
  focusKey?: string;
  iconPosition?: 'right' | 'left';
}

export const CustomButton = ({
  btnStyle,
  mode,
  label,
  labelStyle,
  icon,
  iconStyle,
  children,
  focusKey,
  focusable = true,
  disabled,
  iconPosition = 'right',
  ...props
}: ButtonProps) => {
  const { isTv } = useGetDevice();
  const getModeStyle = () => {
    switch (mode) {
      case 'text':
        return styles.textBtn;
      case 'contained':
        return styles.containedBtn;
      case 'outline':
        return styles.outlineBtn;
      default:
        return styles.containedBtn;
    }
  };

  const containedBtnLabelStyle = mode === 'contained' ? styles.containedBtnLabel : {};
  const buttonFocusKey = useRef(focusKey ?? getUniqueId(ItemName.BUTTON_ITEM)).current;
  const { ref, focusSelf, setFocusOnNavbar } = useSpatialNavigation({
    focusKey: buttonFocusKey,
    focusable: focusable && !disabled,
    scrollIntoViewProps:
      focusKey === ItemName.REGISTER_BUTTON_ITEM
        ? { behavior: 'smooth', block: 'nearest', inline: 'end' }
        : { behavior: 'smooth', inline: 'center', block: 'center' },
    onArrowPress: (direction) => {
      if (
        (direction === 'up' || direction === 'right') &&
        [FocusKeys.HEADER_LOGIN_BUTTON].includes(focusKey as FocusKeys)
      ) {
        focusSelf();
        return false;
      }
      if (
        (direction === 'left' || direction === 'up') &&
        [FocusKeys.PIN_LOGIN, FocusKeys.PIN_LOGIN_BACK].includes(focusKey as FocusKeys)
      ) {
        focusSelf();
        return false;
      }
      if (
        (direction === 'left' || direction === 'right') &&
        [
          FocusKeys.PIN_LOGIN,
          FocusKeys.PIN_LOGIN_ERROR,
          FocusKeys.PIN_LOGIN_UPDATE_CODE,
          FocusKeys.PIN_LOGIN_BACK,
          FocusKeys.PIN_LOGIN_REGISTER,
        ].includes(focusKey as FocusKeys)
      ) {
        focusSelf();
        return false;
      }

      if (
        direction === 'down' &&
        [FocusKeys.PIN_LOGIN_ERROR, FocusKeys.PIN_LOGIN_UPDATE_CODE, FocusKeys.PIN_LOGIN_REGISTER].includes(
          focusKey as FocusKeys
        )
      ) {
        focusSelf();
        return false;
      }

      if (direction === 'left' && [FocusKeys.PROFILE, FocusKeys.PROFILE_LOGOUT].includes(focusKey as FocusKeys)) {
        setFocusOnNavbar();
      }

      if (isTv && direction === 'up' && focusKey === FocusKeys.PROFILE && !isTickerAvailable()) {
        focusSelf();
        return false;
      }

      return true;
    },
  });

  const renderIcon = () =>
    typeof icon === 'string' ? (
      <Image source={{ uri: icon }} style={[styles.icon, iconStyle]} />
    ) : (
      <View style={iconStyle}>{icon}</View>
    );

  return (
    <PressableWithOpacity
      overrideStyle={[getModeStyle(), styles.button, btnStyle as ViewStyle]}
      ref={ref}
      {...props}
      nativeID={buttonFocusKey}
    >
      {icon && iconPosition !== 'right' && renderIcon()}
      {label ? (
        <TextContent accessible={false} style={[styles.label, containedBtnLabelStyle, labelStyle]}>
          {label}
        </TextContent>
      ) : (
        children
      )}
      {icon && iconPosition === 'right' && renderIcon()}
    </PressableWithOpacity>
  );
};

export default CustomButton;
