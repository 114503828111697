import React, { useCallback, useContext } from 'react';
import { View } from 'react-native';
import { FooterData, HistoryContext } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { getStyles } from './footer.styles';
import PrivacyPreferenceCenter from './privacyPreferenceCenter/privacyPreferenceCenter';
import { Breakpoints, TextContent } from '@warnermmedia/gsp-core/sdk/ui';
import { FooterLink } from './footerLink';
import { FocusKeys } from '@warnermmedia/gsp-core/sdk/data-access';

export interface MiddleProps {
  links: FooterData[];
  breakpoints: Breakpoints;
  colors: ReactNativePaper.ThemeColors;
  isUserLoggedIn: boolean;
  isNavMobile: boolean;
}

export const FooterMiddle = (props: MiddleProps) => {
  const historyContext = useContext(HistoryContext);
  const history = historyContext?.ready ? historyContext?.useHistory() : null;
  const styles = getStyles(props.breakpoints, props.colors);

  const onPress = useCallback(
    (item) => {
      if (item.callback) {
        item.callback(item.link);
      }
      if (item.link) {
        history?.push(item.link);
      }
    },
    [history]
  );

  return (
    <View style={styles.linkContainer}>
      {props.links.map((item, index) => {
        return (
          <FooterLink
            key={item.id}
            index={index}
            focusKey={item.title}
            overrideStyle={styles.linkStyle}
            {...(item.id !== 4 ? { onPress: () => onPress(item) } : {})}
            focusStyle={styles.focusStyle}
            leftFocusKey={props.isUserLoggedIn ? FocusKeys.SIDEBAR : null}
            isNavMobile={props.isNavMobile}
          >
            {item.id === 4 ? (
              <PrivacyPreferenceCenter link={item} />
            ) : (
              <TextContent style={[styles.itemTitleStyle, index === 0 ? styles.itemStyleNoBorder : {}]}>
                {item.title}
              </TextContent>
            )}
          </FooterLink>
        );
      })}
    </View>
  );
};
