import { useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { UseFocusableConfig, UseFocusableResult } from '@noriginmedia/norigin-spatial-navigation/dist/useFocusable';
import { useCallback, useContext } from 'react';
import {
  FocusableComponentLayout,
  FocusDetails,
  KeyPressDetails,
} from '@noriginmedia/norigin-spatial-navigation/dist/SpatialNavigation';
import { FocusKeys } from '@warnermmedia/gsp-core/sdk/data-access';
import {
  hasPrevFocusKey,
  isElementInViewport,
  setRouseLastFocusedKey,
} from '@warnermmedia/gsp-core/brands/estadio/feature';
import { useGetDevice } from '../../hooks';
import { useReactiveVar } from '@apollo/client';
import { breakpointsStateStore, HistoryContext } from '@warnermmedia/gsp-core/brands/estadio/data-access';

export interface SpatialNavigationProps<T> extends UseFocusableConfig<T> {
  scrollIntoViewProps?: ScrollIntoViewOptions;
}

interface SpatialNavigationResult<T> extends UseFocusableResult {
  onGridCardArrowPress: (
    direction: string,
    props: T,
    details: KeyPressDetails,
    index: number,
    parentFocusKey?: string,
    dataLength?: number
  ) => boolean;

  onCarouselCardArrowPress: (
    direction: string,
    props: T,
    details: KeyPressDetails,
    index: number,
    parentFocusKey?: string,
    dataLength?: number
  ) => boolean;
  setFocusOnNavbar: (focusKey?: string) => void;
}

export function useSpatialNavigation<T>({
  focusable = true,
  saveLastFocusedChild = false,
  autoRestoreFocus = true,
  trackChildren = false,
  isFocusBoundary,
  focusKey: propFocusKey,
  preferredChildFocusKey,
  onEnterPress,
  onEnterRelease,
  onArrowPress = () => true,
  onFocus,
  onBlur,
  extraProps,
  scrollIntoViewProps = { behavior: 'smooth', inline: 'center', block: 'center' },
}: SpatialNavigationProps<T>): SpatialNavigationResult<T> {
  const { isPwa, isTv, isWeb } = useGetDevice();
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const navMobile = !isPwa || (breakpoints.windowWidth <= breakpoints.breakpointSizes.lg && !isTv);
  const historyContext = useContext(HistoryContext);
  const location = historyContext?.ready ? historyContext?.useLocation() : null;

  const getOnFocus = useCallback(
    (layout: FocusableComponentLayout, props: T, details: FocusDetails): void => {
      const elem = document?.getElementById(layout?.node?.id ?? propFocusKey) as HTMLElement;
      if (elem && !isElementInViewport(elem)) {
        elem.scrollIntoView(scrollIntoViewProps);
      }

      onFocus?.(layout, props, details);
    },
    [onFocus, scrollIntoViewProps, propFocusKey]
  );

  const getOnArrowPress = useCallback(
    (direction: string, props: T, details: KeyPressDetails): boolean => {
      if (direction && document && document?.activeElement && isWeb) {
        (document?.activeElement as HTMLElement).blur?.();
      }
      return onArrowPress?.(direction, props, details);
    },
    [onArrowPress, isWeb]
  );

  const {
    ref,
    focusSelf,
    focused,
    hasFocusedChild,
    focusKey,
    setFocus,
    navigateByDirection,
    pause,
    resume,
    updateAllLayouts,
    getCurrentFocusKey,
  } = useFocusable({
    focusKey: propFocusKey,
    onBlur,
    focusable: focusable && !!propFocusKey && /[A-Z]/.test(propFocusKey),
    extraProps,
    onEnterPress,
    onEnterRelease,
    trackChildren,
    autoRestoreFocus,
    isFocusBoundary,
    saveLastFocusedChild,
    preferredChildFocusKey,
    onFocus: getOnFocus,
    onArrowPress: getOnArrowPress,
  });

  const setFocusOnNavbar = useCallback(
    (key?: string): void => {
      setFocus(FocusKeys.SIDEBAR);
      setRouseLastFocusedKey(key ?? propFocusKey, location?.pathname);
    },
    [setFocus, propFocusKey, location]
  );

  const onGridCardArrowPress = useCallback(
    (
      direction: string,
      props: T,
      details: KeyPressDetails,
      index: number,
      parentFocusKey?: string,
      dataLength?: number
    ): boolean => {
      const elem = document?.getElementById(propFocusKey ?? '') as HTMLElement;
      const activeElementOffsetLeft = (elem as HTMLElement)?.offsetLeft;
      const activeElementOffsetTop = (elem as HTMLElement)?.offsetTop;
      const nextElementSiblingOffsetLeft = (elem?.nextElementSibling as HTMLElement)?.offsetLeft;
      const isFirstElement = index === 0 || activeElementOffsetLeft === 0;

      if (direction === 'right' && dataLength === index) {
        focusSelf();
        return false;
      }

      if (direction === 'right' && nextElementSiblingOffsetLeft === 0) {
        focusSelf();
      }

      if (direction === 'left' && isFirstElement && !navMobile) {
        setFocusOnNavbar();
      }

      if (direction === 'left' && isFirstElement && navMobile) {
        focusSelf();
        return false;
      }

      if (direction === 'up' && parentFocusKey && !hasPrevFocusKey(parentFocusKey) && activeElementOffsetTop === 0) {
        focusSelf();
        return false;
      }

      return true;
    },
    [focusSelf, navMobile, propFocusKey, setFocusOnNavbar]
  );

  const onCarouselCardArrowPress = useCallback(
    (
      direction: string,
      props: T,
      details: KeyPressDetails,
      index: number,
      parentFocusKey?: string,
      dataLength?: number
    ): boolean => {
      if (direction === 'left' && index === 0 && !navMobile) {
        setFocusOnNavbar();
      }

      if (direction === 'left' && index === 0 && navMobile) {
        focusSelf();
        return false;
      }

      if (direction === 'right' && dataLength === index) {
        focusSelf();
        return false;
      }

      if (direction === 'up' && parentFocusKey && !hasPrevFocusKey(parentFocusKey)) {
        focusSelf();
        return false;
      }
      return true;
    },
    [focusSelf, navMobile, setFocusOnNavbar]
  );

  return {
    ref,
    focusSelf,
    focused,
    hasFocusedChild,
    focusKey,
    setFocus,
    navigateByDirection,
    pause,
    resume,
    updateAllLayouts,
    getCurrentFocusKey,
    setFocusOnNavbar,
    onGridCardArrowPress,
    onCarouselCardArrowPress,
  };
}
