import { StyleSheet } from 'react-native';
import { Sizes } from '@warnermmedia/gsp-core/brands/estadio/feature';

export const getStyles = (navMobile: boolean, isWeb: boolean, isVideoPage = false) => {
  const styles = StyleSheet.create({
    navContain: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: corretion for web
      position: !isWeb ? 'absolute' : 'fixed',
      display: isVideoPage && !navMobile ? 'none' : 'flex',
      top: 0,
      left: 0,
      zIndex: 9999999,
      height: isWeb ? '100vh' : 'auto',
    },
    navWebMediumWidth: {
      width: '100%',
      maxWidth: 300,
    },
    navWebFullWidth: {
      maxWidth: 375,
      width: '100%',
    },
    navHeader: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    navHeaderLeft: {},
    navHeaderRight: {
      margin: 0,
      top: 0,
    },
    navBarScroll: {
      marginBottom: 100,
    },
    viewStyle: {
      paddingVertical: 8,
      paddingHorizontal: 27,
      marginVertical: 2,
    },
    labelStyle: {
      fontFamily: 'Oswald-Bold',
      fontSize: 20,
      textTransform: 'uppercase',
      paddingLeft: 20,
    },
    collapsedHeader: {
      paddingBottom: 43,
      paddingHorizontal: 15,
    },
    expandedHeader: {
      paddingBottom: 43,
      paddingHorizontal: 15,
    },
  });

  return styles;
};
