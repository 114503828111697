import React from 'react';
import { Image, View } from 'react-native';
import { TextContent, useGetDevice } from '@warnermmedia/gsp-core/sdk/ui';
import { breakpointsStateStore } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { getStyles } from './tournamentHeader.styles';
import { useReactiveVar } from '@apollo/client';
import { ImageAssets } from '@warnermmedia/gsp-core/brands/estadio/assets';
import {
  getImageArray,
  getImageCardWidth,
  getImageWithStyle,
  useCardItemImage,
} from '@warnermmedia/gsp-core/brands/estadio/feature';

export interface TournamentHeaderProps {
  title: string;
  imageUrl: string;
  description: string;
}

export const TournamentHeader = ({ title, imageUrl, description }: TournamentHeaderProps) => {
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const { isTv } = useGetDevice();
  const styles = getStyles(breakpoints, isTv);
  const { getCardImageSource } = useCardItemImage();
  const tournamentLogo = getCardImageSource({
    fallbackName: ImageAssets.championship,
    image: imageUrl ? getImageWithStyle(getImageArray(imageUrl, getImageCardWidth(320, 160))) : null,
  });

  return (
    <View style={styles.wrapper}>
      <View style={[styles.imageContainer, styles.seriesImage]}>
        <Image source={tournamentLogo} style={styles.genericImage} resizeMethod="auto" resizeMode="contain" />
      </View>
      <View style={styles.contentContainer}>
        <TextContent style={styles.title}>{title}</TextContent>
        <TextContent style={styles.description}>{description}</TextContent>
      </View>
    </View>
  );
};

export default TournamentHeader;
