/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { useEffect, useState } from 'react';
import { useWindowDimensions } from 'react-native';
import { useIsMountedRef } from '../useIsMountedRef';

interface HideSwimlaneArrowProps {
  hideRightArrow: boolean;
  hideLeftArrow: boolean;
}

export const useHideSwimlaneArrow = (
  windowLength: number,
  slideIndex = 0,
  dataLength: number | null
): HideSwimlaneArrowProps => {
  const isMounted = useIsMountedRef();
  const [hideRightArrow, setHideRightArrow] = useState(true);
  const [hideLeftArrow, setHideLeftArrow] = useState(true);

  const { width } = useWindowDimensions();

  useEffect(() => {
    const shouldHide = dataLength ? slideIndex >= dataLength - windowLength : !dataLength;
    if (isMounted.current && hideRightArrow !== shouldHide) {
      setHideRightArrow(shouldHide);
    }
  }, [windowLength, slideIndex, dataLength, isMounted, width, hideRightArrow]);

  useEffect(() => {
    const shouldHide = dataLength ? slideIndex === 0 && slideIndex < windowLength && dataLength > 0 : !dataLength;
    if (isMounted.current && hideLeftArrow !== shouldHide) {
      setHideLeftArrow(shouldHide);
    }
  }, [windowLength, slideIndex, dataLength, isMounted, width, hideLeftArrow]);

  return { hideRightArrow, hideLeftArrow };
};
