import React from 'react';
import { StyleProp, ViewStyle, View, Image, ImageStyle } from 'react-native';
import { TextContent } from '../textcontent';

import styles from './screenheader.styles';

/* eslint-disable-next-line */
export interface ScreenHeaderProps {
  containerStyle?: StyleProp<ViewStyle>;
  headerTitle: string;
  headerStyle?: StyleProp<ViewStyle>;
  description?: string;
  descriptionStyle?: StyleProp<ViewStyle>;
  imageUrl?: string;
  imageStyle?: StyleProp<ImageStyle>;
  staticImage?: number;
  textContainer?: StyleProp<ViewStyle>;
}

export function ScreenHeader(props: ScreenHeaderProps) {
  return (
    <View style={[styles.wrapper, props.containerStyle]}>
      {props.staticImage || props.imageUrl ? (
        <View style={styles.imageContainer}>
          <Image
            source={props.staticImage ? props.staticImage : { uri: props.imageUrl }}
            style={[styles.imageStyle, props.imageStyle]}
          />
        </View>
      ) : null}
      <View style={[styles.textContainer, props.textContainer]}>
        <TextContent style={[styles.titleStyle, props.headerStyle]}>{props.headerTitle}</TextContent>
        {props.description ? (
          <TextContent style={[styles.description, props.descriptionStyle]}>{props.description}</TextContent>
        ) : null}
      </View>
    </View>
  );
}

export default ScreenHeader;
