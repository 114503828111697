import React from 'react';
import { Text, View } from 'react-native';
import { useReactiveVar } from '@apollo/client';
import { breakpointsStateStore, ModalType } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { getStyles } from './paymentRefreshModal.styles';
import { useGetDevice } from '@warnermmedia/gsp-core/sdk/ui';
import { useTheme } from 'react-native-paper';

import { ModalContentContainer, ModalWrapper } from '@warnermmedia/gsp-core/brands/estadio/ui';

interface ModalProps {
  visible: boolean;
  title: string;
  description: string;
}

export const PaymentRefreshModal = ({ title, description, visible }: ModalProps) => {
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const { isTv } = useGetDevice();
  const { colors } = useTheme();
  const styles = getStyles(breakpoints, isTv, colors);

  return (
    <ModalWrapper
      visible={visible}
      onClose={() => null}
      modalType={visible ? ModalType.PaymentRefreshModal : null}
      modalContentContainerType={ModalContentContainer.Full}
      modalLayoutContainerStyle={styles.fullScreenContainer}
      modalMainContainerStyle={styles.container}
    >
      <>
        <Text style={styles.title}>{title}</Text>
        <View style={styles.containerInner}>
          <Text style={styles.description}>{description}</Text>
        </View>
      </>
    </ModalWrapper>
  );
};

export default PaymentRefreshModal;
