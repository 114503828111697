import React from 'react';
import Svg, { Circle, Rect } from 'react-native-svg';

/* eslint-disable-next-line */
export interface PinProps {
  width?: number;
  height?: number;
  color?: string;
  iconColor?: string;
}

export function Pin(props: PinProps) {
  const width = props.width || 20;
  const height = props.height || 20;
  const color = props.color || '#359fd7';
  const iconColor = props.iconColor || '#fff';

  return (
    <Svg fill="none" height={height} viewBox="0 0 20 20" width={width}>
      <Circle cx="10" cy="10" r="10" fill={color} />
      <Rect width="8" height="2" x="11" y="8" fill={iconColor} rx="1" transform="rotate(90 11 8)" />
      <Circle cx="10" cy="8" r="4" fill={iconColor} />
    </Svg>
  );
}

export default Pin;
