

/**
 * From T pick props that exist in U
 */
export type Subset<T, U> = {
  [key in keyof T]: key extends keyof U ? T[key] : never;
};

/**
 * Remove from T the keys that are in common with K
 */
export type Optionalize<T extends K, K> = Omit<T, keyof K>;

/**
 * Make a Type into a Maybe Type
 */
export type Maybe<T> = T | undefined;

/**
 * Make a Type into a Nullable Type
 */
export type Nullable<T> = T | null;

/**
 * Dictionary of string, value pairs
 */
export type Dictionary<T> = { [key: string]: T };

/**
 * Extract a string from a union
 */
export type PluckString<A> = A extends string ? A : never;

// export function parseIntGaurd(value: string | null | undefined | Maybe<Scalars['Int']>): number {
export function parseIntGaurd(value: string | null | undefined): number {
  if (typeof value === 'string') {
    return parseInt(value);
  } else if (typeof value === 'number') {
    return value;
  }
  throw new Error('[parseIntGaurd] ERROR: input value is not a string');
}

// even more not awesome ...
export function stringGaurd(value: string | null | undefined): string {
  if (typeof value === 'string') {
    return value;
  }

  throw new Error('[stringGaurd] ERROR: input value is not a string');
}

export function clamp(value: number, min: number, max: number): number {
  return Math.min(Math.max(value, min), max);
}
