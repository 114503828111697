import React, { FC, useCallback, useEffect } from 'react';
import { ImageStyle, TextStyle, View, ViewStyle } from 'react-native';
import { DatePickerModal } from 'react-native-paper-dates';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { DefaultTheme, Portal, Provider as PaperProvider, useTheme } from 'react-native-paper';
import {
  CustomTextInput,
  getDate,
  isDateValid,
  Tooltip,
  useGetDevice,
  validateDatePattern,
} from '@warnermmedia/gsp-core/sdk/ui';
import { Calendar } from '@warnermmedia/gsp-core/brands/estadio/assets';
import { modalStateStore } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import styles from './datetimepicker.style';
import get from 'lodash/get';

interface DatetimepickerProps {
  date: string;
  modalInputEnabled?: boolean;
  placeholder?: string;
  inputStyle?: TextStyle | TextStyle[];
  iconStyle?: ImageStyle;
  containerStyle?: ViewStyle | ViewStyle[];
  maxLength?: number;
  dateFormat?: string;
  locale?: string;
  editable?: boolean;
  focusable?: boolean;
  errorMessage: string;
  onSelect: (value: string, isValid: boolean) => void;
  validRange?: {
    startDate?: Date;
    endDate?: Date;
  };
  shouldUseFourArrow?: boolean;
}

const DatePattern = {
  es: /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/,
  en: /^(((0)[0-9])|((1)[0-2]))(\/)([0-2][0-9]|(3)[0-1])(\/)\d{4}$/,
};

export const Datetimepicker: FC<DatetimepickerProps> = ({
  date,
  errorMessage,
  validRange,
  dateFormat = 'DD/MM/YYYY',
  locale = 'es',
  editable = true,
  focusable = true,
  modalInputEnabled = true,
  placeholder,
  inputStyle,
  iconStyle,
  containerStyle,
  maxLength,
  onSelect,
  shouldUseFourArrow,
}) => {
  const [inputDate, setInputDate] = React.useState<string>(date);
  const [calendarOpen, setCalendarOpen] = React.useState(false);
  const [inputError, setInputError] = React.useState<string>('');
  const { isIosDevice } = useGetDevice();
  const { colors } = useTheme();

  const DatePickerWrapper = isIosDevice ? Portal : View;

  const isValidateDateFormat = useCallback(
    (date: string) => {
      const localDate = getDate(date);
      const isLowInRange =
        validRange && validRange?.startDate ? getDate(validRange.startDate).diff(localDate) <= 0 : true;
      const isTopInRange = validRange && validRange?.endDate ? getDate(validRange.endDate).diff(localDate) >= 0 : true;

      return localDate.isValid() && isDateValid(date, 'MM/DD/YYYY') && isLowInRange && isTopInRange;
    },
    [validRange]
  );

  const formatDate = useCallback((date: Date | string, dateFormat: string): string => {
    const localDate = getDate(date);
    return localDate.isValid() ? localDate.format(dateFormat) : date.toString();
  }, []);

  const isValidFormat = useCallback(
    (date: string): boolean => {
      return !!validateDatePattern(date, get(DatePattern, locale, ''));
    },
    [locale]
  );

  const getDateString = useCallback(
    (date: string): string => {
      const stripped = date.split('/');
      return locale === 'es' ? `${stripped[1]}/${stripped[0]}/${stripped[2]}` : date;
    },
    [locale]
  );

  const getCalendarSelectedDate = useCallback(
    (date: string) => {
      let calendarDate = new Date();
      if (date && isValidFormat(date)) {
        calendarDate = new Date(getDateString(date));
      }
      return calendarDate;
    },
    [getDateString, isValidFormat]
  );

  const onDismissSingle = React.useCallback(() => {
    setCalendarOpen(false);
  }, [setCalendarOpen]);

  const onConfirmSingle = React.useCallback(
    (params: { date: Date | undefined }) => {
      setCalendarOpen(false);
      if (params.date) {
        const formatted = formatDate(params.date, dateFormat);
        const isValid = isValidateDateFormat(getDateString(formatted));
        setInputDate(formatted);
        setInputError(isValid ? '' : errorMessage);
        onSelect(formatted, isValid);
      }
    },
    [formatDate, isValidateDateFormat, errorMessage, onSelect, dateFormat, getDateString]
  );

  const handleInputDate = React.useCallback(
    (date?: string) => {
      const input = date ?? inputDate;
      const isValid = isValidFormat(input) && isValidateDateFormat(getDateString(input));
      setInputError(isValid ? '' : errorMessage);
      onSelect(input, isValid);
    },
    [inputDate, isValidateDateFormat, errorMessage, onSelect, getDateString, isValidFormat]
  );

  const onChangeText = React.useCallback(
    (date: string) => {
      setInputDate(date);
      handleInputDate(date);
    },
    [handleInputDate]
  );

  useEffect(() => {
    modalStateStore({
      ...modalStateStore(),
      datePickerModal: calendarOpen,
    });
  }, [calendarOpen]);

  const theme = {
    ...DefaultTheme,
    messages: {
      disabledLabelColor: '',
      disabledBackgroundColor: '#ff0085',
      success: {
        icon: 'check',
        labelColor: '#fff',
        backgroundColor: '#0b7d3a',
      },
      error: {
        icon: 'alert-circle-outline',
        labelColor: '#fff',
        backgroundColor: '#cc1f47',
      },
      warning: {
        icon: 'alert-circle-outline',
        labelColor: '#fff',
        backgroundColor: '#000A5A',
      },
      generic: {
        icon: 'alert-circle-outline',
        labelColor: '#fff',
        backgroundColor: '#ff0085',
      },
    },
    colors: {
      ...DefaultTheme.colors,
      primary: '#000A5A',
      surface: '#fff',
      onSurfaceVariant: '#fff',
      onSurface: '#000A5A',
    },
  };
  return (
    <View style={styles.inputWrapper}>
      <CustomTextInput
        value={inputDate}
        placeholder={placeholder}
        inputStyle={inputStyle}
        iconStyle={iconStyle}
        containerStyle={containerStyle}
        maxLength={maxLength}
        onChangeText={onChangeText}
        onBlur={handleInputDate}
        editable={editable}
        focusable={focusable}
        icon={
          <Calendar
            width={30}
            height={30}
            iconColor={colors.keyboardFocus.dark}
            color={colors.tenantBackground.light.surfaceBase}
            opacity={0.5}
          />
        }
        error={!!inputError}
        handleIconClick={() => setCalendarOpen(true)}
        shouldUseFourArrow={shouldUseFourArrow}
      />
      <Tooltip position="left" opacity={1} tooltipText={inputError} visible={!!inputError} />
      {calendarOpen && (
        <DatePickerWrapper>
          <SafeAreaProvider>
            <PaperProvider theme={theme}>
              <DatePickerModal
                locale={locale}
                mode="single"
                visible={calendarOpen}
                onDismiss={onDismissSingle}
                date={getCalendarSelectedDate(inputDate)}
                onConfirm={onConfirmSingle}
                inputEnabled={modalInputEnabled}
                validRange={validRange}
                allowEditing={false}
              />
            </PaperProvider>
          </SafeAreaProvider>
        </DatePickerWrapper>
      )}
    </View>
  );
};

export default React.memo(Datetimepicker);
