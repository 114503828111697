import React, { useEffect, useState } from 'react';
import { Query } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import get from 'lodash/get';
import { useIsMountedRef } from '../useIsMountedRef';
import { QueryNames, QueryResultNameMap } from './useFetchPageTypes';

export function useCMSQueryData<T>(queryName: QueryNames, queryData: Query | undefined): T | undefined {
  const [pageData, setPageData] = useState<T>();
  const isMountedRef = useIsMountedRef();

  useEffect(() => {
    const queryResult = get(queryData, get(QueryResultNameMap, queryName, ''), null);
    if (queryData && isMountedRef.current) {
      setPageData(queryResult);
    }
  }, [queryData, isMountedRef.current]);

  return pageData;
}

export default useCMSQueryData;
