import { ImageStyle, StyleProp, StyleSheet, TextStyle, ViewStyle } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';
import merge from 'lodash/merge';

interface Styles {
  container: ViewStyle;
  offerContainerOvelay: ViewStyle;
  offerText: TextStyle;
  cancelBtn: ViewStyle;
  btnText: TextStyle;
  canceledText: TextStyle;
  maxImageContainer: ViewStyle;
  maxImage: ImageStyle;
  maxMobileImage: ImageStyle;
}

export const getStyles = (breakpoints: Breakpoints, colors: ReactNativePaper.ThemeColors) => {
  const base: StyleProp<Styles> = {
    container: {
      maxWidth: 688,
    },
    offerContainerOvelay: {
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: 40,
      paddingHorizontal: 25,
      paddingBottom: 20,
    },
    offerText: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Oswald-Medium',
      fontSize: 22,
      fontWeight: '600',
      lineHeight: 32.6,
      textAlign: 'center',
    },
    cancelBtn: {
      maxWidth: 303,
      marginVertical: 12,
    },
    btnText: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Oswald-Medium',
      fontSize: 20,
    },
    canceledText: {
      marginBottom: 24,
      width: '100%',
    },
    maxImageContainer: {
      marginBottom: 33,
      width: '100%',
    },
    maxImage: {
      width: '100%',
      aspectRatio: 16 / 9,
    },
    maxMobileImage: {
      width: '100%',
      aspectRatio: 9 / 16,
    },
  };

  const mediaQueries =
    breakpoints.currentBreakpoints.isTiny ||
    breakpoints.currentBreakpoints.isTnySm ||
    breakpoints.currentBreakpoints.isSmMed
      ? ({
          cancelBtn: {
            maxWidth: '100%',
          },
        } as StyleProp<Styles>)
      : breakpoints.currentBreakpoints.isMedLg
      ? ({
          cancelBtn: {
            maxWidth: 335,
          },
        } as StyleProp<Styles>)
      : breakpoints.currentBreakpoints.isLgXl ||
        breakpoints.currentBreakpoints.isXlXxl ||
        breakpoints.currentBreakpoints.isXxl
      ? ({
          maxImage: {
            maxWidth: 688,
            maxHeight: 387,
          },
        } as StyleProp<Styles>)
      : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
