import React, { FC, useCallback } from 'react';
import { Image, Text, View } from 'react-native';
import { Content, Link, Maybe } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { getStyles } from './landingDevice.style';
import { EstadioButton } from '../../../components';
import { languageStrings, useScrollToTop } from '@warnermmedia/gsp-core/brands/estadio/feature';
import { ScrollViewRefContext } from '@warnermmedia/gsp-core/brands/estadio/ui';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';

interface LandingHeroProps {
  headLine?: string | undefined;
  valueDescription?: string | null | undefined;
  valueImage?: string;
  valueContents?: Maybe<Maybe<Content>[]> | undefined;
  breakpoints: Breakpoints;
  colors: ReactNativePaper.ThemeColors;
  width: number;
  ctaLink: Link | null | undefined;
  setShouldFocusForm: (value: boolean) => void;
  isRegisterEnabled: boolean;
}

export const LandingDevice: FC<LandingHeroProps> = ({
  headLine,
  valueDescription,
  valueImage,
  valueContents,
  breakpoints,
  width,
  colors,
  ctaLink,
  setShouldFocusForm,
  isRegisterEnabled,
}) => {
  const styles = getStyles(breakpoints, colors, width);
  const { scrollToTop } = useScrollToTop(ScrollViewRefContext);

  const onPress = useCallback(() => {
    setShouldFocusForm(true);
    scrollToTop();
  }, [scrollToTop, setShouldFocusForm]);

  return (
    <View style={styles.valueWrapper}>
      <View style={styles.valueHeaderContainer}>
        <View style={styles.valueHeaderTextContent}>
          <Text style={styles.header}>{headLine}</Text>
          <Text style={styles.description}>{valueDescription}</Text>
        </View>
        <View style={styles.valueImageContainer}>
          <Image source={{ uri: valueImage }} style={styles.valueImage} resizeMode="contain" />
        </View>
      </View>
      <View style={styles.devider}></View>

      <View style={styles.deviceListContainer}>
        {valueContents?.map((item, index) => {
          const deviceList = item?.item;
          return (
            <View style={styles.devices} key={index}>
              <Text style={styles.devicesHeader}>{deviceList?.headline}</Text>
              <Text style={styles.devicesSubHeader}>{deviceList?.subheadline}</Text>
              <Text style={styles.devicesDescription}>{deviceList?.description}</Text>
            </View>
          );
        })}
      </View>
      {isRegisterEnabled && (
        <EstadioButton
          onPress={onPress}
          label={ctaLink?.text || languageStrings.default.subscribeNowLabel}
          btnStyle={styles.submitButton}
          size="medium"
          accessible={true}
        />
      )}
    </View>
  );
};

export default LandingDevice;
