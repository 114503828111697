import english from './languages/english';
import spanish from './languages/spanish';

let language: typeof english | typeof spanish = english;
// let currentLocale: string | undefined = getCurrentLocale();
export const currentLocale: string | undefined = 'es_US';

if (currentLocale) {
  switch (currentLocale.split('_')[0]) {
    case 'en':
      language = english;
      break;
    case 'es':
      language = spanish;
      break;

    default:
      language = spanish;
  }
}

export default language;
