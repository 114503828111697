import React, { useCallback, useRef } from 'react';
import { GestureResponderEvent, Pressable, StyleProp, Text, TextStyle, View, ViewStyle } from 'react-native';
import { Avatar } from 'react-native-paper';
import { IconSource } from 'react-native-paper/lib/typescript/components/Icon';
import styles from './nav-item.styles';
import { DeviceTypes } from '../../hooks';
import { useSpatialNavigation } from '../spatialNavigation';

/** Extend DrawerItem
 *  add active, expanded, iconSize, labelStyle
 *  remove roundness
 **/

declare module 'react-native' {
  interface PressableStateCallbackType {
    hovered?: boolean;
    focused?: boolean;
  }

  interface PressableProps {
    delayPressIn?: number;
  }
}

export type NavItemProps = React.ComponentPropsWithRef<typeof View> & {
  /**
   * The label text of the item.
   */
  label?: string;
  /**
   * Icon to display for the `DrawerItem`.
   */
  icon?: IconSource;
  /**
   * Function to execute on press.
   */
  onPress?: (e: Event | GestureResponderEvent) => void | boolean;
  /**
   * Accessibility label for the button. This is read by the screen reader when the user taps the button.
   */
  accessibilityLabel?: string;
  style?: StyleProp<ViewStyle>;
  /**
   * Whether to highlight the drawer item as active.
   */
  active?: boolean;
  /**
   * Whether to expand the drawer item to show label.
   */
  expanded?: boolean;
  /**
   * Size of icon (because Avatar.Icon requires a size).
   */
  iconSize?: number;
  /**
   * StyleProp to customize the label
   */
  labelStyle?: StyleProp<TextStyle>;
  /**
   * Route name to navigate to
   */
  navigateTo?: string;
  isFirstChild?: boolean;
  isLastChild?: boolean;
  rightDirectionOnPress: () => void;
  devices: DeviceTypes;
  colors: ReactNativePaper.ThemeColors;
  fonts: ReactNativePaper.ThemeFonts;
  focusKey?: string | null;
  onNavFocus: (key: string) => void;
};

export function NavItem({
  icon,
  iconSize,
  label,
  active,
  expanded,
  style,
  labelStyle,
  onPress,
  accessibilityLabel,
  navigateTo,
  colors,
  fonts,
  devices,
  isFirstChild,
  isLastChild,
  rightDirectionOnPress,
  focusKey,
  onNavFocus,
  ...rest
}: NavItemProps) {
  const contentColor = active ? colors.navItemActiveColor : colors.navbarColor || colors.primary;
  const font = fonts.medium;
  const { isTv, isWeb } = devices;
  const navItemFocusKey = useRef(focusKey ?? label).current;
  const { ref, focusSelf, focused: spatialFocus } = useSpatialNavigation<GestureResponderEvent>({
    focusKey: navItemFocusKey,
    focusable: expanded,
    onFocus: () => {
      onNavFocus(navItemFocusKey ?? '');
    },
    onEnterPress: (e) => {
      onPress?.(e);
    },
    onArrowPress: (direction) => {
      if (direction === 'left') {
        focusSelf();
        return false;
      }

      if (direction === 'up' && isFirstChild && isTv) {
        focusSelf();
        return false;
      }
      if (direction === 'down' && isLastChild) {
        focusSelf();
        return false;
      }

      if (direction === 'right') {
        rightDirectionOnPress();
      }
      return true;
    },
  });

  const navItemfocusStyle =
    spatialFocus && isTv
      ? {
          backgroundColor: colors.stroke.onDark.outline01,
        }
      : { backgroundColor: 'transparent' };

  const handleOnPress = useCallback(
    (event: GestureResponderEvent) => {
      onPress && onPress(event);
    },
    [onPress]
  );

  return (
    <View {...rest} style={[styles.container, navItemfocusStyle]} nativeID="nav_item">
      <Pressable
        delayPressIn={0}
        onPress={handleOnPress}
        style={({ hovered }) => [
          style,
          {
            backgroundColor: hovered ? colors.navItemHoverBackground : 'transparent',
            borderWidth: 0,
            borderColor: 'transparent',
          },
        ]}
        accessibilityRole="button"
        accessibilityState={{ selected: active }}
        accessibilityLabel={accessibilityLabel}
        nativeID={navItemFocusKey}
        ref={ref}
      >
        <View style={styles.wrapper}>
          {icon && <Avatar.Icon icon={icon} style={{ backgroundColor: 'transparent' }} size={iconSize} />}
          <Text
            nativeID="navItemLabel"
            selectable={false}
            numberOfLines={1}
            style={[
              styles.label,
              {
                ...font,
                color: isWeb
                  ? colors.stroke.onDark.outline01
                  : spatialFocus && isTv
                  ? colors.fill.action.accent04
                  : contentColor,
              },
              labelStyle,
              {
                display: expanded ? 'flex' : 'none',
              },
            ]}
          >
            {label}
          </Text>
        </View>
      </Pressable>
    </View>
  );
}

NavItem.displayName = 'Nav.Item';

export default NavItem;
