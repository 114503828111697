import React, { useEffect } from 'react';
import { Dalton } from '../dalton';
import { useIsMountedRef } from '../useIsMountedRef';
import { useReactiveVar } from '@apollo/client';
import { userHasSubscription } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { useLocation } from 'react-router';

export const useEstadioDalton = (): void => {
  const dalton = Dalton();
  const isMountedRef = useIsMountedRef();
  const hasSubscription = useReactiveVar(userHasSubscription);
  const location = useLocation();
  const subscriptionRoutes = ['/subscribe', '/subscriptions/paypal/success'];

  useEffect(() => {
    if (isMountedRef.current) {
      dalton.fetchUserInfo({
        shouldGetBillingProfile: !hasSubscription && !subscriptionRoutes.includes(location.pathname),
      });
    }
  }, [dalton, isMountedRef, hasSubscription, location, subscriptionRoutes]);
};

export default useEstadioDalton;
