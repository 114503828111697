import { useEffect, useState } from 'react';
import { loginAPIStateStore, USER_FAVORITE_TEAM, UserAccount } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { useReactiveVar } from '@apollo/client';
import { languageStrings } from '../..';
import {
  Preferences,
  saveData,
  User,
  UserAddressResponseEntity,
  UserContactNumberResponseEntity,
  UserEmailResponsesEntity,
} from '@warnermmedia/gsp-core/sdk/data-access';

const language = languageStrings.default;

export const useProfileData = () => {
  const [userProfile, setUserProfile] = useState({
    id: '',
    phone: '',
    email: '',
    alternativeEmail: '',
    firstName: '',
    lastName: '',
    birthdate: '',
    countryOfResidence: '',
    password: '',
  });

  const [userPreferences, setUserPreferences] = useState<{
    rut: string;
    favoriteTeam: string | null;
    favoriteTeamId: string;
    gender: string;
    retentionCouponApplied: string;
  }>({
    rut: '',
    favoriteTeam: null,
    favoriteTeamId: '',
    gender: '',
    retentionCouponApplied: '',
  });
  const [mobileContact, setMobileContact] = useState<UserContactNumberResponseEntity | undefined>();
  const [address, setAddress] = useState<UserAddressResponseEntity | undefined>();
  const { user, preferences } = useReactiveVar(loginAPIStateStore);

  useEffect(() => {
    if (user && user.tid) {
      processData(user);
    }
  }, [user]);

  useEffect(() => {
    if (preferences !== null) {
      processPreferences(preferences);
    }
  }, [preferences]);

  const setUserFavoriteTeam = (preferencesProcessed: boolean, favoriteTeam: string, favoriteTeamId: string) => {
    loginAPIStateStore({ ...loginAPIStateStore(), preferencesProcessed });
    if (preferencesProcessed && !!favoriteTeam && !!favoriteTeamId) {
      saveData(USER_FAVORITE_TEAM, favoriteTeam);
    }
  };

  const processPreferences = (data: Preferences) => {
    const rutObj = data.find((item) => item.collection === 'rut');
    const genderObj = data.find((item) => item.collection === 'gender');
    const favoriteTeam = data.find((item) => item.collection === 'favoriteTeam');
    const favoriteTeamId = data.find((item) => item.collection === 'favoriteTeamId');
    const retentionCouponApplied = data.find((item) => item.collection === 'retentionCouponApplied');

    let gender = '';

    if (genderObj?.selections?.[0]) {
      const genderValue = genderObj?.selections?.[0];

      switch (genderValue) {
        case 'Male':
        case language.genderMaleText:
          gender = language.genderMaleText;
          break;
        case 'Female':
        case language.genderFemaleText:
          gender = language.genderFemaleText;
          break;
      }
    }

    const newData = {
      rut: rutObj?.selections[0] ? rutObj?.selections[0] : '',
      gender,
      favoriteTeam: favoriteTeam?.selections[0] ? favoriteTeam?.selections[0] : '',
      favoriteTeamId: favoriteTeamId?.selections[0] ? favoriteTeamId?.selections[0] : '',
      retentionCouponApplied: retentionCouponApplied?.selections[0] ? retentionCouponApplied?.selections[0] : '',
    };
    setUserPreferences(newData);
    setUserFavoriteTeam(!!newData, newData.favoriteTeam, newData.favoriteTeamId);
  };

  const processData = (data: User) => {
    const primaryEmail: UserEmailResponsesEntity | undefined = data.userEmailResponses?.find((email) => {
      const emailItem = (email as unknown) as UserEmailResponsesEntity;
      return emailItem.primary === true;
    });
    const altEmail: UserEmailResponsesEntity | undefined = data.userEmailResponses?.find((email) => {
      const emailItem = (email as unknown) as UserEmailResponsesEntity;
      return emailItem.primary !== true;
    });
    const mobileContact = data.userContactNumberResponses?.find((item) => {
      const contactItem = (item as unknown) as UserContactNumberResponseEntity;
      return (contactItem?.contactType ?? '').toLowerCase() === 'mobile';
    });
    const address = data.userAddressResponses?.find((item) => {
      const addressItem = (item as unknown) as UserAddressResponseEntity;
      return (addressItem?.description ?? '').toLowerCase() === 'home';
    });
    setAddress(address);
    setMobileContact(mobileContact);
    const newData = {
      ...userProfile,
      id: data.tid,
      phone: mobileContact?.contactNumber || '',
      email: primaryEmail?.emailAddress || '',
      alternativeEmail: altEmail?.emailAddress || '',
      firstName: data.firstName,
      lastName: data.lastName,
      birthdate: data.birthdate || '',
      countryOfResidence: address?.country || '',
    };
    setUserProfile(newData);
  };
  const userData: UserAccount = { ...userProfile, ...userPreferences };
  return { userData, mobileContact, address };
};
