import { Linking, Platform } from 'react-native';
import getCurrentLocale from './getCurrentLocale';

// for getting to apps on Apple Store and Play Store
export const canOpenUrl = async (url: string | boolean): Promise<boolean> => {
  if (url) {
    const link = url as string;
    return Linking.canOpenURL(link).then((supported) => {
      supported && Linking.openURL(link);
      return true;
    });
  } else {
    return false;
  }
};

export const openApp = (url: string | boolean): boolean => {
  if (url) {
    Linking.openURL(url as string);
    return true;
  } else {
    return false;
  }
};

export const openInStore = async (appName: string, appStoreID: string, playStoreID: string): Promise<boolean> => {
  if (Platform.OS === 'ios') {
    appName = appName.replace('://', '');
    Linking.openURL(`itms-apps://itunes.apple.com/${getCurrentLocale()}/app/id${appStoreID}?mt=8`);
    return true;
  } else if (Platform.OS === 'android') {
    Linking.openURL(`market://details?id=${playStoreID}`);
    return true;
  } else {
    return false;
  }
};

export default {
  canOpenUrl,
  openApp,
  openInStore,
};
