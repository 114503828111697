import React, { memo } from 'react';
import { ImageBackground, View } from 'react-native';
import { CardProps, TextContent } from '@warnermmedia/gsp-core/sdk/ui';
import { getCardSettings, SlideTop } from '../atoms/cardsAtoms';
import { getStyles } from './videoCard.styles';
import {
  CardDefaultBackground,
  carouselBase,
  getImageWithStyle,
  useCardItemImage,
} from '@warnermmedia/gsp-core/brands/estadio/feature';

const VideoCard = ({
  item,
  index,
  currentIndex,
  type,
  cardBg,
  breakpoints,
  device,
  colors,
}: CardProps<carouselBase>) => {
  const cardSettings = getCardSettings(colors);
  const styles = getStyles(breakpoints, device.isTv, colors);
  const { getCardImageSource } = useCardItemImage();
  const status = item.status;
  const isManual = item?.type === 'manual';
  const itemImage = item?.image ? item?.image : null;
  const videoImage = getCardImageSource({
    fallbackName: cardBg ? cardBg : CardDefaultBackground.EstadioBg,
    image: itemImage && (type === 'dpg' || !isManual) ? getImageWithStyle(itemImage) : null,
  });

  return (
    <View>
      <ImageBackground source={videoImage} style={styles.imageBackground} imageStyle={styles.imageBgStyle}>
        <View style={styles.slideTop}>
          <SlideTop left={item.cardLabel ?? ''} right={item.duration ?? ''} isLive={item.cardLabel === 'live'} />
          {item.type === 'video' || ((item.type === 'epg' || isManual) && (status === 3 || status === 4)) ? (
            <View style={styles.playIcon}>{cardSettings.playButton}</View>
          ) : null}
        </View>
      </ImageBackground>
      <View style={[styles.slideBottom, index === currentIndex && styles.slideBottomActive]}>
        {item.title ? (
          <TextContent style={styles.matchup} numberOfLines={1}>
            {item.title}
          </TextContent>
        ) : null}
        {item.subtitle ? (
          <TextContent style={styles.round} numberOfLines={2}>
            {item.subtitle}
          </TextContent>
        ) : null}
        {isManual && item?.heading ? (
          <TextContent style={styles.round} numberOfLines={2}>
            {item?.heading}
          </TextContent>
        ) : null}
        {item.subHeadline ? (
          <TextContent style={styles.round} numberOfLines={2}>
            {item.subHeadline}
          </TextContent>
        ) : null}
      </View>
    </View>
  );
};

export default memo(VideoCard);
