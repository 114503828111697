import AsyncStorage from '@react-native-async-storage/async-storage';

import handleError from './helpers/handleError';

type KeyType = string;

class SyncStorage {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Map<any, any> = new Map();

  loading = true;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  init(): Promise<any[]> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return AsyncStorage.getAllKeys().then((keys: any) =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      AsyncStorage.multiGet(keys).then((data: any): any[] => {
        data.forEach(this.saveItem.bind(this));
        return [...this.data];
      })
    );
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get(key: KeyType): any {
    return this.data.get(key);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set(key: KeyType, value: any): Promise<any> {
    if (!key) return handleError('set', 'a key');
    this.data.set(key, value);
    return AsyncStorage.setItem(key, JSON.stringify(value));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  remove(key: KeyType): Promise<any> {
    if (!key) return handleError('remove', 'a key');

    this.data.delete(key);
    return AsyncStorage.removeItem(key);
  }

  saveItem(item: Array<KeyType>) {
    let value;

    try {
      value = JSON.parse(item[1]);
    } catch (e) {
      [, value] = item;
    }

    this.data.set(item[0], value);
    this.loading = false;
  }
}

const syncStorage = new SyncStorage();

export default syncStorage;
