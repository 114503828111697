import React, { FC, useContext, useEffect, useRef, useState } from 'react';

import {
  HistoryContext,
  Maybe,
  Series,
  Serieses,
  SeriesSort,
  SortDirections,
} from '@warnermmedia/gsp-core/brands/estadio/data-access';
import TournamentHeader from '../tournamentHeader/tournamentHeader';
import { View } from 'react-native';
import {
  carouselBase,
  DerivedStatus,
  EstadioEventsCallTypes,
  getContentTitle,
  languageStrings,
  QueryNames,
  ScreenEventType,
  useCMSQueryDataResults,
  useFormatCarouselData,
  useIsMountedRef,
  useMparticleScreenEvent,
  useRouter,
} from '@warnermmedia/gsp-core/brands/estadio/feature';
import { CmsCarousel } from '../cmsPage';
import { MetaTags, useGetDevice } from '@warnermmedia/gsp-core/sdk/ui';
import { SkeletonDetailPage } from '../skeletonDisplay';
import { useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { CAROUSEL_KEY, FocusKeys, ROUTE_FOCUS_KEY, saveData } from '@warnermmedia/gsp-core/sdk/data-access';

export type TournamentParams = {
  id: string;
  season: string;
};

export type TournamentDetailsHeader = {
  seriesTitle: string;
  seriesImageUrl: string;
  seriesDescription: string;
};

export interface SubSeriesItem {
  id: string;
  name: string;
  events?: carouselBase[];
}

export const TournamentDetails: FC = () => {
  const params = useRouter().useParams<TournamentParams>();
  const seriesId = params.id ?? '';
  const season = params.season ?? '';
  const [seriesLastEvents, setSeriesLastEvents] = useState<carouselBase[]>([]);
  const [subSeries, setSubSeries] = useState<SubSeriesItem[]>([]);
  const [series, setSeries] = useState<Series>();
  const [tournamentId, setTournamentId] = useState<string>('');
  const [pageHeader, setPageHeader] = useState<TournamentDetailsHeader | null>(null);
  const { pageData: seriesData } = useCMSQueryDataResults<Series>({
    queryName: QueryNames.GET_SERIES_BY_ID,
    id: seriesId,
  });
  const { pageData: seriesLastEventsData } = useCMSQueryDataResults<Event[]>({
    queryName: QueryNames.GET_ALL_EVENTS,
    callType: EstadioEventsCallTypes.Tournament,
    id: tournamentId,
    countStart: '0',
    countEnd: '20',
    season,
    order: SortDirections.Desc,
    mediaFilter: DerivedStatus.Upcoming,
  });

  const { pageData: allSeriesListData, loading: loadingSubSeriesListWithEvents } = useCMSQueryDataResults<Serieses>({
    queryName: QueryNames.GET_SERIES_LIST,
    tournamentId,
    season,
    sort: SeriesSort.StartDate,
    order: SortDirections.Asc,
    countStart: 0,
    countEnd: 50,
  });

  const { formatEvents } = useFormatCarouselData();
  const isMountedRef = useIsMountedRef();
  const historyContext = useContext(HistoryContext);
  const history = historyContext?.ready ? historyContext?.useHistory() : null;
  const { isWeb } = useGetDevice();
  useMparticleScreenEvent(ScreenEventType.Tournament, series?.series_name ?? '', series?.id ?? '');
  const subSeriesCarouselIndex = useRef<number>(1);
  const { setFocus } = useFocusable();

  useEffect(() => {
    let key: string = FocusKeys.TICKER;
    if (seriesLastEvents?.length || subSeries?.length) {
      key =
        seriesLastEvents?.length > 0
          ? CAROUSEL_KEY(1)
          : subSeries.length > 0
          ? CAROUSEL_KEY(subSeriesCarouselIndex.current)
          : FocusKeys.TICKER;
    }
    setFocus(key);
    saveData(ROUTE_FOCUS_KEY, key);
  }, [seriesLastEvents, setFocus, subSeries, subSeriesCarouselIndex]);

  useEffect(() => {
    if (seriesData && isMountedRef.current) {
      setSeries(seriesData as Series);
    }
  }, [seriesData, isMountedRef]);

  useEffect(() => {
    if (series && isMountedRef.current) {
      setTournamentId(series?.tournament?.id ?? '');
      setPageHeader({
        seriesTitle: series?.series_name ?? '',
        seriesImageUrl: series?.taxonomy?.image?.url ?? '',
        seriesDescription: series?.taxonomy?.description ?? '',
      });
    }
  }, [series, isMountedRef]);

  useEffect(() => {
    if (seriesLastEventsData && isMountedRef.current) {
      setSeriesLastEvents(formatEvents(seriesLastEventsData));
    }
  }, [seriesLastEventsData, isMountedRef, formatEvents]);

  useEffect(() => {
    if (allSeriesListData?.series && isMountedRef.current) {
      const subSeriesData = allSeriesListData.series
        .filter((series: Maybe<Series>) => series?.id !== seriesId)
        .map((series: Maybe<Series>) => ({
          id: series?.id,
          name: series?.series_name,
          events: formatEvents(series?.events || [], true, SortDirections.Desc),
        }));
      setSubSeries(subSeriesData as SubSeriesItem[]);
    }
  }, [seriesId, allSeriesListData?.series, isMountedRef, formatEvents]);

  const clickAction = (item: carouselBase) => {
    const eventId = item.match?.eventId;
    if (typeof item !== 'undefined' && eventId) {
      history?.push(`/match/${eventId}`);
    }
  };

  return (
    <View>
      {loadingSubSeriesListWithEvents ? (
        <SkeletonDetailPage windowHeightStartTop={200} />
      ) : (
        <View>
          {isWeb && <MetaTags title={getContentTitle(series?.series_name ?? '')} />}
          {pageHeader && (
            <TournamentHeader
              title={pageHeader.seriesTitle}
              imageUrl={pageHeader.seriesImageUrl}
              description={pageHeader.seriesDescription}
            />
          )}
          {!!seriesLastEventsData && seriesLastEvents?.length > 0 && (
            <CmsCarousel
              id={'latest-matches'}
              headline={languageStrings.default.teamDetailCarouselHeadline}
              content={[]}
              allItems={seriesLastEvents}
              type={'generic'}
              format={'carousel'}
              weight={1}
              laneIndex={1}
              clickAction={clickAction}
            />
          )}
          {!loadingSubSeriesListWithEvents &&
            subSeries
              .filter((s) => !!s.events)
              .map((series, index) => {
                const laneIndex = index + 2;
                const hasValidEvents =
                  series.events &&
                  series.events?.length > 0 &&
                  series.events.some((e) => !!e?.match?.eventId && !!e?.has_media);

                if (hasValidEvents && subSeriesCarouselIndex.current === 1) {
                  subSeriesCarouselIndex.current = laneIndex;
                }

                return (
                  <CmsCarousel
                    id={series?.id ?? ''}
                    headline={series?.name ?? ''}
                    content={[]}
                    allItems={series.events}
                    type={'generic'}
                    format={'carousel'}
                    weight={laneIndex}
                    laneIndex={laneIndex}
                    key={index}
                    clickAction={clickAction}
                  />
                );
              })}
        </View>
      )}
    </View>
  );
};

export default TournamentDetails;
