import { PlayerInstance } from '@top/player-block-react-native';
import { Player } from '@top/player-block-web';
import { ErrorSeverity } from '@top/player-block-api';
import { VideoErrorMessages } from '@warnermmedia/gsp-core/sdk/data-access';
import { getDate } from './useDayjs';
import { ManipulateType, QUnitType, UnitType } from 'dayjs';

export const validateDatePattern = (date: string, datePattern: RegExp) => {
  return date.match(datePattern);
};

export const validateEmail = (email: string) => {
  return /^[a-zA-Z0-9._+-]+@([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,63}$/.test(email);
};

export const validateAlphabeticCharacters = (text: string) => {
  return /^[a-zA-Z ]*$/.test(text);
};

export const validateNonEmptyString = (password: string) => password.trim().length > 0;

export const validateCurrency = (amount: string) => {
  return /^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{2})?$/.test(amount);
};

export const validateTicketNumber = (ticketNumber: string) => {
  return /^[0-9]{4,15}$/.test(ticketNumber);
};

export const validateRUT = (rut: string) => {
  if (!rut) {
    return false;
  }
  const strippedNumber = rut.replace(/[-.]/g, '');
  return /^\d{9}$/.test(strippedNumber);
};

/**
 * validation for a minimum of 8 characters: 1 uppercase, 1 lowercase, 1 number or symbol
 * @param password
 */
export function validatePasswordRule(password: string) {
  const validate = /^((?=.*\d)|(?=.*[!@#$%^&*]))(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  return validate.test(password);
}

export const deriveDate = (date: string) => {
  const derived = date.match(/^(\d{4})(\d{2})(\d{2})$/);
  if (derived) {
    return {
      year: derived[1],
      month: derived[2],
      day: derived[3],
    };
  }
  return {
    year: '',
    month: '',
    day: '',
  };
};

// Formats date into MM/DD/YYYY structure
export const formatDate = (date: string) => {
  const { year, month, day } = deriveDate(date);
  return `${month}/${day}/${year}`;
};

// Formats date into DD/MM/YYYY structure
export const formatDateDDMMYYYY = (date: string | number | Date) => {
  return getDate(date).format('DD/MM/YYYY');
};

export const validateImage = (fileType: string, fileSize: number, validSize = 25000) => {
  const typeArray = ['image/jpeg', '/image/jpg', 'image/png'];
  return fileSize > validSize && typeArray.indexOf(fileType) >= 0;
};

export const validateDate = (date: Date) => {
  return Object.prototype.toString.call(date) === '[object Date]' && !Number.isNaN(date.getTime());
};

export const formatePhone = (value: string) => {
  const formated = value.match(/^(\d{3})(\d{3})(\d{3})$/);
  if (formated) {
    return `${formated[1]}-${formated[2]}-${formated[3]}`;
  }
  return value;
};

export const formateRut = (value: string) => {
  const formated = value.match(/^(\d{2})(\d{3})(\d{3})(\d{1})$/);
  if (formated) {
    return `${formated[1]}.${formated[2]}.${formated[3]}-${formated[4]}`;
  }
  return value;
};

export const addDate = (duration: number, unit: ManipulateType, date = new Date()) => {
  return getDate(date).add(duration, unit).toDate();
};

export const validatePinCode = (code: string) => {
  if (!code) {
    return false;
  }

  return /^[0-9]{6}$/.test(code);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const encodeBase64 = (option: any): string => {
  return Buffer.from(JSON.stringify(option)).toString('base64');
};

export const decodeBase64 = (option: string): string => {
  const data = Buffer.from(option, 'base64').toString();
  let parsedData = '';
  try {
    parsedData = JSON.parse(data);
  } catch (e) {
    parsedData = '';
  }
  return parsedData;
};

export const reportVideoErrorToConviva = (videoError: string, player: PlayerInstance | null | Player) => {
  if (
    videoError === VideoErrorMessages.GEOBLOCK_EVENT_ERROR ||
    videoError === VideoErrorMessages.CONCURRENCY_LIMIT_EVENT_ERROR
  ) {
    player?.reportError({
      severity: ErrorSeverity.Fatal,
      data: videoError,
    });
  }
};

export const getUrlSearchParam = (search: string | undefined, key: string): string | null => {
  const pageQuery = new URLSearchParams(search);
  return pageQuery?.get(key);
};
