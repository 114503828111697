import { ImageStyle, StyleProp, StyleSheet, TextStyle, ViewStyle } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';
import merge from 'lodash/merge';

interface Styles {
  retentionContainer: ViewStyle;
  retentionImage: ImageStyle;
  retentionMobileImage: ImageStyle;
  activateBtn: ViewStyle;
  activateBtnText: TextStyle;
  cancelBtn: ViewStyle;
  cancelBtnText: TextStyle;
  returnBtn: ViewStyle;
  returnBtnText: TextStyle;
  offerContainer: ViewStyle;
  offerContainerOvelay: ViewStyle;
  offerText: TextStyle;
  legalTextContainer: ViewStyle;
  legalText: TextStyle;
  arrowContainer: ViewStyle;
  downArrow: ImageStyle;
}

export const getStyles = (breakpoints: Breakpoints, colors: ReactNativePaper.ThemeColors) => {
  const base: StyleProp<Styles> = {
    retentionContainer: {
      maxWidth: 688,
    },
    retentionImage: {
      width: '100%',
      aspectRatio: 16 / 9,
    },
    retentionMobileImage: {
      width: '100%',
      aspectRatio: 9 / 16,
    },
    activateBtn: {
      maxWidth: 336,
      marginVertical: 12,
    },
    activateBtnText: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Oswald-Medium',
      fontSize: 20,
    },
    cancelBtn: {
      maxWidth: 336,
      borderWidth: 2,
      borderColor: colors.tenantBackground.light.surfaceBase,
      marginVertical: 12,
      backgroundColor: 'transparent',
    },
    cancelBtnText: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Oswald-Medium',
      fontSize: 20,
    },
    returnBtn: {
      maxWidth: 150,
      backgroundColor: 'transparent',
      paddingHorizontal: 0,
      justifyContent: 'flex-start',
    },
    returnBtnText: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Oswald-Regular',
      textDecorationStyle: 'solid',
      textDecorationLine: 'underline',
      textDecorationColor: colors.keyboardFocus.light,
      fontSize: 14,
      textAlign: 'left',
    },
    offerContainer: {
      marginBottom: 33,
      width: '100%',
    },
    offerContainerOvelay: {
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: 40,
      paddingHorizontal: 25,
      paddingBottom: 20,
    },
    offerText: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Oswald-Medium',
      fontSize: 22,
      fontWeight: '600',
      lineHeight: 32.6,
      textAlign: 'center',
    },
    legalTextContainer: {
      marginTop: 33,
      marginBottom: 21,
    },
    legalText: {
      fontFamily: 'Rubik-Regular',
      fontSize: 12,
      fontWeight: '400',
      lineHeight: 14.22,
      color: colors.keyboardFocus.light,
    },
    arrowContainer: {
      marginTop: 17,
    },
    downArrow: {
      width: 10,
      height: 6,
    },
  };

  const mediaQueries =
    breakpoints.currentBreakpoints.isTiny ||
    breakpoints.currentBreakpoints.isTnySm ||
    breakpoints.currentBreakpoints.isSmMed
      ? ({
          activateBtn: {
            maxWidth: '100%',
          },
          cancelBtn: {
            maxWidth: '100%',
          },
        } as StyleProp<Styles>)
      : breakpoints.currentBreakpoints.isMedLg
      ? ({
          activateBtn: {
            maxWidth: 335,
          },
          cancelBtn: {
            maxWidth: 335,
          },
        } as StyleProp<Styles>)
      : breakpoints.currentBreakpoints.isLgXl ||
        breakpoints.currentBreakpoints.isXlXxl ||
        breakpoints.currentBreakpoints.isXxl
      ? ({
          retentionImage: {
            maxWidth: 688,
            maxHeight: 387,
          },
        } as StyleProp<Styles>)
      : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
