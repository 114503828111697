import { StyleProp, StyleSheet, TextStyle, ViewStyle } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';
import merge from 'lodash/merge';

interface Styles {
  container: ViewStyle;
  radioWrapper: ViewStyle;
  label: TextStyle;
  returnBtn: ViewStyle;
  returnBtnText: TextStyle;
  optionsContainer: ViewStyle;
  buttonContainer: ViewStyle;
}

export const getStyles = (breakpoints: Breakpoints, colors: ReactNativePaper.ThemeColors) => {
  const base: StyleProp<Styles> = {
    container: {
      backgroundColor: '#0f1431',
      padding: 10,
    },
    radioWrapper: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginVertical: 3,
      height: 44,
      width: '100%',
    },
    label: {
      color: colors.tenantBackground.light.surfaceBase,
      fontSize: 14,
    },
    returnBtn: {
      alignSelf: 'flex-start',
    },
    returnBtnText: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Oswald-Regular',
      textDecorationStyle: 'solid',
      textDecorationLine: 'underline',
      textDecorationColor: colors.keyboardFocus.light,
      fontSize: 14,
      textAlign: 'left',
      borderColor: colors.keyboardFocus.light,
      textTransform: 'uppercase',
    },
    optionsContainer: {
      maxWidth: 700,
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    buttonContainer: {
      marginTop: 12,
      justifyContent: 'flex-start',
    },
  };

  const mediaQueries =
    breakpoints.currentBreakpoints.isMedLg ||
    breakpoints.currentBreakpoints.isLgXl ||
    breakpoints.currentBreakpoints.isXlXxl ||
    breakpoints.currentBreakpoints.isXxl
      ? ({
          radioWrapper: {
            minWidth: 350,
            width: '50%',
            paddingRight: 4,
          },
          optionsContainer: {
            justifyContent: 'space-between',
          },
        } as StyleProp<Styles>)
      : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
