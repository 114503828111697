import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  topbar: {
    backgroundColor: '#323449',
  },
  countryDropdown: {
    alignSelf: 'flex-end',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 8,
  },
  countryLabel: {
    fontWeight: 'bold',
    fontSize: 20,
    marginRight: 10,
    paddingRight: 16,
    paddingVertical: 8,
    borderRightWidth: 2,
    borderColor: '#161b33',
  },
  countryFlag: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 24,
  },
  flagImage: {
    width: 32,
    height: 24,
    marginRight: 16,
    marginLeft: 4,
  },
});
