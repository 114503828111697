import { ViewStyle, StyleSheet, StyleProp, TextStyle } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';
import { merge } from 'lodash';

interface Styles {
  wrapper: ViewStyle;
  accountWrapperStyle: ViewStyle;
  detailsStyle: ViewStyle;
  detail: ViewStyle;
  actionBtn: ViewStyle;
  titleStyle: TextStyle;
  detailLabel: TextStyle;
  detailValue: TextStyle;
  detailValueFilled: TextStyle;
  subTitle: TextStyle;
  actionBtnText: TextStyle;
  dateWrapper: ViewStyle;
  dateTextInput: ViewStyle;
  dateTextInputFilled: ViewStyle;
  day: ViewStyle;
  month: ViewStyle;
  year: ViewStyle;
  dateInput: ViewStyle;
  cancelBtnText: TextStyle;
  cancelBtn: ViewStyle;
  cancelBtnWrapper: ViewStyle;
}

export const getStyles = (breakpoints: Breakpoints, isTv: boolean, colors: ReactNativePaper.ThemeColors) => {
  const base: StyleProp<Styles> = {
    wrapper: {
      justifyContent: 'center',
      width: '100%',
    },
    accountWrapperStyle: {
      width: '100%',
    },
    detailsStyle: {
      flexDirection: 'row',
      alignItems: 'flex-start',
      flexWrap: 'wrap',
      maxWidth: 1276,
    },
    detail: {
      width: '100%',
      maxWidth: 303,
      marginVertical: 10,
      marginRight: 16,
      marginLeft: 0,
    },
    titleStyle: {
      color: colors.keyboardFocus.light,
      fontSize: 22,
      fontFamily: 'Oswald-SemiBold',
      fontWeight: '600',
      textTransform: 'uppercase',
    },
    detailLabel: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Rubik-Medium',
      fontSize: 16,
      fontWeight: '500',
      height: 20,
      lineHeight: 20,
      width: 280,
    },
    detailValue: {
      color: colors.keyboardFocus.dark,
      backgroundColor: colors.tenantBackground.light.surfaceBase,
      fontFamily: 'Rubik-Regular',
      fontSize: 16,
      fontWeight: 'normal',
      height: 40,
      lineHeight: 24,
      borderWidth: 0,
      opacity: 0.8,
    },
    detailValueFilled: {
      color: colors.keyboardFocus.dark,
      backgroundColor: colors.tenantBackground.light.surfaceBase,
      fontFamily: 'Rubik-Regular',
      fontSize: 16,
      fontWeight: 'normal',
      height: 40,
      lineHeight: 24,
      borderWidth: 0,
      opacity: 1,
    },
    subTitle: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Rubik-Regular',
      fontSize: 16,
      fontWeight: 'normal',
      lineHeight: 20,
      marginBottom: 30,
    },
    actionBtn: {
      backgroundColor: colors.fill.action.accent01,
      borderRadius: 4,
      height: 40,
      width: '100%',
      borderWidth: 0,
      marginBottom: 10,
    },
    actionBtnText: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Oswald-Medium',
      fontSize: 20,
      fontWeight: '500',
      height: 24,
      lineHeight: 24,
      textAlign: 'center',
      textTransform: 'uppercase',
    },
    dateWrapper: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    dateTextInput: {
      backgroundColor: colors.tenantBackground.light.surfaceBase,
      borderColor: colors.tenantBackground.light.surfaceBase,
      height: 40,
      borderRadius: 5,
      width: '100%',
      opacity: 0.8,
    },
    dateTextInputFilled: {
      backgroundColor: colors.tenantBackground.light.surfaceBase,
      borderColor: colors.tenantBackground.light.surfaceBase,
      height: 40,
      borderRadius: 5,
      width: '100%',
      opacity: 1,
    },
    day: {
      width: '25%',
    },
    month: {
      width: '25%',
    },
    year: {
      width: '40%',
    },
    dateInput: {
      width: '100%',
    },
    cancelBtnText: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Oswald-Regular',
      fontSize: 14,
      fontWeight: 'normal',
      lineHeight: 21,
      textDecorationLine: 'underline',
      textTransform: 'uppercase',
    },
    cancelBtn: {
      paddingHorizontal: 0,
    },
    cancelBtnWrapper: {
      alignSelf: 'flex-start',
    },
  };

  const mediaQueries = isTv
    ? ({
        actionBtn: {
          maxWidth: 303,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isTiny ||
      breakpoints.currentBreakpoints.isTnySm ||
      breakpoints.currentBreakpoints.isSmMed
    ? {
        detail: {
          maxWidth: '100%',
        },
      }
    : breakpoints.currentBreakpoints.isMedLg ||
      breakpoints.currentBreakpoints.isLgXl ||
      breakpoints.windowWidth === breakpoints.breakpointSizes.lg
    ? ({
        detail: {
          maxWidth: 335,
        },
        actionBtn: {
          maxWidth: 335,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isXlXxl || breakpoints.currentBreakpoints.isXxl
    ? ({
        actionBtn: {
          maxWidth: 303,
        },
      } as StyleProp<Styles>)
    : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
