import React, { useCallback, useContext, useEffect, useState } from 'react';
import qs from 'qs';
import { useLocation } from 'react-router-dom';
import { SubscriptionSuccess } from '../subscriptionSuccess';
import { Dalton, useIsMountedRef, useMparticleCustomEventObject } from '@warnermmedia/gsp-core/brands/estadio/feature';
import { LoadingAppIndicator } from '../../loadingAppIndicator';
import { HistoryContext } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import {
  MParticleCustomEventTypes,
  mParticleEventProcessor,
  SubscriptionStatus,
} from '@warnermmedia/gsp-core/sdk/data-access';
import get from 'lodash/get';

export const PayPalSubscriptionSuccess = () => {
  const [loading, setLoading] = useState(true);
  const historyContext = useContext(HistoryContext);
  const history = historyContext?.ready ? historyContext?.useHistory() : null;
  const dalton = Dalton();
  const location = useLocation();
  const params = qs.parse(location.search.slice(1));
  const mParticleEventData = useMparticleCustomEventObject();
  const isMountedRef = useIsMountedRef();

  const finalizeSubscription = useCallback(
    async (vid: string) => {
      const response = await dalton.finalizeSubscriptionPurchase(vid);
      if (response) {
        if (response.success && isMountedRef.current) {
          setLoading(false);
          const universalProductIndicator = get(response, 'data.universalProductIndicator', null);
          mParticleEventProcessor.pushMParticleEvent(MParticleCustomEventTypes.SubscriptionSuccessEvent, {
            ...mParticleEventData,
            subscription_status: SubscriptionStatus.Active,
            ...(universalProductIndicator ? { subscription_type: universalProductIndicator } : {}),
          });
        } else {
          history?.push('/subscriptions/paypal/failure');
        }
      }
    },
    [dalton, history, isMountedRef, mParticleEventData]
  );

  useEffect(() => {
    if (params.vindicia_vid) {
      finalizeSubscription(params.vindicia_vid as string);
    } else {
      history?.push('/subscribe');
    }
  }, [params.vindicia_vid, history]);

  if (loading) {
    return <LoadingAppIndicator />;
  }
  return <SubscriptionSuccess source="subscriptions" />;
};

export default PayPalSubscriptionSuccess;
