import React from 'react';
import { breakpointsStateStore, Maybe, Product } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { TextContent } from '@warnermmedia/gsp-core/sdk/ui';
import LinearGradient from 'react-native-linear-gradient';
import { useReactiveVar } from '@apollo/client';
import { getStyles } from './product.styles';
import { useTheme } from 'react-native-paper';

interface Props {
  description: Maybe<string> | undefined;
  product: Product;
  couponDeduction?: string;
}

export const ProductComponent = ({ description, product, couponDeduction }: Props) => {
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const { colors } = useTheme();
  const styles = getStyles(breakpoints);

  return (
    <LinearGradient
      colors={[colors.tenantBackground.dark.surface05.start, colors.tenantBackground.dark.surface05.end]}
      style={styles.productWrapper}
    >
      <TextContent style={styles.headline}>{product?.headline || ''}</TextContent>
      <TextContent style={styles.price}>${couponDeduction || (product?.price as string)}</TextContent>
      <TextContent style={styles.duration}>{product?.subHeadline || ''}</TextContent>
      <TextContent style={styles.details}>{description || ''}</TextContent>
      <TextContent style={styles.disclaimer}>{product?.disclaimer || ''}</TextContent>
    </LinearGradient>
  );
};

export default ProductComponent;
