import { useCallback, useEffect } from 'react';
import { loadData, saveData } from '@warnermmedia/gsp-core/sdk/data-access';
import { useGetDevice } from './useGetDevice';
import { getUrlSearchParam } from '../utils';

const DefaultAppVersionValue = 'na';

export const APP_VERSION = 'APP_VERSION';
export const useWebAppsVersion = () => {
  const { device, isWeb, isTv } = useGetDevice();

  const getTvAppVersion = useCallback(
    (webVersion: string) => {
      let tvVersion = '';
      const savedVersion = loadData(APP_VERSION);
      if (window && !savedVersion) {
        const version = getUrlSearchParam(window.location.search, 'version');
        tvVersion = version ?? '';
      }

      if (tvVersion) {
        return `${device}_${tvVersion}_${webVersion}`;
      }
      return '';
    },
    [device]
  );

  const getAppVersion = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const webVersion = isWeb || isTv ? require('../../../../../package.json')?.version : '';
    const tvAppVersion = isTv ? getTvAppVersion(webVersion) : '';

    let appVersion = DefaultAppVersionValue;

    if (isWeb) {
      appVersion = `${device}_${webVersion}`;
    } else if (isTv && !!tvAppVersion) {
      appVersion = tvAppVersion;
    } else {
      appVersion = `${device}_${DefaultAppVersionValue}`;
    }
    saveData(APP_VERSION, appVersion);
  }, [device, isWeb, isTv, getTvAppVersion]);

  useEffect(() => {
    const savedVersion = loadData(APP_VERSION);
    if (!savedVersion || (savedVersion ?? '').includes(DefaultAppVersionValue)) {
      getAppVersion();
    }
  }, [getAppVersion]);
};

export default useWebAppsVersion;
