import {
  DerivedStatus,
  EstadioEventsCallTypes,
  PagesUrl,
  QueryNames,
  QueryVariablesType,
} from '@warnermmedia/gsp-core/brands/estadio/feature';
import {
  EventFilterNames,
  EventSort,
  EventTypes,
  Feeds,
  QueryGetAllEventsArgs,
  SortDirections,
  Tenant,
  Tenants,
  UserAccount,
} from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { now } from '@warnermmedia/gsp-core/sdk/ui';

const getAllEventsQueryVariables = (
  callType: EstadioEventsCallTypes,
  id = '',
  countStart = '',
  countEnd = '',
  season = '',
  sort: EventSort = EventSort.Date,
  order = SortDirections.Asc,
  mediaFilter?: DerivedStatus
): QueryGetAllEventsArgs => {
  const today = now().utc();
  switch (callType) {
    case EstadioEventsCallTypes.Series:
    case EstadioEventsCallTypes.Tournament: {
      const name = EventFilterNames[callType];
      return {
        ...{
          sort,
          tenant: Tenants.EstadioChile,
        },
        has_media: true,
        ...(order ? { order: order } : {}),
        ...(countStart && countEnd ? { count_start: countStart, count_end: countEnd } : {}),
        ...(season ? { season: parseInt(season, 10) } : {}),
        ...(id && name ? { filterId: [{ name, id }] } : {}),
        ...(mediaFilter ? { media_filter: mediaFilter } : {}),
      };
    }
    case EstadioEventsCallTypes.Team:
      return {
        ...{
          sort,
          start_date: today.format('YYYY-MM-DD'),
          order: order,
          tenant: Tenants.EstadioChile,
        },
        has_media: true,
        ...(countEnd ? { count_end: countEnd } : {}),
        ...(id ? { filterId: [{ name: EventFilterNames.Team, id }] } : {}),
      };
    default:
      return {
        tenant: Tenants.EstadioChile,
        start_date: today.subtract(7, 'day').format('YYYY-MM-DD'),
        end_date: today.add(7, 'day').format('YYYY-MM-DD'),
        has_media: true,
        type: EventTypes.Match,
        order: order,
        sort,
      };
  }
};

export const isQueryReady = (variables: QueryVariablesType, isLogged: boolean, userData?: UserAccount): boolean => {
  switch (variables.queryName) {
    case QueryNames.GET_HOME_PAGE: {
      return !(!!userData && variables.uri === PagesUrl.Home && userData?.favoriteTeam === null);
    }
    case QueryNames.GET_ALL_EVENTS: {
      return variables.callType === EstadioEventsCallTypes.Ticker ? !variables.id : !!variables.id;
    }
    case QueryNames.GET_EVENT_BY_ID:
    case QueryNames.GET_VIDEO_BY_ID:
    case QueryNames.GET_SERIES_BY_ID:
    case QueryNames.GET_LATEST_CLIPS:
    case QueryNames.GET_COMPETITOR_BY_ID:
    case QueryNames.GET_VIDEO_BY_EVENT_ID: {
      return !!variables && !!variables.id;
    }
    case QueryNames.GET_TEAMS: {
      return !!variables && !!variables.uri && isLogged;
    }
    case QueryNames.GET_PROGRAM: {
      return !!variables && !!variables.showId;
    }
    case QueryNames.GET_SERIES_LIST: {
      return !!variables && !!variables.tournamentId;
    }
    default:
      return !!variables;
  }
};

export const getQueryVariables = (variables: QueryVariablesType, userData?: UserAccount) => {
  switch (variables.queryName) {
    case QueryNames.GET_HOME_PAGE: {
      return {
        uri: variables.uri,
        tenant: Tenant.EstadioChile,
        teamId: variables.uri === PagesUrl.Home ? userData?.favoriteTeamId ?? '' : '',
      };
    }
    case QueryNames.GET_PRODUCTS:
    case QueryNames.GET_PROGRAMS:
    case QueryNames.GET_COMMON_PAGE_DATA:
    case QueryNames.GET_TOURNAMENTS: {
      return {
        uri: variables.uri,
        tenant: Tenant.EstadioChile,
      };
    }
    case QueryNames.GET_PROGRAM: {
      return {
        uri: `${variables.uri}/${variables.showId}`,
        tenant: Tenant.EstadioChile,
      };
    }
    case QueryNames.GET_ALL_EVENTS: {
      return getAllEventsQueryVariables(
        variables.callType,
        variables.id,
        variables.countStart,
        variables.countEnd,
        variables.season,
        variables.sort,
        variables.order,
        variables.mediaFilter
      );
    }
    case QueryNames.GET_TICKER_EVENTS: {
      return getAllEventsQueryVariables(variables.callType);
    }
    case QueryNames.GET_SCHEDULE_FEED: {
      const today = now().utc();
      return {
        ...variables,
        ...{
          feed: Feeds.CdfhCl,
          from: today.format('YYYY-MM-DD'),
          to: today.add(1, 'day').format('YYYY-MM-DD'),
        },
      };
    }
    case QueryNames.GET_VIDEO_BY_EVENT_ID: {
      return {
        tenant: Tenant.EstadioChile,
        eventId: `sr:match:${variables.id}`,
      };
    }
    case QueryNames.GET_EVENT_BY_ID: {
      return {
        tenant: Tenants.EstadioChile,
        id: variables.id,
        isSportRadar: variables.isSportRadar,
      };
    }
    case QueryNames.GET_VIDEO_BY_ID: {
      return {
        tenant: Tenants.EstadioChile,
        id: variables.id,
        publishedOnly: true,
      };
    }
    case QueryNames.GET_SERIES_BY_ID: {
      return {
        tenant: Tenants.EstadioChile,
        id: variables.id,
      };
    }
    case QueryNames.GET_SERIES_LIST: {
      return {
        ...{
          tenant: Tenants.EstadioChile,
          tournament_id: variables.tournamentId,
          season: variables.season,
          count_start: variables.countStart,
          count_end: variables.countEnd,
        },
        ...(variables.sort ? { sort: variables.sort } : {}),
        ...(variables.order ? { order: variables.order } : {}),
      };
    }
    case QueryNames.GET_LATEST_CLIPS:
    case QueryNames.GET_COMPETITOR_BY_ID: {
      return {
        tenant: Tenant.EstadioChile,
        teamId: variables.id,
      };
    }
    case QueryNames.GET_TEAMS: {
      return {
        uri: variables.uri,
        tenant: Tenant.EstadioChile,
      };
    }
    default:
      return null;
  }
};
