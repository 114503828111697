import React, { ChangeEvent, CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import CryptoJS from 'crypto-js';
import { defaultStyles, hostedFieldDefaults, hostedFieldDefaultsObj } from './defaults';
import { breakpointsStateStore } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { languageStrings } from '@warnermmedia/gsp-core/brands/estadio/feature';
import { useReactiveVar } from '@apollo/client';
import { VindiciaSuccessResponse } from '@warnermmedia/gsp-core/sdk/data-access';
import { Tooltip } from '@warnermmedia/gsp-core/sdk/ui';
import { PaymentRefreshModal } from '../../modal/paymentRefreshModal';
import { useTheme } from 'react-native-paper';
import get from 'lodash/get';

interface HostedField {
  selector: string;
  placeholder: string;
  label: string;
  format?: string;
  type?: string;
  formatinput?: boolean;
}

interface CustomEvent {
  detail: Record<string, string | boolean>;
}

type HostedFields = Record<string, HostedField | Record<string, string | boolean | Record<string, string>>>;

interface VindiciaWrapperProps {
  fields?: Array<Record<string, string | boolean | undefined | JSX.Element>>;
  options: Record<string, string | number>;
  styles?: Record<string, string>;
  vindicia: {
    setup: (
      obj: Record<
        string,
        | string
        | HostedFields
        | Record<string, string | boolean | undefined | JSX.Element>[]
        | (() => void)
        | ((data: unknown) => void)
        | ((data: EventTarget) => EventTarget)
        | ((data: Record<string, string>) => void)
        | ((data: VindiciaSuccessResponse) => void)
        | ((event: EventTarget) => void)
        | number
      >
    ) => void;
    destroy: () => void;
    clearData: () => void;
    resetCompleteStatus: () => void;
    isValid: (field?: string) => boolean;
    dataLength: (field?: string) => number;
  };
  onSubmitCompleteEvent: (data: VindiciaSuccessResponse) => void;
  onSubmitEvent: (data: Record<string, string>) => boolean;
  children?: React.ReactNode;
  vinValidate?: string;
  currency?: string;
  ignoreCvnPolicy?: boolean;
  minChargebackProb?: number;
  sourceIp?: string;
  ignoreAvsPolicy?: boolean;
  onVindiciaFieldEvent?: (data: EventTarget) => void;
  onSubmitCompleteFailedEvent: (data: Record<string, string>) => void;
  validateAcceptTerms: () => boolean;
  validateCouponUsage: () => boolean;
  renderTerms?: () => React.ReactNode;
  termsAccepted: boolean;
  sessionIsLoading: boolean;
  renderConsent?: () => React.ReactNode;
}

export const VindiciaFormWrapper = (props: VindiciaWrapperProps) => {
  const cardHolderPattern = /^[a-zA-Z\s]*$/;
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const { breakpointHelpers, currentBreakpoints } = breakpoints;
  const { colors } = useTheme();
  const [formError, setFormError] = useState<Record<string, string | boolean>>({
    vin_credit_card_expiration_month: '',
    vin_credit_card_expiration_year: '',
    vin_credit_card_account: '',
    vin_account_holder: '',
    vin_credit_card_cvn: '',
  });
  const formErrorObj: Record<string, string> = {
    vin_credit_card_expiration_month: languageStrings.default.cardExpiryDateError,
    vin_credit_card_expiration_year: languageStrings.default.cardExpiryDateError,
    vin_credit_card_account: languageStrings.default.cardNumberError,
    vin_account_holder: languageStrings.default.cardNameError,
    vin_credit_card_cvn: languageStrings.default.cardCVNError,
  };
  const [vinAccountHolder, setVinAccountHolder] = useState(false);
  const submitBtnRef = useRef();
  const addFieldsToState = () => {
    const { fields } = props;
    const formFields: Record<string, string> = {};
    if (fields) {
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      /* @ts-ignore:*/
      fields.forEach((field: Record<string, string>) => {
        if (field.name) {
          formFields[field.name] = field.value || '';
        }
      });
    }
    return formFields;
  };

  const checkFormValidity = () => {
    const { isValid } = vindiciaState;
    const { vindicia } = props;

    if (isValid !== vindicia.isValid()) {
      setVindiciaState((prevState) => ({
        ...prevState,
        isValid: !isValid,
      }));
    }
  };

  const getDatesFormValidity = (field: string) => {
    const { vindicia } = props;
    let datesFormValidity = {};
    if (field === 'vin_credit_card_expiration_month') {
      datesFormValidity = {
        vin_credit_card_expiration_year: vindicia.isValid('vin_credit_card_expiration_year'),
      };
    }

    if (field === 'vin_credit_card_expiration_year') {
      datesFormValidity = {
        vin_credit_card_expiration_month: vindicia.isValid(field),
        vin_credit_card_expiration_year: vindicia.isValid(field),
      };
    }
    return datesFormValidity;
  };

  const onVindiciaFieldChange = (event: CustomEvent) => {
    let field: string;
    switch (event.detail.fieldType) {
      case 'cardNumber':
        field = 'vin_credit_card_account';
        break;
      case 'cvn':
        field = 'vin_credit_card_cvn';
        break;
      case 'expirationYearInput':
        field = 'vin_credit_card_expiration_year';
        break;
      case 'expirationMonthInput':
        field = 'vin_credit_card_expiration_month';
        break;
      case 'name':
        field = 'vin_account_holder';
        break;
      default:
        break;
    }
    setFormError((prevState) => ({
      ...prevState,
      ...(field ? { [field]: event.detail.isValid, ...getDatesFormValidity(field) } : {}),
    }));
    checkFormValidity();
  };

  const onSubmit = () => {
    const { formFields } = vindiciaState;
    const { onSubmitEvent } = props;
    setVindiciaState((prevState) => ({
      ...prevState,
      submitInProgress: true,
    }));
    return onSubmitEvent(formFields);
  };

  const onSubmitFail = (data: Record<string, string>) => {
    const { onSubmitCompleteFailedEvent } = props;
    setVindiciaState((prevState) => ({
      ...prevState,
      submitInProgress: false,
    }));
    return onSubmitCompleteFailedEvent(data);
  };

  const onSubmitComplete = (data: VindiciaSuccessResponse) => {
    const { onSubmitCompleteEvent } = props;
    setVindiciaState((prevState) => ({
      ...prevState,
      submitInProgress: false,
    }));
    return onSubmitCompleteEvent(data);
  };

  const handleKeyPress = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (event: any) => {
      if (event.keyCode === 13) {
        handleSubmitBtnPress(event);
      }
    },
    []
  );

  const constructOptions = () => {
    const { options, fields, styles } = props;

    const hostedFields: HostedFields = {};
    const localFields = fields ? [...fields] : [];

    if (fields) {
      fields.forEach((item) => {
        const hostedField = hostedFieldDefaultsObj[item.type as string];
        if (hostedField) {
          hostedFields[hostedField.name as string] = {
            selector: (item.selector as string) || hostedField.selector,
            placeholder: (item.placeholder as string) || hostedField.placeholder || '',
            label: (item.label as string) || (hostedField.label as string),
            format: (item.format as string) || (hostedField.format as string),
            formatinput: (item.formatinput as boolean) || (hostedField.formatinput as boolean),
            maskinput: hostedField.maskinput || false,
          };
        }
      });
    } else {
      // if no fields are passed, add the default fields to the form
      for (let i = 0; i < hostedFieldDefaults.length; i += 1) {
        if (hostedFieldDefaults[i].isDefault) {
          const option = {
            selector: hostedFieldDefaults[i].selector,
            label: hostedFieldDefaults[i].label as string,
            format: hostedFieldDefaults[i].format as string,
            placeholder: hostedFieldDefaults[i].placeholder || '',
            type: hostedFieldDefaults[i].name,
            formatinput: hostedFieldDefaults[i].formatinput as boolean,
            maskinput: hostedFieldDefaults[i].maskinput || false,
          };
          hostedFields[hostedFieldDefaults[i].name] = option;
          localFields.push(option);
        }
      }
    }

    hostedFields.styles = styles || defaultStyles;
    const iframeHeightPadding = options.iframeHeightPadding || 0;
    options.formId = options.formId || 'mainForm';

    const localOptions = {
      ...options,
      hostedFields,
      fields: localFields,
      onSubmitEvent: onSubmit,
      onSubmitCompleteEvent: onSubmitComplete,
      onSubmitCompleteFailedEvent: onSubmitFail,
      onVindiciaFieldEvent: onVindiciaFieldChange,
      iframeHeightPadding,
    };

    return localOptions;
  };

  const shouldLoadFn = () => {
    const { options } = props;
    return options.vindiciaAuthId;
  };

  const [vindiciaState, setVindiciaState] = useState({
    sessionId: '',
    sessionHash: '',
    localOptions: constructOptions(),
    isValid: false,
    submitInProgress: false,
    formFields: addFieldsToState(),
    shouldLoad: shouldLoadFn(),
  });

  useEffect(() => {
    const { vindicia } = props;
    const { localOptions, shouldLoad } = vindiciaState;

    if (shouldLoad) {
      updateHiddenFields();
      if (vindicia && vindicia.setup) {
        vindicia.setup(localOptions);
      }
    }
    return () => {
      if (vindicia && vindicia.destroy) {
        vindicia.destroy();
      }
    };
  }, []);

  const onFieldChange = (field: Record<string, string>, e: ChangeEvent<HTMLInputElement>) => {
    const { formFields } = vindiciaState;

    setVindiciaState((prevState) => ({
      ...prevState,
      formFields: {
        ...formFields,
        [field.name]: e.target.value,
      },
    }));
    checkFormValidity();
  };
  const updateHiddenFields = () => {
    const { options } = props;

    if (options.hmac && typeof options.hmac === 'string') {
      const otlHmacKey = options.hmac;
      const sessionId = `SEAT_PMT_${Math.random().toString(36).substr(2, 9)}`;
      const sessionHash = CryptoJS.HmacSHA512(`${sessionId}#POST#/payment_methods`, otlHmacKey);
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      /* @ts-ignore: sessionHash is a WordArray*/
      setVindiciaState((prevState) => ({
        ...prevState,
        sessionId,
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        /* @ts-ignore: sessionHash is a WordArray*/
        sessionHash,
      }));
    }
  };

  const parseStyles = () => {
    const {
      localOptions: {
        hostedFields: { styles },
      },
    } = vindiciaState;

    let styleOutput = '';
    const stylesObj = styles as Record<string, Record<string, string>>;

    Object.keys(stylesObj).forEach((selector: string) => {
      styleOutput += `${selector} {\n`;
      Object.keys(stylesObj[selector]).forEach((rule) => {
        styleOutput += `  ${rule}: ${stylesObj[selector][rule]};\n`;
      });
      styleOutput += '}\n';
    });

    return styleOutput;
  };

  const yearToolTipLeft = currentBreakpoints.isTiny
    ? 70
    : currentBreakpoints.isTnySm || breakpointHelpers.isMediumScreen
    ? 80
    : 90;

  const validateCardHolder = () => {
    return vindiciaState.formFields.name.trim().length < 3 || !vindiciaState.formFields.name.match(cardHolderPattern);
  };

  const renderFields = () => {
    const {
      localOptions: { fields, hostedFields },
      formFields,
    } = vindiciaState;

    return (
      hostedFields &&
      fields.map((fieldItem, index) => {
        const field = fieldItem as Record<string, string>;
        let selector;
        let inputField;
        const showErrorBorder =
          formError.vin_account_holder !== '' && !!formError.vin_account_holder && field.name === 'name' && index === 0;
        const borderStyle = (showErrorBorder
          ? { borderWidth: 1, borderColor: vinAccountHolder ? 'transparent' : 'red', boxSizing: 'border-box' }
          : { boxSizing: 'border-box' }) as CSSProperties | undefined;

        const validHostedFieldValues: Array<string> = hostedFieldDefaults.reduce(
          (acc: Array<string>, curr) => acc.concat(curr.name),
          []
        );

        if (validHostedFieldValues.includes(field.type)) {
          selector = hostedFields[field.type || field.name].selector as string;
          selector = selector.substring(1, selector.length);
          if (field.label === languageStrings.default.expiryDate) {
            inputField = (
              <div style={{ display: 'flex', width: '50%', justifyContent: 'flex-start' }}>
                <div id={selector} /> <div id="vin_credit_card_expiration_year" />
              </div>
            );
          } else if (field.label === 'year') {
            inputField = '';
          } else {
            inputField = <div id={selector} />;
          }
        } else {
          inputField = (
            <input
              className={`field-group__input ${field.className || ''}`}
              type={field.type || 'text'}
              placeholder={field.placeholder || ''}
              value={formFields[field.name]}
              id={field.name}
              onChange={(e) => onFieldChange(field, e)}
              style={{ backgroundColor: colors.tenantBackground.light.surfaceBase }}
            />
          );
        }

        return (
          <div
            className="field-group"
            key={`vin-field-${field.label || field.type || `jsx-${field.name || index}`}`}
            style={{ marginTop: '10px' }}
          >
            {field.label && field.label !== 'year' && (
              <label className="field-group__label" htmlFor={field.name}>
                {field.label === 'Credit Card Number' ? languageStrings.default.cardNumberLabel : field.label}
              </label>
            )}
            {field.name === 'name' ? (
              <input
                type="text"
                id="vin_account_holder"
                name="vin_account_holder"
                onKeyDown={(event) => {
                  if (!event.key.match(cardHolderPattern)) {
                    event.preventDefault();
                  }
                  handleKeyPress(event);
                }}
                style={borderStyle}
                onChange={(e) => onFieldChange(field, e)}
                onBlur={() => {
                  setVinAccountHolder(false);
                  setFormError((prevState) => ({
                    ...prevState,
                    vin_account_holder: validateCardHolder() ? true : '',
                  }));
                }}
              />
            ) : (
              field.render || inputField
            )}
            {field.name === 'name' ? (
              <Tooltip
                opacity={1}
                position="left"
                tooltipText={formErrorObj.vin_account_holder}
                visible={formError.vin_account_holder !== '' && !!formError.vin_account_holder}
              />
            ) : field.label === languageStrings.default.expiryDate ? (
              <Tooltip
                containerStyle={{
                  marginLeft:
                    !formError.vin_credit_card_expiration_year && formError.vin_credit_card_expiration_month
                      ? yearToolTipLeft
                      : 0,
                }}
                opacity={1}
                position="left"
                tooltipText={formErrorObj[selector as string]}
                visible={
                  formError.vin_credit_card_expiration_year === false ||
                  formError.vin_credit_card_expiration_month === false
                }
              />
            ) : (
              <Tooltip
                opacity={1}
                position="left"
                tooltipText={formErrorObj[selector as string]}
                visible={
                  !!(
                    field.label !== 'year' &&
                    formError[selector as string] !== '' &&
                    !formError[selector as string] &&
                    formErrorObj[selector as string]
                  )
                }
              />
            )}
          </div>
        );
      })
    );
  };

  const { sessionId, sessionHash, isValid, submitInProgress, shouldLoad } = vindiciaState;

  const {
    options,
    children,
    vindicia,
    vinValidate,
    currency,
    ignoreCvnPolicy,
    minChargebackProb,
    sourceIp,
    ignoreAvsPolicy,
    sessionIsLoading,
  } = props;

  const handleSubmitBtnPress = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    event.preventDefault();
    const couponUsageCheck = props.validateCouponUsage();
    const isCardNameInValid = validateCardHolder();
    if (!isValid || isCardNameInValid) {
      setFormError({
        vin_credit_card_expiration_month: !!formError.vin_credit_card_expiration_month,
        vin_credit_card_expiration_year: !!formError.vin_credit_card_expiration_year,
        vin_credit_card_account: !!formError.vin_credit_card_account,
        vin_account_holder: isCardNameInValid,
        vin_credit_card_cvn: !!formError.vin_credit_card_cvn,
      });
      setVinAccountHolder(true);
      return;
    }
    if (!couponUsageCheck) {
      return;
    }
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    /* @ts-ignore: programmatically clicking on vindica submit after running estadio validations*/
    submitBtnRef.current.click();
  };
  const formIsLoading = sessionIsLoading || submitInProgress;

  const isFormValid = useCallback(() => {
    const { isValid } = vindiciaState;
    const { vindicia } = props;
    const isCardNameInvalid = validateCardHolder();

    const hasNoErrors = Object.keys(formError).every((key) => {
      const value = get(formError, key, '');
      return key !== 'vin_account_holder' ? value !== '' && !!value : value === '';
    });

    return !isCardNameInvalid && hasNoErrors && (isValid || vindicia.isValid());
  }, [vindiciaState, props, validateCardHolder, formError]);

  return vindicia && shouldLoad ? (
    <form id={(options.formId as string) || 'mainForm'}>
      <input name="vin_session_id" value={sessionId} type="hidden" />
      <input name="vin_session_hash" value={sessionHash} type="hidden" />
      {vinValidate && <input name="vin_validate" value={vinValidate} type="hidden" />}
      {ignoreAvsPolicy && <input name="vin_ignore_avs_policy" value="1" type="hidden" />}
      {ignoreCvnPolicy && <input name="vin_ignore_cvn_policy" value="1" type="hidden" />}
      {minChargebackProb && <input name="vin_min_chargeback_probability" value={minChargebackProb} type="hidden" />}
      {sourceIp && <input name="vin_source_ip" value={sourceIp} type="hidden" />}
      {currency && <input name="vin_currency" value="EUR" type="hidden" />}
      <style type="text/css" dangerouslySetInnerHTML={{ __html: parseStyles() }} />
      {children || (
        <div style={{ width: '100%', maxWidth: '367px' }}>
          {renderFields()}
          <div style={{ margin: '24px 0' }}>{props.renderConsent && props.renderConsent()}</div>
          <button
            type="button"
            id="button"
            onClick={handleSubmitBtnPress}
            onKeyDown={handleKeyPress}
            style={{
              padding: '5px',
              border: 0,
              width: '100%',
              backgroundColor: colors.fill.action.accent01,
              maxWidth: 367,
              borderColor: 'transparent',
              color: colors.tenantBackground.light.surfaceBase,
              borderRadius: '4px',
              height: '40px',
              fontFamily: 'Oswald-Medium',
              fontSize: '20px',
              fontWeight: 500,
              cursor: 'pointer',
              opacity: !isFormValid() ? 0.4 : 1,
            }}
            disabled={!isFormValid()}
          >
            {formIsLoading ? languageStrings.default.loadingText : languageStrings.default.buyNowLabel}
          </button>
          <button
            hidden
            type="submit"
            id="submitButton"
            /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
            /* @ts-ignore: using ref to programmatically click on vindica submit */
            ref={submitBtnRef}
          >
            {languageStrings.default.subscriptionButtonText}
          </button>
          {formIsLoading && isFormValid() ? (
            <PaymentRefreshModal
              visible={formIsLoading}
              title={languageStrings.default.doNotRefreshLabel}
              description={languageStrings.default.doNotRefreshDescription}
            />
          ) : null}
        </div>
      )}
    </form>
  ) : null;
};

export default VindiciaFormWrapper;
