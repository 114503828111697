import {
  carouselBase,
  QueryNames,
  useCMSQueryDataResults,
  useFormatCarouselData,
  useIsMountedRef,
} from '@warnermmedia/gsp-core/brands/estadio/feature';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { CmsCarousel } from '../cmsPage';
import { Video } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { CAROUSEL_KEY, ROUTE_FOCUS_KEY, saveData } from '@warnermmedia/gsp-core/sdk/data-access';
import { useFocusable } from '@noriginmedia/norigin-spatial-navigation';

export type LatestClipsSwimlaneProps = React.ComponentPropsWithRef<typeof View> & {
  /**
   * Team id on sports Radar
   */
  sportsRadarId: string;
  hasLatestMatches: boolean;
};

const LatestClipsSwimlane = ({ sportsRadarId, hasLatestMatches }: LatestClipsSwimlaneProps) => {
  const [data, setData] = useState<carouselBase[]>();
  const { pageData: latestClips } = useCMSQueryDataResults<Video[]>({
    queryName: QueryNames.GET_LATEST_CLIPS,
    id: sportsRadarId,
  });

  const isMountedRef = useIsMountedRef();
  const { formatVideos } = useFormatCarouselData();
  const { setFocus } = useFocusable();

  useEffect(() => {
    if (latestClips && isMountedRef.current) {
      setData(formatVideos(latestClips));
    }
  }, [latestClips, isMountedRef, formatVideos]);

  useEffect(() => {
    if (data && data?.length > 0 && !hasLatestMatches) {
      const key = CAROUSEL_KEY(2);
      setFocus(key);
      saveData(ROUTE_FOCUS_KEY, key);
    }
  }, [data, hasLatestMatches, setFocus]);

  return (
    <View>
      {data && data.length ? (
        <CmsCarousel
          id="latest-videos"
          type="video"
          format="generic"
          content={[]}
          allItems={data}
          headline="ÚLTIMAS NOTICIAS Y CLIPS"
          weight={2}
          laneIndex={2}
        />
      ) : null}
    </View>
  );
};

export default LatestClipsSwimlane;
