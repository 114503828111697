import { useCallback } from 'react';
import { encodeBase64, useGetDevice } from '@warnermmedia/gsp-core/sdk/ui';
import { PinAndPair } from './index';
import {
  Dalton,
  getAuthData,
  getUserPrimaryEmail,
  languageStrings,
  Tve,
  useMparticleCustomEventObject,
} from '../../..';
import {
  CheckCodeResponse,
  clearData,
  CodeStatus,
  DALTON_AUTH_TOKEN,
  EMAIL_VERIFICATION_STATUS,
  MParticleAppConfigParams,
  MParticleCustomEventTypes,
  mParticleEventProcessor,
  saveData,
  setMparticleUserId,
  TVE_TOOLBOX_AUTH_TOKEN,
} from '@warnermmedia/gsp-core/sdk/data-access';
import { AxiosError } from 'axios';
import get from 'lodash/get';
import { useReactiveVar } from '@apollo/client';
import {
  isAppTransitioning,
  isTveAuth,
  loginAPIStateStore,
  PIN_CODE,
} from '@warnermmedia/gsp-core/brands/estadio/data-access';

interface PinAndPairActions {
  getCode: (
    onSuccess: (code: string) => void,
    onError: (message: string) => void,
    isRefreshing: boolean
  ) => Promise<void>;
  validateCode: (code: string, onSuccess: () => void, onError: (message: string) => void) => void;
  claimCode: (code: string, userId: string, onError: (message: string) => void, onSuccess?: () => void) => void;
  checkCode: (
    code: string,
    onSuccess: (codeCheckResponse: CheckCodeResponse | undefined) => void,
    onError: (message: string) => void
  ) => Promise<CheckCodeResponse | void>;
  successPinLoginRedirect: (resp: CheckCodeResponse | undefined, successCallBack?: () => void) => void;
}

export const usePinAndPairActions = (mParticleEventDataOptions: MParticleAppConfigParams = {}): PinAndPairActions => {
  const pinAndPair = PinAndPair();
  const language = languageStrings.default;
  const mParticleEventData = useMparticleCustomEventObject(mParticleEventDataOptions);
  const { device } = useGetDevice();
  const isTveAuthenticated = useReactiveVar(isTveAuth);
  const { handleIsAuthenticated: handleIsTveAuthenticated } = Tve();
  const dalton = Dalton();

  const getUserOptions = useCallback((): string => {
    const authKey = isTveAuthenticated ? TVE_TOOLBOX_AUTH_TOKEN : DALTON_AUTH_TOKEN;
    const { authToken, tveUserId, tveProvider, user } = loginAPIStateStore();
    const userPrimaryEmail = getUserPrimaryEmail({ user });
    const userData = {
      ...{
        ...(userPrimaryEmail ? { email: userPrimaryEmail } : {}),
        ...(authToken ? { token: authToken } : {}),
        ...(tveUserId ? { tveUserId } : {}),
        ...(tveProvider ? { provider: tveProvider } : {}),
        ...getAuthData(authKey),
      },
    };
    return encodeBase64(userData);
  }, [isTveAuthenticated]);

  const handleError = useCallback(
    (
      error: AxiosError,
      eventName: MParticleCustomEventTypes,
      errorMessage: string,
      onError: (message: string) => void
    ) => {
      const message = get(error, 'response.status', '') === '404' ? errorMessage : language.codeValidationGenericError;
      if (eventName) {
        mParticleEventProcessor.pushMParticleEvent(eventName, {
          ...mParticleEventData,
          ...{ error_code: get(error, 'message', '') },
        });
      }
      onError(message);
    },
    [language, mParticleEventData]
  );

  const getCode = useCallback(
    async (
      onSuccess: (code: string) => void,
      onError: (message: string) => void,
      isRefreshing = false
    ): Promise<void> => {
      try {
        mParticleEventProcessor.pushMParticleEvent(
          [
            MParticleCustomEventTypes.CodeGenerationStarEvent,
            ...(isRefreshing ? [MParticleCustomEventTypes.CodeRefreshEvent] : []),
          ],
          mParticleEventData
        );
        const getCodeResponse = await pinAndPair.getCode(device);
        if (getCodeResponse.success && getCodeResponse.data?.regCode) {
          mParticleEventProcessor.pushMParticleEvent(
            MParticleCustomEventTypes.CodeGenerationCompleteEvent,
            mParticleEventData
          );
          clearData(PIN_CODE);
          onSuccess(getCodeResponse.data.regCode);
        } else {
          throw getCodeResponse.error;
        }
      } catch (e) {
        clearData(PIN_CODE);
        handleError(
          e as AxiosError,
          MParticleCustomEventTypes.CodeGenerationErrorEvent,
          language.codeValidationIncorrectCodeError,
          onError
        );
      }
    },
    [pinAndPair, mParticleEventData, handleError, device, language]
  );

  const validateCode = useCallback(
    async (code: string, onSuccess: () => void, onError: (message: string) => void): Promise<void> => {
      try {
        mParticleEventProcessor.pushMParticleEvent(
          MParticleCustomEventTypes.CodeValidationStarEvent,
          mParticleEventData
        );
        const codeValidationResponse = await pinAndPair.validateCode(code);
        if (codeValidationResponse.success) {
          mParticleEventProcessor.pushMParticleEvent(
            MParticleCustomEventTypes.CodeValidationCompleteEvent,
            mParticleEventData
          );
          onSuccess();
        } else {
          throw codeValidationResponse.error;
        }
      } catch (e) {
        handleError(
          e as AxiosError,
          MParticleCustomEventTypes.CodeValidationErrorEvent,
          language.codeValidationIncorrectCodeError,
          onError
        );
      }
    },
    [pinAndPair, mParticleEventData, handleError, language]
  );

  const claimCode = useCallback(
    async (code: string, userId: string, onError: (message: string) => void, onSuccess?: () => void): Promise<void> => {
      try {
        isAppTransitioning(true);
        mParticleEventProcessor.pushMParticleEvent(MParticleCustomEventTypes.CodePairingStarEvent, mParticleEventData);
        const codeClaimResponse = await pinAndPair.claimCode(
          code,
          userId,
          loginAPIStateStore().authToken ?? '',
          getUserOptions()
        );
        if (codeClaimResponse?.success && codeClaimResponse.data && codeClaimResponse.data.isClaimed) {
          mParticleEventProcessor.pushMParticleEvent(
            MParticleCustomEventTypes.CodePairingCompleteEvent,
            mParticleEventData
          );
          onSuccess && onSuccess();
          isAppTransitioning(false);
        } else {
          throw codeClaimResponse.error ? codeClaimResponse.error : new Error(codeClaimResponse.data?.message);
        }
      } catch (e) {
        isAppTransitioning(false);
        handleError(
          e as AxiosError,
          MParticleCustomEventTypes.CodePairingErrorEvent,
          language.codeValidationIncorrectCodeError,
          onError
        );
      }
    },
    [pinAndPair, mParticleEventData, language, handleError, getUserOptions]
  );

  const checkCode = useCallback(
    async (
      code: string,
      onSuccess: (codeCheckResponse: CheckCodeResponse | undefined) => void,
      onError: (message: string) => void
    ): Promise<void> => {
      try {
        const codeCheckResponse = await pinAndPair.checkCode(code);
        if (codeCheckResponse?.success) {
          const { isClaimed, status, regCode } = codeCheckResponse?.data || {};
          if (isClaimed && status === CodeStatus.Paired && code === regCode) {
            mParticleEventProcessor.pushMParticleEvent(MParticleCustomEventTypes.PairSuccessEvent, {
              ...mParticleEventData,
              ...{ device_paired: device },
            });
          }
          onSuccess(codeCheckResponse.data);
        } else {
          throw codeCheckResponse.error ? codeCheckResponse.error : new Error(codeCheckResponse.data?.message);
        }
      } catch (e) {
        handleError(
          e as AxiosError,
          MParticleCustomEventTypes.CodePairingErrorEvent,
          language.codeValidationIncorrectCodeError,
          onError
        );
      }
    },
    [pinAndPair, mParticleEventData, device, handleError, language]
  );

  /**
   * It takes a string as an argument and returns a boolean
   * @param {string} id - the id of the user
   * @returns A boolean value
   */
  const handleIsTve = (id: string | undefined): boolean => {
    const result = id?.includes('tve');
    return !!result;
  };

  const loginUser = useCallback(
    async (uid: string, authToken: string, options?: string) => {
      const isTve = handleIsTve(uid);
      const userData = JSON.parse(options || '');
      const authKey = isTve ? TVE_TOOLBOX_AUTH_TOKEN : DALTON_AUTH_TOKEN;

      saveData(authKey, {
        ...userData,
      });

      if (isTve) {
        handleIsTveAuthenticated(true, authToken, userData?.provider, userData?.tveUserId);
      } else {
        await dalton.fetchUserInfo({ token: authToken, emailStatus: EMAIL_VERIFICATION_STATUS.CONFIRMED });
      }
      setMparticleUserId(uid, userData.email);
    },
    [dalton, handleIsTveAuthenticated]
  );

  /**
   * A callback function that is called when the user successfully logs in.
   * @param [cb] - a callback function that will be called after the user has successfully logged in.
   */
  const successPinLoginRedirect = useCallback(
    async (resp: CheckCodeResponse | undefined, onSuccessCallBack?: () => void) => {
      isAppTransitioning(true);
      const { isClaimed, authToken, regCode, options, uid } = resp || {};
      if (isClaimed && regCode && authToken && uid) {
        await loginUser(uid, authToken, options?.decoded);
        onSuccessCallBack?.();
      }
      isAppTransitioning(false);
    },
    [loginUser]
  );

  return {
    getCode,
    validateCode,
    claimCode,
    checkCode,
    successPinLoginRedirect,
  };
};
