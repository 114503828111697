import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { AppState, AppStateStatus, View } from 'react-native';
import QRCode from 'react-qr-code';
import {
  CustomButton,
  MessageDisplay,
  MetaTags,
  TextContent,
  useGetDevice,
  Variant,
} from '@warnermmedia/gsp-core/sdk/ui';
import { getStyles } from './subscriptions.styles';
import {
  callbackNavigateExternal,
  Dalton,
  languageStrings,
  PagesUrl,
  QueryNames,
  useCMSPageMetaTags,
  useCMSQueryDataResults,
  useIsMountedRef,
  useLogout,
  useMparticleCustomEventObject,
  useMparticleScreenEvent,
} from '@warnermmedia/gsp-core/brands/estadio/feature';
import {
  AppConfigContext,
  LOGINAPI_STATE_INITIAL,
  Page,
  PIN_CODE,
} from '@warnermmedia/gsp-core/brands/estadio/data-access';
import {
  FocusKeys,
  loadData,
  MParticleCustomEventTypes,
  mParticleEventProcessor,
} from '@warnermmedia/gsp-core/sdk/data-access';
import { SubscriptionFailure } from './subscriptionFailure';
import { LayoutType, LayoutWrapper, TitleType } from '../layoutWrapper';
import SubscriptionSwimlane from './subscriptionSwimlane/subscriptionsSwimlane';
import { LoadingAppIndicator } from '../loadingAppIndicator';
import { SubscriptionSuccess } from './subscriptionSuccess';
import { EstadioLinkButton } from '../components';
import get from 'lodash/get';
import { useTheme } from 'react-native-paper';

export interface Loaders {
  coupon: boolean;
  session: boolean;
  tokenRefresh: boolean;
}

export const Subscriptions = () => {
  const [loadingWebSubPage, setLoadingWebSubPage] = useState(false);
  const appState = useRef(AppState.currentState);
  const [appStateVisible, setAppStateVisible] = useState(appState.current);
  const appConfig = useContext(AppConfigContext);
  const dalton = Dalton();
  const language = languageStrings.default;
  const { isPwa, isMobileDevice, isAndroidDevice, isIosDevice, isAndroidtv, isTv, isWeb } = useGetDevice();
  const { colors } = useTheme();
  const styles = getStyles(isAndroidtv, colors);
  const { pageData: pageUriData } = useCMSQueryDataResults<Page>({
    queryName: QueryNames.GET_PRODUCTS,
    uri: PagesUrl.Subscription,
  });
  const { title, type, cmsId } = useCMSPageMetaTags(pageUriData);
  useMparticleScreenEvent(type, title, cmsId);
  const mParticleEventData = useMparticleCustomEventObject({
    section: type,
    contentTitle: title,
    contentId: cmsId,
  });
  // change back to false when coupon mapping api is ready
  const [couponApplied, setCouponApplied] = useState(true);
  const [couponSuccessfullyApplied, setCouponSuccessfullyApplied] = useState(false);
  const [loaders, setLoaders] = useState<Loaders>({
    coupon: false,
    session: false,
    tokenRefresh: false,
  });
  const [purchaseState, setPurchaseState] = useState('');
  const [contentItemIndex, setContentItemIndex] = useState<number>(0);
  const pageTitle = isMobileDevice ? language.subsMobilePageTitle : language.subsWebPageTitle;
  const webVerificationLink = appConfig?.appConfig?.webUrl ?? '';
  const pinValidationCode = loadData(PIN_CODE);
  const { logout } = useLogout();
  const isMountedRef = useIsMountedRef();
  const subscriptionEnabled = appConfig?.appConfig?.subscriptionEnabled ?? true;

  useEffect(() => {
    checkTokenForBillingPurchase();
  }, []);

  const listener = (nextAppState: AppStateStatus) => {
    appState.current = nextAppState;
    setAppStateVisible(appState.current);
  };

  useEffect(() => {
    isAndroidDevice && AppState.addEventListener('change', listener);
    return () => {
      isAndroidDevice && AppState.removeEventListener('change', listener);
    };
  }, []);

  useEffect(() => {
    if (appStateVisible === 'active' && isAndroidDevice) {
      setLoadingWebSubPage(false);
    }
  }, [appStateVisible]);

  const pushPlanNameEvent = (itemIndex: number) => {
    const components = pageUriData?.components && pageUriData?.components[0];
    const contentArr = components?.content && components?.content;
    const productId = get(contentArr, `[${itemIndex}].product[0].productId`, null);
    if (productId) {
      mParticleEventProcessor.pushMParticleEvent(MParticleCustomEventTypes.PlanSelectNameEvent, {
        ...mParticleEventData,
        ...{ subscription_type: productId },
      });
    }
  };

  useEffect(() => {
    if (pageUriData && isMountedRef) {
      pushPlanNameEvent(contentItemIndex);
    }
  }, [pageUriData, contentItemIndex, isMountedRef]);

  const checkTokenForBillingPurchase = async () => {
    const daltonToken = loadData(LOGINAPI_STATE_INITIAL.DALTON_AUTH_TOKEN);
    const { token } = isPwa ? JSON.parse(daltonToken) : daltonToken;
    const hasBillingPurchase = token.search('billing.purchase');
    if (hasBillingPurchase < 0) {
      handleSetLoader('tokenRefresh', true);
      const { data } = await dalton.refreshToken(true);
      const newTokenCheck = data?.authToken?.search('billing.purchase') as number;
      if (newTokenCheck < 0) {
        logout();
      } else {
        handleSetLoader('tokenRefresh', false);
      }
    }
  };

  const handleSetLoader = useCallback((field: string, value: boolean) => {
    setLoaders((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  }, []);

  const handleRedirection = useCallback(() => {
    setLoadingWebSubPage(true);
    callbackNavigateExternal(webVerificationLink ? `${webVerificationLink}/subscribe` : '');
  }, [webVerificationLink]);

  const renderSubscriptionBody = () => {
    const language = languageStrings.default;

    if (isTv) {
      return (
        <View style={styles.tvWrapper}>
          <View style={styles.tvWrapperLeft}>
            <TextContent style={styles.title}>{language.subsMobilePageTitle}</TextContent>
            <MessageDisplay
              displayMessage={language.tvSubscriptionText(webVerificationLink)}
              variant={Variant.Generic}
              textStyle={styles.messageDisplayTV}
              displayIcon={'alert-circle-outline'}
              iconSize={40}
              wrapperStyle={styles.messageDisplayTVWrapper}
            />
            <EstadioLinkButton
              onPress={logout}
              label={language.tvSubscriptionCTAButtonText}
              btnStyle={styles.tvLinkButton}
              labelStyle={styles.tvLinkButtonLabel}
              focusKey={FocusKeys.SUBSCRIPTION_TV_BUTTON}
            />
          </View>
          <View style={styles.tvWrapperRight}>
            <QRCode value={`${webVerificationLink}/login`} size={isAndroidtv ? 300 : 406} />
          </View>
        </View>
      );
    }

    if (isMobileDevice) {
      const buttonLabel = isIosDevice
        ? language.returnLabel
        : loadingWebSubPage
        ? language.loadingText
        : language.subscribeNowLabel;

      return (
        <View>
          <MessageDisplay
            displayMessage={language.subscriptionRequirementDescription}
            variant={Variant.Generic}
            textStyle={styles.messageDisplayMobile}
            displayIcon={'alert-circle-outline'}
          />
          {!isIosDevice && (
            <CustomButton
              mode="contained"
              btnStyle={styles.ctaMobile}
              onPress={handleRedirection}
              label={buttonLabel}
            />
          )}
        </View>
      );
    }

    return (
      <SubscriptionSwimlane
        pageUriData={pageUriData}
        mParticleEventData={mParticleEventData}
        setPurchaseState={setPurchaseState}
        couponApplied={couponApplied}
        setCouponApplied={setCouponApplied}
        setCouponSuccessfullyApplied={setCouponSuccessfullyApplied}
        loaders={loaders}
        handleSetLoader={handleSetLoader}
        setContentItemIndex={setContentItemIndex}
      />
    );
  };

  if (purchaseState === 'failure') {
    return (
      <SubscriptionFailure
        handleTryAgain={() => {
          setPurchaseState('');
        }}
      />
    );
  }

  if (purchaseState === 'success') {
    return <SubscriptionSuccess source={couponSuccessfullyApplied ? 'redeemCoupon' : 'subscriptions'} />;
  }

  return (
    <LayoutWrapper
      layoutType={LayoutType.Full}
      titleType={TitleType.Left}
      pageTitle={isTv || !subscriptionEnabled ? '' : pageTitle}
      subTitle={pinValidationCode ? language.subsPinValidationSubtitle : ''}
      overrideChildrenWrapper={isMobileDevice ? { paddingHorizontal: 25 } : { paddingHorizontal: 0 }}
    >
      {isWeb && <MetaTags title={title} />}
      <View style={styles.wrapper}>{loaders.tokenRefresh ? <LoadingAppIndicator /> : renderSubscriptionBody()}</View>
    </LayoutWrapper>
  );
};

export default Subscriptions;
