import React, { useCallback } from 'react';
import { StyleProp, TextStyle, View, ViewStyle } from 'react-native';
import { TextContent } from '@warnermmedia/gsp-core/sdk/ui';
import { EstadioButton, EstadioTextInput } from '@warnermmedia/gsp-core/brands/estadio/ui';
import { useOnEnterKeyPress } from '@warnermmedia/gsp-core/brands/estadio/feature';
import { breakpointsStateStore } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { getStyles } from './forgotPasswordForm.styles';
import { useReactiveVar } from '@apollo/client';
import { useTheme } from 'react-native-paper';
import { FocusKeys } from '@warnermmedia/gsp-core/sdk/data-access';

export interface ForgotPasswordFormProps {
  isLoading?: boolean;
  onRequestSend(username: string): void;
  error?: string;
  containerStyle?: StyleProp<ViewStyle>;
  btnColor?: string;
  disabledBgColor?: string;
  disabledLabelColor?: string;
  label?: string;
  btnStyle?: StyleProp<ViewStyle>;
  textInputStyle?: StyleProp<TextStyle>;
  ctaBtnLabel?: string;
  placeholder?: string;
  usernameInputError?: string;
  validateUsername: (username: string) => boolean;
}

export function EstadioForgotPasswordForm({
  isLoading,
  onRequestSend,
  error,
  containerStyle,
  btnColor,
  disabledLabelColor,
  disabledBgColor,
  label,
  textInputStyle,
  ctaBtnLabel,
  placeholder,
  usernameInputError,
  validateUsername,
}: ForgotPasswordFormProps) {
  const [username, setUsername] = React.useState<string>('');
  const [inputError, setInputError] = React.useState<string>('');
  const { onEnterKeyPress } = useOnEnterKeyPress();
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const { colors } = useTheme();
  const styles = getStyles(breakpoints, colors);

  function setUserDetails(text: string) {
    setUsername(text);
    setInputError('');
  }

  function sendRequest() {
    if (disabled) {
      return;
    }

    if (validateUsername(username)) {
      onRequestSend && onRequestSend(username);
    } else {
      setInputError(usernameInputError || "The provided username doesn't have a valid format. Please revise it.");
    }
  }

  const disabled = isLoading || !validateUsername(username);

  const onBlur = useCallback(() => {
    if (!validateUsername(username)) {
      setInputError(usernameInputError || "The provided username doesn't have a valid format. Please revise it.");
    }
  }, [username, usernameInputError, setInputError, validateUsername]);

  return (
    <View style={containerStyle}>
      <View style={styles.topContent}>
        <TextContent style={styles.label}>{label} </TextContent>
        <EstadioTextInput
          value={username}
          style={[username.length < 1 ? styles.input : styles.inputFilled, textInputStyle]}
          placeholder={placeholder}
          onChangeText={setUserDetails}
          errorString={inputError}
          error={!!error || !!inputError}
          onKeyPress={(e) => onEnterKeyPress(e, sendRequest)}
          onBlur={onBlur}
          editable={!isLoading}
          focusable={!isLoading}
          focusKey={FocusKeys.PASSWORD_RECOVERY}
        />
      </View>

      <EstadioButton
        onPress={sendRequest}
        label={ctaBtnLabel || 'Send recovery mail'}
        disabled={disabled}
        btnColor={btnColor}
        disabledBgColor={disabledBgColor}
        disabledLabelColor={disabledLabelColor}
        labelStyle={{
          fontFamily: 'Oswald-Regular',
          fontSize: 20,
        }}
        btnStyle={styles.btnWrapper}
      />
    </View>
  );
}

export default React.memo(EstadioForgotPasswordForm);
