/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ZendeskAPI } from 'react-zendesk';
import { isVideoPlayerFullScreen, isZendeskLoaded } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { useReactiveVar } from '@apollo/client';
import { useGetDevice } from '@warnermmedia/gsp-core/sdk/ui';
import { isInRoute } from '../../Services/CommonFunctions';

export const useZendesk = () => {
  const { isWeb } = useGetDevice();
  const location = useLocation();
  const isLoaded = useReactiveVar(isZendeskLoaded);
  const isFullScreen = useReactiveVar(isVideoPlayerFullScreen);
  const isVideoPage = isInRoute(['/video/', '/tvsignal'], location.pathname);

  const showWidget = () => {
    ZendeskAPI('webWidget', 'show');
  };

  const hideWidget = () => {
    ZendeskAPI('webWidget', 'hide');
  };

  useEffect(() => {
    if (isLoaded) {
      if (isWeb) {
        if (isVideoPage || isFullScreen) {
          hideWidget();
        } else {
          showWidget();
        }
      } else {
        hideWidget();
      }
    }
  }, [isVideoPage, isLoaded]);

  return [];
};

export default useZendesk;
