import merge from 'lodash/merge';
import { StyleProp, StyleSheet, ViewStyle, TextStyle } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';

interface Styles {
  btnWrapper: ViewStyle;
  btnLabel: TextStyle;
  volverBtnLabel: TextStyle;
  volverBtn: ViewStyle;
}

export const getStyles = (breakpoints: Breakpoints, colors: ReactNativePaper.ThemeColors) => {
  const base: StyleProp<Styles> = {
    btnWrapper: {
      marginTop: 24,
      backgroundColor: colors.fill.action.accent01,
      borderRadius: 4,
      height: 40,
      width: '100%',
      borderColor: 'transparent',
    },
    btnLabel: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Oswald-Medium',
      fontSize: 20,
      fontWeight: '500',
      lineHeight: 24,
      textAlign: 'center',
      textTransform: 'uppercase',
    },
    volverBtnLabel: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Oswald-Medium',
      fontSize: 14,
      fontWeight: 'normal',
      lineHeight: 24,
      textAlign: 'left',
      textDecorationStyle: 'solid',
      textDecorationLine: 'underline',
      textDecorationColor: colors.keyboardFocus.light,
      textTransform: 'uppercase',
    },
    volverBtn: {
      marginTop: 28,
      backgroundColor: 'transparent',
      height: 40,
      alignSelf: 'flex-start',
      borderColor: 'transparent',
      width: 40,
    },
  };

  const mediaQueries =
    breakpoints.currentBreakpoints.isTiny ||
    breakpoints.currentBreakpoints.isTnySm ||
    breakpoints.currentBreakpoints.isSmMed
      ? ({} as StyleProp<Styles>)
      : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
