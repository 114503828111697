import { useEffect } from 'react';
import { useGetDevice } from '@warnermmedia/gsp-core/sdk/ui';
import { useReactiveVar } from '@apollo/client';
import { modalStateStore } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import get from 'lodash/get';

export const useDisableBodyScroll = (additionalCondition = false) => {
  const { isWeb, isTv } = useGetDevice();
  const modalState = useReactiveVar(modalStateStore);

  useEffect(() => {
    if (isWeb || isTv) {
      const { style } = document.body;
      if (additionalCondition || Object.keys(modalState).some((key) => get(modalState, key, false))) {
        style.position = 'fixed';
        style.overflow = 'hidden';
        style.width = '100vw';
      } else {
        style.overflow = 'unset';
        style.position = 'static';
      }
    }

    return () => {
      if (isWeb || isTv) {
        const { style } = document.body;

        style.overflow = 'unset';
        style.position = 'static';
      }
    };
  }, [modalState, additionalCondition, isWeb, isTv]);
};
