import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  button: {
    width: 15,
    height: 15,
    borderRadius: 10,
    borderWidth: 2,
    marginRight: 10,
  },
});
