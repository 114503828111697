import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { View } from 'react-native';
import { LoginFormComponent } from '@warnermmedia/gsp-core/brands/estadio/ui';
import {
  getContentTitle,
  getUrlParam,
  languageStrings,
  PagesUrl,
  QueryNames,
  useCMSPageMetaTags,
  useCMSQueryDataResults,
  useIsMountedRef,
} from '@warnermmedia/gsp-core/brands/estadio/feature';
import { MetaTags } from '@warnermmedia/gsp-core/sdk/ui';
import { HistoryContext, Page } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { MParticleAppConfigParams } from '@warnermmedia/gsp-core/sdk/data-access';

const Login: FC = () => {
  const historyContext = useContext(HistoryContext);
  const history = historyContext?.ready ? historyContext?.useHistory() : null;
  const verifiedEmailCode = history ? getUrlParam('verifyEmail', history.location.search) : '';
  const { pageData: pageUriData } = useCMSQueryDataResults<Page>({
    queryName: QueryNames.GET_COMMON_PAGE_DATA,
    uri: PagesUrl.Login,
  });
  const { metaTags, title, type, cmsId } = useCMSPageMetaTags(pageUriData);
  const [mParticleEventOptions, setMparticleEventOptions] = useState<MParticleAppConfigParams>(
    {} as MParticleAppConfigParams
  );
  const isMountedRef = useIsMountedRef();

  useEffect(() => {
    if (isMountedRef.current && (title || type || cmsId)) {
      setMparticleEventOptions({
        section: type,
        contentTitle: title,
        contentId: cmsId,
      });
    }
  }, [title, type, cmsId, isMountedRef]);

  const handleNavigation = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (routeName: string, state: any = {}) => {
      history?.replace(routeName, state);
    },
    [history]
  );

  const parentClick = (element: FocusEvent) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (element.target.children.length > 0) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      element.target.children[0].focus();
    }
  };

  useEffect(() => {
    if (isMountedRef.current) {
      const inputs = Array.from(document.getElementsByTagName('input'));
      inputs.forEach((input) => {
        if (input.parentElement && input.type !== 'checkbox') {
          const { parentElement } = input;
          parentElement.tabIndex = 0;
          parentElement.onclick = parentClick;
        }
        input.tabIndex = 0;
      });
    }
  }, [isMountedRef]);

  return (
    <View>
      <View style={{ flex: 1, alignItems: 'center' }}>
        <MetaTags data={metaTags} title={getContentTitle(title)} />
        <LoginFormComponent
          handleNavigation={handleNavigation}
          verifiedEmailCode={verifiedEmailCode}
          mParticleEventLoginOptions={mParticleEventOptions}
          confirmVerificationCodeMessage={languageStrings.default.confirmVerificationCodeMessage}
          verificationMessage={languageStrings.default.verificationMessageTokenExpired}
        />
      </View>
    </View>
  );
};

export default Login;
