import {
  TveAuthTokenResponse,
  TveCountryProviders,
  TveLoginResponse,
  TveLogoutResponse,
  TveProviders,
  TveToolboxConfig,
  TveVideoTokenRequestParams,
  TveVideoTokenResponse,
} from '../../types/tveToolbox-types';
import useAuthorize from './useAuthorize';
import { VideoErrorMessages } from '../../consts';

interface TveToolboxProps {
  tveLogin(country: string, idp: string): Promise<TveLoginResponse>;
  getTveProviders(country?: string): Promise<TveProviders>;
  tveLogout(accessToken: string): Promise<TveLogoutResponse>;
  getAuthorizationToken: (code: string, redirectUri: string) => Promise<TveAuthTokenResponse>;
  getTveVideoToken(params: TveVideoTokenRequestParams): Promise<TveVideoTokenResponse>;
}

const useTveToolbox = (toolboxConfig: TveToolboxConfig, tenantTitle = '', isMobile?: boolean): TveToolboxProps => {
  const authorize = useAuthorize(toolboxConfig, tenantTitle, isMobile);

  const tveLogin = async (_country: string, _idp: string): Promise<TveLoginResponse> => {
    try {
      const response = await authorize(_country, _idp);
      const success = response?.accessToken?.length > 0;
      return {
        isMobile,
        success: success,
        error: success ? null : 'tve login error',
        accessToken: response.accessToken,
      } as TveLoginResponse;
    } catch (e) {
      return {
        isMobile,
        success: false,
        error: e,
      } as TveLoginResponse;
    }
  };

  const getTveProviders = async (country?: string): Promise<TveProviders> => {
    const url = toolboxConfig.providersUrl;
    try {
      const response = await fetch(url);

      if (!response.ok) {
        throw Error();
      }
      const json = await response.json();
      const countryProviders = json.find((item: TveCountryProviders) => item.countryCode === country);
      return {
        success: true,
        providers: countryProviders,
      } as TveProviders;
    } catch (error) {
      return {
        success: false,
        error: 'Error retrieving providers list',
      } as TveProviders;
    }
  };

  const tveLogout = async (accessToken: string): Promise<TveLogoutResponse> => {
    try {
      const response = await fetch(`${toolboxConfig.logoutUrl}?user_token=${accessToken}`, {
        method: 'DELETE',
      });
      if (!response.ok) {
        throw Error();
      }

      const json = await response.json();

      if (!json.status) {
        throw Error();
      }

      return {
        success: true,
      } as TveLogoutResponse;
    } catch (e) {
      return {
        success: false,
        error: 'Error logging out from tve',
      } as TveLogoutResponse;
    }
  };

  const getTveVideoToken = async ({
    mediaId,
    urn,
    network,
    userId,
    accessToken,
    requestor,
    mvpd,
  }: TveVideoTokenRequestParams): Promise<TveVideoTokenResponse> => {
    try {
      const response = await fetch(
        toolboxConfig.videoTokenUrl +
          `?user_token=${accessToken}&urn=${urn}&media_id=${mediaId}&network=${network}&user_id=${userId}&requestor=${requestor}&mvpd=${mvpd}`,
        {
          method: 'GET',
          cache: 'no-cache',
        }
      );
      if (!response.ok) {
        const responseText = await response.text();
        if (responseText === VideoErrorMessages.TOOLBOX_CONCURRENT_SESSION_ERROR) {
          throw Error(VideoErrorMessages.TOOLBOX_CONCURRENT_SESSION_ERROR);
        }
        throw Error(response?.status === 401 ? 'Error getting video token from tve: no access' : '');
      }
      const json = await response.json();
      if (!json.jwt) {
        throw Error();
      }
      return {
        success: true,
        videoToken: json.jwt,
      } as TveVideoTokenResponse;
    } catch (e) {
      return {
        success: false,
        videoToken: '',
        error: e.message ?? 'Error getting video token from tve',
      } as TveVideoTokenResponse;
    }
  };

  const getAuthorizationToken = async (code: string, redirectUri: string) => {
    try {
      const response = await fetch(toolboxConfig.tokenUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Accept: 'application/json',
        },
        body: new URLSearchParams({
          code,
          client_id: toolboxConfig.clientId,
          code_verifier: 'none',
          redirect_uri: redirectUri,
          grant_type: 'authorization_code',
        }),
      });

      if (!response.ok) {
        throw Error();
      }

      const json = await response.json();

      if (!json.access_token) {
        throw Error();
      }

      return {
        success: true,
        access_token: json.access_token,
      };
    } catch (e) {
      return { success: false };
    }
  };

  return {
    getAuthorizationToken: (code: string, redirectUri: string): Promise<TveAuthTokenResponse> =>
      getAuthorizationToken(code, redirectUri),
    tveLogin: (country: string, idp: string): Promise<TveLoginResponse> => tveLogin(country, idp),
    getTveProviders: (country?: string): Promise<TveProviders> => getTveProviders(country),
    tveLogout: (accessToken: string): Promise<TveLogoutResponse> => tveLogout(accessToken),
    getTveVideoToken: (params: TveVideoTokenRequestParams): Promise<TveVideoTokenResponse> => getTveVideoToken(params),
  };
};

export default useTveToolbox;
