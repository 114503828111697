import React, { useCallback, useEffect, useState } from 'react';
import { Text, TextStyle, TouchableOpacity, ViewStyle } from 'react-native';
import { CustomCheckbox, useGetDevice } from '@warnermmedia/gsp-core/sdk/ui';
import { useIsMountedRef } from '@warnermmedia/gsp-core/brands/estadio/feature';
import { useTheme } from 'react-native-paper';

interface CHeckboxProps {
  disabled?: boolean;
  onChange: () => void;
  label?: string;
  checked: boolean;
  labelStyle?: TextStyle;
  style?: ViewStyle | ViewStyle[];
  focusable?: boolean;
  focusKey?: string;
}

export const EstadioCheckbox = ({
  disabled,
  label,
  checked,
  onChange,
  labelStyle,
  style,
  focusable,
  focusKey,
}: CHeckboxProps) => {
  const { isTv } = useGetDevice();
  const { colors } = useTheme();
  const isMountedRef = useIsMountedRef();
  const [checkValue, setCheckValue] = useState(checked);
  const [focus, setFocus] = useState(false);

  const [lastTimeStamp, setLastTimeStamp] = useState(0);

  const handleFocus = useCallback(() => {
    setFocus(true);
  }, []);

  const handleBlur = useCallback(() => {
    setFocus(false);
  }, []);

  const getCheckbox = useCallback(() => {
    return (
      <>
        <CustomCheckbox
          handlePress={onChange}
          isChecked={isTv ? checkValue : checked}
          checkMarkFill={colors.tenantBackground.light.surfaceBase}
          disabled={disabled}
          checkboxStyle={{
            borderRadius: 2,
          }}
          checkedStyle={{
            backgroundColor: colors.fill.action.accent01,
          }}
          unCheckedStyle={{
            borderColor: colors.foreground.onDark.text02,
            backgroundColor: colors.tenantBackground.light.surfaceBase,
          }}
          focusable={false}
        />
        {label && <Text style={[{ fontFamily: 'Oswald-Regular' }, labelStyle]}>{label}</Text>}
      </>
    );
  }, [checkValue, checked, disabled, isTv, onChange, label, labelStyle]);

  const shouldFireGamePadEvent = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (event: any): boolean => {
      // Workaround to avoid firing the GamePad event twice when checking
      return event.type === 'click' && (lastTimeStamp === 0 || Math.abs(event.timeStamp - lastTimeStamp) > 50);
    },
    [lastTimeStamp]
  );

  const handleKeyPress = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (event: any) => {
      if (event.keyCode === 13 || shouldFireGamePadEvent(event)) {
        setLastTimeStamp(event.timeStamp);
        setCheckValue((prev) => !prev);
        onChange();
      }
    },
    [onChange, shouldFireGamePadEvent]
  );

  useEffect(() => {
    if (isMountedRef.current) {
      setCheckValue(checked);
    }
  }, [checked, isMountedRef]);

  return (
    <TouchableOpacity
      onPress={handleKeyPress}
      {...(!isTv ? { onFocus: handleFocus, onBlur: handleBlur } : {})}
      style={[
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        /* @ts-ignore: properties for correct outlining. */
        focus && !isTv ? { outline: `2px solid ${colors.fill.action.accent02}` } : { outline: 'none' },
        !isTv ? { borderRadius: 3 } : {},
        style,
        { flexDirection: 'row' },
      ]}
    >
      {getCheckbox()}
    </TouchableOpacity>
  );
};

export default EstadioCheckbox;
