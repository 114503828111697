import React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';

/* eslint-disable-next-line */
export interface HomeProps {
  width?: number;
  height?: number;
  color?: string;
  iconColor?: string;
}

export function Home(props: HomeProps) {
  const width = props.width || 20;
  const height = props.height || 20;
  const color = props.color || '#359fd7';
  const iconColor = props.iconColor || '#fff';

  return (
    <Svg fill="none" height={height} viewBox="0 0 20 20" width={width}>
      <Circle cx="10" cy="10" r="10" fill={color} />
      <Path
        fill={iconColor}
        d="M14.73 9.35v-.001l-4.08-4.08C10.477 5.097 10.245 5 10 5c-.246 0-.478.096-.651.27L5.27 9.347l-.004.004c-.357.359-.356.942.002 1.3.164.164.38.258.611.268l.028.002h.163v3.002c0 .594.483 1.077 1.077 1.077h1.596c.162 0 .293-.131.293-.293v-2.354c0-.27.22-.491.492-.491h.941c.271 0 .492.22.492.492v2.353c0 .162.13.293.293.293h1.596c.594 0 1.077-.483 1.077-1.077V10.92h.15c.247 0 .478-.096.652-.27.358-.359.359-.942 0-1.301z"
      />
    </Svg>
  );
}

export default Home;
