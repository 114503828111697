import { mParticleEventProcessor } from '@warnermmedia/gsp-core/sdk/data-access';

import { MParticleAppConfigObject, MParticleCustomEventTypes } from '../mParticleEventProcessor';
import { PlayerMediaStateChangedResult } from '@top/player-block-web';
import { getDiff } from '@warnermmedia/gsp-core/sdk/ui';

export const playerMediaStateChangedEvents = (
  result: PlayerMediaStateChangedResult,
  mParticleEventData: MParticleAppConfigObject,
  playerStartTime: string
) => {
  let eventName = null;
  if (result.previousState === 'pending' && result.currentState === 'starting') {
    eventName = MParticleCustomEventTypes.PlayerReadyEvent;
  }

  if (result.previousState === 'starting' && result.currentState === 'loaded') {
    eventName = MParticleCustomEventTypes.MediaResponseEvent;
  }

  if (result.previousState === 'loaded' && result.currentState === 'playing') {
    eventName = MParticleCustomEventTypes.VideoStartEvent;
  }

  if (eventName) {
    mParticleEventProcessor.pushMParticleEvent(eventName, {
      ...mParticleEventData,
      ...{ player_timer: getDiff(playerStartTime) },
    });
  }
};
