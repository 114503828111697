import React from 'react';
import Svg, { Circle, Rect } from 'react-native-svg';

/* eslint-disable-next-line */
export interface SheetProps {
  width?: number;
  height?: number;
  color?: string;
  iconColor?: string;
}

export function Sheet(props: SheetProps) {
  const width = props.width || 20;
  const height = props.height || 20;
  const color = props.color || '#359fd7';
  const iconColor = props.iconColor || '#fff';

  return (
    <Svg fill="none" height={height} viewBox="0 0 20 20" width={width}>
      <Circle cx="10" cy="10" r="10" fill={color} />
      <Rect width="10" height="12" x="5" y="4" fill={iconColor} rx="1" />
      <Rect width="6" height="1" x="13" y="11" fill={color} rx=".5" transform="rotate(-180 13 11)" />
      <Rect width="5" height="1" x="12" y="13" fill={color} rx=".5" transform="rotate(-180 12 13)" />
      <Rect width="6" height="1" x="13" y="9" fill={color} rx=".5" transform="rotate(-180 13 9)" />
      <Rect width="6" height="1" x="13" y="7" fill={color} rx=".5" transform="rotate(-180 13 7)" />
    </Svg>
  );
}

export default Sheet;
