import { StyleProp, StyleSheet, TextStyle, ViewStyle } from 'react-native';

interface Styles {
  wrapper: ViewStyle;
  messageDisplayMobile: TextStyle;
  ctaMobile: ViewStyle;
  tvWrapper: ViewStyle;
  tvWrapperLeft: ViewStyle;
  tvWrapperRight: ViewStyle;
  title: TextStyle;
  tvLinkButton: ViewStyle;
  tvLinkButtonLabel: TextStyle;
  messageDisplayTV: TextStyle;
  messageDisplayTVWrapper: ViewStyle;
}

export const getStyles = (isAndroidTv: boolean, colors: ReactNativePaper.ThemeColors) => {
  const base: StyleProp<Styles> = {
    wrapper: {
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    messageDisplayMobile: {
      marginTop: 8,
      fontSize: 16,
      lineHeight: 20,
    },
    ctaMobile: {
      backgroundColor: colors.fill.action.accent01,
      borderWidth: 0,
      marginTop: 24,
    },
    tvWrapper: {
      margin: 'auto',
      backgroundColor: colors.tenantBackground.dark.surface03,
      height: '70%',
      width: isAndroidTv ? '85%' : '72%',
      marginTop: 40,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingHorizontal: isAndroidTv ? 70 : 140,
      paddingVertical: isAndroidTv ? 100 : 175,
      borderWidth: 0,
      marginBottom: 117,
    },
    tvWrapperLeft: {
      width: isAndroidTv ? '50%' : 475,
    },
    tvWrapperRight: {
      borderWidth: 20,
      borderColor: colors.tenantBackground.light.surfaceBase,
    },
    title: {
      fontFamily: 'Oswald-SemiBold',
      fontSize: 44,
      fontWeight: '600',
      height: 130,
      textTransform: 'uppercase',
      width: 475,
    },
    tvLinkButton: {
      justifyContent: 'center',
      alignItems: 'center',
      borderWidth: 2,
      borderColor: colors.tenantBackground.light.surfaceBase,
      height: 54,
      marginBottom: 24,
      borderRadius: 4,
    },
    tvLinkButtonLabel: {
      fontSize: 24,
      textDecorationLine: 'none',
    },
    messageDisplayTV: {
      fontFamily: ' Rubik-Regular',
      fontSize: 24,
      lineHeight: 32,
    },
    messageDisplayTVWrapper: {
      marginVertical: 40,
    },
  };
  return StyleSheet.create(base);
};
