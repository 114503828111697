import { useContext, useEffect } from 'react';
import { Tve, useIsMountedRef } from '@warnermmedia/gsp-core/brands/estadio/feature';
import { AppConfigContext } from '@warnermmedia/gsp-core/brands/estadio/data-access';

export function useTveRestoreSession(): void {
  const appConfig = useContext(AppConfigContext);
  const isTveEnable = appConfig?.appConfig.tve.enabled;
  const isMountedRef = useIsMountedRef();
  const useTve = Tve();

  useEffect(() => {
    // if a tve token is found, the user will be automatically authenticated
    if (isTveEnable && isMountedRef.current) {
      useTve.restoreSession();
    }
  }, [isTveEnable, useTve, isMountedRef]);
}
