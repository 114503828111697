import { useReactiveVar } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Dimensions, View } from 'react-native';
import { breakpointsStateStore, CHILE_TIMEZONE, Schedule } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import {
  carouselBase,
  languageStrings,
  QueryNames,
  useCMSQueryDataResults,
  useEPGData,
  useSetCardIndex,
} from '@warnermmedia/gsp-core/brands/estadio/feature';
import Epg from '../../swimlanes/epg/epg';
import { getDiff, getLocalDate, useGetDevice } from '@warnermmedia/gsp-core/sdk/ui';
import { useTheme } from 'react-native-paper';

export const EpgWrapper = () => {
  const { pageData: epgData } = useCMSQueryDataResults<Schedule>({ queryName: QueryNames.GET_SCHEDULE_FEED });
  const { formatEPGData } = useEPGData();
  const [epgIndex, setEpgIndex] = useState(0);
  const [epgItems, setEpgItems] = useState<Schedule | undefined>();
  const [epgDisplay, setEpgDisplay] = useState<carouselBase[]>();
  const { setIndex } = useSetCardIndex(epgIndex, setEpgIndex);
  const { width, height } = Dimensions.get('window');
  const device = useGetDevice();
  const { colors } = useTheme();
  const breakpoints = useReactiveVar(breakpointsStateStore);

  const findCurrentEPGItem = (data: carouselBase[]) => {
    let nearestItem = Infinity;
    let epgIndex = -1;
    for (let i = 0; i <= data.length; i++) {
      const item = data[i];
      const date = getLocalDate(item?.show?.airDateTime as string, CHILE_TIMEZONE);
      const diff = date ? parseInt(getDiff(date.toString())) : -1;
      const distance = Math.abs(diff);
      if (distance < nearestItem) {
        nearestItem = distance;
        epgIndex = i;
      }
      if (diff <= 0) {
        break;
      }
    }
    return epgIndex;
  };

  useEffect(() => {
    if (epgDisplay && epgDisplay.length) {
      const index = findCurrentEPGItem(epgDisplay);
      index > 0 && setIndex(index);
    }
  }, [epgDisplay, width, height]);

  useEffect(() => {
    if (epgData && epgItems) {
      setEpgDisplay(formatEPGData(epgItems));
    }
  }, [epgData, epgItems]);

  useEffect(() => {
    if (epgData) {
      setEpgItems(epgData);
    }
  }, [epgData]);

  return (
    <View style={{ backgroundColor: colors.tenantBackground.dark.surfaceBase }}>
      {epgDisplay && epgDisplay.length > 0 && (
        <Epg
          headline={languageStrings.default.epgSwimlaneHeadline}
          index={epgIndex}
          indexUpdate={setIndex}
          data={epgDisplay ?? []}
          laneWidth={breakpoints.windowWidth}
          device={device}
          colors={colors}
          breakpoints={breakpoints}
        />
      )}
    </View>
  );
};

export default EpgWrapper;
