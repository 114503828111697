import React, { useCallback, useEffect } from 'react';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  breakpointsStateStore,
  MODAL_INITIAL_STATE,
  modalStateStore,
  ModalType,
} from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { Modal, ModalProps, View, ViewStyle } from 'react-native';
import { CustomButton, useGetDevice, useSpatialNavigation } from '@warnermmedia/gsp-core/sdk/ui';
import { useReactiveVar } from '@apollo/client';
import { useTheme } from 'react-native-paper';
import { getStyles } from './modalWrapper.styles';
import { FocusContext } from '@noriginmedia/norigin-spatial-navigation';
import { getUniqueId, useIsMountedRef } from '@warnermmedia/gsp-core/brands/estadio/feature';
import { loadData, ROUTE_FOCUS_KEY } from '@warnermmedia/gsp-core/sdk/data-access';

export enum ModalContentContainer {
  Center = 'center',
  Full = 'full',
  None = 'none',
}

interface ModalWrapperProps extends ModalProps {
  visible: boolean;
  modalType: ModalType | null;
  children: JSX.Element;
  onClose: () => void;
  showCloseButton?: boolean;
  useModalWrapperContainer?: boolean;
  useModalInnerContainers?: boolean;
  modalContentContainerType?: ModalContentContainer;
  modalWrapperContainerStyle?: ViewStyle;
  modalMainContainerStyle?: ViewStyle;
  modalLayoutContainerStyle?: ViewStyle;
  modalCloseButtonStyle?: ViewStyle;
  modalCloseButtonLabelStyle?: ViewStyle;
}

export const ModalWrapper = ({
  visible,
  modalType,
  children,
  onClose,
  presentationStyle,
  useModalWrapperContainer = false,
  useModalInnerContainers = true,
  modalContentContainerType = ModalContentContainer.None,
  modalLayoutContainerStyle,
  modalWrapperContainerStyle,
  modalMainContainerStyle,
  modalCloseButtonStyle,
  modalCloseButtonLabelStyle,
  transparent = true,
  animationType = 'none',
  showCloseButton = false,
}: ModalWrapperProps) => {
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const { colors } = useTheme();
  const { isMobileDevice, isIosDevice, isWeb } = useGetDevice();
  const currentWindowWidth = breakpoints.windowWidth;
  const isMobileSize = isMobileDevice || currentWindowWidth <= breakpoints.breakpointSizes.lg;
  const styles = getStyles(isMobileSize, breakpoints, colors, isMobileDevice, isIosDevice);
  const ModalWrapperContainer = useModalWrapperContainer ? View : React.Fragment;
  const closeBtnStyle = modalCloseButtonStyle ? modalCloseButtonStyle : styles.closeBtn;
  const closeBtnTextStyle = modalCloseButtonLabelStyle ? modalCloseButtonLabelStyle : styles.closeBtnText;
  const ModalInnerContainer = useModalInnerContainers ? View : React.Fragment;
  const wrapperContainerStyle = useModalWrapperContainer
    ? { style: [styles.fullScreenContainer, modalWrapperContainerStyle] }
    : {};

  const layoutContainerStyle = useModalInnerContainers
    ? {
        style: [
          modalContentContainerType === ModalContentContainer.Center
            ? isMobileSize
              ? styles.centeredViewMobile
              : styles.centeredView
            : {},
          modalLayoutContainerStyle,
          !useModalWrapperContainer && !modalLayoutContainerStyle ? styles.fullScreenContainer : {},
        ],
      }
    : {};
  const mainContainerStyle = useModalInnerContainers
    ? { style: modalMainContainerStyle ? modalMainContainerStyle : styles.container }
    : {};
  const isMountedRef = useIsMountedRef();
  const modalFocusKey = getUniqueId(modalType ?? '');
  const { ref, focusKey, setFocus } = useSpatialNavigation({
    focusKey: modalFocusKey,
    trackChildren: true,
    isFocusBoundary: true,
  });

  useEffect(() => {
    if (modalType && isMountedRef.current) {
      setFocus(modalType);
    }
  }, [modalType, setFocus, isMountedRef]);

  useEffect(() => {
    if (modalType) {
      modalStateStore({
        ...modalStateStore(),
        [modalType]: visible,
      });
    }
    return () => {
      modalStateStore({
        ...modalStateStore(),
        ...MODAL_INITIAL_STATE,
      });
      setFocus(loadData(ROUTE_FOCUS_KEY));
    };
  }, [visible, modalType, setFocus]);

  const onCloseModal = useCallback(() => {
    isMountedRef.current && onClose();
  }, [onClose, isMountedRef]);

  return visible ? (
    <FocusContext.Provider value={focusKey}>
      <ModalWrapperContainer {...wrapperContainerStyle}>
        <Modal
          visible={visible}
          transparent={transparent}
          onRequestClose={onClose}
          animationType={animationType}
          presentationStyle={presentationStyle}
          ref={ref}
        >
          <ModalInnerContainer
            {...layoutContainerStyle}
            {...(useModalInnerContainers && { nativeID: 'modal-wrapper' })}
          >
            <ModalInnerContainer {...mainContainerStyle}>
              {showCloseButton && (
                <CustomButton
                  mode="text"
                  btnStyle={closeBtnStyle}
                  onPress={onCloseModal}
                  labelStyle={closeBtnTextStyle}
                  label="x"
                />
              )}
              {children}
            </ModalInnerContainer>
          </ModalInnerContainer>
        </Modal>
      </ModalWrapperContainer>
    </FocusContext.Provider>
  ) : null;
};

export default ModalWrapper;
