import React from 'react';
import Svg, { Path, G } from 'react-native-svg';

/* eslint-disable-next-line */
export interface IconMenuProps {
  width?: number;
  height?: number;
  color?: string;
  iconColor?: string;
}

export function IconMenu(props: IconMenuProps) {
  const width = props.width || 10;
  const height = props.height || 8;
  const color = props.color || '#359fd7';
  const iconColor = props.iconColor || '#fff';

  return (
    <Svg fill="none" height={height} viewBox="0 0 10 8" width={width}>
      <G id="Icons" stroke="none" fill={color}>
        <G id="Icon/Menu" transform="translate(-5.000000, -6.000000)" fill={iconColor}>
          <Path
            d="M14,12 C14.5522847,12 15,12.4477153 15,13 C15,13.5522847 14.5522847,14 14,14 L6,14 C5.44771525,14 5,13.5522847 5,13 C5,12.4477153 5.44771525,12 6,12 L14,12 Z M14,9 C14.5522847,9 15,9.44771525 15,10 C15,10.5522847 14.5522847,11 14,11 L6,11 C5.44771525,11 5,10.5522847 5,10 C5,9.44771525 5.44771525,9 6,9 L14,9 Z M14,6 C14.5522847,6 15,6.44771525 15,7 C15,7.55228475 14.5522847,8 14,8 L6,8 C5.44771525,8 5,7.55228475 5,7 C5,6.44771525 5.44771525,6 6,6 L14,6 Z"
            id="Combined-Shape"
          ></Path>
        </G>
      </G>
    </Svg>
  );
}

export default IconMenu;
