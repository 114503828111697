import React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';

/* eslint-disable-next-line */
export interface HoyProps {
  width?: number;
  height?: number;
  color?: string;
  iconColor?: string;
}

export function Hoy(props: HoyProps) {
  const width = props.width || 20;
  const height = props.height || 20;
  const color = props.color || '#359fd7';
  const iconColor = props.iconColor || '#fff';

  return (
    <Svg fill="none" height={height} viewBox="0 0 20 20" width={width}>
      <Circle cx="10" cy="10" r="10" fill={color} />
      <Path
        fill={iconColor}
        d="M3.887 6.853h1.32V9.51H6.35V6.853h1.32v6.48H6.35v-2.872H5.207v2.872h-1.32v-6.48zM10.476 13.421c-.688 0-1.179-.178-1.472-.536-.294-.362-.44-.893-.44-1.592V8.877c0-.698.146-1.224.44-1.576.293-.352.784-.528 1.472-.528.682 0 1.17.18 1.464.536.298.352.448.875.448 1.568v2.424c0 .694-.15 1.222-.448 1.584-.294.358-.782.536-1.464.536zm0-.944c.224 0 .373-.074.448-.224.08-.154.12-.365.12-.632V8.557c0-.266-.04-.472-.12-.616-.075-.149-.224-.224-.448-.224s-.376.075-.456.224c-.075.144-.112.35-.112.616v3.064c0 .272.037.483.112.632.074.15.226.224.456.224zM14.023 10.765L12.76 6.853H14l.696 2.28.64-2.28h1.208l-1.248 3.912v2.568h-1.272v-2.568z"
      />
    </Svg>
  );
}

export default Hoy;
