import { ViewStyle, StyleSheet, StyleProp, TextStyle } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';
import { merge } from 'lodash';

interface Styles {
  fullScreenContainer: ViewStyle;
  container: ViewStyle;
  containerInner: ViewStyle;
  title: TextStyle;
  description: TextStyle;
}

export const getStyles = (breakpoints: Breakpoints, isTv: boolean, colors: ReactNativePaper.ThemeColors) => {
  const base: StyleProp<Styles> = {
    fullScreenContainer: {
      backgroundColor: colors.tenantBackground.dark.modalBackground,
      position: 'absolute',
      width: '100%',
      height: '100%',
    },
    container: {
      flexDirection: 'column',
      paddingVertical: 48,
      backgroundColor: colors.tenantBackground.dark.surface03,
      alignItems: 'center',
      justifyContent: 'center',
      alignSelf: 'center',
    },
    containerInner: {
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
    },
    title: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Oswald-SemiBold',
      fontSize: 22,
      fontWeight: '600',
      textAlign: 'center',
      textTransform: 'uppercase',
    },
    description: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Rubik-Regular',
      fontSize: 16,
      fontWeight: 'normal',
      lineHeight: 20,
      textAlign: 'center',
      marginVertical: 24,
    },
  };

  const mediaQueries = isTv
    ? ({
        container: {
          width: '43%',
          maxWidth: 622,
          paddingHorizontal: 64,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isTiny ||
      breakpoints.currentBreakpoints.isTnySm ||
      breakpoints.currentBreakpoints.isSmMed
    ? {
        fullScreenContainer: {
          justifyContent: 'center',
        },
        container: {
          width: '86%',
          paddingHorizontal: 20,
        },
      }
    : breakpoints.currentBreakpoints.isMedLg
    ? ({
        container: {
          width: '74%',
          paddingHorizontal: 59,
          marginVertical: '12%',
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isLgXl ||
      breakpoints.currentBreakpoints.isXlXxl ||
      breakpoints.currentBreakpoints.isXxl
    ? ({
        container: {
          width: '43%',
          paddingHorizontal: 64,
          marginVertical: '12%',
        },
      } as StyleProp<Styles>)
    : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
