import React, { useEffect } from 'react';

import { ImageAssets, Pause, Play } from '@warnermmedia/gsp-core/brands/estadio/assets';
import OverlayPlayerButton from './overlayPlayerButton';
import PlayerControl from './playerControl';
import { FocusKeys, ROUTE_FOCUS_KEY, saveData } from '@warnermmedia/gsp-core/sdk/data-access';
import { useFocusable } from '@noriginmedia/norigin-spatial-navigation';

type Props = {
  playerReady: boolean;
  controlsVisible: boolean;
  seekValue: number;
  handleSeekValue: (value: number) => void;
  isPlaying: boolean;
  handlePlayPauseClick: () => void;
  contentEnded: boolean;
  handleReplay: () => void;
  isPaused: boolean;
  timer: number;
  handleLiveClick: () => void;
  isLive: boolean;
  duration: number;
};

const PlayerControlsOverlay: React.FC<Props> = ({
  controlsVisible,
  seekValue,
  handleSeekValue,
  isPlaying,
  handlePlayPauseClick,
  contentEnded,
  handleReplay,
  isPaused,
  timer,
  handleLiveClick,
  isLive,
  duration,
}) => {
  const formatTime = (timeInSeconds: number): string => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };
  const { setFocus } = useFocusable();

  useEffect(() => {
    const currentKey =
      contentEnded || isPaused ? FocusKeys.PLAYER_OVERLY_BUTTON : controlsVisible ? FocusKeys.PLAYER_PLAY_BUTTON : '';
    if (currentKey) {
      setFocus(currentKey);
      saveData(ROUTE_FOCUS_KEY, currentKey);
    }
  }, [setFocus, controlsVisible, isPaused, contentEnded]);

  return (
    <>
      {controlsVisible && (
        <div className="videoPlayer__controls">
          <input
            type="range"
            min="0"
            max={100}
            value={seekValue}
            onChange={(e) => {
              handleSeekValue(Number(e.target.value || 0));
            }}
            style={{
              background: `linear-gradient(to right, #0070f3, #0070f3 ${seekValue}%, rgba(255, 255, 255, 0.2) ${seekValue}%, rgba(255, 255, 255, 0.2) 100%)`,
            }}
          />
          <div className="buttom_controls">
            <PlayerControl
              style={{ width: 50, height: 50 }}
              onClick={handlePlayPauseClick}
              icon={
                isPlaying ? (
                  <Pause color="#fff" iconColor="#fff" height={30} width={30} opacity={0.2} />
                ) : (
                  <Play color="#fff" iconColor="#fff" height={40} width={40} opacity={0.2} />
                )
              }
              focusKey={FocusKeys.PLAYER_PLAY_BUTTON}
            />
            {!isLive && (
              <div className="videoPlayer__timer">
                <span>{formatTime(timer)}</span>
                <span>{' / '}</span>
                <span>{formatTime(duration || 0)}</span>
              </div>
            )}
            {isLive && (
              <PlayerControl className="videoPlayer__live-button" onClick={handleLiveClick}>
                Live
              </PlayerControl>
            )}
          </div>
        </div>
      )}

      {contentEnded && (
        <OverlayPlayerButton
          icon={<img src={ImageAssets.replay} alt="Replay" />}
          onClick={() => handleReplay()}
          focusKey={FocusKeys.PLAYER_OVERLY_BUTTON}
        />
      )}
      {isPaused && (
        <OverlayPlayerButton
          icon={<Play color="#fff" iconColor="#fff" height={80} width={80} opacity={0.2} />}
          onClick={handlePlayPauseClick}
          focusKey={FocusKeys.PLAYER_OVERLY_BUTTON}
        />
      )}
    </>
  );
};

export default PlayerControlsOverlay;
