import React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';

/* eslint-disable-next-line */
export interface RefreshProps {
  width?: number;
  height?: number;
  color?: string;
  iconColor?: string;
}

export function Refresh(props: RefreshProps) {
  const width = props.width || 20;
  const height = props.height || 20;
  const color = props.color || '#359fd7';
  const iconColor = props.iconColor || '#fff';

  return (
    <Svg fill="none" height={height} viewBox="0 0 20 20" width={width}>
      <Circle cx="10" cy="10" r="10" fill={color} />
      <Path
        fill={iconColor}
        d="M10.262 5.05c-.921-.001-1.822.27-2.59.778-.12.079-.203.202-.231.342-.029.14 0 .286.078.405.08.12.202.202.342.23.14.03.286 0 .405-.078.592-.392 1.286-.6 1.996-.599 1.996 0 3.621 1.625 3.621 3.622 0 1.997-1.624 3.621-3.621 3.621-1.997 0-3.622-1.624-3.622-3.621 0-.123.006-.247.019-.37l.254.364c.082.117.207.197.348.222.14.025.286-.008.403-.09.117-.082.197-.207.222-.347.024-.141-.008-.286-.09-.403l-.937-1.34c-.04-.057-.092-.107-.152-.145-.06-.038-.126-.064-.196-.076-.07-.012-.141-.01-.21.005-.07.015-.135.044-.193.084l-1.339.938c-.117.082-.196.207-.22.348-.026.14.007.285.088.402.082.117.207.197.348.222.14.025.285-.007.403-.089l.192-.134c-.012.135-.018.271-.018.409 0 2.591 2.108 4.7 4.7 4.7 2.59 0 4.7-2.109 4.7-4.7s-2.109-4.7-4.7-4.7z"
      />
    </Svg>
  );
}

export default Refresh;
