import { ImageStyle, StyleProp, StyleSheet, TextStyle, ViewStyle } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';
import { merge } from 'lodash';
interface Styles {
  team: ViewStyle;
  logoContain: ViewStyle;
  logo: ImageStyle;
  tricode: TextStyle;
}

export const getStyles = (breakpoints: Breakpoints, isTv: boolean, colors: ReactNativePaper.ThemeColors) => {
  const base: StyleProp<Styles> = {
    team: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
    logoContain: {},
    logo: {
      width: '100%',
      height: '100%',
    },
    tricode: {
      fontFamily: 'Rubik-Medium',
      color: colors.keyboardFocus.light,
      fontSize: 18,
      textTransform: 'uppercase',
      fontWeight: 'bold',
      textAlign: 'center',
      marginTop: 10,
    },
  };

  const mediaQueries = isTv
    ? ({
        tricode: {
          fontSize: 27,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isMedLg ||
      breakpoints.currentBreakpoints.isLgXl ||
      breakpoints.windowWidth === breakpoints.breakpointSizes.lg
    ? ({
        tricode: {
          fontSize: 16,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isXlXxl || breakpoints.currentBreakpoints.isXxl
    ? ({
        tricode: {
          fontSize: 16,
        },
      } as StyleProp<Styles>)
    : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
