import { RefObject, useCallback, useEffect, useRef } from 'react';
import { Dimensions, FlatList, NativeScrollEvent, NativeSyntheticEvent } from 'react-native';
import { DeviceTypes } from './useGetDevice';

enum SetScrollDirection {
  Right = 'RIGHT',
  Left = 'LEFT',
}

export function useCarousel(
  index: number,
  ref: RefObject<FlatList>,
  itemWidth: number,
  indexUpdate: (index: number) => void,
  device: DeviceTypes,
  lastIndex?: number
) {
  const activeIndex = useRef<number>(0);
  const contentOffset = useRef<number>(0);
  const scrollDirection = useRef<SetScrollDirection>(SetScrollDirection.Right);
  const { width } = Dimensions.get('window');
  const initialWidth = useRef<number>(width);

  useEffect(() => {
    // We need to recalculate the carousel position so, if the windows resize we will force the index to recalculate from there.
    if (width && width !== initialWidth.current && device.isWeb) {
      activeIndex.current = 0;
      indexUpdate(0);
      ref.current?.scrollToIndex({ animated: true, index: 0 });
    }
  }, [width, initialWidth, device.isWeb, ref]);

  /**
   * Called when scroll view has finished moving.  (ScrollView onMomentumScrollEnd)
   */
  const endDrag = useCallback(
    (e: NativeSyntheticEvent<NativeScrollEvent>) => {
      const offset = Number(e.nativeEvent.contentOffset.x);

      if (contentOffset.current !== offset) {
        scrollDirection.current = contentOffset.current <= offset ? SetScrollDirection.Right : SetScrollDirection.Left;
        const newIndex = Math.round(offset / itemWidth);
        const shouldUpdateIndex =
          scrollDirection.current === SetScrollDirection.Right
            ? !!lastIndex && index < newIndex && newIndex < lastIndex
            : !!lastIndex && index > newIndex && newIndex >= 0;

        if (!device.isTv && shouldUpdateIndex) {
          activeIndex.current = newIndex;
          indexUpdate(newIndex);
        }
        contentOffset.current = offset;
      }
    },
    [device.isTv, itemWidth, lastIndex, index, indexUpdate]
  );

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (device.isTv) {
      timer = setTimeout(() => ref.current?.scrollToIndex({ animated: true, index: index }), 500);
    }
    return () => clearTimeout(timer);
  }, [index, ref, device.isTv]);

  useEffect(() => {
    if (!device.isTv && activeIndex.current !== index) {
      /**
       * Called when index prop is changed.
       */
      if (typeof index !== 'undefined') {
        ref.current?.scrollToIndex({ animated: true, index: index });
        activeIndex.current = index;
      }
    }
  }, [index, ref, device.isTv]);

  return { endDrag };
}
