import React, { useEffect, useRef, useState } from 'react';
import { ImageStyle, StyleProp, Text, TextStyle, View, ViewStyle } from 'react-native';
import { getStyles } from './help.styles';
import { useReactiveVar } from '@apollo/client';
import { breakpointsStateStore } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { isTickerAvailable, useScaleSizing } from '@warnermmedia/gsp-core/brands/estadio/feature';
import { useTheme } from 'react-native-paper';
import QrCode from '../modal/qrcodeModal/qRCode';
import { FocusKeys } from '@warnermmedia/gsp-core/sdk/data-access';
import { useSpatialNavigation } from '@warnermmedia/gsp-core/sdk/ui';

export interface helpScreenProps {
  containerStyle?: StyleProp<ViewStyle>;
  title?: string;
  paragraph?: string;
  subparagraph?: string;
  link?: string;
  imageStyle?: StyleProp<ImageStyle>;
  titleStyle?: StyleProp<TextStyle>;
  paragraphStyle?: StyleProp<TextStyle>;
  textContainerStyle?: StyleProp<ViewStyle>;
}

export function HelpScreen({
  title = '¿Quieres saber más?',
  paragraph = 'Escanea el código QR o ingresa a',
  subparagraph = 'para acceder a nuestros artículos de ayuda,términos y condiciones o ponerte en contacto con un agente.',
  containerStyle = {},
  textContainerStyle = {},
  link = 'estadiocl.zendesk.com',
}: helpScreenProps) {
  const [qrWidth, setQrWidth] = useState<number>(405);
  const scaling = useScaleSizing();
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const { colors } = useTheme();
  const styles = getStyles(breakpoints, true, scaling, colors);
  const helpFocusKey = useRef(FocusKeys.HELP_TV).current;
  const { ref, focusSelf, setFocusOnNavbar, focused } = useSpatialNavigation({
    focusKey: helpFocusKey,
    onArrowPress: (direction) => {
      if (direction === 'left') {
        setFocusOnNavbar();
      }

      if (direction === 'right' || direction === 'down') {
        focusSelf();
        return false;
      }

      if (direction === 'up' && !isTickerAvailable()) {
        focusSelf();
        return false;
      }
      return true;
    },
  });
  const customBorderStyle = {
    borderWidth: !focused ? 0 : 4,
  };

  useEffect(() => {
    setQrWidth(breakpoints.windowWidth * 0.15);
  }, [breakpoints]);

  return (
    <View style={[styles.container, customBorderStyle, containerStyle]} ref={ref} nativeID={helpFocusKey}>
      <View style={[styles.textContainer, textContainerStyle]}>
        <View style={styles.textWrapper}>
          <Text style={styles.titleStyle}>{title}</Text>
          <View>
            <Text style={styles.paragraphStyle}>{paragraph}</Text>
            <Text style={styles.linkStyle}>
              {link} <Text style={styles.paragraphStyle}>{subparagraph}</Text>
            </Text>
          </View>
        </View>
      </View>
      <View>
        <View style={styles.qrStyleContainer}>
          <QrCode qrCodeString={link ?? ''} size={qrWidth} />
        </View>
      </View>
    </View>
  );
}

export default HelpScreen;
