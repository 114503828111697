import { callApiEndpoint, createApiResponse, getQueryParams } from '../axiosHelper';
import { AxiosTypes } from '../../types/axios-types';
import {
  CheckCodeParams,
  CheckCodeResponse,
  ClaimCodeParams,
  ClaimCodeResponse,
  GetCodeParams,
  GetCodeResponse,
  PinAndPairCheckResponse,
  PinAndPairClaimResponse,
  PinAndPairGetResponse,
  PinAndPairPath,
  PinAndPairValidateResponse,
  ValidateCodeParams,
  ValidateCodeResponse,
} from '../../types/pinAndPair-types';

interface PinAndPairProps {
  getCode(getCodeParams: GetCodeParams): PinAndPairGetResponse;
  validateCode(validateCodeParams: ValidateCodeParams): PinAndPairValidateResponse;
  checkCode(checkCodeParams: CheckCodeParams): PinAndPairCheckResponse;
  claimCode(claimCodeParams: ClaimCodeParams): PinAndPairClaimResponse;
}

const usePinAndPair = (pinAndPairBaseApiPath: string, tenant: string, userToken?: string): PinAndPairProps => {
  const getCode = async (getCodeParams: GetCodeParams): PinAndPairGetResponse => {
    const url = `${PinAndPairPath.GET_CODE}${getQueryParams({ ...getCodeParams, ...{ tenant } })}`;
    const getCodeApiCall = await callApiEndpoint(AxiosTypes.GET, pinAndPairBaseApiPath, url, null);

    return createApiResponse<GetCodeResponse>(getCodeApiCall);
  };

  const validateCode = async (validateCodeParams: ValidateCodeParams): PinAndPairValidateResponse => {
    const validateCodeApiCall = await callApiEndpoint(
      AxiosTypes.GET,
      pinAndPairBaseApiPath,
      `${PinAndPairPath.VALIDATE_CODE}/${validateCodeParams.code}`,
      null,
      null,
      userToken
    );

    return createApiResponse<ValidateCodeResponse>(validateCodeApiCall);
  };

  const checkCode = async (checkCodeParams: CheckCodeParams): PinAndPairCheckResponse => {
    const checkCodeApiCall = await callApiEndpoint(
      AxiosTypes.GET,
      pinAndPairBaseApiPath,
      `${PinAndPairPath.CHECK_CODE}/${checkCodeParams.code}`,
      null,
      null,
      userToken
    );

    return createApiResponse<CheckCodeResponse>(checkCodeApiCall);
  };

  const claimCode = async (claimCodeParams: ClaimCodeParams): PinAndPairClaimResponse => {
    const claimCodeApiCall = await callApiEndpoint(
      AxiosTypes.PUT,
      pinAndPairBaseApiPath,
      `${PinAndPairPath.CLAIM_CODE}/${claimCodeParams.regCode}`,
      JSON.stringify({
        uid: claimCodeParams.uid,
        authToken: claimCodeParams.userToken,
        options: claimCodeParams.options,
      }),
      null,
      claimCodeParams.userToken
    );

    return createApiResponse<ClaimCodeResponse>(claimCodeApiCall);
  };

  return {
    getCode: (getCodeParams: GetCodeParams): PinAndPairGetResponse => getCode(getCodeParams),
    validateCode: (validateCodeParams: ValidateCodeParams): PinAndPairValidateResponse =>
      validateCode(validateCodeParams),
    checkCode: (checkCodeParams: CheckCodeParams): PinAndPairCheckResponse => checkCode(checkCodeParams),
    claimCode: (claimCodeParams: ClaimCodeParams): PinAndPairClaimResponse => claimCode(claimCodeParams),
  };
};

export default usePinAndPair;
