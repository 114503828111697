import merge from 'lodash/merge';
import { StyleProp, StyleSheet, TextStyle } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';

interface Styles {
  consent: TextStyle;
  link: TextStyle;
}
export const getStyles = (breakpoints: Breakpoints, colors: ReactNativePaper.ThemeColors) => {
  const base: StyleProp<Styles> = {
    consent: {
      color: colors.keyboardFocus.light,
    },
    link: {
      textDecorationLine: 'underline',
    },
  };
  const mediaQueries =
    breakpoints.currentBreakpoints.isTiny ||
    breakpoints.currentBreakpoints.isTnySm ||
    breakpoints.currentBreakpoints.isSmMed
      ? ({} as StyleProp<Styles>)
      : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
