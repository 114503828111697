import { ViewStyle, StyleSheet, StyleProp, TextStyle } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';
import { BreakpointsSize, ScaleAction } from '@warnermmedia/gsp-core/brands/estadio/feature';

interface Styles {
  container: ViewStyle;
  titleStyle: TextStyle;
  paragraphStyle: TextStyle;
  linkStyle: TextStyle;
  textContainer: ViewStyle;
  paragraphContainer: ViewStyle;
  imageContainer: ViewStyle;
  qrStyleContainer: ViewStyle;
  textWrapper: ViewStyle;
}

export const getStyles = (
  breakpoints: Breakpoints,
  isTv: boolean,
  scaleSizing: ScaleAction,
  colors: ReactNativePaper.ThemeColors
) => {
  const { scaleHeight, scaleWidth } = scaleSizing;
  const base: StyleProp<Styles> = {
    container: {
      backgroundColor: colors.tenantBackground.dark.surface03,
      borderRadius: 0,
      flexDirection: 'row',
      borderColor: colors.foreground.onDark.text02,
      paddingHorizontal: scaleWidth(141, BreakpointsSize.Tv),
      marginHorizontal: scaleWidth(265, BreakpointsSize.Tv),
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: scaleWidth(40, BreakpointsSize.Tv),
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: scaleWidth(20, BreakpointsSize.Tv),
      width: '75%',
      paddingVertical: scaleWidth(175, BreakpointsSize.Tv),
      alignSelf: 'center',
    },
    titleStyle: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Oswald-SemiBold',
      fontSize: scaleHeight(44, BreakpointsSize.Tv),
      fontWeight: '600',
      textTransform: 'uppercase',
      marginBottom: scaleWidth(40, BreakpointsSize.Tv),
      textAlign: 'left',
    },
    paragraphStyle: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Rubik-Regular',
      fontSize: scaleHeight(24, BreakpointsSize.Tv),
      lineHeight: scaleHeight(32, BreakpointsSize.Tv),
      textAlign: 'left',
      fontWeight: 'normal',
    },
    linkStyle: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Rubik-Regular',
      fontSize: scaleHeight(24, BreakpointsSize.Tv),
      fontWeight: '900',
      textAlign: 'left',
    },
    textContainer: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      flexBasis: '60%',
      paddingRight: scaleWidth(100, BreakpointsSize.Tv),
    },
    paragraphContainer: {
      flexDirection: 'row',
      paddingRight: scaleWidth(40, BreakpointsSize.Tv),
      alignItems: 'center',
      justifyContent: 'center',
    },
    imageContainer: {
      alignItems: 'flex-end',
      justifyContent: 'center',
    },
    qrStyleContainer: {
      backgroundColor: colors.tenantBackground.light.surfaceBase,
      padding: scaleWidth(20, BreakpointsSize.Tv),
    },
    textWrapper: {
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      alignSelf: 'flex-start',
    },
  };

  const mediaQueries =
    breakpoints.currentBreakpoints.isXlXxl || breakpoints.currentBreakpoints.isXxl
      ? ({
          textContainer: {
            paddingRight: 50,
          },
        } as StyleProp<Styles>)
      : breakpoints.currentBreakpoints.isLgXl
      ? ({
          container: {
            paddingVertical: 50,
            paddingHorizontal: 20,
          },
          textContainer: {
            paddingRight: 50,
          },
        } as StyleProp<Styles>)
      : isTv
      ? ({
          textContainer: {
            paddingRight: 150,
          },
        } as StyleProp<Styles>)
      : {};

  const mergedStyles = base;

  return StyleSheet.create(mergedStyles);
};
