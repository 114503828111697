import { ImageAssets } from '@warnermmedia/gsp-core/brands/estadio/assets';
import { AppConfigContext, ImageWithStyle, Maybe } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { useCallback, useContext } from 'react';
import { ImageSourcePropType } from 'react-native';
import { get } from 'lodash';
import { EstadioImageWithStyle } from '../Utils/Interfaces';

export enum CardDefaultBackground {
  Team = 'defaultTeamLogo',
  Hero = 'heroBackground',
  Match = 'matchBackground',
  Tournament = 'championship',
  TournamentBg = 'tournamentBg',
  EstadioBg = 'stadiumBackground',
}

interface CardItemImageParams {
  fallbackName?: CardDefaultBackground | string;
  image?: ImageWithStyle | Maybe<ImageWithStyle> | EstadioImageWithStyle | null;
}

interface CardItemImageAction {
  getCardImageSource: (params: CardItemImageParams) => ImageSourcePropType;
}

export const useCardItemImage = (): CardItemImageAction => {
  const appConfig = useContext(AppConfigContext);
  const base = appConfig?.appConfig?.imageSettings?.let;

  const getCardImageSource = useCallback(
    ({ fallbackName = '', image }: CardItemImageParams): ImageSourcePropType => {
      const imageUrl = get(image, 'image.url', '');
      const fallback = get(ImageAssets, fallbackName, fallbackName);
      const url = imageUrl ? (imageUrl.includes('http') ? imageUrl : imageUrl ? `${base}${imageUrl}` : '') : fallback;
      return typeof url === 'string'
        ? {
            uri: url,
          }
        : url;
    },
    [base]
  );

  return {
    getCardImageSource,
  };
};

export default useCardItemImage;
