import { useCallback, useContext, useEffect, useState } from 'react';
import { GestureResponderEvent } from 'react-native';
import { loadData, logoutMparticleUserId, TVE_TOOLBOX_AUTH_TOKEN } from '@warnermmedia/gsp-core/sdk/data-access';

import { Dalton, Tve } from '../../';
import {
  HistoryContext,
  isAppTransitioning,
  isUserLoggedIn,
  userHasSubscription,
} from '@warnermmedia/gsp-core/brands/estadio/data-access';

import { isEmpty } from 'lodash';
import { useApolloClient, useReactiveVar } from '@apollo/client';
import { useLocation } from 'react-router';

interface LogoutAction {
  logout: (e?: Event | GestureResponderEvent) => void;
}

export const useLogout = (): LogoutAction => {
  const historyContext = useContext(HistoryContext);
  const history = historyContext?.ready ? historyContext?.useHistory() : null;
  const [shouldRedirectToLogin, setShouldRedirectToLogin] = useState(false);
  const isTransitioning = useReactiveVar(isAppTransitioning);
  const location = useLocation();
  const { pathname } = location;
  const useDalton = Dalton();
  const client = useApolloClient();
  const useTve = Tve();

  useEffect(() => {
    if (shouldRedirectToLogin && !isTransitioning && pathname !== '/login') {
      history?.push('/login');
    }
  }, [shouldRedirectToLogin, isTransitioning, pathname, history]);

  useEffect(() => {
    if (pathname === '/login') {
      setShouldRedirectToLogin(false);
    }
  }, [shouldRedirectToLogin, pathname]);

  const onSuccess = useCallback(async () => {
    logoutMparticleUserId();
    await client.clearStore();
    isAppTransitioning(false);
    isUserLoggedIn(false);
    userHasSubscription(null);
    setShouldRedirectToLogin(true);
  }, [client]);

  const tveLogout = useCallback(() => {
    useTve.logout(async (result: boolean) => {
      await onSuccess();
    });
  }, [useTve, onSuccess]);

  const daltonLogout = useCallback(() => {
    useDalton.logout(async () => {
      await onSuccess();
    });
  }, [useDalton, onSuccess]);

  const logout = useCallback(
    (e?: Event | GestureResponderEvent) => {
      e && e.preventDefault();
      isAppTransitioning(true);
      const isTveAuthenticated = !isEmpty(loadData(TVE_TOOLBOX_AUTH_TOKEN));
      isTveAuthenticated ? tveLogout() : daltonLogout();
    },
    [tveLogout, daltonLogout]
  );

  return {
    logout,
  };
};

export default useLogout;
