import merge from 'lodash/merge';
import { StyleProp, StyleSheet, TextStyle, ViewStyle } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';

interface Styles {
  input: TextStyle;
  container: ViewStyle;
}

export const getStyles = (breakpoints: Breakpoints, colors: ReactNativePaper.ThemeColors) => {
  const base: StyleProp<Styles> = {
    input: {
      height: 50,
      fontSize: 16,
      color: colors.keyboardFocus.dark,
      zIndex: 1,
      paddingHorizontal: 14,
      margin: 0,
      borderRadius: 4,
      width: '100%',
      fontFamily: 'Rubik-Regular',
      backgroundColor: colors.keyboardFocus.light,
    },
    container: {
      borderColor: colors.keyboardFocus.dark,
      borderWidth: 1,
      borderStyle: 'solid',
      borderRadius: 4,
      marginTop: 8,
    },
  };
  const mediaQueries =
    breakpoints.currentBreakpoints.isTiny ||
    breakpoints.currentBreakpoints.isTnySm ||
    breakpoints.currentBreakpoints.isSmMed
      ? ({} as StyleProp<Styles>)
      : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
