import { RefObject, createRef } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-types
export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

export type ReferenceObject<T> = {
  idx: number;
  ref: RefObject<T>;
};
export function createRefs<T>(length: number): Array<ReferenceObject<T>> {
  const arr: Array<ReferenceObject<T>> = [];
  for (let idx = 0; idx < length; idx++) {
    arr.push({
      idx,
      ref: createRef<T>(),
    });
  }

  return arr;
}
