import React, { useRef } from 'react';
import { getUniqueId } from '@warnermmedia/gsp-core/brands/estadio/feature';
import { ItemName } from '@warnermmedia/gsp-core/sdk/data-access';
import { useSpatialNavigation } from '@warnermmedia/gsp-core/sdk/ui';

export interface PlayerControlProps {
  icon?: JSX.Element;
  onClick: () => void;
  focusKey?: string;
  style?: React.CSSProperties;
  className?: string;
  children?: string | JSX.Element;
}

export const PlayerControl = ({ icon, onClick, focusKey, style, className = '', children }: PlayerControlProps) => {
  const buttonFocusKey = useRef(focusKey ?? getUniqueId(ItemName.PLAYER_CONTROL)).current;
  const { ref } = useSpatialNavigation({
    focusKey: buttonFocusKey,
    onEnterPress: () => {
      onClick();
    },
  });

  return (
    <button tabIndex={0} ref={ref} onClick={onClick} style={style} id={buttonFocusKey} className={className}>
      {icon && icon}
      {children && children}
    </button>
  );
};

export default PlayerControl;
