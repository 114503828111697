export enum FocusKeys {
  RESEND_EMAIL = 'RESEND_EMAIL',
  SUBSCRIPTION_SUCCESS = 'SUBSCRIPTION_SUCCESS',
  SUBSCRIPTION_FAILURE = 'SUBSCRIPTION_FAILURE',
  REDEEM_COUPON = 'REDEEM_COUPON',
  CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION',
  MATCH_HERO = 'MATCH_HERO',
  PASSWORD_RECOVERY = 'PASSWORD_RECOVERY',
  PASSWORD_RESET = 'PASSWORD_RESET',
  CODE_VALIDATION_SUCCESS = 'CODE_VALIDATION_SUCCESS',
  INVOICE = 'INVOICE',
  BILLING = 'BILLING',
  PROFILE = 'PROFILE',
  PROFILE_LOGOUT = 'PROFILE_LOGOUT',
  PIN_LOGIN = 'PIN_LOGIN',
  PIN_LOGIN_ERROR = 'PIN_LOGIN_ERROR',
  PIN_LOGIN_UPDATE_CODE = 'PIN_LOGIN_UPDATE_CODE',
  PIN_LOGIN_BACK = 'PIN_LOGIN_BACK',
  PIN_LOGIN_REGISTER = 'PIN_LOGIN_REGISTER',
  PROVIDER_LOGIN = 'PROVIDER_LOGIN',
  LOGIN_TEXT_INPUT = 'LOGIN_TEXT_INPUT',
  REGISTER_ACCOUNT_FORM = 'REGISTER_ACCOUNT_FORM',
  HEADER_LOGIN_BUTTON = 'HEADER_LOGIN_BUTTON',
  TICKER = 'TICKER',
  NAVLOGO = 'NAVLOGO',
  SIDEBAR = 'SIDEBAR',
  UPDATE_MODAL = 'UPDATE_MODAL',
  CAROUSEL = 'CAROUSEL',
  GRID = 'GRID',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  FOOTER_LEFT = 'FOOTER_LEFT',
  SUBSCRIPTION_TV_BUTTON = 'SUBSCRIPTION_TV_BUTTON',
  SUBSCRIPTION_CARD = 'SUBSCRIPTION_CARD',
  SUBSCRIPTION_PAYPAL = 'SUBSCRIPTION_PAYPAL',
  PLAYER_PLAY_BUTTON = 'PLAYER_PLAY_BUTTON',
  PLAYER_OVERLY_BUTTON = 'PLAYER_PLAY_BUTTON',
  FOOTER_IMAGES_ROW = 'FOOTER_IMAGES_ROW',
  HELP_TV = 'HELP_TV',
  LANDING_HERO = 'LANDING_HERO',
  SUBSCRIBE_TO_MAX = 'SUBSCRIBE_TO_MAX',
}

export enum ItemName {
  TEAM_CARD = 'TEAM_CARD',
  DROPDOWN_ITEM = 'DROPDOWN_ITEM',
  BUTTON_ITEM = 'BUTTON_ITEM',
  TEXT_ITEM = 'TEXT_ITEM',
  BANNER_ITEM = 'BANNER_ITEM',
  RADIO_ITEM = 'RADIO_ITEM',
  CHECKBOX_ITEM = 'CHECKBOX_ITEM',
  PROVIDER_ITEM = 'PROVIDER_ITEM',
  LINK_BUTTON_ITEM = 'LINK_BUTTON_ITEM',
  REGISTER_BUTTON_ITEM = 'REGISTER_BUTTON_ITEM',
  PLAYER_BUTTON = 'PLAYER_BUTTON',
  PLAYER_CONTROL = 'PLAYER_CONTROL',
  HERO_ITEM = 'HERO_ITEM',
  TICKER_ITEM = 'TICKER_ITEM',
  FOOTER_LINK = 'FOOTER_LINK',
  GENERIC_CARD = 'GENERIC_CARD',
}
export const CAROUSEL_KEY = (weight = 1) => `${FocusKeys.CAROUSEL}_${weight}`;

export const GRID_KEY = (type?: string | null, weight = 1) => `${(type ?? FocusKeys.GRID).toUpperCase()}_${weight}`;
