import { StyleProp, StyleSheet, TextStyle, ViewStyle } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';
import { merge } from 'lodash';

interface Styles {
  statusLabel: TextStyle;
  statusContainerStyle: ViewStyle;
  statusContainerWrapper: ViewStyle;
}

export const getStyles = (breakpoints: Breakpoints) => {
  const base: StyleProp<Styles> = {
    statusContainerWrapper: {
      marginTop: 10,
      marginBottom: 16,
      justifyContent: 'flex-start',
    },
    statusContainerStyle: {
      marginHorizontal: 25,
      justifyContent: 'flex-start',
    },
    statusLabel: {
      marginRight: 100,
      fontWeight: '500',
      lineHeight: 16,
      fontFamily: 'Rubik-Medium',
      fontSize: 12,
    },
  };

  const mediaQueries =
    breakpoints.currentBreakpoints.isTiny ||
    breakpoints.currentBreakpoints.isTnySm ||
    breakpoints.currentBreakpoints.isSmMed
      ? ({
          statusContainerStyle: {
            flex: 1,
          },
          statusLabel: {
            marginRight: 0,
          },
        } as StyleProp<Styles>)
      : breakpoints.currentBreakpoints.isMedLg ||
        breakpoints.currentBreakpoints.isLgXl ||
        breakpoints.currentBreakpoints.isXlXxl ||
        breakpoints.currentBreakpoints.isXxl
      ? ({
          statusContainerWrapper: {
            maxWidth: 500,
          },
        } as StyleProp<Styles>)
      : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
