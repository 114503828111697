import { StyleProp, StyleSheet, TextStyle, ViewStyle } from 'react-native';

interface Styles {
  fullScreenContainer: ViewStyle;
  container: ViewStyle;
  containerInner: ViewStyle;
  title: TextStyle;
  description: TextStyle;
  leftContainer: ViewStyle;
  qrContainer: ViewStyle;
  qrContainerBorder: ViewStyle;
}

export const getStyles = (isAndroidTv: boolean, colors: ReactNativePaper.ThemeColors) => {
  const base: StyleProp<Styles> = {
    fullScreenContainer: {
      backgroundColor: 'rgba(0,0,0, .7)',
      position: 'absolute',
      width: '100%',
      height: '100%',
    },
    container: {
      flexDirection: 'column',
      marginTop: isAndroidTv ? '8%' : '10%',
      marginBottom: isAndroidTv ? 0 : '10%',
      marginHorizontal: '10%',
      backgroundColor: colors.tenantBackground.dark.surface03,
      alignItems: 'center',
      minWidth: '60%',
      paddingVertical: isAndroidTv ? 100 : 152,
      paddingHorizontal: 141,
    },
    containerInner: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    leftContainer: {
      flex: 1,
      marginRight: isAndroidTv ? 70 : 190,
    },
    title: {
      fontSize: isAndroidTv ? 32 : 44,
      color: colors.keyboardFocus.light,
      fontFamily: 'Oswald-SemiBold',
      textTransform: 'uppercase',
      paddingBottom: 12,
      fontWeight: '600',
    },
    description: {
      fontSize: isAndroidTv ? 20 : 24,
      color: colors.keyboardFocus.light,
      fontFamily: 'Rubik-Regular',
      fontWeight: 'normal',
    },
    qrContainer: {
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'red',
    },
    qrContainerBorder: {
      borderWidth: 4,
      borderColor: colors.tenantBackground.light.surfaceBase,
      flexShrink: 1,
    },
  };

  return StyleSheet.create(base);
};
