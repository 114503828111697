/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import {
  CheckCodeResponse,
  ClaimCodeResponse,
  DALTON_AUTH_TOKEN,
  GetCodeResponse,
  GetLocateResponse,
  PinAndPairCheckResponse,
  PinAndPairClaimResponse,
  PinAndPairGetResponse,
  PinAndPairValidateResponse,
  TVE_TOOLBOX_AUTH_TOKEN,
  useGeo,
  usePinAndPair,
  ValidateCodeResponse,
} from '@warnermmedia/gsp-core/sdk/data-access';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  AppConfigContext,
  GeoConfig,
  isTveAuth,
  loginAPIStateStore,
  PinAndPairConfig,
} from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { useContext, useEffect, useState } from 'react';

import { AxiosError } from 'axios';
import { useReactiveVar } from '@apollo/client';
import { getStoredAccessProperty, handleApiCall, handleApiCallError } from '../../Services/CommonFunctions';

export const PinAndPair = () => {
  const appConfig = useContext(AppConfigContext);
  const geoConfig: GeoConfig | undefined = appConfig?.appConfig?.geoConfig;
  const isTveAuthenticated = useReactiveVar(isTveAuth);
  const [userToken, setUserToken] = useState<string | null>(loginAPIStateStore().authToken);
  const pinAndPairConfig: PinAndPairConfig | undefined = appConfig?.appConfig?.pinAndPair;
  const pinAndPair = usePinAndPair(
    appConfig?.appConfig.apis.pinAndPairAPI ?? '',
    pinAndPairConfig?.tenant ?? 'estadiochile',
    userToken ?? ''
  );
  const geo = useGeo(appConfig?.appConfig.apis.geoAPI ?? '', geoConfig?.appId ?? '');

  useEffect(() => {
    const authKey = isTveAuthenticated ? TVE_TOOLBOX_AUTH_TOKEN : DALTON_AUTH_TOKEN;
    const token = getStoredAccessProperty<string>(authKey, 'token') ?? loginAPIStateStore().authToken;

    if (token) {
      setUserToken(token);
    }
  }, [isTveAuthenticated, loginAPIStateStore]);

  const getCode = async (deviceCode: string): PinAndPairGetResponse => {
    try {
      const locate = await handleApiCall<GetLocateResponse>(geo.getLocate());
      return handleApiCall<GetCodeResponse>(pinAndPair.getCode({ deviceCode, ip: locate.data?.ip ?? '' }));
    } catch (e) {
      return handleApiCallError(e as AxiosError);
    }
  };

  const validateCode = async (code: string): PinAndPairValidateResponse =>
    handleApiCall<ValidateCodeResponse>(pinAndPair.validateCode({ code }));

  const checkCode = async (code: string): PinAndPairCheckResponse =>
    handleApiCall<CheckCodeResponse>(pinAndPair.checkCode({ code }));

  const claimCode = async (regCode: string, uid: string, userToken: string, options: string): PinAndPairClaimResponse =>
    handleApiCall<ClaimCodeResponse>(pinAndPair.claimCode({ regCode, uid, userToken, options }));

  return {
    getCode,
    validateCode,
    checkCode,
    claimCode,
  };
};
