import { useCallback, useContext, useEffect } from 'react';
import { useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { useIsMountedRef } from '../useIsMountedRef';
import { useReactiveVar } from '@apollo/client';
import { AppConfigContext, isUserLoggedIn } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { useGetDevice } from '@warnermmedia/gsp-core/sdk/ui';
import { CAROUSEL_KEY, FocusKeys, GRID_KEY, ROUTE_FOCUS_KEY, saveData } from '@warnermmedia/gsp-core/sdk/data-access';
import { isElementAvailable } from '../../..';

export const useSetSpatialNavigationFocus = (pathname: string) => {
  const { setFocus } = useFocusable();
  const isMountedRef = useIsMountedRef();
  const isLogged = useReactiveVar(isUserLoggedIn);
  const appConfig = useContext(AppConfigContext);
  const isRegisterEnabled = appConfig?.appConfig?.registerEnabled ?? true;
  const subscriptionEnabled = appConfig?.appConfig?.subscriptionEnabled ?? true;
  const { isWeb, isTv } = useGetDevice();

  const getRouteFocusKey = useCallback(
    (route: string): string => {
      let key = '';
      switch (route) {
        case '/home':
          key = CAROUSEL_KEY(1);
          break;
        case '/profile':
          key = FocusKeys.PROFILE;
          break;
        case '/shows':
          key = GRID_KEY('shows', 1);
          break;
        case '/teams':
          key = GRID_KEY('teams', 1);
          break;
        case '/tournaments':
          key = GRID_KEY('tournaments', 1);
          break;
        case '/tvsignal':
        case '/golfchannel':
          key = isWeb && isElementAvailable(FocusKeys.TICKER) ? FocusKeys.TICKER : '';
          break;
        case '/forgot-password':
          key = FocusKeys.FORGOT_PASSWORD;
          break;
        case '/register':
          key = isRegisterEnabled ? FocusKeys.REGISTER_ACCOUNT_FORM : FocusKeys.LANDING_HERO;
          break;
        case '/login':
          key = FocusKeys.LOGIN_TEXT_INPUT;
          break;
        case '/profile/billing':
          key = FocusKeys.BILLING;
          break;
        case '/resend-email':
          key = FocusKeys.RESEND_EMAIL;
          break;
        case '/subscribe':
          key = !subscriptionEnabled
            ? FocusKeys.SUBSCRIBE_TO_MAX
            : isTv
            ? FocusKeys.SUBSCRIPTION_TV_BUTTON
            : isElementAvailable(FocusKeys.TICKER)
            ? FocusKeys.TICKER
            : '';
          break;
        case '/redeemcoupon':
          key = FocusKeys.REDEEM_COUPON;
          break;
        case '/subscriptions/paypal/success':
          key = FocusKeys.SUBSCRIPTION_SUCCESS;
          break;
        case '/subscriptions/paypal/failure':
          key = FocusKeys.SUBSCRIPTION_FAILURE;
          break;
        case '/cancel-subscription':
          key = FocusKeys.CANCEL_SUBSCRIPTION;
          break;
        case '/consulta-boleta':
          key = FocusKeys.INVOICE;
          break;
        case '/logintv-success':
          key = FocusKeys.CODE_VALIDATION_SUCCESS;
          break;
        case '/login-provider':
          key = FocusKeys.PROVIDER_LOGIN;
          break;
        case '/pinlogin':
          key = FocusKeys.PIN_LOGIN;
          break;
        case '/help':
          key = FocusKeys.HELP_TV;
          break;
        default:
          key = '';
      }
      return key;
    },
    [isTv, isWeb, isRegisterEnabled, subscriptionEnabled]
  );

  const getFirstPageFocusKey = useCallback((): string => {
    return isLogged
      ? CAROUSEL_KEY(1)
      : isWeb
      ? isRegisterEnabled
        ? FocusKeys.REGISTER_ACCOUNT_FORM
        : FocusKeys.LANDING_HERO
      : isTv
      ? FocusKeys.PIN_LOGIN
      : FocusKeys.LOGIN_TEXT_INPUT;
  }, [isLogged, isTv, isWeb, isRegisterEnabled]);

  const getFirstDetailFocusKey = useCallback(
    (route: string): string => {
      let key = '';
      if (route.startsWith('/show/')) {
        key = CAROUSEL_KEY(1);
      }
      if (route.startsWith('/video/')) {
        key = isWeb ? FocusKeys.TICKER : '';
      }
      if (route.startsWith('/team/') || route.startsWith('/tournaments/')) {
        key = CAROUSEL_KEY(1);
      }
      if (route.startsWith('/match/')) {
        key = FocusKeys.MATCH_HERO;
      }
      return key;
    },
    [isWeb]
  );

  const getNextFocusKey = useCallback(
    (route: string): string => {
      const detailPages = ['/team/', '/show/', '/tournaments/', ' /match/', '/video/'];

      if (route === '/') {
        return getFirstPageFocusKey();
      }

      if (detailPages.some((detail) => route.startsWith(detail))) {
        return getFirstDetailFocusKey(route);
      }

      return getRouteFocusKey(route);
    },
    [getRouteFocusKey, getFirstDetailFocusKey, getFirstPageFocusKey]
  );

  useEffect(() => {
    const currentKey = getNextFocusKey(pathname);
    if (pathname && isMountedRef.current && currentKey) {
      setFocus(currentKey);
      saveData(ROUTE_FOCUS_KEY, currentKey);
    }
  }, [setFocus, pathname, getNextFocusKey, isMountedRef]);
};
