import React, { createContext, ReactNode, useCallback, useEffect, useState } from 'react';
import { NativeModules, Platform } from 'react-native';
import { APP_CONFIG_DEFAULT } from '../defauts';
import { AppConfig } from '../types';
import Axios from 'axios';
import { CUSTOM_DOMAIN, loadData } from '@warnermmedia/gsp-core/sdk/data-access';
import AsyncStorage from '@react-native-async-storage/async-storage';
import BuildConfig from 'react-native-build-config';
import { useGetDevice } from '@warnermmedia/gsp-core/sdk/ui';
import { isAppTransitioning } from '../Apollo/ApolloProvider';

interface Props {
  children: ReactNode;
}

export interface AppConfigContextProps {
  appConfig: AppConfig;
}

export const AppConfigContext = createContext<AppConfigContextProps | null>(null);

export const AppConfigProvider = ({ children }: Props) => {
  const [appConfig, setAppConfig] = useState<AppConfig>(APP_CONFIG_DEFAULT);
  const [appConfigUrl, setAppConfigUrl] = useState<string>('');
  const { device, isPwa, isIosDevice, isAndroidDevice } = useGetDevice();

  const initializeAppConfig = async (url: string) => {
    isAppTransitioning(false);
    try {
      const response = await Axios.get<AppConfig>(url);
      setAppConfig({
        ...response.data,
        device: device,
      });
      isAppTransitioning(false);
    } catch (e) {
      console.log(e);
      isAppTransitioning(false);
    }
  };

  const getURLFromStorage = useCallback(async () => {
    let url = null;
    const domain = await AsyncStorage.getItem(CUSTOM_DOMAIN);
    if (domain) url = `${domain.replace(/['"]+/g, '')}/${device}/config.json`;
    return url;
  }, [device]);

  const mobileCheckEnv = useCallback(async () => {
    let url = `https://appconfig-estadiotntsports.wmsports.io/${Platform.OS}/config.json`;
    if (isIosDevice) {
      const ProdChecker = NativeModules.ProdChecker;
      const isTestFlight = await ProdChecker.isTestflight();
      url =
        !__DEV__ && isTestFlight !== 'TESTFLIGHT'
          ? `https://appconfig-estadiotntsports.wmsports.io/${Platform.OS}/config.json`
          : `https://appconfig-estadiotntsports.stage.nonprod.wmsports.io/${Platform.OS}/config.json`;
      if (isTestFlight === 'TESTFLIGHT' || __DEV__) {
        url = (await getURLFromStorage()) ?? url;
      }
    }
    if (isAndroidDevice && BuildConfig.BUILD_TYPE !== 'release') {
      url = (await getURLFromStorage()) ?? url;
    }
    setAppConfigUrl(url);
  }, [getURLFromStorage, isAndroidDevice, isIosDevice]);

  useEffect(() => {
    let appConfigUrl = '';
    // TODO: Need a proper error screen and a way to show an error to the user
    if (isIosDevice || isAndroidDevice) {
      mobileCheckEnv();
    } else if (process.env.NODE_ENV) {
      if (['prod', 'production'].includes(process.env.NODE_ENV)) {
        appConfigUrl = `https://appconfig-estadiotntsports.wmsports.io/${device}/config.json`;
      } else {
        appConfigUrl = `https://appconfig-estadiotntsports.stage.nonprod.wmsports.io/${device}/config.json`;
      }
      if (isPwa && !['prod', 'production'].includes(process.env.NODE_ENV)) {
        const domain = loadData(CUSTOM_DOMAIN);
        if (domain) appConfigUrl = `${domain}/${device}/config.json`;
      }
      setAppConfigUrl(appConfigUrl);
    } else {
      console.log('Node env not set:', process.env);
    }
  }, [device, isAndroidDevice, isIosDevice, isPwa, mobileCheckEnv]);

  useEffect(() => {
    if (appConfigUrl !== '') initializeAppConfig(appConfigUrl);
  }, [appConfigUrl]);

  return <AppConfigContext.Provider value={{ appConfig }}>{children}</AppConfigContext.Provider>;
};
