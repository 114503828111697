import React, { useRef } from 'react';
import { PressableWithOpacity } from '../pressableWithOpacity';
import styles from './radioButton.styles';
import { getUniqueId } from '@warnermmedia/gsp-core/brands/estadio/feature';
import { ItemName } from '@warnermmedia/gsp-core/sdk/data-access';
import { useSpatialNavigation } from '../../spatialNavigation';

export interface RadioBtnProps {
  value: string;
  uncheckedColor: string;
  checkedColor: string;
  borderColor?: string;
  status: 'checked' | 'unchecked';
  onPress: (value: string) => void;
  disabled?: boolean;
  focusKey?: string;
}

export const RadioButton = ({
  value,
  uncheckedColor,
  checkedColor,
  status,
  onPress,
  borderColor,
  disabled = false,
  focusKey,
}: RadioBtnProps) => {
  const radioButtonFocusKey = useRef(focusKey ?? getUniqueId(ItemName.RADIO_ITEM)).current;
  const { ref } = useSpatialNavigation({
    focusKey: radioButtonFocusKey,
  });

  return (
    <PressableWithOpacity
      overrideStyle={[
        styles.button,
        {
          borderColor,
          backgroundColor: status === 'checked' ? checkedColor : uncheckedColor,
        },
      ]}
      disabled={disabled}
      onPress={() => onPress(value)}
      ref={ref}
      nativeID={radioButtonFocusKey}
    />
  );
};

export default RadioButton;
