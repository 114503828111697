enum Variant {
  Error = 'error',
  Warning = 'warning',
  Success = 'success',
  Generic = 'generic',
}

export type StatusMsgState = {
  type: Variant;
  message: string | null;
};

export const STATUS_MESSAGE_STATE_INITIAL: StatusMsgState = {
  type: Variant.Generic,
  message: null,
};
