import merge from 'lodash/merge';
import { StyleProp, StyleSheet, ViewStyle, TextStyle } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';

interface Styles {
  homeBtn: ViewStyle;
  infoWrapper: ViewStyle;
  infoTextWrapper: ViewStyle;
  infoIcon: ViewStyle;
  info: TextStyle;
  transactNo: TextStyle;
  btn: ViewStyle;
  btnLabel: TextStyle;
}

export const getStyles = (breakpoints: Breakpoints, colors: ReactNativePaper.ThemeColors) => {
  const base: StyleProp<Styles> = {
    homeBtn: {
      backgroundColor: colors.fill.action.accent01,
      borderColor: 'transparent',
    },
    infoWrapper: {
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
    infoTextWrapper: {
      width: '100%',
      marginTop: 0,
    },
    infoIcon: {
      height: 24,
      width: 24,
      marginRight: 0,
    },
    info: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Rubik-Regular',
      fontSize: 16,
      fontWeight: 'normal',
      marginVertical: 10,
      marginLeft: 10,
      width: '90%',
    },
    transactNo: {
      fontWeight: '600',
    },
    btn: {
      width: '100%',
      borderRadius: 4,
      borderColor: colors.tenantBackground.light.surfaceBase,
      marginVertical: 10,
    },
    btnLabel: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Oswald-Medium',
      fontSize: 20,
      fontWeight: '500',
      height: 24,
      lineHeight: 24,
      textAlign: 'center',
      textTransform: 'uppercase',
    },
  };

  const mediaQueries =
    breakpoints.currentBreakpoints.isTiny ||
    breakpoints.currentBreakpoints.isTnySm ||
    breakpoints.currentBreakpoints.isSmMed
      ? ({} as StyleProp<Styles>)
      : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
