import React, { useCallback } from 'react';
import { DevSettings, Platform, View } from 'react-native';
import { ApolloProvider, makeVar, ReactiveVar } from '@apollo/client';
import {
  CONFIG_STATE_INITIAL,
  ConfigState,
  FOOTER_STATE_INITIAL,
  FooterState,
  initalCurrentBreakpoints,
  initialBreakpointHelpers,
  initialBreakpointSizes,
  initialWindowHeight,
  initialWindowWidth,
  LOGINAPI_STATE_INITIAL,
  loginAPIState,
  MODAL_INITIAL_STATE,
  ModalStore,
  PLAYER_STATE_INITIAL,
  PlayerState,
  STATUS_MESSAGE_STATE_INITIAL,
  StatusMsgState,
} from '../state-machines';

import useApolloClient from './AppApolloClient';
import { ERROR_MESSAGE_STATE_INITIAL, ErrorMsgState, TveProvider } from '@warnermmedia/gsp-core/sdk/data-access';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';

export const footerStateStore: ReactiveVar<FooterState> = makeVar<FooterState>(FOOTER_STATE_INITIAL);

export const loginAPIStateStore: ReactiveVar<loginAPIState> = makeVar<loginAPIState>(LOGINAPI_STATE_INITIAL);

export const configStateStore: ReactiveVar<ConfigState> = makeVar<ConfigState>(CONFIG_STATE_INITIAL);

export const isUserLoggedIn: ReactiveVar<boolean> = makeVar<boolean>(LOGINAPI_STATE_INITIAL?.isLogged ?? false);

export const isTveAuth: ReactiveVar<boolean> = makeVar<boolean>(LOGINAPI_STATE_INITIAL?.isTveAuth ?? false);

export const tveProvider: ReactiveVar<TveProvider | null> = makeVar<TveProvider | null>(null);

export const isAppTransitioning: ReactiveVar<boolean> = makeVar<boolean>(false);

export const isUserEmailVerified: ReactiveVar<boolean | null> = makeVar<boolean | null>(
  LOGINAPI_STATE_INITIAL?.isUserEmailVerified ?? null
);

export const userHasSubscription: ReactiveVar<boolean | null> = makeVar<boolean | null>(
  LOGINAPI_STATE_INITIAL?.userHasSubscription ?? null
);

export const breakpointsStateStore: ReactiveVar<Breakpoints> = makeVar<Breakpoints>({
  breakpointSizes: initialBreakpointSizes,
  currentBreakpoints: initalCurrentBreakpoints,
  windowWidth: initialWindowWidth,
  windowHeight: initialWindowHeight,
  breakpointHelpers: initialBreakpointHelpers,
});

export const errorMsgStore: ReactiveVar<ErrorMsgState> = makeVar<ErrorMsgState>(ERROR_MESSAGE_STATE_INITIAL);

export const statusMsgStore: ReactiveVar<StatusMsgState> = makeVar<StatusMsgState>(STATUS_MESSAGE_STATE_INITIAL);

export const playerStateStore: ReactiveVar<PlayerState> = makeVar<PlayerState>(PLAYER_STATE_INITIAL);

export const isNavOpen: ReactiveVar<boolean> = makeVar<boolean>(false);

export const removePageNavHeader: ReactiveVar<boolean> = makeVar<boolean>(false);

export const isZendeskLoaded: ReactiveVar<boolean> = makeVar<boolean>(false);

export const isVideoPlayerFullScreen: ReactiveVar<boolean> = makeVar<boolean>(false);

/** Add more/ move all modal state here */
export const modalStateStore: ReactiveVar<ModalStore> = makeVar<ModalStore>(MODAL_INITIAL_STATE);

/**
 * Data Never In Reactive Var Until its initilaized will fix Later
 * Will soon remove this inittialization of states cache persistence to device fixes this issue
 */
footerStateStore({ ...footerStateStore(), ...FOOTER_STATE_INITIAL });
loginAPIStateStore({ ...loginAPIStateStore(), ...LOGINAPI_STATE_INITIAL });
configStateStore({ ...configStateStore(), ...CONFIG_STATE_INITIAL });

export const AppApolloProvider: React.FC = ({ children }): JSX.Element => {
  /**
   * We can use clear cache anytime we want to clear the cache from the Device
   * for security resons or more
   */
  const { client, clearCache } = useApolloClient();

  function isWebPlatform(): boolean {
    return Platform && Platform?.OS && Platform?.OS === 'web';
  }

  // Use it to reload settings & setup when ever its needed
  const reload = useCallback(() => {
    if (!isWebPlatform()) {
      DevSettings.reload();
    } else {
      window.location.reload();
    }
  }, []);

  /**
   * Let's Show something till ApolloClient is ready with Persistence
   * and all - Initializing app...
   */
  if (!client) {
    return <View></View>;
  }

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
