import React, { ReactNode } from 'react';
import { Image, ImageStyle, Text, TextStyle, View, ViewStyle } from 'react-native';
import { CustomButton, CustomTextInput, Dropdown, DropDownItem, RadioButtonGroup } from '../formElements';
import {
  formatDateDDMMYYYY,
  formatePhone,
  validateDate,
  validateEmail,
  validateNonEmptyString,
  validatePasswordRule,
} from '../../utils';
import styles from './accountDetails.styles';
import { Tooltip } from '../tooltip';
import { TextContent } from '../textcontent';
import { useGetDevice } from '../../hooks';
import get from 'lodash/get';
import { FocusKeys } from '@warnermmedia/gsp-core/sdk/data-access';

type OnInputChange = (inputName: string, value: string) => void;
export type OverrideRenderDetail = (entry: DetailEntry, value: string, onChange?: OnInputChange) => React.ReactNode;
type InputTypes = 'string' | 'password' | 'date' | 'phone' | 'image' | 'radio' | 'select';

export interface ValueOptions {
  label: string;
  value: string | number;
  custom?: ReactNode;
}

export type acctOptionsObj = {
  label: string;
  type: InputTypes;
  isHidden?: boolean;
  isRequired?: boolean;
  readOnly?: boolean;
  isMultilineText?: boolean;
  validator?: (value: string) => boolean;
  overrideStyle?: ViewStyle | ImageStyle | TextStyle;
  onChange?: OnInputChange;
  hasError?: boolean;
  setError?: (label: string, value: boolean) => void;
  errorMessage?: string;
  overrideRenderDetail?: OverrideRenderDetail;
  valueOptions?: string[] | ValueOptions[];
  maxLength?: number;
};

export type DetailEntry = [string, acctOptionsObj];

export interface AccountDetailsProps<T> {
  details: T;
  detailsOptions: {
    [K in keyof T]: acctOptionsObj;
  };
  title?: string;
  titleStyle?: TextStyle;
  subTitle?: string;
  subTitleStyle?: TextStyle;
  actionBtnText?: string;
  editable?: boolean;
  disabled?: boolean;
  wrapperStyle?: ViewStyle;
  detailsWrapperStyle?: ViewStyle;
  detailsStyle?: ViewStyle;
  detailStyle?: ViewStyle;
  detailLabelStyle?: TextStyle;
  detailValueStyle?: TextStyle;
  actionBtnStyle?: ViewStyle;
  actionBtnTextStyle?: TextStyle;
  handleActionBtnPress?: () => void;
  passwordHideIcon?: JSX.Element;
  passwordShowIcon?: JSX.Element;
  isPasswordIconClicked?: { password: boolean; passwordConfirmation: boolean };
  handlePasswordIconClick?: (label: string, value: boolean) => void;
}

export function GenericAccountDetails<T>({
  titleStyle,
  subTitleStyle,
  subTitle,
  title,
  actionBtnText,
  details,
  detailsOptions,
  detailsWrapperStyle,
  wrapperStyle,
  detailsStyle,
  detailStyle,
  detailLabelStyle,
  detailValueStyle,
  actionBtnStyle,
  actionBtnTextStyle,
  passwordHideIcon,
  passwordShowIcon,
  isPasswordIconClicked,
  handlePasswordIconClick,
  editable = false,
  disabled = false,
  handleActionBtnPress = () => null,
}: AccountDetailsProps<T>): React.ReactElement | null {
  const { isWeb, isMobileDevice } = useGetDevice();

  const selectValidator = (type: string, value: string) => {
    switch (type) {
      case 'email':
        return validateEmail(value);
      case 'password':
        return validatePasswordRule(value);
      case 'date':
        return validateDate(new Date(value));
      case 'select':
        return validatePasswordRule(value);
      case 'radio':
        return validateDate(new Date(value));
      default:
        return validateNonEmptyString(value);
    }
  };

  const renderText = (value: string, obscure?: boolean, overrideStyle?: TextStyle) => {
    return (
      <TextContent style={[styles.detailValue, detailValueStyle, overrideStyle]}>
        {obscure ? '***********' : value ? value : '-'}
      </TextContent>
    );
  };

  const formateValue = (value: string, type: string) => {
    if (type === 'phone') {
      return formatePhone(value);
    }
    return value;
  };

  const renderDatePicker = (value: string, entry: DetailEntry) => {
    const detail = entry[1];
    const label = entry[0];
    if (isWeb && editable) {
      return React.createElement('input', {
        type: 'date',
        value: value,
        onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
          const { value } = event.target;
          detail.onChange && detail.onChange(value, label);
          if (detail.isRequired) {
            const isValid = detail.validator ? detail.validator(value) : selectValidator(entry[0], value);
            detail.setError && detail.setError(label, !isValid);
          }
        },
        style: {
          height: '40px',
          borderRadius: '5px',
          border: `${detail.hasError ? '2px solid red' : '1px solid'}`,
        },
        disabled: disabled,
      });
    }
    return renderText(value ? formatDateDDMMYYYY(value) : '-');
  };

  const renderImageDetail = (imageSource: string, entry: DetailEntry) => {
    const detail = entry[1];
    const label = entry[0];
    return (
      <View style={styles.imageContainer}>
        <Image source={{ uri: imageSource }} style={[styles.detailsImage, detail.overrideStyle as ImageStyle]} />
        {editable && isWeb && (
          <View>
            {React.createElement('input', {
              type: 'file',
              value: '',
              onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                const { value } = event.target;
                detail.onChange && detail.onChange(value, label);
                if (detail.isRequired) {
                  const isValid = detail.validator ? detail.validator(value) : selectValidator(entry[0], value);
                  detail.setError && detail.setError(label, !isValid);
                }
              },
              style: {
                height: '25px',
                borderRadius: '5px',
                marginLeft: '10px',
              },
            })}
          </View>
        )}
      </View>
    );
  };

  const renderTextDetail = (value: string, entry: DetailEntry) => {
    const detail = entry[1];
    const label = entry[0];
    const formattedValue = value ? formateValue(value, detail.type) : '';
    const isPassword = detail.type === 'password';

    const setFieldError = (textValue = '') => {
      const formattedValue = formateValue(textValue, detail.type);
      const isValid = detail.validator ? detail.validator(formattedValue) : selectValidator(entry[0], formattedValue);
      detail.setError && detail.setError(label, !isValid);
    };

    const isIconClicked = get(isPasswordIconClicked ?? {}, label, false);
    const getPasswordIcon = () => {
      if (!passwordHideIcon && !passwordShowIcon) {
        return;
      }
      return isIconClicked ? passwordShowIcon : passwordHideIcon;
    };

    const handleIconClick = () => {
      handlePasswordIconClick && handlePasswordIconClick(label, !isIconClicked);
    };
    if (editable) {
      return (
        <CustomTextInput
          maxLength={100}
          containerStyle={[detail.overrideStyle as ViewStyle]}
          inputStyle={formattedValue == '' ? detailValueStyle : styles.detailValueFilled}
          value={formattedValue}
          multiline={detail.isMultilineText}
          secureTextEntry={isPassword && !get(isPasswordIconClicked ?? {}, label, false)}
          error={detail.hasError}
          onChangeText={(text) => {
            detail.onChange && detail.onChange(text, label);
            setFieldError(text);
          }}
          onBlur={() => {
            setFieldError(formattedValue);
          }}
          editable={!disabled}
          focusable={!disabled}
          {...(isPassword ? { icon: getPasswordIcon(), handleIconClick } : {})}
          shouldUseFourArrow={true}
        />
      );
    }
    return renderText(formattedValue, isPassword);
  };

  const renderRadio = (value: string, entry: DetailEntry) => {
    const detailOptions = entry[1];
    if (editable) {
      if (detailOptions.valueOptions) {
        return (
          <RadioButtonGroup
            options={detailOptions.valueOptions as string[]}
            name="gender"
            value={value}
            checkedColor="rgb(255, 0, 133)"
            uncheckedColor="#FFFFFF"
            overrideStyle={styles.radioBtnWrapper}
            overrideLabelStyle={{ ...styles.radioLabelStyle, ...detailOptions.overrideStyle }}
            onValueChange={(value, label) => {
              detailOptions.onChange && detailOptions.onChange(value, label);
            }}
            disabled={disabled}
          />
        );
      }
      return renderText('No options defined');
    }
    return renderText(value);
  };

  const getDropdownList = (valueOptions: ValueOptions[]): DropDownItem[] => {
    return valueOptions.map((item) => ({
      label: item.label,
      value: item.value,
      disabled: false,
    }));
  };

  const renderSelect = (value: string, entry: DetailEntry) => {
    const detailOptions = entry[1];
    const label = entry[0];
    if (editable) {
      if (detailOptions.valueOptions) {
        return (
          <View>
            <Dropdown
              data={getDropdownList(detailOptions.valueOptions as ValueOptions[])}
              label={value || detailOptions.label}
              onSelect={(newValue) => {
                detailOptions.onChange && detailOptions.onChange(newValue as string, label);
              }}
              key={detailOptions.label}
              activeIconName="check"
              dropDownContainerHeight={isMobileDevice ? 200 : 395}
              dropDownIconColor="#000"
              hoverStyle={styles.hoverStyle}
              dropDownItemHoverStyle={styles.dropDownItemHoverStyle}
              inputStyle={styles.dropdownInput}
              inputTextStyle={styles.dropDownTextStyle}
              inputClickStyle={styles.inputClickStyle}
              dropDownItemTextStyle={styles.dropDownTextStyle}
              dropDownItemDisabledTextStyle={{ color: 'grey' }}
              disabled={disabled}
            />
          </View>
        );
      }
      return renderText('No options defined');
    }
    return renderText(value);
  };

  const renderDetail = (entry: DetailEntry) => {
    const key = entry[0] as keyof T;
    const value = entry[1] as acctOptionsObj;
    const detailValue = details[key];
    switch (value.type) {
      case 'image':
        return renderImageDetail((detailValue as unknown) as string, [key as string, value]);
      case 'date':
        return renderDatePicker((detailValue as unknown) as string, [key as string, value]);
      case 'select':
        return renderSelect((detailValue as unknown) as string, [key as string, value]);
      case 'radio':
        return renderRadio((detailValue as unknown) as string, [key as string, value]);
      default:
        return renderTextDetail((detailValue as unknown) as string, [key as string, value]);
    }
  };

  const renderDetails = () => {
    return Object.entries(detailsOptions).map((entry) => {
      const detailOption = entry[1] as acctOptionsObj;
      const detailValue = entry[0] as string;
      const detailsKey = detailValue as keyof T;
      const value = (details[detailsKey] as unknown) as string;
      if (detailOption.isHidden) {
        return null;
      }
      return (
        <View style={[styles.detail, detailStyle]} key={detailOption.label}>
          <TextContent style={[styles.detailLabel, detailLabelStyle]}>{detailOption.label}</TextContent>
          {detailOption.readOnly
            ? renderText(value, false, {
                ...styles.readOnlyValue,
                ...detailOption.overrideStyle,
              })
            : detailOption.overrideRenderDetail
            ? // component for editing date(on mobile) and image(all platforms)
              // type should be passed via overrideRender because there is no
              // cross platform implementation for them in react-native-web.
              detailOption.overrideRenderDetail([detailValue, detailOption], detailValue, detailOption.onChange)
            : renderDetail(entry as DetailEntry)}
          <Tooltip
            position="left"
            tooltipText={detailOption.errorMessage || 'This field is required'}
            visible={!!detailOption.hasError}
            opacity={1}
          />
        </View>
      );
    });
  };

  return (
    <View style={wrapperStyle}>
      <View>
        {title ? <Text style={[styles.title, titleStyle]}>{title}</Text> : null}
        {subTitle ? <Text style={[styles.subTitle, subTitleStyle]}>{subTitle}</Text> : null}
      </View>
      <View style={[styles.detailsWrapper, detailsWrapperStyle]}>
        <View style={[styles.details, detailsStyle]}>{renderDetails()}</View>
        <View style={styles.accountFooter}>
          {actionBtnText && (
            <CustomButton
              mode="contained"
              btnStyle={actionBtnStyle}
              labelStyle={actionBtnTextStyle}
              onPress={handleActionBtnPress}
              label={actionBtnText}
              disabled={disabled}
              focusKey={FocusKeys.PROFILE}
            />
          )}
        </View>
      </View>
    </View>
  );
}

export default GenericAccountDetails;
