import React, { useEffect } from 'react';
import { Image, Text, View } from 'react-native';
import { useReactiveVar } from '@apollo/client';
import { getStyles } from './subscription.styles';
import { breakpointsStateStore, Product } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { useTheme } from 'react-native-paper';

interface ProductProps {
  product?: Product;
  handleCTAPress?: (product: Product) => void;
  couponDeduction?: string;
  marketingImage?: string;
}

export const Subscription = ({ product, handleCTAPress, couponDeduction, marketingImage }: ProductProps) => {
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const { colors } = useTheme();
  const styles = getStyles(breakpoints, colors);

  const marketingImageSize = breakpoints.currentBreakpoints.isMedLg
    ? 335
    : breakpoints.currentBreakpoints.isLgXl ||
      breakpoints.currentBreakpoints.isXlXxl ||
      breakpoints.currentBreakpoints.isXxl
    ? 590
    : 325;

  useEffect(() => {
    handleCTAPress && handleCTAPress(product as Product);
  }, [handleCTAPress, product]);

  if (!product) {
    return null;
  }

  return (
    <View style={[styles.left, marketingImage ? [styles.imageMargin] : {}]}>
      {marketingImage ? (
        <Image
          source={{
            uri: `${marketingImage}?h=${marketingImageSize}&w=${marketingImageSize}`,
          }}
          resizeMode="contain"
          style={styles.marketingImageStyle}
        />
      ) : (
        <View>
          <View style={styles.headerViewStyle}>
            <Text style={styles.header1TextStyle}>{product?.headline}</Text>
            <Text style={styles.header2TextStyle}>{product?.subHeadline}</Text>
          </View>
          {product?.cta ? (
            <>
              <View style={[styles.subscribeViewStyle, styles.subscribeShadowStyle]}>
                <View>
                  <Text style={styles.subscribeLargeTextStyle}>{product?.cta?.replace(/\s/, '\n')}</Text>
                </View>
                <Text style={styles.subscribeSmallTextStyle}>{couponDeduction || product?.price}</Text>
              </View>
              <View style={styles.availableViewStyle}>
                <Text style={styles.availableTextStyle}>{product?.disclaimer}</Text>
              </View>
            </>
          ) : null}
        </View>
      )}
    </View>
  );
};

export default Subscription;
