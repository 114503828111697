import AsyncStorage from '@react-native-async-storage/async-storage';

/**
 * LocalStorage Helper Functions
 */

/**
 * getStringData
 * Get string data from local storage
 *
 * @category Helpers
 */
export const getStringData = async (key: string): Promise<string | void> => {
  try {
    const value = await AsyncStorage.getItem(key);
    if (value !== null) {
      return value;
    }
  } catch (e) {
    console.log(e);
  }
};

/**
 * getObjectData
 * Get object data from local storage
 *
 * @category Helpers
 */
export const getObjectData = async (key: string) => {
  try {
    const jsonValue = await AsyncStorage.getItem(key);
    return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch (e) {
    console.log(e);
  }
};

/**
 * storeObjectData
 * Store object data into local storage
 *
 * @category Helpers
 * @param key - key to store value at
 * @param value - value to be stored
 */
export const storeObjectData = async (
  key: string,
  value: Record<string, unknown>
) => {
  try {
    const jsonValue = JSON.stringify(value);
    await AsyncStorage.setItem(key, jsonValue);
  } catch (e) {
    console.log(e);
  }
};

/**
 * storeStringData
 * Store string data into local storage
 *
 * @category Helpers
 * @param key - key to store value at
 * @param value - value to be stored
 */
export const storeStringData = async (key: string, value: string) => {
  try {
    await AsyncStorage.setItem(key, value);
  } catch (e) {
    console.log(e);
  }
};

/**
 * isUserLoggedIn
 * Check if user token exists in local storage
 * If so, user is logged in
 *
 * @category Helpers
 */
export const isUserLoggedIn = async () => {
  const userStore = await getObjectData('WM_SPORTS_USER');

  if (userStore.userToken) {
    return true;
  }

  return false;
};
