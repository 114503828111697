import React, { memo, useRef } from 'react';
import { Image, ImageBackground, Pressable, Text, View } from 'react-native';
import { CardProps, useSpatialNavigation } from '@warnermmedia/gsp-core/sdk/ui';
import { getStyles } from './genericCard.styles';
import {
  CardDefaultBackground,
  carouselBase,
  getImageWithStyle,
  getUniqueId,
  useCardItemImage,
} from '@warnermmedia/gsp-core/brands/estadio/feature';
import { ItemName } from '@warnermmedia/gsp-core/sdk/data-access';

const GenericCard = ({
  item,
  index,
  currentIndex,
  action,
  type,
  cardBg,
  breakpoints,
  colors,
  device,
  focusKey,
  dataLength,
  parentFocusKey,
}: CardProps<carouselBase>) => {
  const styles = getStyles(breakpoints, device.isTv, colors);
  const { getCardImageSource } = useCardItemImage();
  const itemImage = item?.image ? getImageWithStyle(item.image ?? '') : null;
  const image = getCardImageSource({ fallbackName: CardDefaultBackground.Tournament, image: itemImage });
  const background = getCardImageSource({
    fallbackName: cardBg ? cardBg : CardDefaultBackground.TournamentBg,
    image: type === 'tournaments' ? getImageWithStyle(item.background) : itemImage,
  });
  const cardFocusKey = useRef(focusKey ?? getUniqueId(`${ItemName.GENERIC_CARD}_${index}`)).current;
  const { ref, onGridCardArrowPress } = useSpatialNavigation({
    focusKey: cardFocusKey,
    onEnterPress: () => {
      action?.(item);
    },
    onArrowPress: (direction, props, details): boolean => {
      return onGridCardArrowPress(direction, props, details, index, parentFocusKey, dataLength);
    },
  });

  return (
    <Pressable
      onPress={() => (action ? action(item) : null)}
      style={styles.cardLayout}
      ref={ref}
      nativeID={cardFocusKey}
    >
      <View style={styles.cardContainer}>
        <ImageBackground source={background} style={styles.genericTop} imageStyle={styles.slideBgImageStyle}>
          {type === 'tournaments' && image ? (
            <Image style={styles.genericImage} source={image} resizeMethod="auto" resizeMode="contain" />
          ) : null}
        </ImageBackground>
        <View style={[styles.slideBottom, index === currentIndex && styles.slideBottomActive]}>
          <Text style={styles.slideBottomTitle}>{item.title}</Text>
        </View>
      </View>
    </Pressable>
  );
};

export default memo(GenericCard);
