import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import Svg, { SvgProps, Path } from 'react-native-svg';

interface Props extends SvgProps {
  width?: number;
  height?: number;
  color?: string;
  style?: StyleProp<ViewStyle>;
}

export const ChevronLeft = ({ width = 16, height = 24, color = '#fff', style, ...props }: Props) => (
  <Svg width={width} height={height} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.8 11.8c0 .24 0 .24 0 0v.48c0 .48.24.72.479 1.2l10.296 10.085c.718.72 1.916.48 2.873-.24.958-.72.958-2.161.24-2.881l-8.38-8.405 8.38-8.404c.718-.72.479-1.92-.24-2.881-.957-.96-2.155-.96-2.873-.24l-9.817 9.844-.24.24C1.04 11.079.8 11.56.8 11.8Z"
      fill={color}
    />
  </Svg>
);

export default ChevronLeft;
