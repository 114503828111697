import { StyleProp, StyleSheet, TextStyle, ViewStyle } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';
import merge from 'lodash/merge';

interface Styles {
  container: ViewStyle;
  airDateTime: TextStyle;
  title: TextStyle;
}
export const getStyles = (breakpoints: Breakpoints, isTv: boolean, colors: ReactNativePaper.ThemeColors) => {
  const base: StyleProp<Styles> = {
    container: {
      width: 270,
      borderStyle: 'solid',
      borderColor: colors.stroke.onDark.outline02,
      borderRightWidth: 1,
      paddingLeft: 10,
      paddingRight: 10,
    },
    airDateTime: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Rubik-SemiBold',
      fontSize: 20,
      fontWeight: '600',
      lineHeight: 24,
      textAlign: 'center',
      textTransform: 'uppercase',
    },
    title: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Rubik-Regular',
      fontSize: 16,
      fontWeight: 'normal',
      textAlign: 'center',
      lineHeight: 20,
    },
  };

  const mediaQueries =
    isTv || breakpoints.currentBreakpoints.isXxl
      ? ({
          container: {
            width: 239,
          },
        } as StyleProp<Styles>)
      : breakpoints.currentBreakpoints.isMedLg ||
        breakpoints.currentBreakpoints.isLgXl ||
        breakpoints.windowWidth === breakpoints.breakpointSizes.lg
      ? ({
          container: {
            width: 218,
          },
        } as StyleProp<Styles>)
      : {};
  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
