import React, { FC, useCallback } from 'react';
import { Image, Text, View } from 'react-native';
import { getStyles } from './landingOndemand.style';
import { ImageAssets } from '@warnermmedia/gsp-core/brands/estadio/assets';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';
import LinearGradient from 'react-native-linear-gradient';

interface LandingOndemandProps {
  liveHeadLine?: string | undefined;
  liveDescription?: string | null | undefined;
  ondemandHeadLine?: string | undefined;
  ondemandDescription?: string | null | undefined;
  image?: string;
  breakpoints: Breakpoints;
  colors: ReactNativePaper.ThemeColors;
  width: number;
}

export const LandingOndemand: FC<LandingOndemandProps> = ({
  liveHeadLine,
  liveDescription,
  ondemandHeadLine,
  ondemandDescription,
  image,
  breakpoints,
  width,
  colors,
}) => {
  const styles = getStyles(breakpoints, colors, width);
  const currentWindowWidth = breakpoints.windowWidth;
  const isMobileSize = currentWindowWidth <= breakpoints.breakpointSizes.med;

  const getImage = useCallback(() => {
    return (
      <View style={styles.landingOndemandImageWrapper}>
        <Image source={{ uri: image }} style={styles.landingOndemandImage} resizeMode="contain" />
      </View>
    );
  }, [styles.landingOndemandImageWrapper, styles.landingOndemandImage, image]);

  const getLiveEventContent = useCallback(() => {
    return (
      <View style={styles.liveEvent}>
        <View style={styles.topHeader}>
          <Text style={styles.liveEventHeadline}>{liveHeadLine}</Text>
          <View style={styles.live}>
            <Image source={ImageAssets.live} style={styles.liveIcon} />
          </View>
        </View>
        <Text style={styles.ondemandDescription}>{liveDescription}</Text>
      </View>
    );
  }, [styles.liveEvent, styles.topHeader, styles.liveEventHeadline, styles.live, liveDescription, liveHeadLine]);

  const getOndemandContent = useCallback(() => {
    return (
      <View style={styles.ondemandTextContent}>
        <Text style={styles.ondemandHeadline}>{ondemandHeadLine}</Text>
        <Text style={styles.ondemandDescription}>{ondemandDescription}</Text>
      </View>
    );
  }, [styles.ondemandTextContent, styles.ondemandDescription, ondemandHeadLine, ondemandDescription]);

  return (
    <View>
      <LinearGradient
        colors={[
          colors.tenantBackground.dark.surface02OndemandGrad.start,
          colors.tenantBackground.dark.surface02OndemandGrad.end,
        ]}
      >
        <View style={styles.landingOndemandWrapper}>
          <View style={styles.landingContents}>
            {isMobileSize ? (
              <>
                {getLiveEventContent()}
                {getImage()}
                {getOndemandContent()}
              </>
            ) : (
              <>
                {getImage()}
                <View style={styles.webContentLayout}>
                  {getLiveEventContent()}
                  {getOndemandContent()}
                </View>
              </>
            )}
          </View>
        </View>
      </LinearGradient>
    </View>
  );
};

export default LandingOndemand;
