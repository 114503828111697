import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { NavbarWideProps } from './navbarWide';

export function NavbarWideNoGradient(props: NavbarWideProps) {
  const width = props.width || 301;
  const height = props.height || 68;

  return (
    <Svg id="Layer_1" x="0px" y="0px" viewBox="0 0 1002 226" width={width} height={height} fill="none">
      <Path
        fill="#FFFFFF"
        d="M176.6,189c-39.9,2.1-79.6,1-119-5.8c-15.5-2.5-30.6-7.1-44.8-13.8c-5.6-2.7-11.3-5.8-12.8-13
	c0,11.4,0.1,22.7,0,34c-0.2,1.9,0.2,3.8,1,5.5s2.1,3.1,3.7,4.2c4.9,3.2,10,5.9,15.4,8.1c28.6,11,58.7,14.3,89,16.1
	c46.7,2.6,93.5-0.3,139.5-8.9c1.6-0.3,11-2.2,16.6-3.4l-20.4-31.4C222.1,184.2,199.5,187.8,176.6,189z"
      />
      <Path
        fill="#FFFFFF"
        d="M197.4,133.2c-46.6,4.2-93,3.8-139.3-4.1c-15.6-2.4-30.8-7-45.2-13.6c-5.7-2.7-11.3-6-12.9-13.1
	c0,11.4,0.1,22.7,0,34c-0.1,1.9,0.2,3.8,1.1,5.5c0.8,1.7,2.1,3.1,3.7,4.1c4.3,2.9,8.8,5.3,13.5,7.4c26.3,10.5,54,14.1,82,16.2
	c33.3,2.5,66.7,2.1,99.9-1.4c7.2-0.8,24-3,33.7-4.3l-21.3-32.6C207.6,132,202.5,132.7,197.4,133.2z"
      />
      <Path
        fill="#FFFFFF"
        d="M389.7,174.5c-0.3-6.5,0-13.1,0-19.7v-12.9c-0.9,5.5-4.7,8.6-9.1,11.1c-11,6.4-23.2,9.2-35.4,11.8
	c-3.3,0.7-6.6,1.3-9.9,1.9V202c7.8-1.6,15.7-3.1,23.5-5c8.7-1.9,17-5.3,24.4-10.2c2.1-1.3,3.8-3.1,5-5.3
	C389.3,179.4,389.9,176.9,389.7,174.5z"
      />
      <Path
        fill="#FFFFFF"
        d="M365.3,140.7c6.5-2.3,12.7-5.2,18.6-8.7c4.2-2.3,5.9-6.3,5.8-11.2c-0.2-10.1,0-20.1,0-30.2
	c0-0.9-0.1-1.7-0.2-3.2c-1.7,5.9-3.7,8.7-9,11.5c-3.8,2.3-7.9,4.1-12.1,5.5c-10.9,3-21.9,5.6-33,8.1v35.9
	C345.8,145.8,355.7,143.7,365.3,140.7z"
      />
      <Path
        fill="#FFFFFF"
        d="M389.2,40.5c0-4.9,0.5-8.5-1.2-11.5c-2.7,8-10.5,16.3-25.5,22.4c-9.8,2-19.6,4.3-29.4,6
	c-14.1,2.4-28.2,4.5-42.3,6.5c-2.9,0.4-3.8,1.4-3.8,4.4c0.2,23.8,0.3,47.5,0.3,71.3c0.1,1.3-0.1,2.7-0.6,3.9
	c-2.8-4.2-5.6-8.4-8.4-12.6c-10.9-16.6-21.9-33.1-32.7-49.9c-4.4-6.9-10.3-9.2-18.3-7.8c-35.6,5.8-71.7,7.9-107.8,6.2
	c-24.7-1-49.3-3.2-73.4-8.7c-4.7-1.1-9.3-2.4-14-3.8C27,65.5,22,63.3,17.3,60.4C10.1,55.8,2.3,48.6,5,39.5
	c-3.6,2.9-4.8,6.3-4.8,11.4C0.1,62.2,0,69.5,0,78.8c0,9.8,2.7,11,8.2,14.2c10.6,6.2,22.4,9.5,34.1,12.5c23.6,5.9,47.7,8.2,71.9,9.3
	c35.6,1.6,71.1,0.6,106.4-4.9c0.9-0.1,1.8,0,2.6,0.4s1.5,1,2,1.7c4.1,6.4,8.4,12.6,12.6,19c15.5,23.5,31,47.1,46.4,70.7
	c2.9,4.4,6.7,7.5,12.1,7.1c7-0.6,14.1-1.7,21.3-2.8V95.6c12.1-2.3,24-4.4,35.7-7c10.3-2.3,20.4-5.2,29.3-11.2
	c5.4-3.5,6.6-8.2,6.6-15.9C389.4,54.4,389.2,50.4,389.2,40.5z"
      />
      <Path
        fill="#FFFFFF"
        d="M389.2,40.5c0-4.9,0.5-8.5-1.2-11.5c-2.7,8-10.5,16.3-25.5,22.4c-9.8,2-19.6,4.3-29.4,6
	c-14.1,2.4-28.2,4.5-42.3,6.5c-2.9,0.4-3.8,1.4-3.8,4.4c0.2,23.8,0.3,47.5,0.3,71.3c0.1,1.3-0.1,2.7-0.6,3.9
	c-2.8-4.2-5.6-8.4-8.4-12.6c-10.9-16.6-21.9-33.1-32.7-49.9c-4.4-6.9-10.3-9.2-18.3-7.8c-35.6,5.8-71.7,7.9-107.8,6.2
	c-24.7-1-49.3-3.2-73.4-8.7c-4.7-1.1-9.3-2.4-14-3.8C27,65.5,22,63.3,17.3,60.4C10.1,55.8,2.3,48.6,5,39.5
	c-3.6,2.9-4.8,6.3-4.8,11.4C0.1,62.2,0,69.5,0,78.8c0,9.8,2.7,11,8.2,14.2c10.6,6.2,22.4,9.5,34.1,12.5c23.6,5.9,47.7,8.2,71.9,9.3
	c35.6,1.6,71.1,0.6,106.4-4.9c0.9-0.1,1.8,0,2.6,0.4s1.5,1,2,1.7c4.1,6.4,8.4,12.6,12.6,19c15.5,23.5,31,47.1,46.4,70.7
	c2.9,4.4,6.7,7.5,12.1,7.1c7-0.6,14.1-1.7,21.3-2.8V95.6c12.1-2.3,24-4.4,35.7-7c10.3-2.3,20.4-5.2,29.3-11.2
	c5.4-3.5,6.6-8.2,6.6-15.9C389.4,54.4,389.2,50.4,389.2,40.5z"
      />
      <Path
        fill="#FFFFFF"
        d="M18.2,32.3c-4.8,1.6-9.3,4.1-13.3,7.2c-4.7,16,21.8,25.6,21.8,25.6c0.3-0.2,0.7-0.4,1.1-0.5
	c6.7-1.5,13.3-3.5,20-4.5c28.5-4.7,57-9.1,85.6-13.4c18.3-2.8,36.6-5.7,55-7.9c23-2.7,46.2-3.7,69.4-3.1c29.5,0.8,58.9,2.8,87.6,9.6
	c5.8,1.4,11.9,2.9,17.1,6c14.7-5.9,22.8-14.4,25.5-22.4c-1.3-2.2-3.2-4.1-5.4-5.4c-2-1.3-4-2.5-6.1-3.5c-13.3-5.8-27.2-10-41.5-12.6
	c-24.4-4.4-49.2-6.8-74-7.2c-31.3-0.9-62.5,0.9-93.5,5.5L34,28.1C28.6,29,23.3,30.4,18.2,32.3L18.2,32.3z"
      />
      <Path
        fill="#FFFFFF"
        d="M18.2,32.3c-4.8,1.6-9.3,4.1-13.3,7.2c-4.7,16,21.8,25.6,21.8,25.6c0.3-0.2,0.7-0.4,1.1-0.5
	c6.7-1.5,13.3-3.5,20-4.5c28.5-4.7,57-9.1,85.6-13.4c18.3-2.8,36.6-5.7,55-7.9c23-2.7,46.2-3.7,69.4-3.1c29.5,0.8,58.9,2.8,87.6,9.6
	c5.8,1.4,11.9,2.9,17.1,6c14.7-5.9,22.8-14.4,25.5-22.4c-1.3-2.2-3.2-4.1-5.4-5.4c-2-1.3-4-2.5-6.1-3.5c-13.3-5.8-27.2-10-41.5-12.6
	c-24.4-4.4-49.2-6.8-74-7.2c-31.3-0.9-62.5,0.9-93.5,5.5L34,28.1C28.6,29,23.3,30.4,18.2,32.3L18.2,32.3z"
      />
      <Path
        fill="#FFFFFF"
        d="M832.1,176.3l-3.4-1c-2.3-0.7-5.9-1.9-5.9-4.8c0-2.9,3.2-3.7,5.7-3.7h7.2l6.2-11.4h-93.1
	c-2,0-3.9,0.8-5.3,2.2c-1.4,1.4-2.2,3.3-2.2,5.2v10.5c-3.4-11.9-15.2-19.7-27.8-19.7c-5.4-0.1-10.7,1.3-15.3,4.1
	c-4.6,2.7-8.4,6.7-10.9,11.4c-1.3-9.6-8.6-13.8-18.6-13.8H626c-10.2,0-17.5,6.6-17.5,16.9c0,9.8,5.5,12,13.7,14.3
	c2.8,0.8,8,2.1,8,5.8s-3.3,5.1-6.4,5.1c-4.4-0.3-8.6-2.1-11.7-5.2l-5.6,10.6c5.2,3.7,11.4,5.7,17.7,5.8c5.2,0.1,10.3-1.5,14.4-4.8
	c4-3.5,5.2-8.6,5.2-13.6c0-8.2-5.5-11.7-12.5-13.8l-3.4-1c-2.3-0.7-5.8-1.9-5.8-4.8c0-2.9,3.2-4.3,5.6-4.3H648v41h13.5V190h7.6
	c7.4,0,12.7-2.1,15.7-6.7c1.3,15.2,12.9,25.6,28.6,25.6c14,0,24.6-8.2,27.8-20.7v19h13.5v-19.9h0.1l15,19.9h14.6L768.3,186
	c7.4-1.3,11.4-7.5,11.4-14.7c0-1.5-0.2-3-0.5-4.5h9.1v40.4h13.5v-40.4h8.2c-0.5,1.8-0.8,3.6-0.8,5.4c0,9.8,5.4,12,13.7,14.3
	c2.8,0.8,8,2.1,8,5.8s-3.3,5.1-6.4,5.1c-4.4-0.3-8.6-2.1-11.7-5.2l-5.7,10.6c5.2,3.7,11.5,5.7,17.9,5.8c5.2,0.1,10.3-1.5,14.4-4.8
	c4.1-3.5,5.2-8.6,5.2-13.7C844.7,181.9,839.2,178.4,832.1,176.3z M663.9,179.2h-2.3v-12.9h2.3c5.2,0,9.7,0,9.7,6.6
	C673.6,179.5,668.9,179.2,663.9,179.2L663.9,179.2z M713.6,195.9c-2,0-4-0.5-5.8-1.3s-3.5-2-4.8-3.4c-1.4-1.4-2.4-3.1-3.1-5
	c-0.7-1.9-1-3.8-0.9-5.8c0-7.1,6.6-13.7,14.7-13.7c8,0,14.7,6.5,14.7,13.7c0.1,2-0.2,4-0.9,5.8c-0.7,1.9-1.8,3.5-3.1,5
	c-1.4,1.4-3,2.6-4.8,3.4S715.6,195.8,713.6,195.9L713.6,195.9z M756.2,178.6h-1.3v-12.9h1.3c4.4,0,9.4,0.8,9.4,6.5
	C765.5,177.8,760.6,178.6,756.2,178.6L756.2,178.6z"
      />
      <Path
        fill="#FFFFFF"
        d="M832.1,176.3l-3.4-1c-2.3-0.7-5.9-1.9-5.9-4.8c0-2.9,3.2-3.7,5.7-3.7h7.2l6.2-11.4h-93.1
	c-2,0-3.9,0.8-5.3,2.2c-1.4,1.4-2.2,3.3-2.2,5.2v10.5c-3.4-11.9-15.2-19.7-27.8-19.7c-5.4-0.1-10.7,1.3-15.3,4.1
	c-4.6,2.7-8.4,6.7-10.9,11.4c-1.3-9.6-8.6-13.8-18.6-13.8H626c-10.2,0-17.5,6.6-17.5,16.9c0,9.8,5.5,12,13.7,14.3
	c2.8,0.8,8,2.1,8,5.8s-3.3,5.1-6.4,5.1c-4.4-0.3-8.6-2.1-11.7-5.2l-5.6,10.6c5.2,3.7,11.4,5.7,17.7,5.8c5.2,0.1,10.3-1.5,14.4-4.8
	c4-3.5,5.2-8.6,5.2-13.6c0-8.2-5.5-11.7-12.5-13.8l-3.4-1c-2.3-0.7-5.8-1.9-5.8-4.8c0-2.9,3.2-4.3,5.6-4.3H648v41h13.5V190h7.6
	c7.4,0,12.7-2.1,15.7-6.7c1.3,15.2,12.9,25.6,28.6,25.6c14,0,24.6-8.2,27.8-20.7v19h13.5v-19.9h0.1l15,19.9h14.6L768.3,186
	c7.4-1.3,11.4-7.5,11.4-14.7c0-1.5-0.2-3-0.5-4.5h9.1v40.4h13.5v-40.4h8.2c-0.5,1.8-0.8,3.6-0.8,5.4c0,9.8,5.4,12,13.7,14.3
	c2.8,0.8,8,2.1,8,5.8s-3.3,5.1-6.4,5.1c-4.4-0.3-8.6-2.1-11.7-5.2l-5.7,10.6c5.2,3.7,11.5,5.7,17.9,5.8c5.2,0.1,10.3-1.5,14.4-4.8
	c4.1-3.5,5.2-8.6,5.2-13.7C844.7,181.9,839.2,178.4,832.1,176.3z M663.9,179.2h-2.3v-12.9h2.3c5.2,0,9.7,0,9.7,6.6
	C673.6,179.5,668.9,179.2,663.9,179.2L663.9,179.2z M713.6,195.9c-2,0-4-0.5-5.8-1.3s-3.5-2-4.8-3.4c-1.4-1.4-2.4-3.1-3.1-5
	c-0.7-1.9-1-3.8-0.9-5.8c0-7.1,6.6-13.7,14.7-13.7c8,0,14.7,6.5,14.7,13.7c0.1,2-0.2,4-0.9,5.8c-0.7,1.9-1.8,3.5-3.1,5
	c-1.4,1.4-3,2.6-4.8,3.4S715.6,195.8,713.6,195.9L713.6,195.9z M756.2,178.6h-1.3v-12.9h1.3c4.4,0,9.4,0.8,9.4,6.5
	C765.5,177.8,760.6,178.6,756.2,178.6L756.2,178.6z"
      />
      <Path
        fill="#FFFFFF"
        d="M544.5,155.1v26.6H544L529.4,159c-0.6-1.3-1.7-2.4-2.9-3.1c-1.3-0.7-2.7-1-4.2-0.8h-61.6v10.5h16.9v42.5
	h14.6v-42.5h16.4v42.5h14v-31.8h0.3l18.5,27.9c0.6,1.3,1.7,2.4,2.9,3.1c1.3,0.7,2.7,1,4.2,0.8h9.6v-42.5h16.4v42.5h14.4v-42.5h12.7
	l5.4-10.5L544.5,155.1z"
      />
      <Path
        fill="#FFFFFF"
        d="M544.5,155.1v26.6H544L529.4,159c-0.6-1.3-1.7-2.4-2.9-3.1c-1.3-0.7-2.7-1-4.2-0.8h-61.6v10.5h16.9v42.5
	h14.6v-42.5h16.4v42.5h14v-31.8h0.3l18.5,27.9c0.6,1.3,1.7,2.4,2.9,3.1c1.3,0.7,2.7,1,4.2,0.8h9.6v-42.5h16.4v42.5h14.4v-42.5h12.7
	l5.4-10.5L544.5,155.1z"
      />
      <Path
        fill="#FFFFFF"
        d="M699.9,134.8l6.7-17h34.9l6.3,17h58.3c6.6,0.4,13.3-0.6,19.6-2.8c6.3-2.3,12-5.8,16.8-10.4
	s8.7-10.1,11.3-16.2c2.6-6.1,4-12.7,4-19.4s-1.4-13.2-4-19.4c-2.6-6.1-6.5-11.6-11.3-16.2s-10.6-8.1-16.8-10.4
	c-6.3-2.3-12.9-3.2-19.6-2.8h-35.9v85.2l-32.1-85.2h-27.7L673,134.8H699.9z M795.6,58.8h5.9c16.3,0,27.8,9.8,27.8,27.3
	c0,19-12.9,27.3-28,27.3h-5.7V58.8z M724.1,67.5h0.3l10.3,31h-21.2L724.1,67.5z"
      />
      <Path fill="#FFFFFF" d="M886.1,37.3h-25.3v97.5h25.3V37.3z" />
      <Path
        fill="#FFFFFF"
        d="M947.2,34.1c-28.1,0-53.9,20.7-53.9,50.1c0,31.6,22.7,53.8,53.9,53.8c31.3,0,53.9-22.2,53.9-53.8
	C1001.1,54.8,975.2,34.1,947.2,34.1z M947.2,113.5c-15.1,0-27.5-12.2-27.5-29.1c0-13.4,12.4-25.7,27.5-25.7s27.5,12.2,27.5,25.7
	C974.7,101.2,962.3,113.5,947.2,113.5L947.2,113.5z"
      />
      <Path
        fill="#FFFFFF"
        d="M542.5,113.4h-56.4V96.5h46.1V75.1h-46.1V58.8h62.8c-0.9,3.2-1.4,6.6-1.4,9.9c0,18.3,10.1,22.3,25.5,26.6
	c5.2,1.5,14.9,3.9,14.9,10.7c0,6.9-6.1,9.5-11.9,9.5c-8.4,0-15.7-4.4-21.8-9.8l-10.5,19.8c9.7,6.9,21.3,10.6,33.1,10.7
	c9.5,0,19.4-2.6,26.8-8.9c7.4-6.3,9.8-16,9.8-25.4c0-15.3-10.1-21.9-23.3-25.8l-6.3-1.9c-4.3-1.4-10.9-3.5-10.9-9s6-8,10.5-8h49.9
	v77.7h26.5V57.5h28.9l8.1-20.1H580.1c-0.5,0-1,0-1.6,0H460.8v97.5H531L542.5,113.4z"
      />
    </Svg>
  );
}

export default NavbarWideNoGradient;
