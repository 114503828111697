import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  wrapper: {
    width: '100%',
    backgroundColor: 'transparent',
  },
  title: {
    color: 'rgb(255, 255, 255)',
    fontSize: 22,
    fontFamily: 'Oswald-SemiBold',
    fontWeight: '600',
    textTransform: 'uppercase',
    marginBottom: 10,
  },
  resendButton: {
    marginTop: 30,
  },
  subTitle: {
    color: 'rgb(255, 255, 255)',
    fontFamily: 'Rubik-Regular',
    fontSize: 16,
    fontWeight: 'normal',
    height: 40,
    lineHeight: 20,
    maxWidth: 451,
    marginTop: 30,
  },
  message: {
    marginTop: 0,
  },
  errorMessage: {
    marginTop: 0,
    marginBottom: 10,
  },
  messageText: {
    marginTop: 8,
  },
  textLabel: {
    fontSize: 16,
    fontWeight: '500',
    color: '#fff',
    marginBottom: 10,
  },
  inputContainer: {
    marginBottom: 15,
  },
  input: {
    backgroundColor: '#FFFFFF',
    borderColor: '#FFFFFF',
    height: 40,
    borderRadius: 5,
    width: '100%',
  },
  displayIcon: {
    margin: 0,
    padding: 0,
    alignSelf: 'baseline',
  },
  checkBtn: {
    alignSelf: 'flex-start',
    paddingHorizontal: 0,
  },
  underlinedText: {
    color: 'rgb(255, 255, 255)',
    fontFamily: 'Oswald-Regular',
    fontSize: 14,
    fontWeight: 'normal',
    lineHeight: 21,
    textDecorationLine: 'underline',
    textTransform: 'uppercase',
  },
  footerSectionContainer: {
    flex: 1,
    marginTop: 20,
  },
  footerSectionMessage: {
    color: 'rgb(255, 255, 255)',
    fontFamily: 'Rubik-Regular',
    fontSize: 16,
    fontWeight: 'normal',
    height: 20,
    lineHeight: 20,
    maxWidth: 451,
  },
});
