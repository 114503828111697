import { useContext, useEffect } from 'react';
import { AppConfigContext } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { useGetDevice } from '@warnermmedia/gsp-core/sdk/ui';

export function useOneTrustTag(): void {
  const appConfig = useContext(AppConfigContext);
  const { isWeb } = useGetDevice();

  useEffect(() => {
    if (isWeb) {
      const functionScriptElem = document.getElementById('oneTrustScript');
      if (!functionScriptElem) {
        const oneTrustId = appConfig?.appConfig.oneTrust || '10ea3d19-51da-4048-a94f-ea2ac0b17310-test';
        const oneTrustTag = document.createElement('script');
        oneTrustTag.setAttribute('id', 'oneTrustScript');
        oneTrustTag.setAttribute('src', 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js');
        oneTrustTag.setAttribute('data-document-language', 'true');
        oneTrustTag.setAttribute('charset', 'UTF-8');
        oneTrustTag.setAttribute('data-domain-script', oneTrustId);
        const functionElem = document.getElementById('oneTrustFunction');
        document.head.insertBefore(oneTrustTag, functionElem);
      }
    }
  }, [appConfig]);
}
