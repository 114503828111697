import { StyleProp, ViewStyle, StyleSheet } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';
import merge from 'lodash/merge';

interface Styles {
  heroFocusStyle: ViewStyle;
  pip: ViewStyle;
  heroCardMargin: ViewStyle;
  pipMobileStyle: ViewStyle;
  arrowsWrapper: ViewStyle;
}

export const getStyles = (breakpoints: Breakpoints, isTv: boolean) => {
  const base: StyleProp<Styles> = {
    heroFocusStyle: {
      marginBottom: 5,
      marginTop: 10,
      marginLeft: 2,
      marginRight: 2,
      borderRadius: 10,
    },
    pip: {
      flexDirection: 'row',
      justifyContent: 'center',
      marginTop: 5,
      marginBottom: 20,
    },
    heroCardMargin: {
      marginLeft: 20,
      marginRight: 20,
      alignItems: 'center',
    },
    pipMobileStyle: {
      flexDirection: 'row',
      justifyContent: 'center',
      marginVertical: 50,
    },
    arrowsWrapper: {
      justifyContent: 'center',
    },
  };

  const mediaQueries = isTv
    ? ({
        heroFocusStyle: {
          marginLeft: 0,
          marginRight: 0,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isTiny || breakpoints.currentBreakpoints.isTnySm
    ? ({
        heroCardMargin: {
          marginLeft: 20,
          marginRight: 20,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isSmMed
    ? ({
        heroCardMargin: {
          marginLeft: 33,
          marginRight: 20,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isMedLg || breakpoints.windowWidth === breakpoints.breakpointSizes.lg
    ? ({
        heroCardMargin: {
          marginLeft: 33,
          marginRight: 20,
        },
      } as StyleProp<Styles>)
    : {};
  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
