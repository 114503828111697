import { merge } from 'lodash';
import { ImageStyle, StyleSheet, StyleProp, TextStyle, ViewStyle } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';

interface Styles {
  wrapper: ViewStyle;
  flagImage: ImageStyle;
  title: TextStyle;
  searchSection: ViewStyle;
  searchLabel: TextStyle;
  searchBar: TextStyle;
  category: ViewStyle;
  loadingIndicator: ViewStyle;
  showAll: TextStyle;
  accountLoginBtn: ViewStyle;
  accountLoginLabel: TextStyle;
  countryItem: ViewStyle;
  countryItemLabel: TextStyle;
  overrideWrapper: ViewStyle;
  overrideTitle: TextStyle;
  errorLayout: ViewStyle;
  errorsContainer: ViewStyle;
  showAllContainer: ViewStyle;
}

export const getStyles = (breakpoints: Breakpoints, colors: ReactNativePaper.ThemeColors) => {
  const base: StyleProp<Styles> = {
    wrapper: {
      flex: 1,
      width: '100%',
      marginBottom: 30,
    },
    flagImage: {
      width: 32,
      height: 24,
      marginRight: 16,
      marginLeft: 4,
    },
    title: {
      fontSize: 16,
      marginVertical: 16,
      textTransform: 'capitalize',
      fontFamily: 'Rubik-Regular',
      color: colors.tenantBackground.light.surfaceBase,
      opacity: 0.8,
    },
    searchSection: {
      marginVertical: 8,
      marginBottom: 32,
    },
    searchLabel: {
      fontWeight: 'bold',
      fontSize: 16,
      marginBottom: 4,
    },
    searchBar: {
      fontSize: 18,
    },
    loadingIndicator: {
      marginBottom: 10,
    },
    category: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
    showAllContainer: {
      alignSelf: 'flex-start',
      marginVertical: 48,
    },
    showAll: {
      textTransform: 'uppercase',
      textDecorationLine: 'underline',
      fontFamily: 'Oswald-Bold',
      fontSize: 14,
    },
    accountLoginBtn: {
      paddingVertical: 16,
      borderColor: colors.tenantBackground.light.surfaceBase,
      borderWidth: 3,
      marginTop: 38,
    },
    accountLoginLabel: {
      fontSize: 18,
    },
    countryItem: {
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: '#3b3d52',
      borderRadius: 5,
      marginVertical: 4,
      paddingVertical: 16,
      paddingHorizontal: 8,
    },
    countryItemLabel: {
      color: colors.keyboardFocus.light,
      fontSize: 18,
    },
    overrideTitle: {},
    overrideWrapper: {
      maxWidth: 650,
    },
    errorLayout: {
      justifyContent: 'center',
      alignItems: 'center',
      flex: 1,
      marginBottom: 40,
      width: '100%',
    },
    errorsContainer: {
      width: '90%',
    },
  };
  const mediaQueries =
    breakpoints.currentBreakpoints.isMedLg ||
    breakpoints.currentBreakpoints.isLgXl ||
    breakpoints.currentBreakpoints.isXxl ||
    breakpoints.currentBreakpoints.isXlXxl
      ? ({
          overrideTitle: {
            fontSize: 22,
          },
        } as StyleProp<Styles>)
      : ({} as StyleProp<Styles>);

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
