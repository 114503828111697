import React, { useCallback, useContext, useState } from 'react';
import { Image, View } from 'react-native';
import { getStyles } from './retention.styles';
import { useReactiveVar } from '@apollo/client';
import { LayoutType, LayoutWrapper, TitleType } from '../../../layoutWrapper';
import {
  AppConfigContext,
  breakpointsStateStore,
  HistoryContext,
  Page,
} from '@warnermmedia/gsp-core/brands/estadio/data-access';
import {
  Dalton,
  languageStrings,
  PagesUrl,
  QueryNames,
  useCMSPageMetaTags,
  useCMSQueryDataResults,
  useGoBack,
  useMparticleCustomEventObject,
  useMparticleScreenEvent,
  useScrollToTop,
  useStatusMessage,
} from '@warnermmedia/gsp-core/brands/estadio/feature';
import { EstadioButton } from '../../../components';
import { UnsubscribeConfirm } from './unsubscribeConfirm';
import {
  FocusKeys,
  loadData,
  MParticleCustomEventTypes,
  mParticleEventProcessor,
} from '@warnermmedia/gsp-core/sdk/data-access';
import axios from 'axios';
import { CancellationReason, ScrollViewRefContext } from '@warnermmedia/gsp-core/brands/estadio/ui';
import { useTheme } from 'react-native-paper';
import SkeletonDisplay from '../../../skeletonDisplay/skeletonDisplay';
import { MetaTags, TextContent, useGetDevice, Variant } from '@warnermmedia/gsp-core/sdk/ui';
import LinearGradient from 'react-native-linear-gradient';
import { ImageAssets } from '@warnermmedia/gsp-core/brands/estadio/assets';
import get from 'lodash/get';

export const Retention = ({
  subscriptionId,
  cancelingReason,
  subscriptionEndDate,
  retentionCouponApplied,
  isPaypalUser,
}: {
  subscriptionId: string;
  cancelingReason: CancellationReason;
  subscriptionEndDate: string;
  retentionCouponApplied: string;
  isPaypalUser: boolean;
}) => {
  const dalton = Dalton();
  const [loading, setLoading] = useState(false);
  const [cancel, setCancel] = useState(false);
  const { setStatusMessage } = useStatusMessage();
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const historyContext = useContext(HistoryContext);
  const history = historyContext?.ready ? historyContext?.useHistory() : null;
  const { colors } = useTheme();
  const styles = getStyles(breakpoints, colors);
  const isMobileScreen = breakpoints.breakpointHelpers.isMobileScreen;

  const language = languageStrings.default;
  const { pageData: pageUriData, loading: loadingData } = useCMSQueryDataResults<Page>({
    queryName: QueryNames.GET_COMMON_PAGE_DATA,
    uri: PagesUrl.Retention,
  });
  const { type, cmsId } = useCMSPageMetaTags(pageUriData);
  const mParticleEventData = useMparticleCustomEventObject({
    section: type,
    contentTitle: language.retentionTitle,
    contentId: cmsId,
  });
  const retentionComponent = pageUriData?.components?.find((item) => item?.id === 'retention-screen');
  const retentionImage = get(retentionComponent, 'images[0].image.url', '');
  const retentionMobileImage = get(retentionComponent, 'images[1].image.url', '');
  const appConfig = useContext(AppConfigContext);
  const url = appConfig?.appConfig.apis.retentionAPI as string;
  const retentionEnabled = appConfig?.appConfig?.retentionEnabled ?? true;
  const { goBack } = useGoBack();
  const { scrollToTop } = useScrollToTop(ScrollViewRefContext);
  const { isPwa, isWeb } = useGetDevice();
  useMparticleScreenEvent(type, language.retentionTitle, cmsId);
  const showRetentionPage = appConfig?.appConfig?.showRetentionPage ?? true;

  const activatePromotion = useCallback(async () => {
    try {
      let token;
      if (isPwa) {
        token = JSON.parse(loadData('DALTON_AUTH_TOKEN')).token;
      } else {
        token = loadData('DALTON_AUTH_TOKEN').token;
      }
      setLoading(true);
      await axios.get(`${url}${subscriptionId}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      });
      const preferences = await dalton.getPreferences();
      if (preferences.success) {
        setLoading(false);
        setStatusMessage({ message: language.activatePromotionSuccess, type: Variant.Success });
        mParticleEventProcessor.pushMParticleEvent(
          MParticleCustomEventTypes.RetentionOfferAcceptEvent,
          mParticleEventData
        );
        history?.push({ pathname: '/profile', state: { cleanStatusMessage: false } });
      }
    } catch (e) {
      setStatusMessage({ message: language.activatePromotionErr, type: Variant.Error });
      setLoading(false);
      scrollToTop();
    }
  }, [dalton, history, isPwa, language, mParticleEventData, scrollToTop, setStatusMessage, subscriptionId, url]);

  const goToProfile = useCallback(() => {
    mParticleEventProcessor.pushMParticleEvent(
      [MParticleCustomEventTypes.RetentionOfferDismissEvent, MParticleCustomEventTypes.CancellationDeclineEvent],
      mParticleEventData
    );
    goBack();
  }, [mParticleEventData, goBack]);

  const onRetentionOfferDeclinePress = useCallback(() => {
    if (retentionComponent) {
      mParticleEventProcessor.pushMParticleEvent(
        MParticleCustomEventTypes.RetentionOfferDeclineEvent,
        mParticleEventData
      );
    }
    setCancel(true);
  }, [mParticleEventData, retentionComponent]);

  if (loadingData) {
    return <SkeletonDisplay />;
  }

  if (
    retentionCouponApplied === '1' ||
    cancel ||
    pageUriData === null ||
    !retentionComponent ||
    isPaypalUser ||
    !showRetentionPage ||
    !retentionEnabled
  ) {
    return (
      <UnsubscribeConfirm
        subscriptionId={subscriptionId}
        cancelingReason={cancelingReason}
        subscriptionEndDate={subscriptionEndDate}
        retentionComponent={retentionComponent}
        retentionEnabled={retentionEnabled}
        maxSubTitle={pageUriData?.headline || ''}
      />
    );
  }

  return (
    <LayoutWrapper
      pageTitle={language.retentionTitle}
      layoutType={LayoutType.Full}
      titleType={TitleType.Left}
      subTitle={pageUriData?.headline || ''}
    >
      {isWeb && <MetaTags title={language.retentionTitle} />}
      <View style={styles.retentionContainer}>
        <View style={styles.offerContainer}>
          <LinearGradient
            colors={[
              colors.tenantBackground.dark.surface04FilmGrad.start,
              colors.tenantBackground.dark.surface04FilmGrad.end,
            ]}
            style={styles.offerContainerOvelay}
          >
            <TextContent style={styles.offerText}>{retentionComponent?.subheadline}</TextContent>
            <View style={styles.arrowContainer}>
              <Image source={ImageAssets.downArrow} style={styles.downArrow} />
            </View>
          </LinearGradient>
        </View>
        <Image
          source={{ uri: isMobileScreen ? retentionMobileImage : retentionImage }}
          style={isMobileScreen ? styles.retentionMobileImage : styles.retentionImage}
        />
        <View style={styles.legalTextContainer}>
          <TextContent style={styles.legalText}>{retentionComponent?.description}</TextContent>
        </View>
        <EstadioButton
          loading={loading}
          onPress={activatePromotion}
          label={language.promotionButtonLabel}
          labelStyle={styles.activateBtnText}
          btnStyle={styles.activateBtn}
          focusKey={FocusKeys.CANCEL_SUBSCRIPTION}
        />
        <EstadioButton
          onPress={onRetentionOfferDeclinePress}
          label={language.cancelSubscriptionLabel}
          labelStyle={styles.cancelBtnText}
          btnStyle={styles.cancelBtn}
        />
        <EstadioButton
          onPress={goToProfile}
          label={language.backToAccountLabel}
          labelStyle={styles.returnBtnText}
          btnStyle={styles.returnBtn}
        />
      </View>
    </LayoutWrapper>
  );
};

export default Retention;
