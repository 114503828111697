import { Dimensions, Platform, StatusBar } from 'react-native';

const TICKER_HEIGHT = 75;
const FULL_WINDOW_WIDTH: number = Dimensions.get('window').width;
const FULL_WINDOW_HEIGHT: number = Dimensions.get('window').height;
const SCREEEN_HEIGHT: number = Dimensions.get('screen').height;
const STATUSBAR_HEIGHT: number = Platform.OS === 'android' && StatusBar.currentHeight ? StatusBar.currentHeight : 0;

export default {
  IMAGE_SMALL_70: 70,
  BORDER_RADIUS_20: 20,
  FONT_SIZE_14: 14,
  ICON_LAND_WIDTH: 50,
  ICON_LAND_HEIGHT: 5,

  ZERO: 0,
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
  SIX: 6,
  SEVEN: 7,
  EIGHT: 8,
  NINE: 9,
  TEN: 10,
  FIFTHEEN: 15,
  EIGHTHEEN: 15,
  TWENTY: 20,
  TWENTY_FIVE: 25,
  THIRTY: 30,
  THIRTY_FIVE: 35,
  FORTY: 40,
  FORTY_FIVE: 45,
  FIFTY: 50,
  EIGHTY: 80,
  NINETY: 90,
  HUNDRED: 100,
  ONE_TWENTY: 120,
  ONE_FIFTY: 150,
  ONE_EIGHTY: 180,

  PADDING_10: 10,
  PERCENT_100: '100%',
  PERCENT_50: '60%',
  PERCENT_25: '60%',
  FULL_WINDOW_WIDTH: Dimensions.get('window').width,
  FULL_WINDOW_HEIGHT: Dimensions.get('window').height,
  TICKER_HEIGHT: TICKER_HEIGHT,
  HEADER_HEIGHT: 56,
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',

  SPLASHSCREEN_ANIM_WIDTH: 210,
  SPLASHSCREEN_ANIM_HEIGHT: 240,
  STATUSBAR_HEIGHT: STATUSBAR_HEIGHT,
  NAVBAR_HEIGHT: SCREEEN_HEIGHT - FULL_WINDOW_HEIGHT - STATUSBAR_HEIGHT,
};
