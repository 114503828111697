export enum videoStates {
  PLAYER_READY = 'PLAYER_READY',
  MEDIA_ERROR = 'MEDIA_ERROR',
  MEDIA_PAUSED = 'MEDIA_PAUSED',
  MEDIA_RESUMED = 'MEDIA_RESUMED',
  PLAYER_ERROR = 'PLAYER_ERROR',
  CONTENT_ERROR = 'CONTENT_ERROR',
  CONTENT_ENDED = 'CONTENT_ENDED',
  NO_PLAYER = '',
  MEDIA_BLOCKED = 'MEDIA_BLOCKED',
  CONTROLS_VISIBLE = 'CONTROLS_VISIBLE',
  CONTROLS_HIDDEN = 'CONTROLS_HIDDEN',
  MEDIA_STATES = 'MEDIA_STATES',
  VIEW_STATE_CHANGED = 'VIEW_STATE_CHANGED',
  IS_PLAYING = 'ISPLAYING',
  IS_PAUSED = 'IS_PAUSED',
  MODULE_READY = 'MODULE_READY',
  TIME_CHANGED = 'TIME_CHANGED',
  TUB_ERROR = 'TUB_ERROR',
  WARNING = 'WARNING',
  PLAYBACK_COULD_NOT_BE_STARTED = 'PLAYBACK_COULD_NOT_BE_STARTED',
}

export type PlayerState = {
  type: videoStates;
  message: string | null | unknown;
};

export const PLAYER_STATE_INITIAL: PlayerState = {
  type: videoStates.NO_PLAYER,
  message: null,
};
