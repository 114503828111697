import React, { FC, useCallback, useContext } from 'react';
import { Skeleton, useGetDevice } from '@warnermmedia/gsp-core/sdk/ui';
import { View, ViewStyle } from 'react-native';
import { useReactiveVar } from '@apollo/client';
import {
  AppConfigContext,
  breakpointsStateStore,
  isUserLoggedIn,
} from '@warnermmedia/gsp-core/brands/estadio/data-access';
import LayoutWrapper, { LayoutType, TitleType } from '../layoutWrapper/layoutWrapper';
import { LoadingAppIndicator } from '../loadingAppIndicator';
import { SkeletonDetailPage } from './skeletonDetailPage';
import { getStyles } from './skeletonDisplay.styles';
import { useScrollToTop } from '@warnermmedia/gsp-core/brands/estadio/feature';
import { ScrollViewRefContext } from '../layout';

export const SkeletonDisplay: FC<{ route?: string }> = ({ route = '' }) => {
  const isLoggedIn = useReactiveVar(isUserLoggedIn);
  const breakpoints = useReactiveVar(breakpointsStateStore);

  const { isMobileDevice, isTv } = useGetDevice();
  const width = breakpoints.windowWidth;
  const height = breakpoints.windowHeight;
  const styles = getStyles(breakpoints, isTv, isMobileDevice, height);
  const isMobileSize = isMobileDevice || width <= breakpoints.breakpointSizes.med;
  const generalMargin = 40;
  const cardSize = isMobileSize ? 150 : 180;
  const formularyPages = ['/forgot-password', '/reset-password', '/resend-email', '/redeemcoupon'];
  const detailPages = ['/team/', '/show/', '/tournaments/'];
  const matchDetailPages = ['/match/'];
  const layoutType = formularyPages.includes(route) ? LayoutType.Center : LayoutType.Full;
  const isVideoPage = route.startsWith('/video/');
  const { scrollToTop } = useScrollToTop(ScrollViewRefContext);
  const isDetailPage = route.startsWith('/team/') || route.startsWith('/show/') || route.startsWith('/tournaments/');
  const appConfig = useContext(AppConfigContext);
  const isAppEnabled = appConfig?.appConfig?.appEnabled ?? true;

  const getCarouselRow = useCallback((length: number, hasTitle = false, overrideContentStyle?: ViewStyle) => {
    const cardsArray = length > 0 ? new Array(length).fill('') : [];
    return (
      <>
        {cardsArray.map((_, index) => (
          <Skeleton hasTitle={hasTitle} key={index} overrideContentStyle={overrideContentStyle} />
        ))}
      </>
    );
  }, []);

  const getGridSkeleton = useCallback(() => {
    const cardsWindow = Math.floor(((width - generalMargin - cardSize) / cardSize) * (height / cardSize));
    return (
      <View style={styles.gridSkeletonWrapper}>
        <View>
          <Skeleton hasTitle={false} overrideContentStyle={styles.pageTitle} />
          <Skeleton hasSubTitle={false} overrideContentStyle={styles.pageSubtitle} />
        </View>
        <View style={styles.gridSkeletonCardContainer}>
          {getCarouselRow(cardsWindow, false, styles.gridSkeletonCard)}
        </View>
      </View>
    );
  }, [
    width,
    cardSize,
    height,
    getCarouselRow,
    styles.gridSkeletonCard,
    styles.gridSkeletonCardContainer,
    styles.gridSkeletonWrapper,
    styles.pageSubtitle,
    styles.pageTitle,
  ]);

  const getHomeSkeleton = useCallback(() => {
    return (
      <View style={styles.homeSkeletonWrapper}>
        <View>
          <Skeleton overrideContentStyle={styles.heroSkeletonCard} />
        </View>
        <View style={styles.homeSkeletonContainer}>
          <SkeletonDetailPage windowHeightStartTop={300} overrideWrapperStyle={{ paddingLeft: 0 }} />
        </View>
      </View>
    );
  }, [styles.heroSkeletonCard, styles.homeSkeletonContainer, styles.homeSkeletonWrapper]);

  const getTeamSkeleton = useCallback(() => {
    const cardsWindow = Math.floor(((width - generalMargin - cardSize) / cardSize) * (height / cardSize));
    return (
      <View style={styles.teamSkeletonWrapper}>
        <View>
          <Skeleton hasTitle={false} overrideContentStyle={styles.pageTitle} />
          <Skeleton hasSubTitle={false} overrideContentStyle={styles.pageSubtitle} />
        </View>
        <View style={styles.teamSkeletonCardContainer}>
          {getCarouselRow(cardsWindow, false, styles.teamSkeletonCard)}
        </View>
      </View>
    );
  }, [
    width,
    cardSize,
    height,
    getCarouselRow,
    styles.teamSkeletonCard,
    styles.teamSkeletonCardContainer,
    styles.teamSkeletonWrapper,
    styles.pageSubtitle,
    styles.pageTitle,
  ]);

  const getSignalTvSkeleton = useCallback(
    (route: string) => {
      const cardsWindow = Math.floor((width - generalMargin - cardSize) / cardSize) - 1;

      return (
        <View style={styles.signalTvSkeletonWrapper}>
          <View>
            <Skeleton
              hasTitle={true}
              overrideContentStyle={styles.signalTvSkeleton}
              overrideTitleStyle={{ marginLeft: 0 }}
            />
          </View>
          {route === '/tvsignal' && (
            <View style={styles.signalTvSkeletonContainer}>
              {getCarouselRow(cardsWindow, false, { height: cardSize, width: cardSize, marginRight: 5 })}
            </View>
          )}
        </View>
      );
    },
    [
      width,
      cardSize,
      getCarouselRow,
      styles.signalTvSkeleton,
      styles.signalTvSkeletonContainer,
      styles.signalTvSkeletonWrapper,
    ]
  );

  const getVideoSkeleton = useCallback(() => {
    return (
      <View style={styles.videoSkeletonWrapper}>
        <View>
          <Skeleton
            hasTitle={false}
            overrideContentStyle={isMobileDevice ? styles.mobileSkeletonBody : styles.videoSkeletonBody}
          />
        </View>
      </View>
    );
  }, [styles.videoSkeletonWrapper, styles.videoSkeletonBody, isMobileDevice, styles.mobileSkeletonBody]);

  const getLandingSkeleton = useCallback(() => {
    return isAppEnabled ? (
      <View style={styles.landingLayout}>
        <View style={styles.landingContent}>
          <View style={styles.landingSkeletonWrapper}>
            <View style={styles.landingSkeletonTitle}>
              <Skeleton overrideContentStyle={styles.landingTopMarketingImg} />
              <Skeleton overrideContentStyle={styles.landingBottomMarketingImg} />
            </View>
            <View style={styles.landingSkeletonBody}>
              <Skeleton hasSubTitle={true} hasBody={false} overrideSubTitleStyle={{ marginLeft: 0 }} />
              <Skeleton overrideContentStyle={styles.landingForm} />
            </View>
          </View>
          <View style={styles.landingVideoSkeletonWrapper}>
            <View style={styles.landingVideoDes}>
              <Skeleton hasSubTitle={true} hasBody={false} overrideSubTitleStyle={{ marginLeft: 0, marginTop: 0 }} />
              <Skeleton overrideContentStyle={styles.landingVideoDescription} />
            </View>
            <View style={styles.landingVideo}>
              <Skeleton overrideContentStyle={{ height: 284 }} />
            </View>
          </View>
        </View>
      </View>
    ) : (
      <View style={styles.landingLayout}>
        <View style={styles.landingContent}>
          <View style={styles.landingSkeletonWrapper}>
            <View style={styles.landingSkeletonTitle}>
              <Skeleton overrideContentStyle={styles.landingTopMarketingImg} />
              <Skeleton overrideContentStyle={styles.landingBottomMarketingImg} />
            </View>
          </View>
        </View>
      </View>
    );
  }, [
    styles.landingSkeletonWrapper,
    styles.landingSkeletonTitle,
    styles.landingSkeletonBody,
    styles.landingContent,
    styles.landingLayout,
    styles.landingVideo,
    styles.landingVideoDes,
    styles.landingVideoSkeletonWrapper,
    styles.landingBottomMarketingImg,
    styles.landingTopMarketingImg,
    styles.landingForm,
    styles.landingVideoDescription,
    isAppEnabled,
  ]);

  const getListSkeleton = useCallback(() => {
    return (
      <View>
        <Skeleton hasTitle={true} hasBody={false} overrideContentStyle={{ height: 20 }} />
        <Skeleton overrideContentStyle={{ height: 384, marginLeft: 10 }} />
      </View>
    );
  }, []);

  const getMatchDetailSkeleton = useCallback(() => {
    const cardsWindow = Math.floor(height / cardSize);
    return (
      <View style={styles.homeSkeletonWrapper}>
        <View>
          <Skeleton hasSubTitle={false} overrideContentStyle={styles.heroSkeletonCard} />
        </View>
        <View style={styles.matchSkeletonContainer}>
          <View>
            <Skeleton hasSubTitle={true} hasBody={false} />
          </View>
          <View style={styles.matchCarousel}>{getCarouselRow(cardsWindow, false, styles.matchSkeletonCard)}</View>
          <View>
            <Skeleton hasSubTitle={true} hasBody={false} />
          </View>
          <View style={styles.matchCarousel}>{getCarouselRow(cardsWindow, false, styles.matchSkeletonCard)}</View>
        </View>
      </View>
    );
  }, [
    cardSize,
    getCarouselRow,
    height,
    styles.heroSkeletonCard,
    styles.homeSkeletonWrapper,
    styles.matchCarousel,
    styles.matchSkeletonCard,
    styles.matchSkeletonContainer,
  ]);

  const getSkeletonType = useCallback(() => {
    const gridPages = ['/shows', '/tournaments'];
    const teamPage = ['/teams'];
    const listPages = ['/profile/billing'];
    const isHome = isLoggedIn && (route === '/home' || route === '/');
    const liveVideoPages = ['/tvsignal', '/golfchannel'];
    scrollToTop();

    if (formularyPages.includes(route) || route === '/profile') {
      return getListSkeleton();
    }

    if (gridPages.includes(route)) {
      return getGridSkeleton();
    }

    if (teamPage.includes(route)) {
      return getTeamSkeleton();
    }
    if (detailPages.some((detail) => route.startsWith(detail))) {
      return <SkeletonDetailPage />;
    }
    if (matchDetailPages.some((detail) => route.startsWith(detail))) {
      return getMatchDetailSkeleton();
    }

    if (isVideoPage) {
      return getVideoSkeleton();
    }

    if (route === '/register') {
      return getLandingSkeleton();
    }

    if (liveVideoPages.includes(route)) {
      return getSignalTvSkeleton(route);
    }

    if (isHome) {
      return getHomeSkeleton();
    }

    if (listPages.includes(route)) {
      return getListSkeleton();
    }

    return <LoadingAppIndicator />;
  }, [
    route,
    scrollToTop,
    formularyPages,
    isLoggedIn,
    getGridSkeleton,
    getHomeSkeleton,
    getLandingSkeleton,
    getSignalTvSkeleton,
    detailPages,
    getListSkeleton,
    getTeamSkeleton,
    isVideoPage,
    getVideoSkeleton,
    matchDetailPages,
    getMatchDetailSkeleton,
  ]);

  return !isVideoPage && !isDetailPage ? (
    <LayoutWrapper layoutType={layoutType} titleType={TitleType.Left} pageTitle="">
      {getSkeletonType()}
    </LayoutWrapper>
  ) : (
    getSkeletonType()
  );
};

export default SkeletonDisplay;
