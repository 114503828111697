import merge from 'lodash/merge';
import { StyleProp, StyleSheet, ViewStyle, TextStyle } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';

interface Styles {
  wrapper: ViewStyle;
  loginFormLayout: ViewStyle;
  regBtnWrapper: ViewStyle;
  regBtnText: TextStyle;
  regBtnContainer: ViewStyle;
  message: ViewStyle;
}

export const getStyles = (breakpoints: Breakpoints, colors: ReactNativePaper.ThemeColors) => {
  const base: StyleProp<Styles> = {
    wrapper: {
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      marginBottom: 30,
    },
    loginFormLayout: {
      paddingHorizontal: 0,
      paddingBottom: 0,
    },
    regBtnWrapper: {},
    regBtnText: {
      color: colors.keyboardFocus.dark,
    },
    regBtnContainer: {},
    message: {
      marginTop: 30,
    },
  };

  const mediaQueries =
    breakpoints.currentBreakpoints.isTiny ||
    breakpoints.currentBreakpoints.isTnySm ||
    breakpoints.currentBreakpoints.isSmMed
      ? ({} as StyleProp<Styles>)
      : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
