import { StyleProp, ViewStyle, StyleSheet } from 'react-native';

interface Styles {
  container: ViewStyle;
  wrapper: ViewStyle;
  label: ViewStyle;
  iconOnly: ViewStyle;
  hoveredContainer?: ViewStyle;
}

const base: StyleProp<Styles> = {
  container: {},
  wrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    marginRight: 32,
  },
  iconOnly: {
    alignItems: 'center',
  },
};

export default StyleSheet.create(base);
