import { StyleProp, ViewStyle, StyleSheet, TextStyle, ImageStyle } from 'react-native';
import merge from 'lodash/merge';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';

interface Styles {
  detailStyle: ViewStyle;
  detail: ViewStyle;
  background: ViewStyle;
  accountWrapper: ViewStyle;
  overlay: ViewStyle;
  titleStyle: TextStyle;
  subTitleStyle: TextStyle;
  detailLabel: TextStyle;
  detailValue: TextStyle;
  actionBtn: ViewStyle;
  actionBtnText: TextStyle;
  teamLogo: ImageStyle;
}

export const getStyles = (breakpoints: Breakpoints, colors: ReactNativePaper.ThemeColors) => {
  const base: StyleProp<Styles> = {
    detailStyle: {
      alignItems: 'flex-start',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
    },
    detail: {
      width: 300,
    },
    background: {
      width: 111,
      height: 111,
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 5,
    },
    accountWrapper: {
      maxWidth: '100%',
    },
    overlay: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      backgroundColor: colors.fill.action.accent02,
      borderRadius: 5,
    },
    titleStyle: {
      color: colors.keyboardFocus.light,
      fontSize: 33,
      fontFamily: 'Oswald-SemiBold',
      fontWeight: '600',
      textTransform: 'uppercase',
    },
    subTitleStyle: {
      color: colors.keyboardFocus.light,
      fontSize: 22,
      fontFamily: 'Oswald-SemiBold',
      fontWeight: '600',
      textTransform: 'uppercase',
    },
    detailLabel: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Rubik-Medium',
      fontSize: 16,
      fontWeight: '500',
      height: 20,
      lineHeight: 20,
      width: '100%',
    },
    detailValue: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Rubik-Regular',
      fontSize: 20,
      fontWeight: 'normal',
      lineHeight: 24,
      marginVertical: 12,
    },
    actionBtn: {
      backgroundColor: colors.fill.action.accent01,
      borderWidth: 0,
      width: 300,
    },
    actionBtnText: {
      fontFamily: 'Oswald-Medium',
    },
    teamLogo: {
      width: 110,
      height: 110,
    },
  };

  const mediaQueries =
    breakpoints.currentBreakpoints.isTiny ||
    breakpoints.currentBreakpoints.isTnySm ||
    breakpoints.currentBreakpoints.isSmMed
      ? ({
          actionBtn: {
            width: '100%',
          },
        } as StyleProp<Styles>)
      : breakpoints.currentBreakpoints.isMedLg
      ? ({
          detailStyle: {
            width: '90%',
          },
        } as StyleProp<Styles>)
      : breakpoints.currentBreakpoints.isLgXl ||
        breakpoints.currentBreakpoints.isXlXxl ||
        breakpoints.currentBreakpoints.isXxl
      ? ({
          detailStyle: {
            width: '100%',
          },
          detail: {
            width: '22%',
          },
        } as StyleProp<Styles>)
      : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
