import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  messageOverlay: {
    position: 'absolute',
    bottom: 50,
    zIndex: 20000,
    width: '60%',
    marginHorizontal: '20%',
  },
});
