import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { NavbarNarrowProps } from './navbarNarrow';

export function NavbarNarrowNoGradient(props: NavbarNarrowProps) {
  const width = props.width || 150;
  const height = props.height || 129;
  return (
    <Svg id="Layer_1" x="0px" y="0px" viewBox="0 0 500 428.641" width={width} height={height} fill="none">
      <Path
        fill="#FFFFFF"
        d="M214.56,174.8c-36.876,1.905-73.607,0.952-110.064-5.406c-14.335-2.294-28.279-6.58-41.427-12.733
	c-5.229-2.518-10.441-5.39-11.813-12.007c0,10.49,0,20.98,0,31.502c-0.158,1.738,0.161,3.486,0.923,5.056
	c0.762,1.57,1.938,2.902,3.402,3.852c4.521,2.934,9.302,5.446,14.283,7.505c26.483,10.183,54.338,13.201,82.306,14.928
	c43.209,2.447,86.555-0.305,129.107-8.199c1.485-0.258,10.2-2.065,15.348-3.13l-18.866-29.05
	C256.729,170.346,235.798,173.687,214.56,174.8z"
      />
      <Path
        fill="#FFFFFF"
        d="M233.845,123.158c-43.106,3.889-86.099,3.502-128.849-3.825c-14.457-2.209-28.532-6.436-41.815-12.556
	c-5.261-2.534-10.393-5.535-11.926-12.136c0,10.49,0,20.98,0,31.502c-0.129,1.738,0.214,3.479,0.993,5.038
	c0.778,1.559,1.963,2.88,3.429,3.822c3.935,2.657,8.112,4.938,12.475,6.811c24.321,9.683,50.029,13.055,75.85,14.992
	c30.795,2.341,61.738,1.92,92.457-1.259c6.698-0.71,22.19-2.776,31.212-3.954l-19.608-30.178
	C243.269,122.076,238.557,122.738,233.845,123.158z"
      />
      <Path
        fill="#FFFFFF"
        d="M411.787,161.26c-0.258-6.052,0-12.136,0-18.188v-11.926c-0.856,5.1-4.39,7.924-8.376,10.28
	c-10.119,6.004-21.416,8.586-32.729,11.055c-3.034,0.646-6.084,1.21-9.15,1.759v32.567c7.246-1.484,14.524-2.872,21.706-4.664
	c8.036-1.752,15.689-4.939,22.594-9.408c1.948-1.184,3.534-2.88,4.584-4.903C411.466,165.808,411.941,163.535,411.787,161.26z"
      />
      <Path
        fill="#FFFFFF"
        d="M389.177,130.129c5.993-2.113,11.761-4.816,17.22-8.069c3.841-2.114,5.422-5.81,5.325-10.393
	c-0.177-9.296,0-18.607,0-27.919c0-0.823-0.113-1.614-0.209-3.002c-1.614,5.423-3.438,8.069-8.312,10.668
	c-3.526,2.103-7.277,3.803-11.183,5.067c-10.087,2.792-20.286,5.164-30.502,7.504v33.148
	C371.134,134.826,380.285,132.873,389.177,130.129z"
      />
      <Path
        fill="#FFFFFF"
        d="M411.304,37.414c0-4.551,0.484-7.876-1.066-10.635c-2.517,7.407-9.683,15.073-23.594,20.689
	c-9.07,1.872-18.075,3.986-27.193,5.552c-13.008,2.243-26.08,4.148-39.136,6.036c-2.679,0.387-3.502,1.323-3.47,4.083
	c0.21,21.98,0.243,43.977,0.323,65.958c0.088,1.24-0.117,2.484-0.597,3.631c-2.598-3.89-5.213-7.779-7.795-11.685
	c-10.119-15.363-20.302-30.662-30.275-46.139c-4.1-6.375-9.554-8.473-16.897-7.246c-32.947,5.356-66.358,7.297-99.703,5.794
	c-22.852-0.92-45.591-2.953-67.943-8.069c-4.341-0.985-8.618-2.243-12.911-3.47c-4.874-1.357-9.531-3.4-13.83-6.068
	c-6.681-4.196-13.944-10.91-11.426-19.366c-3.341,2.647-4.47,5.858-4.47,10.571c0,10.409-0.129,17.236-0.129,25.821
	c0,9.102,2.453,10.167,7.585,13.169c9.844,5.729,20.689,8.812,31.583,11.539c21.836,5.439,44.123,7.634,66.555,8.65
	c32.955,1.501,65.812,0.565,98.444-4.502c0.814-0.127,1.647-0.015,2.397,0.323c0.751,0.339,1.387,0.888,1.831,1.581
	c3.793,5.923,7.795,11.701,11.652,17.575c14.331,21.787,28.646,43.584,42.945,65.393c2.663,4.083,6.181,6.971,11.184,6.552
	c6.455-0.533,13.007-1.614,19.721-2.582V88.412c11.297-2.163,22.19-4.035,33.051-6.455c9.522-2.098,18.85-4.841,27.145-10.361
	c4.971-3.228,6.116-7.553,6.116-14.718C411.449,50.261,411.304,46.678,411.304,37.414z"
      />
      <Path
        fill="#FFFFFF"
        d="M68.087,29.829c-4.446,1.515-8.6,3.78-12.281,6.697c-4.39,14.734,20.076,23.659,20.076,23.659
	c0.316-0.208,0.665-0.361,1.033-0.452c6.165-1.42,12.281-3.228,18.527-4.18c26.381-4.304,52.783-8.44,79.207-12.41
	c16.945-2.598,33.891-5.277,50.9-7.343c21.302-2.508,42.76-3.447,64.199-2.808c27.29,0.71,54.451,2.598,81.063,8.892
	c5.326,1.275,11.023,2.679,15.832,5.584c13.572-5.439,21.077-13.282,23.594-20.689c-1.241-2.065-2.97-3.794-5.035-5.035
	c-1.804-1.217-3.698-2.296-5.665-3.228c-12.307-5.347-25.19-9.256-38.393-11.652c-22.598-4.093-45.495-6.317-68.459-6.649
	c-28.916-0.856-57.851,0.84-86.469,5.067L82.66,26.004C77.698,26.84,72.82,28.121,68.087,29.829z"
      />
      <Path
        fill="#FFFFFF"
        d="M68.087,29.829c-4.446,1.515-8.6,3.78-12.281,6.697c-4.39,14.734,20.076,23.659,20.076,23.659
	c0.316-0.208,0.665-0.361,1.033-0.452c6.165-1.42,12.281-3.228,18.527-4.18c26.381-4.304,52.783-8.44,79.207-12.41
	c16.945-2.598,33.891-5.277,50.9-7.343c21.302-2.508,42.76-3.447,64.199-2.808c27.29,0.71,54.451,2.598,81.063,8.892
	c5.326,1.275,11.023,2.679,15.832,5.584c13.572-5.439,21.077-13.282,23.594-20.689c-1.241-2.065-2.97-3.794-5.035-5.035
	c-1.804-1.217-3.698-2.296-5.665-3.228c-12.307-5.347-25.19-9.256-38.393-11.652c-22.598-4.093-45.495-6.317-68.459-6.649
	c-28.916-0.856-57.851,0.84-86.469,5.067L82.66,26.004C77.698,26.84,72.82,28.121,68.087,29.829z"
      />
      <Path
        fill="#FFFFFF"
        d="M398.652,398.382l-3.115-0.936c-2.114-0.678-5.422-1.743-5.422-4.487c0-2.743,2.985-3.389,5.229-3.389h6.697
	l5.697-10.57h-86.082c-1.824,0-3.572,0.724-4.862,2.013c-1.289,1.29-2.013,3.038-2.013,4.862v9.78
	c-3.131-10.991-14.024-18.237-25.676-18.237c-4.966-0.074-9.856,1.227-14.128,3.759c-4.273,2.532-7.761,6.196-10.08,10.588
	c-1.21-8.86-7.908-12.765-17.22-12.765h-39.619c-9.393,0-16.139,6.132-16.139,15.654c0,9.07,5.035,11.071,12.685,13.25
	c2.614,0.742,7.408,1.92,7.408,5.341c0,3.422-3.051,4.729-5.907,4.729c-4.08-0.238-7.932-1.96-10.829-4.842l-5.229,9.829
	c4.824,3.423,10.578,5.293,16.494,5.358c4.819,0.131,9.531-1.434,13.314-4.422c3.728-3.228,4.841-7.957,4.841-12.621
	c0-7.585-5.035-10.893-11.571-12.813l-3.098-0.936c-2.115-0.678-5.423-1.743-5.423-4.487c0-2.743,2.986-3.97,5.229-3.97h18.769
	v37.974h12.459v-15.929h7.068c6.827,0,11.781-1.953,14.525-6.197c1.242,14.056,11.958,23.723,26.435,23.723
	c12.91,0,22.755-7.649,25.676-19.204v17.607h12.475v-18.495h0.129l13.847,18.479h13.475l-15.089-19.689
	c6.81-1.211,10.506-6.94,10.506-13.556c0.004-1.419-0.158-2.832-0.484-4.213h8.375v37.458h12.492V389.57h7.601
	c-0.484,1.629-0.724,3.32-0.71,5.019c0,9.086,5.051,11.087,12.717,13.266c2.614,0.759,7.423,1.937,7.423,5.358
	c0,3.421-3.066,4.745-5.922,4.745c-4.086-0.234-7.944-1.956-10.845-4.842l-5.245,9.845c4.833,3.43,10.599,5.299,16.525,5.358
	c4.824,0.131,9.541-1.434,13.331-4.422c3.744-3.228,4.841-8.069,4.841-12.653C410.24,403.627,405.204,400.319,398.652,398.382z
	 M243.175,401.045h-2.114V389.07h2.098c4.841,0,8.989,0,8.989,6.117C252.148,401.303,247.71,401.045,243.175,401.045z
	 M289.105,416.521c-1.84-0.024-3.657-0.417-5.342-1.155c-1.686-0.739-3.206-1.807-4.471-3.143c-1.266-1.336-2.25-2.913-2.896-4.636
	s-0.939-3.558-0.864-5.396c0-6.633,6.117-12.685,13.573-12.685c7.456,0,13.572,6.052,13.572,12.685
	c0.076,1.838-0.218,3.673-0.864,5.396c-0.645,1.723-1.63,3.3-2.895,4.636c-1.266,1.336-2.786,2.404-4.472,3.143
	C292.761,416.104,290.945,416.497,289.105,416.521z M328.466,400.528h-1.21v-11.974h1.21c4.083,0,8.667,0.774,8.667,5.987
	C337.133,399.754,332.549,400.528,328.466,400.528z"
      />
      <Path
        fill="#FFFFFF"
        d="M132.707,378.741v24.595h-0.452l-13.507-20.98c-0.578-1.217-1.521-2.223-2.699-2.877
	c-1.178-0.654-2.531-0.923-3.87-0.77H55.356v9.683H70.93v39.394h13.589v-39.362H99.64v39.394h12.911v-29.323h0.274l17.171,25.708
	c0.593,1.205,1.539,2.201,2.713,2.853s2.519,0.929,3.856,0.795h8.876v-39.427h15.17v39.394h13.282v-39.394h11.797l5.035-9.683
	H132.707z"
      />
      <Path
        fill="#FFFFFF"
        d="M221.354,344.705l6.23-15.67h32.276l5.875,15.67h53.983c6.15,0.368,12.311-0.525,18.103-2.626
	c5.793-2.1,11.094-5.363,15.579-9.588c4.485-4.224,8.059-9.321,10.502-14.978c2.442-5.657,3.702-11.753,3.702-17.915
	c0-6.161-1.26-12.257-3.702-17.914c-2.443-5.656-6.017-10.754-10.502-14.978c-4.485-4.225-9.786-7.488-15.579-9.588
	c-5.792-2.101-11.953-2.994-18.103-2.626h-33.262v78.868l-29.694-78.868H231.15l-34.697,90.213H221.354z M309.922,274.358h5.503
	c15.073,0,25.725,9.102,25.725,25.24c0,17.591-11.959,25.257-25.967,25.257h-5.261V274.358z M243.754,282.427h0.226l9.586,28.71
	h-19.511L243.754,282.427z"
      />
      <Path fill="#FFFFFF" d="M393.616,254.492h-23.449v90.213h23.449V254.492z" />
      <Path
        fill="#FFFFFF"
        d="M450.116,251.506c-25.966,0-49.9,19.14-49.9,46.43c0,29.178,20.98,49.771,49.9,49.771
	c28.92,0,49.884-20.593,49.884-49.771C500,270.646,476.067,251.506,450.116,251.506z M450.116,324.968
	c-14.008,0-25.482-11.297-25.482-26.919c0-12.442,11.474-23.804,25.482-23.804s25.483,11.297,25.483,23.804
	C475.599,313.607,464.108,324.968,450.116,324.968z"
      />
      <Path
        fill="#FFFFFF"
        d="M75.657,324.855H23.497v-15.557h42.67v-19.867h-42.67v-15.073h58.098c-0.853,2.986-1.277,6.078-1.259,9.183
	c0,16.881,9.36,20.592,23.594,24.643c4.842,1.388,13.766,3.583,13.766,9.942c0,6.358-5.664,8.795-10.99,8.795
	c-7.746,0-14.524-4.051-20.125-9.021l-9.683,18.268c8.96,6.382,19.663,9.858,30.662,9.958c8.796,0,17.946-2.437,24.773-8.215
	c6.826-5.777,9.021-14.815,9.021-23.481c0-14.121-9.376-20.254-21.528-23.837l-5.778-1.743c-3.938-1.258-10.07-3.227-10.07-8.327
	c0-5.1,5.551-7.391,9.683-7.391h46.172v71.912h24.498v-71.912h26.741l7.456-18.64H110.435c-0.484,0-0.952,0-1.436,0H0v90.213h64.941
	L75.657,324.855z"
      />
    </Svg>
  );
}

export default NavbarNarrowNoGradient;
