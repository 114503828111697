import { useEffect, useRef } from 'react';

type IntervalFunction = () => unknown | void;

export const useInterval = (callback: IntervalFunction, delay: number, enabled = true) => {
  const savedCallback = useRef<IntervalFunction | null>(null);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current?.();
    }

    let id: number;

    if (enabled && delay !== null) {
      id = setInterval(tick, delay);
    }

    return () => clearInterval(id);
  }, [delay, enabled]);
};
