import React, { useContext, useState } from 'react';
import Hero from '../../swimlanes/hero/hero';
import { carouselFormat, LayoutUpdateContext, useSetCardIndex } from '@warnermmedia/gsp-core/brands/estadio/feature';
import { useGetDevice } from '@warnermmedia/gsp-core/sdk/ui';
import { useTheme } from 'react-native-paper';
import { useReactiveVar } from '@apollo/client';
import { breakpointsStateStore } from '@warnermmedia/gsp-core/brands/estadio/data-access';

export const HeroWrapper: React.FC<carouselFormat> = ({ allItems, clickAction, image, laneIndex = 1 }) => {
  const [cardsIndex, setCardsIndex] = useState(0);
  const contentWidth = useContext(LayoutUpdateContext);
  const { setIndex } = useSetCardIndex(cardsIndex, setCardsIndex);
  const device = useGetDevice();
  const { colors } = useTheme();
  const breakpoints = useReactiveVar(breakpointsStateStore);

  return (
    <>
      {Array.isArray(allItems) && allItems?.length > 0 ? (
        <Hero
          index={cardsIndex}
          indexUpdate={setIndex}
          data={allItems}
          action={clickAction}
          laneWidth={contentWidth}
          cardBg={image?.url ?? ''}
          device={device}
          colors={colors}
          breakpoints={breakpoints}
          carouselFocusKey={`CAROUSEL_${laneIndex}`}
        />
      ) : null}
    </>
  );
};

export default HeroWrapper;
