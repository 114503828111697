import React from 'react';
import Svg from 'react-native-svg';

/* eslint-disable-next-line */
export interface TvReadyProps {
  width?: number | string;
  height?: number | string;
}

export function TvReady(props: TvReadyProps) {
  const width = props.width || 164;
  const height = props.height || 114;

  return (
    <Svg width={width} height={height} viewBox="0 0 164 114" fill="none">
      <path
        d="M28.67 112H135.33M10 2H154C158.418 2 162 5.58172 162 10V94C162 98.4183 158.418 102 154 102H10C5.58172 102 2 98.4183 2 94V10C2 5.58172 5.58172 2 10 2Z"
        stroke="white"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M69.27 52.59L77.59 61.43L94.73 42.57"
        stroke="#1CDCAC"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

export default TvReady;
