import React, { useCallback, useState } from 'react';
import Reaptcha from 'reaptcha';
import { NativeSyntheticEvent, Text, TextInputKeyPressEventData, TouchableOpacity, View } from 'react-native';
import { List } from 'react-native-paper';
import {
  validateAlphabeticCharacters,
  validateEmail,
  validateNonEmptyString,
  validatePasswordRule,
} from '../../../utils';
import { Tooltip, TooltipProps } from '../../tooltip';
import { CustomButton as Button, CustomCheckbox as Checkbox, CustomTextInput as TextInput } from '../../formElements';
import { getStyles, RegisterAccountFormStyles } from './account.styles';
import { languageStrings, useOnEnterKeyPress } from '@warnermmedia/gsp-core/brands/estadio/feature';
import get from 'lodash/get';
import { KeyCodes } from '@warnermmedia/gsp-core/sdk/ui';
import { FocusKeys, ItemName } from '@warnermmedia/gsp-core/sdk/data-access';

export interface RegisterAccountFormData {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
}

type RegisterAccountFormTooltipProps = Omit<TooltipProps, 'tooltipText' | 'visible'>;

export interface RegisterAccountFormCheckbox {
  color?: string;
  uncheckedColor?: string;
}

export interface registerAccountFormErrors {
  missingAnyField: string;
  missingFirstName: string;
  missingLastName: string;
  missingEmail: string;
  invalidEmail: string;
  missingPassword: string;
  missingPassword2: string;
  invalidPassword: string;
  differingPassword: string;
  invalidCaptcha: string;
  unacceptedTerms: string;
  alreadyRegisteredEmail: string;
  incorrectPassword: string;
  unconfirmedAccount: string;
  onlyAlphabeticCharactersError: string;
}

export interface RegisterAccountFormInputAttributes {
  dense?: boolean;
  mode?: 'outlined' | 'flat';
  outlineColor?: string;
  theme?: { colors?: { text?: string; error?: string } };
  textAlign: 'left' | 'right' | 'center' | undefined;
}

export type RegisterAccountFormProps = React.ComponentPropsWithRef<typeof View> & {
  /**
   * The label for first name.
   */
  firstNameLabel?: string;
  /**
   * The label for last name.
   */
  lastNameLabel?: string;
  /**
   * The label for email.
   */
  userEmailLabel?: string;
  /**
   * The label for password.
   */
  passwordLabel?: string;
  /**
   * The label for password repeat.
   */
  password2Label?: string;
  /**
   * The checked color and unchecked color for checkbox.
   */
  checkboxAttributes?: RegisterAccountFormCheckbox;
  /**
   * The label for terms and condition acceptance (is element because of linking).
   */
  termsLabel?: JSX.Element;
  /**
   * The label for submit button.
   */
  ctaLabel?: string;
  /**
   * The image or icon to markup the error list.
   */
  errorIcon?: JSX.Element;
  /**
   * The text before the list of errors.
   */
  errorListLabel?: string;
  /**
   * The object of possible error messages when form is submitted.
   */
  errorMessages?: registerAccountFormErrors;
  /**
   * The stylesheet to format the whole from.
   */
  styles?: RegisterAccountFormStyles;
  /**
   * Common attributes for all TextInput.
   */
  textInputAttributes?: RegisterAccountFormInputAttributes;
  /**
   * Common attributes for all Tooltip.
   */
  tooltipAttributes?: RegisterAccountFormTooltipProps;
  /**
   * Function to execute on when form submit is successful.
   */
  handleRegister: (data: RegisterAccountFormData) => void;
  /**
   * ???
   */
  handleRecaptchaChange?: (value: string) => void;
  /**
   * ???
   */
  recaptchaSiteKey?: string;
  apiError?: string;
  consentMessage: JSX.Element;
  useAcceptTerms: boolean;
  formTitle: JSX.Element;
  formDirectionsText: JSX.Element;
  isLoading: boolean;
  setApiError: (value: string) => void;
  shouldFocusForm?: boolean;
  setShouldFocusForm?: (value: boolean) => void;
  passwordHideIcon?: JSX.Element;
  passwordShowIcon?: JSX.Element;
};

const defaultErrorMessages = {
  missingAnyField: 'You must input all your details to register.',
  missingFirstName: 'First Name Required',
  missingLastName: 'Last Name Required',
  missingEmail: 'Email Required',
  invalidEmail: 'Invalid Email',
  missingPassword: 'Password Required',
  missingPassword2: 'Password Required',
  invalidPassword: '8 characters: 1 uppercase, 1 lowercase, 1 number or symbol',
  differingPassword: 'Repeated Password Does Not Match',
  invalidCaptcha: 'The captcha is not valid. Please try again.',
  unacceptedTerms: 'You must agree to our Terms & Conditions to register.',
  onlyAlphabeticCharactersError: 'Only Alphabetic characters are accepted.',
};

export const RegisterAccountForm = ({
  firstNameLabel,
  lastNameLabel,
  userEmailLabel,
  passwordLabel,
  password2Label,
  checkboxAttributes,
  termsLabel,
  ctaLabel,
  errorIcon,
  errorListLabel,
  errorMessages,
  styles,
  isLoading,
  textInputAttributes,
  tooltipAttributes,
  handleRecaptchaChange,
  handleRegister,
  recaptchaSiteKey,
  apiError,
  consentMessage,
  useAcceptTerms,
  formTitle,
  formDirectionsText,
  setApiError,
  passwordHideIcon,
  passwordShowIcon,
  shouldFocusForm = false,
  setShouldFocusForm = () => null,
}: RegisterAccountFormProps) => {
  const [data, setData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    password2: '',
    termsAccepted: !useAcceptTerms,
  });
  const [inputFocus, setInputFocus] = useState({
    firstName: shouldFocusForm,
    lastName: false,
    email: false,
    password: false,
    password2: false,
  });
  const [inputError, setInputError] = useState({
    missingFirstName: '',
    missingLastName: '',
    missingEmail: '',
    missingPassword: '',
    missingPassword2: '',
    unacceptedTerms: '',
  });
  const [validateError, setValidateError] = useState({
    missingAnyField: '',
    invalidEmail: '',
    invalidPassword: '',
    differingPassword: '',
    invalidCaptcha: '',
    invalidAcceptTerms: '',
    invalidFirstNameTextFieldError: '',
    invalidLastNameTextFieldError: '',
  });
  const [isPasswordIconClicked, setIsPasswordIconClicked] = useState({
    password: false,
    password2: false,
  });
  const baseStyles = getStyles(styles);
  const validateErrorSummary = Object.values(validateError).filter((error) => error !== '');
  const formHasError = validateErrorSummary.length > 0;
  if (tooltipAttributes === undefined) {
    tooltipAttributes = {
      opacity: 1,
      position: 'left' as 'right' | 'left' | 'center',
    } as RegisterAccountFormTooltipProps;
  }
  if (textInputAttributes === undefined) {
    textInputAttributes = {
      textAlign: 'left' as 'left' | 'right' | 'center' | undefined,
    } as RegisterAccountFormInputAttributes;
  }

  type basicErrorFields = 'firstName' | 'lastName' | 'email' | 'password' | 'password2' | 'termsAccepted';
  type basicErrorMessages =
    | 'missingFirstName'
    | 'missingLastName'
    | 'missingEmail'
    | 'missingPassword'
    | 'missingPassword2'
    | 'unacceptedTerms';

  const revalidateField = (field: string, value: string) => {
    setValidateError((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const getBasicError = (field: basicErrorFields, message: basicErrorMessages, value?: boolean | string) => {
    let testBoolean = true;
    const valueToTest = value !== undefined ? value : data[field];
    if (field === 'termsAccepted') {
      testBoolean = valueToTest as boolean;
    } else {
      testBoolean = validateNonEmptyString(valueToTest as string);
    }
    formHasError && revalidateField('missingAnyField', getMissingAnyFieldError());
    return testBoolean ? '' : (errorMessages && errorMessages[message]) || defaultErrorMessages[message];
  };

  const getMissingAnyFieldError = () => {
    return data.firstName === '' ||
      data.lastName === '' ||
      data.email === '' ||
      data.password === '' ||
      data.password2 === ''
      ? errorMessages?.missingAnyField || defaultErrorMessages.missingAnyField
      : '';
  };

  const getInvalidTextFieldError = (fieldName: basicErrorFields) => {
    return data[fieldName] !== '' && !validateAlphabeticCharacters(data[fieldName] as string)
      ? errorMessages?.onlyAlphabeticCharactersError || defaultErrorMessages.onlyAlphabeticCharactersError
      : '';
  };

  const getInvalidEmailError = () => {
    return data.email !== '' && !validateEmail(data.email)
      ? errorMessages?.invalidEmail || defaultErrorMessages.invalidEmail
      : '';
  };

  const getInvalidPasswordError = () => {
    return data.password !== '' && !validatePasswordRule(data.password)
      ? errorMessages?.invalidPassword || defaultErrorMessages.invalidPassword
      : '';
  };

  const getDifferingPasswordError = () => {
    return data.password !== data.password2
      ? errorMessages?.differingPassword || defaultErrorMessages.differingPassword
      : '';
  };

  const getInvalidCaptchaError = () => {
    const isCaptchaValid = false; // TODO: get correct value and revert back to variable
    return recaptchaSiteKey && !isCaptchaValid
      ? errorMessages?.invalidCaptcha || defaultErrorMessages.invalidCaptcha
      : '';
  };

  const handleBasicErrors = (field: basicErrorFields, message: basicErrorMessages, value?: boolean | string) => {
    const update: { [key: string]: string } = {};
    update[message] = getBasicError(field, message, value);
    setInputError((prevState) => ({
      ...prevState,
      ...update,
    }));
  };

  const formValidator = () => {
    return {
      missingAnyField: getMissingAnyFieldError(),
      invalidEmail: getInvalidEmailError(),
      invalidPassword: getInvalidPasswordError(),
      differingPassword: getDifferingPasswordError(),
      invalidCaptcha: getInvalidCaptchaError(),
      invalidAcceptTerms: getBasicError('termsAccepted', 'unacceptedTerms'),
      invalidFirstNameTextFieldError: getInvalidTextFieldError('firstName'),
      invalidLastNameTextFieldError: getInvalidTextFieldError('lastName'),
    };
  };

  const handleAcceptTermsPress = () => {
    const newVal = !data.termsAccepted;
    setData((prevState) => ({
      ...prevState,
      termsAccepted: newVal,
    }));
    handleBasicErrors('termsAccepted', 'unacceptedTerms', newVal);
    formHasError && revalidateField('invalidAcceptTerms', getBasicError('termsAccepted', 'unacceptedTerms', newVal));
  };

  const handleSignup = () => {
    setApiError('');
    const formValidatorResult = formValidator();
    const hasError = Object.values(formValidatorResult).filter((error) => error !== '').length > 0;
    setApiError('');

    setInputError({
      missingFirstName: getBasicError('firstName', 'missingFirstName'),
      missingLastName: getBasicError('lastName', 'missingLastName'),
      missingEmail: getBasicError('email', 'missingEmail'),
      missingPassword: getBasicError('password', 'missingPassword'),
      missingPassword2: getBasicError('password2', 'missingPassword'),
      unacceptedTerms: getBasicError('termsAccepted', 'unacceptedTerms'),
    });
    setValidateError(formValidatorResult);

    if (!hasError) {
      handleRegister({
        firstname: data.firstName,
        lastname: data.lastName,
        email: data.email,
        password: data.password,
      });
    }
  };

  const { onEnterKeyPress } = useOnEnterKeyPress();

  const cleanNameFieldErrors = useCallback(() => {
    if (shouldFocusForm) {
      setInputError((prevState) => ({
        ...prevState,
        missingFirstName: '',
      }));
      setValidateError((prevState) => ({
        ...prevState,
        invalidFirstNameTextFieldError: '',
      }));
    }
  }, [shouldFocusForm]);

  const handlePasswordIconClick = useCallback(
    (label: string) => {
      const isIconClicked = get(isPasswordIconClicked, label, false);
      setIsPasswordIconClicked((prevState) => ({
        ...prevState,
        [label]: !isIconClicked,
      }));
    },
    [isPasswordIconClicked]
  );

  const getPasswordIcon = useCallback(
    (label: string) => {
      const isIconClicked = get(isPasswordIconClicked, label, false);
      return isIconClicked ? passwordShowIcon : passwordHideIcon;
    },
    [isPasswordIconClicked, passwordShowIcon, passwordHideIcon]
  );

  const onKeyPress = useCallback(
    (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (e.keyCode === KeyCodes.UpArrow || e.keyCode === KeyCodes.DownArrow) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        e.target.blur();
      } else {
        onEnterKeyPress(e, handleSignup);
      }
    },
    [onEnterKeyPress, handleSignup]
  );

  return (
    <View>
      {formHasError ? (
        <View style={baseStyles.errorsContainer}>
          {errorIcon || <List.Icon icon="alert-circle-outline" color="#fff" />}
          <View style={baseStyles.errorNotIconStyle}>
            <Text style={baseStyles.errorsTextTextStyle}>{errorListLabel || 'The form contains errors:'}</Text>
            {validateErrorSummary.map((text, i) => (
              <View key={'error' + i} style={baseStyles.errorsViewStyle}>
                <Text style={baseStyles.errorsBulletTextStyle}>{'\u2022'}</Text>
                <Text style={baseStyles.errorsTextTextStyle}>{text}</Text>
              </View>
            ))}
          </View>
        </View>
      ) : null}
      {apiError ? (
        <View style={[baseStyles.errorsContainer, baseStyles.apiErrorViewStyle]}>
          {errorIcon || <List.Icon icon="alert-circle-outline" color="#fff" />}
          <View style={baseStyles.errorsViewStyle}>
            <Text style={baseStyles.errorsBulletTextStyle}>{'\u2022'}</Text>
            <Text style={baseStyles.errorsTextTextStyle}>{apiError}</Text>
          </View>
        </View>
      ) : null}
      <View style={baseStyles.titleContainer}>
        <View>{formTitle}</View>
        <View>{formDirectionsText}</View>
      </View>

      <View>
        <Text style={baseStyles.formLabel}>{firstNameLabel || 'First Name'}</Text>
        <TextInput
          inputStyle={data.firstName.length < 1 ? baseStyles.inputStyle : baseStyles.inputStyleFilled}
          value={data.firstName}
          onChangeText={(firstName: string) => {
            setData((prevState) => ({ ...prevState, firstName }));
            if (inputError.missingFirstName !== '') {
              handleBasicErrors('firstName', 'missingFirstName');
            }
          }}
          onBlur={() => {
            handleBasicErrors('firstName', 'missingFirstName');
            revalidateField('invalidFirstNameTextFieldError', getInvalidTextFieldError('firstName'));
            setInputFocus((prevState) => ({ ...prevState, firstName: false }));
            setShouldFocusForm(false);
          }}
          onFocus={() => {
            setInputFocus((prevState) => ({ ...prevState, firstName: true }));
            setApiError('');
            cleanNameFieldErrors();
          }}
          error={inputError.missingFirstName !== '' || validateError.invalidFirstNameTextFieldError !== ''}
          onKeyPress={onKeyPress}
          {...textInputAttributes}
          shouldFocus={inputFocus.firstName && shouldFocusForm}
          focusKey={FocusKeys.REGISTER_ACCOUNT_FORM}
        />
        <Tooltip
          tooltipText={inputError.missingFirstName || validateError.invalidFirstNameTextFieldError}
          visible={inputError.missingFirstName !== '' || validateError.invalidFirstNameTextFieldError !== ''}
          {...tooltipAttributes}
        />
      </View>
      <View>
        <Text style={baseStyles.formLabel}>{lastNameLabel || 'Last Name'}</Text>
        <TextInput
          inputStyle={data.lastName.length < 1 ? baseStyles.inputStyle : baseStyles.inputStyleFilled}
          value={data.lastName}
          onChangeText={(lastName: string) => {
            setData((prevState) => ({ ...prevState, lastName }));
            if (inputError.missingLastName !== '') {
              handleBasicErrors('lastName', 'missingLastName');
            }
          }}
          onBlur={() => {
            handleBasicErrors('lastName', 'missingLastName');
            revalidateField('invalidLastNameTextFieldError', getInvalidTextFieldError('lastName'));
            setInputFocus((prevState) => ({ ...prevState, lastName: false }));
          }}
          onFocus={() => {
            setInputFocus((prevState) => ({ ...prevState, lastName: true }));
            setApiError('');
          }}
          error={inputError.missingLastName !== '' || validateError.invalidLastNameTextFieldError !== ''}
          onKeyPress={onKeyPress}
          {...textInputAttributes}
        />
        <Tooltip
          tooltipText={inputError.missingLastName || validateError.invalidLastNameTextFieldError}
          visible={inputError.missingLastName !== '' || validateError.invalidLastNameTextFieldError !== ''}
          {...tooltipAttributes}
        />
      </View>
      <View>
        <Text style={baseStyles.formLabel}>{userEmailLabel || 'Email'}</Text>
        <TextInput
          inputStyle={data.email.length < 1 ? baseStyles.inputStyle : baseStyles.inputStyleFilled}
          value={data.email}
          onChangeText={(email: string) => {
            setData((prevState) => ({ ...prevState, email }));
            if (inputError.missingEmail !== '') {
              handleBasicErrors('email', 'missingEmail');
            }
          }}
          onBlur={() => {
            handleBasicErrors('email', 'missingEmail');
            revalidateField('invalidEmail', getInvalidEmailError());
            setInputFocus((prevState) => ({ ...prevState, email: false }));
          }}
          onFocus={() => {
            setInputFocus((prevState) => ({ ...prevState, email: true }));
            setApiError('');
          }}
          error={inputError.missingEmail !== '' || validateError.invalidEmail !== ''}
          onKeyPress={onKeyPress}
          {...textInputAttributes}
          maxLength={100}
        />
        <Tooltip
          tooltipText={inputError.missingEmail || validateError.invalidEmail}
          visible={inputError.missingEmail !== '' || validateError.invalidEmail !== ''}
          {...tooltipAttributes}
        />
      </View>
      <View>
        <Text style={baseStyles.formLabel}>{passwordLabel || 'Password'}</Text>
        <TextInput
          inputStyle={data.password.length < 1 ? baseStyles.inputStyle : baseStyles.inputStyleFilled}
          value={data.password}
          secureTextEntry={!isPasswordIconClicked.password}
          onChangeText={(password: string) => {
            setData((prevState) => ({ ...prevState, password }));
            if (inputError.missingPassword !== '') {
              handleBasicErrors('password', 'missingPassword');
              revalidateField('invalidPassword', getInvalidPasswordError());
            }
          }}
          onBlur={() => {
            handleBasicErrors('password', 'missingPassword');
            revalidateField('invalidPassword', getInvalidPasswordError());
            setInputFocus((prevState) => ({ ...prevState, password: false }));
          }}
          onFocus={() => {
            setInputFocus((prevState) => ({ ...prevState, password: true }));
            setApiError('');
          }}
          error={inputError.missingPassword !== '' || validateError.invalidPassword !== ''}
          onKeyPress={onKeyPress}
          icon={getPasswordIcon('password')}
          handleIconClick={() => handlePasswordIconClick('password')}
          iconStyle={baseStyles.iconStyle}
          iconPosition="right"
          {...textInputAttributes}
        />
        <Tooltip
          tooltipText={inputError.missingPassword || validateError.invalidPassword}
          visible={inputError.missingPassword !== '' || validateError.invalidPassword !== ''}
          {...tooltipAttributes}
        />
      </View>
      <View>
        <Text style={baseStyles.formLabel}>{password2Label || 'Repeat Password'}</Text>
        <TextInput
          inputStyle={data.password2.length < 1 ? baseStyles.inputStyle : baseStyles.inputStyleFilled}
          value={data.password2}
          secureTextEntry={!isPasswordIconClicked.password2}
          onChangeText={(password2: string) => {
            setData((prevState) => ({ ...prevState, password2 }));
            if (inputError.missingPassword2 !== '' || formHasError) {
              handleBasicErrors('password2', 'missingPassword2');
              revalidateField('differingPassword', getDifferingPasswordError());
            }
          }}
          onBlur={() => {
            handleBasicErrors('password2', 'missingPassword2');
            revalidateField('differingPassword', getDifferingPasswordError());
            setInputFocus((prevState) => ({ ...prevState, password2: false }));
          }}
          onFocus={() => {
            setInputFocus((prevState) => ({ ...prevState, password2: true }));
            setApiError('');
          }}
          error={inputError.missingPassword2 !== '' || validateError.differingPassword !== ''}
          onKeyPress={onKeyPress}
          icon={getPasswordIcon('password2')}
          handleIconClick={() => handlePasswordIconClick('password2')}
          iconPosition="right"
          {...textInputAttributes}
        />
        <Tooltip
          tooltipText={inputError.missingPassword2 || validateError.differingPassword}
          visible={inputError.missingPassword2 !== '' || validateError.differingPassword !== ''}
          {...tooltipAttributes}
        />
      </View>
      {recaptchaSiteKey && handleRecaptchaChange ? (
        <View>
          <View style={baseStyles.captcha}>
            <Reaptcha sitekey={recaptchaSiteKey} onVerify={handleRecaptchaChange} />
          </View>
          <Tooltip
            tooltipText={validateError.invalidCaptcha}
            visible={validateError.invalidCaptcha !== ''}
            {...tooltipAttributes}
          />
        </View>
      ) : null}
      {useAcceptTerms && (
        <View>
          <TouchableOpacity onPress={handleAcceptTermsPress} style={baseStyles.formAcceptViewStyle}>
            <Checkbox
              checkboxStyle={baseStyles.checkboxStyle}
              handlePress={handleAcceptTermsPress}
              isChecked={data.termsAccepted}
              {...checkboxAttributes}
            />
            <Text style={baseStyles.formAcceptTextStyle}>{termsLabel || languageStrings.default.termsText}</Text>
          </TouchableOpacity>
          <Tooltip
            tooltipText={inputError.unacceptedTerms}
            visible={inputError.unacceptedTerms !== ''}
            {...tooltipAttributes}
          />
        </View>
      )}
      <View style={baseStyles.concentMessageContainer}>{consentMessage}</View>
      <View>
        <Button
          btnStyle={baseStyles.submitButtonViewStyle}
          mode="contained"
          disabled={isLoading}
          onPress={() => {
            handleSignup();
          }}
          label={ctaLabel}
          labelStyle={baseStyles.submitButtonLabelStyle}
          focusKey={ItemName.REGISTER_BUTTON_ITEM}
        ></Button>
      </View>
    </View>
  );
};

export default RegisterAccountForm;
