import { StyleProp, StyleSheet, TextStyle, ViewStyle } from 'react-native';
import merge from 'lodash/merge';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';

interface Styles {
  input: ViewStyle;
  inputFilled: ViewStyle;
  inputWrapper: ViewStyle;
  inputLabel: TextStyle;
  checkboxWrapper: ViewStyle;
  rememberMeText: TextStyle;
  actionsContainer: ViewStyle;
  crossBar: ViewStyle;
  linkButton: ViewStyle;
  linkButtonLabel: TextStyle;
  loginWithProviderBtnWrapper: ViewStyle;
  loginWithProviderBtn: ViewStyle;
  loginWithProviderLabel: TextStyle;
  tvActionsContainer: ViewStyle;
  tvLinkButton: ViewStyle;
  tvLinkButtonLabel: TextStyle;
  handleSubmitBtn: ViewStyle;
  handleSubmitBtnLabel: TextStyle;
}

export const getStyles = (breakpoints: Breakpoints, isTv: boolean, colors: ReactNativePaper.ThemeColors) => {
  const base: StyleProp<Styles> = {
    input: {
      backgroundColor: colors.keyboardFocus.light,
      borderRadius: 5,
      borderWidth: 1,
      opacity: 0.8,
    },
    inputFilled: {
      backgroundColor: colors.keyboardFocus.light,
      borderRadius: 5,
      borderWidth: 1,
      opacity: 1,
    },
    inputWrapper: {},
    inputLabel: {
      color: colors.keyboardFocus.light,
      fontFamily: 'Rubik-Medium',
      fontSize: 16,
    },
    checkboxWrapper: {
      marginTop: 24,
      flexDirection: 'row',
      alignItems: 'center',
    },
    rememberMeText: {
      color: colors.keyboardFocus.light,
      fontSize: 14,
      fontFamily: 'Rubik-Medium',
      marginLeft: 16,
    },
    actionsContainer: {
      marginTop: 28,
      flexDirection: 'row',
      alignItems: 'center',
    },
    crossBar: {
      width: 1,
      height: 16,
      backgroundColor: colors.tenantBackground.light.surfaceBase,
      marginLeft: 16,
      marginRight: 16,
    },
    linkButton: {},
    linkButtonLabel: {},
    loginWithProviderBtnWrapper: {
      borderTopWidth: 1,
      borderTopColor: colors.stroke.onDark.outline02,
      paddingTop: 16,
      marginTop: 32,
    },
    loginWithProviderBtn: {
      paddingVertical: 16,
      borderColor: colors.tenantBackground.light.surfaceBase,
      borderWidth: 3,
      marginTop: 16,
    },
    loginWithProviderLabel: {
      fontSize: 18,
      maxWidth: '60%',
    },
    tvActionsContainer: {
      marginTop: 28,
      flexDirection: 'column',
    },
    tvLinkButton: {
      justifyContent: 'center',
      alignItems: 'center',
      borderWidth: 2,
      borderColor: colors.tenantBackground.light.surfaceBase,
      height: 54,
      marginBottom: 24,
      borderRadius: 4,
    },
    tvLinkButtonLabel: {
      fontSize: 24,
      textDecorationLine: 'none',
    },
    handleSubmitBtn: {
      marginTop: 26,
      height: 40,
      borderColor: 'transparent',
      borderWidth: 2,
    },
    handleSubmitBtnLabel: {
      fontFamily: 'Oswald-Medium',
      fontSize: 20,
    },
  };
  const mediaQueries = isTv
    ? ({
        actionsContainer: {
          marginTop: 28,
          flexDirection: 'column',
        },
        crossBar: {
          display: 'none',
        },
        linkButton: {
          justifyContent: 'center',
          alignItems: 'center',
          borderWidth: 2,
          borderColor: colors.tenantBackground.light.surfaceBase,
          height: 54,
          marginBottom: 24,
          borderRadius: 4,
        },
        linkButtonLabel: {
          fontSize: 24,
          textDecorationLine: 'none',
        },
        inputLabel: {
          fontSize: 20,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isTiny ||
      breakpoints.currentBreakpoints.isTnySm ||
      breakpoints.currentBreakpoints.isSmMed
    ? ({
        crossBar: {
          display: 'none',
        },
        actionsContainer: {
          alignItems: 'flex-start',
          flexDirection: 'column',
          marginTop: 0,
        },
        linkButton: {
          marginTop: 28,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isMedLg
    ? ({
        actionsContainer: {
          marginTop: 28,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isLgXl
    ? ({} as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isXlXxl || breakpoints.currentBreakpoints.isXxl
    ? ({} as StyleProp<Styles>)
    : ({} as StyleProp<Styles>);

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
