import { setKeyMap } from '@noriginmedia/norigin-spatial-navigation';
import { GspDevice, useGetDevice } from './useGetDevice';
import { useEffect } from 'react';
import { getAndroidTVKeyMap, getTizenKeyMap, getWebKeyMap, getWebOSKeyMap, AllkeyMap } from '../components';

/**
 * This handles the mapping of various platforms buttons to the spacial navigator based on platform.
 */
export const useSpatialButtonMapNavigation = () => {
  const { device } = useGetDevice();

  useEffect(() => {
    if (device === GspDevice.ANDROID_TV) {
      setKeyMap(getAndroidTVKeyMap());
    }
    if (device === GspDevice.WEBOS) {
      setKeyMap(getWebOSKeyMap());
    }
    if (device === GspDevice.TIZEN) {
      setKeyMap(getTizenKeyMap());
    }
    //map to samsunghere
    if (device === GspDevice.WEB) {
      setKeyMap(getWebKeyMap());
    }
  }, [device]);
};

/**This is a hook that initailizes and maps the all keys and buttons to various directions and buttons */
export const useAllKeyMaps = () => {
  useEffect(() => {
    setKeyMap(AllkeyMap);
  }, []);
};
