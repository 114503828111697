import { StyleProp, ViewStyle, StyleSheet, TextStyle, ImageStyle } from 'react-native';
import merge from 'lodash/merge';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';

interface Styles {
  teamDetailLayout: ViewStyle;
  wrapper: ViewStyle;
  background: ImageStyle;
  teamLogoStyle: ImageStyle;
  overlay: ViewStyle;
  textBoxStyle: ViewStyle;
  titleStyle: TextStyle;
  descriptionStyle: TextStyle;
}

export const getStyles = (breakpoints: Breakpoints, isTv: boolean, colors: ReactNativePaper.ThemeColors) => {
  const base: StyleProp<Styles> = {
    teamDetailLayout: {
      width: '100%',
      flexDirection: 'column',
    },
    wrapper: {
      marginTop: 25,
      flexDirection: 'row',
      marginBottom: 48,
      paddingLeft: 29,
    },
    background: {
      width: 200,
      height: 200,
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 5,
    },
    teamLogoStyle: {
      height: '70%',
      width: '70%',
    },
    overlay: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      backgroundColor: colors.fill.action.accent02,
      opacity: 0.9,
      borderRadius: 5,
    },
    textBoxStyle: {
      flexDirection: 'column',
      marginLeft: 16,
      flex: 1,
      marginTop: 'auto',
    },
    titleStyle: {
      color: colors.keyboardFocus.light,
      fontSize: 33,
      fontFamily: 'Oswald-SemiBold',
      fontWeight: '600',
      textTransform: 'uppercase',
      flex: 1,
    },
    descriptionStyle: {
      color: colors.keyboardFocus.light,
      width: '80%',
      fontSize: 16,
      fontWeight: 'normal',
      fontFamily: 'Rubik-Regular',
      flex: 1,
      marginTop: 8,
      lineHeight: 20,
    },
  };

  const mediaQueries = isTv
    ? ({
        background: {
          width: 200,
          height: 200,
        },
        titleStyle: {
          fontSize: 44,
        },
        descriptionStyle: {
          fontSize: 24,
          lineHeight: 32,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isTiny || breakpoints.currentBreakpoints.isTnySm
    ? ({
        background: {
          width: 103,
          height: 103,
        },
        wrapper: {
          flexDirection: 'column',
          paddingLeft: 29,
          marginTop: 16,
        },
        textBoxStyle: {
          marginLeft: 0,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isSmMed
    ? ({
        background: {
          width: 103,
          height: 103,
        },
        wrapper: {
          flexDirection: 'column',
          paddingLeft: 40,
          marginTop: 16,
        },
        textBoxStyle: {
          marginLeft: 0,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isMedLg || breakpoints.windowWidth === breakpoints.breakpointSizes.lg
    ? ({
        background: {
          width: 101,
          height: 101,
        },
        wrapper: {
          paddingLeft: 40,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isLgXl ||
      breakpoints.currentBreakpoints.isXlXxl ||
      breakpoints.currentBreakpoints.isXxl
    ? ({
        background: {
          width: 111,
          height: 111,
        },
      } as StyleProp<Styles>)
    : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
