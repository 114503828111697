import { ImageStyle, StyleProp, StyleSheet, TextStyle, ViewStyle } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';
import { merge } from 'lodash';

interface Styles {
  container: ViewStyle;
  childrenWrapper: ViewStyle;
  fullWidthWrapper: ViewStyle;
  centeredWrapper: ViewStyle;
  titleWrapper: ViewStyle;
  titleContainer: ViewStyle;
  fullContainerTitle: ViewStyle;
  centerContainerTitle: ViewStyle;
  leftTitleWrapper: ViewStyle;
  centerTitleWrapper: ViewStyle;
  title: TextStyle;
  fullLayoutTitle: TextStyle;
  centerLayoutTitle: TextStyle;
  subTitle: TextStyle;
  leftSubTitle: TextStyle;
  centerSubTitle: TextStyle;
  titleImage: ImageStyle;
  messageContainer: ViewStyle;
  titleTextContainer: ViewStyle;
}

export const getStyles = (
  breakpoints: Breakpoints,
  isWeb: boolean,
  isTv: boolean,
  colors: ReactNativePaper.ThemeColors
) => {
  const base: StyleProp<Styles> = {
    childrenWrapper: {
      width: '100%',
      paddingHorizontal: 25,
      paddingBottom: 25,
    },
    container: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: 0,
      width: '100%',
      height: '100%',
    },
    fullWidthWrapper: {
      width: '100%',
      height: '100%',
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
    centerContainerTitle: {
      marginTop: 48,
      marginBottom: 20,
    },
    fullContainerTitle: {
      marginTop: 24,
      marginBottom: 24,
    },
    centeredWrapper: {
      width: '100%',
      height: '100%',
      alignSelf: 'center',
    },
    centerTitleWrapper: {
      width: '100%',
    },
    leftTitleWrapper: {
      width: '100%',
    },
    titleContainer: {
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'flex-end',
      paddingHorizontal: 25,
    },
    titleWrapper: {
      width: '100%',
      justifyContent: 'flex-start',
    },
    title: {
      color: colors.keyboardFocus.light,
      fontWeight: '600',
      fontFamily: 'Oswald-SemiBold',
      textTransform: 'uppercase',
      textAlign: 'left',
      width: '100%',
      paddingLeft: 4,
    },
    fullLayoutTitle: {
      fontSize: 33,
    },
    centerLayoutTitle: {
      fontSize: 22,
    },
    subTitle: {
      fontFamily: 'Rubik-Regular',
      color: colors.keyboardFocus.light,
      fontSize: 16,
      textTransform: 'none',
      textAlign: 'left',
      marginTop: 24,
      width: '100%',
      lineHeight: 24,
      paddingLeft: 4,
    },
    leftSubTitle: {
      width: '100%',
      marginTop: 4,
    },
    centerSubTitle: {
      width: '100%',
      marginTop: 24,
    },
    titleImage: {
      height: 111,
      width: 111,
      marginRight: 16,
    },
    messageContainer: {
      alignSelf: 'flex-start',
    },
    titleTextContainer: {
      width: '100%',
    },
  };

  const mediaQueries = isTv
    ? ({
        fullLayoutTitle: {
          fontSize: 44,
        },
        centerLayoutTitle: {
          fontSize: 44,
        },
        subTitle: {
          fontSize: 24,
          lineHeight: 32,
        },
        leftSubTitle: {
          width: '60%',
        },
        centerContainerTitle: {
          marginBottom: 15,
        },
        centerSubTitle: {
          marginTop: 8,
        },
        centeredWrapper: {
          maxWidth: 634,
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isTiny || breakpoints.currentBreakpoints.isTnySm
    ? {
        childrenWrapper: {
          paddingHorizontal: 25,
        },
        subTitle: {
          width: '100%',
          marginTop: 22,
        },
        titleContainer: {
          flexDirection: 'column',
          alignItems: 'flex-start',
          paddingHorizontal: 25,
        },
        messageContainer: {
          width: '100%',
        },
      }
    : breakpoints.currentBreakpoints.isSmMed
    ? {
        titleContainer: {
          flexDirection: 'column',
          alignItems: 'flex-start',
          paddingHorizontal: 40,
        },
        childrenWrapper: {
          paddingHorizontal: 40,
        },
        messageContainer: {
          width: '100%',
        },
      }
    : breakpoints.currentBreakpoints.isMedLg || breakpoints.windowWidth === breakpoints.breakpointSizes.lg
    ? ({
        childrenWrapper: {
          paddingHorizontal: 40,
        },
        leftSubTitle: {
          width: '56%',
        },
        titleContainer: {
          paddingHorizontal: 40,
        },
        messageContainer: {
          width: '100%',
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isLgXl
    ? ({
        leftSubTitle: {
          width: '56%',
        },
        centeredWrapper: {
          maxWidth: 500,
        },
        messageContainer: {
          width: '100%',
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isXlXxl || breakpoints.currentBreakpoints.isXxl
    ? ({
        leftSubTitle: {
          width: '60%',
        },
        centerContainerTitle: {
          marginBottom: 24,
        },
        centeredWrapper: {
          maxWidth: 500,
        },
      } as StyleProp<Styles>)
    : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
