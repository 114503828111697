import React, { FC } from 'react';
import { Text, View } from 'react-native';
import { Content, Maybe } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { getStyles } from './landingHowTo.style';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';

interface LandingHowToProps {
  headLine?: string;
  tutorialContents?: Maybe<Maybe<Content>[]> | undefined;
  breakpoints: Breakpoints;
  colors: ReactNativePaper.ThemeColors;
}

export const LandingHowTo: FC<LandingHowToProps> = ({ headLine, tutorialContents, breakpoints, colors }) => {
  const styles = getStyles(breakpoints, colors);
  const content = tutorialContents ?? [];

  return (
    <View style={styles.howToWrapper}>
      <View style={styles.howToHeaderContainer}>
        <Text style={styles.howToHeader}>{headLine}</Text>
      </View>
      <View style={styles.tutorialContainer}>
        {content?.map((item, index) => {
          const howToItem = item?.item;

          return (
            <View style={styles.contentsContainer} key={index}>
              <View style={styles.tutorialNumberContainer}>
                <Text style={styles.tutorialNumber}>{index + 1}</Text>
              </View>
              <View style={styles.tutorialContents}>
                <Text style={styles.tutorialHeader}>{howToItem?.headline}</Text>
                <Text style={styles.tutorialDescription}>{howToItem?.description}</Text>
              </View>
            </View>
          );
        })}
      </View>
    </View>
  );
};

export default LandingHowTo;
