import merge from 'lodash/merge';
import { StyleProp, StyleSheet, ViewStyle, TextStyle } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';

interface Styles {
  fieldGrpWrapper: ViewStyle;
  termsWrapper: ViewStyle;
  termsContainer: ViewStyle;
  checkbox: ViewStyle;
  termsText: TextStyle;
  checkboxError: ViewStyle;
}

export const getStyles = (breakpoints: Breakpoints, colors: ReactNativePaper.ThemeColors) => {
  const base: StyleProp<Styles> = {
    fieldGrpWrapper: {
      marginVertical: 15,
    },
    termsWrapper: {
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 0,
    },
    termsContainer: {
      flexDirection: 'column',
      marginBottom: 25,
      maxWidth: 365,
      width: '100%',
      justifyContent: 'flex-start',
    },
    checkbox: {
      marginRight: 10,
      borderColor: colors.tenantBackground.light.surfaceBase,
      backgroundColor: colors.tenantBackground.light.surfaceBase,
    },
    termsText: {
      fontFamily: 'Rubik-Medium',
      fontSize: 12,
      fontWeight: '500',
      lineHeight: 16,
    },
    checkboxError: {
      borderColor: colors.tenantBackground.dark.notifyError,
    },
  };

  const mediaQueries =
    breakpoints.currentBreakpoints.isTiny ||
    breakpoints.currentBreakpoints.isTnySm ||
    breakpoints.currentBreakpoints.isSmMed
      ? ({} as StyleProp<Styles>)
      : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
