import React, { useCallback, useContext, useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import { Linking, View } from 'react-native';
import { MessageDisplay, MetaTags, TextContent, Variant } from '@warnermmedia/gsp-core/sdk/ui';
import { LayoutType, LayoutWrapper, TitleType } from '../layoutWrapper';
import { AppConfigContext, breakpointsStateStore } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import DownloadInvoice from './download/downloadInvoice';
import RequestInvoiceForm from './requestInvoiceForm';
import {
  getContentTitle,
  languageStrings,
  ScreenEventType,
  useMparticleCustomEventObject,
  useMparticleScreenEvent,
  webScrollToTop,
} from '@warnermmedia/gsp-core/brands/estadio/feature';
import { MParticleCustomEventTypes, mParticleEventProcessor } from '@warnermmedia/gsp-core/sdk/data-access';
import { getStyles } from './requestInvoiceForm.styles';
import { useReactiveVar } from '@apollo/client';
import { useTheme } from 'react-native-paper';

interface RequestResponse extends AxiosResponse {
  data: {
    boletaUrl: 'string';
  };
}

export const InvoiceRequest = () => {
  const language = languageStrings.default;
  const appConfig = useContext(AppConfigContext);
  const [requestState, setReqState] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [downloadLink, setDownloadLink] = useState('');
  const mParticleEventData = useMparticleCustomEventObject();
  const requestIsSuccessFul = requestState === 'success';
  const requestFailed = requestState === 'failure';
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const { colors } = useTheme();
  const styles = getStyles(breakpoints, colors);

  const url = appConfig?.appConfig.apis.boletaAPI as string;
  const recaptchaKey = appConfig?.appConfig.recaptchaKey as string;
  const pageTitle = !requestIsSuccessFul ? language.invoiceTitle : language.invoiceTitleDownload;
  useMparticleScreenEvent(ScreenEventType.Invoice, pageTitle);

  useEffect(() => {
    webScrollToTop();
  }, []);

  useEffect(() => {
    if (errorMsg || requestFailed) {
      webScrollToTop();
    }
  }, [errorMsg, requestFailed]);

  const handleFormSubmit = useCallback(
    async (data: FormData) => {
      setErrorMsg('');
      setReqState('');
      setLoading(true);
      try {
        const resp: RequestResponse = await axios.post(url, JSON.stringify(data), {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        setDownloadLink(resp.data.boletaUrl);
        setReqState('success');
        setLoading(false);
        window.scroll({ top: 0, behavior: 'smooth' });
      } catch (e) {
        setReqState('failure');
        setLoading(false);
      }
    },
    [url]
  );

  const renderClickableMessage = () => {
    return (
      <TextContent style={styles.errorText}>
        {`${language.invoiceNotFound1} `}
        <TextContent
          style={styles.errorLink}
          onPress={() => Linking.openURL('https://estadiocl.zendesk.com/hc/es/requests/new')}
        >
          {language.invoiceNotFoundLink}
        </TextContent>
        {` ${language.invoiceNotFound2}`}
      </TextContent>
    );
  };

  const handleReturn = useCallback(() => {
    mParticleEventProcessor.pushMParticleEvent(MParticleCustomEventTypes.ReceiptDownloadEvent, mParticleEventData);
    setReqState('');
  }, [mParticleEventData]);

  return (
    <View>
      {errorMsg || requestFailed ? (
        <MessageDisplay
          displayMessage={errorMsg}
          renderDisplayMessage={requestFailed ? renderClickableMessage : undefined}
          onClick={() => null}
          variant={Variant.Error}
          wrapperStyle={styles.messageWrapper}
        />
      ) : null}
      <LayoutWrapper
        layoutType={LayoutType.Center}
        titleType={TitleType.Left}
        pageTitle={pageTitle}
        subTitle={!requestIsSuccessFul ? language.invoiceSubtitle1 : language.invoiceSubtitle2}
      >
        <MetaTags title={getContentTitle(pageTitle)} />
        {requestIsSuccessFul ? (
          <DownloadInvoice link={downloadLink} handleReturn={handleReturn} />
        ) : (
          <RequestInvoiceForm
            recaptchaKey={recaptchaKey}
            handleFormSubmit={handleFormSubmit}
            loading={loading}
            setErrorMsg={setErrorMsg}
          />
        )}
      </LayoutWrapper>
    </View>
  );
};

export default InvoiceRequest;
