import React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';

/* eslint-disable-next-line */
export interface UserProps {
  width?: number;
  height?: number;
  color?: string;
  iconColor?: string;
}

export function User(props: UserProps) {
  const width = props.width || 20;
  const height = props.height || 20;
  const color = props.color || '#359fd7';
  const iconColor = props.iconColor || '#fff';

  return (
    <Svg fill="none" height={height} viewBox="0 0 20 20" width={width}>
      <Circle cx="10" cy="10" r="10" fill={color} />
      <Path
        fill={iconColor}
        d="M10.047 9.298c1.25 0 2.264-1.186 2.264-2.649C12.31 4.62 11.297 4 10.047 4c-1.25 0-2.263.62-2.263 2.65 0 1.462 1.013 2.648 2.263 2.648zM15.045 13.197l-1.142-2.572c-.052-.117-.144-.215-.258-.275l-1.772-.922c-.04-.02-.086-.016-.121.01-.502.38-1.091.58-1.705.58-.613 0-1.203-.2-1.704-.58-.035-.026-.082-.03-.122-.01l-1.772.922c-.114.06-.205.157-.258.275L5.05 13.197c-.078.178-.062.38.044.543.105.163.284.26.478.26h8.952c.194 0 .373-.097.479-.26.106-.162.122-.365.043-.543z"
      />
    </Svg>
  );
}

export default User;
