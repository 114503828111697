import merge from 'lodash/merge';
import { StyleProp, ViewStyle, StyleSheet } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';

interface Styles {
  footer: ViewStyle;
  middleContainer: ViewStyle;
  rightContainer: ViewStyle;
  leftContainer: ViewStyle;
  left: ViewStyle;
  middle: ViewStyle;
  right?: ViewStyle;
}

export const getStyles = (breakpoints: Breakpoints, colors: ReactNativePaper.ThemeColors) => {
  const base: StyleProp<Styles> = {
    footer: {
      flexDirection: 'column',
      width: '100%',
      paddingHorizontal: 0,
      paddingBottom: 50,
      backgroundColor: colors.tenantBackground.light.surfaceBase,
      paddingTop: 20,
    },
    middleContainer: {},
    rightContainer: {
      paddingTop: 10,
    },
    leftContainer: {},
    left: {},
    middle: {},
  };

  const mediaQueries = breakpoints.currentBreakpoints.isLgXl
    ? ({
        footer: {
          flexDirection: 'row',
          flexWrap: 'wrap',
          paddingTop: 0,
          width: '100%',
          paddingBottom: 29,
        },
        middleContainer: {
          alignItems: 'flex-end',
          width: '75%',
          maxHeight: 15,
          paddingTop: 8,
        },
        rightContainer: {
          alignItems: 'flex-start',
          paddingTop: 5,
          height: 50,
        },
        leftContainer: {
          width: '75%',
        },
        middle: {
          width: '80%',
        },
      } as StyleProp<Styles>)
    : breakpoints.currentBreakpoints.isXlXxl || breakpoints.currentBreakpoints.isXxl
    ? ({
        footer: {
          flexDirection: 'row',
          flexWrap: 'wrap',
          paddingTop: 5,
          width: '100%',
          paddingBottom: 56,
        },
        middleContainer: {
          alignItems: 'flex-end',
          width: '75%',
        },
        rightContainer: {
          paddingTop: 0,
          height: 20,
        },
        leftContainer: {
          width: '75%',
        },
      } as StyleProp<Styles>)
    : {};

  const mergedStyles = merge(base, mediaQueries);

  return StyleSheet.create(mergedStyles);
};
