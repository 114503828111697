import { Platform } from 'react-native';
import ReactAppboy from 'react-native-appboy-sdk';
import MParticle from 'react-native-mparticle';
import { UserEmailResponsesEntity } from '../types';
import { MParticleAppConfigObject, MParticleCustomEventTypes } from './mParticleTypes';

export const MPARTICLE_EVENT_PUSH = 'mparticle_event_push';
const isWeb = Platform.OS === 'web';
const isIos = Platform.OS === 'ios';

export const logScreenEvent = (eventName: MParticleCustomEventTypes, mParticleEventData: MParticleAppConfigObject) => {
  const customFlags = {
    'TikTok.URL': mParticleEventData?.page_name ?? '',
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const mParticle = window && (window as any).mParticle ? (window as any).mParticle : MParticle;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const appBoyBraze = isIos ? ReactAppboy : null;
  try {
    if (isWeb) {
      mParticle.logPageView(eventName, mParticleEventData, customFlags);
    } else {
      mParticle.logScreenEvent(eventName, { ...mParticleEventData, customFlags });
      isIos && appBoyBraze && appBoyBraze.logCustomEvent(eventName, mParticleEventData);
    }
  } catch (error) {
    console.log(`Mparticle Screen Event Error ${error}`);
  }
};

export const logMParticleEvent = (
  eventName: MParticleCustomEventTypes,
  mParticleEventData: MParticleAppConfigObject
): void => {
  const customFlags = {
    'TikTok.URL': mParticleEventData?.page_name ?? '',
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const mParticle = window && (window as any).mParticle ? (window as any).mParticle : MParticle;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const appBoyBraze = isIos ? ReactAppboy : null;

  try {
    if (isWeb) {
      mParticle.logEvent(eventName, mParticle.EventType.Other, mParticleEventData, customFlags);
    } else {
      mParticle.logEvent(eventName, mParticle.EventType.Other, { ...mParticleEventData, customFlags });
      isIos && appBoyBraze && appBoyBraze.logCustomEvent(eventName, mParticleEventData);
    }
  } catch (error) {
    console.log(`Mparticle Custom Event ${eventName} Error ${error}`);
  }
};

const setAppBoyIosSection = (userId: string, userPrimaryEmail: UserEmailResponsesEntity | undefined) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const appBoyBraze = isIos ? ReactAppboy : null;

  try {
    if (appBoyBraze) {
      appBoyBraze.changeUser(userId);
      if (userPrimaryEmail) {
        appBoyBraze.setEmail(userPrimaryEmail.emailAddress);
      }
    }
  } catch (error) {
    console.log(`Appboy Change User Identity Error ${error}`);
  }
};

const setMparticleMobileUserId = (userId: string, userPrimaryEmail: UserEmailResponsesEntity | undefined) => {
  try {
    const mParticleRequest = new MParticle.IdentityRequest();
    mParticleRequest.setUserIdentity(userId, MParticle.UserIdentityType.CustomerId);
    if (userPrimaryEmail) {
      mParticleRequest.setUserIdentity(userPrimaryEmail.emailAddress, MParticle.UserIdentityType.Email);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    MParticle.Identity.login(mParticleRequest, (error: any, userId: any) => {
      console.log(`Login MParticle User Identities, UserId: ${userId} Error: ${error}`);
    });
  } catch (error) {
    console.log(`Login Identity Error on Mobile ${error}`);
  }
};

const setMparticleWebUserId = (userId: string, userPrimaryEmail: UserEmailResponsesEntity | undefined) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const mParticle = window && (window as any).mParticle ? (window as any).mParticle : null;
  try {
    const identityRequest = {
      userIdentities: {
        email: userPrimaryEmail?.emailAddress ?? null,
        customerid: userId ?? null,
      },
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const identityCallback = (result: any) =>
      console.log('Login MParticle User Identities', result?.getUser()?.getUserIdentities()?.userIdentities ?? '');
    !!mParticle && mParticle.Identity.login(identityRequest, identityCallback);
  } catch (error) {
    console.log(`Login Identity Error on Web ${error}`);
  }
};

export const setMparticleUserId = (userId: string, userPrimaryEmail?: UserEmailResponsesEntity | undefined) => {
  try {
    isWeb && setMparticleWebUserId(userId, userPrimaryEmail);
    !isWeb && setMparticleMobileUserId(userId, userPrimaryEmail);
    isIos && setAppBoyIosSection(userId, userPrimaryEmail);
  } catch (error) {
    console.log(`Mparticle Identity Error ${error}`);
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getIdentityCallBack = (): ((error?: any, userId?: any, result?: any) => void) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let identityCallback = (error: any, userId: any) => {
    console.log(`Logout MParticle User Identities, UserId: ${userId} Error: ${error}`);
  };

  if (isWeb) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    identityCallback = (result: any) => {
      if (result.getUser()) {
        console.log('Logout MParticle User Identities', result.getUser().getUserIdentities().userIdentities);
      }
    };
  }
  return identityCallback;
};

export const logoutMparticleUserId = (): void => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const mParticle = window && (window as any).mParticle ? (window as any).mParticle : MParticle;
  try {
    mParticle.Identity.logout({}, getIdentityCallBack());
  } catch (error) {
    console.log(`Mparticle Identity Error ${error}`);
  }
};
