import { StyleProp, StyleSheet, TextStyle, ViewStyle } from 'react-native';
import { Breakpoints } from '@warnermmedia/gsp-core/sdk/ui';
import { BreakpointsSize, ScaleAction } from '@warnermmedia/gsp-core/brands/estadio/feature';

interface Styles {
  titleStyle: TextStyle;
  descriptionStyle: TextStyle;
  linkStyle: TextStyle;
  textContainer: ViewStyle;
  qrStyleContainer: ViewStyle;
  qrView: ViewStyle;
  labelStyle?: TextStyle;
  buttonStyle?: ViewStyle;
  navbar?: ViewStyle;
  navBarText?: TextStyle;
  row?: ViewStyle;
  codeLoader?: ViewStyle;
  inputText?: ViewStyle;
  chevron?: ViewStyle;
  pinContainer?: ViewStyle;
  text?: TextStyle;
  wrapper?: ViewStyle;
  loaderContainer?: ViewStyle;
}

export const getStyles = (breakpoints: Breakpoints, scaleSizing: ScaleAction, colors: ReactNativePaper.ThemeColors) => {
  const { scaleHeight, scaleWidth } = scaleSizing;
  const base: StyleProp<Styles> = {
    titleStyle: {
      fontFamily: 'Rubik-Regular',
      fontWeight: '400',
      fontSize: scaleHeight(32, BreakpointsSize.Tv),
      lineHeight: scaleHeight(38, BreakpointsSize.Tv),
      color: colors.keyboardFocus.light,
      marginBottom: scaleHeight(32, BreakpointsSize.Tv),
    },
    descriptionStyle: {
      fontFamily: 'Rubik-Regular',
      fontWeight: '400',
      fontSize: scaleHeight(32, BreakpointsSize.Tv),
      lineHeight: scaleHeight(38, BreakpointsSize.Tv),
      color: colors.keyboardFocus.light,
      marginBottom: scaleHeight(32, BreakpointsSize.Tv),
    },
    linkStyle: {
      fontFamily: 'Rubik-Regular',
      fontWeight: '500',
      lineHeight: scaleHeight(52, BreakpointsSize.Tv),
      color: colors.keyboardFocus.light,
      fontSize: scaleHeight(44, BreakpointsSize.Tv),
      marginBottom: scaleHeight(88, BreakpointsSize.Tv),
    },
    textContainer: {
      flexDirection: 'column',
    },
    qrStyleContainer: {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      padding: scaleHeight(20, BreakpointsSize.Tv),
    },
    qrView: {
      alignItems: 'center',
      justifyContent: 'center',
    },
    buttonStyle: {
      height: scaleHeight(54, BreakpointsSize.Tv),
      borderColor: colors.keyboardFocus.light,
      borderWidth: 2,
      borderRadius: 4,
      backgroundColor: 'transparent',
      margin: scaleHeight(0.5, BreakpointsSize.Tv),
    },
    labelStyle: {
      fontFamily: 'Oswald-Regular',
      fontWeight: '500',
      fontSize: scaleHeight(24, BreakpointsSize.Tv),
      lineHeight: scaleHeight(36, BreakpointsSize.Tv),
      textAlign: 'center',
      textTransform: 'uppercase',
    },
    navbar: {
      marginBottom: scaleHeight(77, BreakpointsSize.Tv),
      justifyContent: 'center',
    },
    navBarText: {
      fontFamily: 'Rubik-Regular',
      fontWeight: '500',
      fontSize: scaleHeight(32, BreakpointsSize.Tv),
      lineHeight: scaleHeight(38, BreakpointsSize.Tv),
      color: colors.keyboardFocus.light,
      marginLeft: scaleWidth(9, BreakpointsSize.Tv),
    },
    row: {
      flexDirection: 'row',
      alignItems: 'center',
      alignSelf: 'flex-start',
    },
    codeLoader: {
      height: scaleHeight(110, BreakpointsSize.Tv),
      width: scaleHeight(72, BreakpointsSize.Tv),
      alignSelf: 'center',
    },
    inputText: {
      height: scaleHeight(110, BreakpointsSize.Tv),
      width: scaleHeight(72, BreakpointsSize.Tv),
      backgroundColor: colors.keyboardFocus.light,
      borderRadius: 8,
      justifyContent: 'center',
      alignItems: 'center',
    },
    text: {
      fontFamily: 'Oswald-Regular',
      fontWeight: '600',
      lineHeight: scaleHeight(130, BreakpointsSize.Tv),
      textAlign: 'center',
      textTransform: 'uppercase',
      fontSize: scaleHeight(88, BreakpointsSize.Tv),
    },
    pinContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: scaleHeight(88, BreakpointsSize.Tv),
    },
    loaderContainer: {
      height: scaleHeight(110, BreakpointsSize.Tv),
      marginBottom: scaleHeight(88, BreakpointsSize.Tv),
    },
    wrapper: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  };

  return StyleSheet.create(base);
};
