import React, { useCallback, useContext, useRef } from 'react';
import { ImageBackground, Pressable, Text, View } from 'react-native';
import {
  callbackNavigateExternal,
  getImageArray,
  getImageCardWidth,
  getImageWithStyle,
  getLocalLink,
  getUniqueId,
  languageStrings,
  useCardItemImage,
} from '@warnermmedia/gsp-core/brands/estadio/feature';
import { breakpointsStateStore, Component, HistoryContext } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import LinearGradient from 'react-native-linear-gradient';
import { useReactiveVar } from '@apollo/client';
import { useTheme } from 'react-native-paper';
import { getStyles } from './banner.styles';
import { Hoverable } from 'react-native-web-hover';
import { TextContent, useGetDevice, useSpatialNavigation } from '@warnermmedia/gsp-core/sdk/ui';
import { get } from 'lodash';
import { Cta } from '../../../cta/cta';
import { Play } from '@warnermmedia/gsp-core/brands/estadio/assets';
import { ItemName } from '@warnermmedia/gsp-core/sdk/data-access';

interface BannerComponentProps {
  data: Component;
  shouldOverflow?: boolean;
}

export const Banner = ({ data, shouldOverflow = false }: BannerComponentProps) => {
  const { colors } = useTheme();
  const { isTv, isPwa } = useGetDevice();
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const navMobile = !isPwa || (breakpoints.windowWidth <= breakpoints.breakpointSizes.lg && !isTv);
  const styles = getStyles(breakpoints, colors, isTv);
  const device = useGetDevice();
  const { getCardImageSource } = useCardItemImage();

  const liveCardImage = getCardImageSource({
    fallbackName: '',
    image: data.images ? getImageWithStyle(getImageArray(data.images, getImageCardWidth(640, 320))) : null,
  });

  const backgroundImage = getCardImageSource({
    fallbackName: '',
    image: data.image ? getImageWithStyle(getImageArray(data.image.url, getImageCardWidth(1280, 960))) : null,
  });

  const largeScreenSize =
    device.isWeb && (breakpoints.currentBreakpoints.isLgXl || breakpoints.currentBreakpoints.isXlXxl);
  const mediumScreenSize =
    device.isWeb &&
    (breakpoints.currentBreakpoints.isMedLg ||
      breakpoints.currentBreakpoints.isSmMed ||
      breakpoints.currentBreakpoints.isTnySm);

  const CtaIcon = Play;

  const link = getLocalLink(get(data, 'link.uri', null));
  const historyContext = useContext(HistoryContext);
  const history = historyContext?.ready ? historyContext?.useHistory() : null;

  const navigateLanding = useCallback(() => {
    if (link) {
      history?.push(link);
    }
  }, [history, link]);

  const transformValue = useCallback(() => {
    return largeScreenSize
      ? {
          transform: [{ scale: 1.1 }],
        }
      : mediumScreenSize
      ? {
          transform: [{ scale: 1.05 }],
        }
      : {};
  }, [largeScreenSize, mediumScreenSize]);

  const cardFocusKey = useRef(getUniqueId(ItemName.BANNER_ITEM)).current;
  const { ref, focusSelf, setFocusOnNavbar } = useSpatialNavigation({
    focusKey: cardFocusKey,
    onEnterPress: () => {
      navigateLanding();
    },
    onArrowPress: (direction) => {
      if (direction === 'right') {
        focusSelf();
        return false;
      }

      if (direction === 'left' && !navMobile) {
        setFocusOnNavbar();
      }

      if (direction === 'left' && navMobile) {
        focusSelf();
        return false;
      }
      return true;
    },
  });

  const renderLiveCards = useCallback(
    (hovered: boolean) => {
      const transformValueResult = hovered ? transformValue() : {};
      return (
        <View style={[styles.cardContainer, transformValueResult]}>
          <ImageBackground style={styles.liveBannerCard} source={liveCardImage}>
            <View style={styles.ctaWrapper}>
              <Cta
                icon={
                  <CtaIcon
                    color={colors.tenantBackground.light.surfaceBase}
                    iconColor={colors.fill.action.accent01}
                    height={isTv ? 33 : 20}
                    width={isTv ? 33 : 20}
                  />
                }
                position="left"
                text={languageStrings.default.liveLabel}
                textContainStyle={styles.labelContentStyle}
                textStyle={styles.ctaText}
                style={styles.playLabelStyle}
              />
            </View>
          </ImageBackground>
          <View style={styles.textContainer}>
            <TextContent style={styles.headLine} numberOfLines={1} accessible={false}>
              {data.headline}
            </TextContent>
            {data.subheadline ? (
              <TextContent style={styles.subHeadLine} numberOfLines={1}>
                {data.subheadline}
              </TextContent>
            ) : null}
          </View>
        </View>
      );
    },
    [
      data.headline,
      data.subheadline,
      styles.cardContainer,
      styles.headLine,
      styles.liveBannerCard,
      styles.subHeadLine,
      transformValue,
      liveCardImage,
      CtaIcon,
      colors.fill.action.accent01,
      colors.tenantBackground.light.surfaceBase,
      styles.ctaText,
      styles.ctaWrapper,
      styles.labelContentStyle,
      styles.playLabelStyle,
      isTv,
      styles.textContainer,
    ]
  );

  const renderHoveredLiveCards = useCallback(() => {
    return <Hoverable>{({ hovered }) => renderLiveCards(hovered)}</Hoverable>;
  }, [renderLiveCards]);

  const callbackNavigateExternalMax = useCallback(() => {
    if (device.isWeb || device.isAndroidDevice) {
      callbackNavigateExternal(data.link?.uri ?? '');
    }
  }, [data.link?.uri, device.isWeb, device.isAndroidDevice]);

  const renderMaxBanner = useCallback(() => {
    return (
      <View style={styles.maxCardLayout}>
        <View style={styles.cardWrapper}>
          <Pressable onPress={callbackNavigateExternalMax} ref={ref} nativeID={cardFocusKey} style={styles.cardLayout}>
            <Text style={styles.maxDescription}>{data.description}</Text>
          </Pressable>
        </View>
      </View>
    );
  }, [
    ref,
    data.description,
    cardFocusKey,
    callbackNavigateExternalMax,
    styles.maxDescription,
    styles.maxCardLayout,
    styles.cardWrapper,
    styles.cardLayout,
  ]);

  return (
    <View style={!!shouldOverflow && styles.bannerLayout}>
      {!['alert-banner'].includes(data?.id ?? '') ? (
        <ImageBackground source={backgroundImage} style={styles.backgroundImage}>
          <LinearGradient
            colors={[
              colors.tenantBackground.dark.surfaceBaseFilmGrad.end,
              colors.tenantBackground.dark.surfaceBaseFilmGrad.start,
            ]}
            start={{ x: 0, y: 0 }}
            end={{ x: 1, y: 0 }}
            locations={[0, 0.3, 1]}
            style={styles.backgroundOverlay}
          >
            <View style={styles.cardWrapper}>
              <Pressable onPress={navigateLanding} ref={ref} nativeID={cardFocusKey} style={styles.cardLayout}>
                {renderHoveredLiveCards()}
              </Pressable>
            </View>
          </LinearGradient>
        </ImageBackground>
      ) : (
        renderMaxBanner()
      )}
    </View>
  );
};

export default Banner;
