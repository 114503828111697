/**
 * @class DaltonSingleton
 * @description getter & setter for user token
 */
class DaltonSingleton {
  private static _instance: DaltonSingleton;
  private static _token: string;
  private static _endpoint: string;

  private constructor() {
    // nothing here ... just closed off
  }

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  // Dalton token getters/setters
  public get token(): string {
    return DaltonSingleton._token;
  }

  public set token(value: string) {
    if (value != null) {
      DaltonSingleton._token = value;
    }
  }

  // Dalton endpoint getters/setters
  public get endpoint(): string {
    return DaltonSingleton._endpoint;
  }

  public set endpoint(value: string) {
    if (value != null) {
      DaltonSingleton._endpoint = value;
    }
  }
}

export const DALTON = DaltonSingleton.Instance;
