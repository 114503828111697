import React, { useEffect, useState } from 'react';
import { Dimensions, ScrollView, View } from 'react-native';
import { getStyles } from './staticLanding.styles';
import { useReactiveVar } from '@apollo/client';
import get from 'lodash/get';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { breakpointsStateStore, componentTypes, Page } from '@warnermmedia/gsp-core/brands/estadio/data-access';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  carouselFormat,
  PagesUrl,
  QueryNames,
  useCMSPageData,
  useCMSPageMetaTags,
  useCMSQueryDataResults,
  useIsMountedRef,
  useMparticleScreenEvent,
} from '@warnermmedia/gsp-core/brands/estadio/feature';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { MetaTags, useGetDevice } from '@warnermmedia/gsp-core/sdk/ui';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { MParticleAppConfigParams } from '@warnermmedia/gsp-core/sdk/data-access';
import { useTheme } from 'react-native-paper';
import { LandingHero } from '../landingHero';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { SkeletonDisplay } from '@warnermmedia/gsp-core/brands/estadio/ui';

export const StaticLanding = () => {
  const { pageData: pageUriData, loading } = useCMSQueryDataResults<Page>({
    queryName: QueryNames.GET_PRODUCTS,
    uri: PagesUrl.Register,
  });
  const [display, setDisplay] = useState<carouselFormat[] | null>(null);
  const { metaTags, title, type, cmsId } = useCMSPageMetaTags(pageUriData);
  const [mParticleEventOptions, setMparticleEventOptions] = useState<MParticleAppConfigParams>(
    {} as MParticleAppConfigParams
  );
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const { colors } = useTheme();
  const { width } = Dimensions.get('window');
  const styles = getStyles(breakpoints, colors);
  const [shouldFocusForm, setShouldFocusForm] = useState(false);
  const screenComponents = display ? display.filter(({ type }) => type === componentTypes.SCREEN) : [];
  const landingHero = screenComponents.find(({ id }) => id === 'landing-hero');
  const landingProduct = get(landingHero, 'content[0].product[0]', null);
  const { formatData } = useCMSPageData();
  useMparticleScreenEvent(type, title, cmsId);
  const isMountedRef = useIsMountedRef();
  const { isWeb } = useGetDevice();

  useEffect(() => {
    if (pageUriData && pageUriData.components && isMountedRef.current) {
      setDisplay(formatData(pageUriData.components));
    }
  }, [pageUriData, isMountedRef]);

  useEffect(() => {
    if (isWeb && isMountedRef.current) {
      const inputs = Array.from(document.getElementsByTagName('input'));
      inputs.forEach((input) => {
        input.tabIndex = 0;
      });
    }
  }, [isWeb, isMountedRef]);

  useEffect(() => {
    if (isMountedRef.current) {
      setMparticleEventOptions({
        section: type,
        contentTitle: title,
        contentId: cmsId,
      });
    }
  }, [title, type, cmsId, isMountedRef]);

  return (
    <>
      {isWeb && <MetaTags data={metaTags} title={title} />}
      {!loading ? (
        <View style={styles.pageContainer}>
          {landingHero ? (
            <LandingHero
              landingImages={landingHero.images}
              mParticleEventOptions={mParticleEventOptions}
              shouldFocusForm={shouldFocusForm}
              setShouldFocusForm={setShouldFocusForm}
              backgroundImage={landingHero?.image?.url}
              product={landingProduct}
              ctaLink={landingHero?.link}
              breakpoints={breakpoints}
              width={width}
              colors={colors}
              isRegisterEnabled={false}
              isAppEnabled={false}
            />
          ) : null}
        </View>
      ) : (
        <SkeletonDisplay route="/register" />
      )}
    </>
  );
};

export default StaticLanding;
