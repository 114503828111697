import React from 'react';
import { View } from 'react-native';
import { CardProps } from '@warnermmedia/gsp-core/sdk/ui';
import { Cta } from '../../cta/cta';
import { getCardSettings } from '../atoms/cardsAtoms';
import { getStyles } from './dpgHeader.styles';
import {
  carouselBase,
  getUpcomingHeader,
  languageStrings,
  useUpcomingEventCountdown,
} from '@warnermmedia/gsp-core/brands/estadio/feature';

export const DpgHeader = (props: CardProps<carouselBase>) => {
  const styles = getStyles(props.breakpoints);
  const fontSize: number = props.device.isTv ? 24 : 18;
  const status = props.item.status;
  const countdown = useUpcomingEventCountdown(status === 2, props.item.scheduled_utc, props.item.type);
  const cardSettings = getCardSettings(props.colors);

  const upcomingHeader =
    countdown || getUpcomingHeader(props.item.scheduled_utc, languageStrings.default.todayLabel, props.item.type);

  return (
    <React.Fragment>
      <View
        style={[
          styles.statusBackground,
          { backgroundColor: status === 3 ? props.colors.fill.action.accent01 : props.colors.fill.action.accent04 },
        ]}
      ></View>
      <View style={styles.header}>
        <Cta
          icon={status === 4 ? cardSettings.refreshCTA : status === 3 ? cardSettings.playCTA : cardSettings.upcomingCTA}
          text={
            status === 4
              ? languageStrings.default.revealLabel
              : status === 3
              ? languageStrings.default.liveLabel
              : upcomingHeader
          }
          textStyle={{
            fontSize: fontSize,
            fontFamily: 'Oswald-Regular',
            textTransform: 'uppercase',
          }}
          position="center"
        ></Cta>
      </View>
    </React.Fragment>
  );
};

export default DpgHeader;
