/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { CMSAPIModels, SortDirections } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { carouselBase } from '../../Utils/Interfaces';
import { eventToCarouselBase, sortCarouselContentByDate, videoToCarouselBase } from '../../Services/CommonFunctions';
import { useCallback } from 'react';

type FormatEvents = (
  queryData: CMSAPIModels.Maybe<CMSAPIModels.Event>[] | undefined,
  shouldSortEvents?: boolean,
  order?: SortDirections
) => carouselBase[];

type FormatVideos = (queryData: CMSAPIModels.Maybe<CMSAPIModels.Video>[] | undefined) => carouselBase[];

interface FormatCarouselDataProps {
  formatEvents: FormatEvents;
  formatVideos: FormatVideos;
}

export const useFormatCarouselData = (): FormatCarouselDataProps => {
  const formatEvents: FormatEvents = useCallback((data = [], shouldSortEvents = false, order) => {
    const carouselData = data.map(eventToCarouselBase);
    return shouldSortEvents ? sortCarouselContentByDate(carouselData, order) : carouselData;
  }, []);

  const formatVideos: FormatVideos = useCallback((data = []): carouselBase[] => {
    const carouselData = data.map(videoToCarouselBase);
    return carouselData;
  }, []);

  return { formatEvents, formatVideos };
};
