import React, { useCallback, useRef, useState } from 'react';
import Reaptcha from 'reaptcha';
import { View } from 'react-native';
import {
  Datetimepicker,
  TextContent,
  Tooltip,
  validateCurrency,
  validateDatePattern,
  validateNonEmptyString,
  validateRUT,
  validateTicketNumber,
} from '@warnermmedia/gsp-core/sdk/ui';
import { getStyles } from './requestInvoiceForm.styles';
import { EstadioButton, EstadioTextInput } from '../components';
import { languageStrings } from '@warnermmedia/gsp-core/brands/estadio/feature';
import { useReactiveVar } from '@apollo/client';
import { useTheme } from 'react-native-paper';
import { breakpointsStateStore } from '@warnermmedia/gsp-core/brands/estadio/data-access';
import { FocusKeys } from '@warnermmedia/gsp-core/sdk/data-access';

export interface FormData {
  folio: string;
  rut: string;
  date: string;
  amount: string;
  recaptcha_token: string;
}

interface RequestInvoiceForm {
  handleFormSubmit: (data: FormData) => void;
  recaptchaKey: string;
  loading: boolean;
  setErrorMsg: (error: string) => void;
}

const datePattern = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/;

export const RequestInvoiceForm = ({ handleFormSubmit, recaptchaKey, loading, setErrorMsg }: RequestInvoiceForm) => {
  const language = languageStrings.default;
  const breakpoints = useReactiveVar(breakpointsStateStore);
  const { colors } = useTheme();
  const styles = getStyles(breakpoints, colors);
  const [data, setData] = useState<FormData>({
    folio: '',
    rut: '',
    date: '',
    amount: '',
    recaptcha_token: '',
  });
  const [error, setError] = useState({
    folio: false,
    rut: false,
    date: false,
    amount: false,
    recaptcha_token: false,
  });

  const dataRef = useRef(data);

  const handleErrorChange = (field: string, value: boolean) => {
    setError((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleChange = (field: string, value: string) => {
    setData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
    if (field === 'date') {
      dataRef.current[field] = value;
    }
  };

  const handleSubmit = () => {
    setErrorMsg('');
    const errorObj = {
      folio: !validateTicketNumber(data.folio),
      rut: !validateRUT(data.rut),
      date: !validateDatePattern(data.date, datePattern),
      amount: !validateCurrency(data.amount),
      recaptcha_token: !validateNonEmptyString(data.recaptcha_token),
    };
    setError(errorObj);
    const isFormValid = !Object.values(errorObj).includes(true);
    if (error.recaptcha_token && !data.recaptcha_token) {
      setErrorMsg(language.invoiceErrorMsg);
      return;
    }
    if (!isFormValid) {
      setErrorMsg(language.invoiceSuccessMsg);
      return;
    }
    const formatedDate = data.date.replace(/\//g, '-');
    const payload = { ...data };
    payload.date = formatedDate;
    const processedRUT = data.rut.replace(/[-.]/g, '');
    payload.rut = `${processedRUT.substring(0, 8)}-${processedRUT[8]}`;
    handleFormSubmit(payload);
  };

  const memoizedCallback = useCallback(
    (token: string | null) => {
      handleChange('recaptcha_token', token as string);
      error.recaptcha_token && handleErrorChange('recaptcha_token', !validateNonEmptyString(token as string));
    },
    [error.recaptcha_token]
  );

  const memoizedOnExpiredCallback = useCallback(() => {
    handleChange('recaptcha_token', '');
    handleErrorChange('recaptcha_token', true);
  }, []);

  const onSelectDate = useCallback((value: string, isValid: boolean) => {
    if (isValid) {
      handleChange('date', value);
    } else {
      handleErrorChange('date', !isValid);
    }
  }, []);

  return (
    <View>
      <View style={styles.inputWrapper}>
        <TextContent style={styles.label}>{language.invoiceNumber}</TextContent>
        <EstadioTextInput
          inputStyle={data.folio.length < 1 ? styles.input : styles.inputFilled}
          value={data.folio}
          onChangeText={(text) => {
            handleChange('folio', text);
            const hasError = !validateTicketNumber(text);
            handleErrorChange('folio', hasError);
          }}
          onBlur={() => {
            const hasError = !validateTicketNumber(data.folio);
            handleErrorChange('folio', hasError);
          }}
          error={error.folio}
          editable={!loading}
          focusable={!loading}
          errorString={language.invoiceNumberToolTip}
          focusKey={FocusKeys.INVOICE}
        />
      </View>
      <View style={styles.inputWrapper}>
        <TextContent style={styles.label}>{language.invoiceDate}</TextContent>
        <Datetimepicker
          date={data.date}
          errorMessage={language.invoiceDateToolTip}
          validRange={{
            endDate: new Date(),
          }}
          modalInputEnabled={false}
          editable={!loading}
          dateFormat="DD/MM/YYYY"
          placeholder="DD/MM/YYYY"
          containerStyle={styles.dateInput}
          inputStyle={styles.dateTextInput}
          onSelect={onSelectDate}
        />
      </View>
      <View style={styles.inputWrapper}>
        <TextContent style={styles.label}>{language.invoiceAmount}</TextContent>
        <EstadioTextInput
          inputStyle={data.amount.length < 1 ? styles.input : styles.inputFilled}
          value={data.amount}
          onChangeText={(text) => {
            handleChange('amount', text);
            const hasError = !validateCurrency(text);
            handleErrorChange('amount', hasError);
          }}
          onBlur={() => {
            const hasError = !validateCurrency(data.amount);
            handleErrorChange('amount', hasError);
          }}
          error={error.amount}
          editable={!loading}
          focusable={!loading}
          errorString={language.invoiceAmountToolTip}
        />
      </View>
      <View style={styles.inputWrapper}>
        <TextContent style={styles.label}>RUT</TextContent>
        <EstadioTextInput
          inputStyle={data.rut.length < 1 ? styles.input : styles.inputFilled}
          value={data.rut}
          onChangeText={(text) => {
            handleChange('rut', text);
            const hasError = !validateRUT(text);
            handleErrorChange('rut', hasError);
          }}
          onBlur={() => {
            const hasError = !validateRUT(data.rut);
            handleErrorChange('rut', hasError);
          }}
          error={error.rut}
          editable={!loading}
          focusable={!loading}
          errorString={language.invoiceRUTToolTip}
        />
      </View>
      <View style={styles.inputWrapper}>
        <View style={styles.recaptchWrapper}>
          <Reaptcha
            hl={language.recaptchaLang}
            sitekey={recaptchaKey}
            onVerify={memoizedCallback}
            onExpire={memoizedOnExpiredCallback}
          />
        </View>
        <Tooltip
          tooltipText={language.invoiceRecaptchaToolTip}
          visible={error.recaptcha_token && !data.recaptcha_token}
          opacity={1}
          position="center"
        />
      </View>
      <EstadioButton
        labelStyle={styles.btnLabel}
        btnStyle={styles.btnWrapper}
        mode="contained"
        disabled={loading}
        onPress={handleSubmit}
        loading={loading}
        label={language.consult}
      />
    </View>
  );
};

export default RequestInvoiceForm;
